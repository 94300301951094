import { ReactNode } from "react";
import { SxProps, Theme } from "@mui/material";
import { ColumnFiltersState, Updater } from "@tanstack/react-table";
import { ICheckboxOption } from "../CheckboxTree/CheckboxTree.utils";

export type TFilteringParams<T extends object = TFilters> = Record<
  keyof T,
  string[] | undefined
>;

export type TDateRangeFilterSchema = Record<
  DateRangeKey,
  { label: string; filterKey: string; type?: FilterType }
>;

export enum DateRangeType {
  Start = "START",
  End = "END",
}

export enum DateRangeKey {
  "StartDate" = "startDate",
  "EndDate" = "endDate",
}

export enum FilterType {
  Date = "DATE",
  Select = "SELECT",
  DateRange = "DATE_RANGE",
  Toggle = "TOGGLE",
  SelectTypeahead = "SELECT_TYPEAHEAD",
}

export type TFilterOption = {
  text: string;
  value: string;
};

type TBaseFilter<Type extends FilterType> = {
  type: Type;
};

type TBaseSelectFilter<FilterOption = TFilterOption> = {
  label: string;
  options: FilterOption[];
  selectAllLabel?: string;
  sx?: SxProps<Theme>;
};

export type TDateRangeFilter = TBaseFilter<FilterType.DateRange> & {
  options: TDateRangeFilterSchema;
};

export type TSelectFilter<FilterOption = TFilterOption> =
  TBaseFilter<FilterType.Select> & TBaseSelectFilter<FilterOption>;

export type TSelectTypeaheadFilter<FilterOption = ICheckboxOption> =
  TBaseFilter<FilterType.SelectTypeahead> & TBaseSelectFilter<FilterOption>;

export type TToggleFilter = TBaseFilter<FilterType.Toggle> & {
  label: string;
};

export type TPossibleFilter =
  | TDateRangeFilter
  | TSelectFilter
  | TToggleFilter
  | TSelectTypeaheadFilter;

export type TFilters<PossibleFilter = TPossibleFilter> = Record<
  string,
  PossibleFilter
>;

type TKeywordFilterProps<B> = {
  hideKeywordFilter?: B;
  keywordFilterValue: string;
  onKeywordChange: (text: string) => void;
  keywordFilterPlaceholder?: string;
  filterByKeywordLabel: string;
};

type THideKeywordFilter<B extends Boolean> = B extends true
  ? Partial<TKeywordFilterProps<B>>
  : TKeywordFilterProps<B>;

export type TTableFilterProps = {
  filters?: TFilters;
  selectedFilters?: TFilteringParams;
  onFiltersChange?: (filters: TFilteringParams) => void;
  switcher?: ReactNode;
  showButtonLabel?: string;
  hideButtonLabel?: string;
  clearAllFiltersLabel?: string;
  errorAdornmentAriaLabel: string;
} & (THideKeywordFilter<true> | THideKeywordFilter<false>);

export type TKeywordParams = {
  keyword?: string;
};

export type TFiltersUpdater = Updater<ColumnFiltersState>;
