import { SyntheticEvent } from "react";
import { Link, LinkProps } from "@mui/material";
import styles from "./ButtonLink.styles";

interface IButtonLinkProps {
  onClick?: (event?: SyntheticEvent) => void;
  sx?: LinkProps["sx"];
  disabled?: boolean;
  children: React.ReactNode;
}

const ButtonLink = (props: IButtonLinkProps) => {
  const sx = {
    ...props.sx,
    ...styles.link,
    ...(props.disabled && styles.disabled),
  };

  return (
    <Link
      component="button"
      disabled={props.disabled}
      aria-disabled={props.disabled}
      underline="none"
      onClick={props.onClick}
      sx={sx}
    >
      {props.children}
    </Link>
  );
};

export default ButtonLink;
