import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import { TFunction } from "i18next";
import {
  FormMultiSelectTypeahead,
  FormSelect,
  FormSingleSelectTypeahead,
  FormTextInput,
} from "components/common/form-fields";
import { InfoTooltip } from "components/shared";
import { TSingleSelectTypeaheadOption } from "components/shared/SingleSelectTypeahead";
import useDrugClassList from "hooks/api/GQL/entityManagement/useDrugClassList";
import useEntityOrganizations from "hooks/api/GQL/entityManagement/useEntityOrganizations";
import { EntityStatus } from "models/entities.models";
import { getOrganizationsOptions } from "screens/EntityManagement/components/Products/ProductsTable/ProductsTable.utils";
import { formatValuesToOptions } from "utils/helpers";
import { SubstanceEntityFieldName } from "./SubstanceEntityFormFields.types";
import { getDrugClassFieldOptions } from "./SubstanceEntityFormFields.utils";
import styles from "./SubstanceEntityFormFields.styles";

const ATCInfo = ({ t }: { t: TFunction }) => {
  const ATCInstructions: string[] =
    t("ATCCodeGuidanceByIndex", {
      returnObjects: true,
    }) ?? [];

  return (
    <Stack m={2} spacing={1} direction="column">
      <Typography variant="body2" mb={1}>
        {`${t("ATCCodeGuidanceIntro")}`}
      </Typography>
      {ATCInstructions.map((instruction, index) => (
        <Typography key={index} variant="body2">
          {`${index + 1}. ${instruction}`}
        </Typography>
      ))}
    </Stack>
  );
};

const SubstanceEntityFormFields = () => {
  const { t } = useTranslation("administration");
  const { data: drugClassList } = useDrugClassList();
  const { organizations } = useEntityOrganizations();

  const drugClassFieldOptions = useMemo(
    () => getDrugClassFieldOptions(drugClassList),
    [drugClassList],
  );

  const statusOptions = useMemo(
    () =>
      formatValuesToOptions(
        [EntityStatus.Active, EntityStatus.Inactive],
        (status) => t(`${status}`),
        (status) => status,
      ),
    [t],
  );

  const organizationsOptions = useMemo(
    () => getOrganizationsOptions(organizations, t),
    [organizations, t],
  );

  const onTransformFieldToUpperCase = (fieldValue: string) => {
    return fieldValue?.toUpperCase();
  };

  return (
    <Grid container spacing={2} rowGap={1} mb={4}>
      <Grid item xs={6}>
        <FormTextInput
          fullWidth
          name={SubstanceEntityFieldName.SubstanceName}
          label={t("manageSubstanceFormFields.name")}
        />
      </Grid>
      <Grid item xs={6}>
        <FormSingleSelectTypeahead<TSingleSelectTypeaheadOption>
          fullWidth
          getValue={(option) => option}
          name={SubstanceEntityFieldName.DrugClass}
          label={t("manageSubstanceFormFields.class")}
          options={drugClassFieldOptions}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={6}>
        <FormTextInput
          fullWidth
          name={SubstanceEntityFieldName.INN}
          label={t("manageSubstanceFormFields.INN")}
        />
      </Grid>

      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <FormTextInput
              fullWidth
              name={SubstanceEntityFieldName.ATCCode}
              label={t("manageSubstanceFormFields.ATC")}
              fieldTransform={onTransformFieldToUpperCase}
            />
          </Grid>
          <Grid item xs={1}>
            <InfoTooltip
              iconStyles={styles.atcHelpIcon}
              title={<ATCInfo t={t} />}
              placement="right"
              textAriaLabel={t("ATCCodeHelpText")}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <FormMultiSelectTypeahead
          fullWidth
          name={SubstanceEntityFieldName.LinkedOrganizations}
          label={t("manageSubstanceFormFields.linkedOrganizations")}
          options={organizationsOptions}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </Grid>
      <Grid item xs={6}>
        <FormSelect
          fullWidth
          name={SubstanceEntityFieldName.Status}
          label={t("manageSubstanceFormFields.status")}
          options={statusOptions}
        />
      </Grid>
    </Grid>
  );
};

export default SubstanceEntityFormFields;
