const poll = <T>(
  fn: () => Promise<T>,
  getIsDone: (data: T) => boolean,
  intervalAmount: number,
) =>
  new Promise<T>((resolve) => {
    (async function doPoll() {
      const response = await fn();

      if (getIsDone(response)) {
        resolve(response);
      } else {
        setTimeout(doPoll, intervalAmount);
      }
    })();
  });

export default poll;
