import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Stack, Typography } from "@mui/material";
import Alert from "components/shared/Alert";
import Button from "components/shared/Button";
import Loading from "components/shared/Loading";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import NoDataFallback from "components/shared/NoDataFallback";
import TableFilter from "components/shared/TableFilter";
import { useAlerts } from "context/AlertContext";
import useCreateAssetRelationship from "hooks/api/REST/assets/useCreateAssetRelationship";
import useDocuments from "hooks/api/REST/documents/useDocuments";
import useKeywordFilter from "hooks/filters/useKeywordFilter";
import useManualPagination from "hooks/react-table/useManualPagination";
import { IDocument } from "models/documents.models";
import { IHAQMetadata } from "models/HAQ.models";
import { AssetRelationship } from "utils/constants/assets.constants";
import {
  DocumentStatus,
  SUPPORTED_SUBTYPES,
} from "utils/constants/doc.constants";
import { TAssetWithContent } from "utils/helpers";
import AddProjectDocumentsTable from "./AddProjectDocumentsTable";
import styles from "./AddProjectDocuments.styles";

export type TAddProjectDocuments = {
  isOpen: boolean;
  onClose: () => void;
  HAQResponseWithContent: TAssetWithContent<IHAQMetadata> | undefined;
  attachedDocuments?: IDocument[];
};

const AddProjectDocuments = ({
  isOpen,
  onClose,
  HAQResponseWithContent,
  attachedDocuments,
}: TAddProjectDocuments) => {
  const { t } = useTranslation(["documents", "HAQ", "common"]);

  const { projectId } = useParams();
  const { showAlert } = useAlerts();

  const { pagination, paginationParams, setPagination, resetPagination } =
    useManualPagination();

  const { keywordParam, onKeywordChange, debouncedKeywordParam } =
    useKeywordFilter(resetPagination);

  const {
    documents,
    pagination: { totalElements },
    isLoading,
    isError,
    isSuccess,
  } = useDocuments(
    {
      assetTypes: SUPPORTED_SUBTYPES.join(),
      statuses: [DocumentStatus.Final],
      ...paginationParams,
      ...(projectId && { projectIds: projectId }),
      ...(debouncedKeywordParam && { keyword: debouncedKeywordParam }),
    },
    {
      keepPreviousData: true,
      enabled: isOpen,
    },
  );

  const { mutateAsync: createRelationships } = useCreateAssetRelationship(
    AssetRelationship.HasAttachment,
  );

  const [documentsForSubmission, setDocumentsForSubmission] = useState<
    IDocument[]
  >([]);

  const hasResults = isSuccess && Boolean(documents?.length);
  const isNoResults = isSuccess && !Boolean(documents?.length);

  const confirmChanges = async () => {
    try {
      await Promise.allSettled(
        documentsForSubmission.map((document) =>
          createRelationships({
            assetId: HAQResponseWithContent?.assetId ?? "",
            attachmentId: document.id,
          }),
        ),
      );

      documentsForSubmission.map((document) =>
        showAlert({
          message: t("notifications.successfullyAdded", {
            ns: "HAQ",
            documentName: document.name,
          }),
          severity: "success",
        }),
      );

      onClose();
    } catch {
      showAlert({
        message: t("notifications.errorAdded", {
          ns: "HAQ",
        }),
        severity: "error",
      });
    }
  };

  const cancelButton = (
    <Button
      key="cancel"
      data-qaid="add-project-documents-cancel-button"
      variant="text"
      onClick={onClose}
    >
      {t("button.cancel", { ns: "common" })}
    </Button>
  );

  const confirmButton = (
    <Button
      color="primary"
      key="done"
      data-qaid="add-project-documents-add-button"
      variant="contained"
      disabled={!documentsForSubmission.length}
      onClick={confirmChanges}
    >
      {t("button.add", { ns: "common" })}
    </Button>
  );

  return (
    <Modal
      data-qaid="add-project-documents-modal"
      sx={styles.modal}
      open={isOpen}
      title={t("viewPage.component.attachments.attachProjectDocuments", {
        ns: "HAQ",
      })}
    >
      <Typography variant="body2" sx={styles.information}>
        {t("viewPage.component.attachments.information", { ns: "HAQ" })}
      </Typography>
      {isError && (
        <Alert severity="error">{t("documents.failedLoadDocumentList")}</Alert>
      )}
      {isLoading && <Loading sx={styles.loading} />}
      {!isLoading && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
        >
          <TableFilter
            keywordFilterValue={keywordParam.keyword || ""}
            onKeywordChange={onKeywordChange}
            filterByKeywordLabel={t("keywordFilter.filterByKeyword", {
              ns: "common",
            })}
            errorAdornmentAriaLabel={t("ariaLabels.textFieldError", {
              ns: "common",
            })}
          />
        </Stack>
      )}
      {isNoResults && (
        <NoDataFallback
          sx={{ mt: 2 }}
          message={t("documents.noDocumentsFound")}
        />
      )}
      {hasResults && (
        <AddProjectDocumentsTable
          documents={documents}
          attachedDocuments={attachedDocuments}
          pagination={pagination}
          setPagination={setPagination}
          totalElements={totalElements}
          onDocumentForSubmissionChange={setDocumentsForSubmission}
        />
      )}
      <ModalActionButtons buttons={[cancelButton, confirmButton]} />
    </Modal>
  );
};

export default AddProjectDocuments;
