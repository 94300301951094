import { useTranslation } from "react-i18next";
import { QueryObserverOptions, useQuery } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import {
  IDocumentsResponseData,
  IGetDocumentsRequestParams,
} from "models/documents.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export default function useDocuments(
  params?: IGetDocumentsRequestParams,
  options?: QueryObserverOptions<IDocumentsResponseData, Error>,
) {
  const { t } = useTranslation("projects", {
    keyPrefix: "notifications",
  });

  const { data, status, error, isLoading, isError, isSuccess } = useQuery<
    IDocumentsResponseData,
    Error
  >(
    [QueryAPIKey.GetDocuments, params],
    () => services.documents.getDocuments(params).then((res) => res.data),
    {
      enabled: !!params?.projectIds,
      ...options,
    },
  );

  useShowAlert({
    status,
    failureMessage: t("getProjectDocumentsFailure"),
  });

  const { content, ...pagination } = { ...data, content: data?.content };

  return {
    documents: content,
    pagination,
    isLoading,
    isError,
    isSuccess,
    status,
    error,
  };
}
