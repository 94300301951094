import { useCallback } from "react";
import useDeleteAsset from "hooks/api/REST/assets/useDeleteAsset";
import { useFetchDocuments } from "hooks/api/REST/documents/useDocuments";
import { IDocument } from "models/documents.models";
import { MAX_SIZE } from "utils/constants/api.constants";
import { DocumentSubtype } from "utils/constants/doc.constants";

const useDeletePreviouslySubmittedLOAs = (projectId: string) => {
  const { fetchDocuments } = useFetchDocuments({
    assetTypes: DocumentSubtype.LOA,
    projectIds: projectId,
    size: MAX_SIZE,
  });

  const { mutateAsync: deleteDocument } = useDeleteAsset();

  const deleteLOAs = useCallback(
    async (tenantId: string) => {
      const documents = await fetchDocuments();

      return Promise.all(
        documents
          .filter(
            (document: IDocument) =>
              document.metadata?.applicableHealthAuthorityTenantId === tenantId,
          )
          .map((document: IDocument) => deleteDocument(document?.id)),
      );
    },
    [deleteDocument, fetchDocuments],
  );

  return { deleteLOAs };
};

export default useDeletePreviouslySubmittedLOAs;
