import { TFunction } from "i18next";
import { z } from "zod";
import { HAQFieldName } from "utils/constants/HAQ.constants";
import { TOption } from "utils/types";
import {
  getGenericFutureDateConstraint,
  MEMBER_SCHEMA,
} from "utils/validators/constraints/validationConstraints";

export type TRequestHAQExternalReviewForm = {
  [HAQFieldName.DueDate]: Date | null;
  [HAQFieldName.AdditionalInstructions]: string;
  [HAQFieldName.HARecipients]: TOption<string>[];
};

export const requestHAQExternalReviewDefaultValues = {
  [HAQFieldName.DueDate]: null,
  [HAQFieldName.AdditionalInstructions]: "",
  [HAQFieldName.HARecipients]: [],
};

export const getRequestHAQExternalReviewSchema = (t: TFunction) =>
  z.object({
    [HAQFieldName.DueDate]: getGenericFutureDateConstraint(t),
    [HAQFieldName.AdditionalInstructions]: z.string().optional(),
    [HAQFieldName.HARecipients]: z.array(MEMBER_SCHEMA).min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
  });
