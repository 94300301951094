import { IDocument } from "models/documents.models";

export enum ChangeDocumentNameFields {
  DossierName = "dossierName",
}

export type TChangeDueDateForm = {
  [ChangeDocumentNameFields.DossierName]: string;
};

export type TChangeDocumentNameProps = {
  onClose: () => void;
  document: IDocument | null;
};
