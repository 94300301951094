import { SyntheticEvent, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Box, Divider, Stack } from "@mui/material";
import ButtonLink from "components/shared/ButtonLink";
import Loading from "components/shared/Loading";
import { useProject } from "context/ProjectContext";
import useDocuments from "hooks/api/REST/documents/useDocuments";
import { IDocument } from "models/documents.models";
import { DocumentStatus, DocumentSubtype } from "utils/constants/doc.constants";
import DocumentsOptions from "./DocumentsOptions";
import styles from "./DocumentsRadioList.styles";

type TDocument = IDocument;

interface IDocumentRadioListProps {
  selectedDocument: TDocument | null;
  onSelectDocument: (document: TDocument | null) => void;
  onChooseImportFile?: (event?: SyntheticEvent) => void;
}

const DocumentsRadioList = ({
  onSelectDocument,
  onChooseImportFile,
  selectedDocument,
}: IDocumentRadioListProps) => {
  const { project } = useProject();

  const { documents, isError, isLoading, isSuccess, error } = useDocuments({
    assetTypes: DocumentSubtype.AssessmentAidSource,
    projectIds: project?.id,
  });

  const { t } = useTranslation("documents");

  const handleChange = useCallback(
    (selectedDocument: TDocument) => {
      onSelectDocument(selectedDocument);
    },
    [onSelectDocument],
  );

  const filteredDocuments = useMemo(
    () =>
      documents?.filter(
        (document) => document.status === DocumentStatus.Uploaded,
      ),
    [documents],
  );

  useEffect(() => {
    onSelectDocument(documents?.length ? documents[0] : null);
  }, [onSelectDocument, documents]);

  return (
    <Stack gap={1.5}>
      {onChooseImportFile && (
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
        >
          <ButtonLink disabled>
            {t("importDocument.browseFilesAction")}
          </ButtonLink>
          <ButtonLink onClick={onChooseImportFile}>
            {t("importDocument.importFilesAction")}
          </ButtonLink>
        </Stack>
      )}
      {isError && (
        <Alert severity="error">
          {error?.message || t("documents.failedLoadDocumentList")}
        </Alert>
      )}
      {isLoading && <Loading sx={styles.loading} />}
      {isSuccess &&
        (Boolean(documents?.length) ? (
          <DocumentsOptions
            documents={filteredDocuments || []}
            onChange={handleChange}
            selectedDocument={selectedDocument}
          />
        ) : (
          <Box>{t("importDocument.noDocuments")}</Box>
        ))}
    </Stack>
  );
};

export default DocumentsRadioList;
