import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/shared/Modal";
import OrganizationForm from "../OrganizationForm";
import {
  CreateOrganizationStep,
  OrganizationType,
} from "./CreateOrganizationModal.types";
import OrganizationTypeStep from "./OrganizationTypeStep";
import styles from "./CreateOrganizationModal.styles";

type TCreateOrganizationModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const CreateOrganizationModal = ({
  onClose,
  isOpen,
}: TCreateOrganizationModalProps) => {
  const { t } = useTranslation(["administration", "common"]);

  const [step, setStep] = useState(CreateOrganizationStep.OrganizationType);

  const [organizationType, setOrganizationType] =
    useState<OrganizationType | null>(null);

  useEffect(() => {
    return () => {
      if (isOpen) {
        setStep(CreateOrganizationStep.OrganizationType);
        setOrganizationType(null);
      }
    };
  }, [isOpen]);

  const handleNext = () => setStep(CreateOrganizationStep.OrganizationForm);

  const handleCancel = () => onClose();

  const modalTitle = useMemo(() => {
    if (organizationType) {
      return organizationType === OrganizationType.Affiliated
        ? t("createOrganizationalModal.titleAffiliated")
        : t("createOrganizationalModal.titleCorporate");
    }

    return t("createOrganizationalModal.title");
  }, [organizationType, t]);

  return (
    <Modal
      data-testid="create-new-organization-modal"
      data-qaid="create-new-project-modal"
      title={modalTitle}
      open={isOpen}
      sx={
        step === CreateOrganizationStep.OrganizationType
          ? styles.organizationTypeModal
          : null
      }
    >
      {step === CreateOrganizationStep.OrganizationType && (
        <OrganizationTypeStep
          organizationType={organizationType}
          onSelectType={setOrganizationType}
          handleCancel={handleCancel}
          handleNext={handleNext}
        />
      )}

      {step === CreateOrganizationStep.OrganizationForm && organizationType && (
        <OrganizationForm type={organizationType} onClose={onClose} />
      )}
    </Modal>
  );
};

export default CreateOrganizationModal;
