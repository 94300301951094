import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const documentSidebarWidth = 400;
const sidebarPanelWidth = 50;

const sidebarPanelButton = {
  width: 44,
  height: 44,
  mt: 0.5,
};

const sidebar = {
  overflow: "hidden",
  flex: `0 0 auto`,
  width: documentSidebarWidth,
  transition: "margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
};

const styles: TStyles = {
  sidebarWide: {
    ...sidebar,
    ml: 0,
  },

  sidebarNarrow: {
    ...sidebar,
    ml: `calc(${sidebarPanelWidth}px - ${documentSidebarWidth}px)`,
  },

  sidebarPanel: {
    flex: `0 0 ${sidebarPanelWidth}px`,
    backgroundColor: getPaletteColor("common.white"),
    borderLeft: getBorderStyle("1px", "solid", "divider"),
    pt: 1,
    alignItems: "center",
    position: "relative",
    zIndex: 1,
  },

  sidebarSlide: {
    pt: 2,
    pl: 2,
    flexGrow: 1,
    height: "100%",
  },

  sidebarContent: {
    overflow: "hidden",
    flexGrow: 1,
  },

  sidebarPanelButton,

  sidebarPanelButtonActive: {
    ...sidebarPanelButton,
    backgroundColor: getPaletteColor("primary.50"),
  },

  sidebarComments: {
    pt: 2,
    pr: 2,
    height: "100%",
    overflow: "auto",
  },

  sidebarCommentsHidden: {
    p: 0,
    height: 0,
    overflow: "hidden",
    visibility: "hidden",
  },

  sidebarHeader: {
    display: "flex",
    alignItems: "center",
    pb: 1,
    borderBottom: getBorderStyle("1px", "solid", "divider"),
    justifyContent: "space-between",
  },

  sidebarHeaderTitle: {
    flex: 1,
    textTransform: "uppercase",
  },
};

export default styles;
