import { actionButtonsHeight } from "components/shared/Modal/Modal.styles";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  MuiDialogActions: {
    height: actionButtonsHeight,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
};

export default styles;
