import { createContext, useContext } from "react";
import {
  TUseDocumentOutlineActionsValue,
  useDocumentOutlineActions,
} from "./DocumentOutlineActions";

type TDocumentSidebarContextProviderProps = {
  children: React.ReactNode;
};

type TDocumentSidebarContextValue = TUseDocumentOutlineActionsValue;

const DocumentSidebarContext = createContext<
  TDocumentSidebarContextValue | undefined
>(undefined);

const DocumentSidebarContextProvider = (
  props: TDocumentSidebarContextProviderProps,
) => {
  const documentOutlineActions = useDocumentOutlineActions();

  return (
    <DocumentSidebarContext.Provider value={documentOutlineActions}>
      {props.children}
    </DocumentSidebarContext.Provider>
  );
};

export const useDocumentSidebarContext = (): TDocumentSidebarContextValue => {
  const context = useContext(DocumentSidebarContext);

  if (context === undefined) {
    throw new Error(
      "useDocumentSidebarContext must be used within a DocumentSidebarContextProvider",
    );
  }

  return context;
};

export default DocumentSidebarContextProvider;
