import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  errorIcon: {
    color: getPaletteColor("grey.400"),
  },
};

export default styles;
