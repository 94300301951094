import { useFeatureFlags } from "context/FeatureFlagsContext";
import { DocumentSubtype } from "utils/constants/doc.constants";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import { ProjectType } from "utils/constants/project.constants";
import { includeInArray } from "utils/helpers";

const useSupportedDocumentSubtypes = (
  projectType: ProjectType | undefined,
): DocumentSubtype[] => {
  const featureFlags = useFeatureFlags();

  const isHAUnstructuredContentEnabled =
    featureFlags["FTE-23951_enableHAUnstructuredContent"] === FeatureFlag.On;

  const isECTDEnabled =
    featureFlags["FTE-23984_enableDossierUploadCard"] === FeatureFlag.On;

  switch (projectType) {
    case ProjectType.Reliance:
      return [
        DocumentSubtype.AssessmentReport,
        DocumentSubtype.HADecisionLetter,
        DocumentSubtype.ProjectParticipants,
        DocumentSubtype.SupportingInformation,
        ...includeInArray(
          [
            DocumentSubtype.InspectionReports,
            DocumentSubtype.CoverLetter,
            DocumentSubtype.ApplicantsStrategy,
          ],
          isHAUnstructuredContentEnabled,
        ).flat(),
        ...includeInArray(DocumentSubtype.ECTD, isECTDEnabled),
      ];

    case ProjectType.RegulatoryReview:
      return [
        DocumentSubtype.AssessmentReport,
        DocumentSubtype.HADecisionLetter,
        DocumentSubtype.ProjectParticipants,
        DocumentSubtype.SupportingInformation,
        ...includeInArray(
          [
            DocumentSubtype.InspectionReports,
            DocumentSubtype.CoverLetter,
            DocumentSubtype.ApplicantsStrategy,
          ],
          isHAUnstructuredContentEnabled,
        ).flat(),
        ...includeInArray(DocumentSubtype.ECTD, isECTDEnabled),
      ];

    case ProjectType.Orbis:
      return [
        DocumentSubtype.AssessmentAid,
        DocumentSubtype.GSP,
        DocumentSubtype.LOA,
        DocumentSubtype.References,
        DocumentSubtype.SupportingInformation,
      ];

    default:
      return [];
  }
};

export default useSupportedDocumentSubtypes;
