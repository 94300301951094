import { assetsBasePart } from "./constants";

export default {
  languages: () => `${assetsBasePart}/languages`,
  domains: () => `${assetsBasePart}/domains`,
  types: (domainId: string) =>
    `${assetsBasePart}/domains/${domainId}/document-types`,
  comments: {
    thread: (componentId: string, threadId: string) =>
      `${assetsBasePart}/annotations/${componentId}/threads/${threadId}`,
    byThreadId: (componentId: string, threadId: string) =>
      `${assetsBasePart}/annotations/${componentId}/threads/${threadId}/comments`,
    byId: (componentId: string, threadId: string, commentId: string) =>
      `${assetsBasePart}/annotations/${componentId}/threads/${threadId}/comments/${commentId}`,
  },
  suggestions: {
    byId: (componentId: string, suggestionId: string) =>
      `${assetsBasePart}/annotations/${componentId}/suggestions/${suggestionId}`,
    all: (componentId: string) =>
      `${assetsBasePart}/annotations/${componentId}/suggestions`,
  },
};
