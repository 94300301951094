import { Palette, Theme } from "@mui/material/styles";
import get from "lodash/get";
import { TNestedKeyOf } from "utils/types";

type TPaletteColorsOnly = Omit<
  Required<Palette>,
  "mode" | "tonalOffset" | "contrastThreshold" | "action"
> & {
  action: Omit<
    Palette["action"],
    | "hoverOpacity"
    | "selectedOpacity"
    | "disabledOpacity"
    | "focusOpacity"
    | "activatedOpacity"
  >;
};

export const getPaletteColor =
  (key: TNestedKeyOf<TPaletteColorsOnly>) => (theme: Theme) =>
    get(theme.palette, key, "initial");

export const getBorderStyle =
  (width: string, style: string, key: TNestedKeyOf<TPaletteColorsOnly>) =>
  (theme: Theme) =>
    `${width} ${style} ${getPaletteColor(key)(theme)}`;
