import { TFunction } from "i18next";
import { z } from "zod";

export enum DossierDifferencesModalFields {
  SummaryOfDifferences = "summaryOfDifferences",
  Sections = "sections",
  HealthAuthorities = "healthAuthorities",
  DossierDifferenceId = "dossierDifferenceId",
}

export type TDossierDifferencesModalForm = {
  [DossierDifferencesModalFields.SummaryOfDifferences]: string;
  [DossierDifferencesModalFields.Sections]: string[];
  [DossierDifferencesModalFields.HealthAuthorities]: {
    label: string;
    value: string;
  }[];
};

export type TDossierDifferencesModalFormEdit = TDossierDifferencesModalForm & {
  [DossierDifferencesModalFields.DossierDifferenceId]: string;
};

export const DossierDifferencesModalFormInitialValues: TDossierDifferencesModalForm =
  {
    [DossierDifferencesModalFields.SummaryOfDifferences]: "",
    [DossierDifferencesModalFields.Sections]: [],
    [DossierDifferencesModalFields.HealthAuthorities]: [],
  };

const healthAuthoritiesSchema = z.object({
  label: z.string(),
  value: z.string(),
});

export const getDossierDifferenceFormSchema = (t: TFunction) =>
  z.object({
    [DossierDifferencesModalFields.SummaryOfDifferences]: z
      .string()
      .max(500, {
        message: `${t("validations.maxTextField", { ns: "common" })}500`,
      })
      .min(1, {
        message: t("validations.requiredField", { ns: "common" }),
      }),
    [DossierDifferencesModalFields.Sections]: z
      .array(z.string())
      .min(1, { message: t("dossierDifferences.moreSections") }),
    [DossierDifferencesModalFields.HealthAuthorities]: z
      .array(healthAuthoritiesSchema)
      .min(1, { message: t("dossierDifferences.addMoreHA") }),
    [DossierDifferencesModalFields.DossierDifferenceId]: z.string().optional(),
  });
