import { TStyles } from "utils/Styles";

const styles: TStyles = {
  pdfObject: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },

  documentContainer: {
    position: "relative",
    flexGrow: 1,
  },

  modal: {
    "& .MuiDialog-paper": {
      minWidth: "70%",
      minHeight: "90%",
      maxWidth: "unset",
      display: "flex",
      flexDirection: "column",
    },

    "& .MuiDialogContent-root": {
      flex: 1,
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      p: 0,
    },

    p: 0,
  },
};

export default styles;
