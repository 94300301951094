import { useState } from "react";
import { Stack } from "@mui/material";
import { Loading } from "components/shared";
import useEntityOrganizations from "hooks/api/GQL/entityManagement/useEntityOrganizations";
import CreateOrganizationModal from "./CreateOrganizationModal/CreateOrganizationModal";
import OrganizationsTable from "./OrganizationsTable";

export const Organization = () => {
  const [
    shouldShowCreateOrganizationModal,
    setShouldShowCreateOrganizationModal,
  ] = useState(false);

  const { organizations, isLoading } = useEntityOrganizations();

  const onClickCreateOrganization = () => {
    setShouldShowCreateOrganizationModal(true);
  };

  return (
    <Stack direction="column" spacing={2}>
      {isLoading ? (
        <Loading />
      ) : (
        <OrganizationsTable
          onClickCreateOrganization={onClickCreateOrganization}
          organizations={organizations || []}
        />
      )}
      <CreateOrganizationModal
        isOpen={shouldShowCreateOrganizationModal}
        onClose={() => setShouldShowCreateOrganizationModal(false)}
      />
    </Stack>
  );
};

export default Organization;
