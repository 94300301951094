import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  editorBar: {
    backgroundColor: getPaletteColor("common.white"),
    p: (theme) => theme.spacing(0.3, 4, 0.5, 4),
    boxShadow: (theme) => theme.shadows[4],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: getBorderStyle("2px", "solid", "blueGrey.50"),
    position: "relative",
    zIndex: 100,
  },

  componentName: {
    backgroundColor: getPaletteColor("custom.selected"),
    fontSize: 12,
    color: getPaletteColor("text.secondary"),
  },

  tools: {
    "& .ck.ck-toolbar": {
      border: "none",
      pl: 0,
    },

    "& .ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items": {
      flexWrap: "wrap",
    },
  },

  actions: {
    display: "flex",
    alignItems: "center",
    columnGap: "1rem",
  },

  jumpBack: {
    fontWeight: 400,
    textAlign: "center",

    "&[hidden]": {
      visibility: "hidden",
    },
  },
};

export default styles;
