import { Grid, Typography } from "@mui/material";
import { getTextValue } from "utils/helpers";
import styles from "../GSPViewItem.styles";

type TItemProps = {
  name: string;
  description?: string;
};

const ItemRow = ({ name, description }: TItemProps) => (
  <>
    <Grid item xs={6}>
      <Typography sx={styles.name}>{name}</Typography>
    </Grid>
    <Grid item xs={5}>
      <Typography sx={styles.description}>
        {getTextValue(description)}
      </Typography>
    </Grid>
  </>
);

export default ItemRow;
