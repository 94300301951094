import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Stack, Typography } from "@mui/material";
import { Button } from "components/shared";
import { useProject } from "context/ProjectContext";
import { useProjectLOASubmittedTenantIds } from "hooks/project";
import useUserRoles from "hooks/user/useUserRoles";
import useUser from "hooks/useUser";
import { useDocumentsContext } from "screens/Project/sections/Documents/DocumentsContext";
import {
  AddDocumentTeaser,
  TeaserCard,
  TeaserImgBox,
} from "screens/Project/sections/Documents/DocumentsTeasers/components";
import styles from "screens/Project/sections/Documents/DocumentsTeasers/DocumentsTeasers.styles";
import { ImportModalType } from "screens/Project/sections/Documents/modals/ImportDocument";
import { findCompletedDocument } from "screens/Project/sections/Documents/utils/documentHelper";
import getDocumentIcons from "screens/Project/sections/Documents/utils/getDocumentIcons";
import { getPaletteColor } from "theme/helpers";
import { QueryFetchingStatus } from "utils/constants/api.constants";
import { DocumentSubtype } from "utils/constants/doc.constants";
import { getHAsExceptHostHA } from "utils/helpers";
import { isSponsorUser } from "utils/user/organization";
import LOADocImg from "assets/images/loa.png";

type TLOATeaserProps = {
  onAddDocument: (type: ImportModalType | null) => void;
};

const LOATeaser = ({ onAddDocument }: TLOATeaserProps) => {
  const { t } = useTranslation(["documents", "common"]);

  const { project, participatingHAOrganizations } = useProject();

  const participatingHAOrganizationsExceptHostHA = useMemo(
    () => getHAsExceptHostHA(participatingHAOrganizations),
    [participatingHAOrganizations],
  );

  const { isProjectManager } = useUserRoles(project?.id);

  const { requiredDocuments } = useDocumentsContext();

  const isLoading = requiredDocuments.status === QueryFetchingStatus.Loading;

  const document = useMemo(
    () => findCompletedDocument(requiredDocuments.data, DocumentSubtype.LOA),
    [requiredDocuments.data],
  );

  const { loaSubmittedTenantIds } = useProjectLOASubmittedTenantIds(
    project?.id,
  );

  const quantityHealthAuthoritiesApprovedDoc = loaSubmittedTenantIds?.length;

  const quantityHealthAuthorities =
    participatingHAOrganizationsExceptHostHA?.length;

  const allHealthAuthoritiesApproveDoc =
    quantityHealthAuthoritiesApprovedDoc === quantityHealthAuthorities;

  const { organization } = useUser();

  if (!isSponsorUser(organization)) {
    return null;
  }

  const AddButton = isProjectManager && quantityHealthAuthorities > 0 && (
    <Button
      variant="outlined"
      onClick={() => onAddDocument(ImportModalType.SAL)}
    >
      {t("button.add", { ns: "common" })}
    </Button>
  );

  if (isLoading) {
    return (
      <TeaserCard
        title={t("documents.teaser.loaTitle")}
        imgBox={<TeaserImgBox isLoading={true} img={LOADocImg} />}
        description={
          <Typography
            variant="badgeLabel"
            color={getPaletteColor("text.secondary")}
          >
            {t("teaserCard.loading")}
          </Typography>
        }
      />
    );
  }

  if (document) {
    return (
      <TeaserCard
        title={t("documents.teaser.loaTitle")}
        imgBox={<TeaserImgBox isLoading={false} img={LOADocImg} />}
        description={
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography
              variant="badgeLabel"
              color={getPaletteColor("text.secondary")}
            >
              {t("partOfTotal", {
                part: quantityHealthAuthoritiesApprovedDoc,
                total: quantityHealthAuthorities,
                ns: "common",
              })}
            </Typography>
            {allHealthAuthoritiesApproveDoc && (
              <CheckCircleRoundedIcon
                fontSize="small"
                sx={styles.successIcon}
                aria-label={t("teaserCard.completeStatusAriaLabel")}
              />
            )}
          </Stack>
        }
      >
        {AddButton}
      </TeaserCard>
    );
  }

  return (
    <AddDocumentTeaser
      data-qaid="add-loa-teaser"
      icon={getDocumentIcons(t, "info").attachment}
      title={t("documents.teaser.loaTitle")}
      description={
        <>
          <Typography variant="caption" sx={styles.textCenter}>
            {t("documents.teaser.loaDescription")}
          </Typography>
          <Typography variant="caption" sx={styles.errorText}>
            {t("partOfTotal", {
              part: quantityHealthAuthoritiesApprovedDoc,
              total: quantityHealthAuthorities,
              ns: "common",
            })}
          </Typography>
        </>
      }
    >
      {AddButton}
    </AddDocumentTeaser>
  );
};

export default LOATeaser;
