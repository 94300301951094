import { TStyles } from "utils/Styles";

const styles: TStyles = {
  selectAll: {
    cursor: "pointer",
    py: 0,
  },

  optionItem: {
    py: 0,
  },
};

export default styles;
