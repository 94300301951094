import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/material";
import { FormSwitch, FormTextInput } from "components/common/form-fields";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import {
  IFeatureFlag,
  IUpdateFeatureFlagRequestParams,
} from "models/featureFlags.models";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import {
  FeatureFlagModalField,
  getFlagFromSchema,
  TFeatureFlagModalForm,
} from "../FeatureFlagModal.types";

type TEditFlagProps = {
  onClose: () => void;
  currentFlag: IFeatureFlag;
  updateFlag: ({
    name,
    value,
    description,
  }: IUpdateFeatureFlagRequestParams) => void;
};

const isBoolean = (value: string) =>
  value === FeatureFlag.On || value === FeatureFlag.Off;

const getDefaultValues = (
  { name, description, value }: IFeatureFlag,
  isBooleanValue: boolean,
) => ({
  name,
  description: description || "",
  isActive: isBooleanValue && value === FeatureFlag.On,
  isValueString: !isBooleanValue,
  value: !isBooleanValue ? value : "",
});

export const EditFlag = (props: TEditFlagProps) => {
  const isCurrentValueBoolean = useMemo(
    () => isBoolean(props.currentFlag.value),
    [props.currentFlag],
  );

  const { t } = useTranslation(["featureFlags", "common"]);

  const defaultValues = useMemo(
    () => getDefaultValues(props.currentFlag, isCurrentValueBoolean),
    [props.currentFlag, isCurrentValueBoolean],
  );

  const methods = useForm<TFeatureFlagModalForm>({
    mode: "all",
    defaultValues,
    resolver: zodResolver(getFlagFromSchema(t, props.currentFlag.value)),
  });

  const { handleSubmit, watch, formState } = methods;

  const isValueString = watch(FeatureFlagModalField.IsValueString);

  const onSubmit = async (data: TFeatureFlagModalForm) => {
    const flagValue = isValueString
      ? data[FeatureFlagModalField.Value]
      : data[FeatureFlagModalField.IsActive];

    props.updateFlag({
      name: props.currentFlag.name,
      value: `${flagValue}`,
      description: data[FeatureFlagModalField.Description],
    });
    props.onClose();
  };

  const closeButton = (
    <Button variant="outlined" key="close" onClick={props.onClose}>
      {t("button.close", { ns: "common" })}
    </Button>
  );

  const saveFlagButton = (
    <Button
      disabled={!formState.isValid}
      variant="contained"
      key="save-flag"
      type="submit"
    >
      {t("button.save", { ns: "common" })}
    </Button>
  );

  return (
    <Modal open title={t("editFlag.modalTitle")}>
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <FormTextInput
            name={FeatureFlagModalField.Name}
            label={t("editFlag.controllerFlagNameLabel")}
            disabled={true}
          />
          <FormTextInput
            name={FeatureFlagModalField.Description}
            label={t("editFlag.controllerFlagDescriptionLabel")}
            multiline
            rows={3}
          />
          <FormSwitch
            name={FeatureFlagModalField.IsValueString}
            label={t("editFlag.valueTypeLabel")}
          />
          {isValueString ? (
            <FormTextInput
              name={FeatureFlagModalField.Value}
              label={t("editFlag.controllerFlagValueLabel")}
            />
          ) : (
            <FormSwitch
              name={FeatureFlagModalField.IsActive}
              label={t("editFlag.activeLabel")}
            />
          )}
        </Stack>
        <ModalActionButtons buttons={[closeButton, saveFlagButton]} />
      </FormWrapper>
    </Modal>
  );
};

export default EditFlag;
