import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { gql } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { ICountriesListResponse } from "./useCountriesList.models";

export const query = gql`
  query CountryTypes {
    countryTypes {
      id
      name
      code
    }
  }
`;

const useCountriesList = (enabled: boolean = true) => {
  const { t } = useTranslation(["administration", "countries"]);
  const { showAlert } = useAlerts();

  const { data, isLoading, isSuccess } = useQuery<ICountriesListResponse>(
    [QueryAPIKey.GetCountriesList],
    () =>
      services.graphQL.request<ICountriesListResponse>(query).catch((error) => {
        showAlert({
          severity: AlertSeverity.Error,
          message: t("notifications.getCountriesListFailure"),
        });
        throw error;
      }),
    {
      enabled,
    },
  );

  return {
    data:
      data?.countryTypes?.map((country) => ({
        ...country,
        name: t(country?.code?.toLocaleLowerCase(), { ns: "countries" }),
      })) ?? [],
    isLoading,
    isSuccess,
  };
};

export default useCountriesList;
