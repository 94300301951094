import { saveAs } from "file-saver";
import { IDocument } from "models/documents.models";
import { EventType } from "models/tracking.models";
import { services } from "services";
import { AssetType } from "utils/constants/assets.constants";
import { ALLOWED_DOCS } from "utils/constants/doc.constants";

const useDownloadBlob = (document: IDocument) => {
  return async () => {
    const res = await services.assets.getAssetContent(document?.id ?? "", {
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    });

    const { data } = res;
    const fileType = data?.type ?? "";

    const fileExtension =
      ALLOWED_DOCS.find((doc) => doc.fileType === fileType)?.fileType || "pdf";

    const filename = `${document.name}.${fileExtension}`;

    saveAs(res.data, filename);

    services.tracking.addTrackingEvent({
      assetId: document.id,
      assetType: AssetType.Document,
      eventType: EventType.AppContentDownload,
      projectId: document.projectIds?.[0] || "",
    });
  };
};

export default useDownloadBlob;
