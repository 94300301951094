export type TCTOCItem = {
  id: string;
  name: string;
  children?: TCTOCItem[];
};

export type TCTOCDocumentModule = {
  id: string;
  name: string;
  children: TCTOCItem[];
};

export const getCTOCDocument = (): TCTOCDocumentModule[] => [
  {
    id: "1",
    name: "Module 1 Administrative information",
    children: [
      {
        id: "1.1",
        name: "1.1 Forms",
      },
      {
        id: "1.2",
        name: "1.2 Cover letters",
      },
      {
        id: "1.3",
        name: "1.3 Administrative information",
        children: [
          {
            id: "1.3.1",
            name: "1.3.1 Contact/sponsor/applicant information",
            children: [
              {
                id: "1.3.1.1",
                name: "1.3.1.1 Change of address or corporate name",
              },
              {
                id: "1.3.1.2",
                name: "1.3.1.2 Change in contact/agent",
              },
              {
                id: "1.3.1.3",
                name: "1.3.1.3 Change in sponsor",
              },
              {
                id: "1.3.1.4",
                name: "1.3.1.4 Transfer of obligation",
              },
              {
                id: "1.3.1.5",
                name: "1.3.1.5 Change in ownership of an application or reissuance of license",
              },
            ],
          },
          {
            id: "1.3.2",
            name: "1.3.2 Field copy certification",
          },
          {
            id: "1.3.3",
            name: "1.3.3 Debarment certification",
          },
          {
            id: "1.3.4",
            name: "1.3.4 Financial certification and disclosure",
          },
          {
            id: "1.3.5",
            name: "1.3.5 Patent and exclusivity",
            children: [
              {
                id: "1.3.5.1",
                name: "1.3.5.1 Patent information",
              },
              {
                id: "1.3.5.2",
                name: "1.3.5.2 Patent certification",
              },
              {
                id: "1.3.5.3",
                name: "1.3.5.3 Exclusivity claim",
              },
            ],
          },
          {
            id: "1.3.6",
            name: "1.3.6 Tropical disease priority review voucher",
          },
        ],
      },
      {
        id: "1.4",
        name: "1.4 References",
        children: [
          {
            id: "1.4.1",
            name: "1.4.1 Letter of authorization",
          },
          {
            id: "1.4.2",
            name: "1.4.2 Statement of right of reference",
          },
          {
            id: "1.4.3",
            name: "1.4.3 List of authorized persons to incorporate by reference",
          },
          {
            id: "1.4.4",
            name: "1.4.4 Cross-reference to previously submitted information",
          },
        ],
      },
      {
        id: "1.5",
        name: "1.5 Application status",
        children: [
          {
            id: "1.5.1",
            name: "1.5.1 Withdrawal of an IND",
          },
          {
            id: "1.5.2",
            name: "1.5.2 Inactivation request",
          },
          {
            id: "1.5.3",
            name: "1.5.3 Reactivation request",
          },
          {
            id: "1.5.4",
            name: "1.5.4 Reinstatement request",
          },
          {
            id: "1.5.5",
            name: "1.5.5 Withdrawal of an unapproved BLA, NDA, ANDA, or Supplement",
          },
          {
            id: "1.5.6",
            name: "1.5.6 Withdrawal of listed drug",
          },
          {
            id: "1.5.7",
            name: "1.5.7 Withdrawal of approval of an application or revocation of license",
          },
        ],
      },
      {
        id: "1.6",
        name: "1.6 Meetings",
        children: [
          {
            id: "1.6.1",
            name: "1.6.1 Meeting request",
          },
          {
            id: "1.6.2",
            name: "1.6.2 Meeting background materials",
          },
          {
            id: "1.6.3",
            name: "1.6.3 Correspondence regarding meetings",
          },
        ],
      },
      {
        id: "1.7",
        name: "1.7 Fast track",
        children: [
          {
            id: "1.7.1",
            name: "1.7.1 Fast track designation request",
          },
          {
            id: "1.7.2",
            name: "1.7.2 Fast track designation withdrawal request",
          },
          {
            id: "1.7.3",
            name: "1.7.3 Rolling review request",
          },
          {
            id: "1.7.4",
            name: "1.7.4 Correspondence regarding fast track/rolling review",
          },
        ],
      },
      {
        id: "1.8",
        name: "1.8 Special protocol assessment request",
        children: [
          {
            id: "1.8.1",
            name: "1.8.1 Clinical study",
          },
          {
            id: "1.8.2",
            name: "1.8.2 Carcinogenicity study",
          },
          {
            id: "1.8.3",
            name: "1.8.3 Stability study",
          },
          {
            id: "1.8.4",
            name: "1.8.4 Animal efficacy study for approval under the animal rule",
          },
        ],
      },
      {
        id: "1.9",
        name: "1.9 Pediatric administrative information",
        children: [
          {
            id: "1.9.1",
            name: "1.9.1 Request for waiver of pediatric studies",
          },
          {
            id: "1.9.2",
            name: "1.9.2 Request for deferral of pediatric studies",
          },
          {
            id: "1.9.3",
            name: "1.9.3 Request for pediatric exclusivity determination",
          },
          {
            id: "1.9.4",
            name: "1.9.4 Proposed pediatric study request and amendments",
          },
          {
            id: "1.9.5",
            name: "1.9.5 Proposal for written agreement (no longer applicable)",
          },
          {
            id: "1.9.6",
            name: "1.9.6 Other correspondence regarding pediatric exclusivity or study plans",
          },
        ],
      },
      {
        id: "1.10",
        name: "1.10 Dispute resolution",
        children: [
          {
            id: "1.10.1",
            name: "1.10.1 Request for dispute resolution",
          },
          {
            id: "1.10.2",
            name: "1.10.2 Correspondence related to dispute resolution",
          },
        ],
      },
      {
        id: "1.11",
        name: "1.11 Information amendment: Information not covered under modules 2 to 5",
        children: [
          {
            id: "1.11.1",
            name: "1.11.1 Quality information amendment",
          },
          {
            id: "1.11.2",
            name: "1.11.2 Nonclinical information amendment",
          },
          {
            id: "1.11.3",
            name: "1.11.3 Clinical information amendment",
          },
          {
            id: "1.11.4",
            name: "1.11.4 Multiple module information amendment",
          },
        ],
      },
      {
        id: "1.12",
        name: "1.12 Other correspondence",
        children: [
          {
            id: "1.12.1",
            name: "1.12.1 Pre IND correspondence",
          },
          {
            id: "1.12.2",
            name: "1.12.2 Request to charge for clinical trial",
          },
          {
            id: "1.12.3",
            name: "1.12.3 Request to charge for expanded access",
          },
          {
            id: "1.12.4",
            name: "1.12.4 Request for comments and advice",
          },
          {
            id: "1.12.5",
            name: "1.12.5 Request for a waiver",
          },
          {
            id: "1.12.6",
            name: "1.12.6 Exception from informed consent for emergency research",
          },
          {
            id: "1.12.7",
            name: "1.12.7 Public disclosure statement for exception from informed\nconsent for emergency research",
          },
          {
            id: "1.12.8",
            name: "1.12.8 Correspondence regarding exception from informed consent for emergency research",
          },
          {
            id: "1.12.9",
            name: "1.12.9 Notification of discontinuation of clinical trial",
          },
          {
            id: "1.12.10",
            name: "1.12.10 Generic drug enforcement actstatement",
          },
          {
            id: "1.12.11",
            name: "1.12.11 ANDA basis for submission statement",
          },
          {
            id: "1.12.12",
            name: "1.12.12 Comparison of generic drug and reference listed drug",
          },
          {
            id: "1.12.13",
            name: "1.12.13 Request for waiver for in vivo studies",
          },
          {
            id: "1.12.14",
            name: "1.12.14 Environmental analysis",
          },
          {
            id: "1.12.15",
            name: "1.12.15 Request for waiver of in vivo bioavailability studies",
          },
          {
            id: "1.12.16",
            name: "1.12.16 Field alert reports",
          },
          {
            id: "1.12.17",
            name: "1.12.17 Orphan drug designation",
          },
        ],
      },
      {
        id: "1.13",
        name: "1.13 Annual report",
        children: [
          {
            id: "1.13.1",
            name: "1.13.1 Summary for nonclinical studies",
          },
          {
            id: "1.13.2",
            name: "1.13.2 Summary of clinical pharmacology information",
          },
          {
            id: "1.13.3",
            name: "1.13.3 Summary of safety information",
          },
          {
            id: "1.13.4",
            name: "1.13.4 Summary of labeling changes",
          },
          {
            id: "1.13.5",
            name: "1.13.5 Summary of manufacturing changes",
          },
          {
            id: "1.13.6",
            name: "1.13.6 Summary of microbiological changes",
          },
          {
            id: "1.13.7",
            name: "1.13.7 Summary of other significant new information",
          },
          {
            id: "1.13.8",
            name: "1.13.8 Individual study information",
          },
          {
            id: "1.13.9",
            name: "1.13.9 General investigational plan",
          },
          {
            id: "1.13.10",
            name: "1.13.10 Foreign marketing",
          },
          {
            id: "1.13.11",
            name: "1.13.11 Distribution data",
          },
          {
            id: "1.13.12",
            name: "1.13.12 Status of postmarketing study commitments and requirements",
          },
          {
            id: "1.13.13",
            name: "1.13.13 Status of other postmarketing studies and requirements",
          },
          {
            id: "1.13.14",
            name: "1.13.14 Log of outstanding regulatory business",
          },
          {
            id: "1.13.15",
            name: "1.13.15 Developmentsafety update report (DSUR)",
          },
        ],
      },
      {
        id: "1.14",
        name: "1.14 Labeling",
        children: [
          {
            id: "1.14.1",
            name: "1.14.1 Draft labeling",
            children: [
              {
                id: "1.14.1.1",
                name: "1.14.1.1 Draft carton and container labels",
              },
              {
                id: "1.14.1.2",
                name: "1.14.1.2 Annotated draft labeling text",
              },
              {
                id: "1.14.1.3",
                name: "1.14.1.3 Draft labeling text",
              },
              {
                id: "1.14.1.4",
                name: "1.14.1.4 Label comprehension studies",
              },
              {
                id: "1.14.1.5",
                name: "1.14.1.5 Labeling history",
              },
            ],
          },
          {
            id: "1.14.2",
            name: "1.14.2 Final labeling",
            children: [
              {
                id: "1.14.2.1",
                name: "1.14.2.1 Final carton or container labels",
              },
              {
                id: "1.14.2.2",
                name: "1.14.2.2 Final package insert (package inserts, patient information, medication guides)",
              },
              {
                id: "1.14.2.3",
                name: "1.14.2.3 Final labeling text",
              },
            ],
          },
          {
            id: "1.14.3",
            name: "1.14.3 Listed drug labeling",
            children: [
              {
                id: "1.14.3.1",
                name: "1.14.3.1 Annotated comparison with listed drug",
              },
              {
                id: "1.14.3.2",
                name: "1.14.3.2 Approved labeling text for listed drug",
              },
              {
                id: "1.14.3.3",
                name: "1.14.3.3 Labeling text for reference listed drug",
              },
            ],
          },
          {
            id: "1.14.4",
            name: "1.14.4 Investigational drug labeling",
            children: [
              {
                id: "1.14.4.1",
                name: "1.14.4.1 Investigational brochure",
              },
              {
                id: "1.14.4.2",
                name: "1.14.4.2 Investigational drug labeling",
              },
            ],
          },
          {
            id: "1.14.5",
            name: "1.14.5 Foreign labeling",
          },
          {
            id: "1.14.6",
            name: "1.14.6 Product labeling for 2253 submissions",
          },
        ],
      },
      {
        id: "1.15",
        name: "1.15 Promotional material",
        children: [
          {
            id: "1.15.1",
            name: "1.15.1 Correspondence relating to promotional materials",
            children: [
              {
                id: "1.15.1.1",
                name: "1.15.1.1 Request for advisory comments on launch materials",
              },
              {
                id: "1.15.1.2",
                name: "1.15.1.2 Request for advisory comments on non-launch materials",
              },
              {
                id: "1.15.1.3",
                name: "1.15.1.3 Presubmission of launch promotional materials for accelerated approval products",
              },
              {
                id: "1.15.1.4",
                name: "1.15.1.4 Presubmission of non-launch promotional materials for accelerated approval products",
              },
              {
                id: "1.15.1.5",
                name: "1.15.1.5 Pre-dissemination review of television ads",
              },
              {
                id: "1.15.1.6",
                name: "1.15.1.6 Response to untitled letter or warning letter",
              },
              {
                id: "1.15.1.7",
                name: "1.15.1.7 Response to information request",
              },
              {
                id: "1.15.1.8",
                name: "1.15.1.8 Correspondence accompanying materials previously missing or rejected",
              },
              {
                id: "1.15.1.9",
                name: "1.15.1.9 Withdrawal request",
              },
              {
                id: "1.15.1.10",
                name: "1.15.1.10 Submission of annotated references",
              },
              {
                id: "1.15.1.11",
                name: "1.15.1.11 General correspondence",
              },
            ],
          },
          {
            id: "1.15.2",
            name: "1.15.2 Materials attribute",
            children: [
              {
                id: "1.15.2.1",
                name: "1.15.2.1 Material",
                children: [
                  {
                    id: "1.15.2.1.1",
                    name: "1.15.2.1.1 Clean version",
                  },
                  {
                    id: "1.15.2.1.2",
                    name: "1.15.2.1.2 Annotated version",
                  },
                  {
                    id: "1.15.2.1.3",
                    name: "1.15.2.1.3 Annotated labeling version",
                  },
                  {
                    id: "1.15.2.1.4",
                    name: "1.15.2.1.4 Annotated references",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "1.16",
        name: "1.16 Risk management plan",
        children: [
          {
            id: "1.16.1",
            name: "1.16.1 Risk Management (Non-REMS)",
          },
          {
            id: "1.16.2",
            name: "1.16.2 Risk Evaluation and Mitigation Strategy (REMS)",
            children: [
              {
                id: "1.16.2.1",
                name: "1.16.2.1 Final REMS",
              },
              {
                id: "1.16.2.2",
                name: "1.16.2.2 Draft REMS",
              },
              {
                id: "1.16.2.3",
                name: "1.16.2.3 REMS Assessment",
              },
              {
                id: "1.16.2.4",
                name: "1.16.2.4 REMS Assessment Methodology",
              },
              {
                id: "1.16.2.5",
                name: "1.16.2.5 REMS Correspondence",
              },
              {
                id: "1.16.2.6",
                name: "1.16.2.6 REMS Modification History",
              },
            ],
          },
        ],
      },
      {
        id: "1.17",
        name: "1.17 Postmarketing studies",
        children: [
          {
            id: "1.17.1",
            name: "1.17.1 Correspondence regarding postmarketing commitments",
          },
          {
            id: "1.17.2",
            name: "1.17.2 Correspondence regarding postmarketing requirements",
          },
        ],
      },
      {
        id: "1.18",
        name: "1.18 Proprietary headerTexts",
      },
      {
        id: "1.19",
        name: "1.19 Pre-EUA and EUA",
      },
      {
        id: "1.20",
        name: "1.20 General investigational plan for initialIND",
      },
    ],
  },
  {
    id: "2",
    name: "Module 2 Summaries",
    children: [
      {
        id: "2.2",
        name: "2.2 Introduction to summary",
      },
      {
        id: "2.3",
        name: "2.3 Quality overallsummary",
      },
      {
        id: "2.4",
        name: "2.4 Nonclinical overview",
      },
      {
        id: "2.5",
        name: "2.5 Clinical overview",
      },
      {
        id: "2.6",
        name: "2.6 Nonclinical written and tabulated summaries",
        children: [
          {
            id: "2.6.1",
            name: "2.6.1 Introduction",
          },
          {
            id: "2.6.2",
            name: "2.6.2 Pharmacology written summary",
          },
          {
            id: "2.6.3",
            name: "2.6.3 Pharmacology tabulated summary",
          },
          {
            id: "2.6.4",
            name: "2.6.4 Pharmacokinetic written summary",
          },
          {
            id: "2.6.5",
            name: "2.6.5 Pharmacokinetic tabulated summary",
          },
          {
            id: "2.6.6",
            name: "2.6.6 Toxicology written summary",
          },
          {
            id: "2.6.7",
            name: "2.6.7 Toxicology tabulated summary",
          },
        ],
      },
      {
        id: "2.7",
        name: "2.7 Clinicalsummary",
        children: [
          {
            id: "2.7.1",
            name: "2.7.1 Summary of Biopharmaceutic Studies and Associated Analytical Methods",
          },
          {
            id: "2.7.2",
            name: "2.7.2 Summary of Clinical Pharmacology studies",
          },
          {
            id: "2.7.4",
            name: "2.7.4 Summary of Clinical Safety",
          },
          {
            id: "2.7.5",
            name: "2.7.5 References",
          },
          {
            id: "2.7.6",
            name: "2.7.6 Synopses of individual studies",
          },
        ],
      },
    ],
  },
  {
    id: "3",
    name: "Module 3 Quality",
    children: [
      {
        id: "3.2",
        name: "3.2 Body of data",
        children: [
          {
            id: "3.2.S",
            name: "3.2.S Drug substance",
            children: [
              {
                id: "3.2.S.1",
                name: "3.2.S.1 General information",
                children: [
                  {
                    id: "3.2.S.1.1",
                    name: "3.2.S.1.1 Nomenclature",
                  },
                  {
                    id: "3.2.S.1.2",
                    name: "3.2.S.1.2 Structure",
                  },
                  {
                    id: "3.2.S.1.3",
                    name: "3.2.S.1.3 General properties",
                  },
                ],
              },
              {
                id: "3.2.S.2",
                name: "3.2.S.2 Manufacture",
                children: [
                  {
                    id: "3.2.S.2.1",
                    name: "3.2.S.2.1 Manufacturer(s)",
                  },
                  {
                    id: "3.2.S.2.2",
                    name: "3.2.S.2.2 Description of Manufacturing Process and Process Controls",
                  },
                  {
                    id: "3.2.S.2.3",
                    name: "3.2.S.2.3 Control of Materials",
                  },
                  {
                    id: "3.2.S.2.4",
                    name: "3.2.S.2.4 Controls of Critical Steps and Intermediates",
                  },
                  {
                    id: "3.2.S.2.5",
                    name: "3.2.S.2.5 Process Validation and/or Evaluation",
                  },
                  {
                    id: "3.2.S.2.6",
                    name: "3.2.S.2.6 Manufacturing Process Development",
                  },
                ],
              },
              {
                id: "3.2.S.3",
                name: "3.2.S.3 Characterization",
                children: [
                  {
                    id: "3.2.S.3.1",
                    name: "3.2.S.3.1 Elucidation of Structure and other Characteristics",
                  },
                  {
                    id: "3.2.S.3.2",
                    name: "3.2.S.3.2 Impurities",
                  },
                ],
              },
              {
                id: "3.2.S.4",
                name: "3.2.S.4 Control of drug substance",
                children: [
                  {
                    id: "3.2.S.4.1",
                    name: "3.2.S.4.1 Specification",
                  },
                  {
                    id: "3.2.S.4.2",
                    name: "3.2.S.4.2 Analytical Procedures",
                  },
                  {
                    id: "3.2.S.4.3",
                    name: "3.2.S.4.3 Validation of Analytical Procedures",
                  },
                  {
                    id: "3.2.S.4.4",
                    name: "3.2.S.4.4 Batch Analyses",
                  },
                  {
                    id: "3.2.S.4.5",
                    name: "3.2.S.4.5 Justification of Specification",
                  },
                ],
              },
              {
                id: "3.2.S.5",
                name: "3.2.S.5 Reference standards or materials",
              },
              {
                id: "3.2.S.6",
                name: "3.2.S.6 Container closure systems",
              },
              {
                id: "3.2.S.7",
                name: "3.2.S.7 Stability",
                children: [
                  {
                    id: "3.2.S.7.1",
                    name: "3.2.S.7.1 Stability Summary and Conclusions",
                  },
                  {
                    id: "3.2.S.7.2",
                    name: "3.2.S.7.2 Post Approval Stability Protocol and Stability Commitment",
                  },
                  {
                    id: "3.2.S.7.3",
                    name: "3.2.S.7.3 Stability Data",
                  },
                ],
              },
            ],
          },
          {
            id: "3.2.P",
            name: "3.2.P Drug product",
            children: [
              {
                id: "3.2.P.1",
                name: "3.2.P.1 Description and composition of the drug product",
              },
              {
                id: "3.2.P.2",
                name: "3.2.P.2 Pharmaceutical development",
              },
              {
                id: "3.2.P.3",
                name: "3.2.P.3 Manufacture",
                children: [
                  {
                    id: "3.2.P.3.1",
                    name: "3.2.P.3.1 Manufacturer(s)",
                  },
                  {
                    id: "3.2.P.3.2",
                    name: "3.2.P.3.2 Batch Formula",
                  },
                  {
                    id: "3.2.P.3.3",
                    name: "3.2.P.3.3 Description of Manufacturing Process and Process Controls",
                  },
                  {
                    id: "3.2.P.3.4",
                    name: "3.2.P.3.4 Controls of Critical Steps and Intermediates",
                  },
                  {
                    id: "3.2.P.3.5",
                    name: "3.2.P.3.5 Process Validation and/or Evaluation",
                  },
                ],
              },
              {
                id: "3.2.P.4",
                name: "3.2.P.4 Control of excipients",
                children: [
                  {
                    id: "3.2.P.4.1",
                    name: "3.2.P.4.1 Specification(s)",
                  },
                  {
                    id: "3.2.P.4.2",
                    name: "3.2.P.4.2 Analytical Procedures",
                  },
                  {
                    id: "3.2.P.4.3",
                    name: "3.2.P.4.3 Validation of Analytical Procedures",
                  },
                  {
                    id: "3.2.P.4.4",
                    name: "3.2.P.4.4 Justification of Specifications",
                  },
                  {
                    id: "3.2.P.4.5",
                    name: "3.2.P.4.5 Excipients of Human or Animal Origin",
                  },
                  {
                    id: "3.2.P.4.6",
                    name: "3.2.P.4.6 Novel Excipients",
                  },
                ],
              },
              {
                id: "3.2.P.5",
                name: "3.2.P.5 Control of drug product",
                children: [
                  {
                    id: "3.2.P.5.1",
                    name: "3.2.P.5.1 Specification(s)",
                  },
                  {
                    id: "3.2.P.5.2",
                    name: "3.2.P.5.2 Analytical Procedures",
                  },
                  {
                    id: "3.2.P.5.3",
                    name: "3.2.P.5.3 Validation of Analytical Procedures",
                  },
                  {
                    id: "3.2.P.5.4",
                    name: "3.2.P.5.4 Batch Analyses",
                  },
                  {
                    id: "3.2.P.5.5",
                    name: "3.2.P.5.5 Characterization of Impurities",
                  },
                  {
                    id: "3.2.P.5.6",
                    name: "3.2.P.5.6 Justification of Specification(s)",
                  },
                ],
              },
              {
                id: "3.2.P.6",
                name: "3.2.P.6 Reference standards or materials",
              },
              {
                id: "3.2.P.7",
                name: "3.2.P.7 Container closure system",
              },
              {
                id: "3.2.P.8",
                name: "3.2.P.8 Stability",
                children: [
                  {
                    id: "3.2.P.8.1",
                    name: "3.2.P.8.1 Stability Summary and Conclusion",
                  },
                  {
                    id: "3.2.P.8.2",
                    name: "3.2.P.8.2 Postapproval Stability Protocol and Stability Commitment",
                  },
                  {
                    id: "3.2.P.8.3",
                    name: "3.2.P.8.3 Stability Data",
                  },
                ],
              },
            ],
          },
          {
            id: "3.2.A",
            name: "3.2.A Appendices",
            children: [
              {
                id: "3.2.A.1",
                name: "3.2.A.1 Facilities and Equipment [name, manufacturer]",
              },
              {
                id: "3.2.A.2",
                name: "3.2.A.2 Adventitious agents safety evaluation [name, dosage form, manufacturer]",
              },
              {
                id: "3.2.A.3",
                name: "3.2.A.3 Novel excipients",
              },
            ],
          },
          {
            id: "3.2.R",
            name: "3.2.R Regional information",
          },
          {
            id: "3.3",
            name: "3.3 Literature references",
          },
        ],
      },
    ],
  },
  {
    id: "4",
    name: "Module 4 Nonclinical Study Reports",
    children: [
      {
        id: "4.2",
        name: "4.2 Study reports",
        children: [
          {
            id: "4.2.1",
            name: "4.2.1 Pharmacology",
            children: [
              {
                id: "4.2.1.1",
                name: "4.2.1.1 Primary pharmacodynamics",
              },
              {
                id: "4.2.1.2",
                name: "4.2.1.2 Secondary pharmacodynamics",
              },
              {
                id: "4.2.1.3",
                name: "4.2.1.3 Safety pharmacology",
              },
              {
                id: "4.2.1.4",
                name: "4.2.1.4 Pharmacodynamic drug interactions",
              },
            ],
          },
          {
            id: "4.2.2",
            name: "4.2.2 Pharmacokinetics",
            children: [
              {
                id: "4.2.2.1",
                name: "4.2.2.1 Analytical methods and validation reports",
              },
              {
                id: "4.2.2.2",
                name: "4.2.2.2 Absorption",
              },
              {
                id: "4.2.2.3",
                name: "4.2.2.3 Distribution",
              },
              {
                id: "4.2.2.4",
                name: "4.2.2.4 Metabolism",
              },
              {
                id: "4.2.2.5",
                name: "4.2.2.5 Excretion",
              },
              {
                id: "4.2.2.6",
                name: "4.2.2.6 Pharmacokinetic drug interactions",
              },
              {
                id: "4.2.2.7",
                name: "4.2.2.7 Other pharmacokinetic studies",
              },
            ],
          },
          {
            id: "4.2.3",
            name: "4.2.3 Toxicology",
            children: [
              {
                id: "4.2.3.1",
                name: "4.2.3.1 Single dose toxicity [Species and route]",
              },
              {
                id: "4.2.3.2",
                name: "4.2.3.2 Repeat dose toxicity [Species, route, duration]",
              },
              {
                id: "4.2.3.3",
                name: "4.2.3.3 Genotoxicity",
                children: [
                  {
                    id: "4.2.3.3.1",
                    name: "4.2.3.3.1 In vitro",
                  },
                  {
                    id: "4.2.3.3.2",
                    name: "4.2.3.3.2 In vivo",
                  },
                ],
              },
              {
                id: "4.2.3.4",
                name: "4.2.3.4 Carcinogenicity",
                children: [
                  {
                    id: "4.2.3.4.1",
                    name: "4.2.3.4.1 Long term studies[Species]",
                  },
                  {
                    id: "4.2.3.4.2",
                    name: "4.2.3.4.2 Short or mediumterm studies",
                  },
                  {
                    id: "4.2.3.4.3",
                    name: "4.2.3.4.3 Other studies",
                  },
                ],
              },
              {
                id: "4.2.3.5",
                name: "4.2.3.5 Reproductive and developmental toxicity",
                children: [
                  {
                    id: "4.2.3.5.1",
                    name: "4.2.3.5.1 Fertility and early embryonic development",
                  },
                  {
                    id: "4.2.3.5.2",
                    name: "4.2.3.5.2 Embryofetal development",
                  },
                  {
                    id: "4.2.3.5.3",
                    name: "4.2.3.5.3 Prenatal and postnatal development, including maternal function",
                  },
                  {
                    id: "4.2.3.5.4",
                    name: "4.2.3.5.4 Studiesin which the offspring (juvenile animals) are dosed and/or further evaluated",
                  },
                ],
              },
              {
                id: "4.2.3.6",
                name: "4.2.3.6 Local tolerance",
              },
              {
                id: "4.2.3.7",
                name: "4.2.3.7 Other toxicity studies",
                children: [
                  {
                    id: "4.2.3.7.1",
                    name: "4.2.3.7.1 Antigenicity",
                  },
                  {
                    id: "4.2.3.7.2",
                    name: "4.2.3.7.2 Immunotoxicity",
                  },
                  {
                    id: "4.2.3.7.3",
                    name: "4.2.3.7.3 Mechanistic studies",
                  },
                  {
                    id: "4.2.3.7.4",
                    name: "4.2.3.7.4 Dependence",
                  },
                  {
                    id: "4.2.3.7.5",
                    name: "4.2.3.7.5 Metabolites",
                  },
                  {
                    id: "4.2.3.7.6",
                    name: "4.2.3.7.6 Impurities",
                  },
                  {
                    id: "4.2.3.7.7",
                    name: "4.2.3.7.7 Other",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "4.3",
        name: "4.3 Literature references",
      },
    ],
  },
  {
    id: "5",
    name: "Module 5 Clinical Study Reports",
    children: [
      {
        id: "5.2",
        name: "5.2 Tabular listing of all clinical studies",
      },
      {
        id: "5.3",
        name: "5.3 Clinicalstudy reports and related information",
        children: [
          {
            id: "5.3.1",
            name: "5.3.1 Reports of biopharmaceutic studies",
            children: [
              {
                id: "5.3.1.1",
                name: "5.3.1.1 Bioavailability (BA) Study reports and related information",
              },
              {
                id: "5.3.1.2",
                name: "5.3.1.2 Comparative BA and bioequivalence (BE) Study reports and related information",
              },
              {
                id: "5.3.1.3",
                name: "5.3.1.3 In Vitro - in Vivo correlation Study reports and related information",
              },
              {
                id: "5.3.1.4",
                name: "5.3.1.4 Reports of bioanalytical and analytical methods for human studies",
              },
            ],
          },
          {
            id: "5.3.2",
            name: "5.3.2 Reports of studies pertinent to pharmacokinetics using human biomaterials",
            children: [
              {
                id: "5.3.2.1",
                name: "5.3.2.1 Plasma protein binding Study reports and related information",
              },
              {
                id: "5.3.2.2",
                name: "5.3.2.2 Reports of hepatic metabolism and drug interaction studies",
              },
              {
                id: "5.3.2.3",
                name: "5.3.2.3 Reports of studies using other human biomaterials",
              },
            ],
          },
          {
            id: "5.3.3",
            name: "5.3.3 Reports of human pharmacokinetic (PK) studies",
            children: [
              {
                id: "5.3.3.1",
                name: "5.3.3.1 Healthy subject PK and initial tolerability Study reports and related information",
              },
              {
                id: "5.3.3.2",
                name: "5.3.3.2 Patient PK and initial tolerability Study reports and related information",
              },
              {
                id: "5.3.3.3",
                name: "5.3.3.3 Intrinsic factor PK Study reports and related information",
              },
              {
                id: "5.3.3.4",
                name: "5.3.3.4 Extrinsic factor Study reports and related information",
              },
              {
                id: "5.3.3.5",
                name: "5.3.3.5 Population PK Study reports and related information",
              },
            ],
          },
          {
            id: "5.3.4",
            name: "5.3.4 Reports of human pharmacodynamic (PD) studies",
            children: [
              {
                id: "5.3.4.1",
                name: "5.3.4.1 Healthy subject PD and PK/PD Study reports and related information",
              },
              {
                id: "5.3.4.2",
                name: "5.3.4.2 Patient PD and PK/PD Study reports and related information",
              },
            ],
          },
          {
            id: "5.3.5",
            name: "5.3.5 Reports of efficacy and safety studies [Indication]",
            children: [
              {
                id: "5.3.5.1",
                name: "5.3.5.1 Study reports and related information of controlled clinical studies pertinent to the claimed indication",
              },
              {
                id: "5.3.5.2",
                name: "5.3.5.2 Study reports and related information of uncontrolled clinical studies",
              },
              {
                id: "5.3.5.3",
                name: "5.3.5.3 Reports of analyses of data from more than one study",
              },
              {
                id: "5.3.5.4",
                name: "5.3.5.4 Other Study reports and related information",
              },
            ],
          },
          {
            id: "5.3.6",
            name: "5.3.6 Reports of postmarketing experience",
          },
        ],
      },
      {
        id: "5.4",
        name: "5.4 Literature references",
      },
    ],
  },
];

export const getPartialCTOCDocument = (modulesToExclude: string[]) =>
  getCTOCDocument().filter((module) => !modulesToExclude.includes(module.id));
