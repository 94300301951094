import { FilterFn } from "@tanstack/react-table";

// This is different from the built in "arrIncludesSome" filter function since it requires the
// filter to match the value rather than just be included in it.
export const filterByArrayIncludesExactly: FilterFn<any> = (
  row,
  columnId: string,
  filterValue: string[],
) => {
  return filterValue.includes(row.getValue(columnId));
};
