import { FilterOptionsState } from "@mui/material";
import isString from "lodash/isString";
import { ICheckboxOption } from "components/shared/CheckboxTree/CheckboxTree.utils";
import { cloneDeep } from "utils/helpers";

export const filterTree = (
  options: ICheckboxOption[],
  filterString: string,
): ICheckboxOption[] => {
  const lowerCaseFilterString = filterString.toLowerCase();

  return options.map((option) => {
    const hasMatch =
      option.label.toLowerCase().indexOf(lowerCaseFilterString) > -1;

    if (hasMatch) {
      return option;
    }

    const filteredChildren = option?.children?.length
      ? filterTree(option.children, filterString)
      : [];

    const childShown = filteredChildren.some(({ hidden }) => !hidden);

    option.hidden = !childShown;

    return option;
  });
};

export const getIsEmptyTree = (options: ICheckboxOption[]) => {
  return !Boolean(options.filter((el) => !el.hidden).length);
};

export const getFilteredOptions = (
  options: ICheckboxOption[],
  state: FilterOptionsState<any>,
) => {
  const optionsCopy = cloneDeep(options);
  const filteredTree = filterTree(optionsCopy, state.inputValue);

  return getIsEmptyTree(filteredTree) ? [] : filteredTree;
};

export const getOptionLabel = (option: ICheckboxOption | string) =>
  isString(option) ? option : option?.label;
