import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Loading } from "components/shared";
import { ModalActionButtons, RadioTileGroup } from "components/shared";
import useOrganizationTypeList from "hooks/api/GQL/entityManagement/useOrganizationTypeList";
import { OrganizationType } from "./CreateOrganizationModal.types";
import { getOrganizationTypeTitles } from "./CreateOrganizationModal.utils";

type TOrganizationTypeStepProps = {
  organizationType: OrganizationType | null;
  onSelectType: (type: OrganizationType) => void;
  handleCancel: () => void;
  handleNext: () => void;
};

const OrganizationTypeStep = ({
  organizationType,
  onSelectType,
  handleCancel,
  handleNext,
}: TOrganizationTypeStepProps) => {
  const { t } = useTranslation(["administration", "common"]);

  const { data: organizationTypeList, isLoading } = useOrganizationTypeList();

  const organizationTypeInfo = useMemo(
    () => getOrganizationTypeTitles(organizationTypeList, t),
    [t, organizationTypeList],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <RadioTileGroup
        ariaLabel={t("createOrganizationalModal.radioTileGroupFormLabel")}
        formLabel={t("createOrganizationalModal.radioTileGroupFormLabel")}
        name="organizationType"
        radios={organizationTypeInfo}
        onChange={(type) => {
          onSelectType(type as OrganizationType);
        }}
      />

      <ModalActionButtons>
        <Button
          key="cancel"
          variant="text"
          data-qaid="organization-type-cancel-button"
          onClick={handleCancel}
        >
          {t("button.cancel", { ns: "common" })}
        </Button>
        <Button
          disabled={!organizationType}
          key="next"
          variant="contained"
          data-qaid="organization-type-next-button"
          onClick={handleNext}
        >
          {t("button.next", { ns: "common" })}
        </Button>
      </ModalActionButtons>
    </>
  );
};

export default OrganizationTypeStep;
