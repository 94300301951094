import { ProjectType } from "utils/constants/project.constants";
import { isHAUser, isSponsorUser } from "utils/user/organization";
import { TPermissionFunction } from "../usePermissions.types";

//  TODO: Implementation of this functionality within the task FTE-11510

export const deriveDeleteQuestionPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveCreateQuestionPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveEditQuestionOwnedPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;
    default:
      return false;
  }
};

export const deriveEditQuestionPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveCreateQuestionResponsePermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveEditQuestionResponsePermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveChangeQuestionOwnedStatusPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveChangeQuestionStatusPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveChangeQuestionResponseStatusPermission: TPermissionFunction =
  ({ project, organization, userRoles }) => {
    switch (project?.type) {
      case ProjectType.Reliance:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return true;
          }
        }

        return false;

      case ProjectType.RegulatoryReview:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return true;
          }
        }

        return false;

      default:
        return false;
    }
  };

export const deriveImportReferenceOrExternalQuestionPermission: TPermissionFunction =
  ({ project, organization, userRoles }) => {
    switch (project?.type) {
      case ProjectType.Reliance:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return true;
          }
        }

        return false;

      case ProjectType.RegulatoryReview:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return true;
          }
        }

        return false;

      default:
        return false;
    }
  };

export const deriveEditReferenceOrExternalQuestionPermission: TPermissionFunction =
  ({ project, organization, userRoles }) => {
    switch (project?.type) {
      case ProjectType.Reliance:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return true;
          }
        }

        return false;

      case ProjectType.RegulatoryReview:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return true;
          }
        }

        return false;

      default:
        return false;
    }
  };

export const deriveChangeReferenceOrExternalQuestionStatusPermission: TPermissionFunction =
  ({ project, organization, userRoles }) => {
    switch (project?.type) {
      case ProjectType.Reliance:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return true;
          }
        }

        return false;

      case ProjectType.RegulatoryReview:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return true;
          }
        }

        return false;

      default:
        return false;
    }
  };

export const deriveSendCopyQuestionPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveSubmitQuestionResponsePermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveSubmitReferenceOrExternalQuestionPermission: TPermissionFunction =
  ({ project, organization, userRoles }) => {
    switch (project?.type) {
      case ProjectType.Reliance:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return false;
          }
        }

        return false;

      case ProjectType.RegulatoryReview:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return false;
          }
        }

        return false;

      default:
        return false;
    }
  };

export const deriveViewQuestionAndResponsePermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
  isGlobalPM,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }

        if (userRoles.isContentViewer) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }

        if (userRoles.isContentViewer) {
          return true;
        }

        if (isGlobalPM) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }

        if (userRoles.isContentViewer) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }

        if (userRoles.isContentViewer) {
          return true;
        }

        if (isGlobalPM) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveExportQuestionAndResponsePermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveCreateCommentsOwnedAssetPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
  isGlobalPM,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (isGlobalPM) {
          return true;
        }

        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (isGlobalPM) {
          return true;
        }

        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveCreateCommentsPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
  isGlobalPM,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (isGlobalPM) {
          return true;
        }

        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (isGlobalPM) {
          return true;
        }

        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveResolveCommentsPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};
