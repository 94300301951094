import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import uniqBy from "lodash/uniqBy";
import {
  FilterType,
  TFilterOption,
  TSelectFilter,
} from "components/shared/types/filter";
import {
  TherapeuticTable,
  TTherapeuticRowData,
} from "../TherapeuticSummary.types";

const getOptions = (therapeuticItems: TTherapeuticRowData[]): TFilterOption[] =>
  uniqBy(therapeuticItems, TherapeuticTable.RouteOfAdministration).map(
    (therapeuticItem) => ({
      text: therapeuticItem[TherapeuticTable.RouteOfAdministration],
      value: therapeuticItem[TherapeuticTable.RouteOfAdministration],
    }),
  );

const useRouteOfAdministrationFilter = (
  therapeuticItems: TTherapeuticRowData[],
): Record<TherapeuticTable.RouteOfAdministration, TSelectFilter> => {
  const { t } = useTranslation("portfolio");
  const theme = useTheme();

  return useMemo(
    () => ({
      [TherapeuticTable.RouteOfAdministration]: {
        type: FilterType.Select,
        label: t("therapeutics.columns.routeOfAdministration"),
        options: getOptions(therapeuticItems),
        selectAllLabel: t("multiSelect.selectAll", { ns: "common" }),
        sx: {
          width: theme.spacing(26),
        },
      },
    }),
    [therapeuticItems, t, theme],
  );
};

export default useRouteOfAdministrationFilter;
