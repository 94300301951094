import {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import omit from "lodash/omit";
import { mergeDeep, TRecursivePartial } from "utils/helpers";
import { TDictionary } from "utils/types";
import {
  TBackgroundJobsContextValue,
  TPossibleBackgroundJob,
} from "./BackgroundJobsContext.types";
import { isJobOfType } from "./BackgroundJobsContext.utils";

export const BackgroundJobsContext = createContext<
  TBackgroundJobsContextValue | undefined
>(undefined);

export const BackgroundJobsProvider = ({ children }: PropsWithChildren) => {
  const [jobs, setJobs] = useState<TDictionary<TPossibleBackgroundJob>>({});

  const addJob = useCallback(
    (job: TPossibleBackgroundJob) => {
      setJobs((jobs) => ({ ...jobs, [job.id]: job }));
    },
    [setJobs],
  );

  const getJob = useCallback(
    <T extends TPossibleBackgroundJob>(jobId: string, jobType: T["type"]) => {
      const job = jobs[jobId];

      if (!job || !isJobOfType(job, jobType)) {
        return undefined;
      }

      return job;
    },
    [jobs],
  );

  const updateJobData = useCallback(
    <T extends TPossibleBackgroundJob>(
      jobId: string,
      jobData: TRecursivePartial<T["data"]>,
    ) => {
      setJobs((jobs) => ({
        ...jobs,
        [jobId]: {
          ...jobs[jobId],
          data: mergeDeep(jobs[jobId]?.data, jobData),
        },
      }));
    },
    [setJobs],
  );

  const removeJob = useCallback(
    (jobId: string) => {
      setJobs((jobs) => omit(jobs, [jobId]));
    },
    [setJobs],
  );

  const value = useMemo(
    () => ({ addJob, getJob, updateJobData, removeJob }),
    [addJob, getJob, updateJobData, removeJob],
  );

  return (
    <BackgroundJobsContext.Provider value={value}>
      {children}
    </BackgroundJobsContext.Provider>
  );
};
