import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TrendingFlat } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Loading from "components/shared/Loading";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import useProcessDocument from "hooks/api/REST/documents/useProcessDocument";
import { IParseJob } from "models/assets.models";
import { useDocumentsContext } from "screens/Project/sections/Documents/DocumentsContext";
import { DocumentAction } from "utils/constants/doc.constants";
import { DOCUMENTS_VISUALS } from "./ConvertDocument.constants";
import DocumentCard from "./DocumentCard";
import styles from "./ConvertDocument.styles";

const ConvertDocument = () => {
  const { triggerProcessingDocument, isLoading: isTriggerProcessLoading } =
    useProcessDocument();

  const { t } = useTranslation(["documents", "common"]);

  const methods = useForm();

  const { handleSubmit } = methods;

  const {
    currentActionOnDocument: { documents, documentAction },
    setCurrentActionOnDocument,
    parseJobContext,
    setParseJobContext,
  } = useDocumentsContext();

  const closeModal = () =>
    setCurrentActionOnDocument({ documents: [], documentAction: null });

  const onSubmit = () => {
    if (!parseJobContext) {
      triggerProcessingDocument(
        {
          data: {
            sourceAssetId: documents[0].id ?? "",
          },
        },
        {
          onSuccess: (parseJob: IParseJob) => {
            setParseJobContext({
              ...parseJob,
              documentName: documents[0].name,
            });
          },
        },
      );
    }

    closeModal();
  };

  return (
    <Modal
      data-qaid="convert-document-modal"
      title={t("importDocument.aaid.title")}
      open={documentAction === DocumentAction.Convert}
    >
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2.5}>
          <Box sx={styles.documentImage}>
            <DocumentCard
              sx={{ backgroundColor: DOCUMENTS_VISUALS.word.color }}
            >
              {DOCUMENTS_VISUALS.word.abbreviation}
            </DocumentCard>
            <TrendingFlat sx={styles.arrow} />
            <DocumentCard
              sx={{ backgroundColor: DOCUMENTS_VISUALS.assessmentAid.color }}
            >
              {DOCUMENTS_VISUALS.assessmentAid.abbreviation}
            </DocumentCard>
          </Box>
          {!isEmpty(parseJobContext) ? (
            <Box>
              <strong>{parseJobContext.documentName}</strong>{" "}
              {t("convertDocument.willBeConvertedConfirmationUploading")}
              <br />
              {t(
                "convertDocument.willBeConvertedConfirmationReceiveNotification",
              )}
            </Box>
          ) : (
            <Box>
              {t("convertDocument.convertConfirmationAreYouSure")}{" "}
              <strong>{documents?.[0]?.name}</strong>{" "}
              {t("convertDocument.convertConfirmationToStructured")}
            </Box>
          )}
        </Stack>
        <ModalActionButtons>
          <Button data-qaid="convert-modal-cancel-button" onClick={closeModal}>
            {t("button.cancel", { ns: "common" })}
          </Button>
          <Button
            data-qaid="convert-modal-cancel-button"
            variant="contained"
            color="primary"
            type="submit"
          >
            {isTriggerProcessLoading ? (
              <Loading />
            ) : !parseJobContext ? (
              t("convertDocument.convertSubmit")
            ) : (
              t("convertDocument.convertOk")
            )}
          </Button>
        </ModalActionButtons>
      </FormWrapper>
    </Modal>
  );
};

export default ConvertDocument;
