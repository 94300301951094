import { createSvgIcon } from "@mui/material";

export const FilterLinesIcon = createSvgIcon(
  <path
    d="M6 12H18M3 6H21M9 18H15"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  "FilterLinesIcon",
);
