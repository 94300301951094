enum ImportFormBaseFieldName {
  File = "file",
  Type = "type",
  Domain = "domain",
  Subtype = "subtype",
  Language = "language",
  Name = "name",
  Indication = "indication",
  Author = "author",
  LitigationHold = "litigationHold",
  FHIRResourceType = "FHIRResourceType",
}

enum ImportFormSharedFieldName {
  SubmissionDate = "submissionDate",
}

enum ImportFormReferencesFieldName {
  SAL = "sponsorAuthorizationLetter",
  HealthAuthority = "healthAuthority",
  FormalSubmissionDate = "formalSubmissionDate",
}

enum ToplineResultsFormFieldName {}

enum ImportFormLOAFieldName {
  ApplicableHealthAuthorityTenantId = "applicableHealthAuthorityTenantId",
}

export {
  ImportFormBaseFieldName,
  ToplineResultsFormFieldName,
  ImportFormSharedFieldName,
  ImportFormReferencesFieldName,
  ImportFormLOAFieldName,
};
