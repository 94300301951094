import { AxiosResponse } from "axios";
import { get, post, put, routes } from "api";
import { IClientConfig } from "api/types";
import { ILegalAcknowledgementData } from "models/legalAcknowledgement.models";
import {
  ICreateUserGroupRequestData,
  IUserGroupsRequestParams,
} from "models/userGroup.models";
import {
  ICreateUserRequestData,
  IGetUserProjectRolesRequestParams,
  IGetUsersListRequestParams,
  IUpdateUserRequestData,
  IUserProjectRole,
} from "models/users.models";

const user = {
  agreeToAcknowledgement(
    id: string,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse<ILegalAcknowledgementData[]>> {
    return put<ILegalAcknowledgementData[]>(
      routes.identity.user.acknowledgementById(id),
      { data: { accepted: true }, isMockApiOn },
    );
  },

  getUser(id: string, config?: IClientConfig): Promise<AxiosResponse> {
    return get(routes.identity.user.byId(id), config);
  },

  getCurrentUser(isMockApiOn?: boolean): Promise<AxiosResponse> {
    return get(routes.identity.user.current(), { isMockApiOn });
  },

  getUserLegalAcknowledgements(isMockApiOn?: boolean): Promise<AxiosResponse> {
    return get(routes.identity.user.acknowledgement(), { isMockApiOn });
  },

  getUsersList(
    params: IGetUsersListRequestParams = {},
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return get(routes.identity.user.all(), {
      params,
      isMockApiOn,
    });
  },

  updateUser(
    data: IUpdateUserRequestData,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return put(routes.identity.user.byId(data.id), { data, isMockApiOn });
  },

  getUserGroups(
    params: IUserGroupsRequestParams,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return get(routes.identity.user.memberGroups(), {
      params,
      isMockApiOn,
    });
  },

  createUserGroup: (
    data: ICreateUserGroupRequestData,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> => {
    return post(routes.identity.user.groups(), { data, isMockApiOn });
  },

  getTimeZones: (isMockApiOn?: boolean): Promise<AxiosResponse> => {
    return get(routes.identity.user.timezones(), { isMockApiOn });
  },

  getCurrentUserRoles: (isMockApiOn?: boolean): Promise<AxiosResponse> => {
    return get(routes.identity.user.getCurrentUserRoles(), {
      isMockApiOn,
    });
  },

  getUserProjectRoles: (
    params?: IGetUserProjectRolesRequestParams,
  ): Promise<AxiosResponse<IUserProjectRole[]>> => {
    return get(routes.identity.user.getUserProjectRoles(), { params });
  },

  getUsersTemplate: (): Promise<AxiosResponse> => {
    return get(routes.identity.user.getUsersTemplate(), {
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    });
  },

  uploadUserTemplate: (data: FormData): Promise<AxiosResponse> => {
    return put(routes.identity.user.uploadUserTemplate(), {
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  logout: (): Promise<AxiosResponse> => {
    return post(routes.identity.user.logout());
  },

  createUser: (data: ICreateUserRequestData): Promise<AxiosResponse> => {
    return post(routes.identity.user.all(), { data });
  },

  activateUser: (userId: string): Promise<AxiosResponse> => {
    return post(routes.identity.user.activationRequestById(userId));
  },

  deactivateUser: (userId: string): Promise<AxiosResponse> => {
    return post(routes.identity.user.deactivationRequestById(userId));
  },
};

export default user;
