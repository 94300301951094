import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const getDateValue = (value?: Date | number | string): Date | null => {
  if (!value) {
    return null;
  } else if (typeof value === "string" || typeof value === "number") {
    // Ensures that the date passed to the DatePicker is in UTC to prevent date shifting, especially when no timestamp included
    return dayjs(value).utc().toDate();
  } else {
    return value;
  }
};

export default getDateValue;
