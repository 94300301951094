import { PropsWithChildren, ReactNode } from "react";
import { Box, CardContent, CardHeader, SxProps, Theme } from "@mui/material";
import Card from "components/shared/Card";
import Avatar from "../Avatar/Avatar";
import styles from "./DetailsCard.styles";

export type TDetailsCardProps = {
  title: string;
  avatar: ReactNode;
  action?: ReactNode;
  sx?: SxProps<Theme>;
} & PropsWithChildren;

export const DetailsCard = ({
  children,
  title,
  avatar,
  action,
  sx,
}: TDetailsCardProps) => {
  return (
    <Card sx={sx} data-qaid="details-card">
      <Box sx={styles.headerContainer}>
        <CardHeader
          sx={styles.header}
          title={title}
          avatar={
            <Avatar size={40} variant="rounded">
              {avatar}
            </Avatar>
          }
          action={action}
          data-qaid="details-card-header"
        />
      </Box>
      <CardContent sx={styles.content}>{children}</CardContent>
    </Card>
  );
};

export default DetailsCard;
