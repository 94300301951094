import { useMemo, useState } from "react";
import { Box, IconButton, Slide, Stack } from "@mui/material";
import styles from "./InfoMenu.styles";

export type TInfoMenuItem = {
  id: string;
  icon: React.ReactNode;
  content: React.ReactNode;
  withoutIconButton?: boolean;
};

export type TInfoMenuProps = {
  infoMenuItems: TInfoMenuItem[];
  openItemId?: string;
  textButtonHide: string;
  textButtonShow: string;
};

const InfoMenu = ({
  infoMenuItems,
  openItemId,
  textButtonHide,
  textButtonShow,
}: TInfoMenuProps) => {
  const [contentType, setContentType] = useState<string | null>(
    openItemId || null,
  );

  const [isContentVisible, setIsContentVisible] = useState<boolean>(
    Boolean(openItemId),
  );

  const getIsButtonActive = (buttonContentType: string) =>
    buttonContentType === contentType && isContentVisible;

  const documentSidebarStyles = isContentVisible
    ? styles.sidebarWide
    : styles.sidebarNarrow;

  const switchContentType = (type: string) => {
    if (contentType === type) {
      setIsContentVisible((isContentVisible) => !isContentVisible);
    } else {
      setContentType(type);
      setIsContentVisible(true);
    }
  };

  const activeMenuItem = useMemo(
    () => infoMenuItems?.find((item) => item.id === contentType),
    [contentType, infoMenuItems],
  );

  if (!Boolean(infoMenuItems.length)) {
    return null;
  }

  return (
    <Stack
      direction="row"
      sx={documentSidebarStyles}
      data-testid="info-menu"
      data-qaid="info-menu"
    >
      <Slide
        direction="left"
        in={isContentVisible && Boolean(activeMenuItem)}
        appear={false}
      >
        <Box sx={styles.sidebarSlide}>
          {infoMenuItems.map(({ id, content }) => (
            <Stack
              key={id}
              direction="column"
              sx={
                activeMenuItem?.id === id
                  ? styles.sidebarSlideVisible
                  : styles.sidebarSlideHidden
              }
            >
              {content}
            </Stack>
          ))}
        </Box>
      </Slide>
      <Stack direction="column" sx={styles.sidebarPanel}>
        {infoMenuItems.map(({ id, icon, withoutIconButton }: TInfoMenuItem) => {
          const name = `${
            getIsButtonActive(id) ? textButtonHide : textButtonShow
          } ${id}`;

          return withoutIconButton ? (
            <div key={id}>{icon}</div>
          ) : (
            <IconButton
              key={id}
              onClick={() => switchContentType(id)}
              aria-label={name}
              name={name}
              color="primary"
              sx={
                getIsButtonActive(id)
                  ? styles.sidebarPanelButtonActive
                  : styles.sidebarPanelButton
              }
            >
              {icon}
            </IconButton>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default InfoMenu;
