import { useMutation, useQueryClient } from "react-query";
import useShowAlert, { TAlertMessage } from "hooks/useShowAlert";
import { IDocument } from "models/documents.models";
import { IGSPComponentRequestData } from "models/gsp.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export type TUpdateAssetsMutationData<T> = {
  data: T;
};

const useUpdateAssets = <T extends IGSPComponentRequestData[] | IDocument[]>(
  messages?: TAlertMessage,
) => {
  const queryClient = useQueryClient();

  const updateAssetsMutation = useMutation(
    ({ data }: TUpdateAssetsMutationData<T>) =>
      services.assets.updateAssets(data).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryAPIKey.GetAssets);
      },
    },
  );

  useShowAlert({
    status: updateAssetsMutation.status,
    ...messages,
  });

  return updateAssetsMutation;
};

export default useUpdateAssets;
