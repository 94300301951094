/* eslint-disable no-restricted-imports */
import React, { Ref, useMemo } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from "@mui/material";
import { getIsEmptyString } from "utils/helpers";
import Loading from "../Loading";
import styles from "./Select.styles";

export type TSelectProps<T> = Omit<MuiSelectProps<T>, "id"> & {
  helperText?: string;
  errorMessage?: string;
  unselectText?: string;
  emptyLabel?: string;
  isLoading?: boolean;

  /**
   * Required id string used to uniquely identify the select field (e.g. for form label)
   */
  id: string;
};

export type TOption = {
  label: string;
  value: string;
  disabled?: boolean;
  testId?: string;
};

const Select = React.forwardRef(
  <T extends unknown>(props: TSelectProps<T>, ref?: Ref<any>) => {
    const labelId = `${props.id}-label`;

    const {
      helperText,
      errorMessage,
      error,
      unselectText,
      emptyLabel,
      isLoading,
      label,
      ...selectProps
    } = props;

    const combinedStyles = {
      ...styles.defaultSelect,
      ...props.sx,
    };

    const displayedLabel = useMemo(
      () =>
        selectProps.value || getIsEmptyString(emptyLabel) ? label : emptyLabel,
      [emptyLabel, selectProps.value, label],
    );

    return (
      <FormControl
        error={error}
        size={props.size}
        sx={combinedStyles}
        variant={props.variant}
        required={props.required}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
      >
        <InputLabel id={labelId} data-testid={labelId} data-qaid={labelId}>
          {displayedLabel}
        </InputLabel>
        <MuiSelect
          sx={props.disabled ? styles.disabledSelect : null}
          inputRef={ref}
          labelId={labelId}
          MenuProps={
            props.MenuProps || {
              id: "menu-" + props.id,
              MenuListProps: {
                style: { maxHeight: 350 },
              },
            }
          }
          label={displayedLabel}
          {...selectProps}
        >
          {!props.required && unselectText && (
            <MenuItem sx={styles.unselect} key="clear" value={""}>
              {unselectText}
            </MenuItem>
          )}
          {isLoading && <Loading />}
          {props.children}
        </MuiSelect>
        {helperText && (
          <FormHelperText
            data-testid={`${props.id}-helper-text`}
            data-qaid={`${props.id}-helper-text`}
            variant="standard"
            aria-live="polite"
          >
            {helperText}
          </FormHelperText>
        )}
        {error && errorMessage && (
          <FormHelperText
            data-testid={`${props.id}-error-message`}
            data-qaid={`${props.id}-error-message`}
            variant="standard"
            aria-live="polite"
            error
          >
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

// Type assertion required to be able to preserve MUI Select generic component w/ a forwardRef.
// credit: https://dirask.com/posts/React-forwardRef-with-generic-component-in-TypeScript-D6BoRD
export default Select as <T>(
  props: TSelectProps<T>,
  ref?: Ref<any>,
) => ReturnType<typeof Select>;
