import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { QueryClient, useQueryClient } from "react-query";
import { TFunction } from "i18next";
import poll from "api/utils/poll";
import { TSelectedHAQTableRow } from "components/shared/types";
import { TShowAlert, useAlerts } from "context/AlertContext";
import { useProject } from "context/ProjectContext";
import { IProjectData } from "hooks/api/GQL/project/useProjectData.models";
import { lockBulkAssetsEditing } from "hooks/api/REST/assets/useLockBulkAssetsEditing";
import { getWorkflowExecution } from "hooks/api/REST/workflow/useGetWorkflowExecution";
import { startWorkflow } from "hooks/api/REST/workflow/useStartWorkflow";
import useUser from "hooks/useUser";
import { IOrganization } from "models/organizations.models";
import { EventType } from "models/tracking.models";
import { TUserData } from "models/users.models";
import { WorkflowExecutionStatus } from "models/workflow.models";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import {
  DEFAULT_POLLING_INTERVAL,
  QueryAPIKey,
} from "utils/constants/api.constants";
import { AssetType } from "utils/constants/assets.constants";
import { ISO_DATE_FORMAT } from "utils/constants/format.constants";
import { WorkflowId } from "utils/constants/workflows.constants";
import { formatDate } from "utils/helpers";

export const changeHAQDueDate =
  (
    t: TFunction,
    queryClient: QueryClient,
    showAlert: TShowAlert,
    user: TUserData | undefined,
    project: IProjectData | undefined | null,
    creatorTenant: IOrganization | undefined,
  ) =>
  async (
    selectedRows: TSelectedHAQTableRow[],
    dueDate: Date,
    successMessage: string,
    failureMessage: string,
  ) => {
    if (!user || !creatorTenant) {
      return;
    }

    const assetIds = selectedRows.map((row) => row?.HAQ.id);

    services.tracking.addBulkTrackingEvent(
      assetIds.map((assetId) => ({
        assetId,
        assetType: AssetType.Question,
        eventType: EventType.AppHAQChangeDueDate,
        projectId: project?.id ?? "",
      })),
    );

    await lockBulkAssetsEditing(t, showAlert, assetIds);

    const startWorkflowResponse = await startWorkflow(
      showAlert,
      {
        id: WorkflowId.ChangeHAQDueDate,
        payload: {
          originator: user.id,
          targetTenant: creatorTenant.tenantId,
          assets: assetIds,
          dueDate: formatDate(dueDate, ISO_DATE_FORMAT),
        },
      },
      undefined,
      failureMessage,
    );

    const id = startWorkflowResponse?.data?.id;

    if (!id) {
      return;
    }

    const { status } = await poll(
      () => getWorkflowExecution(t, showAlert, id),
      (data) =>
        data.status === WorkflowExecutionStatus.Complete ||
        data.status === WorkflowExecutionStatus.Failed,
      DEFAULT_POLLING_INTERVAL,
    );

    if (status === WorkflowExecutionStatus.Complete) {
      showAlert({
        severity: AlertSeverity.Success,
        title: successMessage,
        message: t("workflowMessages.notificationsSent"),
      });
    }

    if (status === WorkflowExecutionStatus.Failed) {
      showAlert({
        severity: AlertSeverity.Error,
        message: failureMessage,
      });
    }

    queryClient.invalidateQueries(QueryAPIKey.GetAsset);
    queryClient.invalidateQueries(QueryAPIKey.GetAssetContent);
    queryClient.invalidateQueries(QueryAPIKey.GetAssetsWithContent);
  };

export const useChangeHAQDueDate = () => {
  const { t } = useTranslation("notifications");
  const { showAlert } = useAlerts();
  const { user } = useUser();
  const { project, creatorTenant } = useProject();
  const queryClient = useQueryClient();

  return useMemo(
    () =>
      changeHAQDueDate(t, queryClient, showAlert, user, project, creatorTenant),
    [t, queryClient, showAlert, user, project, creatorTenant],
  );
};
