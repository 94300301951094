import { ICheckboxOption } from "components/shared/CheckboxTree/CheckboxTree.utils";

type TBaseDocumentForCheckbox = {
  name: string;
  id?: string;
  children?: TBaseDocumentForCheckbox[];
};

export const mapDocumentToCheckboxOption = <A extends TBaseDocumentForCheckbox>(
  dropdownOptions: A[] | undefined,
): ICheckboxOption[] => {
  return (
    dropdownOptions?.map((outline) => {
      const option = {
        id: outline.id,
        value: outline.id,
        label: outline.name,
        ...(outline?.children?.length && {
          children: mapDocumentToCheckboxOption(outline.children),
        }),
      } as ICheckboxOption;

      return option;
    }) || []
  );
};
