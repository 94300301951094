import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { TGSPComponent } from "models/gsp.models";
import {
  prepareDossierDifferenceEditData,
  TDossierDifferencesModule,
  TDossierDifferencesSection,
} from "screens/Project/screens/GSP/GSPItems/GSPItems.utils";
import { TOption } from "utils/types";
import {
  TDossierDifferencesModalForm,
  TDossierDifferencesModalFormEdit,
} from "../DossierDifferenceModal.types";
import useOpenDossierDifferenceModal from "../hooks/useOpenDossierDifferenceModal";

type TDossierDifferenceModalContextValue = {
  isOpen: boolean;
  listOfSubmittingHAs: TOption<string>[];
  onClose: () => void;
  onOpen: (initialData?: TDossierDifferencesModalForm) => void;
  initialFormData:
    | TDossierDifferencesModalFormEdit
    | TDossierDifferencesModalForm;

  handleClickEditDossierDifference: (
    currentSection: TDossierDifferencesSection,
    initialHA?: { name: string; tenantId: string },
  ) => void;
};

type TDossierDifferenceModalProviderProps = PropsWithChildren<{
  components: TGSPComponent[];
  dossierDifferencesModules: TDossierDifferencesModule[];
}>;

const DossierDifferenceModalContext = createContext<
  TDossierDifferenceModalContextValue | undefined
>(undefined);

export const useDossierDifferenceModal =
  (): TDossierDifferenceModalContextValue => {
    const context = useContext(DossierDifferenceModalContext);

    if (context === undefined) {
      throw new Error(
        "useDossierDifferenceModal must be used within an DossierDifferenceModalProvider",
      );
    }

    return context;
  };

const DossierDifferenceModalProvider = ({
  children,
  components,
  dossierDifferencesModules,
}: TDossierDifferenceModalProviderProps) => {
  const [isOpen, listOfSubmittingHAs, onClose, onOpen, initialFormData] =
    useOpenDossierDifferenceModal(components);

  const handleClickEditDossierDifference = useCallback(
    (
      currentSection: TDossierDifferencesSection,
      initialHA?: { name: string; tenantId: string },
    ) =>
      onOpen(
        prepareDossierDifferenceEditData(
          currentSection,
          dossierDifferencesModules,
          initialHA,
        ),
      ),
    [onOpen, dossierDifferencesModules],
  );

  const value = useMemo(
    () => ({
      isOpen,
      listOfSubmittingHAs,
      onClose,
      onOpen,
      initialFormData,
      handleClickEditDossierDifference,
    }),
    [
      isOpen,
      listOfSubmittingHAs,
      onClose,
      onOpen,
      initialFormData,
      handleClickEditDossierDifference,
    ],
  );

  return (
    <DossierDifferenceModalContext.Provider value={value}>
      {children}
    </DossierDifferenceModalContext.Provider>
  );
};

export default DossierDifferenceModalProvider;
