import { SvgIconProps, Theme } from "@mui/material";
import { TFunction } from "i18next";
import getDocumentIcons from "screens/Project/sections/Documents/utils/getDocumentIcons";
import { getPaletteColor } from "theme/helpers";
import { DocumentSubtype } from "utils/constants/doc.constants";

export const getDocumentIconByType = (
  documentType: string,
  t: TFunction,
  color?: SvgIconProps["color"],
) => {
  switch (documentType) {
    case DocumentSubtype.Question:
      return getDocumentIcons(t, color).attachment;
    default:
      return getDocumentIcons(t, color).document;
  }
};

export const getDocumentIconColor =
  (documentType: string) => (theme: Theme) => {
    switch (documentType) {
      case DocumentSubtype.Question:
        return getPaletteColor("orange.50")(theme);
      default:
        return getPaletteColor("indigo.50")(theme);
    }
  };
