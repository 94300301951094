import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { MoreVert } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { Editor as CustomEditor } from "ckeditor";
import Editor from "components/common/Editor";
import ButtonMenu from "components/shared/ButtonMenu";
import { queryClient } from "config/reactQueryClient";
import useLockAssetEditing from "hooks/api/REST/documents/useLockAssetEditing";
import useInterval from "hooks/useInterval";
import useUser from "hooks/useUser";
import { TDocumentViewMode } from "models/documents.models";
import {
  TDocumentAction,
  TDocumentComponent,
} from "screens/Project/sections/Documents/Document/Document.types";
import { useDocumentContext } from "screens/Project/sections/Documents/Document/DocumentContext/DocumentContext";
import { getLastVersion } from "screens/Project/sections/Documents/utils/documentHelper";
import { QueryAPIKey } from "utils/constants/api.constants";
import {
  DocumentAction,
  DocumentComponentStatus,
  DocumentViewMode,
} from "utils/constants/doc.constants";
import { REACQUIRE_EDIT_LOCK_THRESHOLD_IN_MINUTES } from "utils/constants/time.constants";
import { getDisplayedAssetStatus } from "utils/helpers";
import { isHAUser } from "utils/user/organization";
import { useComponentVisibility } from "./useComponentVisibility";
import { useDocumentComponentActions } from "./useDocumentComponentActions";
import { LiveHelpIcon } from "assets/icons";
import styles from "./DocumentComponent.styles";

export type TDocumentComponentProps = {
  component: TDocumentComponent;
  onActionSelect: (action: TDocumentAction) => void;
  viewMode: TDocumentViewMode;
};

const DELAY_BEFORE_FOCUS_ON_EDITOR = 600;

const DocumentComponent = ({
  component,
  onActionSelect,
  viewMode,
}: TDocumentComponentProps) => {
  const {
    assetId,
    content: { versions, content: initialContent },
    parentId,
    headerText,
    status,
  } = component;

  const { t } = useTranslation(["documents", "HAQ", "common"]);

  const { organization } = useUser();
  const { editingComponent, toolbarRef } = useDocumentContext();
  const [editor, setEditor] = useState<CustomEditor | null>(null);

  const { documentId } = useParams();

  const isHeader = useMemo(
    () => parentId === documentId,
    [parentId, documentId],
  );

  const version = useMemo(() => getLastVersion(versions), [versions]);

  const isThisComponentEditing =
    editingComponent.component?.assetId === component.assetId;

  useEffect(() => {
    if (isThisComponentEditing) {
      editingComponent.setComponent(component);
    }
  }, [component, isThisComponentEditing, editingComponent]);

  const { currentComponentRef } = useComponentVisibility(
    isThisComponentEditing,
  );

  const { lockAssetEditing } = useLockAssetEditing();

  const handleContinueEditing = useCallback(
    () => lockAssetEditing(editingComponent.component?.assetId ?? ""),
    [lockAssetEditing, editingComponent.component?.assetId],
  );

  useInterval({
    callback: handleContinueEditing,
    delay: isThisComponentEditing
      ? 1000 * 60 * REACQUIRE_EDIT_LOCK_THRESHOLD_IN_MINUTES
      : null,
  });

  const handleContentChange = (content: string) => {
    const isCKEditorContentChanged = !Boolean(
      content === null || content === initialContent,
    );

    if (isCKEditorContentChanged !== editingComponent?.isContentChanged) {
      editingComponent?.setIsContentChanged(isCKEditorContentChanged);
    }
  };

  const handleEdit = () => {
    lockAssetEditing(assetId, {
      onSuccess: () => {
        editingComponent.setComponent(component);
        editingComponent.setEditor(editor);

        if (editor?.ui.view.toolbar.element) {
          toolbarRef?.current?.appendChild(editor.ui.view.toolbar.element);
        }

        setTimeout(
          () => editor?.editing.view.focus(),
          DELAY_BEFORE_FOCUS_ON_EDITOR,
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          QueryAPIKey.GetAssetContent,
          component.assetId,
        ]);
      },
    });
  };

  const isEditingEnabled =
    !Boolean(editingComponent.component) &&
    ((viewMode === DocumentViewMode.Editing &&
      status === DocumentComponentStatus.Draft) ||
      viewMode === DocumentViewMode.Review);

  const { actionOptions } = useDocumentComponentActions({
    component,
    viewMode,
    onActionSelect,
    handleEdit,
    isEditingEnabled,
  });

  const onEditorReady = (id: string, editor: CustomEditor) => {
    setEditor(editor);
  };

  const SectionDetails = () => (
    <Box sx={styles.sectionDetails}>
      <Stack sx={styles.metaInfo}>
        <Typography variant="body1" sx={styles.sectionVersion}>
          v{version}
        </Typography>
        <Typography
          sx={
            status === DocumentComponentStatus.Draft
              ? styles.sectionStatusDraft
              : styles.sectionStatus
          }
        >
          {getDisplayedAssetStatus(component.componentType, status, t)}
        </Typography>
        <ButtonMenu
          value={status}
          options={actionOptions}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <IconButton
            aria-label={t("button.moreOptions", { ns: "common" })}
            size="large"
          >
            <MoreVert color="action" />
          </IconButton>
        </ButtonMenu>
      </Stack>
    </Box>
  );

  const documentStateStyles = isThisComponentEditing ? styles.editingState : {};

  const documentComponentStyles = (
    !isHeader ? { ...styles.sectionContent, ...documentStateStyles } : {}
  ) as SxProps<Theme>;

  const isThisEditorInReadOnlyState =
    !Boolean(isThisComponentEditing) ||
    (isThisComponentEditing && !editingComponent.isVisible);

  return (
    <Box
      sx={documentComponentStyles}
      className="highlightingItem"
      id={assetId}
      ref={currentComponentRef}
    >
      {isHeader ? (
        <Typography variant="h6" sx={{ mb: 1 }}>
          {headerText}
        </Typography>
      ) : (
        <Box sx={styles.sectionHeader}>
          <Typography variant="subtitle1" sx={{ marginRight: 1 }}>
            {headerText}
          </Typography>
          <SectionDetails />
          <Box sx={styles.sectionControls}>
            {isEditingEnabled && (
              <IconButton
                sx={styles.sectionControlButton}
                onClick={handleEdit}
                aria-label={t("button.edit", { ns: "common" })}
              >
                <EditIcon color="action" />
              </IconButton>
            )}
            {isHAUser(organization) && (
              <IconButton
                sx={styles.sectionControlButton}
                aria-label={t("addQuestion", { ns: "HAQ" })}
                onClick={() =>
                  onActionSelect({
                    type: DocumentAction.AddQuestion,
                    component,
                  })
                }
              >
                <LiveHelpIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      )}
      {!isHeader && (
        <Editor
          data={initialContent}
          isReadOnly={isThisEditorInReadOnlyState}
          componentId={assetId}
          isTrackChangesEnabled={viewMode === DocumentViewMode.Review}
          onContentChange={handleContentChange}
          onEditorReady={onEditorReady}
          editor={editor}
        />
      )}
    </Box>
  );
};

export default DocumentComponent;
