import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import ChosenAssets from "components/common/ChosenAssets";
import Button from "components/shared/Button";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { useAlerts } from "context/AlertContext";
import { IHAQWithContent } from "hooks/api/GQL/haq/useHAQsWithContent.models";
import useDeleteAssets from "hooks/api/REST/assets/useDeleteAssets";
import useOrganizations from "hooks/api/REST/organizations/useOrganizations";
import useUser from "hooks/useUser";
import { Path } from "router/paths";
import { QueryAPIKey } from "utils/constants/api.constants";
import { getHAQShortName } from "utils/helpers";
import { isSponsorUser } from "utils/user/organization";

export type TDeleteHAQProps = {
  onClose: () => void;
  isOpen: boolean;
  HAQ: IHAQWithContent | undefined;
  onDelete?: () => void;
};

const DeleteHAQ = ({ HAQ, onClose, isOpen, onDelete }: TDeleteHAQProps) => {
  const { t } = useTranslation(["HAQ", "common"]);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { showAlert } = useAlerts();
  const { organizations } = useOrganizations();
  const { organization } = useUser();

  const queryClient = useQueryClient();

  const { isLoading: isDocumentDeletionInProgress, mutateAsync: deleteHAQ } =
    useDeleteAssets({
      options: {
        onSuccess: () => {
          showAlert({
            message: t("notifications.deleteSuccess"),
            severity: "success",
          });

          queryClient.invalidateQueries(QueryAPIKey.GetAsset);
          queryClient.invalidateQueries(QueryAPIKey.GetAssetContent);
          queryClient.invalidateQueries(QueryAPIKey.GetAssetsWithContent);

          if (onDelete) {
            onDelete();
          } else {
            navigate(Path.ProjectHAQs.replace(":projectId", projectId ?? ""));
          }
        },

        onError: () => {
          showAlert({
            message: t("notifications.deleteFailure"),
            severity: "error",
          });
        },

        onSettled: onClose,
      },
    });

  const handleDeleteHAQ = async () => {
    if (HAQ?.id) {
      await deleteHAQ({ assetIds: [HAQ.id] });
      onClose();
    }
  };

  return (
    <Modal
      open={isOpen}
      data-qaid="delete-HAQ-modal"
      title={t("deleteHAQ.title")}
    >
      <Typography variant="body1" marginBottom={1.5}>
        {isSponsorUser(organization)
          ? t("deleteHAQ.confirmationForSponsor")
          : t("deleteHAQ.confirmationForHA")}
      </Typography>
      {HAQ && (
        <ChosenAssets
          content={[getHAQShortName(HAQ, t("HAQAcronym"), organizations)]}
        />
      )}
      <ModalActionButtons>
        <Button data-qaid="delete-HAQ-modal-cancel-button" onClick={onClose}>
          {t("button.cancel", { ns: "common" })}
        </Button>
        <Button
          disabled={isDocumentDeletionInProgress}
          data-qaid="delete-HAQ-modal-submit-button"
          variant="contained"
          color="error"
          onClick={handleDeleteHAQ}
        >
          {t("button.delete", { ns: "common" })}
        </Button>
      </ModalActionButtons>
    </Modal>
  );
};

export default DeleteHAQ;
