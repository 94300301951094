import { ReactNode } from "react";

export enum DocumentsTable {
  Id = "id",
  Name = "name",
  FileType = "fileType",
  Type = "type",
  Status = "status",
}

export type TDocumentsRowData = {
  [DocumentsTable.Id]: string;
  [DocumentsTable.Name]: ReactNode;
  [DocumentsTable.FileType]: string;
  [DocumentsTable.Type]: string;
  [DocumentsTable.Status]: ReactNode;
  isDisabled?: boolean;
};
