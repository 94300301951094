import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SelectProps as MUISelectProps } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { FormSelect } from "components/common/form-fields";
import useLanguages from "hooks/api/REST/documents/useLanguages";
import { ENG_LANG_CODE } from "utils/constants/doc.constants";

export type TSelectProps = Omit<MUISelectProps, "name"> & {
  fieldName: string;
  defaultValue?: string;
};

function LanguageField({ fieldName, defaultValue }: TSelectProps) {
  const { setValue } = useFormContext();

  const { t } = useTranslation("documents", {
    keyPrefix: "importDocument.fields",
  });

  const { languages, isLoading } = useLanguages();

  useEffect(() => {
    if (!isEmpty(languages)) {
      const defaultLanguage = languages?.find(
        (language) =>
          language.code === defaultValue || language.code === ENG_LANG_CODE,
      );

      if (defaultLanguage) {
        setValue(fieldName, defaultLanguage.code);
      }
    }
  }, [languages, setValue, defaultValue, fieldName]);

  const languageOptions = useMemo(
    () =>
      languages?.map((language) => ({
        value: language.code,
        label: language.name,
      })),
    [languages],
  );

  return (
    <FormSelect
      name={fieldName}
      data-qaid="language-select"
      label={t("language.label")}
      isLoading={isLoading}
      options={languageOptions || []}
    />
  );
}

export default LanguageField;
