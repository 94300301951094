import { useContext } from "react";
import { DocumentsContext, TDocumentsContextValue } from "./DocumentsContext";

const useDocumentsContext = (): TDocumentsContextValue => {
  const context = useContext(DocumentsContext);

  if (context === undefined) {
    throw new Error(
      "useDocumentContext must be used within a DocumentsContextProvider",
    );
  }

  return context;
};

export { useDocumentsContext };
