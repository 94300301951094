import { forwardRef } from "react";
import { Tooltip as MuiTooltip, TooltipProps } from "@mui/material";

const Tooltip = forwardRef(
  (
    {
      className,
      arrow,
      disabled,
      ...props
    }: TooltipProps & { disabled?: boolean },
    ref,
  ) => {
    return disabled ? (
      props.children
    ) : (
      <MuiTooltip
        {...props}
        arrow={arrow ?? true}
        classes={{ popper: className }}
        ref={ref}
      />
    );
  },
);

export default Tooltip;
