import { FunctionComponent, PropsWithChildren, SVGProps } from "react";
import { Stack, Typography } from "@mui/material";
import { ReactComponent as MainLayoutErrorIcon } from "assets/images/no-data.svg";
import styles from "./GenericFallback.styles";

type TFallbackProps = PropsWithChildren<{
  message?: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}>;

const GenericFallback = ({
  children,
  message,
  icon: Icon = MainLayoutErrorIcon,
}: TFallbackProps) => (
  <Stack
    sx={styles.errorContainer}
    justifyContent="center"
    alignItems="center"
    gap={2}
  >
    <Icon />
    <Typography variant="h6" component="h1">
      {message || ""}
    </Typography>
    {children}
  </Stack>
);

export default GenericFallback;
