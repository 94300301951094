import { forwardRef, Ref } from "react";
import Avatar, { TAvatarProps } from "components/shared/Avatar";
import COUNTRIES, { TCountry } from "utils/constants/countries.constants";

type TFlagProps = Omit<TAvatarProps, "src"> & {
  country: keyof TCountry;
};

const Flag = forwardRef((props: TFlagProps, ref: Ref<HTMLElement>) => {
  const { country, ...restProps } = props;
  const src = COUNTRIES[country]?.flag;

  return <Avatar ref={ref} src={src} size={18} {...restProps} />;
});

export default Flag;
