import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import {
  getCoreRowModel,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table";
import { ReactTable } from "components/shared";
import Button from "components/shared/Button";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import useUser from "hooks/useUser";
import { isSponsorUser } from "utils/user/organization";
import getColumns from "./columns";
import type {
  THAQHistoryRowData,
  THAQShareHistoryModalProps,
} from "./HAQShareHistoryModal.types";
import { getRows } from "./HAQShareHistoryModal.utils";
import styles from "./HAQShareHistoryModal.styles";

const tableOptions: TableOptions<THAQHistoryRowData> = {
  columns: [],
  data: [],
  enableFilters: false,
  enableSorting: false,
  enableRowSelection: false,
  enableMultiRowSelection: false,
  enableGlobalFilter: false,
  getCoreRowModel: getCoreRowModel(),
};

const HAQShareHistoryModal = ({
  shareHistory,
  onClose,
  HAQName,
}: THAQShareHistoryModalProps) => {
  const { t } = useTranslation(["HAQ", "common"]);
  const { organization } = useUser();

  const columns = useMemo(() => getColumns(HAQName, t), [t, HAQName]);

  const rows = useMemo(
    () => getRows(shareHistory, organization, t),
    [shareHistory, organization, t],
  );

  const tableInstance = useReactTable<THAQHistoryRowData>({
    ...tableOptions,
    columns: columns,
    data: rows,
  });

  return (
    // prevents row selection firing from clicking modal overlay
    // TODO: FTE-28128
    <Box onClick={(event) => event.stopPropagation()}>
      <Modal
        data-qaid="haq-share-history-modal"
        open={!!shareHistory}
        title={
          isSponsorUser(organization)
            ? t("shareHistory.sponsorModalTitle")
            : t("shareHistory.HAModalTitle")
        }
        sx={styles.container}
      >
        <Stack spacing={2}>
          <ReactTable
            sx={styles.section}
            tableInstance={tableInstance}
            isPaginated={false}
            bodyRowHover={false}
          />
        </Stack>
        <ModalActionButtons>
          <Button
            data-qaid="haq-share-close-button"
            variant="text"
            onClick={onClose}
          >
            {t("button.close", { ns: "common" })}
          </Button>
        </ModalActionButtons>
      </Modal>
    </Box>
  );
};

export default HAQShareHistoryModal;
