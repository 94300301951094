import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { IShareHistoryItem } from "utils/constants/shareHistory.constants";

const useShareHistory = (id: string) => {
  const { t } = useTranslation("user", {
    keyPrefix: "notifications",
  });

  const { data, isLoading, isError, status } = useQuery<
    { content: IShareHistoryItem[] },
    Error
  >(
    [QueryAPIKey.GetAssetShareHistory, id],
    () => services.assets.getAssetShareHistory(id).then((res) => res.data),
    { enabled: Boolean(id) },
  );

  useShowAlert({
    status,
    failureMessage: t("shareHistoryFailure"),
  });

  return {
    shareHistory: data,
    isLoading,
    isError,
  };
};

export default useShareHistory;
