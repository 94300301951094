import { OktaAuth } from "@okta/okta-auth-js";
import getEnv from "env";
import { GraphQLClient } from "graphql-request";

export const getClient = (oktaAuth: OktaAuth): GraphQLClient =>
  new GraphQLClient(getEnv().graphQLUrl, {
    headers: {
      Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
    },
  });
