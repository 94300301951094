import { IOrganization } from "models/organizations.models";
import { getIsEmptyString } from "utils/helpers/getIsEmptyString";

const getOrganizationByName = (
  organizations: IOrganization[] | undefined,
  name: string | undefined,
) => {
  if (getIsEmptyString(name)) {
    return undefined;
  }

  return organizations?.find(
    (organization) =>
      organization.name === name || organization.shortName === name,
  );
};

export default getOrganizationByName;
