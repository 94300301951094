import { useTranslation } from "react-i18next";
import {
  BackgroundJobType,
  TFileUploadJob,
  useBackgroundJobs,
} from "context/BackgroundJobsContext";
import { BLOB_SIZE_THRESHOLD_IN_BYTES } from "utils/constants/size.constants";
import { getReadableFileSize } from "utils/helpers";
import { getRemainingUploadTime } from "utils/helpers/azureBlobStorage";

type TFileUploadStatusProps = {
  jobId: string;
};

const FileUploadStatus = ({ jobId }: TFileUploadStatusProps) => {
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation("common");

  const { getJob } = useBackgroundJobs();

  const job = getJob<TFileUploadJob>(jobId, BackgroundJobType.FileUpload);

  if (!job) {
    return <></>;
  }

  const {
    createdDate,
    data: {
      totalBytes,
      progress: { loadedBytes },
    },
  } = job;

  if (totalBytes <= BLOB_SIZE_THRESHOLD_IN_BYTES) {
    return <>{t("uploading")}</>;
  }

  if (loadedBytes === 0) {
    return <>{t("initializingUpload")}</>;
  }

  const sizeProgress = t("partOfTotal", {
    part: getReadableFileSize(loadedBytes, currentLanguage),
    total: getReadableFileSize(totalBytes, currentLanguage),
  });

  const remainingUploadTime = getRemainingUploadTime({
    startDate: createdDate,
    totalBytes,
    loadedBytes,
    currentLanguage,
  });

  const remainingTime = t("timeRemaining", { time: remainingUploadTime });

  return (
    <>
      {sizeProgress} - {remainingTime}
    </>
  );
};

export default FileUploadStatus;
