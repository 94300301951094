import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import {
  DocumentHistoryTable,
  TDocumentHistoryRowData,
} from "./DocumentShareHistory.types";

const columnHelper = createColumnHelper<TDocumentHistoryRowData>();

const getRecipientColumn = (header: string) =>
  columnHelper.accessor(DocumentHistoryTable.Recipient, {
    header,
    cell: ({ row }) => row.original.recipient,
    meta: { width: "32%" },
  });

const getDateColumn = (header: string) =>
  columnHelper.accessor(DocumentHistoryTable.Date, {
    header,
    meta: { width: "20%", headCellStyles: { pr: 0 } },
  });

const getCommonColumns = (t: TFunction) => [
  columnHelper.accessor(DocumentHistoryTable.Status, {
    header: t("documents.shareHistory.columns.status"),
    cell: ({ row }) => row.original.status,
    meta: { width: "37%" },
  }),

  columnHelper.display({
    header: t("documents.shareHistory.columns.actions"),
    cell: ({ row }) => row.original.actions,
    meta: { width: "11%", SROnly: true, bodyCellStyles: { py: 0 } },
  }),
];

const getColumns = (t: TFunction) => ({
  submitted: [
    getRecipientColumn(t("documents.shareHistory.columns.recipient.submit")),
    getDateColumn(t("documents.shareHistory.columns.date.submit")),
    ...getCommonColumns(t),
  ],
  shared: [
    getRecipientColumn(t("documents.shareHistory.columns.recipient.share")),
    getDateColumn(t("documents.shareHistory.columns.date.share")),
    ...getCommonColumns(t),
  ],
});

export default getColumns;
