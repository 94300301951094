import { PropsWithChildren, SyntheticEvent } from "react";
import { Dispatch, SetStateAction } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, Stack, SxProps, Theme } from "@mui/material";
import { FormDate, FormTextInput } from "components/common/form-fields";
import ButtonLink from "components/shared/ButtonLink";
import { SelectedHAQs } from "../SelectedHAQs";
import styles from "./HAQFormLayout.styles";

export type THAQFormLayoutProps<T extends FieldValues> = PropsWithChildren<{
  dateFieldName: FieldPath<T>;
  infoFieldName: FieldPath<T>;
  HAQsFieldName?: FieldPath<T>;
  sx?: SxProps<Theme>;
  setIsAddState?: Dispatch<SetStateAction<Boolean>>;
}>;

export function HAQFormLayout<T extends FieldValues>({
  dateFieldName,
  infoFieldName,
  HAQsFieldName,
  sx,
  children,
  setIsAddState,
}: THAQFormLayoutProps<T>) {
  const { t } = useTranslation("HAQ");

  return (
    <Stack gap={3} sx={sx} justifyContent="flex-start">
      {children}

      {HAQsFieldName && (
        <>
          <SelectedHAQs fieldName={HAQsFieldName} />
          <ButtonLink
            onClick={(event?: SyntheticEvent) => {
              event?.preventDefault();
              setIsAddState?.(true);
            }}
            sx={styles.addHAQsButton}
          >
            {t("buttonAddMoreHAQ")}
          </ButtonLink>
        </>
      )}

      <Grid container spacing={3} sx={styles.container}>
        <Grid item xs={6}>
          <FormDate<T>
            data-qaid="add-haq-due-date-field"
            disablePast
            name={dateFieldName}
            label={String(t("addHAQModal.fields.dueDate.label"))}
          />
        </Grid>

        <Grid item xs={12}>
          <FormTextInput<T>
            data-qaid="add-haq-instructions-text-field"
            name={infoFieldName}
            label={String(t("addHAQModal.fields.additionalInstructions.label"))}
            multiline
            rows={2}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
