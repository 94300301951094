import { BinaryChoice } from "utils/constants/common";

export enum UpdateEmailNotificationsForm {
  EmailNotifications = "emailNotifications",
}

export type TUpdateEmailNotificationsModalProps = {
  open: boolean;
  isEnabled: boolean;
  onClose?: () => void;
  onSave?: () => void;
};

export type TUpdateEmailNotificationsForm = {
  [UpdateEmailNotificationsForm.EmailNotifications]: BinaryChoice;
};
