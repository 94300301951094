import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  breadcrumbs: {
    fontSize: 12,
    color: getPaletteColor("blueGrey.600"),
    lineHeight: "20px",

    ".MuiBreadcrumbs-li:last-of-type": {
      color: getPaletteColor("blueGrey.800"),
    },
  },
};

export default styles;
