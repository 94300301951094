import { useMemo } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { FieldArrayPath } from "react-hook-form/dist/types/path";
import ItemAssignmentSelect, {
  TAssignmentFormData,
  TItemAssignmentSelectProps,
} from "components/shared/ItemAssignmentSelect";

export type TFormItemAssignmentProps = Omit<
  TItemAssignmentSelectProps,
  "fields" | "addItemForAssignment" | "assignItem" | "removeItem" | "getError"
> & {
  name: FieldArrayPath<TAssignmentFormData>;
};

const FormItemAssignment = ({
  name,
  itemOptions,
  ...props
}: TFormItemAssignmentProps) => {
  const { control, trigger } = useFormContext<TAssignmentFormData>();

  const { fields, append, update, remove } = useFieldArray<TAssignmentFormData>(
    {
      control,
      name,
      shouldUnregister: true,
    },
  );

  const addItemForAssignment = (itemValue: string) => {
    const targetItem = itemOptions.find((option) => option.value === itemValue);

    if (targetItem) {
      append({
        item: { label: targetItem.label, value: itemValue },
        assignment: { label: "", value: "" },
      });
    }
  };

  const assignItem = (value: string, fieldId: string) => {
    const fieldIndex = fields.findIndex((el) => el.id === fieldId);

    const item = {
      ...fields[fieldIndex],
      assignment: { label: value, value },
    };

    update(fieldIndex, item);
    trigger(name);
  };

  const availableItems = useMemo(() => {
    return itemOptions.filter(
      (option) =>
        !fields.some((assignment) => assignment.item.value === option.value),
    );
  }, [itemOptions, fields]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, formState: { errors } }) => (
        <ItemAssignmentSelect
          {...field}
          {...props}
          addItemForAssignment={addItemForAssignment}
          assignItem={assignItem}
          removeItem={remove}
          fields={fields}
          itemOptions={availableItems}
          getError={(index: number) =>
            errors?.[name]?.[index]?.assignment?.value
          }
        />
      )}
    />
  );
};

export default FormItemAssignment;
