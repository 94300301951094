import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { Row } from "@tanstack/react-table";
import { TFunction } from "i18next";
import ButtonMenu from "components/shared/ButtonMenu";
import { TLinkedOrganizationEntity } from "screens/EntityManagement/components/Organization/Organization.types";
import { TDrugClassType, TSubstance } from "../Substances.types";
import { TSubstancesRowData } from "./SubstancesTable.types";

export const getSubstancesForRender = (
  substances: TSubstance[] | undefined | null,
  t: TFunction,
  onClickManageSubstance: (substance: TSubstance) => void,
  canManageEntities: boolean,
): TSubstancesRowData[] =>
  substances?.map((substance) => {
    return {
      ...substance,
      class: substance.drugClassType,
      actions: (
        <ButtonMenu
          options={
            canManageEntities
              ? [
                  {
                    id: "edit-substance",
                    label: t("editSubstance", { ns: "administration" }),
                    disabled: !substance.actions.canEditEntity,
                    onClick: () => {
                      onClickManageSubstance(substance);
                    },
                  },
                ]
              : []
          }
        >
          <IconButton aria-label="actionsButton" disabled={!canManageEntities}>
            <MoreVertIcon />
          </IconButton>
        </ButtonMenu>
      ),
    };
  }) ?? [];

export const filterByLinkedOrg = (
  row: Row<TSubstancesRowData>,
  columnId: string,
  filterValue: string[],
) => {
  const rowOrgIds = (row.getValue(columnId) as TLinkedOrganizationEntity[]).map(
    (org) => org.id,
  );

  return filterValue.some((selectedOrgId) => rowOrgIds.includes(selectedOrgId));
};

export const filterByDrugClassType = (
  row: Row<TSubstancesRowData>,
  columnId: string,
  filterValue: string[],
) => {
  const rowDrugClass: TDrugClassType = row.getValue(columnId);

  return filterValue.some((selectedDrugClass) =>
    rowDrugClass.code.includes(selectedDrugClass),
  );
};
