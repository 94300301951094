import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  label: {
    mb: "15px",
    mt: 1,
    color: getPaletteColor("grey.700"),
  },
};

export default styles;
