import { AxiosResponse } from "axios";
import { del, get, patch, post, put, routes } from "api";
import { IClientConfig } from "api/types";
import {
  IAddProjectMemberRequestData,
  ICancelProjectRequestData,
  ICancelProjectResponseData,
  ICreateProjectRequestData,
  IGetProjectsRequestParams,
  IUpdateProjectRequestData,
  IUpdateProjectResponseData,
  IUpdateProjectStatusRequestData,
} from "models/projects.models";
import { ProjectTypeDefinition } from "screens/Projects/Projects.constants";

const projects = {
  getProject(id: string, config?: IClientConfig): Promise<AxiosResponse> {
    return get(routes.asset.projects.byId(id), config);
  },

  getProjects(
    params: IGetProjectsRequestParams = {},
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return get(routes.asset.projects.all(), {
      params,
      isMockApiOn,
    });
  },

  getQuestions(
    projectId: string,
    limit: number,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return get(routes.asset.projects.questions(projectId), {
      params: { limit },
      isMockApiOn,
    });
  },

  createProject(
    data: ICreateProjectRequestData,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return post(routes.asset.projects.all(), { data, isMockApiOn });
  },

  addProjectMembers(
    projectId: string,
    data: IAddProjectMemberRequestData[],
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return post(routes.asset.projects.members(projectId), {
      data,
      isMockApiOn,
    });
  },

  updateProjectStatus(
    projectId: string,
    data: IUpdateProjectStatusRequestData,
  ): Promise<AxiosResponse> {
    return patch(routes.asset.projects.status(projectId), {
      data,
    });
  },

  cancelProject(
    projectId: string,
    data: ICancelProjectRequestData,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return put<ICancelProjectResponseData>(
      routes.asset.projects.cancel(projectId),
      { data, isMockApiOn },
    );
  },

  updateProject(
    projectId: string,
    data: IUpdateProjectRequestData,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return put<IUpdateProjectResponseData>(
      routes.asset.projects.update(projectId),
      { data, isMockApiOn },
    );
  },

  removeMemberAccess(
    projectId: string,
    memberId: string,
  ): Promise<AxiosResponse> {
    return del(routes.asset.projects.membersById(projectId, memberId));
  },

  getProjectTypeSubtypes(
    projectType: string,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return get(routes.asset.projects.subTypes(projectType), { isMockApiOn });
  },

  getProjectTypes(isMockApiOn?: boolean): Promise<AxiosResponse> {
    return get(routes.asset.projects.projectTypes(), { isMockApiOn });
  },

  getProjectTypesDefinition(params: {
    name: ProjectTypeDefinition;
    assigned: boolean;
  }): Promise<AxiosResponse> {
    return get(routes.asset.projects.projectTypesDefinitions(), { params });
  },

  getProjectTypeMetadata(projectType: string): Promise<AxiosResponse> {
    return get(routes.asset.projects.byProjectType(projectType));
  },
};

export default projects;
