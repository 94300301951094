import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { Button } from "components/shared";
import NoDataFallback from "components/shared/NoDataFallback";
import { useDossierDifferenceModal } from "screens/Project/screens/GSP/GSPItems";
import { PlusIcon } from "assets/icons";

type TEmptyState = {
  isEditMode: boolean;
};

const EmptyState = ({ isEditMode }: TEmptyState) => {
  const { onOpen } = useDossierDifferenceModal();

  const { t } = useTranslation("projects", {
    keyPrefix: "dossierDifferences",
  });

  return (
    <Stack alignItems="center">
      <NoDataFallback
        message={t("noSummaryText")}
        textColor="text.secondary"
        noBorders
      />

      {isEditMode && (
        <Button
          sx={{ mt: -1 }}
          data-qaid="empty-state-add-difference-button"
          variant={"outlined"}
          startIcon={<PlusIcon />}
          onClick={() => onOpen()}
        >
          {t("addDifferenceButton")}
        </Button>
      )}
    </Stack>
  );
};

export default EmptyState;
