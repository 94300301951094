import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useProject } from "context/ProjectContext";
import useStartWorkflow from "hooks/api/REST/workflow/useStartWorkflow";
import useUser from "hooks/useUser";
import { IDocument, IDocumentMetadata } from "models/documents.models";
import { DocumentStatus } from "utils/constants/doc.constants";
import { WorkflowId } from "utils/constants/workflows.constants";
import { isHAUser } from "utils/user/organization";

export const useChangeDocumentStatus = () => {
  const { user, organization } = useUser();

  const [workflowInProgressId, setWorkflowInProgressId] = useState<
    string | null
  >(null);

  const { t } = useTranslation("notifications");

  const { project } = useProject();

  const { startWorkflow } = useStartWorkflow({
    successMessage: t("workflowMessages.changeDocumentStatusSuccessMessage"),
    failureMessage: t("workflowMessages.changeDocumentStatusErrorMessage"),
  });

  const initiateWorkflow = useCallback(
    (
      documents: IDocument<IDocumentMetadata>[],
      targetStatus: DocumentStatus,
      onClose: () => void,
    ) => {
      if (workflowInProgressId) {
        return;
      }

      if (user?.id && organization && project?.id) {
        startWorkflow(
          {
            id: isHAUser(organization)
              ? WorkflowId.HAAssetManualStatusChange
              : WorkflowId.SponsorAssetManualStatusChange,
            payload: {
              originator: user.id,
              assets: documents.map((document) => document.id),
              status: targetStatus,
              project: project.id,
            },
          },
          {
            onSuccess: (response) => {
              setWorkflowInProgressId(response?.data?.id);
              onClose();
            },
          },
        );
      }
    },
    [
      startWorkflow,
      user?.id,
      organization,
      workflowInProgressId,
      setWorkflowInProgressId,
      project?.id,
    ],
  );

  return {
    workflowInProgressId,
    setWorkflowInProgressId,
    initiateWorkflow,
  };
};
