import { ReactNode } from "react";

export enum DocumentsTable {
  Id = "id",
  Name = "name",
  FileType = "fileType",
  Type = "type",
  Status = "status",
  WorkflowStatus = "workflowStatus",
  Version = "version",
  LastModified = "lastModifiedDate",
  Actions = "actions",
}

export type TDocumentsRowData = {
  [DocumentsTable.Id]: string;
  [DocumentsTable.Name]: ReactNode;
  [DocumentsTable.FileType]: string;
  [DocumentsTable.Type]: string;
  [DocumentsTable.Status]: ReactNode;
  [DocumentsTable.WorkflowStatus]?: string | null;
  [DocumentsTable.Version]: string;
  [DocumentsTable.LastModified]: string;
  [DocumentsTable.Actions]?: ReactNode;
};
