import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { NavLink, useLocation } from "react-router-dom";
import { AccountCircleOutlined } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import Tooltip from "components/shared/Tooltip";
import { useFeatureFlags } from "context/FeatureFlagsContext";
import useGetUnreadNotificationCount from "hooks/api/REST/notifications/useGetUnreadNotificationCount";
import useUser from "hooks/useUser";
import { Path } from "router/paths";
import { signOut } from "utils/auth";
import {
  DEFAULT_NOTIFICATIONS_POLLING_INTERVAL,
  NOTIFICATIONS_CURRENT_USER,
  QueryAPIKey,
} from "utils/constants/api.constants";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import { ChangeLanguageMenu } from "./ChangeLanguageMenu";
import MainNavigationList from "./MainNavigationList";
import Notifications from "./Notifications";
import LogoSmall from "assets/images/accumulus_logo_nav_icon.svg";
import LogoLarge from "assets/images/accumulus_logo_nav_large.svg";
import { ReactComponent as CloseNavIcon } from "assets/images/align-left-01.svg";
import { ReactComponent as OpenNavIcon } from "assets/images/align-right-01.svg";
import { ReactComponent as NotificationIcon } from "assets/images/bell-01.svg";
import { ReactComponent as NotificationIconWithDot } from "assets/images/bell-with-dot.svg";
import styles from "./GlobalNavigation.styles";

const GlobalNavigation = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const { user } = useUser();
  const featureFlags = useFeatureFlags();
  const queryClient = useQueryClient();

  const { isSuccess, unreadNotificationCount } = useGetUnreadNotificationCount(
    NOTIFICATIONS_CURRENT_USER,
    { read: false, size: 1 },
    DEFAULT_NOTIFICATIONS_POLLING_INTERVAL,
  );

  const hasUnreadNotifications = useMemo(() => {
    return Boolean(
      isSuccess && unreadNotificationCount && unreadNotificationCount > 0,
    );
  }, [isSuccess, unreadNotificationCount]);

  useEffect(() => {
    // Refetch the full notification data if there are new unread notifications
    if (unreadNotificationCount && unreadNotificationCount > 0) {
      queryClient.invalidateQueries([
        QueryAPIKey.GetNotificationsForUser,
        user?.id,
      ]);
    }
  }, [unreadNotificationCount, queryClient, user]);

  // Close notification when navigation occurs
  useEffect(() => {
    setIsNotificationsOpen(false);
  }, [location]);

  const handleNotificationsMenuClick = () => {
    if (!isNotificationsOpen && isOpen) {
      setIsOpen(false);
    }

    setIsNotificationsOpen((prevOpenedState) => !prevOpenedState);
  };

  const handleLogout = () => {
    signOut({
      shouldRestorePath: false,
      shouldLogoutEverywhere: true,
    }).catch(console.error);
  };

  const handleDrawerOpen = () => {
    setIsOpen(true);
  };

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const handleNotificationsDrawerClose = () => {
    setIsNotificationsOpen(false);
  };

  const notificationMenuItemStyle = useMemo(() => {
    if (isOpen) {
      if (isNotificationsOpen) {
        return styles.notificationMenuItemActive;
      } else {
        return styles.notificationMenuItem;
      }
    } else {
      if (isNotificationsOpen) {
        return styles.notificationMenuItemDrawerClosedActive;
      } else {
        return styles.notificationMenuItemDrawerClosed;
      }
    }
  }, [isOpen, isNotificationsOpen]);

  const { t } = useTranslation("user", {
    keyPrefix: "notificationsGlobalNavigation",
  });

  return (
    <>
      <Drawer
        data-testid="left-hand-drawer"
        data-qaid="left-hand-drawer"
        variant="permanent"
        anchor="left"
        open={isOpen}
        sx={isOpen ? styles.drawerOpen : styles.drawerClosed}
      >
        <Stack direction="column" sx={styles.container}>
          <Box
            sx={isOpen ? styles.logoContainerOpen : styles.logoContainerClosed}
            role={t("banner")}
          >
            <img
              data-testid="left-hand-drawer-logo"
              data-qaid="left-hand-drawer-logo"
              src={isOpen ? LogoLarge : LogoSmall}
              alt={t("listItemButtonNotificationsAriaLabel")}
            />
          </Box>
          <Box sx={styles.mainNavContainer}>
            <MainNavigationList isSidebarExpanded={isOpen} />
          </Box>
          <Box sx={styles.additionalNavContainer} role={t("navigation")}>
            <List disablePadding sx={styles.navMenuList}>
              <ListItem disablePadding sx={notificationMenuItemStyle}>
                <ListItemButton
                  data-testid="left-hand-navigation-notifications"
                  data-qaid="left-hand-navigation-notifications"
                  aria-label={t("listItemButtonNotificationsAriaLabel")}
                  sx={styles.navItem}
                  onClick={handleNotificationsMenuClick}
                >
                  <ListItemIcon>
                    {hasUnreadNotifications ? (
                      <NotificationIconWithDot
                        data-testid="left-hand-navigation-unread-notifications-icon"
                        data-qaid="left-hand-navigation-unread-notifications-icon"
                        title={t("notificationIconWithDotTitle")}
                      />
                    ) : (
                      <NotificationIcon
                        data-testid="left-hand-navigation-notifications-icon"
                        data-qaid="left-hand-navigation-notifications-icon"
                        title={t("notificationIcon")}
                      />
                    )}
                  </ListItemIcon>
                  {isOpen && (
                    <ListItemText
                      primary={t("notificationButton")}
                      sx={styles.navMenuItemButtonText}
                    />
                  )}
                </ListItemButton>
              </ListItem>

              {featureFlags["showTranslateMenu"] === FeatureFlag.On && (
                <ChangeLanguageMenu isSidebarExpanded={isOpen} />
              )}

              <ListItem
                disablePadding
                sx={
                  isOpen ? styles.profileMenuItem : styles.profileMenuItemClosed
                }
              >
                <ListItemButton
                  data-testid="left-hand-navigation-profile"
                  data-qaid="left-hand-navigation-profile"
                  aria-label={t("listItemButtonProfileAriaLabel")}
                  sx={styles.navItem}
                  component={NavLink}
                  to={Path.Profile}
                >
                  <ListItemIcon>
                    <AccountCircleOutlined
                      sx={styles.profileMenuIcon}
                      data-testid="left-hand-navigation-profile-icon"
                      data-qaid="left-hand-navigation-profile-icon"
                    />
                  </ListItemIcon>
                  {isOpen && (
                    <ListItemText
                      primary={user?.firstName}
                      sx={styles.navMenuItemButtonText}
                    />
                  )}
                </ListItemButton>
                {isOpen && (
                  <Tooltip placement="right" title={t("logoutTooltipTitle")}>
                    <IconButton
                      data-testid="left-hand-navigation-logout-button"
                      data-qaid="left-hand-navigation-logout-button"
                      sx={styles.profileMenuItemLogoutArrowIcon}
                      onClick={handleLogout}
                      aria-label={t("logoutIconButton")}
                      disableFocusRipple
                    >
                      <LogoutIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </ListItem>
            </List>
            <Divider
              sx={isOpen ? styles.divider : styles.dividerDrawerClosed}
            />
            <List disablePadding sx={styles.navMenuList}>
              <ListItem
                disablePadding
                sx={
                  isOpen
                    ? styles.drawerToggleMenuItem
                    : styles.drawerToggleMenuItemDrawerClosed
                }
              >
                <ListItemButton
                  data-testid="left-hand-navigation-close-drawer-button"
                  data-qaid="left-hand-navigation-close-drawer-button"
                  sx={styles.navItem}
                  aria-label={t("listItemButtonDrawerToggleAriaLabel")}
                  onClick={isOpen ? handleDrawerClose : handleDrawerOpen}
                >
                  <ListItemIcon>
                    {isOpen ? (
                      <CloseNavIcon title={t("closeNavIconTitle")} />
                    ) : (
                      <OpenNavIcon title={t("openNavIconTitle")} />
                    )}
                  </ListItemIcon>
                  {isOpen && <ListItemText primary={t("closeMenuIconTitle")} />}
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Stack>
      </Drawer>
      <Notifications
        isOpen={isNotificationsOpen}
        onClose={handleNotificationsDrawerClose}
      />
    </>
  );
};

export default GlobalNavigation;
