import { getCTOCDocument, TCTOCItem } from "./CTOCDocument";

type TCTOCDocumentMap = { [key: string]: string[] };

const getSectionsNames = (items: TCTOCItem[]) =>
  items.reduce((acc: string[], currentItem) => {
    acc.push(currentItem.name);

    if (currentItem.children) {
      acc.push(...getSectionsNames(currentItem.children));
    }

    return acc;
  }, []);

export const CTOC_DOCUMENT_MODULES_MAP: TCTOCDocumentMap =
  getCTOCDocument().reduce((map: TCTOCDocumentMap, currentModule) => {
    map[currentModule.name] = getSectionsNames(currentModule.children);

    return map;
  }, {});

const getModuleSectionsMap = (items: TCTOCItem[]) =>
  items.reduce((acc: TCTOCDocumentMap, currentItem) => {
    if (!currentItem.children?.length) {
      return acc;
    }

    acc[currentItem.name] = currentItem.children.map(
      (childItem) => childItem.name,
    );

    const result: TCTOCDocumentMap = {
      ...acc,
      ...getModuleSectionsMap(currentItem.children),
    };

    return result;
  }, {});

export const CTOC_DOCUMENT_SECTIONS_MAP: TCTOCDocumentMap =
  getCTOCDocument().reduce((map: TCTOCDocumentMap, currentModule) => {
    return { ...map, ...getModuleSectionsMap(currentModule.children) };
  }, {});
