import {
  forwardRef,
  HTMLAttributes,
  Ref,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteProps,
  MenuItem,
  Typography,
} from "@mui/material";
import TextField, { TTextFieldProps } from "components/shared/TextField";
import styles from "./SingleSelectTypeahead.styles";

export type TSingleSelectTypeaheadOptionProps = {
  noWrap?: boolean;
};

export type TSingleSelectTypeaheadOption = {
  id?: string;
  label: string;
  value: string;
  disabled?: boolean;
};

export type TSingleSelectTypeaheadProps<T> = Omit<
  AutocompleteProps<
    TSingleSelectTypeaheadOption, // type of value
    false, // Multiple
    false, // DisableClearable
    false // FreeSolo
  >,
  "onChange" | "renderInput"
> & {
  testId?: string;
  required?: boolean;
  error?: boolean;
  label?: string;
  helperText?: string;
  options: TSingleSelectTypeaheadOption[];
  emptyLabel?: string;
  OptionProps?: TSingleSelectTypeaheadOptionProps;
  textFieldProps?: TTextFieldProps;
  getValue: (option: TSingleSelectTypeaheadOption) => T;
  onChange?: (value: T | null) => void;
  onClose?: () => void;
};

const Option = ({
  optionProps,
  option,
  noWrap,
}: {
  optionProps: HTMLAttributes<HTMLLIElement>;
  option: TSingleSelectTypeaheadOption;
  noWrap?: boolean;
}) => {
  const { label, disabled } = option;

  const optionStyles = {
    ...styles.option,
    ...(noWrap && styles.noWrapOption),
  };

  return (
    <MenuItem {...optionProps} disabled={disabled} sx={optionStyles}>
      <Typography noWrap={noWrap}>{label}</Typography>
    </MenuItem>
  );
};

const SingleSelectTypeahead = forwardRef(
  <T extends unknown>(
    props: TSingleSelectTypeaheadProps<T>,
    ref: Ref<HTMLDivElement>,
  ) => {
    const {
      testId,
      error,
      value,
      label,
      required,
      options,
      helperText,
      placeholder,
      emptyLabel,
      textFieldProps,
      OptionProps,
      getValue,
      onChange,
      onClose,
      ...autocompleteProps
    } = props;

    const [selectedOption, setSelectedOption] =
      useState<TSingleSelectTypeaheadOption | null>(value || null);

    const handleChange = useCallback(
      (
        _event: SyntheticEvent<Element, Event>,
        selectedValue: TSingleSelectTypeaheadOption | null,
        _reason: AutocompleteChangeReason,
      ) => {
        onChange?.(
          selectedValue
            ? getValue(selectedValue as TSingleSelectTypeaheadOption)
            : null,
        );

        setSelectedOption(selectedValue);
      },
      [getValue, onChange],
    );

    return (
      <Autocomplete
        data-testid={testId}
        data-qaid={testId}
        renderOption={(optionProps, option) => (
          <Option
            key={option.value}
            optionProps={optionProps}
            option={option}
            {...OptionProps}
          />
        )}
        options={options}
        value={selectedOption}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={({ InputProps: { ...restInputProps }, ...restParams }) => {
          const inputProps = {
            ...restInputProps,
            "data-qaid": `${testId}-input`,
          };

          return (
            <TextField
              ref={ref}
              value={selectedOption}
              label={label}
              emptyLabel={emptyLabel}
              error={error}
              helperText={helperText}
              placeholder={placeholder}
              required={required}
              InputProps={{
                ...inputProps,
              }}
              FormHelperTextProps={{
                variant: "standard",
                "aria-live": "polite",
              }}
              {...restParams}
              {...textFieldProps}
            />
          );
        }}
        sx={styles.autocomplete}
        onClose={onClose}
        onChange={handleChange}
        {...autocompleteProps}
      />
    );
  },
);

export default SingleSelectTypeahead as <T>(
  props: TSingleSelectTypeaheadProps<T>,
  ref: Ref<HTMLDivElement>,
) => ReturnType<typeof SingleSelectTypeahead>;
