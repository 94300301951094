import getEnv from "env";
import { oktaAuth } from "utils/auth";
import { IClientConfig } from "../types";
import { configureRequest } from "../utils/request";
import { client } from "./client";

async function makeRequest<SuccessPayload>({
  headers = {},
  params = {},
  isMockApiOn = false,
  ...restConfig
}: IClientConfig) {
  const ENV = getEnv();
  const baseURL = isMockApiOn ? ENV.mockApiUrl : ENV.apiUrl;

  const config = {
    baseURL,
    headers: {
      Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      ...(isMockApiOn ? { "x-api-key": ENV.mockApiKey } : {}),
      ...headers,
    },
    params,
    ...restConfig,
  };

  return configureRequest(() => client.request<SuccessPayload>(config));
}

export function get<SuccessPayload>(url: string, config?: IClientConfig) {
  return makeRequest<SuccessPayload>({ url, method: "GET", ...config });
}

export function post<SuccessPayload>(url: string, config?: IClientConfig) {
  return makeRequest<SuccessPayload>({ url, method: "POST", ...config });
}

export function put<SuccessPayload>(url: string, config?: IClientConfig) {
  return makeRequest<SuccessPayload>({ url, method: "PUT", ...config });
}

export function patch<SuccessPayload>(url: string, config?: IClientConfig) {
  return makeRequest<SuccessPayload>({ url, method: "PATCH", ...config });
}

export function del<SuccessPayload>(url: string, config?: IClientConfig) {
  return makeRequest<SuccessPayload>({ url, method: "DELETE", ...config });
}
