import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import {
  FormCheckbox,
  FormDate,
  FormSelect,
} from "components/common/form-fields";
import { useProject } from "context/ProjectContext";
import useUser from "hooks/useUser";
import { ImportModalType } from "screens/Project/sections/Documents/modals/ImportDocument";
import { ImportFormReferencesFieldName } from "screens/Project/sections/Documents/modals/ImportDocument";
import { TImportForm } from "screens/Project/sections/Documents/modals/ImportDocument/ImportDocument.types";
import { getHAsExceptHostHA } from "utils/helpers";
import { mapOrganizationsToSelectOptions } from "utils/helpers/mapOrganizationsToSelectOptions";
import { isSponsorUser } from "utils/user/organization";

type TReferencesAdditionalFieldsProps = {
  openModalType: ImportModalType;
};

const ReferencesAdditionalFields = (
  props: TReferencesAdditionalFieldsProps,
) => {
  const { t } = useTranslation("documents", {
    keyPrefix: "importDocument",
  });

  const { organization } = useUser();

  const { participatingHAOrganizations } = useProject();

  const participatingHAOrganizationsExceptHostHA = useMemo(
    () => getHAsExceptHostHA(participatingHAOrganizations),
    [participatingHAOrganizations],
  );

  const HAOptions = useMemo(
    () =>
      mapOrganizationsToSelectOptions(participatingHAOrganizationsExceptHostHA),
    [participatingHAOrganizationsExceptHostHA],
  );

  const { watch } = useFormContext<TImportForm>();

  const isSAL = watch(ImportFormReferencesFieldName.SAL);

  return (
    <>
      {isSponsorUser(organization) &&
        !isEmpty(participatingHAOrganizationsExceptHostHA) && (
          <>
            <Grid item xs={12}>
              <FormCheckbox
                disabled={props.openModalType === ImportModalType.SAL}
                name={ImportFormReferencesFieldName.SAL}
                label={t("fields.sponsorAuthorizationLetter.label")}
              />
            </Grid>
            {isSAL && (
              <Grid item xs={12}>
                <FormSelect
                  data-qaid="import-form-references-ha-select"
                  name={ImportFormReferencesFieldName.HealthAuthority}
                  label={t("fields.healthAuthority.label")}
                  options={HAOptions}
                />
              </Grid>
            )}
          </>
        )}
      <Grid item xs={12}>
        <FormDate
          data-qaid="references-submission-date-field"
          name={ImportFormReferencesFieldName.FormalSubmissionDate}
          label={t("fields.formalSubmissionDate.label")}
          disablePast
        />
      </Grid>
    </>
  );
};

export default ReferencesAdditionalFields;
