import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const heading: TStyles[keyof TStyles] = {
  display: "flex",
  height: 70,
  alignItems: "center",
  justifyContent: "space-between",
  paddingRight: 2,

  "& .MuiTypography-root": {
    fontFamily: (theme) => theme.typography.fontFamilyReadexPro,
    fontSize: 20,
    fontWeight: 500,
    color: getPaletteColor("grey.900"),
    lineHeight: 1.6,
  },
};

const styles: TStyles = {
  heading: {
    ...heading,
    width: "100%",
  },

  headingSummary: {
    ...heading,
  },

  accordion: {
    boxShadow: 0,

    ".MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded": {
      margin: 0,
    },
  },

  accordionSummary: {
    px: 3,

    "& .MuiAccordionSummary-expandIconWrapper": {
      color: "inherit",
    },

    "& .MuiAccordionSummary-content": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },

  accordionDetails: {
    p: (theme) => theme.spacing(0, 3, 3, 3),
  },

  content: {
    borderTop: getBorderStyle("1px", "solid", "divider"),
  },

  addMoreBtn: {
    paddingRight: 2,
  },

  error: {
    border: getBorderStyle("1px", "solid", "red.400"),
    borderRadius: "4px",
  },
};

export default styles;
