import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import {
  ProductVariantsTable,
  TProductVariantsRowData,
} from "./ProductVariantsTable.types";

const columnHelper = createColumnHelper<TProductVariantsRowData>();

export const getColumns = (t: TFunction) => [
  columnHelper.accessor(ProductVariantsTable.Id, {
    header: "",
    enableColumnFilter: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor(ProductVariantsTable.Type, {
    header: t("productsColumns.type"),
    meta: {
      width: "20%",
      headCellStyles: { pl: 9 },
      bodyCellStyles: { pl: 9 },
    },
  }),
  columnHelper.accessor(ProductVariantsTable.RouteOfAdministration, {
    header: t("productsColumns.routeOfAdministration"),
    meta: {
      headerTooltip: t("productsColumns.routeOfAdministrationTooltip"),
      width: "17%",
    },
  }),
  columnHelper.accessor(ProductVariantsTable.ManufacturedDose, {
    header: t("productsColumns.manufacturedDose"),
    meta: {
      headerTooltip: t("productsColumns.manufacturedDoseTooltip"),
      width: "34%",
    },
  }),
  columnHelper.accessor(ProductVariantsTable.Strength, {
    header: t("productsColumns.strength"),
    meta: { width: "17%" },
  }),
  columnHelper.accessor(ProductVariantsTable.Status, {
    header: t("productsColumns.status"),
    meta: { width: "7%" },
  }),

  columnHelper.display({
    header: t("productsColumns.actions"),
    cell: ({ row }) => row.original.actions,
    meta: { SROnly: true, width: "5%" },
  }),
];
