import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useAlerts } from "context/AlertContext";
import { TUserData } from "models/users.models";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";

export default function useUpdateUser() {
  const queryClient = useQueryClient();
  const { showAlert } = useAlerts();

  const { t } = useTranslation("user", {
    keyPrefix: "notifications",
  });

  const { mutateAsync: updateUser } = useMutation((data: TUserData) =>
    services.user
      .updateUser(data)
      .then(() => {
        showAlert({
          message: t("updateUserSuccess"),
          severity: AlertSeverity.Success,
        });

        return queryClient.invalidateQueries(QueryAPIKey.GetCurrentUser);
      })
      .catch(() => {
        showAlert({
          message: t("updateUserFailure"),
          severity: AlertSeverity.Error,
        });
      }),
  );

  return { updateUser };
}
