import { TStyles } from "utils/Styles";

const styles: TStyles = {
  table: {
    "& .MuiTableCell-head": {
      lineHeight: 1.43,
      py: 2.25,
    },
  },
};

export default styles;
