import { Stack } from "@mui/material";
import { useFeatureFlags } from "context/FeatureFlagsContext";
import useUser from "hooks/useUser";
import { ImportModalType } from "screens/Project/sections/Documents/modals/ImportDocument";
import { DocumentSubtype } from "utils/constants/doc.constants";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import { isSponsorUser } from "utils/user/organization";
import { GenericTeaser } from "../components";
import { TDocumentsTeasersProps } from "../DocumentsTeasers.types";

const RelianceDocumentsTeasers = ({
  onAddDocument,
}: TDocumentsTeasersProps) => {
  const { organization } = useUser();
  const featureFlags = useFeatureFlags();

  const canShowProjectDossierUploadCard =
    featureFlags["FTE-23984_enableDossierUploadCard"] === FeatureFlag.On;

  return isSponsorUser(organization) ? (
    <Stack direction={"row"} spacing={2} marginBottom={2}>
      {canShowProjectDossierUploadCard && (
        <GenericTeaser
          key={DocumentSubtype.ECTD}
          documentType={DocumentSubtype.ECTD}
          onAddDocument={() => onAddDocument(ImportModalType.ECTD)}
        />
      )}
      <GenericTeaser
        key={DocumentSubtype.AssessmentReport}
        documentType={DocumentSubtype.AssessmentReport}
        onAddDocument={() => onAddDocument(ImportModalType.AssessmentReport)}
      />
      <GenericTeaser
        key={DocumentSubtype.HADecisionLetter}
        documentType={DocumentSubtype.HADecisionLetter}
        onAddDocument={() => onAddDocument(ImportModalType.HADecisionLetter)}
      />
    </Stack>
  ) : null;
};

export default RelianceDocumentsTeasers;
