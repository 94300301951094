import { Box, Chip } from "@mui/material";
import { FilterType, TFilterOption } from "components/shared/types/filter";
import styles from "./FilterChips.styles";

export type TActiveFilter = {
  key: string;
  label: string;
  option: TFilterOption;
  type?: FilterType;
};

export type TFilterChipsProps = {
  onDelete: (key: string, value: string) => void;
  activeFilters: TActiveFilter[];
};

const FilterChips = (props: TFilterChipsProps) => {
  const getText = (option: TFilterOption, type?: FilterType) => {
    if (type === FilterType.Date) {
      const filterDate = new Date(option.text);

      return filterDate.toDateString();
    } else {
      return option.text;
    }
  };

  return (
    <Box sx={styles.container}>
      {props.activeFilters.map(({ key, label, option, type }) => (
        <Chip
          data-testid="filter-chip"
          data-qaid="filter-chip"
          aria-label={`${label}: ${option.text}`}
          key={`${key}-${option.value}`}
          label={
            <>
              <span className="filterName">{label}</span>:{" "}
              {getText(option, type)}
            </>
          }
          onDelete={() => props.onDelete(key, option.value)}
          size="small"
          sx={styles.filterChip}
        />
      ))}
    </Box>
  );
};

export default FilterChips;
