import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    px: 5,
    py: 3,
  },

  header: {
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 3,
  },

  table: {
    "& .MuiTableCell-head": {
      color: "text.primary",
      fontWeight: 500,
      lineHeight: 1.43,

      "&:not(.MuiTableCell-paddingCheckbox)": {
        py: 2.25,
      },
    },
  },
};

export default styles;
