import { TFunction } from "i18next";
import isEmpty from "lodash/isEmpty";
import upperFirst from "lodash/upperFirst";
import {
  TPortfolioItem,
  TTherapeuticProduct,
} from "screens/Portfolio/Portfolio.types";
import { TTherapeuticRowData } from "./TherapeuticSummary.types";

export const getTherapeuticProductsForRender = (
  t: TFunction,
  therapeuticProductData?: TTherapeuticProduct[],
): TTherapeuticRowData[] => {
  return therapeuticProductData && !isEmpty(therapeuticProductData)
    ? therapeuticProductData?.map((therapeutic) => ({
        ...therapeutic,
        country: {
          id: therapeutic?.jurisdiction?.id,
          description: therapeutic?.jurisdiction?.description,
        },
        status: t(`therapeutic.${therapeutic.status}`, {
          ns: "statuses",
        }),
      }))
    : [];
};

export const getTherapeuticSummaryPageTitle = (
  therapeuticId?: string,
  portfolioData?: TPortfolioItem[],
) => {
  return !isEmpty(portfolioData)
    ? upperFirst(
        portfolioData?.find(({ id }) => id === therapeuticId)?.ingredient,
      )
    : "";
};
