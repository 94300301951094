import { useCallback, useEffect, useState } from "react";
import {
  unstable_useBlocker as useBlocker,
  useBeforeUnload,
} from "react-router-dom";
import type { Location } from "@remix-run/router";
import useUserIdleState from "hooks/useUserIdleState";
import useWindowVisibilityChange from "hooks/useWindowVisibilityChange";
import { REACQUIRE_EDIT_LOCK_THRESHOLD_IN_MINUTES } from "utils/constants/time.constants";
import { IDLE_TIME_LIMIT_IN_MINUTES } from "./constants";

export enum NavigationType {
  InApp = "inApp",
  Browser = "browser",
}

export type TNavigationAlert = NavigationType | null;

const useCheckRedirection = (isEnabled: boolean) => {
  const [alertNavigationType, setAlertNavigationType] =
    useState<TNavigationAlert>(null);

  const { isWindowVisible } = useWindowVisibilityChange();

  const { isUserIdle, idleTime } = useUserIdleState({
    timeout: IDLE_TIME_LIMIT_IN_MINUTES,
    isEnabled,
  });

  // this timer depends on the idle state timeout
  // general idle time to set read only is 30 minutes
  const minutesBeingIdle = idleTime / 60;

  const hasToSetReadOnly =
    minutesBeingIdle >= REACQUIRE_EDIT_LOCK_THRESHOLD_IN_MINUTES;

  // For handling close tab/window event
  const unloadFunction = useCallback(
    (e: BeforeUnloadEvent) => {
      if (isEnabled && !hasToSetReadOnly) {
        e.preventDefault();
        e.returnValue = "";
        setAlertNavigationType(NavigationType.Browser);
      }
    },
    [isEnabled, hasToSetReadOnly],
  );

  // For handling close tab/window event [2]
  useBeforeUnload(unloadFunction);

  // For in app navigation
  const blockerFunction = useCallback(
    ({
      currentLocation,
      nextLocation,
    }: {
      currentLocation: Location;
      nextLocation: Location;
    }) => {
      const blockRedirection =
        currentLocation.pathname !== nextLocation.pathname;

      blockRedirection && setAlertNavigationType(NavigationType.InApp);

      return blockRedirection && isEnabled;
    },
    [isEnabled],
  );

  const { proceed } = useBlocker(blockerFunction);

  useEffect(() => {
    if ((isUserIdle || !isWindowVisible) && isEnabled) {
      setAlertNavigationType(NavigationType.Browser);
    }
  }, [isUserIdle, isWindowVisible, proceed, isEnabled]);

  return {
    alertNavigationType,
    setAlertNavigationType,
    proceed,
    hasToSetReadOnly,
  };
};

export default useCheckRedirection;
