import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { IAssetType } from "models/assets.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export default function useAssetMetadata(type: string) {
  const { t } = useTranslation("projects", {
    keyPrefix: "notifications",
  });

  const {
    data: assetMeta,
    isLoading,
    isError,
    status,
    isSuccess,
  } = useQuery<IAssetType>(
    [QueryAPIKey.GetAssetMetadata],
    () => services.assets.getAssetMetadata(type).then((res) => res.data),
    {
      enabled: !!type,
    },
  );

  useShowAlert({
    status,
    failureMessage: t("getAssetMetaFailure"),
  });

  return {
    assetMeta,
    isLoading,
    isError,
    isSuccess,
  };
}
