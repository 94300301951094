import { useTranslation } from "react-i18next";
import TableFilter from "components/shared/TableFilter";
import { TFiltersUpdater } from "components/shared/types/filter";
import useReactTableFilters from "hooks/filters/useReactTableFilters";
import useTherapeuticFilters from "../hooks/useTherapeuticSummaryFilters";
import { TTherapeuticRowData } from "../TherapeuticSummary.types";

type TTherapeuticSummaryHeaderProps = {
  setAllFilters: (updater: TFiltersUpdater) => void;
  setGlobalFilter: (text: string) => void;
  therapeuticItems: TTherapeuticRowData[];
};

const TherapeuticSummaryHeader = ({
  setGlobalFilter,
  setAllFilters,
  therapeuticItems,
}: TTherapeuticSummaryHeaderProps) => {
  const { t } = useTranslation();

  const { filterParams, onFiltersChange, keywordParam, onKeywordChange } =
    useReactTableFilters(setGlobalFilter, setAllFilters);

  const therapeuticFilters = useTherapeuticFilters(therapeuticItems);

  return (
    <TableFilter
      filters={therapeuticFilters}
      keywordFilterValue={keywordParam}
      onKeywordChange={onKeywordChange}
      selectedFilters={filterParams}
      onFiltersChange={onFiltersChange}
      filterByKeywordLabel={t("keywordFilter.filterByKeyword", {
        ns: "common",
      })}
      showButtonLabel={t("common:filter.showFilterButton")}
      hideButtonLabel={t("common:filter.hideFilterButton")}
      clearAllFiltersLabel={t("common:filter.clearAllFiltersLabel")}
      errorAdornmentAriaLabel={t("common:ariaLabels.textFieldError")}
    />
  );
};

export default TherapeuticSummaryHeader;
