import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";

export interface IUseUserIdleState {
  isUserIdle: boolean;
  idleTime: number;
}

const useUserIdleState = ({
  timeout,
  isEnabled,
  retryInterval = 500,
}: {
  timeout: number;
  isEnabled: boolean;
  retryInterval?: number;
}): IUseUserIdleState => {
  const [isIdle, setIsIdle] = useState(false);
  const [idleTime, setIdleTime] = useState(0);

  const handleOnIdle = () => {
    setIsIdle(true);
  };

  const handleOnActive = () => {
    reset();
    setIsIdle(false);
  };

  const { getIdleTime, reset, pause, start } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    debounce: 1000,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIdleTime(Math.ceil(getIdleTime() / 1000));
    }, retryInterval);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    if (!isEnabled) {
      pause();
    }

    if (isEnabled) {
      start();
    }
  }, [isEnabled, pause, start]);

  return { isUserIdle: isIdle, idleTime };
};

export default useUserIdleState;
