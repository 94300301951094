import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  teaserContainer: {
    border: getBorderStyle("1px", "solid", "blueGrey.100"),
    borderRadius: "8px",
    backgroundColor: getPaletteColor("blueGrey.50"),
    padding: "13px",
    width: "270px",
    height: "194px",
  },

  textCenter: {
    textAlign: "center",
  },

  actionBlock: {
    flexGrow: 1,
  },
};

export default styles;
