import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  componentsContainer: {
    height: "300px",
    width: "536px",
    overflowY: "scroll",
    pl: "11px",
  },

  componentsContainerCenter: {
    height: "300px",
    width: "536px",
    overflowY: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: getPaletteColor("text.secondary"),
  },

  selectContainer: {
    flexGrow: 1,

    "& .MuiFormControl-root": {
      width: "100%",
    },
  },

  preposition: {
    py: 1.0625,
    lineHeight: "1.4375em",
  },
};

export default styles;
