import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import useShowAlert, { TAlertMessage } from "hooks/useShowAlert";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

const useDeleteAsset = ({
  messages,
  options,
}: {
  messages?: TAlertMessage;
  options?: Omit<UseMutationOptions<{}, Error, string>, "mutationFn">;
} = {}) => {
  const queryClient = useQueryClient();

  const deleteAssetMutation = useMutation(
    (id: string) => services.assets.deleteAsset(id).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryAPIKey.GetAssets);
      },
      ...options,
    },
  );

  useShowAlert({
    status: deleteAssetMutation.status,
    ...messages,
  });

  return deleteAssetMutation;
};

export default useDeleteAsset;
