import { useTranslation } from "react-i18next";
import { Stack, SxProps, Theme, Typography } from "@mui/material";
import Flag from "components/shared/Flag";
import InfoTooltip, { TInfoTooltipProps } from "components/shared/InfoTooltip";
import { TCountry } from "utils/constants/countries.constants";

export type THealthAuthorityProps = {
  country: keyof TCountry;
  name: string;
  title?: string;
  info?: TInfoTooltipProps["title"];
  sx?: SxProps<Theme> | undefined;
};

const HealthAuthority = (props: THealthAuthorityProps) => {
  const { t } = useTranslation("common");

  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={props.sx}>
      <Flag
        key={props.country}
        country={props.country}
        alt=""
        title={props.title}
      />
      <Typography variant="body2" component="span">
        {props.name}
      </Typography>
      {props.info ? (
        <InfoTooltip
          title={props.info}
          textAriaLabel={t("ariaLabels.information")}
        />
      ) : null}
    </Stack>
  );
};

export default HealthAuthority;
