import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  errorIcon: {
    color: getPaletteColor("grey.400"),
  },

  variantContainer: {
    p: 2,
    borderRadius: "6px",
    width: "100%",
    backgroundColor: getPaletteColor("grey.100"),
  },
};

export default styles;
