import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormSelect } from "components/common/form-fields";
import { ProposedTimeline } from "utils/constants/gsp.constants";

const options = [ProposedTimeline.Priority, ProposedTimeline.Standard];

const Timeline = ({ name }: { name: string }) => {
  const { t } = useTranslation("documents", {
    keyPrefix: "gsp.item.fields.timeline",
  });

  const selectOptions = useMemo(
    () =>
      options.map((option) => ({
        value: option,
        label: t(`optionLabels.${option}`),
      })),
    [t],
  );

  return (
    <FormSelect
      name={name}
      data-qaid="timeline-select"
      label={t("label")}
      options={selectOptions}
    />
  );
};

export default Timeline;
