import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const sectionVersion = {
  backgroundColor: getPaletteColor("blueGrey.50"),
  px: 1,
  py: 0.5,
  borderRadius: 1,
  fontSize: 10,
};

const styles: TStyles = {
  name: {
    color: getPaletteColor("text.primary"),
    textDecoration: "none",
    cursor: "pointer",
  },

  expandButton: {
    padding: 0,
  },

  headerNode: {
    display: "flex",
    cursor: "default",
    alignItems: "flex-start",
    userSelect: "none",
    justifyContent: "space-between",
  },

  section: {
    pt: 1.5,
  },

  sectionVersion: {
    ...sectionVersion,
  },

  sectionStatus: {
    ...sectionVersion,
    textTransform: "uppercase",
  },

  topLevelSection: {
    pt: 1.5,
    pr: 1.25,
    pb: 2,
    borderTop: getBorderStyle("1px", "solid", "divider"),

    "&:first-of-type": {
      borderTop: "none",
    },
  },
};

export default styles;
