import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelect } from "components/common/form-fields";
import { useFeatureFlags } from "context/FeatureFlagsContext";
import { useProject } from "context/ProjectContext";
import usePermissions from "hooks/permissions";
import { ImportFormBaseFieldName } from "screens/Project/sections/Documents/modals/ImportDocument";
import { ImportModalType } from "screens/Project/sections/Documents/modals/ImportDocument/ImportDocument";
import { TImportFormBase } from "screens/Project/sections/Documents/modals/ImportDocument/ImportDocument.types";
import {
  getDocumentTypesByProjectType,
  importModalDefaultValues,
} from "screens/Project/sections/Documents/modals/ImportDocument/ImportDocument.utils";
import { AssetGroup } from "utils/constants/assets.constants";
import { FeatureFlag } from "utils/constants/featureFlags.constants";

type TTypeFieldProps = {
  openModalType: ImportModalType;
};

const TypeField = ({ openModalType }: TTypeFieldProps) => {
  const { reset, watch } = useFormContext<TImportFormBase>();

  const { project } = useProject();

  const { permissions } = usePermissions(project?.id ?? "");

  const featureFlags = useFeatureFlags();

  const isFHIREnabled = featureFlags["enableFHIRDocuments"] === FeatureFlag.On;

  const isECTDEnabled =
    featureFlags["FTE-23984_enableDossierUploadCard"] === FeatureFlag.On;

  const { t } = useTranslation(["documents", "asset"]);

  const domain = watch(ImportFormBaseFieldName.Domain);
  const language = watch(ImportFormBaseFieldName.Language);
  const file = watch(ImportFormBaseFieldName.File);
  const name = watch(ImportFormBaseFieldName.Name);

  const documentTypes = getDocumentTypesByProjectType({
    project,
    permissions,
    isFHIREnabled,
    isECTDEnabled,
  })[domain];

  const documentTypeOptions = useMemo(
    () =>
      documentTypes?.map((type) => ({
        label: t(`group.${type}`, { ns: "asset" }),
        value: type,
      })) || [],
    [documentTypes, t],
  );

  const onChange = useCallback(
    (value: string | null) => {
      reset(
        {
          ...importModalDefaultValues,
          [ImportFormBaseFieldName.Type]: value as AssetGroup,
          [ImportFormBaseFieldName.Domain]: domain,
          [ImportFormBaseFieldName.Language]: language,
          [ImportFormBaseFieldName.File]: file,
          [ImportFormBaseFieldName.Name]: name,
        },
        {
          keepErrors: true,
        },
      );
    },
    [domain, file, language, name, reset],
  );

  const isFieldDisabled =
    openModalType !== ImportModalType.Default || !Boolean(domain);

  return (
    <FormSelect
      name={ImportFormBaseFieldName.Type}
      data-testid="document-type-select"
      data-qaid="document-type-select"
      label={t("importDocument.fields.type.label")}
      disabled={isFieldDisabled}
      onChange={onChange}
      options={documentTypeOptions}
    />
  );
};

export default TypeField;
