import { useMemo } from "react";
import { TTherapeuticRowData } from "../TherapeuticSummary.types";
import useCountryFilter from "./useCountryFilter";
import useDosageFormFilter from "./useDosageFormFilter";
import useRouteOfAdministrationFilter from "./useRouteOfAdministrationFilter";

const useTherapeuticFilters = (therapeuticItems: TTherapeuticRowData[]) => {
  const countryFilter = useCountryFilter(therapeuticItems);
  const dosageFilter = useDosageFormFilter(therapeuticItems);

  const routeOfAdministrationFilter =
    useRouteOfAdministrationFilter(therapeuticItems);

  return useMemo(
    () => ({
      ...countryFilter,
      ...dosageFilter,
      ...routeOfAdministrationFilter,
    }),
    [countryFilter, dosageFilter, routeOfAdministrationFilter],
  );
};

export default useTherapeuticFilters;
