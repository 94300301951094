import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  card: {
    borderRadius: 1.5,
    width: "69px",
    height: "81px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  text: {
    fontSize: "24px",
    fontWeight: "700",
    color: getPaletteColor("common.white"),
  },
};

export default styles;
