import { AssetType } from "utils/constants/assets.constants";
import { TAllOrNone } from "utils/types";

export enum EventType {
  // Content
  AppContentChangeStatus = "APP_CONTENT_CHANGE_STATUS",
  AppContentDownload = "APP_CONTENT_DOWNLOAD",
  AppContentImport = "APP_CONTENT_IMPORT",
  AppContentMetadataView = "APP_CONTENT_METADATA_VIEW",
  AppContentSubmit = "APP_CONTENT_SUBMIT",
  AppContentSubmitHistoryView = "APP_CONTENT_SUBMIT_HISTORY_VIEW",
  AppContentView = "APP_CONTENT_VIEW",

  // HAQ
  AppHAQChangeDueDate = "APP_HAQ_CHANGE_DUE_DATE",
  AppHAQChangeStatus = "APP_HAQ_CHANGE_STATUS",
  AppHAQCreate = "APP_HAQ_CREATE",
  AppHAQExport = "APP_HAQ_EXPORT",
  AppHAQSendCopy = "APP_HAQ_SEND_COPY",
  AppHAQSubmit = "APP_HAQ_SUBMIT",
  AppHAQView = "APP_HAQ_VIEW",
  AppHAQViewMetadata = "APP_HAQ_VIEW_METADATA",
  AppHAQViewResponse = "APP_HAQ_VIEW_RESPONSE",
  AppHAQViewShareHistory = "APP_HAQ_VIEW_SHARE_HISTORY",

  // Project
  AppProjectView = "APP_PROJECT_VIEW",
}

// types that should have copy like like "you created" all others are "you viewed"
export const CREATE_EVENT_TYPES = [
  EventType.AppHAQCreate,
  EventType.AppContentImport,
];

export interface ITrackingEventRequestData {
  eventType: EventType;
  projectId: string;
  assetId?: string;
  eventData: {
    assetType?: TTrackingAssetType;
  };
}

export type TTrackingEventBaseData = {
  projectId: string;
  eventType: EventType;
};

export type TTrackingAssetType = AssetType.Document | AssetType.Question;

export type TTrackingEventAssetData = {
  assetId: string;
  assetType: TTrackingAssetType;
};

export type TTrackingEventData = TTrackingEventBaseData &
  TAllOrNone<TTrackingEventAssetData>;

export interface ITrackingEventResponseData {
  id: string;
}
