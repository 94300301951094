import { HttpStatusCode } from "axios";
import { getStatusCode, oktaAuth } from "utils/auth";

export async function configureRequest<T>(
  requestExecutor: () => Promise<T>,
): Promise<T> {
  try {
    return await requestExecutor();
  } catch (error) {
    if (
      error instanceof Error &&
      getStatusCode(error) === HttpStatusCode.Unauthorized
    ) {
      await oktaAuth.tokenManager.renew("accessToken");
    }

    throw error;
  }
}
