import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  section: {},

  sectionContent: {
    mb: 1,
    borderRadius: "4px",
    p: 1.5,
    background: getPaletteColor("common.white"),
    boxShadow: (theme) =>
      `0px 2px 4px ${getPaletteColor("action.selected")(theme)}`,
  },

  editingState: {
    border: getBorderStyle("1px", "solid", "primary.light"),
  },

  sectionHeader: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: 1,
  },

  readOnly: {
    fontSize: (theme) => theme.typography.pxToRem(11),
  },

  sectionDetails: {
    display: "flex",
    justifyContent: "space-between",
  },

  sectionVersion: {
    backgroundColor: getPaletteColor("blueGrey.50"),
    p: "4px 8px",
    borderRadius: 1,
    fontSize: 10,
  },

  metaInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  sectionStatus: {
    border: "1px solid",
    borderColor: getPaletteColor("amber.600"),
    borderRadius: 1,
    fontSize: 10,
    textTransform: "uppercase",
    p: "4px 8px",
    ml: 1,
  },

  sectionStatusDraft: {
    backgroundColor: getPaletteColor("blueGrey.50"),
    borderRadius: 1,
    fontSize: 10,
    textTransform: "uppercase",
    p: "4px 8px",
    ml: 1,
  },

  sectionControls: {
    position: "absolute",
    right: -30,
    bottom: -60,
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  sectionControlButton: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    boxShadow: (theme) => theme.shadows[6],
    backgroundColor: getPaletteColor("common.white"),
    color: getPaletteColor("primary.main"),

    "&:hover": {
      backgroundColor: getPaletteColor("primary.main"),
      color: getPaletteColor("common.white"),

      "& path": {
        stroke: getPaletteColor("common.white"),
      },
      boxShadow: (theme) => theme.shadows[6],
    },
  },
};

export default styles;
