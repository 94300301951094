import isEmpty from "lodash/isEmpty";
import { IHAQWithContent } from "hooks/api/GQL/haq/useHAQsWithContent.models";
import { TUserPermissions } from "hooks/permissions/usePermissions.types";
import { IHAQ } from "models/HAQ.models";
import { IOrganization } from "models/organizations.models";
import { HAQStatus } from "utils/constants/HAQ.constants";
import {
  getIsAssetCopied,
  getIsEligibleToManipulateAsset,
  getIsHAQAcknowledged,
  getIsReferenceOrExternalHAQ,
} from "utils/helpers";
import {
  isAllowed,
  isDenied,
  TActionAllowable,
} from "utils/helpers/HAQ/getHAQStatusChangeOptions";
import { isHAUser } from "utils/user/organization";

export const getCanRequestInternalReview = (
  HAQ: IHAQWithContent | undefined,
  organization: IOrganization | undefined,
) =>
  HAQ?.status === HAQStatus.Draft &&
  getIsEligibleToManipulateAsset(HAQ, organization);

export const getCanRequestExternalReview = (
  HAQ: IHAQWithContent | undefined,
  organization: IOrganization | undefined,
) =>
  HAQ?.status === HAQStatus.Draft &&
  isHAUser(organization) &&
  getIsEligibleToManipulateAsset(HAQ, organization);

export const getCanSendCopyToSponsor = (
  HAQ: IHAQ | IHAQWithContent | undefined,
  canSendCopyQuestion: boolean,
  organization: IOrganization | undefined,
  isHAQCopied: boolean,
) =>
  HAQ?.status === HAQStatus.Final &&
  canSendCopyQuestion &&
  !isHAQCopied &&
  getIsEligibleToManipulateAsset(HAQ, organization);

export const getCanCreateRelatedQuestion = (
  HAQ: IHAQ | undefined,
  organization: IOrganization | undefined,
) =>
  [HAQStatus.Reviewed, HAQStatus.Final].includes(HAQ?.status as HAQStatus) &&
  isHAUser(organization) &&
  getIsEligibleToManipulateAsset(HAQ, organization);

export enum SubmitToHAsDeniedReason {
  NotImplemented = "NOT_IMPLEMENTED",
  NoPermission = "NO_PERMISSION",
  AlreadyCopied = "ALREADY_COPIED",
  NotFinal = "NOT_FINAL",
}

export const getCanSubmitToHAsWithReason = (
  asset: IHAQWithContent | undefined,
  organization: IOrganization | undefined,
  permissions: TUserPermissions,
): TActionAllowable<SubmitToHAsDeniedReason> => {
  if (!asset) {
    return isDenied(SubmitToHAsDeniedReason.NotImplemented);
  }

  const hasPermissions = getIsReferenceOrExternalHAQ(
    asset?.metadata?.importType,
  )
    ? permissions.canSubmitReferenceOrExternalQuestion
    : permissions.canSubmitQuestionResponse;

  if (!getIsEligibleToManipulateAsset(asset, organization) || !hasPermissions) {
    return isDenied(SubmitToHAsDeniedReason.NoPermission);
  }

  if (asset.status !== HAQStatus.Final) {
    return isDenied(SubmitToHAsDeniedReason.NotFinal);
  }

  if (getIsAssetCopied(asset.shareTracking)) {
    return isDenied(SubmitToHAsDeniedReason.AlreadyCopied);
  }

  return isAllowed();
};

export const getCanSubmitToHA = (
  HAQResponse: IHAQ | IHAQWithContent | undefined,
  organization: IOrganization | undefined,
  permissions: TUserPermissions,
  isHAQCopied: boolean,
) => {
  const hasPermissions = getIsReferenceOrExternalHAQ(
    HAQResponse?.metadata?.importType,
  )
    ? permissions.canSubmitReferenceOrExternalQuestion
    : permissions.canSubmitQuestionResponse;

  return (
    HAQResponse?.status === HAQStatus.Final &&
    hasPermissions &&
    getIsEligibleToManipulateAsset(HAQResponse, organization) &&
    !isHAQCopied
  );
};

export const getCanChangeHAQStatus = (
  HAQ: IHAQ | IHAQWithContent | undefined,
  HAQR: IHAQ | IHAQWithContent | undefined,
  organization: IOrganization | undefined,
) => {
  const isEligibleToManipulateHAQ = getIsEligibleToManipulateAsset(
    HAQ,
    organization,
  );

  const isEligibleToManipulateHAQR = getIsEligibleToManipulateAsset(
    HAQR,
    organization,
  );

  if (isHAUser(organization)) {
    return isEligibleToManipulateHAQ;
  }

  if (!HAQ?.metadata.imported) {
    return isEligibleToManipulateHAQR;
  }

  return isEligibleToManipulateHAQ && isEligibleToManipulateHAQR;
};

export const getCanChangeDueDate = (
  HAQs: IHAQWithContent[],
  organization: IOrganization | undefined,
  isProjectManager: boolean,
) =>
  HAQs.every(
    (HAQ) =>
      getIsEligibleToManipulateAsset(HAQ, organization) &&
      getIsHAQAcknowledged(HAQ?.shareTracking) &&
      isEmpty(HAQ?.children),
  ) &&
  isHAUser(organization) &&
  isProjectManager;
