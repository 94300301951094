import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  headerContainer: {
    padding: "16px 24px 0",
  },

  header: {
    padding: "12px 0",
    borderBottom: getBorderStyle("1px", "solid", "grey.200"),

    ".MuiAvatar-root": {
      backgroundColor: getPaletteColor("lightBlue.50"),
      color: getPaletteColor("blueGrey.800"),
    },

    ".MuiCardHeader-action": {
      margin: 0,
      alignSelf: "center",
    },
  },

  content: {
    paddingTop: "24px",

    "&:last-child": {
      paddingBottom: "32px",
    },
  },
};

export default styles;
