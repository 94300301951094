import { createContext, useMemo } from "react";
import { Outlet } from "react-router-dom";
import useGetFeatureFlags from "hooks/api/GQL/featureFlags/useGetFeatureFlags";
import { TFeatureFlagsMap } from "models/featureFlags.models";

export type TFeatureFlagsValue = TFeatureFlagsMap;

export const FeatureFlagsContext = createContext<
  TFeatureFlagsValue | undefined
>(undefined);

export const FeatureFlagsProvider = () => {
  const { featureFlags, isSuccess } = useGetFeatureFlags();

  const contextValue = useMemo(
    () =>
      featureFlags?.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.name]: curr.value,
        }),
        {},
      ) ?? {},
    [featureFlags],
  );

  return (
    <FeatureFlagsContext.Provider value={contextValue}>
      {isSuccess ? <Outlet /> : null}
    </FeatureFlagsContext.Provider>
  );
};
