import {
  cloneElement,
  MouseEvent,
  ReactElement,
  ReactNode,
  useMemo,
  useState,
} from "react";
import { ButtonProps, Menu, MenuProps, PopoverOrigin } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import MenuItem from "./MenuItem";
import styles from "./ButtonMenu.styles";

export type TMenuOption = {
  id: string;
  label: string;
  items?: TMenuOption[];
  onClick?: (selectedOption?: TMenuOption) => void;
  disabled?: boolean;
  shouldBeSeparated?: boolean;
  additionalInfo?: ReactNode;
  adornment?: ReactNode | null;
};

export type TButtonMenuBaseProps = {
  value?: string | null;
  dense?: boolean;
  onClose?: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  onSelect?: (selectedOption: TMenuOption) => void;
  options: TMenuOption[];
  buttonProps?: ButtonProps;
  ariaLabel?: string;
};

export type TButtonMenuProps = Omit<
  MenuProps,
  "MenuListProps" | "onSelect" | "open"
> &
  TButtonMenuBaseProps;

const ButtonMenu = ({
  dense = true,
  onSelect,
  options,
  sx,
  value,
  onClose,
  children,
  buttonProps,
  ...props
}: TButtonMenuProps) => {
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(menuEl);

  const handleMenuButtonClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // Prevents event bubbling when used within selectable table rows
    setMenuEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    if (menuEl) {
      setMenuEl(null);
    }

    onClose?.();
  };

  const button = cloneElement(children as ReactElement, {
    onClick: handleMenuButtonClick,
    ...buttonProps,
  });

  const isShowInset = useMemo(
    () => !Boolean(options.find((el) => el.items?.length)),
    [options],
  );

  return (
    <>
      {button}
      {!isEmpty(options) && (
        <Menu
          MenuListProps={{
            dense: dense,
          }}
          onClose={handleMenuClose}
          sx={sx}
          open={isOpen}
          anchorEl={menuEl}
          tabIndex={0}
          {...props}
        >
          {options.map((option) =>
            option.items && option.items.length ? (
              <ButtonMenu
                {...props}
                key={option.id}
                dense={dense}
                options={option.items}
                sx={styles.menu}
                value={value}
                onSelect={option.onClick || onSelect}
                onClose={handleMenuClose}
              >
                <MenuItem
                  key={option.id}
                  dense={dense}
                  sx={
                    option.shouldBeSeparated && options.length > 1
                      ? styles.menuItemWithSeparator
                      : styles.menuItem
                  }
                  option={option}
                  disabled={option.disabled}
                />
              </ButtonMenu>
            ) : (
              <MenuItem
                key={option.id}
                dense={dense}
                onClick={() => {
                  onSelect?.(option);
                  option.onClick?.({} as TMenuOption);
                  handleMenuClose?.();
                }}
                sx={
                  option.shouldBeSeparated && options.length > 1
                    ? styles.menuItemWithSeparator
                    : styles.menuItem
                }
                option={option}
                isSelected={
                  isShowInset && value ? option.id === value : undefined
                }
                adornment={option.adornment}
                disabled={option.disabled}
              />
            ),
          )}
        </Menu>
      )}
    </>
  );
};

export default ButtonMenu;
