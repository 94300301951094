import { getPaletteColor } from "theme/helpers";

const Styles = {
  defaultChip: {
    ".MuiChip-label": {
      fontSize: "13px",
      lineHeight: "18px",
      letterSpacing: "0.16px",
      padding: "7px 10px",
    },

    "&.Mui-disabled": {
      backgroundColor: getPaletteColor("action.disabled"),
    },

    "& .MuiChip-labelSmall": {
      padding: "3px 10px",
    },
  },
};

export default Styles;
