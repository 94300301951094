import { Skeleton, Typography } from "@mui/material";
import { TFunction } from "i18next";
import useOrganizations from "hooks/api/REST/organizations/useOrganizations";
import useUser from "hooks/useUser";
import { MAX_SIZE } from "utils/constants/api.constants";
import { getOrganizationByTenantId } from "utils/helpers";
import { isSponsorUser } from "utils/user/organization";

type TRecipientProps = {
  tenantId: string;
  t: TFunction;
};

export const Recipient = ({ tenantId, t }: TRecipientProps) => {
  const { organizations, isLoading } = useOrganizations(
    { tenantIds: [tenantId], size: MAX_SIZE },
    !!tenantId,
  );

  const { organization: user } = useUser();

  const organization = getOrganizationByTenantId(
    organizations?.content,
    tenantId,
  );

  const sponsorName = organization?.name;
  const missingInfo = t("shareHistory.missingInfo");

  if (isLoading) {
    return <Skeleton />;
  }

  const recipient = isSponsorUser(user)
    ? t("shareHistory.recipientReliance")
    : sponsorName ?? missingInfo;

  return <Typography variant="body2">{recipient}</Typography>;
};
