import { Component, useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { Command, EditorConfig } from "ckeditor5";
import { Editor as CustomEditor } from "ckeditor";
import useComments from "hooks/api/REST/documents/comments/useComments";
import useSuggestions from "hooks/api/REST/documents/suggestions/useSuggestions";
import useUsers from "hooks/api/REST/user/useUsers";
import useUser from "hooks/useUser";
import styles from "./Editor.styles";

// @ckeditor/ckeditor5-react doesn't export the props interface
export type TCKEditorProps = CKEditor<CustomEditor> extends Component<
  infer Props
>
  ? Props
  : never;

export type TEditorProps = Omit<TCKEditorProps, "config" | "editor"> & {
  isReadOnly?: boolean;
  componentId: string;
  isTrackChangesEnabled?: boolean;
  editor: CustomEditor | null;
  onContentChange: (data: string) => void;
  onEditorReady?: (id: string, editor: CustomEditor) => void;
  label?: string;
  removeToolbarItems?: string[];
  placeholder?: string;
  isCommentButtonEnabled?: boolean;
};

const READ_ONLY_LOCK_ID = "read-only-lock-id";

const disableCKEditorCommand = (cmd: Command) => {
  cmd.on(
    "set:isEnabled",
    (evt) => {
      evt.return = false;
      evt.stop();
    },
    { priority: "highest" },
  );

  cmd.isEnabled = false;
};

const Editor = ({
  data = undefined,
  isReadOnly = true,
  componentId,
  isTrackChangesEnabled = false,
  onContentChange,
  onEditorReady,
  editor,
  label,
  removeToolbarItems,
  placeholder,
  isCommentButtonEnabled = true,
  ...ckEditorProps
}: TEditorProps) => {
  const [isEditorReady, setIsEditorReady] = useState(false);

  const commentsAdapter = useComments();
  const suggestionsAdapter = useSuggestions();

  const { user: currentUser } = useUser();
  const { users } = useUsers();

  const editorConfig: EditorConfig | undefined =
    users && currentUser
      ? {
          collaboration: { channelId: componentId }, // associate this editor instance with the correct section ID
          adapters: {
            users: users,
            currentUser: currentUser,
            getCommentsAdapter: commentsAdapter,
            getTrackChangesAdapter: suggestionsAdapter,
          },
          placeholder: placeholder || "",
          toolbar: {
            removeItems: removeToolbarItems || [],
          },
        }
      : undefined;

  // toggle read-only and track changes modes
  useEffect(() => {
    if (!isEditorReady) {
      return;
    }

    const isEditorInReadOnlyState = editor?.isReadOnly;

    if (isReadOnly && !isEditorInReadOnlyState) {
      editor?.enableReadOnlyMode(READ_ONLY_LOCK_ID);
    } else if (!isReadOnly && isEditorInReadOnlyState) {
      editor?.disableReadOnlyMode(READ_ONLY_LOCK_ID);
    }

    // commands can only be executed to when editor is not in read only mode, so also sync that
    // value into the editor if read only mode is disabled
    const trackChangesCommand = editor?.commands.get("trackChanges");

    if (
      !isReadOnly &&
      isCommentButtonEnabled &&
      trackChangesCommand &&
      trackChangesCommand.value !== isTrackChangesEnabled
    ) {
      editor?.execute("trackChanges");
    }
  }, [
    isEditorReady,
    isReadOnly,
    editor,
    isTrackChangesEnabled,
    isCommentButtonEnabled,
  ]);

  const configureEditor = (editor: CustomEditor) => {
    const addCommentThreadCommand = editor.commands.get("addCommentThread");

    // disable toolbar `Add comment` button based on prop
    if (addCommentThreadCommand && !isCommentButtonEnabled) {
      disableCKEditorCommand(addCommentThreadCommand);
    }
  };

  const onReady = (editor: CustomEditor) => {
    if (!editor) {
      return;
    }

    configureEditor(editor);
    onEditorReady?.(componentId, editor);
    setIsEditorReady(true);
  };

  const editorStyle = (
    label ? { ...styles.editor, ...styles.materialBorder } : styles.editor
  ) as SxProps<Theme>;

  return (
    <Box sx={styles.container}>
      <Box sx={editorStyle}>
        {editorConfig && (
          <CKEditor
            config={editorConfig}
            data={data}
            editor={CustomEditor}
            onReady={onReady}
            onError={console.error}
            onChange={(_event, editor) => {
              const data = editor.getData();
              onContentChange(data);
            }}
            {...ckEditorProps}
          />
        )}
        {label ? (
          <Typography variant="inputLabel" sx={styles.label}>
            {label}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export default Editor;
