import { IPagination } from "models/pagination.models";
import {
  AssetDomain,
  AssetGroup,
  AssetRelationship,
} from "utils/constants/assets.constants";
import { DocumentSubtype, ParseJobStatus } from "utils/constants/doc.constants";
import {
  ApplicationType,
  ProposedApprovalPathway,
  SubmissionPlan,
} from "utils/constants/gsp.constants";
import { ShareHistoryItemType } from "utils/constants/shareHistory.constants";
import { TenantType } from "utils/constants/users.constants";
import { TISODateFormat } from "utils/types";

export interface IAssetMetadata {
  componentType: string;
  documentId: string;
  headerText: string;
  healthAuthorityId?: string;
  id?: string;
  order: number;
  submissionPlan?: SubmissionPlan;
  tenantId?: string;
  workflowStatus: string;
  authoringTenantId: string;
}

export interface IAssetECTDLeaf {
  ID: string;
  arn: string;
  checksum: string;
  "checksum-type": string;
  operation: string;
  title: string;
  "xlink:href": string;
  "xlink:type": string;
  "xmlns:xlink": string;
}

export interface IECTD {
  [key: string]: IECTDSection;
}

export interface IECTDSection {
  [key: string]: IECTDSection | IECTDSection[] | string | IECTDSectionLeaf;
}

export interface IECTDSectionLeaf {
  leaf: IAssetECTDLeaf[];
}

export interface IAssetECTDMetadata {
  authoringTenantId: string;
  domain: AssetDomain;
  "ectd:ectd": IECTD;
  fileName: string;
  fileType: string;
  name: string;
  subtype: DocumentSubtype;
  version: string;
}

export interface IAssetProductMetadata {
  active: Boolean;
  catalog_number?: number;
  comparator_product?: unknown;
  contains_animal_tissue_or_cells?: unknown;
  contains_cmr_substances?: unknown;
  contains_dehp?: unknown;
  contains_endocrine_disrupting_substances?: unknown;
  contains_human_derived_medicinal_product?: unknown;
  contains_human_tissue_or_cells?: unknown;
  contains_medicinal_product_substances?: unknown;
  contains_substances_of_microbial_origin?: unknown;
  created_by: string;
  created_date: string;
  deleted_by: string;
  deleted_date: string;
  description: string;
  global_id: string;
  id: string;
  international_birthdate: string;
  last_modified_by: string;
  last_modified_date: string;
  manufactured_dosage_form: string;
  name: string;
  ndc_string: string;
  nonproprietary_name: string;
  organization_id: string;
  primaryProductFamilyId: string;
  roa: string;
  strength: string;
  structured_product_labeling: string;
  substance_id: string;
  type: string;
  version: number;
}

export interface IDossierDifferencesMetadata {
  sections: string[];
  summaryOfDifferences: string;
  applicableHealthAuthorityTenantIds: string[];
}

export interface IInvitationMetadata {
  tenantId: string;
  sentDate: string;
  dueDate: TISODateFormat;
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

// Below types should ideally be camelCase; BE has mistakenly provided them in snake-case.
// Will need to be updated in the future once BE resolves this.
export interface IProductMetadata {
  active: boolean;
  catalog_number?: string;
  comparator_product?: boolean;
  contains_animal_tissue_or_cells?: boolean;
  contains_cmr_substances?: boolean;
  contains_dehp?: boolean;
  contains_endocrine_disrupting_substances?: boolean;
  contains_human_derived_medicinal_product?: boolean;
  contains_human_tissue_or_cells?: boolean;
  contains_medicinal_product_substances?: boolean;
  contains_substances_of_microbial_origin?: boolean;
  created_by: string;
  createdDate: string;
  deletedBy?: string;
  deletedDate?: string;
  description: string;
  globalId?: string;
  id: string;
  international_birthdate?: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  manufacturedDosageForm: string;
  name: string;
  ndcString?: string;
  nonproprietaryName: string;
  organizationId: string;
  primaryProductFamilyId: string;
  roa: string;
  strength: string;
  structuredProductLabeling: string;
  substanceId: string;
  type: string;
  version: number;
}

// Below types should ideally be camelCase; BE has mistakenly provided them in snake-case.
// Will need to be updated in the future once BE resolves this.
// Additional properties will need to be added to this type later once BE defines the schema.
export interface ISubstanceMetadata {
  active: boolean;
  atcCode: string;
  casNumber?: string;
  createdBy: string;
  createdDate: string;
  deletedBy?: string;
  deletedDate?: string;
  id: string;
  inn: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  name: string;
  orgId: string;
  parentId?: string;
  strength: string;
  substanceClass: string;
  type: string;
  version: number;
}

export interface IAssetSource {
  arn: string;
  tenantId: string;
  tenantType: TenantType;
}

export interface IAsset<T = IAssetMetadata> {
  id: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  parentId: string;
  type: string;
  name: string;
  metadata: T;
  status?: string;
  projectIds?: string[];
  sourceArn?: string;
  // ToDo (AV): make source required when sourceArn is deprecated
  source?: IAssetSource;
  // ToDo (AV): sourceArn is to be deprecated
  children?: IAsset<T>[] | null;
}

export interface IShareTracking {
  submissionDate: string;
  type: ShareHistoryItemType;
  tenantId: string;
  id: string;
  objectId: string;
  status: string;
  responseDate: string;
}

export interface IAssetContent {
  id: string;
  createdBy: string;
  createdDate: string;
  assetId: string;
  content: string;
  versions: IAssetVersion[];
  type?: string;
}

export interface IAssetsResponseData<T> extends IPagination {
  content: IAsset<T>[];
}

export interface IGetAssetRelationshipParams {
  size?: number;
  relationshipType: AssetRelationship;
}

export interface IAssetType {
  description: string;
  metadataSchema: {
    allOf: ITenantInfo[];
  };
  name: string;
}

export interface ITenantInfo {
  if: {
    properties: {
      tenantId: {
        const: string;
      };
    };
  };
  then: {
    properties: {
      applicationInfo: {
        properties: ITenantProperty;
      };
    };
  };
}

export interface ITenantProperty {
  proposedApprovalPathway: {
    enum: ProposedApprovalPathway[];
  };
  type: {
    enum: ApplicationType[];
  };
}

export interface IAssetVersion {
  createdBy: string;
  createdDate: string;
  assetContentId: string;
  version: string;
}

export interface ICreateAssetVersion {
  version: string;
}

export interface IParseJob {
  id: string;
  parsedAssetId: string;
  sourceAssetId: string;
  status: ParseJobStatus;
}

export interface ICreateAssetContentRequestData {
  content: string;
  assetId: string;
  versions?: { version: string }[];
}

export interface IDataDictionary<T> {
  key: string;
  value: T;
}

export interface IAssetRelationship {
  id: string;
  assetId: string;
  relationship: AssetRelationship;
  otherAssetArn: string;
}

export interface IAssetRelationshipRequestData {
  otherAssetArn: string;
  relationship: AssetRelationship;
}

export interface IDeleteAssetsRequestData {
  assetIds: string[];
}

export enum BulkAssetsActionOperation {
  LOCK = "LOCK",
  UNLOCK = "UNLOCK",
}

export interface IBulkAssetsActionRequestData {
  operation: BulkAssetsActionOperation;
  assetIds: string[];
}

export interface ILockBulkAssetResponseData {
  assetId: string;
  userId: string;
  expiry: string;
}

export interface ICreateUploadTokenRequestData {
  type: DocumentSubtype;
  name: string;
  projectIds: string[];
  metadata: {
    domain: AssetDomain;
    group: AssetGroup;
    subtype: DocumentSubtype;
    name: string;
    language: string;
    version: string;
    fileType: string;
  };
}

export interface ICreateUploadTokenResponseData {
  id: string;
  type: DocumentSubtype;
  name: string;
  assetUploadUrl: string;
  status: string;
  expiryTime: string;
  createdBy: string;
  createdDate: string;
}
