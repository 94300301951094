import { useQuery, UseQueryOptions } from "react-query";
import { gql } from "graphql-request";
import { Variables } from "graphql-request/src/types";
import useShowAlert from "hooks/useShowAlert";
import { IAssetMetadata } from "models/assets.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { TAssetParams, TAssetResponse } from "./useAssets.models";

export const query = gql`
  query Assets($assetTypes: [String], $projectIds: [String], $size: Int) {
    assets(assetTypes: $assetTypes, projectIds: $projectIds, size: $size) {
      id
      assetContent {
        content
      }
    }
  }
`;

export const queryAllAssetsData = gql`
  query Assets(
    $assetTypes: [String]
    $assetIds: [String]
    $projectIds: [String]
    $sourceArns: [String]
    $size: Int
  ) {
    assets(
      assetTypes: $assetTypes
      assetIds: $assetIds
      projectIds: $projectIds
      sourceArns: $sourceArns
      size: $size
    ) {
      id
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      type
      name
      status
      projectIds
      metadata
      sourceArn
      source {
        arn
        tenantId
        tenantType
      }
    }
  }
`;

export type TUseAssetsProps<T = IAssetMetadata> = {
  shouldFetchAllAssetData: boolean;
  params: TAssetParams;
  queryOptions?: UseQueryOptions<TAssetResponse<T>, Error>;
  failureMessage?: string;
};

const useAssets = <T = IAssetMetadata>(
  shouldFetchAllAssetData = false,
  params: TAssetParams,
  queryOptions?: UseQueryOptions<TAssetResponse<T>, Error>,
  failureMessage?: string,
) => {
  const { data, status, isLoading, error, isSuccess } = useQuery<
    TAssetResponse<T>,
    Error
  >(
    [QueryAPIKey.GetAssets, params],
    () =>
      services.graphQL.request<TAssetResponse<T>, Variables>(
        shouldFetchAllAssetData ? queryAllAssetsData : query,
        params,
      ),
    queryOptions,
  );

  useShowAlert({
    status,
    failureMessage,
  });

  return {
    assetsData: data,
    isLoading,
    error,
    isSuccess,
  };
};

export default useAssets;
