import { useContext } from "react";
import { BackgroundJobsContext } from "./BackgroundJobsContext";

const useBackgroundJobs = () => {
  const context = useContext(BackgroundJobsContext);

  if (context === undefined) {
    throw new Error(
      "useBackgroundJobs must be used within a BackgroundJobsProvider",
    );
  }

  return context;
};

export default useBackgroundJobs;
