import { IDocument } from "models/documents.models";
import { TOption } from "utils/types";

export enum SubmitDocumentFieldName {
  Documents = "documents",
  Recipients = "recipients",
  Message = "message",
}

export type TSubmitDocumentForm = {
  [SubmitDocumentFieldName.Documents]: IDocument[];
  [SubmitDocumentFieldName.Recipients]: TOption<string>[];
  [SubmitDocumentFieldName.Message]: string;
};
