import { Stack, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { AvatarGroup } from "components/shared";
import { TAvatarItem } from "components/shared/AvatarGroup/AvatarGroup";
import { TPortfolioCountry } from "screens/Portfolio/Portfolio.types";
import { PORTFOLIO_FLAGS_MAX_LENGTH } from "utils/constants/project.constants";
import styles from "./CountryFlag.styles";

export type TPortfolioCountryFlags = {
  countries?: TPortfolioCountry[];
  maxLength?: number;
  includeCountryCode?: boolean;
};

const PortfolioCountryFlags = ({
  countries,
  maxLength = PORTFOLIO_FLAGS_MAX_LENGTH,
  includeCountryCode = false,
}: TPortfolioCountryFlags) => {
  const data = countries?.map((country) => ({
    value: country.id,
    tooltip: country.description,
    id: country.id,
  }));

  if (isEmpty(data)) {
    return null;
  }

  return (
    <Stack direction="row" sx={styles.flagContainer}>
      <AvatarGroup
        data={data as TAvatarItem[]}
        type="flag"
        max={maxLength}
        data-testid="portfolio-country-flags"
        data-qaid="portfolio-country-flags"
      />
      {includeCountryCode && !isEmpty(data) && data?.length === 1 && (
        <Typography variant="body2" sx={styles.countryCode}>
          {data[0].value}
        </Typography>
      )}
    </Stack>
  );
};

export default PortfolioCountryFlags;
