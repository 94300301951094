import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import FileUploadStatus from "components/common/FileUploadStatus";
import {
  BackgroundJobType,
  TFileUploadJob,
  useBackgroundJobs,
} from "context/BackgroundJobsContext";
import { useProject } from "context/ProjectContext";
import { IDocument } from "models/documents.models";
import { getCanDocumentBeViewed } from "screens/Project/sections/Documents/utils/documentActions";
import { getPaletteColor } from "theme/helpers";
import { getDisplayedAssetStatus, getDocumentPath } from "utils/helpers";
import TeaserActions from "../TeaserActions";
import TeaserCard from "../TeaserCard";
import TeaserImgBox from "../TeaserImgBox";
import AAIDDocImg from "assets/images/aaid.png";

type TDossierTeaserCardProps = {
  document: IDocument;
};

const DossierTeaserCard = ({ document }: TDossierTeaserCardProps) => {
  const { t } = useTranslation(["documents", "common"]);

  const { project } = useProject();

  const { getJob } = useBackgroundJobs();

  const job = getJob<TFileUploadJob>(document.id, BackgroundJobType.FileUpload);

  const navigate = useNavigate();

  const handleOpenDossier = () =>
    navigate(getDocumentPath(project?.id, document));

  return (
    <TeaserCard
      title={
        job
          ? t("documents.teaser.PROJECT_DOSSIER.uploading")
          : t("subtypeOptions.PROJECT_DOSSIER")
      }
      imgBox={<TeaserImgBox isLoading={false} img={AAIDDocImg} />}
      description={
        <Typography
          variant="badgeLabel"
          color={getPaletteColor("text.secondary")}
        >
          {job ? (
            <FileUploadStatus jobId={job.id} />
          ) : (
            getDisplayedAssetStatus(
              document?.source?.tenantType,
              document.status,
              t,
            )
          )}
        </Typography>
      }
      onTeaserClick={
        getCanDocumentBeViewed(document) ? handleOpenDossier : undefined
      }
    >
      {!job && <TeaserActions document={document} />}
    </TeaserCard>
  );
};

export default DossierTeaserCard;
