import { memo, MouseEvent, useCallback, useEffect, useState } from "react";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import {
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import { getIsEmptyString } from "utils/helpers";
import { CheckboxState, ICheckboxOption } from "./CheckboxTree.utils";
import styles from "./CheckboxTree.styles";

// Padding for both parent and child options
const DEFAULT_PADDING = 2;

// Additional padding for all child options
const NORMAL_PADDING = 2.5;

// Extra padding for all non expandable child options
const EXTRA_PADDING = 4.5;

const Option = ({
  option,
  toggle,
  depth = 0,
  sx,
  expandButtonLabel,
  isExpanded,
  shouldApplyExtraIndent,
}: {
  option: ICheckboxOption;
  toggle: (option: ICheckboxOption) => void;
  depth: number;
  sx?: SxProps<Theme>;
  expandButtonLabel?: string;
  isExpanded?: boolean;
  shouldApplyExtraIndent?: boolean;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const hasChildren = option.children && option.children.length > 0;
  const isOptionHidden = getIsEmptyString(option.label);

  useEffect(() => {
    if (hasChildren && typeof isExpanded === "boolean") {
      setExpanded(Boolean(isExpanded));
    }
  }, [hasChildren, isExpanded]);

  const handleExpand = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setExpanded((prevState) => !prevState);
  }, []);

  const isChildrenExpanded = expanded || isOptionHidden;

  // Indent calculation consists of default padding + padding based on depth
  const indent = DEFAULT_PADDING + NORMAL_PADDING * depth;

  // Padding calculation consists of indent + extra padding for non expandable elements inside expandable tree
  const paddingLeft =
    shouldApplyExtraIndent && !hasChildren ? indent + EXTRA_PADDING : indent;

  return (
    <ListItem disablePadding aria-expanded={expanded} sx={{ flexWrap: "wrap" }}>
      {!isOptionHidden && (
        <ListItemButton
          role={undefined}
          aria-label={option.label}
          data-testid={option.testId}
          data-qaid={option.testId}
          sx={{
            paddingLeft,
            paddingTop: 0,
            paddingBottom: 0,
            ...sx,
          }}
          onClick={() =>
            option.value ? toggle(option) : setExpanded(!expanded)
          }
        >
          {hasChildren && (
            <IconButton
              size="small"
              aria-label={expandButtonLabel}
              onClick={handleExpand}
              sx={styles.expandMoreButton}
            >
              {expanded ? (
                <ExpandMore fontSize="small" />
              ) : (
                <ChevronRight fontSize="small" />
              )}
            </IconButton>
          )}

          {option.value && (
            <Checkbox
              size="small"
              checked={option.checked === CheckboxState.True}
              indeterminate={option.checked === CheckboxState.Indeterminate}
              disabled={
                option.disabled ||
                option.children?.every((child) => child.disabled)
              }
              inputProps={{
                "aria-label": "Checkbox",
              }}
              onClick={() => toggle(option)}
              sx={styles.checkbox}
            />
          )}
          <Stack direction="row" alignItems="baseline" spacing={1}>
            <ListItemText>{option.label}</ListItemText>
            {option.secondary && <ListItemText secondary={option.secondary} />}
          </Stack>
        </ListItemButton>
      )}
      {hasChildren && (
        <Collapse in={isChildrenExpanded} sx={{ width: "100%" }}>
          {isChildrenExpanded && (
            <List disablePadding>
              {option.children?.map((child) => {
                return (
                  !child.hidden && (
                    <Option
                      key={child.id}
                      option={child}
                      toggle={toggle}
                      depth={isOptionHidden ? depth - 1 : depth + 1}
                      expandButtonLabel={expandButtonLabel}
                      isExpanded={isExpanded}
                      shouldApplyExtraIndent={shouldApplyExtraIndent}
                    />
                  )
                );
              })}
            </List>
          )}
        </Collapse>
      )}
    </ListItem>
  );
};

export default memo(Option);
