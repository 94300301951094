import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, Stack, Typography } from "@mui/material";
import { getIsFeatureOn } from "components/Auth/accessChecks";
import { Button, Tooltip } from "components/shared";
import useFeatureFlags from "context/FeatureFlagsContext/useFeatureFlags";
import { useProject } from "context/ProjectContext";
import useUsers from "hooks/api/REST/user/useUsers";
import usePermissions from "hooks/permissions";
import { useUserRoles } from "hooks/user";
import useUser from "hooks/useUser";
import { IDocument } from "models/documents.models";
import { TUserData } from "models/users.models";
import UpdateDocumentMetadata from "screens/Project/sections/Documents/modals/UpdateDocumentMetadata";
import { getCanDocumentBePreviewed } from "screens/Project/sections/Documents/utils/documentActions";
import { getDocumentPath } from "utils/helpers";
import { isSponsorUser } from "utils/user/organization";
import { getCanDocumentMetadataBeEdited } from "./DocumentInformation.utils";
import DocumentInformationContent from "./DocumentInformationContent";
import styles from "./DocumentInformation.styles";

export type TDocumentInformationProps = {
  document: IDocument;
  handlePreviewDocument: (document: IDocument | null) => void;
  onClose: () => void;
  projectId: string | undefined;
};

export const DocumentInformation = ({
  document,
  handlePreviewDocument,
  onClose,
  projectId,
}: TDocumentInformationProps) => {
  const { t } = useTranslation("common");

  const featureFlags = useFeatureFlags();

  const { permissions } = usePermissions(projectId || "");

  const { project } = useProject();

  const { user, organization } = useUser();

  const { users: organizationUsers } = useUsers();

  const { isProjectManager, isContentEditor } = useUserRoles(project?.id || "");

  const isDocumentOwnedByUser = (user: TUserData | undefined) =>
    user?.email === document.createdBy;

  const isDocumentOwner = isDocumentOwnedByUser(user);

  const isDocumentOwnerFromUserOrganization = organizationUsers?.some(
    isDocumentOwnedByUser,
  );

  const enableHAUnstructuredContentManagement = getIsFeatureOn(
    "FTE-25852_enableHAUnstructuredContentManagement",
  )({
    featureFlags,
  });

  const hasUserEditPermissions_deprecated =
    isSponsorUser(organization) &&
    (isProjectManager || (isContentEditor && isDocumentOwner));

  const hasUserEditPermissions = useMemo(
    () =>
      enableHAUnstructuredContentManagement
        ? (permissions.canEditContentMetadata &&
            isDocumentOwnerFromUserOrganization) ||
          (isContentEditor && isDocumentOwner)
        : hasUserEditPermissions_deprecated,
    [
      isContentEditor,
      isDocumentOwner,
      permissions,
      hasUserEditPermissions_deprecated,
      enableHAUnstructuredContentManagement,
      isDocumentOwnerFromUserOrganization,
    ],
  );

  const [
    shouldShowUpdateDocumentMetadataModal,
    setShouldShowUpdateDocumentMetadataModal,
  ] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleOpen = () => {
    if (getCanDocumentBePreviewed(document, permissions)) {
      handlePreviewDocument(document);
    } else {
      navigate(getDocumentPath(document.projectIds?.[0], document));
    }
  };

  const documentName = document.name || document.metadata?.fileName;

  return (
    <Drawer open anchor="right" onClose={onClose}>
      {shouldShowUpdateDocumentMetadataModal && (
        <UpdateDocumentMetadata
          document={document}
          onClose={() => {
            setShouldShowUpdateDocumentMetadataModal(false);
          }}
        />
      )}

      <Box margin={4} role="complementary">
        <Button
          variant="text"
          startIcon={<CloseIcon />}
          onClick={onClose}
          aria-label={t("button.close")}
        >
          {t("button.close")}
        </Button>
        <Tooltip title={documentName}>
          <Typography variant="h6" paddingRight={2} sx={styles.fileName}>
            {documentName}
          </Typography>
        </Tooltip>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Button
            variant="contained"
            aria-label={t("button.open")}
            onClick={handleOpen}
          >
            {t("button.open")}
          </Button>
          {getCanDocumentMetadataBeEdited(
            hasUserEditPermissions,
            document,
            project,
          ) && (
            <Button
              variant="text"
              aria-label={t("button.edit")}
              onClick={() => {
                setShouldShowUpdateDocumentMetadataModal(true);
              }}
            >
              {t("button.edit")}
            </Button>
          )}
        </Stack>
        <DocumentInformationContent document={document} />
      </Box>
    </Drawer>
  );
};
