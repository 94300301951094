import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import useUser from "hooks/useUser";
import { INotification } from "models/notificationItem.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export default function useMarkNotificationRead() {
  const { t } = useTranslation("user", {
    keyPrefix: "notifications",
  });

  const queryClient = useQueryClient();
  const { user } = useUser();

  const {
    status,
    error,
    isLoading,
    isError,
    isSuccess,
    data: notificationResponse,
    mutate: triggerMarkNotificationAsRead,
  } = useMutation({
    mutationFn: ({
      id,
      notification,
    }: {
      id: string;
      notification: INotification;
    }) =>
      services.notifications
        .markNotificationRead(id, notification)
        .then((res) => res.data),
    onMutate: async (notificationResponse) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey: [QueryAPIKey.GetNotificationsForUser, user?.id],
      });

      // Grab current query data state
      const previousNotifications = queryClient.getQueryData([
        QueryAPIKey.GetNotificationsForUser,
        user?.id,
      ]);

      // Set query data with updated notification read state
      queryClient.setQueryData(
        [QueryAPIKey.GetNotificationsForUser, user?.id],
        (prevQueryData: any) => {
          const modifiedPages = [...prevQueryData.pages].map((page) => {
            const updatedPageContent = page.content.map(
              (notification: INotification) => {
                return notification.id === notificationResponse.id
                  ? { ...notification, ...notificationResponse.notification }
                  : notification;
              },
            );

            return { ...page, content: updatedPageContent };
          });

          return {
            pages: modifiedPages,
            pageParams: prevQueryData.pageParams,
          };
        },
      );

      return { previousNotifications };
    },
    onError: (err, notificationResponse, context) => {
      queryClient.setQueryData(
        [QueryAPIKey.GetNotificationsForUser, user?.id],
        context?.previousNotifications,
      );
    },
  });

  useShowAlert({
    status,
    successMessage: t("markNotificationAsReadSuccess"),
    failureMessage: t("markNotificationAsReadFailure"),
  });

  return {
    status,
    error,
    isLoading,
    isError,
    isSuccess,
    notificationResponse,
    triggerMarkNotificationAsRead,
  };
}
