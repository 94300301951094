import { IOrganization } from "models/organizations.models";
import { TPossibleAsset } from "utils/types";
import { isHAUser, isSponsorUser } from "utils/user/organization";

const getIsAssetOwnedByUserOrganization = (
  asset: TPossibleAsset,
  organization: IOrganization | undefined,
) => {
  if (isSponsorUser(organization)) {
    return asset?.source?.tenantId === organization?.tenantId;
  }

  if (isHAUser(organization)) {
    return asset?.metadata?.authoringTenantId === organization?.tenantId;
  }

  return false;
};

export default getIsAssetOwnedByUserOrganization;
