import { TStyles } from "utils/Styles";

const iconSize = "24px";

const styles: TStyles = {
  container: {
    p: "12px",
    borderRadius: "4px",
  },

  sectionHeader: {
    justifyContent: "space-between",
    alignItems: "center",
    mb: 1,
  },

  iconButtonContainer: {
    mt: -0.5,
  },

  editIconButton: {
    width: iconSize,
    height: iconSize,
  },

  title: {
    pb: 2,
  },

  addProjectDocuments: {
    p: 0,
    mb: 2,
  },
};

export default styles;
