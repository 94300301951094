import { TEntityActions } from "models/entities.models";
import { TLinkedOrganizationEntity } from "../Organization/Organization.types";
import { TProductFamily } from "../ProductFamily/ProductFamily.types";
import { TSubstance } from "../Substances/Substances.types";

export type TDictionary = {
  id: string;
  code: string;
  name: string;
};

export enum ProductVariantStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export type TProductVariant = {
  id: string;
  ownerTenantId: string;
  productType: TDictionary;
  strengthValue: string;
  strengthType: TDictionary;
  strengthVolumeValue?: string;
  strengthVolumeType?: TDictionary;
  routeOfAdministrationType: TDictionary;
  manufacturedDoseFormType: TDictionary;
  status: ProductVariantStatus;
  actions: TEntityActions;
};

export enum EntityProductStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export type TEntityProduct = {
  id: string;
  tradeName: string;
  nonproprietaryName: string;
  ownerTenantId: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  substances: TSubstance[];
  productFamilies: TProductFamily[];
  linkedOrganizations: TLinkedOrganizationEntity[];
  status: EntityProductStatus;
  actions: TEntityActions;
};

export enum ProductModificationAction {
  ManageVariant = "manage",
  EditProduct = "editProduct",
  ChangeOverallStatus = "changeOverallStatus",
  ChangeVariantStatus = "changeVariantStatus",
}
