import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { IDataDictionary } from "models/assets.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export enum DataDictionaryKey {
  Question = "question",
}

export default function useDataDictionary<T>(key: DataDictionaryKey) {
  const { t } = useTranslation("asset", {
    keyPrefix: "notifications",
  });

  const {
    data: dataDictionary,
    isLoading,
    isError,
    status,
    isSuccess,
  } = useQuery<IDataDictionary<T>>(
    [QueryAPIKey.GetDataDictionary],
    () => services.assets.getDataDictionary(key).then((res) => res.data),
    {
      enabled: !!key,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );

  useShowAlert({
    status,
    failureMessage: t("getDataDictionaryFailure"),
  });

  return {
    dataDictionary: dataDictionary?.value,
    isLoading,
    isError,
    isSuccess,
  };
}
