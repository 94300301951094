import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    mb: 2,
  },

  skeleton: {
    backgroundColor: getPaletteColor("common.white"),
    flex: "0 0 100%",
    borderRadius: "6px",
    px: 2,
    py: 1.5,
    boxShadow: getPaletteColor("action.selected"),
  },
};

export default styles;
