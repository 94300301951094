import React from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import DatePicker from "components/shared/DatePicker";

export type TDateRangeProps = {
  startDateName: string;
  startDateLabel: string;
  endDateName: string;
  endDateLabel: string;
  onValueChange: (key: string, value: Date | null) => void;
};

export const DateRange = ({
  startDateName,
  startDateLabel,
  endDateName,
  endDateLabel,
  onValueChange,
}: TDateRangeProps) => {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();

  const startDateValue = watch(startDateName);
  const endDateValue = watch(endDateName);

  return (
    <>
      <Controller
        control={control}
        name={startDateName}
        render={({ field }) => (
          <DatePicker
            {...field}
            label={startDateLabel}
            error={!!get(errors, startDateName)}
            helperText={get(errors, startDateName)?.message}
            size="small"
            onChange={(val) => {
              field.onChange(val);
              onValueChange?.(startDateName, val);
            }}
            shouldDisableDate={(date) =>
              !!endDateValue && date > new Date(endDateValue)
            }
          />
        )}
      />
      <Controller
        control={control}
        name={endDateName}
        render={({ field }) => (
          <DatePicker
            {...field}
            label={endDateLabel}
            error={!!get(errors, endDateName)}
            helperText={get(errors, endDateName)?.message}
            size="small"
            onChange={(val) => {
              field.onChange(val);
              onValueChange?.(endDateName, val);
            }}
            shouldDisableDate={(date) =>
              !!startDateValue && date < new Date(startDateValue)
            }
          />
        )}
      />
    </>
  );
};

export default DateRange;
