import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Box, Stack } from "@mui/material";
import {
  getCoreRowModel,
  OnChangeFn,
  RowSelectionState,
  SortingState,
  Updater,
  useReactTable,
} from "@tanstack/react-table";
import { isEmpty, isFunction } from "lodash";
import { Button, ReactTable } from "components/shared";
import Loading from "components/shared/Loading";
import NoDataFallback from "components/shared/NoDataFallback";
import TableFilter from "components/shared/TableFilter";
import useFeatureFlags from "context/FeatureFlagsContext/useFeatureFlags";
import { useProject } from "context/ProjectContext";
import useDocuments from "hooks/api/REST/documents/useDocuments";
import usePermissions from "hooks/permissions";
import useManualPagination from "hooks/react-table/useManualPagination";
import useManualSorting from "hooks/react-table/useManualSorting";
import useUser from "hooks/useUser";
import {
  DocumentSubtype,
  SUPPORTED_SUBTYPES,
} from "utils/constants/doc.constants";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import getColumns from "./columns";
import DocumentParsingAlert from "./DocumentParsingAlert";
import { DocumentsTable, TDocumentsRowData } from "./Documents.types";
import {
  getDocumentsForRender,
  shouldShowImportButton,
} from "./Documents.utils";
import { DocumentsActionButtons } from "./DocumentsActionButtons";
import { DocumentsBulkActions } from "./DocumentsBulkActions";
import {
  DocumentsContextProvider,
  useDocumentsContext,
} from "./DocumentsContext";
import DocumentsTeasers from "./DocumentsTeasers";
import { useFilters } from "./hooks";
import { ConvertDocument, ImportDocument } from "./modals";
import { DocumentsActionModals } from "./modals/DocumentsActionModals";
import { ImportModalType } from "./modals/ImportDocument";
import styles from "./Documents.styles";

const Documents = () => {
  const { t } = useTranslation([
    "documents",
    "common",
    "statuses",
    "countries",
    "HAQ",
    "dataX",
  ]);

  const featureFlags = useFeatureFlags();

  const { project } = useProject();

  const { organization } = useUser();

  const {
    permissions: {
      canUploadContent,
      canUploadContentToAllProjects,
      canViewContentInAllProjects,
      canViewContent,
      canDoDocumentBulkActions,
    },
  } = usePermissions(project?.id ?? "");

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const { selectedDocuments, setSelectedDocumentsIds } = useDocumentsContext();

  const [importModalType, setImportModalType] =
    useState<ImportModalType | null>(null);

  const { pagination, paginationParams, setPagination, resetPagination } =
    useManualPagination();

  const { sorting, sortingParams, setSorting } =
    useManualSorting<TDocumentsRowData>(
      [{ id: DocumentsTable.LastModified, desc: true }],
      resetPagination,
    );

  const assetTypes = useMemo(() => {
    if (featureFlags["FTE-23984_enableDossierUploadCard"] === FeatureFlag.On) {
      return SUPPORTED_SUBTYPES.join();
    }

    return SUPPORTED_SUBTYPES.filter(
      (type) => type !== DocumentSubtype.ECTD,
    ).join();
  }, [featureFlags]);

  const {
    filters,
    onFiltersChange,
    filterParams,
    keywordParam,
    onKeywordChange,
    filtersRequestParams,
    debouncedKeywordParam,
  } = useFilters(resetPagination);

  const {
    documents,
    pagination: { totalElements },
    isLoading,
    isError,
    isSuccess,
  } = useDocuments(
    {
      assetTypes,
      ...(project?.id && { projectIds: project.id }),
      ...sortingParams,
      ...paginationParams,
      ...filtersRequestParams,
      ...(debouncedKeywordParam && { keyword: debouncedKeywordParam }),
    },
    {
      keepPreviousData: true,
    },
  );

  const columns = useMemo(() => getColumns(t), [t]);

  const rows = useMemo(
    () => getDocumentsForRender(documents, t),
    [documents, t],
  );

  const handleRowSelectionChange = (
    updaterOrValue: Updater<RowSelectionState>,
  ) => {
    const newSelection = isFunction(updaterOrValue)
      ? updaterOrValue(rowSelection)
      : updaterOrValue;

    setRowSelection(newSelection);
    setSelectedDocumentsIds(new Set(Object.keys(newSelection)));
  };

  const tableInstance = useReactTable({
    columns: columns,
    data: rows,
    enableSorting: true,
    manualSorting: true,
    enableSortingRemoval: false,
    enableRowSelection: Boolean(project?.active),
    enableMultiRowSelection: Boolean(project?.active),
    manualPagination: true,
    getRowId: (row) => row.id,
    rowCount: totalElements ?? 0,
    state: {
      sorting,
      pagination,
      rowSelection,
    },
    // because `ColumnSort` defines the column `id` as string
    onSortingChange: setSorting as OnChangeFn<SortingState>,
    onPaginationChange: setPagination,
    onRowSelectionChange: handleRowSelectionChange,
    getCoreRowModel: getCoreRowModel(),
  });

  const isNoResults = isSuccess && !Boolean(documents?.length);
  const hasResults = isSuccess && Boolean(documents?.length);

  const isImportButtonVisible =
    project?.active &&
    (canUploadContentToAllProjects || canUploadContent) &&
    shouldShowImportButton({
      organization,
      featureFlags,
      projectType: project.type,
    });

  const isContentListViewable =
    hasResults && (canViewContentInAllProjects || canViewContent);

  return (
    <Box>
      {isError && (
        <Alert severity="error">{t("documents.failedLoadDocumentList")}</Alert>
      )}
      <Stack direction="row" sx={styles.container} spacing={1}>
        <DocumentsTeasers onAddDocument={setImportModalType} />

        {isImportButtonVisible && (
          <Button
            sx={styles.importButton}
            variant="contained"
            onClick={() => setImportModalType(ImportModalType.Default)}
            data-testid="import-button"
            data-qaid="import-button"
          >
            {t("importDocument.importButton")}
          </Button>
        )}
      </Stack>

      {isLoading && <Loading sx={styles.loading} />}
      {!isLoading && (
        <Stack
          mb={2}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
        >
          <TableFilter
            filters={filters}
            selectedFilters={filterParams}
            keywordFilterValue={keywordParam.keyword || ""}
            onFiltersChange={onFiltersChange}
            onKeywordChange={onKeywordChange}
            filterByKeywordLabel={t("keywordFilter.filterByKeyword", {
              ns: "common",
            })}
            showButtonLabel={t("filter.showFilterButton", { ns: "common" })}
            hideButtonLabel={t("filter.hideFilterButton", { ns: "common" })}
            clearAllFiltersLabel={t("filter.clearAllFiltersLabel", {
              ns: "common",
            })}
            errorAdornmentAriaLabel={t("ariaLabels.textFieldError", {
              ns: "common",
            })}
          />

          {!isEmpty(selectedDocuments) &&
            (!canDoDocumentBulkActions ? (
              <DocumentsActionButtons />
            ) : (
              <DocumentsBulkActions />
            ))}
        </Stack>
      )}

      {(isNoResults || !isContentListViewable) && (
        <NoDataFallback message={t("documents.noDocumentsFound")} />
      )}
      {isContentListViewable && (
        <ReactTable
          sx={styles.table}
          tableInstance={tableInstance}
          isPaginated={true}
        />
      )}

      <DocumentParsingAlert />

      <ConvertDocument />

      {importModalType && (
        <ImportDocument
          openType={importModalType}
          onClose={setImportModalType}
        />
      )}
      <DocumentsActionModals />
    </Box>
  );
};

export default () => (
  <DocumentsContextProvider>
    <Documents />
  </DocumentsContextProvider>
);
