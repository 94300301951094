import { useMemo } from "react";
import useCurrentUserRoles from "hooks/api/REST/user/useCurrentUserRoles";
import { Role } from "utils/constants/users.constants";

export type TUseUserRoles = {
  isContentEditor: boolean;
  isContentViewer: boolean;
  isProjectManager: boolean;
  isLoading: boolean;
};

const useUserRoles = (projectId: string | undefined): TUseUserRoles => {
  const { roles, isLoading } = useCurrentUserRoles({ enabled: true }, [
    projectId,
  ]);

  const userRoles = useMemo(() => {
    return projectId
      ? (roles ?? [])
          .filter(({ projects }) => projects.includes(projectId))
          .map(({ roleId }) => roleId)
      : [];
  }, [roles, projectId]);

  return useMemo(
    () => ({
      isContentEditor: userRoles.includes(Role.ContentEditor),
      isContentViewer: userRoles.includes(Role.ContentViewer),
      isProjectManager: userRoles.includes(Role.ProjectManager),
      isLoading,
    }),
    [userRoles, isLoading],
  );
};

export default useUserRoles;
