import { assetsBasePart } from "./constants";

export default {
  all: () => `${assetsBasePart}/assets`,
  byId: (id: string) => `${assetsBasePart}/assets/${id}`,
  type: (type: string) => `${assetsBasePart}/asset-types/${type}`,
  parseRequests: () => `${assetsBasePart}/assets/parse-requests`,
  dataDictionary: (key: string) => `${assetsBasePart}/data-dictionary/${key}`,
  parseRequestById: (id: string) =>
    `${assetsBasePart}/assets/parse-requests/${id}`,
  allVersions: (id: string) => `${assetsBasePart}/assets/${id}/versions`,
  createVersion: (id: string, contentId: string) =>
    `${assetsBasePart}/assets/${id}/content/${contentId}/version`,
  deleteVersion: (assetId: string, version: string) =>
    `${assetsBasePart}/assets/${assetId}/versions/${version}`,
  getAssetShareHistory: (assetId: string) =>
    `${assetsBasePart}/assets/${assetId}/share-tracking`,
  getAssetBulkShareHistory: (assetIds: string[]) =>
    `${assetsBasePart}/assets/bulk-share-tracking?objectIds=${assetIds}`,
  getContentById: (assetId: string, contentId: string) =>
    `${assetsBasePart}/assets/${assetId}/content/${contentId}`,
  content: (assetId: string) => `${assetsBasePart}/assets/${assetId}/content`,
  lockAssetEditing: (assetId: string) =>
    `${assetsBasePart}/assets/${assetId}/lock`,
  bulkAssetsAction: () => `${assetsBasePart}/assets/bulk-lock`,
  commentThreads: (assetId: string) =>
    `${assetsBasePart}/annotations/${assetId}/threads`,
  relationships: (assetId: string) =>
    `${assetsBasePart}/assets/${assetId}/asset-relationships`,
  deleteRelationship: (assetId: string, relationshipId: string) =>
    `${assetsBasePart}/assets/${assetId}/asset-relationships/${relationshipId}`,
  deleteAssets: () => `${assetsBasePart}/assets/bulk-delete-request`,
  createUploadToken: () => `${assetsBasePart}/assets/upload/upload-token`,
};
