import { useEffect, useRef } from "react";
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MenuItem, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import Select, { TOption, TSelectProps } from "components/shared/Select";

export type TFormSelectProps<FormType extends FieldValues> = Omit<
  TSelectProps<TOption["value"]>,
  "id" | "onChange" | "onClose"
> & {
  name: FieldPath<FormType>;
  label: string;
  options: TOption[];
  onChange?: (value: TOption["value"] | null) => void;
  onClose?: () => void;
  unregisterOnUnmount?: boolean;
};

const FormSelect = <FormType extends FieldValues>({
  name,
  options,
  onChange,
  onClose,
  unregisterOnUnmount = true,
  ...props
}: TFormSelectProps<FormType>) => {
  const { t } = useTranslation("common");

  const { control, unregister, trigger } = useFormContext<FormType>();

  useEffect(() => {
    if (unregisterOnUnmount) {
      return () => unregister(name);
    }
  }, [unregister, name, unregisterOnUnmount]);

  const inputRef = useRef<HTMLInputElement>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Select
          {...field}
          {...props}
          id={name}
          value={field.value || ""}
          error={!!error}
          helperText={error?.message || props.helperText}
          size="small"
          onChange={(event) => {
            field.onChange(event);
            onChange?.(event?.target?.value);
          }}
          onClose={() => {
            trigger(name);
            onClose?.();
          }}
          inputProps={{
            ref: (ref: { node: HTMLInputElement | undefined }) =>
              (inputRef.current = ref?.node),
          }}
          MenuProps={{ sx: { width: inputRef?.current?.offsetWidth } }}
        >
          {!isEmpty(options) ? (
            options.map(({ label, value, testId }) => (
              <MenuItem
                value={value}
                key={value}
                data-testid={testId}
                data-qaid={testId}
              >
                <Typography noWrap>{label}</Typography>
              </MenuItem>
            ))
          ) : (
            <MenuItem value="none" disabled>
              {t("emptyState.noResults")}
            </MenuItem>
          )}
        </Select>
      )}
    />
  );
};

export default FormSelect;
