import { useMutation, useQueryClient } from "react-query";
import { IHAQWithContent } from "hooks/api/GQL/haq/useHAQsWithContent.models";
import useShowAlert, { TAlertMessage } from "hooks/useShowAlert";
import { IAsset } from "models/assets.models";
import { IDocument, IDocumentMetadata } from "models/documents.models";
import { IGSPDossierDifferencesRequestData } from "models/gsp.models";
import { IHAQMetadata } from "models/HAQ.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

type TUpdateGSPMutationData = {
  data:
    | IGSPDossierDifferencesRequestData
    | IHAQWithContent
    | IAsset<IHAQMetadata>
    | IDocument<IDocumentMetadata>;
  id: string;
};

const useUpdateAsset = (messages: TAlertMessage) => {
  const queryClient = useQueryClient();

  const updateAssetMutation = useMutation(
    ({ data, id }: TUpdateGSPMutationData) =>
      services.assets.updateAsset(data, id).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryAPIKey.GetAssets);
        queryClient.invalidateQueries(QueryAPIKey.GetAssetsWithContent);
      },
    },
  );

  useShowAlert({
    status: updateAssetMutation.status,
    ...messages,
  });

  return updateAssetMutation;
};

export default useUpdateAsset;
