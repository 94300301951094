import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { ILanguage } from "models/documents.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export default function useLanguages() {
  const { t } = useTranslation("documents", {
    keyPrefix: "notifications",
  });

  const { data, status, error, isLoading, isError, isSuccess } = useQuery<
    ILanguage[],
    Error
  >([QueryAPIKey.GetLanguages], () =>
    services.documents.getLanguages().then((res) => res.data),
  );

  useShowAlert({
    status,
    failureMessage: t("getLanguagesFailure"),
  });

  return {
    languages: data,
    isLoading,
    isError,
    isSuccess,
    error,
  };
}
