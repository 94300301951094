import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Grid } from "@mui/material";
import {
  FormComboBox,
  FormSelect,
  FormTextInput,
} from "components/common/form-fields";
import { Button, FormWrapper, ModalActionButtons } from "components/shared";
import useCountriesList from "hooks/api/GQL/entityManagement/useCountriesList";
import useCreateEntityOrganization from "hooks/api/GQL/entityManagement/useCreateEntityOrganization";
import useOrganizationRoleList from "hooks/api/GQL/entityManagement/useOrganizationRoleList";
import useUpdateEntityOrganization from "hooks/api/GQL/entityManagement/useUpdateEntityOrganization";
import { EntityStatus } from "models/entities.models";
import { OrganizationType } from "../CreateOrganizationModal/CreateOrganizationModal.types";
import { TEntityOrganization } from "../Organization.types";
import {
  getCreateOrganizationFormSchema,
  getCreateOrganizationFormValues,
  OrganizationFieldName,
  TOrganizationForm,
} from "./OrganizationForm.types";
import {
  getCountryFieldOptions,
  getOrganizationFormValuesForEdit,
  getOrganizationRole,
  getOrganizationValuesForUpdate,
  getStatusFieldOptions,
} from "./OrganizationForm.utils";

type TOrganizationFormProps = {
  onClose: () => void;
  type: OrganizationType;
  selectedOrganization?: TEntityOrganization;
};

const OrganizationForm = ({
  onClose,
  type,
  selectedOrganization,
}: TOrganizationFormProps) => {
  const { t } = useTranslation(["administration", "common", "countries"]);

  const { updateOrganization, isLoading: isUpdateLoading } =
    useUpdateEntityOrganization();

  const { data: organizationRoleList } = useOrganizationRoleList(type);

  const { data: countriesList } = useCountriesList();

  const { createEntityOrganization, isLoading } = useCreateEntityOrganization();

  const organizationRoleFieldOptions = useMemo(
    () => getOrganizationRole(organizationRoleList, t),
    [organizationRoleList, t],
  );

  const statusFieldOptions = useMemo(() => getStatusFieldOptions(t), [t]);

  const countryFieldOptions = useMemo(
    () => getCountryFieldOptions(countriesList),
    [countriesList],
  );

  const defaultValues = useMemo(
    () =>
      selectedOrganization
        ? getOrganizationFormValuesForEdit(
            selectedOrganization,
            organizationRoleFieldOptions,
          )
        : getCreateOrganizationFormValues(),
    [selectedOrganization, organizationRoleFieldOptions],
  );

  const methods = useForm<TOrganizationForm>({
    mode: "all",
    defaultValues,
    resolver: zodResolver(getCreateOrganizationFormSchema(t)),
  });

  const {
    reset,
    formState: { isValid, isDirty },
    handleSubmit,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = (formValues: TOrganizationForm) => {
    const entityOrganization = {
      name: formValues[OrganizationFieldName.OrganizationName].trim(),
      alias: formValues[OrganizationFieldName.OrganizationAlias],
      organizationRoleTypeCodes: formValues[
        OrganizationFieldName.OrganizationRole
      ].map((role) => role.value),
      organizationTypeCode: type,
      countryTypeCode: formValues[OrganizationFieldName.Country],
      active: formValues[OrganizationFieldName.Status] === EntityStatus.Active,
      address: formValues[OrganizationFieldName.Address],
      city: formValues[OrganizationFieldName.City],
      postCode: formValues[OrganizationFieldName.ZipCode],
    };

    createEntityOrganization({
      entityOrganization,
    }).then(handleClose);
  };

  const onSaveChanges = (data: TOrganizationForm) => {
    if (selectedOrganization) {
      const entityOrganization = getOrganizationValuesForUpdate(
        selectedOrganization,
        data,
      );

      updateOrganization({
        entityOrganization,
      }).then(handleClose);
    }
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <FormWrapper
      methods={methods}
      onSubmit={handleSubmit(selectedOrganization ? onSaveChanges : onSubmit)}
    >
      <Grid container spacing={2} rowGap={1} mb={4}>
        <Grid item xs={6}>
          <FormTextInput
            fullWidth
            name={OrganizationFieldName.OrganizationName}
            label={t("createOrganizationalModal.organizationFieldName.name")}
          />
        </Grid>

        <Grid item xs={6}>
          <FormTextInput
            fullWidth
            name={OrganizationFieldName.OrganizationAlias}
            label={t("createOrganizationalModal.organizationFieldName.alias")}
          />
        </Grid>

        <Grid item xs={12}>
          <FormComboBox
            fullWidth
            name={OrganizationFieldName.OrganizationRole}
            label={t(
              "createOrganizationalModal.organizationFieldName.organizationRole",
            )}
            options={organizationRoleFieldOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <FormTextInput
            fullWidth
            name={OrganizationFieldName.Address}
            label={t("createOrganizationalModal.organizationFieldName.address")}
          />
        </Grid>

        <Grid item xs={6}>
          <FormTextInput
            fullWidth
            name={OrganizationFieldName.City}
            label={t("createOrganizationalModal.organizationFieldName.city")}
          />
        </Grid>

        <Grid item xs={6}>
          <FormTextInput
            fullWidth
            name={OrganizationFieldName.ZipCode}
            label={t("createOrganizationalModal.organizationFieldName.zipCode")}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            fullWidth
            name={OrganizationFieldName.Country}
            label={t("createOrganizationalModal.organizationFieldName.country")}
            options={countryFieldOptions}
          />
        </Grid>

        <Grid item xs={6}>
          <FormSelect
            fullWidth
            name={OrganizationFieldName.Status}
            label={t("createOrganizationalModal.organizationFieldName.status")}
            options={statusFieldOptions}
          />
        </Grid>
      </Grid>

      <ModalActionButtons>
        <Button
          variant="text"
          data-qaid="organization-form-cancel-button"
          onClick={handleClose}
          disabled={isLoading}
        >
          {t("button.cancel", { ns: "common" })}
        </Button>
        <Button
          type="submit"
          data-qaid="organization-form-submit-button"
          variant="contained"
          disabled={
            isLoading ||
            isUpdateLoading ||
            !isValid ||
            (selectedOrganization && !isDirty)
          }
        >
          {selectedOrganization
            ? t("organization.saveOrganizationChanges")
            : t("organization.createNewOrganization")}
        </Button>
      </ModalActionButtons>
    </FormWrapper>
  );
};

export default OrganizationForm;
