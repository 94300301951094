import { IDocument } from "models/documents.models";
import { TISODateFormat } from "utils/types";
import { TOption } from "utils/types";

export enum ShareDocumentFieldName {
  Documents = "documents",
  Recipients = "recipients",
  DueDate = "dueDate",
  Message = "message",
}

export type TShareDocumentForm = {
  [ShareDocumentFieldName.Documents]: IDocument[];
  [ShareDocumentFieldName.Recipients]: TOption<string>[];
  [ShareDocumentFieldName.DueDate]: TISODateFormat;
  [ShareDocumentFieldName.Message]: string;
};
