export enum Path {
  // Admin
  Admin = "/admin",
  AdminEntityManagement = "/admin/entity-management",
  AdminUserManagement = "/admin/user-management",
  AdminUser = "/admin/user-management/:userId",
  AdminUserProfile = "/admin/user-management/:userId/profile",
  AdminUserProjects = "/admin/user-management/:userId/projects",
  AdminAddNewUser = "/admin/user-management/add-user",
  AdminReset = "/admin/reset",
  AdminEntityManagementOrganization = "/admin/entity-management/organization",
  AdminEntityManagementSubstances = "/admin/entity-management/substances",
  AdminEntityManagementProducts = "/admin/entity-management/products",
  AdminEntityManagementProductFamily = "/admin/entity-management/product-family",
  AdminAuditTrail = "/admin/audit-trail",

  // ----
  HomePage = "/",
  Document = "/documents/:projectId",
  LegalAcknowledgementsConsent = "/legal-acknowledgements",
  LegalAcknowledgementDecline = "/legal-acknowledgements/do-not-agree",
  Login = "/login",
  LoginCallback = "/login/callback",
  Profile = "/profile",
  PostAuth = "/post-auth",
  FeatureFlags = "feature-flags",
  HealthCheck = "health-check",

  // Project
  Projects = "/projects",
  Project = "/projects/:projectId",
  ProjectSummary = "/projects/:projectId/summary",
  ProjectTasks = "/projects/:projectId/tasks",
  ProjectDocuments = "/projects/:projectId/documents",
  ProjectDocument = "/projects/:projectId/documents/:documentId",
  ProjectFHIRResource = "/projects/:projectId/documents/FHIR/:resourceType/:FHIRId",
  ProjectHAQs = "/projects/:projectId/HA-questions",
  ProjectHAQ = "/projects/:projectId/HA-questions/:HAQId",
  ProjectParticipatingHAs = "/projects/:projectId/participating-HAs",
  ProjectDetails = "/projects/:projectId/details",
  ProjectMembers = "/projects/:projectId/members",
  ProjectGSP = "/projects/:projectId/documents/GSP/:GSPId",
  ProjectECTD = "/projects/:projectId/documents/ECTD/:ECTDId",
  ProjectMilestones = "/projects/:projectId/milestones",

  // Tasks
  Tasks = "/tasks",
  Task = "/tasks/:taskId",
  // ToDo (AV): This is a temporary route to display HAQ preview. Will be refactored soon in favor of ProjectHAQ
  HAQAcceptanceTaskPreview = "/tasks/:taskId/haq-preview/:HAQId",

  // FHIR
  Portfolio = "/portfolio",
  TherapeuticSummary = "/portfolio/therapeutics/:therapeuticId",
  CountryProduct = "/portfolio/therapeutics/:therapeuticId/medication/:medicationId/country/:countryId",

  NotFound = "/not-found",
}

// currently we use it only as workaround to have ability use Route component inside of React components
// TODO: refactor breadcrumbs & tabs components logic to have ability fully migrate
// to relative paths
export enum RelativePath {
  // Project
  Project = "projects/:projectId/*",
  ProjectSummary = "summary",
  ProjectMilestones = "milestones",
  ProjectTasks = "tasks",
  ProjectDocuments = "documents",
  ProjectFHIRResource = "documents/FHIR/:resourceType/:FHIRId",
  ProjectHAQs = "HA-questions",
  ProjectParticipatingHAs = "participating-HAs",
  ProjectDetails = "details",
  ProjectMembers = "members",
  ProjectECTD = "documents/ECTD/:ECTDid",
}

export const APPLICATION_ROUTES = [
  {
    path: Path.Projects,
    name: "projects",
  },
  {
    path: Path.Project,
  },
  {
    path: Path.ProjectSummary,
    name: "summary",
  },
  {
    path: Path.ProjectTasks,
    name: "tasks",
  },
  {
    path: Path.ProjectDocuments,
    name: "documents",
  },
  {
    path: Path.ProjectDocument,
  },
  {
    path: Path.ProjectHAQs,
    name: "HAQs",
  },
  {
    path: Path.ProjectHAQ,
  },
  {
    path: Path.ProjectParticipatingHAs,
    name: "participatingHAs",
  },
  {
    path: Path.ProjectMembers,
    name: "members",
  },
  {
    path: Path.ProjectGSP,
  },
  {
    path: Path.Portfolio,
    name: "portfolio",
  },
  {
    path: Path.TherapeuticSummary,
    name: "therapeutic",
  },
  { path: Path.CountryProduct },
  {
    path: Path.Admin,
    name: "admin",
  },
  {
    path: Path.AdminUserManagement,
    name: "userManagement",
  },
  {
    path: Path.AdminAuditTrail,
    name: "auditTrail",
  },
  {
    path: Path.ProjectECTD,
  },
];
