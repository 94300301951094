import { forwardRef } from "react";
import { Box, Skeleton } from "@mui/material";
import styles from "./Skeleton.styles";

type TEditorSkeletonProps = {
  height: number;
  id?: string;
  order?: number;
};

const EditorSkeleton = forwardRef((props: TEditorSkeletonProps, ref) => {
  return (
    <Box
      ref={ref}
      sx={styles.container}
      className="highlightingItem"
      id={props.id}
      data-order={props.order}
    >
      <Box sx={styles.skeleton}>
        <Skeleton
          data-testid="skeleton"
          data-qaid="skeleton"
          height={props.height}
        />
      </Box>
    </Box>
  );
});

export default EditorSkeleton;
