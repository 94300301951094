import React, { Ref } from "react";
import { useTranslation } from "react-i18next";
import { BaseTextFieldProps } from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "components/shared/TextField";
import { FALLBACK_LANGUAGE } from "utils/constants/common";
import { getDateValue } from "utils/helpers";

export type TDatePickerProps = Omit<
  MuiDatePickerProps<Date, Date>,
  "label" | "onChange" | "renderInput" | "value"
> &
  Pick<
    BaseTextFieldProps,
    "error" | "helperText" | "id" | "required" | "size" | "sx"
  > & {
    label?: string;
    value?: Date | number | string;
    onChange?: (value: Date | null, keyboardInputValue?: string) => void;
    onBlur?: () => void;
  };

const DatePicker = React.forwardRef(
  (
    {
      error,
      helperText,
      id,
      label,
      required,
      size,
      sx,
      value,
      onBlur,
      onChange,
      disabled,
      ...props
    }: TDatePickerProps,
    ref: Ref<any>,
  ) => {
    const { i18n } = useTranslation();
    require(`dayjs/locale/${i18n.language || FALLBACK_LANGUAGE}.js`);

    const handleChange = (value: Date | null) => {
      onChange?.(value);
    };

    return (
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18n.language}
      >
        <MuiDatePicker
          data-testid={id}
          data-qaid={id}
          disabled={disabled}
          InputProps={{
            ...(id && { id }),
          }}
          label={label}
          renderInput={(params) => (
            <TextField
              {...params}
              ref={ref}
              error={!disabled && error}
              helperText={helperText}
              required={required}
              size={size}
              sx={sx}
              onBlur={onBlur}
              FormHelperTextProps={{
                variant: "standard",
                "aria-live": "polite",
              }}
            />
          )}
          value={getDateValue(value)}
          onChange={handleChange}
          {...props}
        />
      </LocalizationProvider>
    );
  },
);

export default DatePicker;
