/* eslint-disable no-restricted-imports */
import * as React from "react";
import { Ref } from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup as MUIRadioGroup,
  RadioGroupProps as MUIRadioGroupProps,
} from "@mui/material";

type TOption = {
  label: string;
  value: string;
};

export type TRadioGroupProps = MUIRadioGroupProps & {
  options: TOption[];
};

const RadioGroup = React.forwardRef(
  ({ id, options, ...props }: TRadioGroupProps, ref?: Ref<any>) => {
    return (
      <MUIRadioGroup ref={ref} data-testid={id} data-qaid={id} {...props}>
        {options.map((option) => {
          return (
            <FormControlLabel
              key={option.label + option.value}
              control={<Radio size="small" />}
              {...option}
            />
          );
        })}
      </MUIRadioGroup>
    );
  },
);

export default RadioGroup;
