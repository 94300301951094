import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ModeComment, ModeEdit, Visibility } from "@mui/icons-material";
import { Box, MenuItem, Typography } from "@mui/material";
import Select from "components/shared/Select";
import { TDocumentViewMode } from "models/documents.models";
import {
  DocumentSubtype,
  DocumentViewMode,
} from "utils/constants/doc.constants";
import { includeInArray } from "utils/helpers";
import { TDocumentHeaderProps } from "./DocumentHeader";
import styles from "./DocumentHeader.styles";

export const HeaderModeSelect = ({
  viewMode,
  onViewModeChange,
  isActionsDisabled,
  documentSubtype,
}: Pick<
  TDocumentHeaderProps,
  "viewMode" | "onViewModeChange" | "isActionsDisabled" | "documentSubtype"
>) => {
  const { t } = useTranslation("documents", {
    keyPrefix: "documentHeader",
  });

  const documentModeOptions = useMemo(
    () => [
      {
        value: DocumentViewMode.Editing,
        label: t("editLabel"),
        subTitle: t("editSubtitle"),
        icon: <ModeEdit sx={styles.icon} fontSize="small" />,
      },
      {
        value: DocumentViewMode.Viewing,
        label: t("viewLabel"),
        subTitle: t("viewSubtitle"),
        icon: <Visibility sx={styles.icon} fontSize="small" />,
      },
      ...includeInArray(
        {
          value: DocumentViewMode.Review,
          label: t("reviewLabel"),
          subTitle: t("reviewSubtitle"),
          icon: <ModeComment sx={styles.icon} fontSize="small" />,
        },
        Boolean(!documentSubtype || documentSubtype !== DocumentSubtype.FHIR),
      ),
    ],
    [t, documentSubtype],
  );

  return (
    <Select
      id="modeSelect"
      data-qaid="header-mode-select"
      sx={styles.modeSelect}
      size="small"
      defaultValue={viewMode}
      disabled={isActionsDisabled}
      onChange={({ target }) =>
        onViewModeChange(target.value as TDocumentViewMode)
      }
      value={viewMode}
    >
      {documentModeOptions.map((mode) => (
        <MenuItem
          sx={styles.modeSelectItem}
          key={mode.value}
          value={mode.value}
        >
          {mode.icon}
          <Box>
            <Typography variant="body2">{mode.label}</Typography>
            <Typography variant="body2" sx={styles.itemSubtitle}>
              {mode.subTitle}
            </Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};
