import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const Styles: TStyles = {
  default: {
    borderRadius: "8px",
    boxShadow: (theme) =>
      `0px 2px 4px ${getPaletteColor("action.selected")(theme)}`,
    border: getBorderStyle("1px", "solid", "custom.cardBorder"),

    ".MuiCardActions-root": {
      justifyContent: "flex-end",
      paddingRight: (theme) => theme.spacing(2),
      paddingBottom: (theme) => theme.spacing(2),
    },
  },
};

export default Styles;
