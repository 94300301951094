import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Typography } from "@mui/material";
import { FormCheckboxGroup } from "components/common/form-fields";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Loading from "components/shared/Loading";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import SelectedFile from "components/shared/SelectedFile/SelectedFile";
import useAllAssetVersions from "hooks/api/REST/assets/useAllAssetVersions";
import useDeleteAssetVersion from "hooks/api/REST/assets/useDeleteAssetVersion";
import { TDocumentComponent } from "screens/Project/sections/Documents/Document/Document.types";
import VersionOption from "screens/Project/sections/Documents/VersionOption/VersionOption";
import {
  DeleteVersions as DeleteVersionsField,
  deleteVersionsSchema,
  getDocumentVersionOptions,
  TCheckboxVersionOption,
} from "./DeleteVersions.utils";
import styles from "./DeleteVersions.styles";

export type TDeleteVersionsProps = {
  onClose: (isRefresh: boolean) => void;
  contentToDelete: TDocumentComponent | null;
  isOpen: boolean;
};

type TDeleteVersionsForm = {
  [DeleteVersionsField.Versions]: string[];
};

const DeleteVersions = ({
  onClose,
  contentToDelete,
  isOpen,
}: TDeleteVersionsProps) => {
  const methods = useForm<TDeleteVersionsForm>({
    defaultValues: { [DeleteVersionsField.Versions]: [] },
    resolver: zodResolver(deleteVersionsSchema),
  });

  const { formState, handleSubmit, reset } = methods;

  const { versions, isFetching: isFetchingVersions } = useAllAssetVersions(
    contentToDelete?.content.assetId || "",
  );

  const { t } = useTranslation(["documents", "common"]);

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const { mutateAsync: deleteAssetVersion, isLoading: isDeleting } =
    useDeleteAssetVersion();

  const onSubmit = async ({ versions }: TDeleteVersionsForm) => {
    await Promise.allSettled(
      versions.map((version) =>
        deleteAssetVersion({
          id: contentToDelete?.assetId || "",
          version: version,
        }),
      ),
    );
    onClose(true);
  };

  const versionOptions = useMemo(
    () => getDocumentVersionOptions(versions),
    [versions],
  );

  return (
    <Modal
      data-qaid="delete-version-modal"
      open={isOpen}
      title={
        !Boolean(contentToDelete)
          ? t("deleteVersionModal.titleMultiple")
          : t("deleteVersionModal.title")
      }
    >
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="body2" marginBottom={1.5}>
          {t("deleteVersionModal.contentText")}
        </Typography>

        <SelectedFile
          fileName={contentToDelete?.headerText || ""}
          clearIconAriaLabel={t("ariaLabels.clearIcon", {
            fileName: contentToDelete?.headerText || "",
            ns: "common",
          })}
          withoutIcon
        />
        <Box sx={styles.versions}>
          {isFetchingVersions ? (
            <Loading />
          ) : (
            <FormCheckboxGroup
              name={DeleteVersionsField.Versions}
              options={versionOptions}
              allLabel={t("deleteVersionModal.allVersions", {
                versionsLength: contentToDelete?.content?.versions?.length,
              })}
              lockIconAriaLabel={t("deleteVersionModal.locked")}
              labelControl={(item: TCheckboxVersionOption) => (
                <VersionOption item={item} />
              )}
            />
          )}
        </Box>
        <ModalActionButtons>
          <Button
            key="delete-modal-cancel-button"
            onClick={() => onClose(false)}
          >
            {t("button.cancel", { ns: "common" })}
          </Button>
          <Button
            key="delete-modal-submit-button"
            disabled={!formState.isValid || isDeleting}
            variant="contained"
            color="error"
            type="submit"
          >
            {t("button.delete", { ns: "common" })}
          </Button>
        </ModalActionButtons>
      </FormWrapper>
    </Modal>
  );
};

export default DeleteVersions;
