import { useMutation, useQueryClient } from "react-query";
import useShowAlert, { TAlertMessage } from "hooks/useShowAlert";
import { IAssetRelationship } from "models/assets.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { AssetRelationship } from "utils/constants/assets.constants";

export type TCreateAssetsMutationData = {
  assetId: string;
  attachmentId: string;
};

const useCreateAssetRelationship = (
  relationType: AssetRelationship = AssetRelationship.HasAttachment,
  messages?: TAlertMessage,
) => {
  const queryClient = useQueryClient();

  const createAssetsRelationships = useMutation(
    ({ assetId, attachmentId }: TCreateAssetsMutationData) =>
      services.assets
        .createAssetRelationship(assetId, {
          otherAssetArn: attachmentId,
          relationship: relationType,
        })
        .then((res) => res.data as IAssetRelationship),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryAPIKey.GetAssetRelationships);
      },
    },
  );

  useShowAlert({
    status: createAssetsRelationships.status,
    ...messages,
  });

  return createAssetsRelationships;
};

export default useCreateAssetRelationship;
