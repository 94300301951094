import { TFunction } from "i18next";
import { BinaryChoice } from "utils/constants/common";

export type TEmailNotificationsOption = {
  label: string;
  value: BinaryChoice;
};

export const getEmailNotificationsOptions = (
  t: TFunction,
): TEmailNotificationsOption[] => {
  return [
    {
      label: t("emailNotifications:options.optIn"),
      value: BinaryChoice.Yes,
    },
    {
      label: t("emailNotifications:options.optOut"),
      value: BinaryChoice.No,
    },
  ];
};

export const getEmailNotificationOption = (
  isEnabled: boolean,
  t: TFunction,
): TEmailNotificationsOption => {
  const value = BinaryChoice.fromBoolean(isEnabled);
  const options = getEmailNotificationsOptions(t);
  const foundOption = options.find((option) => option.value === value);

  if (foundOption) {
    return foundOption;
  }

  throw new Error("wrong option in EmailNotificationsOptions");
};

export const getEmailNotificationLabel = (
  isEnabled: boolean,
  t: TFunction,
): string => {
  return getEmailNotificationOption(isEnabled, t).label;
};
