import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useChangeAssetStatus } from "api/orchestration/changeAssetStatus";
import { TSelectedHAQTableRow } from "components/shared/types";
import { useProject } from "context/ProjectContext";
import { EventType } from "models/tracking.models";
import {
  ConfirmStatusChangeModalAdvanced,
  TConfirmStatusChangeModalResult,
} from "screens/Project/screens/HAQ/components";
import { services } from "services";
import { AssetType } from "utils/constants/assets.constants";
import { HAQStatus } from "utils/constants/HAQ.constants";

export function useHandleActionClickV2(
  selectedRows: TSelectedHAQTableRow[],
  setChangeStatusModal: Dispatch<SetStateAction<JSX.Element>>,
  setIsAnyWorkflowInProgress: Dispatch<SetStateAction<boolean>>,
  shouldEnableTrackingEvent?: boolean,
) {
  const { t } = useTranslation(["HAQ", "notifications"]);
  const { project } = useProject();
  const changeAssetStatus = useChangeAssetStatus();

  return async function handleActionClickV2(targetStatus?: HAQStatus) {
    const result = await new Promise<TConfirmStatusChangeModalResult>(
      (resolve) => {
        setChangeStatusModal(
          <ConfirmStatusChangeModalAdvanced
            isOpen={true}
            handleConfirm={resolve}
            confirmationText={
              targetStatus
                ? t("HAQ:manualStatusChange.confirmComponents")
                : t("HAQ:manualStatusChange.confirmStatusAndComponents")
            }
            selectedRows={selectedRows}
            preselectedTargetStatus={targetStatus}
          />,
        );
      },
    );

    setChangeStatusModal(<></>);

    if (!result) {
      return;
    }

    setIsAnyWorkflowInProgress(true);

    await changeAssetStatus(
      result.targetAssets,
      result.targetStatus,
      t("workflowMessages.bulkStatusChangeSuccessMessage", {
        ns: "notifications",
      }),
      t("workflowMessages.bulkStatusChangeErrorMessage", {
        ns: "notifications",
      }),
    );

    setIsAnyWorkflowInProgress(false);

    shouldEnableTrackingEvent &&
      services.tracking.addBulkTrackingEvent(
        result.HAQIdsToTrack.map((HAQId) => ({
          assetId: HAQId,
          assetType: AssetType.Question,
          eventType: EventType.AppHAQChangeStatus,
          projectId: project?.id || "",
        })),
      );
  };
}
