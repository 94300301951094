import { RequestDocument, Variables } from "graphql-request/src/types";
import { gql } from "api";

const graphQL = {
  request<T, V extends Variables = Variables>(
    query: RequestDocument,
    variables?: V,
  ) {
    return gql<T, V>(query, variables);
  },
};

export default graphQL;
