import { getBorderStyle } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  drawerBottomBar: {
    p: 2,
    borderTop: getBorderStyle("1px", "solid", "divider"),
  },
};

export default styles;
