import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { gql } from "graphql-request";
import useShowAlert from "hooks/useShowAlert";
import { IFeatureFlag } from "models/featureFlags.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export const query = gql`
  query FeatureFlags {
    featureFlags {
      name
      description
      value
      dateStamp
      modifiedBy
    }
  }
`;

const useGetFeatureFlags = () => {
  const { t } = useTranslation("featureFlags");

  const { data, status, isLoading, isSuccess, isError } = useQuery<{
    featureFlags: IFeatureFlag[];
  }>(QueryAPIKey.GetFeatureFlags, () => services.graphQL.request(query), {
    enabled: true,
  });

  useShowAlert({
    status,
    failureMessage: t("notifications.loadFlagsFailure"),
  });

  return {
    featureFlags: data?.featureFlags,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useGetFeatureFlags;
