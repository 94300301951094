import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { gql, Variables } from "graphql-request";
import useShowAlert from "hooks/useShowAlert";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { IPortfolioDataResponse } from "./usePortfolioData.models";

export const query = gql`
  query Portfolio($isMockVersion: Boolean, $templateIdentifier: String) {
    portfolio(
      isMockVersion: $isMockVersion
      templateIdentifier: $templateIdentifier
    ) {
      report {
        id
        ingredient
        associatedProducts {
          id
          jurisdictions {
            id
            description
          }
        }
      }
    }
  }
`;

type TUsePortfolioData = {
  isMockVersion?: boolean;
  templateIdentifier?: string;
};

const usePortfolioData = (
  { isMockVersion = false, templateIdentifier }: TUsePortfolioData = {},
  enabled: boolean = true,
) => {
  const { t } = useTranslation("portfolio");

  const { data, status, isLoading, isSuccess } =
    useQuery<IPortfolioDataResponse>(
      [QueryAPIKey.GetPortfolio, isMockVersion, templateIdentifier],
      () =>
        services.graphQL.request<IPortfolioDataResponse, Variables>(query, {
          isMockVersion,
          templateIdentifier,
        }),
      { enabled },
    );

  useShowAlert({
    status,
    failureMessage: t("notifications.getPortfolioFailure"),
  });

  return {
    data,
    isLoading,
    isSuccess,
  };
};

export default usePortfolioData;
