import { useTranslation } from "react-i18next";
import { Alert, Box, Stack } from "@mui/material";
import Loading from "components/shared/Loading";
import { IDocument } from "models/documents.models";
import { DocumentsOptions } from "screens/Project/sections/Documents/modals/ImportDocument";
import styles from "./AddDocuments.styles";

type TAddDocumentsProps = {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  documents: IDocument[] | undefined;
  onChange: (document: IDocument) => void;
};

const AddDocuments = ({
  isError,
  isLoading,
  isSuccess,
  documents,
  onChange,
}: TAddDocumentsProps) => {
  const { t } = useTranslation("documents");

  return (
    <Stack gap={1.5}>
      {isError && (
        <Alert severity="error">{t("documents.failedLoadDocumentList")}</Alert>
      )}
      {isLoading && <Loading sx={styles.loading} />}
      {isSuccess &&
        (Boolean(documents?.length) ? (
          <DocumentsOptions
            documents={documents || []}
            onChange={onChange}
            isCheckbox
          />
        ) : (
          <Box>
            {t("documents.commonFields.documents.noDocumentsToDisplay")}
          </Box>
        ))}
    </Stack>
  );
};

export default AddDocuments;
