import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import ChosenAssets from "components/common/ChosenAssets";
import { ConfirmModal } from "components/shared";

type TConfirmStatusChangeModalBasicProps = {
  isOpen: boolean;
  handleConfirm: (shouldConfirm: boolean) => void;
  selectedContent: string[];
  confirmationText: string;
};

const ConfirmStatusChangeModalBasic = (
  props: TConfirmStatusChangeModalBasicProps,
) => {
  const { t } = useTranslation(["HAQ", "common"]);

  const { isOpen, handleConfirm, selectedContent, confirmationText } = props;

  return (
    <ConfirmModal
      confirmColor="primary"
      open={isOpen}
      title={t("actionOptionsChangeStatusLabel")}
      onConclude={handleConfirm}
      confirmButtonText={t("button.confirm", { ns: "common" })}
      cancelButtonText={t("button.cancel", { ns: "common" })}
      isConfirmButtonDisabled={isEmpty(selectedContent)}
    >
      <Stack spacing={2}>
        <Typography variant="body2">
          {isEmpty(selectedContent)
            ? t("actionOptionsChangeStatusEmpty")
            : confirmationText}
        </Typography>
        <ChosenAssets content={selectedContent} />
      </Stack>
    </ConfirmModal>
  );
};

export default ConfirmStatusChangeModalBasic;
