import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Alert as MuiAlert, AlertProps as MuiAlertProps } from "@mui/material";

const Alert = (props: MuiAlertProps) => (
  <MuiAlert
    {...props}
    iconMapping={{
      success: <CheckCircleOutlineIcon fontSize="inherit" />,
    }}
  />
);

export default Alert;
