import { TStyles } from "utils/Styles";

const styles: TStyles = {
  drawer: {
    zIndex: 1000,

    "& form": {
      height: "100%",
    },
  },

  container: {
    width: "50vw",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};

export default styles;
