/* eslint-disable no-restricted-imports */
import { ForwardedRef, forwardRef } from "react";
import {
  Box,
  Button as MuiButton,
  ButtonProps as MUIButtonProps,
} from "@mui/material";
import Loading from "../Loading";
import styles from "./Button.styles";

type TButtonProps<C extends React.ElementType> = MUIButtonProps<
  C,
  { component?: C }
> & {
  loading?: boolean;
};

const Button = forwardRef(function Button<C extends React.ElementType>(
  props: TButtonProps<C>, //https://mui.com/material-ui/guides/composition/#with-typescript
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { loading, disabled, ...buttonProps } = props;

  // should be changed when this component will be implemented on mui https://mui.com/material-ui/api/loading-button/
  return (
    <MuiButton
      ref={ref}
      sx={styles.button}
      data-insights-id={
        props["aria-label"] || props["data-testid"] || props["data-qaid"]
      }
      {...buttonProps}
      disabled={disabled || loading}
    >
      <>
        {loading && <Loading size={16} sx={styles.loading} color="inherit" />}
        <Box sx={loading ? styles.hidden : {}} component="span">
          {props.children}
        </Box>
      </>
    </MuiButton>
  );
});

export default Button;
