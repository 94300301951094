import { useEffect } from "react";
import { useParams } from "react-router";
import { Box, Divider, Stack } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { Loading } from "components/shared";
import { useHAQ } from "hooks/haq";
import useHideJiraWidget from "hooks/useHideJiraWidget";
import { EventType } from "models/tracking.models";
import { services } from "services";
import { AssetType } from "utils/constants/assets.constants";
import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";
import commonStyles from "styles/common.styles";

export type THAQDrawerProps = {
  HAQId: string | undefined;
  onCloseDrawer: () => void;
};

export const HAQDrawer = ({ HAQId, onCloseDrawer }: THAQDrawerProps) => {
  const { projectId } = useParams();

  useHideJiraWidget(Boolean(HAQId));

  const {
    HAQ,
    isHAQLoading,
    HAQResponse,
    HAQContent,
    HAQResponseContent,
    isHAQContentLoading,
    isHAQResponseContentLoading,
    shareHistoryTableProps,
  } = useHAQ(HAQId);

  useEffect(() => {
    if (HAQId) {
      services.tracking.addTrackingEvent({
        assetId: HAQId,
        assetType: AssetType.Question,
        eventType: EventType.AppHAQViewMetadata,
        projectId: projectId || "",
      });
    }
  }, [HAQId, projectId]);

  return (
    <Drawer anchor="right" open={Boolean(HAQId)} onClose={onCloseDrawer}>
      <Box sx={{ width: 500 }}>
        {isHAQLoading ? (
          <Loading sx={commonStyles.loadingIndicator} />
        ) : (
          <Stack
            padding={3}
            spacing={2}
            role="complementary"
            divider={<Divider />}
          >
            <Header
              HAQ={HAQ}
              HAQResponse={HAQResponse}
              onCloseDrawer={onCloseDrawer}
              shareHistory={shareHistoryTableProps}
            />
            <Content
              HAQContent={HAQContent}
              isHAQContentLoading={isHAQContentLoading}
              HAQResponseContent={HAQResponseContent}
              isHAQResponseContentLoading={isHAQResponseContentLoading}
            />
            <Footer HAQ={HAQ} projectId={projectId} />
          </Stack>
        )}
      </Box>
    </Drawer>
  );
};

export default HAQDrawer;
