import { AxiosResponse } from "axios";
import { get, routes } from "api";
import { IGetOrganizationsRequestParams } from "models/organizations.models";

const organizations = {
  getOrganizations(
    params: IGetOrganizationsRequestParams,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return get(routes.identity.organizations.all(), {
      params,
      isMockApiOn,
    });
  },
  getOrganizationById(
    id: string,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return get(routes.identity.organizations.byId(id), {
      isMockApiOn,
    });
  },
};

export default organizations;
