import { AxiosResponse } from "axios";
import { get, put, routes } from "api";
import {
  IUpdateProjectMemberRequestData,
  IUpdateProjectMemberStatusRequestData,
} from "models/projectMembers.models";

const projectMembers = {
  getProjectMembers(
    projectId: string,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return get(routes.identity.projectMembers.all(projectId), {
      isMockApiOn,
    });
  },

  updateProjectMember: (
    projectId: string,
    data: IUpdateProjectMemberRequestData,
  ): Promise<AxiosResponse> => {
    return put(routes.identity.projectMembers.individualAssignment(projectId), {
      data,
    });
  },

  updateProjectMemberStatus: (
    projectId: string,
    data: IUpdateProjectMemberStatusRequestData,
  ): Promise<AxiosResponse> => {
    return put(routes.asset.projects.memberStatus(projectId), {
      data,
    });
  },
};

export default projectMembers;
