import { useEffect, useRef, useState } from "react";
import { IIntersectionObserverOptions } from "./useElementOnScreen.types";

const useElementOnScreen = (options: IIntersectionObserverOptions) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  const toggleElementVisible = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(toggleElementVisible, options);

    const currentRef = containerRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [containerRef, isVisible, options]);

  return { containerRef, isVisible };
};

export default useElementOnScreen;
