import { useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import Button from "components/shared/Button";
import {
  TFilteringParams,
  TTableFilterProps,
} from "components/shared/types/filter";
import { omit } from "utils/helpers";
import FilterChips from "./FilterChips";
import FilterSelects from "./FilterSelects";
import KeywordFilterField from "./KeywordFilterField";
import { getActiveFilters, getSelectedFilterCount } from "./TableFilter.utils";
import { FilterLinesIcon } from "assets/icons";
import styles from "./TableFilter.styles";

const TableFilter = (props: TTableFilterProps) => {
  const [shouldShowFilters, setShouldShowFilters] = useState(false);

  const activeFilters = useMemo(
    () => getActiveFilters(props.filters, props.selectedFilters),
    [props.filters, props.selectedFilters],
  );

  const selectedFilterCount = useMemo(
    () => getSelectedFilterCount(props.selectedFilters),
    [props.selectedFilters],
  );

  const showHideButtonLabel = useMemo(
    () => (shouldShowFilters ? props.hideButtonLabel : props.showButtonLabel),
    [shouldShowFilters, props.hideButtonLabel, props.showButtonLabel],
  );

  const showHideButtonAriaLabel =
    showHideButtonLabel +
    (selectedFilterCount > 0 ? ` (${selectedFilterCount})` : "");

  const clearAllFilters = () => props.onFiltersChange?.({});

  const deleteFilterSelection = (filterKey: string, value: string) => {
    if (!props.selectedFilters || !props.selectedFilters[filterKey]) {
      return;
    }

    const newValues =
      props.selectedFilters[filterKey]?.filter(
        (filterValue) => filterValue !== value,
      ) ?? [];

    updateFilter({ [filterKey]: newValues });
  };

  const handleClearAllClicked = () => {
    clearAllFilters();
    setShouldShowFilters(false);
  };

  const toggleFilters = () => setShouldShowFilters((prevState) => !prevState);

  const updateFilter = (filters: TFilteringParams) => {
    Object.keys(filters).forEach((filterName) => {
      if (isEmpty(filters[filterName]) && Boolean(props.selectedFilters)) {
        // if the filter no longer has any selected values, remove it from the selected filters object
        props.onFiltersChange?.(
          omit(props.selectedFilters ?? {}, [filterName]),
        );
      } else {
        // add or overwrite the filter in the selected filters object
        props.onFiltersChange?.({
          ...props.selectedFilters,
          ...filters,
        });
      }
    });
  };

  return (
    <Box>
      <Stack direction="row" spacing={1} alignItems="center">
        {!props.hideKeywordFilter && (
          <KeywordFilterField
            onChange={props.onKeywordChange}
            value={props.keywordFilterValue}
            placeholder={props.keywordFilterPlaceholder}
            filterByKeywordLabel={props.filterByKeywordLabel}
            errorAdornmentAriaLabel={props.errorAdornmentAriaLabel}
          />
        )}
        {Boolean(showHideButtonLabel) && (
          <Button
            data-testid="show-or-hide-filters-btn"
            data-qaid="show-or-hide-filters-btn"
            aria-label={showHideButtonAriaLabel}
            endIcon={selectedFilterCount || null}
            onClick={toggleFilters}
            startIcon={<FilterLinesIcon />}
            sx={styles.showHideFiltersButton}
            variant="outlined"
          >
            {showHideButtonLabel}
          </Button>
        )}
        {selectedFilterCount ? (
          <Button
            data-testid="clear-all-filters-btn"
            data-qaid="clear-all-filters-btn"
            onClick={handleClearAllClicked}
            sx={styles.clearAllButton}
            variant="text"
          >
            {props.clearAllFiltersLabel}
          </Button>
        ) : null}
        {props.switcher ? props.switcher : null}
      </Stack>
      {shouldShowFilters ? (
        <FilterSelects
          filters={props.filters}
          updateFilter={updateFilter}
          selectedFilters={props.selectedFilters}
        />
      ) : null}

      {!shouldShowFilters && !isEmpty(activeFilters) ? (
        <FilterChips
          activeFilters={activeFilters}
          onDelete={deleteFilterSelection}
        />
      ) : null}
    </Box>
  );
};

export default TableFilter;
