import { Outlet } from "react-router";
import { UserSessionTimeout } from "components/Auth";
import { useSessionCheck } from "./hooks/useSessionCheck";

export const SecureRoute = () => {
  const { authState } = useSessionCheck();

  if (!authState || !authState.isAuthenticated) {
    return null;
  }

  return (
    <>
      <UserSessionTimeout />
      <Outlet />
    </>
  );
};
