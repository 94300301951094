import { TStyles } from "utils/Styles";
import { focusOutlineStyle } from "styles/accessibility.styles";

const styles: TStyles = {
  navItem: {
    "&:focus-visible": {
      outline: focusOutlineStyle,
    },
  },
  languageIcon: {
    width: 25,
    height: 25,
  },
};

export default styles;
