import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Expand,
  SettingsOutlined,
  VerticalAlignCenter as Collapse,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material";
import { useDocumentSidebarContext } from "screens/Project/sections/Documents/Document";

export type TUseDocumentOutlineActionsValue = {
  shouldShowStatus: boolean;
  setShouldShowStatus: React.Dispatch<React.SetStateAction<boolean>>;
  shouldShowVersionNumber: boolean;
  setShouldShowVersionNumber: React.Dispatch<React.SetStateAction<boolean>>;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useDocumentOutlineActions(): TUseDocumentOutlineActionsValue {
  const [shouldShowStatus, setShouldShowStatus] = useState<boolean>(true);

  const [shouldShowVersionNumber, setShouldShowVersionNumber] =
    useState<boolean>(true);

  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  return useMemo(
    () => ({
      shouldShowStatus,
      setShouldShowStatus,
      shouldShowVersionNumber,
      setShouldShowVersionNumber,
      isExpanded,
      setIsExpanded,
    }),
    [isExpanded, shouldShowStatus, shouldShowVersionNumber],
  );
}

const DocumentOutlineActions = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { t } = useTranslation("documents");

  const {
    setIsExpanded,
    shouldShowStatus,
    setShouldShowStatus,
    shouldShowVersionNumber,
    setShouldShowVersionNumber,
  } = useDocumentSidebarContext();

  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpandAll = () => {
    setIsExpanded(true);
    setAnchorEl(null);
  };

  const handleCollapseAll = () => {
    setIsExpanded(false);
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        aria-label={t("document.outline.options")}
        size="small"
        onClick={handleOpen}
        id="outline-options-button"
        aria-controls={open ? "outline-options-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        data-testid="outline-options-dropdown-button"
        data-qaid="outline-options-dropdown-button"
      >
        <SettingsOutlined sx={{ height: "16px", width: "16px" }} />
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        id="outline-options-menu"
        MenuListProps={{
          "aria-labelledby": "outline-options-button",
        }}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Switch
                data-qaid="show-status-switch-field"
                checked={shouldShowStatus}
                onChange={() => setShouldShowStatus((prev) => !prev)}
              />
            }
            label={t("document.outline.showStatus")}
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <ListItemIcon>
                <Switch
                  data-qaid="show-version-switch-field"
                  checked={shouldShowVersionNumber}
                  onChange={() => setShouldShowVersionNumber((prev) => !prev)}
                />
              </ListItemIcon>
            }
            label={t("document.outline.showVersion")}
          />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleExpandAll}>
          <ListItemIcon>
            <Expand />
          </ListItemIcon>
          <ListItemText>{t("document.outline.expandAll")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleCollapseAll}>
          <ListItemIcon>
            <Collapse />
          </ListItemIcon>
          <ListItemText>{t("document.outline.collapseAll")}</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default DocumentOutlineActions;
