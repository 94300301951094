import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack, Typography } from "@mui/material";
import { FormCheckboxGroup, FormSelect } from "components/common/form-fields";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { useProject } from "context/ProjectContext";
import useStartWorkflow from "hooks/api/REST/workflow/useStartWorkflow";
import usePermissions from "hooks/permissions";
import useUser from "hooks/useUser";
import { getDocumentStatusChangeOptions } from "screens/Project/sections/Documents/utils/documentStatusChanges";
import { WorkflowId } from "utils/constants/workflows.constants";
import { getDisplayedAssetStatus } from "utils/helpers";
import { isHAUser } from "utils/user/organization";
import {
  bulkStatusChangeDefaultValues,
  BulkStatusChangeForm,
  bulkStatusChangeSchema,
  TBulkStatusChangeForm,
  TBulkStatusChangeModalProps,
  TGroupedOutline,
} from "./BulkStatusChangeModal.types";
import { flattenOutlineOptions } from "./BulkStatusChangeModal.utils";
import styles from "./BulkStatusChangeModal.styles";

const BulkStatusChangeModal = ({
  outline,
  onClose,
  isOpen,
}: TBulkStatusChangeModalProps) => {
  const { t } = useTranslation(["documents", "common", "notifications"]);

  const { user, organization } = useUser();

  const { project } = useProject();

  const { permissions } = usePermissions(project?.id ?? "");

  const { startWorkflow } = useStartWorkflow({
    successMessage: t("workflowMessages.bulkStatusChangeSuccessMessage", {
      ns: "notifications",
    }),
    failureMessage: t("workflowMessages.bulkStatusChangeErrorMessage", {
      ns: "notifications",
    }),
  });

  const methods = useForm<TBulkStatusChangeForm>({
    mode: "all",
    defaultValues: bulkStatusChangeDefaultValues,
    resolver: zodResolver(bulkStatusChangeSchema(t)),
  });

  const { formState, handleSubmit, reset, watch, resetField } = methods;

  const handleClose = useCallback(() => {
    onClose();
    reset();
  }, [reset, onClose]);

  useEffect(() => {
    const { unsubscribe } = watch((value, { name }) => {
      if (
        name === BulkStatusChangeForm.CurrentStatus &&
        value[BulkStatusChangeForm.NewStatus]
      ) {
        resetField(BulkStatusChangeForm.NewStatus);
      }
    });

    return () => unsubscribe();
  }, [resetField, watch]);

  const onSubmit = useCallback(
    (data: TBulkStatusChangeForm) => {
      if (user?.id && organization && project?.id) {
        startWorkflow(
          {
            id: isHAUser(organization)
              ? WorkflowId.HAAssetManualStatusChange
              : WorkflowId.SponsorAssetManualStatusChange,
            payload: {
              originator: user.id,
              project: project.id,
              assets: data[BulkStatusChangeForm.ComponentIds],
              status: data[BulkStatusChangeForm.NewStatus],
            },
          },
          { onSuccess: handleClose },
        );
      }
    },
    [handleClose, user?.id, organization, startWorkflow, project?.id],
  );

  const groupedOutline: TGroupedOutline = useMemo(
    () =>
      flattenOutlineOptions(outline || [], "").reduce((acc, curr) => {
        const status = curr.status;

        if (acc[status] !== undefined) {
          acc[status].push(curr);
        } else {
          acc[status] = [curr];
        }

        return acc;
      }, {} as TGroupedOutline),
    [outline],
  );

  const currentStatus = watch(BulkStatusChangeForm.CurrentStatus);

  const newStatusOptions = useMemo(() => {
    if (currentStatus === "") {
      return [];
    } else {
      return getDocumentStatusChangeOptions(
        currentStatus,
        organization,
        t,
        permissions,
      )
        .filter(({ id }) => id !== currentStatus)
        .map(({ id, label }) => ({ id, label, value: id }));
    }
  }, [currentStatus, organization, t, permissions]);

  const currentStatusOptions = useMemo(
    () =>
      Object.entries(groupedOutline).map(([key, val]) => ({
        id: key,
        label: `${getDisplayedAssetStatus(organization?.type, key, t)} (${
          val.length
        })`,
        value: key,
      })),
    [organization?.type, groupedOutline, t],
  );

  const componentsWithCurrentStatus = useMemo(() => {
    if (currentStatus === "") {
      return [];
    } else {
      const currOutline = groupedOutline[currentStatus];

      if (currOutline === undefined) {
        return [];
      }

      return currOutline.map(({ id, name, index }) => ({
        id,
        label: `${index} ${name}`,
      }));
    }
  }, [currentStatus, groupedOutline]);

  const cancelButton = (
    <Button
      data-qaid="bulk-change-cancel-button"
      key="cancel"
      onClick={handleClose}
    >
      {t("button.cancel", { ns: "common" })}
    </Button>
  );

  const submitButton = (
    <Button
      variant="contained"
      data-qaid="bulk-change-save-button"
      type="submit"
      key="submit"
      disabled={!formState.isValid}
    >
      {t("button.save", { ns: "common" })}
    </Button>
  );

  return (
    <Modal
      data-qaid="bulk-status-change-modal"
      title={t("bulkComponentStatus.title", { ns: "documents" })}
      open={isOpen}
    >
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          <p>{t("bulkComponentStatus.description", { ns: "documents" })}</p>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="start"
            spacing={1}
          >
            <Trans t={t} i18nKey="bulkComponentStatus.statusTransition">
              <Box sx={styles.selectContainer}>
                <FormSelect
                  data-qaid="bulk-component-current-status-select"
                  name={BulkStatusChangeForm.CurrentStatus}
                  options={currentStatusOptions}
                  label={t("bulkComponentStatus.currentStatus", {
                    ns: "documents",
                  })}
                />
              </Box>
              <Box sx={styles.preposition}>
                {t("bulkComponentStatus.preposition", {
                  ns: "documents",
                })}
              </Box>
              <Box sx={styles.selectContainer}>
                <FormSelect
                  data-qaid="bulk-component-new-status-select"
                  name={BulkStatusChangeForm.NewStatus}
                  options={newStatusOptions}
                  label={t("bulkComponentStatus.newStatus", {
                    ns: "documents",
                  })}
                />
              </Box>
            </Trans>
          </Stack>
          {componentsWithCurrentStatus.length ? (
            <Box sx={styles.componentsContainer}>
              <FormCheckboxGroup
                name={BulkStatusChangeForm.ComponentIds}
                lockIconAriaLabel={t("multiSelect.locked", {
                  ns: "common",
                })}
                allLabel={t("multiSelect.selectAll", { ns: "common" })}
                options={componentsWithCurrentStatus}
              />
            </Box>
          ) : (
            <Box sx={styles.componentsContainerCenter}>
              <Typography variant="body2">
                <i>{t("bulkComponentStatus.empty", { ns: "documents" })}</i>
              </Typography>
            </Box>
          )}
        </Stack>
        <ModalActionButtons buttons={[cancelButton, submitButton]} />
      </FormWrapper>
    </Modal>
  );
};

export default BulkStatusChangeModal;
