import { useTranslation } from "react-i18next";
import { QueryObserverOptions, useQuery } from "react-query";
import { ClientError, gql } from "graphql-request";
import { Variables } from "graphql-request/src/types";
import useShowAlert from "hooks/useShowAlert";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { IProjectGQLResponseData } from "./useProjectData.models";

export const query = gql`
  query ProjectData($projectId: String) {
    summary {
      project(projectId: $projectId) {
        active
        id
        name
        status
        type
        sourceArn
        metadata
        previousStatus
        members {
          userId
          roles
        }
        ... on Reliance {
          regulatoryEvent
          eventTypes
          sponsor {
            id
            name
          }
        }
        ... on RegulatoryReview {
          regulatoryEvent
          eventTypes
          sponsor {
            id
            name
          }
        }
      }
    }
  }
`;

const useProjectData = (
  projectId: string | undefined,
  options?: QueryObserverOptions<IProjectGQLResponseData, ClientError>,
) => {
  const { t } = useTranslation("projects");

  const { data, status, isLoading, error } = useQuery<
    IProjectGQLResponseData,
    ClientError
  >(
    [QueryAPIKey.GetProjectData, projectId],
    () =>
      services.graphQL.request<IProjectGQLResponseData, Variables>(query, {
        projectId,
      }),
    {
      enabled: Boolean(projectId),
      ...options,
    },
  );

  useShowAlert({
    status,
    failureMessage: t("notifications.getProjectInformationFailure"),
  });

  return {
    data: data?.summary.project,
    isLoading,
    error,
  };
};

export default useProjectData;
