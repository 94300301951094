import React, { useState } from "react";
import { Link } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import { Divider, Stack } from "@mui/material";
import { ButtonLink } from "components/shared";
import usePermissions from "hooks/permissions";
import { IDocument } from "models/documents.models";
import { PreviewDocument } from "screens/Project/sections/Documents/modals";
import { getCanDocumentBePreviewed } from "screens/Project/sections/Documents/utils/documentActions";
import { getDocumentPath } from "utils/helpers";
import { TAtLeast, TEditModeProps } from "utils/types";
import styles from "./AttachmentsList.styles";

type TAttachmentsListEditMode<B extends boolean> = B extends false
  ? Partial<TEditModeProps<B, IDocument>>
  : TAtLeast<TEditModeProps<B, IDocument>, "onRemoveClick">;

export type TAttachmentsListProps = {
  attachedDocuments?: IDocument[];
  projectId?: string;
} & (TAttachmentsListEditMode<false> | TAttachmentsListEditMode<true>);

const AttachmentsList = ({
  attachedDocuments,
  projectId,
  isEditMode,
  onRemoveClick,
}: TAttachmentsListProps) => {
  const [documentToPreview, setDocumentToPreview] = useState<IDocument | null>(
    null,
  );

  const { permissions } = usePermissions(projectId || "");

  return (
    <Stack sx={styles.container}>
      {attachedDocuments?.map((document) => (
        <React.Fragment key={document.id}>
          <Stack
            justifyContent="space-between"
            direction="row"
            sx={styles.documentLink}
          >
            {getCanDocumentBePreviewed(document, permissions) ? (
              <ButtonLink onClick={() => setDocumentToPreview(document)}>
                {document.name}
              </ButtonLink>
            ) : (
              <Link to={getDocumentPath(projectId, document)}>
                {document.name}
              </Link>
            )}
            {isEditMode && (
              <ClearIcon onClick={() => onRemoveClick(document)} />
            )}
          </Stack>
          <Divider />
        </React.Fragment>
      ))}
      {documentToPreview && (
        <PreviewDocument
          document={documentToPreview}
          onClose={() => setDocumentToPreview(null)}
        />
      )}
    </Stack>
  );
};

export default AttachmentsList;
