import { useEffect } from "react";
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import {
  Checkbox as MUICheckbox,
  CheckboxProps as MUICheckboxProps,
  FormControlLabel,
} from "@mui/material";

export type TFormCheckboxProps<FormType extends FieldValues> = Omit<
  MUICheckboxProps,
  "checked" | "value"
> & {
  name: FieldPath<FormType>;
  label: string;
};

const FormCheckbox = <FormType extends FieldValues>({
  name,
  label,
  ...props
}: TFormCheckboxProps<FormType>) => {
  const { control, unregister } = useFormContext<FormType>();

  useEffect(
    () => () => {
      unregister(name, { keepValue: true });
    },
    [unregister, name],
  );

  return (
    <FormControlLabel
      label={label}
      control={
        <Controller
          name={name}
          control={control}
          render={({ field: { value, ...field } }) => (
            <MUICheckbox
              {...field}
              {...props}
              checked={!!value}
              data-testid="checkbox-field"
            />
          )}
        />
      }
    />
  );
};

export default FormCheckbox;
