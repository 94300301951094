import { ProjectType } from "utils/constants/project.constants";
import { isHAUser, isSponsorUser } from "utils/user/organization";
import { TPermissionFunction } from "../usePermissions.types";

//  TODO: Implementation of this functionality within the task FTE-10084

export const deriveViewAllProjectsPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
  isGlobalPM,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        // ALL HA users are PM users
        return true;
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager || isGlobalPM) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        // ALL HA users are PM users
        return true;
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager || isGlobalPM) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveAssignUserAccountToProjectPermission: TPermissionFunction =
  ({ project, organization, userRoles }) => {
    switch (project?.type) {
      case ProjectType.Reliance:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return false;
          }
        }

        return false;

      case ProjectType.RegulatoryReview:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return false;
          }
        }

        return false;

      default:
        return false;
    }
  };

export const deriveAssignUserAccountRoleTypePermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveEditProjectMembersPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.Orbis:
      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveRemoveProjectMembersPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.Orbis:
      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveCreateNewProjectPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  // This is currently not being utilized for Project Creation, since it relies upon `project` as an argument.
  // An individual project context is not yet available when a user clicks the "New Project" button

  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveChangeProjectStatusPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.Orbis:
      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveEditProjectInformationPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveEditParticipationTypePermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveChangeProjectMembersStatusPermission: TPermissionFunction =
  ({ project, organization, userRoles }) => {
    switch (project?.type) {
      case ProjectType.Reliance:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }
        }

        return false;

      case ProjectType.RegulatoryReview:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }
        }

        return false;

      default:
        return false;
    }
  };
