import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { TFunction } from "i18next";
import ConfirmModal from "components/shared/ConfirmModal";
import useStartWorkflow from "hooks/api/REST/workflow/useStartWorkflow";
import useUser from "hooks/useUser";
import { IShareHistoryItem } from "utils/constants/shareHistory.constants";
import { WorkflowId } from "utils/constants/workflows.constants";

type TRevokeButtonProps = {
  item: IShareHistoryItem;
  shouldShow?: boolean;
  t: TFunction;
};

export const RevokeButton = ({ item, shouldShow, t }: TRevokeButtonProps) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const { user } = useUser();

  const { startWorkflow, isLoading, isSuccess } = useStartWorkflow({
    successMessage: t("workflowMessages.revokeShareSuccessMessage"),
    failureMessage: t("workflowMessages.revokeShareErrorMessage"),
  });

  const revokeShare = (item: IShareHistoryItem) => {
    startWorkflow({
      id: WorkflowId.RevokeShare,
      payload: {
        targetTenant: item.tenantId,
        assets: [item.objectId],
        originator: user?.id ?? "",
      },
    });
  };

  const onConclude = (result: boolean) => {
    if (result) {
      revokeShare(item);
    } else {
      setIsConfirmOpen(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setIsConfirmOpen(false);
    }
  }, [isSuccess]);

  return (
    <>
      <span
        style={{
          visibility: shouldShow ? "visible" : "hidden",
        }}
      >
        <Button
          name="Revoke"
          role="button"
          key="revoke"
          variant="text"
          onClick={() => setIsConfirmOpen(true)}
          color="error"
        >
          {t("button.revoke", { ns: "common" })}
        </Button>
      </span>

      <ConfirmModal
        open={isConfirmOpen}
        title={t("documents.shareHistory.confirm.title")}
        onConclude={onConclude}
        isConfirmButtonDisabled={isLoading}
        confirmButtonText={t("button.revoke", { ns: "common" })}
        cancelButtonText={t("button.cancel", { ns: "common" })}
      >
        {t("documents.shareHistory.confirm.body")}
      </ConfirmModal>
    </>
  );
};
