import { TCountry } from "utils/constants/countries.constants";
import {
  ApplicationType,
  InvitationReviewType,
  InvitationStatus,
  ProposedApprovalPathway,
  ProposedTimeline,
  ReviewerParticipationType,
  SubmissionPlan,
} from "utils/constants/gsp.constants";
import { IHAQMetadata } from "./HAQ.models";

export interface IGSPHealthAuthority {
  id: string;
  name: string;
  tenantId: string;
  country: keyof TCountry;
  shortName: string;
}

export interface IGSPMetadataSponsorInfo {
  companyName?: string;
  contactName?: string;
  contactEmail?: string;
  phoneNumber?: string;
}

export interface IGSPApplicationInfo {
  type?: ApplicationType | "";
  number?: string;
  startDate?: string;
  proposedFillingDate?: string;
  proposedTimeline?: ProposedTimeline | "";
  isRtorOrRollingReview?: string | boolean;
  proposedApprovalPathway?: ProposedApprovalPathway | "";
  proposedTherapeuticIndication?: string;
  clinicalTrialsSupportingProposedIndication?: string;
  additionalComments?: string;
}

export interface IGSPComponentBase {
  id: string;
  healthAuthority: IGSPHealthAuthority;
  parentId: string;
  type: string;
  status?: string;
  name: string;
  metadata:
    | IGSPNotSubmittingComponentMetadata
    | IGSPSubmittingComponentMetadata;
}

interface IInvitationResponseMetadata {
  reviewType: InvitationReviewType;
  participationType: ReviewerParticipationType;
  plannedActionDate: string;
  fdaMeetingParticipation: boolean;
}

interface IInvitationMetadata {
  status: InvitationStatus;
  response: IInvitationResponseMetadata;
  responseDueDate: string;
}

export interface IGSPNotSubmittingComponentMetadata {
  submissionPlan?: SubmissionPlan | "";
  sponsorInfo?: IGSPMetadataSponsorInfo;
  reason?: string;
  tenantId?: string;
  invitation?: IInvitationMetadata;
}

export interface IGSPNotSubmittingComponent extends IGSPComponentBase {
  metadata: IGSPNotSubmittingComponentMetadata;
}

export interface IGSPSubmittingComponentMetadata {
  submissionPlan?: SubmissionPlan | "";
  sponsorInfo?: IGSPMetadataSponsorInfo;
  applicationInfo?: IGSPApplicationInfo;
  tenantId?: string;
  invitation?: IInvitationMetadata;
}

export interface IGSPSubmittingComponent extends IGSPComponentBase {
  metadata: IGSPSubmittingComponentMetadata;
}

export type TGSPComponent =
  | IGSPSubmittingComponent
  | IGSPNotSubmittingComponent;

export interface IGSPComponentRequestData
  extends Omit<TGSPComponent, "healthAuthority"> {}

type TDossierDifferencesMetadata = {
  summaryOfDifferences: string;
  sections: string[];
  applicableHealthAuthorityTenantIds: string[];
};

export interface IGSPDossierDifferencesRequestData
  extends Pick<IGSPComponentBase, "parentId" | "type" | "name"> {
  metadata: TDossierDifferencesMetadata | IHAQMetadata;
}

export const isSubmittingGSPComponent = (
  component: TGSPComponent,
): component is IGSPSubmittingComponent =>
  component.metadata.submissionPlan === SubmissionPlan.Submitting ||
  !component.metadata.submissionPlan;

export const isNotSubmittingGSPComponent = (
  component: TGSPComponent,
): component is IGSPNotSubmittingComponent =>
  component.metadata.submissionPlan === SubmissionPlan.NotSubmitting;

export interface IGSP {
  id: string;
  name: string;
  status?: string;
  components: TGSPComponent[];
}
