import { createSvgIcon } from "@mui/material";

export const CertificateIcon = createSvgIcon(
  <svg fill="none">
    <path
      d="M6.5 20.5H5C3.89543 20.5 3 19.6046 3 18.5V4.5C3 3.39543 3.89543 2.5 5 2.5H19C20.1046 2.5 21 3.39543 21 4.5V18.5C21 19.6046 20.1046 20.5 19 20.5H17.5M12 19.5C13.6569 19.5 15 18.1569 15 16.5C15 14.8431 13.6569 13.5 12 13.5C10.3431 13.5 9 14.8431 9 16.5C9 18.1569 10.3431 19.5 12 19.5ZM12 19.5L12.0214 19.4998L8.82867 22.6926L6.00024 19.8641L9.01965 16.8447M12 19.5L15.1928 22.6926L18.0212 19.8641L15.0018 16.8447M9 6.5H15M7 10H17"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "CertificateIcon",
);
