import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import dayjs from "dayjs";
import Loading from "components/shared/Loading";
import { isHATenant } from "utils/user/organization";
import PrivacyPolicy from "assets/pdf/accumulus_privacy_policy.pdf";
import TermsOfUse from "assets/pdf/accumulus_terms_of_use.pdf";
import styles from "./AuthLayout.styles";

const AuthLayout = () => {
  const { t } = useTranslation("user", {
    keyPrefix: "authLayout",
  });

  return (
    <>
      <Container sx={styles.container}>
        <Box sx={styles.cardContainer}>
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </Box>
        <Box sx={styles.copy} role="contentinfo">
          <Typography component="span" variant="body2">
            {t("createdBy", { currentYear: dayjs().year() })}
          </Typography>
          <Box component="span">
            <Link to={PrivacyPolicy} rel="noreferrer" target="_blank">
              <Typography variant="body2">{t("privacyPolicyLink")}</Typography>
            </Link>
            {isHATenant() && (
              <Link to={TermsOfUse} rel="noreferrer" target="_blank">
                <Typography variant="body2">{t("termsOfUseLink")}</Typography>
              </Link>
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default AuthLayout;
