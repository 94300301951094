import { useTranslation } from "react-i18next";
import { FormSelect } from "components/common/form-fields";
import { ImportFormBaseFieldName } from "screens/Project/sections/Documents/modals/ImportDocument";
import { FHIRResourceType } from "utils/constants/fhir.constants";

const selectionOptions = Object.values(FHIRResourceType).map((value) => ({
  label: value,
  value: value,
}));

const FHIRResourceTypeField = () => {
  const { t } = useTranslation("documents", {
    keyPrefix: "importDocument.fields",
  });

  return (
    <FormSelect
      name={ImportFormBaseFieldName.FHIRResourceType}
      label={t("FHIRResourceType.label")}
      data-qaid="fhir-resource-type-select"
      options={selectionOptions}
    />
  );
};

export default FHIRResourceTypeField;
