import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  imgBox: {
    width: "80%",
    height: "110px",
    boxShadow: (theme) =>
      `0px 0px 5px 2px ${getPaletteColor("grey.300")(theme)}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 2px",
  },

  defaultImg: {
    width: "100%",
    height: "110px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    zIndex: 0,
  },
};

export default styles;
