import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  FormWrapper,
  Modal,
  ModalActionButtons,
} from "components/shared";
import useCreateSubstanceEntity from "hooks/api/GQL/entityManagement/useCreateSubstanceEntity";
import useUpdateSubstanceEntity from "hooks/api/GQL/entityManagement/useUpdateSubstanceEntity";
import { EntityStatus } from "models/entities.models";
import { getEntityOrganizationRoles } from "screens/EntityManagement/utils/getEntityOrganizationRoles";
import SubstanceEntityFormFields from "../SubstanceEntityFormFields";
import {
  SubstanceEntityFieldName,
  TSubstanceEntityForm,
} from "../SubstanceEntityFormFields/SubstanceEntityFormFields.types";
import { TSubstance } from "../Substances.types";
import {
  getManageSubstanceModalSchema,
  getSubstanceEntityFormValues,
} from "./ManageSubstanceModal.utils";

export type TManageSubstanceModalProps = {
  open: boolean;
  substanceToEdit: TSubstance | undefined;
  onClose: () => void;
};

const ManageSubstanceModal = ({
  open,
  substanceToEdit,
  onClose,
}: TManageSubstanceModalProps) => {
  const { t } = useTranslation(["administration", "common"]);

  const { createSubstance, isLoading: isSubstanceCreating } =
    useCreateSubstanceEntity();

  const { updateSubstance, isLoading: isSubstanceUpdating } =
    useUpdateSubstanceEntity();

  const isLoading = isSubstanceCreating || isSubstanceUpdating;

  const isEditMode = Boolean(substanceToEdit);

  const modalTitle = t(
    isEditMode ? "editSubstanceModalTitle" : "createNewSubstanceModalTitle",
    {
      ns: "administration",
    },
  );

  const defaultValues = useMemo(
    () => getSubstanceEntityFormValues(substanceToEdit),
    [substanceToEdit],
  );

  const methods = useForm<TSubstanceEntityForm>({
    mode: "all",
    defaultValues,
    resolver: zodResolver(getManageSubstanceModalSchema(t)),
  });

  const {
    formState: { isDirty, isValid },
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const onSubmit = (values: TSubstanceEntityForm) => {
    const substanceToSave = {
      name: values[SubstanceEntityFieldName.SubstanceName].trim(),
      drugClassType: { code: values[SubstanceEntityFieldName.DrugClass].value },
      inn: values[SubstanceEntityFieldName.INN],
      atcCode: values[SubstanceEntityFieldName.ATCCode],
      entityOrganizationRoles:
        getEntityOrganizationRoles(
          values[SubstanceEntityFieldName.LinkedOrganizations],
        ) ?? [],
      active: values[SubstanceEntityFieldName.Status] === EntityStatus.Active,
    };

    const params = {
      substanceEntity: isEditMode
        ? { ...substanceToSave, id: substanceToEdit?.id }
        : substanceToSave,
    };

    const handlePostActions = {
      onSuccess: () => {
        reset();
        onClose();
      },
    };

    isEditMode
      ? updateSubstance(params, handlePostActions)
      : createSubstance(params, handlePostActions);
  };

  const onCancelManageSubstance = () => {
    reset();
    onClose();
  };

  const cancelButton = (
    <Button
      data-qaid="manage-substance-entity-cancel-button"
      key="cancel"
      variant="text"
      onClick={onCancelManageSubstance}
    >
      {t("button.cancel", { ns: "common" })}
    </Button>
  );

  const submitButton = (
    <Button
      key="submit-button"
      data-qaid="manage-substance-entity-submit-button"
      variant="contained"
      loading={isLoading}
      disabled={!isValid || !isDirty}
      type="submit"
    >
      {t("button.submit", { ns: "common" })}
    </Button>
  );

  return (
    <Modal maxWidth="md" open={open} title={modalTitle}>
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <SubstanceEntityFormFields />

        <ModalActionButtons buttons={[cancelButton, submitButton]} />
      </FormWrapper>
    </Modal>
  );
};

export default ManageSubstanceModal;
