import React, { Ref } from "react";
import ReactPhoneInput, {
  PhoneInputProps,
} from "react-phone-input-material-ui";
import { Box, FormHelperText, SxProps, Theme } from "@mui/material";
import TextField from "../TextField";
import "react-phone-input-material-ui/lib/style.css";
import styles from "./PhoneInput.styles";

export type TPhoneInputProps = Omit<PhoneInputProps, "component"> & {
  label: string;
  helperText?: string;
  country?: string;
  error?: boolean;
  sx?: SxProps<Theme>;
};

const PhoneInput = React.forwardRef(
  (phoneInputProps: TPhoneInputProps, ref?: Ref<any>) => {
    const sx = {
      ...styles.phoneInput,
      ...(!phoneInputProps.country ? styles.emptyPhoneInput : {}),
      ...phoneInputProps.sx,
    };

    return (
      <Box sx={sx}>
        <ReactPhoneInput
          {...phoneInputProps}
          inputProps={ref ? { ref } : undefined}
          isValid={!phoneInputProps.error}
          placeholder={phoneInputProps.placeholder ?? ""}
          component={TextField}
        />
        {phoneInputProps.error && (
          <FormHelperText variant="standard" aria-live="polite" error>
            {phoneInputProps.helperText}
          </FormHelperText>
        )}
      </Box>
    );
  },
);

export default PhoneInput;
