import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import { isAxiosError } from "api/types";
import useShowAlert from "hooks/useShowAlert";
import { INotification } from "models/notificationItem.models";
import { IFilterParams } from "models/pagination.models";
import { services } from "services";
import {
  QueryAPIKey,
  QueryFetchingStatus,
} from "utils/constants/api.constants";

export type TUseGetNotificationsForUserReturn = {
  status: QueryFetchingStatus;
  error: unknown;
  isLoading: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  isError: boolean;
  isSuccess: boolean;
  notifications: INotification[] | undefined;
};

export default function useGetNotificationsForUser(
  userId: string | undefined,
  params: IFilterParams,
): TUseGetNotificationsForUserReturn {
  const { t } = useTranslation("user", {
    keyPrefix: "notifications",
  });

  const {
    status,
    error,
    fetchNextPage,
    isLoading,
    isError,
    isSuccess,
    hasNextPage,
    data,
  } = useInfiniteQuery(
    [QueryAPIKey.GetNotificationsForUser, userId],

    async ({ pageParam = params.page || 0 }) => {
      const response = await services.notifications.getNotificationsForUser(
        userId || "",
        { ...params, page: pageParam },
      );

      // TODO: remove/update with global error handling work - FTE-4189
      return isAxiosError(response) ? Promise.reject(response) : response.data;
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.last ? undefined : lastPage.number + 1,
      enabled: !!userId,
    },
  );

  useShowAlert({
    status,
    failureMessage: t("getNotificationsForUserFailure"),
  });

  const notifications = data?.pages.flatMap((page) => page.content);

  return {
    status: status as QueryFetchingStatus,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isError,
    isSuccess,
    notifications,
  };
}
