import { ReactNode } from "react";

export enum ProductFamilyTable {
  Id = "id",
  Name = "name",
  Description = "description",
  Status = "status",
  Actions = "actions",
}

export type TProductFamilyRowData = {
  [ProductFamilyTable.Id]: string;
  [ProductFamilyTable.Name]: string;
  [ProductFamilyTable.Description]: string;
  [ProductFamilyTable.Status]: string;
  [ProductFamilyTable.Actions]: ReactNode;
};
