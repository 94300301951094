import { getBorderStyle } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    "& .MuiPaper-root": {
      minWidth: 750,
      maxWidth: "initial",
    },
  },

  section: {
    "& .MuiTableCell-head": {
      py: 1,
      lineHeight: 1.43,
    },

    "& .MuiTableCell-root:first-of-type": {
      borderRight: getBorderStyle("1px", "solid", "divider"),
    },
  },
};

export default styles;
