import { useTranslation } from "react-i18next";
import { InsertDriveFile as AppIcon } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { IGSPSubmittingComponentMetadata } from "models/gsp.models";
import SectionTitle from "screens/Project/screens/GSP/GSPItems/components/SectionTitle";
import { formatDate } from "utils/helpers";
import ItemRow from "./ItemRow";

type TApplicationInfoProps = {
  applicationInfo: IGSPSubmittingComponentMetadata["applicationInfo"];
};

const ApplicationInfo = ({ applicationInfo }: TApplicationInfoProps) => {
  const { t } = useTranslation("documents", {
    keyPrefix: "gsp.item",
  });

  const rollingReviewDescription = applicationInfo?.startDate
    ? t("startDate", { startDate: formatDate(applicationInfo?.startDate) })
    : undefined;

  const fillingDate = formatDate(applicationInfo?.proposedFillingDate);

  return (
    <Box py={2} pl={1}>
      <SectionTitle Icon={AppIcon} title={t("sections.applicationInfo")} />
      <Grid container spacing={1.6} p={2.5}>
        <ItemRow
          name={t("fields.isRtorOrRollingReview.viewModeLabel")}
          description={rollingReviewDescription}
        />
        <ItemRow
          name={t("fields.proposedFillingDate.label")}
          description={fillingDate}
        />
        <ItemRow
          name={t("fields.timeline.label")}
          description={applicationInfo?.proposedTimeline}
        />
        <ItemRow
          name={t("fields.approvalPathway.label")}
          description={applicationInfo?.proposedApprovalPathway}
        />
      </Grid>
      <Grid container spacing={1.6} p={2.5}>
        <ItemRow
          name={t("fields.therapeuticIndication.label")}
          description={applicationInfo?.proposedTherapeuticIndication}
        />
        <ItemRow
          name={t("fields.clinicalTrialsIndication.label")}
          description={
            applicationInfo?.clinicalTrialsSupportingProposedIndication
          }
        />
        <ItemRow
          name={t("fields.additionalComments.viewModeLabel")}
          description={applicationInfo?.additionalComments}
        />
      </Grid>
    </Box>
  );
};

export default ApplicationInfo;
