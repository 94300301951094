import { useState } from "react";
import { Stack } from "@mui/material";
import { Loading } from "components/shared";
import useProductFamilies from "hooks/api/GQL/entityManagement/useProductFamilies";
import CreateProductFamilyModal from "./CreateProductFamilyModal";
import ProductFamilyTable from "./ProductFamilyTable";

export const ProductFamily = () => {
  const [isCreateProductFamilyModalOpen, setIsCreateProductFamilyModalOpen] =
    useState(false);

  const { data, isLoading } = useProductFamilies();

  return (
    <Stack direction="column" spacing={2}>
      {isLoading ? (
        <Loading />
      ) : (
        <ProductFamilyTable
          productFamilies={data}
          onClickCreateProductFamily={() =>
            setIsCreateProductFamilyModalOpen(true)
          }
        />
      )}
      <CreateProductFamilyModal
        isOpen={isCreateProductFamilyModalOpen}
        onClose={() => setIsCreateProductFamilyModalOpen(false)}
      />
    </Stack>
  );
};

export default ProductFamily;
