import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "@mui/material";
import { HAQFormLayout } from "components/common/HAQ/HAQFormLayout";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { useProject } from "context/ProjectContext";
import useStartWorkflow from "hooks/api/REST/workflow/useStartWorkflow";
import { useHAQ } from "hooks/haq";
import useUser from "hooks/useUser";
import { useHAQContext } from "screens/Project/screens/HAQ/HAQContext/HAQContextProvider";
import { QueryAPIKey } from "utils/constants/api.constants";
import { ISO_DATE_FORMAT } from "utils/constants/format.constants";
import { WorkflowId } from "utils/constants/workflows.constants";
import { formatDate, getIsEmptyString } from "utils/helpers";
import {
  getSendHAQCopySchema,
  sendHAQCopyDefaultValues,
  SendHAQCopyFieldName,
  TSendHAQCopyForm,
} from "./SendHAQCopy.types";

type TSendHAQCopyModal = {
  HAQId: string | undefined;
  HAQName: string;
  onClose: () => void;
  isOpen: boolean;
};

export const SendHAQCopyModal = ({
  HAQId,
  onClose,
  isOpen,
  HAQName,
}: TSendHAQCopyModal) => {
  const { user } = useUser();
  const { t } = useTranslation(["HAQ", "common", "notifications"]);
  const { HAQ, relatedAssetName } = useHAQ(HAQId);
  const { project, creatorTenant } = useProject();

  const relatedEntityName = relatedAssetName || project?.name;
  const { setIsAnyWorkflowInProgress } = useHAQContext();

  const methods = useForm<TSendHAQCopyForm>({
    mode: "all",
    defaultValues: sendHAQCopyDefaultValues,
    resolver: zodResolver(getSendHAQCopySchema(t)),
  });

  const { formState, handleSubmit } = methods;

  const { startWorkflow } = useStartWorkflow({
    successMessage: t("workflowMessages.sendHAQCopySuccessMessage", {
      ns: "notifications",
      HAQName,
    }),
    failureMessage: t("workflowMessages.sendHAQCopyErrorMessage", {
      ns: "notifications",
      HAQName,
    }),
  });

  const queryClient = useQueryClient();

  const handleSendHAQCopy = (data: TSendHAQCopyForm) => {
    if (HAQ && creatorTenant?.tenantId && user?.id && project?.id) {
      startWorkflow(
        {
          id: WorkflowId.SendHAQCopy,
          payload: {
            project: project.id,
            originator: user.id,
            targetTenant: creatorTenant.tenantId,
            assets: [HAQ.id],
            dueDate: formatDate(
              data[SendHAQCopyFieldName.DueDate],
              ISO_DATE_FORMAT,
            ),
            message: data[SendHAQCopyFieldName.Instructions],
          },
        },
        {
          onSuccess: (response) => {
            setIsAnyWorkflowInProgress(!!response?.data?.id);
            // Note: Checking the workflow execution status before invalidating queries is not feasible, as the execution status
            // will remain in 'RUNNING' status until a sponsor user takes action on their side.
            // Setting a timeout is a temporary fix until re-architecture is implemented around HAQs and workflow status checks
            setTimeout(() => {
              queryClient.invalidateQueries(QueryAPIKey.GetAsset);
              queryClient.invalidateQueries(QueryAPIKey.GetAssetContent);
              queryClient.invalidateQueries(QueryAPIKey.GetAssetsWithContent);
            }, 2000);
            onClose();
          },
        },
      );
    }
  };

  const cancelButton = (
    <Button
      key="cancel"
      data-qaid="send-haq-cancel-button"
      variant="text"
      onClick={onClose}
    >
      {t("button.cancel", { ns: "common" })}
    </Button>
  );

  const submitButton = (
    <Button
      key="submit"
      data-qaid="send-haq-send-button"
      type="submit"
      variant="contained"
      disabled={!formState.isValid}
    >
      {t("button.send", { ns: "common" })}
    </Button>
  );

  return (
    <Modal
      data-qaid="send-haq-copy-modal"
      open={isOpen}
      title={t("sendHAQCopy.title", { ns: "HAQ" })}
    >
      <FormWrapper methods={methods} onSubmit={handleSubmit(handleSendHAQCopy)}>
        <HAQFormLayout<TSendHAQCopyForm>
          dateFieldName={SendHAQCopyFieldName.DueDate}
          infoFieldName={SendHAQCopyFieldName.Instructions}
        >
          {!getIsEmptyString(relatedEntityName) && (
            <Typography variant="body2" color="text.secondary">
              {t("viewPage.component.relatedTo", {
                parentComponent: relatedEntityName,
              })}
            </Typography>
          )}
          <Typography>
            <Trans t={t} i18nKey={"sendHAQCopy.recipient"}>
              <b>{creatorTenant?.name}</b>
            </Trans>
          </Typography>
        </HAQFormLayout>
        <ModalActionButtons buttons={[cancelButton, submitButton]} />
      </FormWrapper>
    </Modal>
  );
};
