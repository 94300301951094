import { useEffect } from "react";
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CountryData } from "react-phone-input-material-ui";
import PhoneInput from "components/shared/PhoneInput";
import { TPhoneInputProps } from "components/shared/PhoneInput/PhoneInput";

//based on ITU-T Recommendations
const MAX_PHONE_NUMBER_LENGTH = 15;

export type TFormPhoneInputProps<FormType extends FieldValues> = Omit<
  TPhoneInputProps,
  "error" | "helperText" | "onBlur" | "value"
> & {
  name: FieldPath<FormType>;
};

const FormPhoneInput = <FormType extends FieldValues>({
  name,
  ...props
}: TFormPhoneInputProps<FormType>) => {
  const { control, setError, unregister } = useFormContext<FormType>();

  const { t } = useTranslation("common");

  const handleBlur = (data: CountryData | {}, value: string) => {
    // Format will be in the form of "+. (...) ...-....", where the dots represent the digits.
    // Counting the dots in the format indicates how many digits there should be in the value.
    const validPhoneLength = (data as CountryData)?.format?.match(
      /\./g,
    )?.length;

    if (!validPhoneLength || validPhoneLength > MAX_PHONE_NUMBER_LENGTH) {
      return;
    }

    if (value?.length && validPhoneLength !== value?.length) {
      setError(name, {
        type: "custom",
        message: t("validations.invalidPhoneShort"),
      });
    }
  };

  useEffect(() => () => unregister(name), [unregister, name]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <PhoneInput
          {...field}
          {...props}
          error={!!error}
          helperText={error?.message}
          onBlur={(_event, data) => {
            handleBlur(data, field.value);
          }}
        />
      )}
    />
  );
};

export default FormPhoneInput;
