import { useState } from "react";
import { useTranslation } from "react-i18next";
import useDeleteAsset from "hooks/api/REST/assets/useDeleteAsset";

type TUseDeleteDossierDifferenceModal = {
  dossierDifferenceId: string;
  onClose: () => void;
};

const useDeleteDossierDifferenceModal = ({
  dossierDifferenceId,
  onClose,
}: TUseDeleteDossierDifferenceModal) => {
  const [
    isDeleteDossierDifferenceModalOpen,
    setIsDeleteDossierDifferenceModalOpen,
  ] = useState<boolean>(false);

  const { t } = useTranslation("projects", {
    keyPrefix: "notifications",
  });

  const openDeleteModal = () => setIsDeleteDossierDifferenceModalOpen(true);
  const closeDeleteModal = () => setIsDeleteDossierDifferenceModalOpen(false);

  const { mutateAsync: deleteDossierDifferences, isLoading: isLoadingDelete } =
    useDeleteAsset({
      messages: {
        failureMessage: t("deleteDossierDifferencesFailure"),
        successMessage: t("deleteDossierDifferencesSuccess"),
      },
    });

  const onConcludeHandler = async (confirm: boolean) => {
    if (!confirm) {
      closeDeleteModal();
    } else {
      const deletionResult = await deleteDossierDifferences(
        dossierDifferenceId,
      );

      closeDeleteModal();

      onClose();

      return deletionResult;
    }
  };

  return {
    isOpenDeleteModal: isDeleteDossierDifferenceModalOpen,
    openDeleteModal,
    onConcludeHandler,
    isLoadingDelete,
  };
};

export default useDeleteDossierDifferenceModal;
