import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { IUpdateSuggestionRequestData } from "models/annotations.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export type TSuggestionForUpdate = {
  componentId: string;
  suggestionId: string;
  data: IUpdateSuggestionRequestData;
};

const useUpdateSuggestion = () => {
  const queryClient = useQueryClient();

  const { t } = useTranslation("annotations", { keyPrefix: "notifications" });

  const updateSuggestionMutation = useMutation(
    ["updateSuggestion"],
    (mutationData: TSuggestionForUpdate) => {
      const { componentId, suggestionId, data } = mutationData;

      return services.documents.suggestions.updateSuggestion(
        componentId,
        suggestionId,
        data,
      );
    },
    {
      onSuccess: (data, { componentId }) => {
        queryClient.invalidateQueries([QueryAPIKey.GetSuggestion, componentId]);
      },
    },
  );

  useShowAlert({
    status: updateSuggestionMutation.status,
    failureMessage: t("updateSuggestionFailure"),
  });

  return updateSuggestionMutation;
};

export default useUpdateSuggestion;
