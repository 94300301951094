import { Box } from "@mui/material";
import styles from "./CharacterLimit.styles";

export type TCharacterLimit = {
  maxLength: number;
  value: string;
};

const CharacterLimit = ({ maxLength, value }: TCharacterLimit) => {
  return (
    <Box component="span" sx={styles.characterLimit}>{`${
      value ? value.trim().length : 0
    } / ${maxLength}`}</Box>
  );
};

export default CharacterLimit;
