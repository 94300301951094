import { KeyboardEvent, MouseEvent, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import useCheckboxGroup from "hooks/useCheckboxGroup";
import { Keyboard } from "utils/constants/keyboard.constants";
import { ROWS_PER_PAGE_OPTIONS } from "utils/constants/table.constants";
import { formatStringToCamelCase } from "utils/helpers";
import SelectionCell from "./components/SelectionCell";
import DefaultRow from "./components/TableRow/Row";
import { ASCENDING, DEFAULT_ORDER, DESCENDING } from "./constants";
import { getAdjacentElementWithTabIndex } from "./Table.utils";
import { TColumnKey, TRowBase, TTableProps } from "./types";
import styles from "./Table.styles";
import accessibilityStyles from "styles/accessibility.styles";

function Table<T extends TRowBase>(props: TTableProps<T>) {
  const {
    id,
    onSortClick,
    order,
    orderBy,
    columns,
    rows,
    pagination,
    isHeadless = false,
    RowRenderer = DefaultRow,
    sx: _sx,
    selectAllLabel,
    onSelectionChange,
    initiallySelectedItems,
    bodyRowAlign = "top",
    ...tableProps
  } = props;

  const { t } = useTranslation("common");

  const handlePageChange = (
    _event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    pagination?.onPageChange(page);
  };

  const handleSortClick = (columnKey: TColumnKey<T>) => {
    if (orderBy === columnKey) {
      onSortClick?.(columnKey, order === ASCENDING ? DESCENDING : ASCENDING);
    } else {
      onSortClick?.(columnKey, DEFAULT_ORDER);
    }
  };

  const availableRowIds = useMemo(
    () => rows.filter((row) => !row.isDisabled).map((row) => row.id),
    [rows],
  );

  const {
    onSelectAllClick,
    onSelectClick,
    itemsSelected: rowsSelected,
    checkIfItemSelected,
  } = useCheckboxGroup(
    availableRowIds,
    onSelectionChange,
    initiallySelectedItems,
  );

  const sx = { ...styles.root, ...(isHeadless && styles.headless), ..._sx };

  const combinedBodyRowStyles = {
    ...styles.bodyRow,
    ...styles[`bodyRowAlign${formatStringToCamelCase(bodyRowAlign, "upper")}`],
  };

  const areAllRowsSelected =
    rowsSelected.size > 0 && rowsSelected.size === availableRowIds.length;

  const hasSelectedRows =
    rowsSelected.size > 0 && rowsSelected.size < availableRowIds.length;

  const isSelectable = !!onSelectionChange;

  const page = pagination?.page ?? 0;

  const tableHeaderRowRef = useRef<HTMLTableRowElement>(null);

  const handleKeyDownColumnCell = (
    event: KeyboardEvent<HTMLTableCellElement>,
    column: any,
  ) => {
    const currentHeaderCell = tableHeaderRowRef?.current?.children.namedItem(
      column.key,
    );

    switch (event.code) {
      case Keyboard.Enter:
        column.sortable && handleSortClick(column.key);
        break;
      case Keyboard.ArrowRight:
        const nextFocusableElement =
          currentHeaderCell?.nextElementSibling &&
          getAdjacentElementWithTabIndex(
            currentHeaderCell?.nextElementSibling,
            "next",
          );

        nextFocusableElement && nextFocusableElement.focus();
        break;
      case Keyboard.ArrowLeft:
        const prevFocusableElement =
          currentHeaderCell?.previousElementSibling &&
          getAdjacentElementWithTabIndex(
            currentHeaderCell?.previousElementSibling,
            "prev",
          );

        prevFocusableElement && prevFocusableElement.focus();
        break;
      default:
        break;
    }
  };

  return (
    <TableContainer sx={sx}>
      <MuiTable role="table" {...tableProps}>
        {columns.length && !isHeadless ? (
          <TableHead>
            <TableRow ref={tableHeaderRowRef}>
              {isSelectable && (
                <SelectionCell
                  sx={{ pt: "5px", pb: "5px" }}
                  indeterminate={hasSelectedRows}
                  checked={areAllRowsSelected}
                  disabled={!availableRowIds.length}
                  onChange={onSelectAllClick}
                  ariaLabel={`${selectAllLabel || t("selectAll")}`}
                />
              )}
              {columns.map((column, headerColIndex) => (
                <TableCell
                  id={`${String(column.key)}_${id}`}
                  // id is needed for keyboard navigation
                  key={`${String(column.key)}_${headerColIndex}`}
                  sortDirection={orderBy === column.key ? order : false}
                  width={column?.width}
                  onKeyDown={(event) => handleKeyDownColumnCell(event, column)}
                >
                  {column.sortable ? (
                    <TableSortLabel
                      active={orderBy === column.key}
                      direction={orderBy === column.key ? order : DEFAULT_ORDER}
                      onClick={() => handleSortClick(column.key)}
                    >
                      {column.content}
                    </TableSortLabel>
                  ) : (
                    <Box
                      component="span"
                      sx={column.SROnly ? accessibilityStyles.SROnly : {}}
                    >
                      {column.content}
                    </Box>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        ) : null}

        {rows.length ? (
          <TableBody
            data-testid="table-body"
            data-qaid="table-body"
            sx={combinedBodyRowStyles}
          >
            {rows.map((row, rowIndex) => (
              <RowRenderer
                key={rowIndex}
                row={row}
                columns={columns}
                isSelectable={isSelectable}
                isRowSelected={checkIfItemSelected(row.id)}
                onSelectClick={onSelectClick}
              />
            ))}
          </TableBody>
        ) : null}

        {pagination ? (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                SelectProps={{
                  inputProps: {
                    "aria-labelledby": "rowsPerPage",
                  },
                  id: "rowsPerPage",
                }}
                data-testid="select-rows-per-page"
                data-qaid="select-rows-per-page"
                {...pagination}
                onPageChange={handlePageChange}
                page={pagination?.rowsPerPage >= pagination?.count ? 0 : page}
              />
            </TableRow>
          </TableFooter>
        ) : null}
      </MuiTable>
    </TableContainer>
  );
}

export default Table;
