import { Stack } from "@mui/material";
import { Loading } from "components/shared";
import useSubstances from "hooks/api/GQL/entityManagement/useSubstances";
import SubstancesTable from "./SubstancesTable";

const Substances = () => {
  const { data, isLoading } = useSubstances();

  return (
    <Stack direction="column" spacing={2}>
      {isLoading ? <Loading /> : <SubstancesTable substances={data} />}
    </Stack>
  );
};

export default Substances;
