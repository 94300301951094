import { AxiosError, AxiosRequestConfig } from "axios";

type TClientConfigParams = Omit<AxiosRequestConfig["params"], "format"> & {
  format?: "FULL" | "DEFAULT" | "BRIEF";
};

export interface IClientConfig extends AxiosRequestConfig {
  isMockApiOn?: boolean;
  params?: TClientConfigParams;
}

export const isAxiosError = (response: unknown): response is AxiosError =>
  response instanceof AxiosError;
