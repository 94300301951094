import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FilterType, TSelectFilter } from "components/shared/types/filter";
import { formatCountriesAsOptions } from "utils/helpers/formatCountriesAsOptions/formatCountriesAsOptions";
import {
  TherapeuticTable,
  TTherapeuticRowData,
} from "../TherapeuticSummary.types";

const useCountryFilter = (
  therapeuticItems: TTherapeuticRowData[],
): Record<TherapeuticTable.Country, TSelectFilter> => {
  const { t } = useTranslation("portfolio");

  return useMemo(
    () => ({
      [TherapeuticTable.Country]: {
        type: FilterType.Select,
        label: t("therapeutics.columns.country"),
        options: formatCountriesAsOptions(therapeuticItems, "country"),
        selectAllLabel: t("multiSelect.selectAll", { ns: "common" }),
      },
    }),
    [therapeuticItems, t],
  );
};

export default useCountryFilter;
