import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelect } from "components/common/form-fields";
import { useProject } from "context/ProjectContext";
import useDomains from "hooks/api/REST/documents/useDomains";
import { ImportFormBaseFieldName } from "screens/Project/sections/Documents/modals/ImportDocument";
import { ImportModalType } from "screens/Project/sections/Documents/modals/ImportDocument/ImportDocument";
import { TImportFormBase } from "screens/Project/sections/Documents/modals/ImportDocument/ImportDocument.types";
import {
  getDomainFieldOptions,
  getFilteredDomainDictionary,
  importModalDefaultValues,
} from "screens/Project/sections/Documents/modals/ImportDocument/ImportDocument.utils";
import { AssetDomain } from "utils/constants/assets.constants";

type TDomainFieldProps = {
  openModalType: ImportModalType;
};

const Domain = ({ openModalType }: TDomainFieldProps) => {
  const { reset, watch } = useFormContext<TImportFormBase>();

  const { t } = useTranslation(["documents", "asset"]);

  const { domains, isLoading } = useDomains();

  const { project } = useProject();

  const language = watch(ImportFormBaseFieldName.Language);
  const file = watch(ImportFormBaseFieldName.File);
  const name = watch(ImportFormBaseFieldName.Name);

  const filteredDomainDictionary = getFilteredDomainDictionary(
    domains,
    project,
  );

  const domainOptions = useMemo(
    () => getDomainFieldOptions(filteredDomainDictionary, t),
    [filteredDomainDictionary, t],
  );

  const onChange = useCallback(
    (value: string | null) => {
      reset(
        {
          ...importModalDefaultValues,
          [ImportFormBaseFieldName.Domain]: value as AssetDomain,
          [ImportFormBaseFieldName.Language]: language,
          [ImportFormBaseFieldName.File]: file,
          [ImportFormBaseFieldName.Name]: name,
        },
        {
          keepErrors: true,
        },
      );
    },
    [file, language, name, reset],
  );

  const isFieldDisabled = openModalType !== ImportModalType.Default;

  return (
    <FormSelect
      name={ImportFormBaseFieldName.Domain}
      data-qaid="domain-select"
      label={t("importDocument.fields.domain.label")}
      disabled={isFieldDisabled}
      onChange={onChange}
      isLoading={isLoading}
      options={domainOptions}
    />
  );
};

export default Domain;
