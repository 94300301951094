import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    pt: 3,
    pb: 3,
  },

  cardContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    flexDirection: "column",
  },

  authContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    zIndex: "modal",
    height: "auto",
    border: "none",
  },

  copy: (theme) => ({
    pt: "20px",
    pb: "32px",
    color: getPaletteColor("common.white"),

    "span:not(:first-of-type)": {
      pl: "31px",
    },

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },

    span: {
      [theme.breakpoints.down("sm")]: {
        display: "block",

        "&:not(:first-of-type)": {
          pl: "0px",
        },
      },
    },

    a: {
      display: "inline-block",
      color: getPaletteColor("primary.light"),

      "&:not(:first-of-type)": {
        ml: "21px",
      },
    },
  }),
};

export default styles;
