import {
  IOrbisProjectMetadata,
  IProject,
  IRelianceProjectMetadata,
} from "models/projects.models";
import { ProjectType } from "utils/constants/project.constants";

export const checkIsRelianceProject = (
  project: IProject | null | undefined,
): project is IProject<IRelianceProjectMetadata> =>
  project?.type === ProjectType.Reliance;

export const checkIsRegulatoryReviewProject = (
  project: IProject | null | undefined,
): project is IProject<IRelianceProjectMetadata> =>
  project?.type === ProjectType.RegulatoryReview;

export const checkIsOrbisProject = (
  project: IProject | null | undefined,
): project is IProject<IOrbisProjectMetadata> =>
  project?.type === ProjectType.Orbis;
