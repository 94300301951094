import { ICheckboxOption } from "components/shared/CheckboxTree/CheckboxTree.utils";

const flatMap = (options: ICheckboxOption[]) => {
  if (options.length === 0) {
    return options;
  }

  const flatChildren = (option: ICheckboxOption): any =>
    option?.children
      ? [option, option.children.flatMap((child) => flatChildren(child))]
      : option;

  return options.flatMap((option) => flatChildren(option)).flat(Infinity);
};

export default flatMap;
