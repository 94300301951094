import { Stack, Typography } from "@mui/material";
import styles from "./RadioTile.styles";

export type TRadioLabelProps = {
  icon?: JSX.Element;
  title: string;
  description: string;
  id?: string;
};

const RadioLabel = (props: TRadioLabelProps) => {
  return (
    <Stack sx={styles.radioLabel}>
      {props.icon}
      <Typography variant="subtitle1">{props.title}</Typography>
      <Typography variant="helperText" sx={styles.description}>
        {props.description}
      </Typography>
    </Stack>
  );
};

export default RadioLabel;
