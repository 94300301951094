import { forwardRef, Ref, useMemo } from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import {
  TCheckboxGroupOption,
  TCheckboxGroupProps,
  TCheckboxValues,
} from "./types";
import { LockIcon } from "assets/icons";
import styles from "./CheckboxGroup.styles";

const CheckboxGroup = forwardRef(
  <T extends TCheckboxGroupOption>(
    props: TCheckboxGroupProps<T>,
    ref?: Ref<any>,
  ) => {
    const {
      options = [],
      onChange,
      labelControl,
      allLabel,
      lockIconAriaLabel,
      value = [],
    } = props;

    const toggleAll = (
      _event: React.ChangeEvent<HTMLInputElement>,
      checked: boolean,
    ) => {
      const newValues: TCheckboxValues = options
        .filter((item) => !item.disabled && checked)
        .map((opt) => opt.id);

      onChange?.(newValues);
    };

    const isAllChecked = useMemo(
      () => !options.some((item) => !item.disabled && !value.includes(item.id)),
      [options, value],
    );

    const isAtLeastOneChecked = useMemo(
      () => options.some((item) => !item.disabled && value.includes(item.id)),
      [options, value],
    );

    const isAllOptionsDisabled = useMemo(
      () => options.every((option) => option.disabled),
      [options],
    );

    const onItemChange = (id: string) => {
      const newValues =
        value.indexOf(id) !== -1
          ? value.filter((checked) => id !== checked)
          : [...value, id];

      onChange?.(newValues);
    };

    return (
      <>
        {options?.length ? (
          <Box>
            <FormControlLabel
              label={allLabel}
              control={
                <Checkbox
                  inputRef={ref}
                  checked={isAllChecked && !isAllOptionsDisabled}
                  indeterminate={isAtLeastOneChecked && !isAllChecked}
                  onChange={toggleAll}
                  value={value}
                  disabled={isAllOptionsDisabled}
                />
              }
            />
            <Box sx={styles.options}>
              {options.map((item) => {
                return (
                  <FormControlLabel
                    key={item.id}
                    label={labelControl ? labelControl(item) : item.label}
                    control={
                      item.disabled ? (
                        <LockIcon
                          style={{ margin: "11px" }}
                          aria-label={lockIconAriaLabel}
                          data-testid="lock-icon"
                          data-qaid="lock-icon"
                        />
                      ) : (
                        <Checkbox
                          checked={value.includes(item.id)}
                          data-testid={`checkbox-${item.id}`}
                          data-qaid={`checkbox-${item.id}`}
                          onChange={() => onItemChange(item.id)}
                        />
                      )
                    }
                  />
                );
              })}
            </Box>
          </Box>
        ) : null}
      </>
    );
  },
);

export default CheckboxGroup as <T extends TCheckboxGroupOption>(
  props: TCheckboxGroupProps<T>,
  ref?: Ref<any>,
) => ReturnType<typeof CheckboxGroup>;
