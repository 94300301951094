import { useTranslation } from "react-i18next";
import { QueryObserverOptions, useQuery } from "react-query";
import { AxiosError } from "axios";
import { IClientConfig } from "api/types";
import useShowAlert from "hooks/useShowAlert";
import { IAsset, IAssetMetadata } from "models/assets.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export default function useAsset<T = IAssetMetadata>(
  id: string,
  config?: IClientConfig,
  options?: QueryObserverOptions,
) {
  const { t } = useTranslation("asset", {
    keyPrefix: "notifications",
  });

  const { data, status, isLoading, isError, isSuccess, error } = useQuery<
    IAsset<T>,
    AxiosError
  >(
    [QueryAPIKey.GetAsset, id],
    () => services.assets.getAsset(id, config).then((res) => res.data),
    {
      enabled: !!id && (options?.enabled ?? true),
    },
  );

  useShowAlert({
    status,
    failureMessage: t("getAssetFailure"),
  });

  return {
    asset: data,
    status,
    error,
    isLoading,
    isError,
    isSuccess,
  };
}
