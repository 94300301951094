import { AxiosResponse } from "axios";
import { del, get, post, put, routes } from "api";
import { IClientConfig } from "api/types";
import { IHAQWithContent } from "hooks/api/GQL/haq/useHAQsWithContent.models";
import {
  IAsset,
  IAssetContent,
  IAssetRelationshipRequestData,
  IBulkAssetsActionRequestData,
  ICreateAssetContentRequestData,
  ICreateAssetVersion,
  ICreateUploadTokenRequestData,
  ICreateUploadTokenResponseData,
  IDeleteAssetsRequestData,
  IGetAssetRelationshipParams,
  ILockBulkAssetResponseData,
} from "models/assets.models";
import { IDetailedDescriptionOfChangePayload } from "models/detailedDescriptionOfChange.models";
import { IDocument, IDocumentMetadata } from "models/documents.models";
import {
  IGSPComponentRequestData,
  IGSPDossierDifferencesRequestData,
} from "models/gsp.models";
import { IHAQMetadata, IHAQPayload } from "models/HAQ.models";

const assets = {
  getAsset(id: string, config?: IClientConfig): Promise<AxiosResponse> {
    return get(routes.asset.assets.byId(id), config);
  },

  updateAssets(data: IGSPComponentRequestData[] | IDocument[]) {
    return put(routes.asset.assets.all(), { data });
  },

  updateAsset(
    data:
      | IGSPDossierDifferencesRequestData
      | IAsset<IHAQMetadata>
      | IHAQWithContent
      | IDocument<IDocumentMetadata>,
    assetId: string,
  ) {
    return put(routes.asset.assets.byId(assetId), { data });
  },

  getAssetMetadata(
    type: string,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return get(routes.asset.assets.type(type), { isMockApiOn });
  },

  getDataDictionary(key: string): Promise<AxiosResponse> {
    return get(routes.asset.assets.dataDictionary(key));
  },

  createAssets(
    data:
      | IGSPDossierDifferencesRequestData
      | IHAQPayload
      | IDetailedDescriptionOfChangePayload,
    isMockApiOn?: boolean,
  ) {
    return post(routes.asset.assets.all(), { data, isMockApiOn });
  },

  deleteAsset(assetId: string): Promise<AxiosResponse> {
    return del(routes.asset.assets.byId(assetId));
  },

  deleteAssets(data: IDeleteAssetsRequestData): Promise<AxiosResponse> {
    return post(routes.asset.assets.deleteAssets(), { data });
  },

  getAllAssetVersion(
    id: string,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return get(routes.asset.assets.allVersions(id), { isMockApiOn });
  },

  createAssetVersion(
    id: string,
    contentId: string,
    data: ICreateAssetVersion,
    isMockApiOn?: boolean,
  ) {
    return post(routes.asset.assets.createVersion(id, contentId), {
      data,
      isMockApiOn,
    });
  },

  deleteAssetVersion(assetId: string, version: string) {
    return del(routes.asset.assets.deleteVersion(assetId, version));
  },

  getAssetShareHistory(
    id: string,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return get(routes.asset.assets.getAssetShareHistory(id), { isMockApiOn });
  },

  getAssetBulkShareHistory(ids: string[]): Promise<AxiosResponse> {
    return get(routes.asset.assets.getAssetBulkShareHistory(ids));
  },

  getParseJob(id: string): Promise<AxiosResponse> {
    return get(routes.asset.assets.parseRequestById(id));
  },

  getAssetContent(
    assetId: string,
    config?: IClientConfig,
  ): Promise<AxiosResponse> {
    return get(routes.asset.assets.content(assetId), config);
  },

  getContentById(
    assetId: string,
    contentId: string,
  ): Promise<AxiosResponse<IAssetContent>> {
    return get(routes.asset.assets.getContentById(assetId, contentId));
  },

  createAssetContent: (
    assetId: string,
    data: ICreateAssetContentRequestData,
  ): Promise<AxiosResponse> => {
    return post<IAssetContent>(routes.asset.assets.content(assetId), {
      data,
    });
  },

  getCommentThreads: (assetId: string): Promise<AxiosResponse> => {
    return get(routes.asset.assets.commentThreads(assetId));
  },

  createAssetRelationship: (
    assetId: string,
    data: IAssetRelationshipRequestData,
  ) => {
    return post(routes.asset.assets.relationships(assetId), {
      data,
    });
  },

  getAssetRelationships: (
    assetId: string,
    params: IGetAssetRelationshipParams,
  ): Promise<AxiosResponse> => {
    return get(routes.asset.assets.relationships(assetId), {
      params,
    });
  },

  deleteAssetRelationship: (
    assetId: string,
    relationshipId: string,
  ): Promise<AxiosResponse> => {
    return del(routes.asset.assets.deleteRelationship(assetId, relationshipId));
  },

  bulkAssetsAction: (data: IBulkAssetsActionRequestData) => {
    return post<ILockBulkAssetResponseData[]>(
      routes.asset.assets.bulkAssetsAction(),
      {
        data,
      },
    );
  },

  createUploadToken: (
    data: ICreateUploadTokenRequestData,
  ): Promise<AxiosResponse<ICreateUploadTokenResponseData>> => {
    return post(routes.asset.assets.createUploadToken(), { data });
  },
};

export default assets;
