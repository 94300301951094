import { useTranslation } from "react-i18next";
import {
  Checkbox,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { IDocument } from "models/documents.models";
import styles from "./DocumentsRadioList.styles";

type TDocumentOptionsProps = {
  onChange: (data: IDocument) => void;
  documents: IDocument[];
  isCheckbox?: boolean;
  selectedDocument?: IDocument | null;
};

const DocumentsOptions = ({
  onChange,
  documents,
  isCheckbox,
  selectedDocument,
}: TDocumentOptionsProps) => {
  const { t } = useTranslation("documents");

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell width="3%"></TableCell>
          <TableCell>{t("documents.columns.name")}</TableCell>
          <TableCell width="20%">{t("documents.columns.type")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {documents?.map((document) => (
          <TableRow key={`document-${document.id}`}>
            <TableCell sx={styles.radioCell}>
              {isCheckbox ? (
                <Checkbox
                  value={document.id}
                  onChange={() => onChange(document)}
                  size="small"
                />
              ) : (
                <Radio
                  value={document.id}
                  onChange={() => onChange(document)}
                  checked={document.id === selectedDocument?.id}
                  size="small"
                />
              )}
            </TableCell>
            <TableCell>{document.name}</TableCell>
            <TableCell>{document.metadata?.fileType}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default DocumentsOptions;
