import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useBeforeUnload } from "react-router-dom";
import { Box } from "@mui/material";
import UnsavedChangesModal from "components/common/UnsavedChangesModal";
import Button from "components/shared/Button";
import Chip from "components/shared/Chip";
import { queryClient } from "config/reactQueryClient";
import useCreateAssetContent from "hooks/api/REST/assets/useCreateAssetContent";
import { IAssetContent } from "models/assets.models";
import { useDocumentContext } from "screens/Project/sections/Documents/Document/DocumentContext/DocumentContext";
import { QueryAPIKey } from "utils/constants/api.constants";
import styles from "./EditorBar.styles";

const EditorBar = () => {
  const { t } = useTranslation(["documents", "common"]);

  const { documentId } = useParams();

  const { toolbarRef, editingComponent, resetEditingComponent } =
    useDocumentContext();

  useBeforeUnload(resetEditingComponent);

  const isEditorBarVisible =
    Boolean(editingComponent.component) &&
    Boolean(toolbarRef?.current?.firstChild);

  const [shouldShowWarningModal, setShouldShowWarningModal] = useState(false);

  const { createAssetContent } = useCreateAssetContent({
    failureMessage: t("updateDocumentComponentFailure"),
  });

  const closeEditorBar = () => {
    resetEditingComponent();

    if (Boolean(toolbarRef?.current?.firstChild)) {
      toolbarRef?.current?.removeChild(
        toolbarRef.current.firstChild as ChildNode,
      );
    }
  };

  const saveComponent = () => {
    onCloseWarning();

    if (documentId) {
      createAssetContent(
        {
          assetId: editingComponent.component?.assetId || "",
          data: {
            content: editingComponent?.editor?.data?.get() || "",
            assetId: editingComponent.component?.assetId || "",
          },
        },
        {
          onSuccess: (data: IAssetContent) => {
            closeEditorBar();
            editingComponent?.editor?.data?.set(data.content);
            queryClient.invalidateQueries([
              QueryAPIKey.GetAssetContent,
              editingComponent.component?.assetId,
            ]);
          },
        },
      );
    }
  };

  const onCancel = () => {
    editingComponent?.isContentChanged
      ? setShouldShowWarningModal(true)
      : closeEditorBar();
  };

  const onCloseWarning = () => {
    setShouldShowWarningModal(false);
  };

  const onDiscardChanges = () => {
    setShouldShowWarningModal(false);
    editingComponent?.editor?.data?.set(
      editingComponent.component?.content.content as any,
    );
    closeEditorBar();
  };

  return (
    <>
      <Box
        sx={{
          ...styles.editorBar,
          display: isEditorBarVisible ? "flex" : "none",
        }}
      >
        <Box>
          <Chip
            sx={styles.componentName}
            label={editingComponent.component?.headerText || ""}
            size="small"
          />
          <Box sx={styles.tools} ref={toolbarRef} />
        </Box>

        <Button
          hidden={editingComponent.isVisible}
          sx={styles.jumpBack}
          href={`#${editingComponent.component?.assetId || ""}`}
        >
          {t("document.jumpBackToEditContent")}
        </Button>
        <Box sx={styles.actions}>
          <Button data-qaid="editor-bar-cancel-button" onClick={onCancel}>
            {t("button.cancel", { ns: "common" })}
          </Button>
          <Button
            onClick={saveComponent}
            variant="contained"
            disabled={!editingComponent?.isContentChanged}
          >
            {t("button.save", { ns: "common" })}
          </Button>
        </Box>
      </Box>
      <UnsavedChangesModal
        shouldCheckRedirection={isEditorBarVisible}
        onSave={saveComponent}
        onClose={onCloseWarning}
        shouldShowWarningModal={shouldShowWarningModal}
        discardChanges={onDiscardChanges}
      />
    </>
  );
};

export default EditorBar;
