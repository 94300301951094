import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Typography } from "@mui/material";
import { FormCheckboxGroup } from "components/common/form-fields";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Loading from "components/shared/Loading";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import SelectedFile from "components/shared/SelectedFile/SelectedFile";
import { useAlerts } from "context/AlertContext";
import useAllAssetVersions from "hooks/api/REST/assets/useAllAssetVersions";
import useDeleteAsset from "hooks/api/REST/assets/useDeleteAsset";
import useDeleteAssetVersion from "hooks/api/REST/assets/useDeleteAssetVersion";
import { IDocument } from "models/documents.models";
import { getIsDocumentStructuredAAID } from "screens/Project/sections/Documents/utils/document";
import VersionOption, {
  TVersionOption,
} from "screens/Project/sections/Documents/VersionOption/VersionOption";
import { QueryAPIKey } from "utils/constants/api.constants";
import {
  DeleteVersions,
  deleteVersionsSchema,
  getDocumentVersionOptions,
} from "./DeleteDocument.utils";
import styles from "./DeleteDocument.styles";

export type TDeleteDocumentProps = {
  onClose: () => void;
  document: IDocument | null;
};

type TDeleteVersionsForm = {
  [DeleteVersions.Versions]: string[];
};

const DeleteDocument = ({ document, onClose }: TDeleteDocumentProps) => {
  const { t } = useTranslation(["documents", "common"]);

  const { showAlert } = useAlerts();

  const queryClient = useQueryClient();

  const {
    mutateAsync: deleteDocument,
    isLoading: isDocumentDeletionInProgress,
  } = useDeleteAsset({
    options: {
      onSuccess: () => {
        showAlert({
          title: document?.name,
          message: t("notifications.deleteSuccess"),
          severity: "success",
        });

        queryClient.invalidateQueries(QueryAPIKey.GetDocuments);
      },

      onError: async () => {
        showAlert({
          title: document?.name,
          message: t("notifications.deleteFailure"),
          severity: "error",
        });
      },
    },
  });

  const {
    mutateAsync: deleteDocumentVersion,
    isLoading: isDocumentVersionDeletionInProgress,
  } = useDeleteAssetVersion();

  const { versions: allVersions = [], isFetching: isFetchingVersions } =
    useAllAssetVersions(document?.id || "");

  const shouldShowVersions = allVersions?.length > 1;

  const formResolver = shouldShowVersions
    ? { resolver: zodResolver(deleteVersionsSchema) }
    : {};

  const methods = useForm<TDeleteVersionsForm>({
    defaultValues: { [DeleteVersions.Versions]: [] },
    ...formResolver,
  });

  const {
    formState: { isValid },
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    if (!Boolean(document)) {
      reset();
    }
  }, [document, reset]);

  const onSubmit = async (params?: TDeleteVersionsForm) => {
    const isSelectedAllVersion =
      params?.versions.length === allVersions?.length;

    onClose();

    if (!shouldShowVersions || isSelectedAllVersion) {
      await deleteDocument(document?.id ?? "");
    } else {
      if (params) {
        await Promise.allSettled(
          params.versions.map((version) =>
            deleteDocumentVersion({
              id: document?.id || "",
              version: version,
            }),
          ),
        );
      }
    }
  };

  const isStructuredDocument = getIsDocumentStructuredAAID(document);

  const documentTypeText = document ? t(`subtypeOptions.${document.type}`) : "";

  const contentText = useMemo(() => {
    if (shouldShowVersions) {
      return t("deleteDocumentModal.selectVersion");
    } else {
      return isStructuredDocument
        ? t("deleteDocumentModal.deleteStructuredContent", {
            type: documentTypeText,
          })
        : t("deleteDocumentModal.deleteUnstructuredContent");
    }
  }, [isStructuredDocument, shouldShowVersions, t, documentTypeText]);

  const titleText = `${t("deleteDocumentModal.title")} ${
    isStructuredDocument ? documentTypeText : t("deleteDocumentModal.content")
  }`;

  const versionOptions = useMemo(
    () => getDocumentVersionOptions(allVersions),
    [allVersions],
  );

  const isSubmitButtonDisabled =
    isDocumentDeletionInProgress ||
    isDocumentVersionDeletionInProgress ||
    isFetchingVersions ||
    !isValid;

  return (
    <Modal open data-qaid="delete-document-modal" title={titleText}>
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="body2" marginBottom={1.5}>
          {contentText}
        </Typography>
        <Box sx={styles.selectedFile}>
          <SelectedFile
            fileName={document?.name || ""}
            clearIconAriaLabel={t("ariaLabels.clearIcon", {
              fileName: document?.name || "",
              ns: "common",
            })}
          />
        </Box>

        {isFetchingVersions ? <Loading /> : null}
        {shouldShowVersions && !isFetchingVersions && (
          <FormCheckboxGroup
            name={DeleteVersions.Versions}
            options={versionOptions}
            allLabel={t("deleteVersionModal.allVersions", {
              versionsLength: allVersions.length,
            })}
            lockIconAriaLabel={t("deleteVersionModal.locked")}
            labelControl={(item: TVersionOption) => (
              <VersionOption item={item} />
            )}
          />
        )}
        <ModalActionButtons>
          <Button data-qaid="delete-modal-cancel-button" onClick={onClose}>
            {t("button.cancel", { ns: "common" })}
          </Button>
          <Button
            data-qaid="delete-modal-submit-button"
            disabled={isSubmitButtonDisabled}
            variant="contained"
            color="error"
            type="submit"
            sx={styles.submitButton}
          >
            {t("button.delete", { ns: "common" })}
          </Button>
        </ModalActionButtons>
      </FormWrapper>
    </Modal>
  );
};

export default DeleteDocument;
