import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  headerActions: {
    alignItems: "center",
    gap: 2,
  },

  modeSelect: {
    height: 28,
    width: 154,
    minWidth: 154,
    backgroundColor: getPaletteColor("custom.selected"),

    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      fontSize: 14,
    },

    "& .MuiTypography-body2:last-child": {
      display: "none",
    },
  },

  modeSelectItem: {
    display: "flex",
    alignItems: "flex-start",
    fontSize: 14,
  },

  itemSubtitle: {
    fontSize: 12,
  },

  icon: {
    maxWidth: 20,
    maxHeight: 20,
    color: getPaletteColor("grey.600"),
    mr: 1,
  },

  iconAction: {
    color: getPaletteColor("grey.600"),
  },

  actionsButton: {
    width: 153,
    height: 42,
    justifyContent: "space-between",
    pr: 0,

    "& .MuiButton-endIcon": {
      width: 50,
      height: 42,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      backgroundColor: getPaletteColor("primary.400"),
      transition: (theme) =>
        theme.transitions.create("background-color", {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.short,
        }),
    },

    "&:hover .MuiButton-endIcon": {
      backgroundColor: getPaletteColor("primary.500"),
    },
  },
};

export default styles;
