import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    p: (theme) => theme.spacing(2.5, 4, 2.5, 4),
  },

  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 280,
  },

  header: {
    justifyContent: "space-between",
    alignItems: "center",
    mt: 1,
    marginBottom: 3,
  },

  filters: {
    marginTop: 3.5,
    marginBottom: 2,
  },

  table: {
    "& .MuiTableCell-head": {
      lineHeight: 1.7,
    },
  },
};

export default styles;
