import {
  DossierDifferencesModalFields,
  TDossierDifferencesModalForm,
  TDossierDifferencesModalFormEdit,
} from "../DossierDifferenceModal.types";

export const getDossierDifferenceId = (
  data: TDossierDifferencesModalForm | TDossierDifferencesModalFormEdit,
) =>
  DossierDifferencesModalFields.DossierDifferenceId in data &&
  data.dossierDifferenceId;
