export enum BinaryChoice {
  Yes = "YES",
  No = "NO",
}

export namespace BinaryChoice {
  export const fromBoolean = (ifYes: boolean): BinaryChoice => {
    return ifYes ? BinaryChoice.Yes : BinaryChoice.No;
  };

  export const toBoolean = (choice: string): boolean => {
    switch (choice) {
      case BinaryChoice.Yes:
        return true;
      case BinaryChoice.No:
        return false;

      default:
        throw new Error(`"${choice}" cannot be converted to boolean`);
    }
  };
}

export const FALLBACK_LANGUAGE = "en";

export const ACCUMULUS_TRUST_URL = "https://www.accumulus.org/term-of-use/";
