import { TFunction } from "i18next";
import { getHAName } from "components/common/HealthAuthority/HealthAuthority.utils";
import { IOrganization } from "models/organizations.models";

export const getRecipientsForDisplay = (
  organizations: IOrganization[],
  t: TFunction,
) => {
  return (
    organizations
      ?.map((org) => {
        return {
          label: getHAName(org.country, org.name, t),
          value: org.tenantId || org.id,
        };
      })
      .filter((org) => org.label) || []
  );
};
