import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    backgroundColor: getPaletteColor("common.white"),
    height: "auto",
    p: (theme) => theme.spacing(1, 4, 1.5, 4),
    boxShadow: (theme) =>
      `0px 2px 5px ${getPaletteColor("action.selected")(theme)}`,
    position: "relative",
    zIndex: (theme) => theme.zIndex.appBar,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

export default styles;
