import { TFunction } from "i18next";
import { z } from "zod";
import { TComboBoxOption } from "components/shared/ComboBox/ComboBox";
import {
  getGenericFutureDateConstraint,
  getGenericStringArrayConstraint,
  MEMBER_SCHEMA,
} from "utils/validators/constraints/validationConstraints";

export enum StartReviewModalField {
  Components = "components",
  Members = "members",
  DueDate = "dueDate",
  Instructions = "instructions",
}

export type TStartReviewModalForm = {
  [StartReviewModalField.Members]: TComboBoxOption[];
  [StartReviewModalField.Components]: string[];
  [StartReviewModalField.DueDate]: Date | null;
  [StartReviewModalField.Instructions]: string;
};

export const startReviewModalValues: TStartReviewModalForm = {
  [StartReviewModalField.Members]: [],
  [StartReviewModalField.DueDate]: null,
  [StartReviewModalField.Components]: [],
  [StartReviewModalField.Instructions]: "",
};

export const getStartReviewFormSchema = (t: TFunction) =>
  z.object({
    [StartReviewModalField.Components]: getGenericStringArrayConstraint(t),
    [StartReviewModalField.Members]: z.array(MEMBER_SCHEMA).min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [StartReviewModalField.DueDate]: getGenericFutureDateConstraint(t),
    [StartReviewModalField.Instructions]: z.string().optional(),
  });
