import { useMemo } from "react";
import getEnv from "env";
import compact from "lodash/compact";
import get from "lodash/get";
import { getShareHistoryTableProps } from "components/common/HAQ/HAQShareHistory/HAQShareHistoryModal/HAQShareHistoryModal.utils";
import useFeatureFlags from "context/FeatureFlagsContext/useFeatureFlags";
import useAsset from "hooks/api/REST/assets/useAsset";
import useAssetContent from "hooks/api/REST/assets/useAssetContent";
import useGetAssetRelationships from "hooks/api/REST/assets/useGetAssetRelationships";
import useBulkShareHistory from "hooks/api/REST/shareHistory/useBulkShareHistory";
import useUser from "hooks/useUser";
import { IHAQMetadata } from "models/HAQ.models";
import { AssetRelationship, AssetType } from "utils/constants/assets.constants";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import { getParsedARN } from "utils/helpers";
import { isHAUser } from "utils/user/organization";

const useHAQ = (HAQId?: string) => {
  const { organization } = useUser();
  const featureFlags = useFeatureFlags();

  const shouldUseHAQActionsV2 =
    featureFlags["FTE-13049_enableHAQActionsV2"] === FeatureFlag.On;

  const {
    asset: HAQ,
    isLoading: isHAQLoading,
    error: HAQError,
  } = useAsset<IHAQMetadata>(HAQId ?? "", {
    params: {
      format: "FULL",
    },
  });

  const assetRelation =
    typeof HAQ?.metadata?.assets?.[0] === "object"
      ? HAQ.metadata.assets[0]
      : { id: HAQ?.metadata?.assets?.[0], name: "" };

  const { asset: relatedAsset } = useAsset(
    assetRelation.id || "",
    {},
    {
      enabled: getParsedARN(assetRelation.id)?.tenantId === getEnv().tenant,
    },
  );

  const { data: HAQContent, isLoading: isHAQContentLoading } = useAssetContent(
    HAQId ?? "",
  );

  const HAQResponse = useMemo(
    () =>
      HAQ?.children?.find((item) => item.type === AssetType.QuestionResponse),
    [HAQ],
  );

  const { shareHistory: HAQAndHAQRShareHistory } = useBulkShareHistory(
    compact([HAQId, HAQResponse?.id]),
  );

  const HAQShareHistory = get(HAQAndHAQRShareHistory, HAQId || "");

  const HAQResponseShareHistory = get(
    HAQAndHAQRShareHistory,
    HAQResponse?.id || "",
  );

  const shareHistoryTableProps = getShareHistoryTableProps(
    HAQShareHistory || [],
    HAQResponseShareHistory || [],
    shouldUseHAQActionsV2 ? Boolean(HAQ?.metadata?.imported) : false,
    isHAUser(organization),
  );

  const { data: HAQResponseContent, isLoading: isHAQResponseContentLoading } =
    useAssetContent(HAQResponse?.id ?? "");

  const {
    asset: HAQResponseAttachments,
    isLoading: isHAQResponseAttachmentsLoading,
  } = useGetAssetRelationships(
    HAQResponse?.id ?? "",
    AssetRelationship.HasAttachment,
  );

  return useMemo(
    () => ({
      HAQ,
      isHAQLoading,
      HAQContent,
      isHAQContentLoading,
      HAQResponse,
      HAQResponseContent,
      isHAQResponseContentLoading,
      relatedAssetName: relatedAsset?.name || assetRelation.name || null,
      shareHistoryTableProps,
      HAQShareHistory,
      HAQResponseShareHistory,
      HAQResponseAttachments,
      isHAQResponseAttachmentsLoading,
      HAQError,
    }),
    [
      HAQError,
      HAQ,
      isHAQLoading,
      HAQContent,
      isHAQContentLoading,
      HAQResponse,
      HAQResponseContent,
      isHAQResponseContentLoading,
      relatedAsset,
      assetRelation.name,
      shareHistoryTableProps,
      HAQShareHistory,
      HAQResponseShareHistory,
      HAQResponseAttachments,
      isHAQResponseAttachmentsLoading,
    ],
  );
};

export default useHAQ;
