import { useTranslation } from "react-i18next";
import { QueryObserverOptions, useQuery } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { ICurrentUserProjectRole } from "models/users.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export default function useCurrentUserRoles(
  options: QueryObserverOptions<ICurrentUserProjectRole[], Error>,
  params: unknown[] = [],
) {
  const { t } = useTranslation("user", {
    keyPrefix: "notifications",
  });

  const {
    data: roles,
    status,
    error,
    isLoading,
    isError,
    isSuccess,
  } = useQuery<ICurrentUserProjectRole[], Error>(
    [QueryAPIKey.GetCurrentUserRoles, ...params],
    () => services.user.getCurrentUserRoles().then((res) => res.data),
    options,
  );

  useShowAlert({
    status,
    failureMessage: t("getCurrentUserProjectRolesFailure"),
  });

  return {
    roles,
    status,
    isLoading,
    isError,
    isSuccess,
    error,
  };
}
