export enum QueryFetchingStatus {
  Idle = "idle",
  Error = "error",
  Loading = "loading",
  Success = "success",
}

export enum QueryAPIKey {
  // assets
  GetAssets = "getAssets",
  GetAssetsWithContent = "getAssetsWithContent",
  GetAsset = "getAsset",
  GetAssetRelationships = "getAssetRelationships",
  GetAssetMetadata = "getAssetMetadata",
  GetAllAssetVersions = "getAllAssetVersions",
  GetAssetContent = "getAssetContent",
  GetAssetShareHistory = "getAssetShareHistory",
  GetAssetBulkShareHistory = "getAssetBulkShareHistory",
  GetParseJob = "getParseJob",
  GetDataDictionary = "getDataDictionary",
  GetCommentThreads = "getCommentThreads",
  GetProjectTypeDefinitions = "getProjectTypeDefinitions",

  // data x
  GetFHIRResource = "getFHIRResource",
  GetFHIRResourcesByType = "getFHIRResourcesByType",
  UpdateProductFamily = "updateProductFamily",

  // documents
  GetDocument = "getDocument",
  GetDocuments = "getDocuments",
  GetDocumentTypes = "getDocumentTypes",
  GetDomains = "getDomains",
  GetCommentThread = "getCommentThread",
  GetSuggestion = "getSuggestion",

  // feature flags
  GetFeatureFlags = "getFeatureFlags",

  // projects
  GetProjectTypeSubtypes = "getProjectTypeSubtypes",
  GetProjectTypes = "getProjectTypes",
  GetProjectData = "GetProjectData",
  GetProjects = "getProjects",
  GetProjectsCountsByStatus = "getProjectsCountsByStatus",
  GetProjectTypeMetadata = "getProjectTypeMetadata",
  GetQuestions = "getQuestions",
  GetLanguages = "getLanguages",
  GetRecentProjects = "getRecentProjects",
  GetUserProjects = "getUserProjects",

  // GSP
  GetGSPById = "getGSPById",

  // resources
  GetResourceById = "getResourceById",

  // users
  GetCurrentUser = "getCurrentUser",
  GetUserProjectRoles = "getUserProjectRoles",
  GetCurrentUserRoles = "getCurrentUserRoles",
  GetProjectMembers = "getProjectMembers",
  GetUsersList = "getUsersList",
  GetUserGroups = "getUserGroups",
  GetTimeZones = "getTimeZones",
  GetUserLegalAcknowledgement = "getUserLegalAcknowledgement",
  GetUserData = "getUserData",

  // tasks
  GetTasks = "getTasks",
  GetTask = "getTask",
  GetGroupedTask = "getGroupedTask",
  GetTasksData = "getTasksData",
  GetTasksPaginated = "getTasksPaginated",
  GetTaskParticipants = "getTaskParticipants",

  // milestones
  GetMilestones = "getMilestones",

  // organizations
  GetOrganization = "getOrganization",
  GetOrganizations = "getOrganizations",

  // notifications
  GetNotificationsForUser = "getNotificationsForUser",
  GetUnreadNotificationCount = "getUnreadNotificationCount",

  // workflows
  GetWorkflow = "getWorkflow",

  // activity
  GetRecentActivity = "getRecentActivity",

  // portfolio
  GetPortfolio = "getPortfolio",
  GetTherapeuticSummary = "getTherapeuticSummary",
  GetOutline = "getOutline",
  GetBatchFormula = "getBatchFormula",
  GetBatchLotProductInformation = "getBatchLotProductInformation",
  GetDrugProduct = "getDrugProduct",
  GetDrugProductExcipients = "getDrugProductExcipients",
  GetDrugProductImpurities = "getDrugProductImpurities",
  GetDrugProductMicrobiologicalAttributes = "getDrugProductMicrobiologicalAttributes",
  GetDrugProductStabilityStudy = "getDrugProductStabilityStudy",
  GetPQDomainOrganizations = "getPQDomainOrganizations",

  // entity management
  GetSubstance = "getSubstance",
  GetSubstances = "getSubstances",
  GetEntityProducts = "getEntityProducts",
  GetProductVariants = "getProductVariants",
  GetEntityOrganizations = "getEntityOrganizations",
  GetProductFamilies = "getProductFamilies",
  GetProductEntityControlledLists = "getProductEntityControlledLists",
  GetDrugClassList = "getDrugClassList",
  GetOrganizationRoleList = "getOrganizationRoleList",
  GetOrganizationTypeList = "getOrganizationTypeList",
  GetCountriesList = "GetCountriesList",

  // audit
  GetAuditReport = "getAuditReport",
}

export const DEFAULT_POLLING_INTERVAL = 1000;
export const DEFAULT_NOTIFICATIONS_POLLING_INTERVAL = 5000;

export const MAX_SIZE = 2000;

// BE can extract current user from headers
// FE can pass string 'self'
export const NOTIFICATIONS_CURRENT_USER = "self";
