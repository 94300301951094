import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  SxProps,
  Theme,
} from "@mui/material";
import styles from "./Loading.styles";

type TLoadingProps = {
  sx?: SxProps<Theme>;
  size?: number | string;
  color?: CircularProgressProps["color"];
};

const Loading = (props: TLoadingProps) => {
  const { t } = useTranslation("common");

  return (
    <Box component="span" sx={props.sx || styles.container} data-qaid="loading">
      <CircularProgress
        size={props.size || "26px"}
        color={props.color}
        aria-label={t("accessibility.label.loading")}
      />
    </Box>
  );
};

export default Loading;
