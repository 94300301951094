import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import { Button } from "components/shared";

type THeaderProps = {
  onCloseDrawer: () => void;
};

const Header = ({ onCloseDrawer }: THeaderProps) => {
  const { t } = useTranslation(["HAQ", "common"]);

  return (
    <Box padding={3} paddingBottom={0}>
      <Button
        variant="text"
        startIcon={<CloseIcon />}
        onClick={onCloseDrawer}
        aria-label={t("button.close", { ns: "common" })}
        data-qaid="add-haq-drawer-close-button"
      >
        {t("button.close", { ns: "common" })}
      </Button>

      <Box display="flex" alignItems="center" paddingBottom={2} paddingTop={2}>
        <Typography variant="h6" component="h2">
          {t("addHAQModal.title")}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
