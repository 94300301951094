import { TFunction } from "i18next";
import { z } from "zod";
import { IDocument } from "models/documents.models";
import { TISODateFormat } from "utils/types";
import {
  DOCUMENT_SCHEMA,
  getGenericFutureDateConstraint,
  MEMBER_SCHEMA,
} from "utils/validators/constraints/validationConstraints";
import {
  ShareDocumentFieldName,
  TShareDocumentForm,
} from "./ShareDocument.types";

export const getShareDocumentsDefaultValues = (
  documentsToSubmit: IDocument[],
): TShareDocumentForm => ({
  [ShareDocumentFieldName.Documents]: documentsToSubmit,
  [ShareDocumentFieldName.Recipients]: [],
  [ShareDocumentFieldName.DueDate]: "" as TISODateFormat,
  [ShareDocumentFieldName.Message]: "",
});

export const getShareDocumentSchema = (t: TFunction, isSponsor: boolean) =>
  z.object({
    [ShareDocumentFieldName.Documents]: z.array(DOCUMENT_SCHEMA).min(1),
    [ShareDocumentFieldName.Recipients]: z.array(MEMBER_SCHEMA).min(1, {
      message: String(
        t("documents.shareDocument.fields.recipients.emptyMessage", {
          ns: "documents",
        }),
      ),
    }),
    [ShareDocumentFieldName.DueDate]: isSponsor
      ? getGenericFutureDateConstraint(t)
      : z.literal(""),
    [ShareDocumentFieldName.Message]: z.string().optional(),
  });
