import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import CollapsibleNavListItem from "./CollapsibleNavListItem";
import useNavigationItems from "./hooks/useNavigationItems";
import styles from "./GlobalNavigation.styles";

type TProps = {
  isSidebarExpanded: boolean;
};

const MainNavigationList = (props: TProps) => {
  const { t } = useTranslation(["common"]);

  const navigationItems = useNavigationItems();

  const navMenuListStyles = {
    ...styles.navMenuList,
    ...(!props.isSidebarExpanded && styles.navMenuListClose),
  };

  return (
    <Box
      role="navigation"
      aria-label={t("accessibility.label.primary", { ns: "common" })}
    >
      <List disablePadding sx={navMenuListStyles} role="list">
        {navigationItems.map((navItem) =>
          isEmpty(navItem.items) ? (
            <ListItem
              key={navItem.key}
              disablePadding
              sx={
                props.isSidebarExpanded
                  ? styles.navMenuItem
                  : styles.navMenuItemDrawerClosed
              }
            >
              <ListItemButton
                data-testid="left-hand-navigation-page-option"
                data-qaid="left-hand-navigation-page-option"
                component={NavLink}
                sx={styles.navItem}
                aria-label={navItem.key}
                key={navItem.key}
                to={navItem.href}
                disabled={navItem.disabled}
              >
                <ListItemIcon>{navItem.icon}</ListItemIcon>
                {props.isSidebarExpanded && (
                  <ListItemText
                    primary={navItem.label}
                    sx={styles.navMenuItemButtonText}
                  />
                )}
              </ListItemButton>
            </ListItem>
          ) : (
            <CollapsibleNavListItem
              key={navItem.key}
              navItem={navItem}
              isSidebarExpanded={props.isSidebarExpanded}
            />
          ),
        )}
      </List>
    </Box>
  );
};

export default MainNavigationList;
