import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import isEmpty from "lodash/isEmpty";
import useShowAlert from "hooks/useShowAlert";
import { IOrganization } from "models/organizations.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { TErrorData } from "utils/types";

export default function useOrganization(id: string | undefined) {
  const { t } = useTranslation("organizations", {
    keyPrefix: "notifications",
  });

  const safeOrganizationId = id || "";

  const { data, status, isLoading, isError, isSuccess, error } = useQuery<
    IOrganization,
    AxiosError<TErrorData>
  >(
    [QueryAPIKey.GetOrganization, id],
    () =>
      services.organizations
        .getOrganizationById(safeOrganizationId)
        .then((res) => res.data),
    { enabled: !isEmpty(id), staleTime: Infinity },
  );

  useShowAlert({
    status,
    failureMessage: t("getOrganizationFailure"),
  });

  return {
    organization: data,
    isLoading,
    isError,
    isSuccess,
    error,
    status,
  };
}
