import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  label: {
    color: getPaletteColor("text.secondary"),
  },
};

export default styles;
