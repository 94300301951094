import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  formRowUtils: {
    display: "flex",
    alignItems: "center",
    mr: 3,
  },

  expandIcon: {
    color: getPaletteColor("grey.900"),
    ml: 3,
  },

  formContainer: {
    py: 4,

    "& .MuiFormControl-root": {
      width: "100%",
    },
  },

  divider: {
    pt: 4,
    mb: 4,
  },

  formSectionHeading: {
    color: getPaletteColor("grey.600"),
    display: "flex",
    alignItems: "center",
    pb: 2,
    columnGap: 1,
  },

  dossierDifference: {
    width: "100%",
    padding: 2,
    border: getBorderStyle("1px", "solid", "blueGrey.100"),
    borderRadius: "8px",
    textAlign: "center",
    color: getPaletteColor("grey.500"),
    mt: 2,
  },

  formSectionBottomMargin: {
    mb: 4,
  },

  fixedHeightContainer: {
    maxHeight: "470px",
    overflow: "auto",
    mt: 2.5,
  },
};

export default styles;
