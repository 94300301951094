import { TFunction } from "i18next";
import { z } from "zod";
import { TSingleSelectTypeaheadOption } from "components/shared/SingleSelectTypeahead";

export enum OrganizationFieldName {
  OrganizationName = "name",
  OrganizationAlias = "alias",
  OrganizationRole = "role",
  Address = "address",
  City = "city",
  ZipCode = "zipCode",
  Country = "country",
  Status = "status",
}

export type TOrganizationForm = {
  [OrganizationFieldName.OrganizationName]: string;
  [OrganizationFieldName.OrganizationAlias]: string;
  [OrganizationFieldName.OrganizationRole]: TSingleSelectTypeaheadOption[];
  [OrganizationFieldName.Address]: string;
  [OrganizationFieldName.City]: string;
  [OrganizationFieldName.ZipCode]: string;
  [OrganizationFieldName.Country]: string;
  [OrganizationFieldName.Status]: string;
};

export const getCreateOrganizationFormValues = (): TOrganizationForm => ({
  [OrganizationFieldName.OrganizationName]: "",
  [OrganizationFieldName.OrganizationAlias]: "",
  [OrganizationFieldName.OrganizationRole]: [],
  [OrganizationFieldName.Address]: "",
  [OrganizationFieldName.City]: "",
  [OrganizationFieldName.ZipCode]: "",
  [OrganizationFieldName.Country]: "",
  [OrganizationFieldName.Status]: "",
});

export const getCreateOrganizationFormSchema = (t: TFunction) =>
  z
    .object({
      [OrganizationFieldName.OrganizationName]: z.string().min(1, {
        message: t("validations.requiredField", { ns: "common" }),
      }),
      [OrganizationFieldName.OrganizationAlias]: z.string().min(1, {
        message: t("validations.requiredField", { ns: "common" }),
      }),
      [OrganizationFieldName.OrganizationRole]: z
        .array(
          z.object({
            label: z.string(),
            value: z.string(),
          }),
        )
        .min(1, {
          message: t("validations.requiredField", { ns: "common" }),
        }),
      [OrganizationFieldName.Country]: z.string().min(1, {
        message: t("validations.requiredField", { ns: "common" }),
      }),
      [OrganizationFieldName.Status]: z.string().min(1, {
        message: t("validations.requiredField", { ns: "common" }),
      }),
    })
    .passthrough();
