import { IProjectData } from "hooks/api/GQL/project/useProjectData.models";
import { IDocument } from "models/documents.models";
import { getIsDocumentComposite } from "screens/Project/sections/Documents/utils/document";
import { DocumentStatus } from "utils/constants/doc.constants";

export const getCanDocumentMetadataBeEdited = (
  hasUserEditPermissions: boolean,
  document: IDocument,
  project?: IProjectData | null,
) => {
  if (!project?.active) {
    return false;
  }

  const isUnstructuredDocument = !getIsDocumentComposite(document);

  const isCorrectStatus = [
    DocumentStatus.InVerification,
    DocumentStatus.Final,
  ].includes(document.status);

  const isSubmitted = document?.metadata?.submissionDate;

  return (
    hasUserEditPermissions &&
    isUnstructuredDocument &&
    isCorrectStatus &&
    !isSubmitted
  );
};
