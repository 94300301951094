import { SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getCoreRowModel,
  PaginationState,
  Updater,
  useReactTable,
} from "@tanstack/react-table";
import { isFunction } from "lodash";
import { ReactTable } from "components/shared";
import { IDocument } from "models/documents.models";
import {
  getDocumentForSubmission,
  getDocumentsForRender,
} from "./AddProjectDocuments.utils";
import getColumns from "./columns";
import styles from "./AddProjectDocuments.styles";

export type TDocumentsSelection = {
  [documentId: string]: boolean;
};

export type TAddProjectDocumentsTableProps = {
  documents?: IDocument[];
  attachedDocuments?: IDocument[];
  pagination: PaginationState;
  setPagination: (updaterOrValue: SetStateAction<PaginationState>) => void;
  totalElements?: number;
  onDocumentForSubmissionChange: (documents: IDocument[]) => void;
};

const AddProjectDocumentsTable = ({
  documents,
  attachedDocuments,
  pagination,
  setPagination,
  totalElements,
  onDocumentForSubmissionChange,
}: TAddProjectDocumentsTableProps) => {
  const { t } = useTranslation(["documents"]);

  const attachedDocumentsSelection: TDocumentsSelection = Object.fromEntries(
    attachedDocuments?.map(({ id }) => [id, true]) ?? [],
  );

  const [rowSelection, setRowSelection] = useState<TDocumentsSelection>(
    attachedDocumentsSelection,
  );

  const columns = useMemo(() => getColumns(t), [t]);

  const rows = useMemo(() => {
    return getDocumentsForRender(documents, t, attachedDocuments);
  }, [documents, t, attachedDocuments]);

  const handleRowSelectionChange = (
    updaterOrValue: Updater<TDocumentsSelection>,
  ) => {
    const newSelection = isFunction(updaterOrValue)
      ? updaterOrValue(rowSelection)
      : updaterOrValue;

    const selectedDocuments =
      documents?.filter(({ id }) => newSelection[id]) ?? [];

    setRowSelection({
      ...newSelection,
      ...attachedDocumentsSelection, // attachedDocuments should always be preselected
    });
    onDocumentForSubmissionChange(
      getDocumentForSubmission(selectedDocuments, attachedDocuments),
    );
  };

  const tableInstance = useReactTable({
    columns: columns,
    data: rows,
    enableSorting: false,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    manualPagination: true,
    getRowId: (row) => row.id,
    rowCount: totalElements ?? 0,
    initialState: {
      rowSelection,
    },
    state: {
      pagination,
      rowSelection,
    },
    meta: {
      isRowDisabled: (row) => !!row.original.isDisabled,
    },
    onPaginationChange: setPagination,
    onRowSelectionChange: handleRowSelectionChange,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <ReactTable
      sx={styles.table}
      tableInstance={tableInstance}
      isPaginated={true}
    />
  );
};

export default AddProjectDocumentsTable;
