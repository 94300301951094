import React, { PropsWithChildren } from "react";
import { Grid, Skeleton } from "@mui/material";

export interface ISkeletonWrapperProps {
  isLoading: boolean;
}

export const SkeletonWrapper = ({
  children,
  isLoading,
}: PropsWithChildren<ISkeletonWrapperProps>) => {
  return isLoading ? (
    <Grid item xs={12} data-testid="skeleton" data-qaid="skeleton">
      <Skeleton />
    </Grid>
  ) : (
    <>{children}</>
  );
};
