import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Box, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import Button from "components/shared/Button";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import SelectedFile from "components/shared/SelectedFile/SelectedFile";
import { useAlerts } from "context/AlertContext";
import useDeleteAssets from "hooks/api/REST/assets/useDeleteAssets";
import { IDocument } from "models/documents.models";
import { QueryAPIKey } from "utils/constants/api.constants";
import styles from "./DeleteDocuments.styles";

export interface IDeleteDocumentsModalProps {
  onClose: () => void;
  documents: IDocument[] | null;
}

const DeleteDocument = ({ documents, onClose }: IDeleteDocumentsModalProps) => {
  const { t } = useTranslation(["documents", "common"]);

  const { showAlert } = useAlerts();

  const queryClient = useQueryClient();

  const {
    mutateAsync: deleteDocuments,
    isLoading: isBulkDocumentDeletionInProgress,
  } = useDeleteAssets({
    options: {
      onSuccess: () => {
        showAlert({
          message: t("notifications.deleteBulkContentSuccess"),
          severity: "success",
        });

        queryClient.invalidateQueries(QueryAPIKey.GetDocuments);
      },

      onError: async () => {
        showAlert({
          message: t("notifications.deleteBulkContentFailure"),
          severity: "error",
        });
      },
    },
  });

  const onConfirmDeleteDocuments = async () => {
    onClose();
    const assetIds = documents?.map((document) => document.id) || [];
    await deleteDocuments({ assetIds });
  };

  return (
    <Modal
      open
      data-qaid="delete-documents-modal"
      title={t("deleteDocumentsModal.title")}
    >
      <Typography variant="body2" marginBottom={1.5}>
        {t("deleteDocumentsModal.deleteBulkUnstructuredContent")}
      </Typography>
      <Box sx={styles.fileListContainer}>
        {documents?.map((document) => {
          return (
            <Box sx={styles.selectedFile} key={document.id}>
              <SelectedFile
                fileName={document?.name || ""}
                clearIconAriaLabel={t("ariaLabels.clearIcon", {
                  fileName: document?.name || "",
                  ns: "common",
                })}
              />
            </Box>
          );
        })}
      </Box>
      <ModalActionButtons>
        <Button
          data-qaid="delete-documents-modal-cancel-button"
          onClick={onClose}
        >
          {t("button.cancel", { ns: "common" })}
        </Button>
        <Button
          data-qaid="delete-documents-modal-submit-button"
          disabled={isEmpty(documents) || isBulkDocumentDeletionInProgress}
          variant="contained"
          color="error"
          onClick={onConfirmDeleteDocuments}
          sx={styles.submitButton}
        >
          {t("button.delete", { ns: "common" })}
        </Button>
      </ModalActionButtons>
    </Modal>
  );
};

export default DeleteDocument;
