import { TStyles } from "utils/Styles";

const styles: TStyles = {
  fileName: {
    paddingBlock: 1,
    maxWidth: "600px",
    overflowWrap: "break-word",
  },
};

export default styles;
