import { useQuery, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";
import { ILegalAcknowledgementData } from "models/legalAcknowledgement.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { TErrorData } from "utils/types";

export default function useLegalAcknowledgements(
  options?: UseQueryOptions<
    ILegalAcknowledgementData[],
    AxiosError<TErrorData>
  >,
) {
  const {
    data: acknowledgements,
    error,
    isLoading,
    isError,
    isSuccess,
  } = useQuery<ILegalAcknowledgementData[], AxiosError<TErrorData>>(
    [QueryAPIKey.GetUserLegalAcknowledgement],
    () => services.user.getUserLegalAcknowledgements().then((res) => res.data),
    options,
  );

  return {
    acknowledgements,
    isLoading,
    isError,
    isSuccess,
    error,
  };
}
