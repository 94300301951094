import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { Tooltip } from "components/shared";
import { IDocument } from "models/documents.models";
import {
  getIsDocumentComposite,
  getIsDocumentStructuredAAID,
} from "screens/Project/sections/Documents/utils/document";
import { DocumentStatus } from "utils/constants/doc.constants";
import { getDocumentPath } from "utils/helpers";
import { FilterSearchIcon } from "assets/icons";
import styles from "./DocumentName.styles";

export type TDocumentNameProps = {
  document: IDocument;
};

const DocumentName = ({ document }: TDocumentNameProps) => {
  const { projectId } = useParams();
  const { t } = useTranslation("documents");

  return (
    <Box sx={styles.documentNameCell}>
      {getIsDocumentComposite(document) &&
      document.status !== DocumentStatus.Failure ? (
        <>
          <Link to={getDocumentPath(projectId, document)}>{document.name}</Link>
          {getIsDocumentStructuredAAID(document) && (
            <Tooltip
              title={t("documents.structuredDocument")}
              placement="right"
            >
              <FilterSearchIcon sx={styles.icon} />
            </Tooltip>
          )}
        </>
      ) : (
        document.name
      )}
    </Box>
  );
};

export default DocumentName;
