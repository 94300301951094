import { Theme } from "@mui/material/styles";
import { getPaletteColor } from "theme/helpers";
import {
  NAVIGATION_DRAWER_TABLET_WIDTH,
  NAVIGATION_DRAWER_WIDTH,
} from "utils/constants/drawer.constants";
import { TStyles } from "utils/Styles";
import { focusOutlineStyle } from "styles/accessibility.styles";

const openedMixin = (theme: Theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

const drawerClosedWidth = 80;

const drawerPaperBaseStyle = {
  zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  height: "100vh",
  backgroundColor: getPaletteColor("secondary.main"),
  overflowX: "hidden",
};

const logoContainerBaseStyle = {
  display: "flex",
  minHeight: "63px",
  mt: "13px",
  mb: "24px",

  alignItems: "center",
  justifyContent: "center",
};

const navMenuItemClosedWidth = 48;

const navMenuItemBaseStyle = {
  mb: "8.4px",
};

const navMenuItemHoverStyle = {
  color: getPaletteColor("common.white"),
  borderRadius: "6px",
};

const notificationMenuItemBaseStyle = {
  mb: "4.8px",
};

const notificationMenuItemDrawerClosedBaseStyle = {
  width: navMenuItemClosedWidth,
};

const notificationMenuItemActiveBaseStyle = {
  color: getPaletteColor("custom.globalNavigationText"),

  "& .MuiListItemButton-root": {
    borderRadius: "6px",
    backgroundColor: getPaletteColor("secondary.300"),
  },
};

const profileMenuItemBaseStyle = {
  mb: "5.6px",

  "& a": {
    flex: 1,
  },
};

const dividerBaseStyle = {
  borderColor: getPaletteColor("custom.globalNavigationDivider"),
  mb: "3px",
};

const getDrawerToggleMenuItemBaseStyle = (theme: Theme) => ({
  "& .MuiListItemButton-root": {
    height: 40,

    "& .MuiListItemText-root": {
      my: 0,

      "& .MuiListItemText-primary": {
        whiteSpace: "nowrap",
        ...theme.typography.buttonSmall,
      },
    },
  },
});

const navContainer = {
  px: 1.5,
  pb: 0.5,
};

const styles: TStyles = {
  drawerOpen: (theme) => ({
    ...openedMixin(theme),
    width: NAVIGATION_DRAWER_WIDTH,

    [theme.breakpoints.down("xl")]: {
      width: NAVIGATION_DRAWER_TABLET_WIDTH,
    },

    "& .MuiDrawer-paper": {
      ...drawerPaperBaseStyle,
      ...openedMixin(theme),
      width: NAVIGATION_DRAWER_WIDTH,

      [theme.breakpoints.down("xl")]: {
        width: NAVIGATION_DRAWER_TABLET_WIDTH,
      },
    },
  }),

  drawerClosed: (theme) => ({
    ...closedMixin(theme),
    width: drawerClosedWidth,

    "& .MuiDrawer-paper": {
      ...drawerPaperBaseStyle,
      ...closedMixin(theme),
      width: drawerClosedWidth,
    },
  }),

  navItem: {
    "&:focus-visible": {
      outline: focusOutlineStyle,
    },
  },

  container: {
    height: "100%",

    overflow: "hidden",
  },

  logoContainerOpen: {
    ...logoContainerBaseStyle,

    "& img": {
      width: "182px",
      height: "46px",
    },
  },

  logoContainerClosed: {
    ...logoContainerBaseStyle,

    "& img": {
      width: "32px",
      height: "30px",
    },
  },

  mainNavContainer: {
    ...navContainer,
    flex: 1,
    overflowX: "hidden",
    overflowY: "auto",
  },

  additionalNavContainer: navContainer,

  navMenuList: {
    color: getPaletteColor("custom.globalNavigationText"),
    pt: "3px",

    "& .MuiListItemButton-root": {
      boxSizing: "border-box",
      height: 45.2,
      p: 0,

      "& .MuiListItemIcon-root": {
        width: 48,
        minWidth: "auto",
        height: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "inherit",
      },

      "&:hover": {
        ...navMenuItemHoverStyle,
      },
    },

    // React Router NavLink styles
    "& a": {
      color: getPaletteColor("custom.globalNavigationText"),
      textDecoration: "none",

      "&.MuiListItemButton-root.active": {
        color: getPaletteColor("common.white"),
        borderRadius: "6px",
        backgroundColor: getPaletteColor("primary.main"),
      },
    },
  },

  navMenuListClose: {
    width: navMenuItemClosedWidth,
  },

  navMenuItem: (theme) => ({
    ...navMenuItemBaseStyle,
    ...openedMixin(theme),
  }),

  navMenuItemDrawerClosed: (theme) => ({
    ...navMenuItemBaseStyle,
    ...closedMixin(theme),
    width: navMenuItemClosedWidth,

    "& .MuiListItemButton-root": {
      px: 0,
    },
  }),

  navMenuItemButtonText: {
    my: 0,
    pr: 2,

    "& .MuiListItemText-primary": {
      fontFamily: (theme) => theme.typography.fontFamilyReadexPro,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "24px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  },

  notificationMenuItem: (theme) => ({
    ...notificationMenuItemBaseStyle,
    ...openedMixin(theme),
  }),

  notificationMenuItemDrawerClosed: (theme) => ({
    ...notificationMenuItemBaseStyle,
    ...closedMixin(theme),
    ...notificationMenuItemDrawerClosedBaseStyle,
  }),

  notificationMenuItemActive: (theme) => ({
    ...notificationMenuItemBaseStyle,
    ...openedMixin(theme),
    ...notificationMenuItemActiveBaseStyle,
  }),

  notificationMenuItemDrawerClosedActive: (theme) => ({
    ...notificationMenuItemBaseStyle,
    ...closedMixin(theme),
    ...notificationMenuItemDrawerClosedBaseStyle,
    ...notificationMenuItemActiveBaseStyle,
  }),

  profileMenuItem: (theme) => ({
    ...profileMenuItemBaseStyle,
    ...openedMixin(theme),
  }),

  profileMenuItemClosed: (theme) => ({
    ...profileMenuItemBaseStyle,
    ...closedMixin(theme),
    width: navMenuItemClosedWidth,
  }),

  profileMenuIcon: {
    width: 30,
    height: 30,
  },

  profileMenuItemLogoutArrowIcon: {
    boxSizing: "content-box",
    borderRadius: "6px",
    width: 20,
    px: 1,
    py: "8.89px",
    ml: "2px",
    color: getPaletteColor("custom.globalNavigationText"),

    "&:hover": {
      ...navMenuItemHoverStyle,
    },

    "&.Mui-focusVisible": {
      outline: focusOutlineStyle,
    },

    "& .MuiTouchRipple-ripple .MuiTouchRipple-child": {
      borderRadius: 0,
    },
  },

  divider: (theme) => ({
    ...dividerBaseStyle,
    ...openedMixin(theme),
    ml: "auto",
    mr: "auto",
    // 100% width minus 4px left/right for padding
    width: "calc(100% - 8px)",
  }),

  dividerDrawerClosed: (theme) => ({
    ...dividerBaseStyle,
    ...closedMixin(theme),
    width: navMenuItemClosedWidth,
  }),

  drawerToggleMenuItem: (theme) => ({
    ...getDrawerToggleMenuItemBaseStyle(theme),
    ...openedMixin(theme),
  }),

  drawerToggleMenuItemDrawerClosed: (theme) => ({
    ...getDrawerToggleMenuItemBaseStyle(theme),
    ...closedMixin(theme),
    width: navMenuItemClosedWidth,
  }),
};

export default styles;
