import { MouseEvent, useEffect, useState } from "react";
import { NavLink, useMatch } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { TNavigationItem } from "../hooks/useNavigationItems";
import { default as globalNavigationStyles } from "../GlobalNavigation.styles";
import styles from "./CollapsibleNavListItem.styles";

export type TCollapsibleNavListItemProps = {
  navItem: TNavigationItem;
  isSidebarExpanded: boolean;
};

const CollapsibleNavListItem = ({
  navItem,
  isSidebarExpanded,
}: TCollapsibleNavListItemProps) => {
  const isRouteMatched = Boolean(useMatch(`${navItem.href}/*`));

  const [isCollapsed, setIsCollapsed] = useState(isRouteMatched);

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    setIsCollapsed(isRouteMatched);
  }, [isRouteMatched]);

  return (
    <ListItem key={navItem.key} sx={styles.listItem} disablePadding>
      <ListItemButton
        data-testid="left-hand-navigation-page-option"
        data-qaid="left-hand-navigation-page-option"
        component={NavLink}
        sx={globalNavigationStyles.navItem}
        aria-label={navItem.key}
        key={navItem.key}
        to={navItem.href}
      >
        <ListItemIcon>{navItem.icon}</ListItemIcon>
        {isSidebarExpanded && (
          <>
            <ListItemText
              primary={navItem.label}
              sx={globalNavigationStyles.navMenuItemButtonText}
            />
            <ListItemIcon onClick={handleClick}>
              {isCollapsed ? (
                <ExpandLess fontSize="small" />
              ) : (
                <ExpandMore fontSize="small" />
              )}
            </ListItemIcon>
          </>
        )}
      </ListItemButton>
      {isSidebarExpanded && (
        <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
          <List sx={styles.collapseMenuList} disablePadding>
            {navItem.items?.map((navItemsItem) => (
              <ListItem key={navItemsItem.key} sx={styles.collapseListItem}>
                <ListItemButton
                  data-testid={`left-hand-navigation-page-option-collapse-${navItemsItem.key}`}
                  data-qaid="left-hand-navigation-page-option-collapse"
                  component={NavLink}
                  aria-label={navItemsItem.key}
                  to={navItemsItem.href}
                  disabled={navItemsItem.disabled}
                  sx={styles.collapsibleListItemButton}
                >
                  <ListItemText>
                    <Typography
                      sx={styles.collapsibleListItemButtonText}
                      variant="subtitle2"
                    >
                      {navItemsItem.label}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </ListItem>
  );
};

export default CollapsibleNavListItem;
