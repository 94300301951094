import { useTranslation } from "react-i18next";
import { QueryObserverOptions, useQuery } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { IAssetRelationship } from "models/assets.models";
import { services } from "services";
import { MAX_SIZE, QueryAPIKey } from "utils/constants/api.constants";
import { AssetRelationship } from "utils/constants/assets.constants";

export default function useGetAssetRelationships(
  id: string,
  relationType: AssetRelationship = AssetRelationship.HasAttachment,
  options?: QueryObserverOptions,
) {
  const { t } = useTranslation("asset", {
    keyPrefix: "notifications",
  });

  const { data, status, isLoading, isError, isSuccess, error } = useQuery<
    IAssetRelationship[],
    Error
  >(
    [QueryAPIKey.GetAssetRelationships, id],
    () =>
      services.assets
        .getAssetRelationships(id, {
          relationshipType: relationType,
          size: MAX_SIZE,
        })
        .then((res) => res.data.content),
    {
      enabled: !!id && (options?.enabled ?? true),
    },
  );

  useShowAlert({
    status,
    failureMessage: t("getAssetFailure"),
  });

  return {
    asset: data,
    status,
    error,
    isLoading,
    isError,
    isSuccess,
  };
}
