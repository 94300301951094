export enum SubmissionPlan {
  Submitting = "SUBMITTING",
  NotSubmitting = "NOT_SUBMITTING",
}

export enum ApplicationType {
  AbbreviatedNewDrug = "ABBREVIATED_NEW_DRUG_APPLICATION",
  BiologicLicenseApplication = "BIOLOGIC_LICENSE_APPLICATION",
  NewDrugApplication = "NEW_DRUG_APPLICATION",
  MarketingAuthorisation = "MARKETING_AUTHORISATION_APPLICATION",
  RegistrationDossier = "REGISTRATION_DOSSIER",
  NewDrugSubmission = "NEW_DRUG_SUBMISSION_SUPPLEMENT_TO_NEW_DRUG_SUBMISSION",
}

export enum ProposedApprovalPathway {
  AcceleratesApproval = "ACCELERATED_APPROVAL",
  BreakthroughDesignation = "BREAKTHROUGH_DESIGNATION",
  FastTrack = "FAST_TRACK",
  Priority = "PRIORITY",
  PriorityReview = "PRIORITY_REVIEW",
  ProvisionalApproval = "PROVISIONAL_APPROVAL",
  Rdc2042017 = "RDC_204_2017",
  Rdc20542017 = "RDC_2054_2017",
  AdvancedTherapyProducts = "ADVANCED_THERAPY_PRODUCTS",
  ExtraordinaryUseNewDrugs = "EXTRAORDINARY_USE_NEW_DRUGS",
  NoticeOfComplianceWithConditions = "NOTICE_OF_COMPLIANCE_WITH_CONDITIONS",
  AbridgedEvaluation = "ABRIDGED_EVALUATION",
  FullEvaluation = "FULL_EVALUATION",
  VerificationCecaEvaluation = "VERIFICATION_CECA_EVALUATION",
  VerificationEvaluation = "VERIFICATION_EVALUATION",
  Art13TherapeuticProducts = "ART_13_THERAPEUTIC_PRODUCTS",
  FastTrackAuthorization = "FAST_TRACK_AUTHORIZATION",
  ProcedureWithPriorNotification = "PROCEDURE_WITH_PRIOR_NOTIFICATION",
  TemporaryAuthorization = "TEMPORARY_AUTHORIZATION",
  DayAssessment = "150_DAY_ASSESSMENT",
  Conditional = "CONDITIONAL",
  DcpMrpReliance = "DCP_MRP_RELIANCE",
  EcDecisionReliance = "EC_DECISION_RELIANCE",
  ExceptionalCircumstances = "EXCEPTIONAL_CIRCUMSTANCES",
  InnovativeLicensingAndAccess = "INNOVATIVE_LICENSING_AND_ACCESS",
  PromisingInnovativeMedicine = "PROMISING_INNOVATIVE_MEDICINE",
  RollingReview = "ROLLING_REVIEW",
  UnfetteredAccess = "UNFETTERED_ACCESS",
}

export enum ProposedTimeline {
  Priority = "PRIORITY",
  Standard = "STANDARD",
}

export enum InvitationStatus {
  Accepted = "ACCEPTED",
  Pending = "PENDING",
}

export enum InvitationReviewType {
  Priority = "PRIORITY",
  Conditional = "CONDITIONAL",
}

export enum ReviewerParticipationType {
  TypeA = "TYPE_A_REGULAR",
  TypeB = "TYPE_B_MODIFIED",
  TypeC = "TYPE_C_WRITTEN_REPORT",
  NotParticipating = "NOT_PARTICIPATING",
}
