import { TFunction } from "i18next";
import { z } from "zod";
import { TProductVariant } from "../Products.types";
import {
  ProductVariantFieldName,
  TProductVariantForm,
} from "./ProductVariantFormFields.types";

export const createProductVariantFormValues = (
  variant?: TProductVariant,
): TProductVariantForm => ({
  [ProductVariantFieldName.ProductType]: variant?.productType?.code ?? "",
  [ProductVariantFieldName.RouteOfAdministration]:
    variant?.routeOfAdministrationType?.code ?? "",
  [ProductVariantFieldName.ManufacturedDoseForm]:
    variant?.manufacturedDoseFormType?.code ?? "",
  [ProductVariantFieldName.StrengthValue]: variant?.strengthValue ?? "",
  [ProductVariantFieldName.StrengthType]: variant?.strengthType?.code ?? "",
  [ProductVariantFieldName.StrengthVolumeValue]:
    variant?.strengthVolumeValue ?? "",
  [ProductVariantFieldName.StrengthVolumeType]:
    variant?.strengthVolumeType?.code ?? "",
  [ProductVariantFieldName.Status]: variant?.status ?? "",
});

export const createProductVariantFormSchema = (
  t: TFunction,
  isEditMode: boolean,
) =>
  z.object({
    [ProductVariantFieldName.ProductType]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductVariantFieldName.RouteOfAdministration]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductVariantFieldName.ManufacturedDoseForm]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductVariantFieldName.StrengthValue]: z.coerce
      .number({
        invalid_type_error: t("validations.invalidNumber", { ns: "common" }),
      })
      .positive({
        message: t("validations.requiredField", { ns: "common" }),
      })
      .transform((arg) => arg.toString()),
    [ProductVariantFieldName.StrengthType]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductVariantFieldName.StrengthVolumeValue]: z
      .union([
        z.coerce
          .number({
            invalid_type_error: t("validations.invalidNumber", {
              ns: "common",
            }),
          })
          .positive()
          .transform((arg) => arg.toString()),
        z.undefined(),
        z.string().length(0, {
          message: t("validations.invalidNumber", {
            ns: "common",
          }),
        }),
      ])
      .optional(),
    [ProductVariantFieldName.StrengthVolumeType]: z.string().optional(),
    [ProductVariantFieldName.Status]: !isEditMode
      ? z.string().min(1, {
          message: t("validations.requiredField", { ns: "common" }),
        })
      : z.string().optional(),
  });
