import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

type TDeleteAssetVersionMutationData = {
  id: string;
  version: string;
};

const useDeleteAssetVersion = () => {
  const queryClient = useQueryClient();

  const { t } = useTranslation("documents", {
    keyPrefix: "notifications",
  });

  const deleteAssetVersionMutation = useMutation(
    ({ id, version }: TDeleteAssetVersionMutationData) =>
      services.assets.deleteAssetVersion(id, version).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryAPIKey.GetAssets);
      },
    },
  );

  useShowAlert({
    status: deleteAssetVersionMutation.status,
    failureMessage: t("deleteAssetVersionFailure"),
    successMessage: t("deleteAssetVersionSuccess"),
  });

  return deleteAssetVersionMutation;
};

export default useDeleteAssetVersion;
