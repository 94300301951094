import { TUserExtendedData } from "models/users.models";
import { Role } from "utils/constants/users.constants";

export interface IUsersDataResponse {
  users: TUserExtendedData[];
}

export enum RoleScope {
  System = "SYSTEM",
  Project = "PROJECT",
  S2S = "S2S",
}

export type TUsersParams = {
  roleScope?: RoleScope[];
  roleIds?: Role[];
};
