import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse, IconButton, Stack, Typography } from "@mui/material";
import { IDocumentOutline } from "models/documents.models";
import { useDocumentSidebarContext } from "screens/Project/sections/Documents/Document";
import { getDisplayedAssetStatus } from "utils/helpers";
import styles from "./OutlineSection.styles";

type TOutlineSectionProps = {
  section: IDocumentOutline;
  depth: number;
  index: string;
  onItemClick: (outline: IDocumentOutline) => void;
};

const OutlineSection = (props: TOutlineSectionProps) => {
  const [isOpened, setIsOpened] = useState(true);

  const { isExpanded, shouldShowStatus, shouldShowVersionNumber } =
    useDocumentSidebarContext();

  const { t } = useTranslation(["documents", "common", "statuses"]);

  useEffect(() => {
    setIsOpened(isExpanded);
  }, [isExpanded, setIsOpened]);

  const isLeafNode = !props.section.children.length;
  const isTopLevelNode = props.depth === 0;
  const ExpandIcon = isOpened ? ExpandLess : ExpandMore;
  const headerTypography = isTopLevelNode ? "subtitle2" : "helperText";

  const latestVersion = props.section.latestVersion?.version;

  return (
    <Box sx={isTopLevelNode ? styles.topLevelSection : styles.section}>
      <Box sx={{ ml: props.depth * 2, ...styles.headerNode }}>
        <Stack direction="row" spacing={1}>
          <Typography variant={headerTypography}>
            {isTopLevelNode ? `${props.index}.` : props.index}
          </Typography>
          <Stack direction="column" spacing={0.5}>
            <Typography variant={headerTypography}>
              <Box
                sx={styles.name}
                onClick={() => props.onItemClick(props.section)}
              >
                {props.section.name}
              </Box>
            </Typography>
            <Stack direction="row" spacing={1}>
              {shouldShowVersionNumber && latestVersion && (
                <Typography variant="body1" sx={styles.sectionStatus}>
                  v{latestVersion}
                </Typography>
              )}
              {shouldShowStatus && (
                <Typography variant="body1" sx={styles.sectionStatus}>
                  {getDisplayedAssetStatus(
                    props.section.metadata.componentType,
                    props.section.status,
                    t,
                  )}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
        {!isLeafNode && isTopLevelNode && (
          <IconButton
            data-testid="expand-button"
            data-qaid="expand-button"
            disableRipple
            disableFocusRipple
            size="small"
            color="inherit"
            aria-expanded={isOpened}
            aria-label={t("ariaLabels.expand", { ns: "common" })}
            sx={styles.expandButton}
            onClick={() => setIsOpened((prevState) => !prevState)}
          >
            <ExpandIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
      {!isLeafNode && (
        <Collapse in={isOpened} timeout="auto" unmountOnExit={false}>
          {props.section.children?.map(
            (nestedSection: IDocumentOutline, index) => (
              <OutlineSection
                index={`${props.index}.${index + 1}`}
                key={nestedSection.id}
                section={nestedSection}
                depth={props.depth + 1}
                onItemClick={props.onItemClick}
              />
            ),
          )}
        </Collapse>
      )}
    </Box>
  );
};

export default OutlineSection;
