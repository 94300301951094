import { useState } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import { TMenuOption } from "components/shared/ButtonMenu";
import { TSelectedHAQTableRow } from "components/shared/types";
import useFeatureFlags from "context/FeatureFlagsContext/useFeatureFlags";
import { useProject } from "context/ProjectContext";
import usePermissions from "hooks/permissions";
import { useUserRoles } from "hooks/user";
import useUser from "hooks/useUser";
import { useHAQContext } from "screens/Project/screens/HAQ/HAQContext/HAQContextProvider";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import {
  HAQAction,
  HAQBulkActionType,
  HAQStatus,
} from "utils/constants/HAQ.constants";
import {
  getIsAssetCreatedByUser,
  getIsReferenceOrExternalHAQ,
} from "utils/helpers";
import { isHAUser, isSponsorUser } from "utils/user/organization";
import {
  getPossibleStatusChangeMenuOptionsForHAV1,
  getPossibleStatusChangeMenuOptionsForSponsorV1,
  getPossibleStatusChangeMenuOptionsForSponsorV3,
} from "./useChangeStatusAction.utils";
import { useHandleActionClickV1 } from "./useHandleActionClickV1";
import { useHandleActionClickV2 } from "./useHandleActionClickV2";

type TUseChangeStatusAction = {
  selectedRows: TSelectedHAQTableRow[];
  isAnyHAQCopied: boolean;
  shouldEnableTrackingEvent?: boolean;
};

export const useChangeStatusAction = ({
  selectedRows,
  isAnyHAQCopied,
  shouldEnableTrackingEvent,
}: TUseChangeStatusAction) => {
  const { user, organization } = useUser();
  const { project } = useProject();
  const { permissions } = usePermissions(project?.id ?? "");
  const { isProjectManager } = useUserRoles(project?.id);
  const { t } = useTranslation(["projects", "HAQ", "asset", "common"]);
  const featureFlags = useFeatureFlags();

  const { isAnyWorkflowInProgress, setIsAnyWorkflowInProgress } =
    useHAQContext();

  const shouldUseHAQActionsV2 =
    featureFlags["FTE-13049_enableHAQActionsV2"] === FeatureFlag.On;

  const shouldUseHAQChangeStatusV3 =
    featureFlags["FTE-30742_enableHAQChangeStatusV3"] === FeatureFlag.On;

  const [changeStatusModal, setChangeStatusModal] = useState(<></>);

  const handleActionClickV1 = useHandleActionClickV1(
    selectedRows,
    setChangeStatusModal,
    setIsAnyWorkflowInProgress,
    shouldEnableTrackingEvent,
  );

  const handleActionClickV2 = useHandleActionClickV2(
    selectedRows,
    setChangeStatusModal,
    setIsAnyWorkflowInProgress,
    shouldEnableTrackingEvent,
  );

  const possibleStatusChangeMenuOptionsForSponsorV1 =
    getPossibleStatusChangeMenuOptionsForSponsorV1({
      t,
      organization,
      isProjectManager,
      handleClick: handleActionClickV1,
    });

  const possibleStatusChangeMenuOptionsForSponsorV3 =
    getPossibleStatusChangeMenuOptionsForSponsorV3({
      t,
      organization,
      isProjectManager,
      handleClick: handleActionClickV2,
    });

  const possibleStatusChangeMenuOptionsForHAV1 =
    getPossibleStatusChangeMenuOptionsForHAV1({
      selectedRows,
      t,
      organization,
      isProjectManager,
      handleClick: (status: HAQStatus) =>
        handleActionClickV1({
          actionType: HAQBulkActionType.QuestionOnly,
          status,
        }),
      isAnyHAQCopied,
    });

  const possibleStatusChangeMenuOptionsV1 = isSponsorUser(organization)
    ? possibleStatusChangeMenuOptionsForSponsorV1
    : possibleStatusChangeMenuOptionsForHAV1;

  const isAnyHAQReferenceOrExternal = Boolean(
    selectedRows.find((row) =>
      getIsReferenceOrExternalHAQ(row.HAQ.metadata?.importType),
    ),
  );

  const hasSponsorChangeStatusPermissions = isAnyHAQReferenceOrExternal
    ? permissions.canChangeReferenceOrExternalQuestionStatus
    : permissions.canChangeQuestionResponseStatus;

  const isAllHAQOwnedByUser = selectedRows.every((row) =>
    getIsAssetCreatedByUser(row.HAQ, user),
  );

  const hasHAChangeStatusPermission = !isAllHAQOwnedByUser
    ? permissions.canChangeQuestionStatus
    : permissions.canChangeQuestionOwnedStatus;

  const hasChangeStatusPermissions = isHAUser(organization)
    ? hasHAChangeStatusPermission
    : hasSponsorChangeStatusPermissions;

  const isSponsorUsingNewActions =
    (shouldUseHAQActionsV2 || shouldUseHAQChangeStatusV3) &&
    isSponsorUser(organization);

  const isShown =
    !isEmpty(selectedRows) &&
    project?.active &&
    hasChangeStatusPermissions &&
    (isSponsorUsingNewActions || !isEmpty(possibleStatusChangeMenuOptionsV1)) &&
    (isSponsorUser(organization) || !isAnyHAQCopied);

  let extraProps: { items?: TMenuOption[] } | { onClick: () => Promise<void> };

  if (shouldUseHAQChangeStatusV3 && isSponsorUser(organization)) {
    extraProps = { items: possibleStatusChangeMenuOptionsForSponsorV3 };
  } else if (shouldUseHAQActionsV2 && isSponsorUser(organization)) {
    extraProps = { onClick: () => handleActionClickV2() };
  } else {
    extraProps = { items: possibleStatusChangeMenuOptionsV1 };
  }

  return {
    changeStatusAction: isShown && {
      id: HAQAction.ChangeStatus,
      label: t("HAQs.actionOptionsChangeStatusLabel"),
      disabled: isAnyWorkflowInProgress,
      ...extraProps,
    },
    changeStatusModal,
  };
};
