import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const formControlLabelStyles: TStyles[keyof TStyles] = {
  display: "flex",
  justifyContent: "center",
  width: 200,
  height: 163,
  margin: 0,
  padding: "0 7px",
  boxShadow: (theme) => `0px 0px 0px 1px ${getPaletteColor("grey.300")(theme)}`,

  // Invisible border at unchecked state
  // so it does not affect width/height when checked
  border: "2px solid transparent",
  borderRadius: 2,
};

const styles: TStyles = {
  formControlLabel: formControlLabelStyles,
  checkedFormControlLabel: {
    ...formControlLabelStyles,
    borderColor: getPaletteColor("primary.main"),
  },

  radioButton: {
    padding: 0,
    position: "absolute",
    opacity: 0,

    "&:hover": {
      backgroundColor: "transparent",
    },

    "&.MuiButtonBase-root.Mui-focusVisible:before": {
      boxShadow: "none",
    },
  },

  radioLabel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },

  description: {
    color: getPaletteColor("text.secondary"),
  },
};

export default styles;
