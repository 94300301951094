import { Chip, Tooltip } from "@mui/material";
import { ICheckboxOption } from "../CheckboxTree/CheckboxTree.utils";
import styles from "./SelectionChip.styles";

type TSelectionChipProps = {
  chip: ICheckboxOption;
  onRemove?: (chip: ICheckboxOption) => void;
};

const SelectionChip = ({ chip, onRemove }: TSelectionChipProps) => {
  const computedStyle = {
    ...styles.chip,
    ...(!onRemove && styles.unremovableChip),
  };

  return (
    <Tooltip placement="right" title={chip.label}>
      <Chip
        size="small"
        label={chip.label}
        sx={computedStyle}
        onDelete={onRemove ? () => onRemove(chip) : undefined}
      />
    </Tooltip>
  );
};

export default SelectionChip;
