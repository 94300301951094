import { useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import DocumentHeader from "components/common/Document/DocumentHeader";
import AnnotationUIProvider from "components/common/Editor/AnnotationUIContext/AnnotationUIContext";
import EditorContext from "components/common/Editor/EditorContext/EditorContext";
import { AddHAQDrawer } from "components/common/HAQ";
import Loading from "components/shared/Loading";
import useGetDocument from "hooks/api/REST/documents/useGetDocument";
import { IDocumentOutline, TDocumentViewMode } from "models/documents.models";
import { QueryAPIKey } from "utils/constants/api.constants";
import {
  DocumentAction,
  DocumentViewMode,
} from "utils/constants/doc.constants";
import { INITIAL_PAGINATION_PARAMS } from "utils/constants/table.constants";
import { filterTree } from "utils/helpers";
import { TDocumentComponent } from "./Document.types";
import StartReviewModal from "./DocumentActionModals/StartReviewModal";
import {
  DocumentContextProvider,
  useDocumentContext,
} from "./DocumentContext/DocumentContext";
import DocumentSidebar from "./DocumentSidebar";
import EditorBar from "./EditorBar";
import {
  BulkStatusChangeModal,
  DeleteVersion,
  SendCopyComponents,
} from "./modals";
import { checkIfPossibleToSendCopyComponent } from "./modals/SendCopyComponents/SendCopyComponents.utils";
import ObservedDocumentComponent from "./ObservedDocumentComponent";
import { mapOutlineToSkeletonComponents } from "./ObservedDocumentComponent/mapOutlineToSkeletonComponents";
import { useDocumentHeaderActions } from "./useDocumentHeaderActions";
import styles from "./Document.styles";

export type TDocumentAction = {
  component: TDocumentComponent | null;
  type: DocumentAction | null;
};

const Document = () => {
  const { documentId, projectId } = useParams();
  const queryClient = useQueryClient();

  const [scrollTargetComponentId, setScrollTargetComponentId] = useState<
    string | null
  >(null);

  const sidebarRef = useRef<HTMLDivElement>(null);

  const documentComponentsWrapperRef = useRef<HTMLDivElement>(null);

  const [isSidebarMounted, setIsSidebarMounted] = useState(false);

  const [activeAction, setActiveAction] = useState<TDocumentAction>({
    component: null,
    type: null,
  });

  const [viewMode, setViewMode] = useState<TDocumentViewMode>(
    DocumentViewMode.Viewing,
  );

  const { document, isLoading: isLoadingDocument } = useGetDocument(
    documentId ?? "",
    {
      params: { ...INITIAL_PAGINATION_PARAMS, format: "FULL" },
    },
  );

  const documentOutlineForSendCopyComponents = useMemo(
    () =>
      filterTree(document?.children, (section: IDocumentOutline) =>
        checkIfPossibleToSendCopyComponent(section.status),
      ),
    [document],
  );

  const { actionOptions } = useDocumentHeaderActions(
    setActiveAction,
    Boolean(documentOutlineForSendCopyComponents?.length),
    document?.status,
    document?.metadata?.cleared,
  );

  const invalidateDocumentQueries = () => {
    queryClient.invalidateQueries(QueryAPIKey.GetDocument);
  };

  const { editingComponent } = useDocumentContext();

  const documentsItems = useMemo(() => {
    if (document?.children) {
      return mapOutlineToSkeletonComponents(document?.children).sort(
        (a, b) => a.order - b.order,
      );
    } else {
      return [];
    }
  }, [document]);

  if (isLoadingDocument) {
    return <Loading />;
  }

  const resetActiveAction = () => {
    setActiveAction({
      component: null,
      type: null,
    });
  };

  const handleModalClose = (
    shouldInvalidateDocumentQueries: boolean = false,
  ) => {
    resetActiveAction();

    if (shouldInvalidateDocumentQueries) {
      invalidateDocumentQueries();
    }
  };

  return (
    <Box sx={styles.document}>
      <DocumentHeader
        viewMode={viewMode}
        onViewModeChange={setViewMode}
        actionOptions={actionOptions}
        documentName={document?.name}
        isActionsDisabled={Boolean(editingComponent.component)}
      />
      <EditorBar />
      <Stack direction="row" sx={styles.documentContainer}>
        <Box ref={documentComponentsWrapperRef} sx={styles.documentContent}>
          <EditorContext
            sidebarRef={sidebarRef}
            isLayoutReady={isSidebarMounted}
          >
            {documentsItems.map((component) => (
              <ObservedDocumentComponent
                key={component.assetId}
                component={component}
                setActiveAction={setActiveAction}
                viewMode={viewMode}
                scrollTargetComponentId={scrollTargetComponentId}
              />
            ))}
          </EditorContext>
        </Box>
        <DocumentSidebar
          onSidebarMount={setIsSidebarMounted}
          outline={document?.children}
          onItemClick={(outlineSection) => {
            setScrollTargetComponentId(outlineSection.id);
          }}
          ref={sidebarRef}
        />
      </Stack>
      <StartReviewModal
        isOpen={activeAction.type === DocumentAction.StartSponsorReview}
        onClose={handleModalClose}
        documentOutline={document?.children}
      />
      <DeleteVersion
        isOpen={activeAction.type === DocumentAction.DeleteVersions}
        contentToDelete={activeAction.component}
        onClose={handleModalClose}
      />
      <SendCopyComponents
        isOpen={activeAction.type === DocumentAction.SendCopy}
        component={activeAction.component}
        onClose={handleModalClose}
        documentOutline={documentOutlineForSendCopyComponents}
      />
      <BulkStatusChangeModal
        isOpen={activeAction.type === DocumentAction.ChangeStatus}
        outline={document?.children}
        onClose={handleModalClose}
      />
      <AddHAQDrawer
        isOpen={Boolean(activeAction.type === DocumentAction.AddQuestion)}
        onClose={handleModalClose}
        projectId={projectId || ""}
        parent={activeAction.component || document}
      />
    </Box>
  );
};

export default () => (
  <DocumentContextProvider>
    <AnnotationUIProvider>
      <Document />
    </AnnotationUIProvider>
  </DocumentContextProvider>
);
