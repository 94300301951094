import {
  ChevronRight as CollapsedRowIcon,
  ExpandMore as ExpandedRowIcon,
} from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";
import { Row } from "@tanstack/react-table";

export type TExpandCollapseIconProps<T extends object> = SvgIconProps & {
  row: Row<T>;
};

function ExpandCollapseIcon<T extends object>({
  row,
  ...svgIconProps
}: TExpandCollapseIconProps<T>) {
  const IconComponent = row.getIsExpanded()
    ? ExpandedRowIcon
    : CollapsedRowIcon;

  return <IconComponent {...svgIconProps} />;
}

export default ExpandCollapseIcon;
