import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { services } from "services";

export default function useLockAssetEditing() {
  const { t } = useTranslation("documents");

  const { status, error, isLoading, isError, isSuccess, mutate } = useMutation(
    (assetId: string) =>
      services.documents.lockAssetEditing(assetId).then((res) => res.data),
  );

  useShowAlert({
    status,
    failureMessage: t("documentComponent.documentComponentEditingNotAllowed"),
  });

  return {
    isEditingAllowed: isSuccess,
    error,
    isLoading,
    isError,
    isSuccess,
    lockAssetEditing: mutate,
  };
}
