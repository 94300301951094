import { IDocumentOutline } from "models/documents.models";
import { DocumentStatus } from "utils/constants/doc.constants";

export type TFlattenedOutline = Omit<IDocumentOutline, "status"> & {
  index: string;
  status: DocumentStatus;
};

export const flattenOutlineOptions = (
  outlines: IDocumentOutline[],
  indexString: string,
): TFlattenedOutline[] => {
  let skeletonComponentsList: TFlattenedOutline[] = [];

  outlines.forEach((outline, index) => {
    const newIndexString = indexString
      ? `${indexString}.${index + 1}`
      : `${index + 1}`;

    skeletonComponentsList.push({
      ...outline,
      status: outline.status as unknown as DocumentStatus,
      index: newIndexString,
    });

    if (Array.isArray(outline.children)) {
      skeletonComponentsList = skeletonComponentsList.concat(
        flattenOutlineOptions(outline.children, newIndexString),
      );
    }
  });

  return skeletonComponentsList;
};
