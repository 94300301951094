import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import dayjs from "dayjs";
import useTasks from "hooks/api/REST/tasks/useTasks";
import useTriggerTaskEvent from "hooks/api/REST/tasks/useTriggerTaskEvent";
import { TUseTriggerTaskEvent } from "hooks/api/REST/tasks/useTriggerTaskEvent";
import { TSubmitDocumentForm } from "screens/Project/sections/Documents/modals/SubmitDocuments/SubmitDocuments.types";
import { QueryAPIKey } from "utils/constants/api.constants";
import { ISO_DATE_FORMAT } from "utils/constants/format.constants";
import {
  TaskEvent,
  TaskStatus,
  TaskType,
} from "utils/constants/tasks.constants";
import { TISODateFormat } from "utils/types";

export default function useSubmitGSP(projectId: string | undefined) {
  const queryClient = useQueryClient();

  const { t } = useTranslation("documents", {
    keyPrefix: "documents.submitDocuments.submitForm",
  });

  const { tasks, isSuccess: tasksSuccessfullyLoaded } = useTasks({
    params: {
      includeTypes: TaskType.SubmitGSP,
      onlyMyTasks: false,
      content: {
        project: projectId,
      },
    },
  });

  const { triggerTaskEvent } = useTriggerTaskEvent(
    t("submitSuccess"),
    t("submitFailure"),
  );

  const pendingGSPSubmitTask = useMemo(() => {
    return tasks?.find(
      (task) =>
        ![TaskStatus.Complete, TaskStatus.Cancelled].includes(task.status),
    );
  }, [tasks]);

  const submitGSP = useCallback(
    (data: TSubmitDocumentForm) => {
      const safeId = pendingGSPSubmitTask?.id || "";

      const payload: TUseTriggerTaskEvent = {
        id: safeId,
        data: {
          event: TaskEvent.Submit,
          input: {
            targetTenants: data.recipients.map((recipient) => recipient.value),
            supportingAssets: data.documents.map((doc) => doc.id),
            // @TODO: Default due date for one year for now - To be deleted when ironed out in workflows
            dueDate: dayjs()
              .add(1, "year")
              .format(ISO_DATE_FORMAT) as TISODateFormat,
            message: data.message,
          },
        },
      };

      return triggerTaskEvent(payload, {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryAPIKey.GetDocuments);
        },
      });
    },
    [pendingGSPSubmitTask, queryClient, triggerTaskEvent],
  );

  return { submitGSP, tasksSuccessfullyLoaded };
}
