import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { TCheckboxGroupOption } from "components/shared/CheckboxGroup";
import {
  DEFAULT_TIME_FORMAT,
  SHORT_DATE_FORMAT,
} from "utils/constants/format.constants";
import { formatDate } from "utils/helpers";
import styles from "./VersionOption.styles";

export type TVersionOption = TCheckboxGroupOption & {
  latestVersion?: boolean;
  createdDate?: string;
};

type TVersionOptionComponent = {
  item: TVersionOption;
};

const VersionOption = ({ item }: TVersionOptionComponent) => {
  const { t } = useTranslation(["documents", "common"]);

  const versionCreatedDate = useMemo(() => {
    return t("deleteVersionModal.versionOptionCreatedDate", {
      date: formatDate(item.createdDate, SHORT_DATE_FORMAT),
      time: formatDate(item.createdDate, DEFAULT_TIME_FORMAT),
    });
  }, [item.createdDate, t]);

  return (
    <>
      <Box sx={styles.option}>
        <Typography sx={styles.version}>{item.label}</Typography>
        {item.latestVersion ? (
          <Typography sx={styles.latest}>
            {t("deleteVersionModal.latest")}
          </Typography>
        ) : null}
        <Typography sx={styles.createdDate}>{versionCreatedDate}</Typography>
        {item.disabled ? (
          <Typography sx={styles.disabledInformation}>
            {t("deleteVersionModal.canNotBeDeleted")}
          </Typography>
        ) : null}
      </Box>
    </>
  );
};

export default VersionOption;
