import axios from "axios";
import * as qs from "qs";

export const client = axios.create({
  paramsSerializer: (params: any) =>
    qs.stringify(params, { arrayFormat: "comma" }),
  headers: {
    "Content-Type": "application/json",
  },
});
