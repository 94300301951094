import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const endIconStyles = {
  width: 50,
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
};

const actionsButtonStyles = {
  width: 153,
  height: 42,
  justifyContent: "space-between",
  p: "0 0 0 16px",
};

const styles: TStyles = {
  actionsButton: {
    ...actionsButtonStyles,

    "& .MuiButton-endIcon": {
      ...endIconStyles,
      backgroundColor: getPaletteColor("primary.400"),
      transition: (theme) =>
        theme.transitions.create("background-color", {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.short,
        }),
    },

    "&:hover .MuiButton-endIcon": {
      backgroundColor: getPaletteColor("primary.500"),
    },
  },

  disabledActionsButton: {
    ...actionsButtonStyles,

    "& .MuiButton-endIcon": endIconStyles,
  },
};

export default styles;
