import { AxiosResponse } from "axios";
import { get, post, put, routes } from "api";
import {
  IGetTasksRequestParams,
  ITriggerTaskEventRequestData,
  IUpdateTaskRequestData,
} from "models/tasks.models";

const tasks = {
  getTasks(
    params?: IGetTasksRequestParams,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return get(routes.workflows.tasks.all(), {
      params,
      isMockApiOn,
    });
  },

  getTask(id: string): Promise<AxiosResponse> {
    return get(routes.workflows.tasks.byId(id));
  },

  updateTask(
    id: string,
    data: IUpdateTaskRequestData,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return put(routes.workflows.tasks.byId(id), { data, isMockApiOn });
  },

  triggerTaskEvent(id: string, data: ITriggerTaskEventRequestData) {
    return post(routes.workflows.tasks.events(id), {
      data,
    });
  },
};

export default tasks;
