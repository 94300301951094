import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  FormWrapper,
  Modal,
  ModalActionButtons,
} from "components/shared";
import useUpdateProductEntity from "hooks/api/GQL/entityManagement/useUpdateProductEntity";
import ProductEntityFormFields from "../ProductEntityFormFields/ProductEntityFormFields";
import { TProductEntityForm } from "../ProductEntityFormFields/ProductEntityFormFields.types";
import { TEntityProduct } from "../Products.types";
import {
  getCreateProductModalSchema,
  getFormValuesForExistingProduct,
  getProductValuesForUpdate,
} from "./UpdateProductModal.utils";

export type TUpdateProductModalProps = {
  isOpen: boolean;
  product?: TEntityProduct;
  onClose: () => void;
};

const UpdateProductModal = ({
  isOpen,
  product,
  onClose,
}: TUpdateProductModalProps) => {
  const { t } = useTranslation(["administration", "common"]);

  const { updateProduct } = useUpdateProductEntity();

  const defaultValues = useMemo(
    () => getFormValuesForExistingProduct(product),
    [product],
  );

  const methods = useForm<TProductEntityForm>({
    mode: "all",
    defaultValues: defaultValues,
    resolver: zodResolver(getCreateProductModalSchema(t, false)),
  });

  const {
    formState: { isDirty, isValid },
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const closeModal = () => {
    reset();
    onClose();
  };

  const onSubmit = (values: TProductEntityForm) => {
    if (product) {
      updateProduct(getProductValuesForUpdate(product, values)).then(onClose);
    }
  };

  return (
    <Modal
      maxWidth="lg"
      open={isOpen}
      title={t("updateProductModalTitle", { ns: "administration" })}
    >
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <ProductEntityFormFields isEditMode />
        <ModalActionButtons>
          <Button
            data-qaid="create-product-entity-cancel-button"
            key="cancel"
            variant="text"
            onClick={closeModal}
          >
            {t("button.cancel", { ns: "common" })}
          </Button>
          <Button
            key="submit-button"
            data-qaid="create-product-entity-submit-button"
            variant="contained"
            disabled={!isValid || !isDirty}
            type="submit"
          >
            {t("button.submit", { ns: "common" })}
          </Button>
        </ModalActionButtons>
      </FormWrapper>
    </Modal>
  );
};

export default UpdateProductModal;
