import { ForwardedRef, forwardRef } from "react";
import {
  SxProps,
  Tabs as MuiTabs,
  TabsProps as MUITabsProps,
  Theme,
} from "@mui/material";
import styles from "./Tabs.style";

type TTabsProps = MUITabsProps & {
  sx?: SxProps<Theme>;
};

const Tabs = forwardRef(function Tabs(
  { sx, ...props }: TTabsProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const tabsStyles = {
    ...styles.tabs,
    ...sx,
  };

  return (
    <MuiTabs
      ref={ref}
      sx={tabsStyles}
      {...props}
      variant="scrollable"
      scrollButtons="auto"
    >
      {props.children}
    </MuiTabs>
  );
});

export default Tabs;
