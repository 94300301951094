import { TFunction } from "i18next";
import { TSelectedHAQTableRow } from "components/shared/types";
import { IOrganization } from "models/organizations.models";
import { getDisplayedAssetStatus } from "utils/helpers";
import { getCopiedDate } from "utils/helpers/asset/getIsAssetCopied/getIsAssetCopied";
import {
  StatusChangeDeniedReason,
  TActionAllowable,
} from "utils/helpers/HAQ/getHAQStatusChangeOptions";
import { isHAUser } from "utils/user/organization";

export function getHAQSecondaryText(
  canHAQTransitionToStatus: TActionAllowable<StatusChangeDeniedReason>,
  row: TSelectedHAQTableRow,
  organization: IOrganization | undefined,
  t: TFunction,
) {
  if (!canHAQTransitionToStatus.isAllowed) {
    if (
      canHAQTransitionToStatus.reason === StatusChangeDeniedReason.NoContent
    ) {
      return t("HAQ:manualStatusChange.questionEmpty");
    }

    if (
      canHAQTransitionToStatus.reason === StatusChangeDeniedReason.AlreadyCopied
    ) {
      return t(
        isHAUser(organization)
          ? "HAQ:manualStatusChange.alreadyCopied"
          : "HAQ:manualStatusChange.alreadySubmitted",
        { date: getCopiedDate(row.HAQ.shareTracking) },
      );
    }
  }

  return getDisplayedAssetStatus(row.HAQ.source?.tenantType, row.HAQ.status, t);
}

export function getHAQRSecondaryText(
  canHAQRTransitionToStatus: TActionAllowable<StatusChangeDeniedReason>,
  row: TSelectedHAQTableRow,
  t: TFunction,
) {
  if (!canHAQRTransitionToStatus.isAllowed) {
    if (
      canHAQRTransitionToStatus.reason === StatusChangeDeniedReason.NoContent
    ) {
      return t("HAQ:manualStatusChange.responseEmpty");
    }

    if (
      canHAQRTransitionToStatus.reason ===
      StatusChangeDeniedReason.AlreadyCopied
    ) {
      return t("HAQ:manualStatusChange.alreadySubmitted", {
        date: getCopiedDate(row.HAQResponse?.shareTracking),
      });
    }
  }

  return getDisplayedAssetStatus(
    row.HAQResponse?.source?.tenantType,
    row.HAQResponse?.status,
    t,
  );
}
