import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Link, useParams } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { TFunction } from "i18next";
import { Button } from "components/shared";
import Loading from "components/shared/Loading";
import ToastNotification, {
  TToastNotificationProps,
} from "components/shared/ToastNotification";
import useParseJob from "hooks/api/REST/assets/useParseJob";
import {
  TParseJobContext,
  useDocumentsContext,
} from "screens/Project/sections/Documents/DocumentsContext";
import {
  DEFAULT_POLLING_INTERVAL,
  QueryAPIKey,
} from "utils/constants/api.constants";
import { ParseJobStatus } from "utils/constants/doc.constants";

const getStatusMessage = (
  status: ParseJobStatus | undefined,
  t: TFunction,
): {
  icon?: TToastNotificationProps["icon"];
  severity?: TToastNotificationProps["severity"];
  message?: string;
} => {
  switch (status) {
    case ParseJobStatus.Processing: {
      return {
        icon: <Loading />,
        message: t("documentPolling.converting"),
      };
    }

    case ParseJobStatus.Completed: {
      return {
        severity: "success",
        message: t("documentPolling.convertingSuccess"),
      };
    }

    case ParseJobStatus.Failure: {
      return {
        severity: "error",
        message: t("documentPolling.convertingError"),
      };
    }

    default: {
      return {};
    }
  }
};

const DocumentParsingAlert = () => {
  const queryClient = useQueryClient();

  const { projectId } = useParams<{
    projectId: string;
  }>();

  const { t } = useTranslation(["documents", "common"]);

  const { parseJobContext, setParseJobContext } = useDocumentsContext();

  const { parseJob, isError: isErrorParseJob } = useParseJob(
    parseJobContext?.id,
    {
      refetchInterval: (data, query) =>
        data?.status === ParseJobStatus.Processing && !query?.state.error
          ? DEFAULT_POLLING_INTERVAL
          : false,
      onSuccess: (data) => {
        if (data?.status === ParseJobStatus.Completed) {
          queryClient.invalidateQueries(QueryAPIKey.GetDocuments);

          setParseJobContext({
            ...(parseJobContext as TParseJobContext),
            status: data?.status as ParseJobStatus,
          });
        }
      },
    },
  );

  const handleClose = () => {
    setParseJobContext(null);
  };

  const isParseCompleted = parseJob?.status === ParseJobStatus.Completed;

  const path = `/projects/${projectId}/${
    parseJobContext?.isImportedHAQ
      ? "HA-questions"
      : `documents/${parseJob?.parsedAssetId}`
  }`;

  return parseJobContext && !isErrorParseJob ? (
    <ToastNotification
      open
      onClose={isParseCompleted ? handleClose : () => {}}
      autoHideDuration={null}
      title={parseJobContext?.documentName || ""}
      action={
        <>
          {isParseCompleted && (
            <Button
              data-qaid="document-parsing-open-button"
              component={Link}
              size="small"
              to={path}
            >
              {t("button.open", { ns: "common" })}
            </Button>
          )}
          <IconButton
            size="small"
            aria-label={t("button.close", { ns: "common" })}
            color="inherit"
            onClick={handleClose}
          >
            <Close fontSize="small" />
          </IconButton>
        </>
      }
      {...getStatusMessage(parseJob?.status ?? ParseJobStatus.Processing, t)}
    />
  ) : null;
};

export default DocumentParsingAlert;
