import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  footer: {
    backgroundColor: getPaletteColor("blueGrey.50"),
    width: "100%",
    display: "flex",
    alignItems: "center",
    px: 2,
    py: 2,
    justifyContent: "flex-end",
  },

  actions: {
    display: "flex",
    columnGap: 1,
  },
};

export default styles;
