import { Theme } from "@mui/material";
import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const SIDEBAR_WIDTH = 400;
const SIDEBAR_TABLET_WIDTH = 300;

const getDocumentSidebarWidth = ({ tablet = false } = {}) =>
  tablet ? SIDEBAR_TABLET_WIDTH : SIDEBAR_WIDTH;

const sidebarPanelWidth = 50;

const sidebarPanelButton = {
  width: 44,
  height: 44,
  mt: 0.5,
};

const getSidebarStyles = (theme: Theme) => ({
  overflow: "hidden",
  flex: `0 0 auto`,
  width: getDocumentSidebarWidth(),
  transition: "margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",

  [theme.breakpoints.down("xl")]: {
    width: getDocumentSidebarWidth({ tablet: true }),
  },
});

const styles: TStyles = {
  sidebarWide: (theme) => ({
    ...getSidebarStyles(theme),
    ml: 0,
  }),

  sidebarNarrow: (theme) => ({
    ...getSidebarStyles(theme),
    ml: `calc(${sidebarPanelWidth}px - ${getDocumentSidebarWidth()}px)`,
  }),

  sidebarPanel: {
    flex: `0 0 ${sidebarPanelWidth}px`,
    backgroundColor: getPaletteColor("common.white"),
    borderLeft: getBorderStyle("1px", "solid", "divider"),
    pt: 1,
    alignItems: "center",
    position: "relative",
    zIndex: 1,
  },

  sidebarSlide: {
    pt: 2,
    pl: 2,
    flexGrow: 1,
    height: "100%",
  },

  sidebarSlideVisible: {
    height: "100%",
  },

  sidebarSlideHidden: {
    p: 0,
    height: 0,
    overflow: "hidden",
    visibility: "hidden",
  },

  sidebarPanelButton: sidebarPanelButton,

  sidebarPanelButtonActive: {
    ...sidebarPanelButton,
    backgroundColor: getPaletteColor("primary.50"),
  },
};

export default styles;
