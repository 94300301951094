import { TFunction } from "i18next";
import { z } from "zod";
import { IHAQPayload } from "models/HAQ.models";
import { TDocumentComponent } from "screens/Project/sections/Documents/Document/Document.types";
import { AssetDefaultName, AssetType } from "utils/constants/assets.constants";
import {
  HAQFieldName,
  HAQStatus,
  HAQType,
} from "utils/constants/HAQ.constants";
import { formatValuesToOptions } from "utils/helpers";
import { defaultCompare } from "utils/helpers/defaultCompare";
import {
  THAQForm,
  THAQParentData,
  TQuestionOptions,
} from "./AddHAQDrawer.types";

const getParentAssetRelation = (parent?: THAQParentData) => {
  if (getIsComponentScopedHAQ(parent)) {
    if (parent?.assetId && parent?.name) {
      return JSON.stringify({ id: parent.assetId, name: parent.name });
    }
  } else {
    if (parent?.id && parent?.name) {
      return JSON.stringify({ id: parent.id, name: parent.name });
    }
  }

  return "";
};

export const getDefaultValues = (parent?: THAQParentData): THAQForm => {
  return {
    [HAQFieldName.Type]: HAQType.Draft,
    [HAQFieldName.Content]: "",
    [HAQFieldName.Domain]: "",
    [HAQFieldName.Topic]: "",
    [HAQFieldName.ReferenceNumber]: "",
    [HAQFieldName.ContentReference]: "",
    [HAQFieldName.Assets]: getParentAssetRelation(parent),
  };
};

export const getAddHAQSchema = (t: TFunction) =>
  z.object({
    [HAQFieldName.Assets]: z.string().optional(),
    [HAQFieldName.Type]: z.string().optional(),
    [HAQFieldName.Content]: z.string().min(1, {
      message: String(t("validations.requiredField", { ns: "common" })),
    }),
    [HAQFieldName.Domain]: z.string().min(1, {
      message: String(t("validations.requiredField", { ns: "common" })),
    }),
    [HAQFieldName.Topic]: z.string().min(1, {
      message: String(t("validations.requiredField", { ns: "common" })),
    }),
    [HAQFieldName.ReferenceNumber]: z.string().optional(),
    [HAQFieldName.ContentReference]: z.string().optional(),
  });

export const getSubmitPayload = (
  data: THAQForm,
  projectId: string,
): IHAQPayload => {
  return {
    type: AssetType.Question,
    name: AssetDefaultName.HAQ,
    projectIds: [projectId],
    status: HAQStatus.Draft,
    metadata: {
      completed: false,
      [HAQFieldName.ReferenceNumber]: data[HAQFieldName.ReferenceNumber],
      [HAQFieldName.ContentReference]: data[HAQFieldName.ContentReference],
      [HAQFieldName.Domain]: data[HAQFieldName.Domain],
      [HAQFieldName.Topic]: data[HAQFieldName.Topic],
      [HAQFieldName.Assets]: Boolean(data[HAQFieldName.Assets])
        ? [JSON.parse(data[HAQFieldName.Assets])]
        : [],
    },
    content: {
      [HAQFieldName.Content]: data[HAQFieldName.Content],
    },
  };
};

export const getOptionFromDataDictionary = (
  dataDictionary: TQuestionOptions,
  option: HAQFieldName.Domain | HAQFieldName.Topic,
  domainValue?: string,
): string[] => {
  switch (option) {
    case HAQFieldName.Domain: {
      return Array.from(
        new Set(
          dataDictionary
            .filter((data) => data.topic.length) // only display domains that have topics
            .map((data) => data.domain),
        ),
      );
    }

    case HAQFieldName.Topic: {
      // if domain is selected, only display topics relevent to that domain
      if (domainValue) {
        return Array.from(
          new Set(
            dataDictionary
              .filter((data) => data.domain === domainValue)
              .flatMap((data) => data.topic),
          ),
        );
      } else {
        return Array.from(
          new Set(dataDictionary.flatMap((data) => data.topic)),
        );
      }
    }

    default:
      return [];
  }
};

export function getIsComponentScopedHAQ(
  parent: THAQParentData,
): parent is TDocumentComponent {
  const parentAsComponent = parent as TDocumentComponent;

  return !!parentAsComponent?.assetId;
}

export function formatOptionsFromDataDictionary(
  t: TFunction,
  dataDictionary: TQuestionOptions | undefined,
  fieldName: HAQFieldName.Domain | HAQFieldName.Topic,
  domain?: string,
) {
  const keys = dataDictionary
    ? getOptionFromDataDictionary(dataDictionary, fieldName, domain)
    : [];

  return formatValuesToOptions(
    keys,
    (value) => t(`domains.${value}`),
    (value) => value,
  ).sort((a, b) => defaultCompare(a.label, b.label));
}
