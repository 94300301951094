import { ReactNode } from "react";
import { TLinkedOrganizationEntity } from "screens/EntityManagement/components/Organization/Organization.types";
import { TProductFamily } from "screens/EntityManagement/components/ProductFamily/ProductFamily.types";
import { TSubstance } from "screens/EntityManagement/components/Substances/Substances.types";

export enum ProductsTable {
  Id = "id",
  Name = "name",
  NonProprietaryName = "nonProprietaryName",
  ProductFamily = "productFamily",
  Substance = "substance",
  LinkedOrganizations = "linkedOrganizations",
  Status = "status",
  Actions = "actions",
}

export type TProductsRowData = {
  [ProductsTable.Id]: string;
  [ProductsTable.Name]: string;
  [ProductsTable.NonProprietaryName]: string;
  [ProductsTable.ProductFamily]: TProductFamily[];
  [ProductsTable.Substance]: TSubstance[];
  [ProductsTable.LinkedOrganizations]: TLinkedOrganizationEntity[];
  [ProductsTable.Status]: string;
  [ProductsTable.Actions]: ReactNode;
};
