import { useState } from "react";
import { ButtonLink } from "components/shared";
import { IDocument, IDocumentMetadata } from "models/documents.models";
import { PreviewDocument } from "screens/Project/sections/Documents/modals";

interface IDocumentPreviewProps {
  document: IDocument<IDocumentMetadata>;
}

function PreviewDocumentLink(props: IDocumentPreviewProps) {
  const { document } = props;
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  return (
    <>
      <ButtonLink
        sx={{ verticalAlign: "top" }}
        onClick={() => setIsPreviewOpen(true)}
      >
        {document.name}
      </ButtonLink>
      {isPreviewOpen && (
        <PreviewDocument
          document={document}
          onClose={() => setIsPreviewOpen(false)}
        />
      )}
    </>
  );
}

export default PreviewDocumentLink;
