import { TStyles } from "utils/Styles";

const styles: TStyles = {
  assignments: {
    mt: 2,
    mb: 2,
  },
  assignmentRow: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  itemSelect: {
    width: 1, // full-width
  },
};

export default styles;
