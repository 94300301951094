import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { FormSelect } from "components/common/form-fields";
import { useFeatureFlags } from "context/FeatureFlagsContext";
import { useProject } from "context/ProjectContext";
import usePermissions from "hooks/permissions";
import useUser from "hooks/useUser";
import { ImportModalType } from "screens/Project/sections/Documents/modals/ImportDocument";
import {
  ImportFormBaseFieldName,
  ImportFormReferencesFieldName,
} from "screens/Project/sections/Documents/modals/ImportDocument/components/fields/names";
import { TImportFormBase } from "screens/Project/sections/Documents/modals/ImportDocument/ImportDocument.types";
import {
  getDocumentSubtypesByProjectType,
  importModalDefaultValues,
} from "screens/Project/sections/Documents/modals/ImportDocument/ImportDocument.utils";
import { DocumentSubtype } from "utils/constants/doc.constants";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import { getHAsExceptHostHA } from "utils/helpers";
import { isHAUser, isSponsorUser } from "utils/user/organization";

type TSubtypeFieldProps = {
  openModalType: ImportModalType;
};

const SubtypeField = ({ openModalType }: TSubtypeFieldProps) => {
  const { reset, watch } = useFormContext<TImportFormBase>();

  const { project, participatingHAOrganizations } = useProject();

  const participatingHAOrganizationsExceptHostHA = useMemo(
    () => getHAsExceptHostHA(participatingHAOrganizations),
    [participatingHAOrganizations],
  );

  const { t } = useTranslation("documents");

  const featureFlags = useFeatureFlags();

  const isFHIREnabled = featureFlags["enableFHIRDocuments"] === FeatureFlag.On;

  const isECTDEnabled =
    featureFlags["FTE-23984_enableDossierUploadCard"] === FeatureFlag.On;

  const { projectId } = useParams();
  const { organization } = useUser();

  const { permissions } = usePermissions(projectId || "");

  const domain = watch(ImportFormBaseFieldName.Domain);
  const type = watch(ImportFormBaseFieldName.Type);
  const language = watch(ImportFormBaseFieldName.Language);
  const file = watch(ImportFormBaseFieldName.File);
  const name = watch(ImportFormBaseFieldName.Name);

  const subtypes = getDocumentSubtypesByProjectType({
    projectType: project?.type,
    permissions,
    isHAUser: isHAUser(organization),
    isFHIREnabled,
    isECTDEnabled,
  })[type];

  const allowedOptions = useMemo(
    () =>
      subtypes?.map((subtype) => ({
        label:
          // We want to display AAID with the value of AAID source
          subtype === DocumentSubtype.AssessmentAidSource
            ? t("subtypeOptions.ASSESSMENT_AID")
            : t(`subtypeOptions.${subtype}`),
        value: subtype,
      })) || [],
    [subtypes, t],
  );

  const getAdditionalDefaultValues = useCallback(
    (value: string | null) => {
      if (value === DocumentSubtype.References) {
        return {
          [ImportFormReferencesFieldName.HealthAuthority]: "",
          [ImportFormReferencesFieldName.FormalSubmissionDate]: null,
          [ImportFormReferencesFieldName.SAL]:
            isSponsorUser(organization) &&
            !isEmpty(participatingHAOrganizationsExceptHostHA),
        };
      }

      return {};
    },
    [organization, participatingHAOrganizationsExceptHostHA],
  );

  const onChange = useCallback(
    (value: string | null) => {
      reset(
        {
          ...importModalDefaultValues,
          [ImportFormBaseFieldName.Subtype]: value as DocumentSubtype,
          [ImportFormBaseFieldName.Domain]: domain,
          [ImportFormBaseFieldName.Type]: type,
          [ImportFormBaseFieldName.Language]: language,
          [ImportFormBaseFieldName.File]: file,
          [ImportFormBaseFieldName.Name]: name,
          ...getAdditionalDefaultValues(value),
        },
        {
          keepErrors: true,
        },
      );
    },
    [domain, type, file, language, name, reset, getAdditionalDefaultValues],
  );

  const isFieldDisabled =
    openModalType !== ImportModalType.Default || !Boolean(type);

  return (
    <FormSelect
      name={ImportFormBaseFieldName.Subtype}
      data-qaid="sybtype-select"
      label={t("importDocument.fields.subtype.label")}
      disabled={isFieldDisabled}
      onChange={onChange}
      options={allowedOptions}
    />
  );
};

export default SubtypeField;
