import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  table: {
    borderRadius: "4px",
    boxShadow: (theme) => `0px 2px 4px ${getPaletteColor("grey.300")(theme)}`,

    "& .MuiTable-root": {
      backgroundColor: getPaletteColor("primary.contrastText"),
    },

    "& .MuiTableCell-head": {
      color: "text.primary",
      fontWeight: 500,
      lineHeight: 1.43,

      "&:not(.MuiTableCell-paddingCheckbox)": {
        py: 2.25,
      },
    },

    "& .MuiTableCell-body:last-of-type": {
      width: "62px",
    },
    a: {
      color: getPaletteColor("text.primary"),
      textDecoration: "none",
    },
  },

  actionsIcon: {
    p: 1.125,
    my: -1.125,
  },

  container: {
    justifyContent: "space-between",
    alignItems: "flex-start",
  },

  importButton: {
    ml: "auto",
  },

  header: {
    flex: 1,
  },

  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 260,
  },
};

export default styles;
