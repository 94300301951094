import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { Box, IconButton, Slide, Stack, Typography } from "@mui/material";
import AnnotationsActions from "components/common/Editor/AnnotationFilters";
import { IDocumentOutline } from "models/documents.models";
import DocumentOutline from "./DocumentOutline";
import DocumentOutlineActions from "./DocumentOutlineActions";
import DocumentSidebarContextProvider from "./DocumentSidebarContext";
import { AnnotationsIcon } from "assets/icons";
import styles from "./DocumentSidebar.styles";

export enum SidebarContentType {
  Outline = "Outline",
  Annotations = "Comments & Changes",
}

export type TDocumentSidebarProps = {
  outline: IDocumentOutline[] | undefined;
  onSidebarMount: (isSidebarMounted: boolean) => void;
  onItemClick: (item: IDocumentOutline) => void;
};

const DocumentSidebar = forwardRef(
  (
    { outline, onSidebarMount, onItemClick }: TDocumentSidebarProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [contentType, setContentType] = useState<SidebarContentType>(
      SidebarContentType.Outline,
    );

    const { t } = useTranslation("common");

    const [isContentVisible, setIsContentVisible] = useState(true);

    const sidebarPanelButtons = [
      {
        contentType: SidebarContentType.Outline,
        icon: <ListAltIcon fontSize="small" />,
      },
      {
        contentType: SidebarContentType.Annotations,
        icon: <AnnotationsIcon fontSize="small" />,
      },
    ];

    useEffect(() => {
      onSidebarMount(true);

      return () => onSidebarMount(false);
    }, [onSidebarMount]);

    const switchContentType = (type: SidebarContentType) => {
      if (contentType === type) {
        setIsContentVisible((isContentVisible) => !isContentVisible);
      } else {
        setContentType(type);
        setIsContentVisible(true);
      }
    };

    const getIsButtonActive = (buttonContentType: SidebarContentType) =>
      buttonContentType === contentType && isContentVisible;

    const documentSidebarStyles = isContentVisible
      ? styles.sidebarWide
      : styles.sidebarNarrow;

    return (
      <DocumentSidebarContextProvider>
        <Stack direction="row" sx={documentSidebarStyles}>
          <Slide direction="left" in={isContentVisible} appear={false}>
            <Stack direction="column" sx={styles.sidebarSlide}>
              <Stack direction="row" sx={styles.sidebarHeader}>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={styles.sidebarHeaderTitle}
                >
                  {contentType}
                </Typography>

                {contentType === SidebarContentType.Annotations && (
                  <AnnotationsActions includeSuggestions={true} />
                )}
                {contentType === SidebarContentType.Outline && (
                  <DocumentOutlineActions />
                )}
              </Stack>
              <Box sx={styles.sidebarContent}>
                {contentType === SidebarContentType.Outline && (
                  <DocumentOutline
                    outline={outline}
                    onItemClick={onItemClick}
                  />
                )}
                <Box
                  ref={ref}
                  sx={
                    contentType === SidebarContentType.Annotations
                      ? styles.sidebarComments
                      : styles.sidebarCommentsHidden
                  }
                  data-testid="Annotations"
                  data-qaid="Annotations"
                />
              </Box>
            </Stack>
          </Slide>

          <Stack direction="column" sx={styles.sidebarPanel}>
            {sidebarPanelButtons.map((button) => (
              <IconButton
                color="primary"
                key={button.contentType}
                onClick={() => switchContentType(button.contentType)}
                aria-label={`${
                  getIsButtonActive(button.contentType)
                    ? t("button.hide")
                    : t("button.show")
                } ${button.contentType}`}
                name={`${
                  getIsButtonActive(button.contentType)
                    ? t("button.hide")
                    : t("button.show")
                } ${button.contentType}`}
                sx={
                  getIsButtonActive(button.contentType)
                    ? styles.sidebarPanelButtonActive
                    : styles.sidebarPanelButton
                }
              >
                {button.icon}
              </IconButton>
            ))}
          </Stack>
        </Stack>
      </DocumentSidebarContextProvider>
    );
  },
);

export default DocumentSidebar;
