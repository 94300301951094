import { useTranslation } from "react-i18next";
import {
  Checkbox,
  FormControlLabel,
  ListItemText,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import Select, { TSelectProps } from "components/shared/Select";
import styles from "./MultiSelect.styles";

type TSelectedItems = string[];

type TMultiSelectItem = {
  text: string;
  value: string;
};

export type TMultiSelectProps = Omit<
  TSelectProps<TSelectedItems>,
  "multiple" | "onChange" | "value"
> & {
  items: TMultiSelectItem[];
  onChange?: (selected: TSelectedItems) => void;
  selectAllLabel?: string;
  values?: string[];
};

const MultiSelect = (props: TMultiSelectProps) => {
  const { t } = useTranslation("common", { keyPrefix: "accessibility.label" });

  const { items, onChange, selectAllLabel, values, ...selectProps } = props;

  const deselectAll = () => {
    onChange?.([]);
  };

  const selectAll = () => {
    onChange?.(items.map((item) => item.value));
  };

  const handleChange = (event: SelectChangeEvent<TSelectedItems>) => {
    const value = event.target.value as TSelectedItems | [];

    if (value.find((el) => el === "all")) {
      toggleAll();
    } else {
      onChange?.(value);
    }
  };

  const toggleAll = () => {
    if (values?.length !== items.length) {
      selectAll();
    } else {
      deselectAll();
    }
  };

  const renderValue = (selected: TSelectedItems) => {
    if (selected.length <= 0) {
      return null;
    }

    return `${selected.length} ${t("selected")}`;
  };

  return (
    <Select
      multiple
      onChange={handleChange}
      renderValue={renderValue}
      value={values ?? []}
      {...selectProps}
    >
      <MenuItem value="all" sx={styles.selectAll}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              indeterminate={
                Boolean(values?.length) && values?.length !== items.length
              }
              checked={values?.length === items.length}
            />
          }
          onClick={(event) => event.preventDefault()}
          label={<ListItemText primary={selectAllLabel ?? "All"} />}
        />
      </MenuItem>
      {items.length
        ? items.map(({ text, value }) => (
            <MenuItem
              key={`${text}${value}`}
              value={value}
              sx={styles.optionItem}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={values?.includes(value) ?? false}
                  />
                }
                label={<ListItemText primary={text} />}
                onClick={(event) => event.preventDefault()}
              />
            </MenuItem>
          ))
        : null}
    </Select>
  );
};

export default MultiSelect;
