import { useMutation, useQueryClient } from "react-query";
import useShowAlert, { TAlertMessage } from "hooks/useShowAlert";
import { IAsset, IAssetMetadata } from "models/assets.models";
import { IDetailedDescriptionOfChangePayload } from "models/detailedDescriptionOfChange.models";
import { IGSPDossierDifferencesRequestData } from "models/gsp.models";
import { IHAQMetadata, IHAQPayload } from "models/HAQ.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export type TCreateAssetsMutationData = {
  data:
    | IGSPDossierDifferencesRequestData
    | IHAQPayload
    | IDetailedDescriptionOfChangePayload;
};

const useCreateAsset = <T extends IAssetMetadata | IHAQMetadata>(
  messages?: TAlertMessage,
) => {
  const queryClient = useQueryClient();

  const createAssetMutation = useMutation(
    ({ data }: TCreateAssetsMutationData) =>
      services.assets.createAssets(data).then((res) => res.data as IAsset<T>),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryAPIKey.GetAssets);
      },
    },
  );

  useShowAlert({
    status: createAssetMutation.status,
    ...messages,
  });

  return createAssetMutation;
};

export default useCreateAsset;
