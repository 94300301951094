import { useTranslation } from "react-i18next";
import { useQuery, UseQueryOptions } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { IGetTasksRequestParams, ITaskExtendedData } from "models/tasks.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

const useTasks = ({
  params,
  options,
}: {
  params?: IGetTasksRequestParams;
  options?: UseQueryOptions<ITaskExtendedData[], Error>;
} = {}) => {
  const { data, status, isError, isLoading, isSuccess, isFetching } = useQuery<
    ITaskExtendedData[],
    Error
  >(
    [QueryAPIKey.GetTasks, params],
    () => services.tasks.getTasks(params).then((res) => res.data),
    options,
  );

  const { t } = useTranslation("tasks", {
    keyPrefix: "notifications",
  });

  useShowAlert({
    status,
    failureMessage: t("getTasksFailure"),
  });

  return {
    tasks: data,
    isError,
    isLoading,
    isSuccess,
    isFetching,
    status,
  };
};

export default useTasks;
