import { TRadioLabelProps } from "components/shared/RadioTile/RadioLabel";

export enum CreateOrganizationStep {
  OrganizationType = "ORGANIZATION_TYPE",
  OrganizationForm = "ORGANIZATION_FORM",
}

export enum OrganizationType {
  Corporate = "CORPORATE",
  Affiliated = "AFFILIATED",
}

export interface IOrganizationTypeMap extends TRadioLabelProps {
  value: string;
}
