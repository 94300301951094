import { TFunction } from "i18next";
import { FilterType, TPossibleFilter } from "components/shared/types/filter";
import { DocumentStatus } from "utils/constants/doc.constants";
import { ProjectType } from "utils/constants/project.constants";

const FILE_TYPE_FILTER = (t: TFunction) => ({
  label: t("filters.fileType.label"),
  options: [
    { text: t("filters.fileType.options.jpg"), value: "jpg" },
    { text: t("filters.fileType.options.pdf"), value: "pdf" },
    { text: t("filters.fileType.options.png"), value: "png" },
    {
      text: t("filters.fileType.options.docx"),
      value: "docx",
    },
    {
      text: t("filters.fileType.options.xml"),
      value: "xml",
    },
  ],
  selectAllLabel: t("filters.fileType.selectAllLabel"),
});

const getStatusOptions = (
  projectType: ProjectType | undefined,
  t: TFunction,
) => {
  switch (projectType) {
    case ProjectType.Orbis:
      return [
        {
          text: t("filters.status.options.PROCESSING"),
          value: DocumentStatus.Processing,
        },
        {
          text: t("filters.status.options.UPLOADED"),
          value: DocumentStatus.Uploaded,
        },
        {
          text: t("filters.status.options.COMPLETED"),
          value: DocumentStatus.Completed,
        },
        {
          text: t("filters.status.options.DRAFT"),
          value: DocumentStatus.Draft,
        },
      ];

    case ProjectType.Reliance:
      return [
        {
          text: t("filters.status.options.IN_VERIFICATION"),
          value: DocumentStatus.InVerification,
        },
        {
          text: t("filters.status.options.FINAL"),
          value: DocumentStatus.Final,
        },
      ];

    default:
      return [];
  }
};

const getStatusFilter = (
  projectType: ProjectType | undefined,
  t: TFunction,
): TPossibleFilter => {
  return {
    type: FilterType.Select,
    label: t("filters.status.label"),
    options: getStatusOptions(projectType, t),
    selectAllLabel: t("filters.status.selectAllLabel"),
  };
};

export { FILE_TYPE_FILTER, getStatusFilter };
