import { PropsWithChildren, useContext, useMemo, useState } from "react";
import { HAQContext } from "./HAQContext";

export const useHAQContext = () => {
  const context = useContext(HAQContext);

  if (context === undefined) {
    throw new Error("useHAQContext must be used within an HAQContextProvider");
  }

  return context;
};

const HAQContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isAnyWorkflowInProgress, setIsAnyWorkflowInProgress] =
    useState<boolean>(false);

  const value = useMemo(
    () => ({
      isAnyWorkflowInProgress,
      setIsAnyWorkflowInProgress,
    }),
    [isAnyWorkflowInProgress, setIsAnyWorkflowInProgress],
  );

  return <HAQContext.Provider value={value}>{children}</HAQContext.Provider>;
};

export default HAQContextProvider;
