import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    mb: 2,
    height: "100%",
  },

  toolbar: {
    mb: 1,
  },

  editor: {
    flex: "0 0 100%",
  },

  materialBorder: {
    position: "relative",
    "& .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline":
      {
        border: getBorderStyle("1px", "solid", "custom.inputBorder"),
        borderRadius: "4px",
        boxShadow: "none",

        "&:focus": {
          borderColor: getPaletteColor("primary.main"),
          boxShadow: (theme) =>
            `0px 0px 0px 1px ${getPaletteColor("primary.main")(theme)}`,

          "& + span": {
            color: getPaletteColor("primary.main"),
          },
        },
      },
  },

  label: {
    position: "absolute",
    top: "-11px",
    left: "10px",
    background: getPaletteColor("common.white"),
    p: "5px",
  },
};

export default styles;
