import { TStyles } from "utils/Styles";

const styles: TStyles = {
  fileListContainer: {
    maxHeight: 180,
    overflow: "auto",
  },

  submitButton: {
    minWidth: "75px",
    minHeight: "36px",
  },

  selectedFile: {
    margin: "0 0 10px 0",
  },
};

export default styles;
