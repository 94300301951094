import { TStyles } from "utils/Styles";

const styles: TStyles = {
  modal: {
    ".MuiDialog-paper": {
      overflowY: "inherit",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    paddingTop: 1,
  },

  dropdownHelper: {
    fontSize: "12px",
    opacity: "60%",
    mt: 0.5,
  },
};

export default styles;
