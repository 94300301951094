import { UseFormReturn } from "react-hook-form";
import { TFunction } from "i18next";
import Button from "components/shared/Button";
import { TDossierDifferencesModalForm } from "../DossierDifferenceModal.types";

type TUseDossierDifferencesModalButtons = {
  isLoading: boolean;
  onClose: () => void;
  methods: UseFormReturn<TDossierDifferencesModalForm>;
  isEditMode: boolean;
  openDeleteModal: () => void;
  t: TFunction;
};

const getDossierDifferenceModalButtons = ({
  isLoading,
  onClose,
  methods,
  isEditMode,
  openDeleteModal,
  t,
}: TUseDossierDifferencesModalButtons) => {
  const {
    formState: { isValid },
  } = methods;

  const addButton = (
    <Button
      variant="contained"
      data-qaid="add-dossier-differences-submit-button"
      type="submit"
      key="submit"
      disabled={isLoading || !isValid}
    >
      {isEditMode
        ? t("button.edit", { ns: "common" })
        : t("button.add", { ns: "common" })}
    </Button>
  );

  const cancelButton = (
    <Button
      data-qaid="add-dossier-differences-cancel-button"
      key="cancel"
      onClick={onClose}
    >
      {t("button.cancel", { ns: "common" })}
    </Button>
  );

  const deleteButton = (
    <Button
      variant="outlined"
      key="delete"
      data-qaid="dossier-differences-delete-button"
      onClick={openDeleteModal}
      color="error"
    >
      {t("button.delete", { ns: "common" })}
    </Button>
  );

  return { addButton, cancelButton, deleteButton };
};

export default getDossierDifferenceModalButtons;
