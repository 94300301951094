import { TFunction } from "i18next";
import { z, ZodIssueCode } from "zod";

export enum FeatureFlagModalField {
  Name = "name",
  Description = "description",
  IsActive = "isActive",
  IsValueString = "isValueString",
  Value = "value",
}

export type TFeatureFlagModalForm = {
  [FeatureFlagModalField.Name]: string;
  [FeatureFlagModalField.Description]: string;
  [FeatureFlagModalField.IsActive]: boolean;
  [FeatureFlagModalField.Value]: string;
  [FeatureFlagModalField.IsValueString]: boolean;
};

export const featureFlagModalValues: TFeatureFlagModalForm = {
  [FeatureFlagModalField.Name]: "",
  [FeatureFlagModalField.Description]: "",
  [FeatureFlagModalField.IsActive]: false,
  [FeatureFlagModalField.Value]: "",
  [FeatureFlagModalField.IsValueString]: false,
};

export const getFlagFromSchema = (t: TFunction, currentValue?: string) =>
  z
    .object({
      [FeatureFlagModalField.Name]: z.string().min(1, {
        message: t("validations.requiredField", { ns: "common" }),
      }),
      [FeatureFlagModalField.Description]: z.string().min(1, {
        message: t("validations.requiredField", { ns: "common" }),
      }),
      [FeatureFlagModalField.Value]: z.string(),
      [FeatureFlagModalField.IsActive]: z.boolean(),
      [FeatureFlagModalField.IsValueString]: z.boolean(),
    })
    .superRefine((data, ctx) => {
      if (data[FeatureFlagModalField.IsValueString]) {
        if (!data[FeatureFlagModalField.Value].trim()) {
          ctx.addIssue({
            message: t("validations.requiredField", { ns: "common" }),
            code: ZodIssueCode.custom,
            path: [FeatureFlagModalField.Value],
          });
        }

        if (
          data[FeatureFlagModalField.IsValueString] &&
          currentValue &&
          data[FeatureFlagModalField.Value] === currentValue
        ) {
          ctx.addIssue({
            message: t("validations.requiredField", { ns: "common" }),
            code: ZodIssueCode.custom,
            path: [FeatureFlagModalField.Value],
          });
        }
      }
    });
