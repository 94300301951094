import isEmpty from "lodash/isEmpty";
import { getIsEmptyString } from "utils/helpers";

export enum CheckboxState {
  False = "false",
  Indeterminate = "indeterminate",
  True = "true",
}

export interface ICheckboxOption {
  id: string;
  label: string;
  secondary?: string;
  value: string;
  hidden?: boolean;
  disabled?: boolean;
  expanded?: boolean;
  checked?: CheckboxState;
  children?: ICheckboxOption[];
  testId?: string;
  // sometimes used when doing dynamic enabling or disabling of options
  metadata?: Record<string, any>;
}

export interface ICheckboxTreeProps<T = ICheckboxOption> {
  options: ICheckboxOption[];
  onChange?: (selections: T[]) => void;
  values?: ICheckboxOption[];
  isSelectAllEnabled?: boolean;
  selectAllSectionsLabel: string;
  expandButtonLabel: string;
  startIndentOffset?: number;
  isAllOptionsExpanded?: boolean;
  getValue: (option: ICheckboxOption) => T;
}

const getSelectedOption = (option: ICheckboxOption) => ({
  id: option.id,
  label: option.label,
  value: option.value,
  disabled:
    option.disabled ||
    Boolean(option.children?.some((child) => child.disabled)),
});

// https://accumulus.atlassian.net/wiki/spaces/PLAT/pages/347635766/Multiselect+Checkbox+Tree+Functionality

export const updateChildrenSelections = (
  isAdd: boolean,
  selections: ICheckboxOption[],
  options: ICheckboxOption[],
) => {
  let newSelections = [...selections];
  options.forEach((option) => {
    const optionSelectIdx = newSelections.findIndex(
      (currentSelection) => currentSelection.value === option.value,
    );

    if (isAdd && optionSelectIdx === -1) {
      if (option.value && !option.disabled) {
        newSelections.push(getSelectedOption(option));
      }
    } else if (!isAdd && optionSelectIdx > -1) {
      newSelections.splice(optionSelectIdx, 1);
    }

    if (option?.children?.length) {
      newSelections = updateChildrenSelections(
        isAdd,
        newSelections,
        option.children,
      );
    }
  });

  return newSelections;
};

function getHasUncheckedSelfOrDecendants(
  option: ICheckboxOption,
  selections: ICheckboxOption[],
): boolean {
  if (!option.children || isEmpty(option.children)) {
    return (
      !option.disabled &&
      !selections.find((selection) => selection.value === option.value)
    );
  }

  return option.children.some((child) =>
    getHasUncheckedSelfOrDecendants(child, selections),
  );
}

export const getNewSelections = (
  selections: ICheckboxOption[],
  selection: ICheckboxOption,
  options: ICheckboxOption[],
) => {
  if (
    selection.disabled ||
    selection.children?.every((child) => child.disabled)
  ) {
    return selections;
  }

  let newSelections = [...selections];

  // Handle if this option was touched specifically
  const selectedIdx = newSelections.findIndex(
    (currentSelection) => currentSelection.value === selection.value,
  );

  const isAdd = getHasUncheckedSelfOrDecendants(selection, newSelections);

  if (isAdd) {
    newSelections.push(getSelectedOption(selection));
  } else if (!isAdd && selectedIdx !== -1) {
    // If an option has disabled children, but all the enabled children are checked, clicking the
    // indeterminate option is considered a remove even though the option itself isn't in the
    // selections.
    newSelections.splice(selectedIdx, 1);
  }

  if (selection?.children?.length) {
    newSelections = updateChildrenSelections(
      isAdd,
      newSelections,
      selection.children,
    );
  }

  // Selection has been updated, need to start from top and ensure all anscestor options are updated as needed too
  return updateParentsBasedOnChildren(newSelections, options).filter(
    (selection) => !getIsEmptyString(selection.label),
  );
};

export const updateParentsBasedOnChildren = (
  selections: ICheckboxOption[],
  options: ICheckboxOption[],
) => {
  let newSelections = [...selections];

  options.forEach((option) => {
    if (option?.children?.length) {
      newSelections = updateParentsBasedOnChildren(
        newSelections,
        option.children,
      );

      const isAllChildrenSelected =
        option.children.findIndex(
          ({ value }) =>
            newSelections.findIndex(
              (currentSelection) => currentSelection.value === value,
            ) === -1,
        ) === -1;

      const optionIdx = newSelections.findIndex(
        (currentSelection) => currentSelection.value === option.value,
      );

      if (!isAllChildrenSelected && optionIdx > -1) {
        newSelections.splice(optionIdx, 1);
      }

      if (isAllChildrenSelected && optionIdx === -1 && option.value) {
        newSelections.push(option);
      }
    }
  });

  return newSelections;
};

export const updateOptionsStatus = (
  selections: ICheckboxOption[],
  options: ICheckboxOption[],
) => {
  return options.map((option) => {
    const optionIdx = selections.findIndex(
      (currentSelection) => currentSelection.value === option.value,
    );

    if (option?.children) {
      const newChildren = updateOptionsStatus(selections, option.children);

      option.children = newChildren;

      const allChildrenChecked = newChildren.every(
        ({ checked }) => checked === CheckboxState.True,
      );

      const someChildrenChecked = newChildren.some(
        ({ checked }) => checked === CheckboxState.True,
      );

      const someChildrenIndeterminate = newChildren.some(
        ({ checked }) => checked === CheckboxState.Indeterminate,
      );

      if (allChildrenChecked) {
        option.checked = CheckboxState.True;
      } else if (someChildrenChecked || someChildrenIndeterminate) {
        option.checked = CheckboxState.Indeterminate;
      } else {
        option.checked = CheckboxState.False;
      }
    } else if (optionIdx > -1) {
      option.checked = CheckboxState.True;
    } else {
      option.checked = CheckboxState.False;
    }

    return option;
  });
};

export const generateSelectAllCheckedState = (
  values: ICheckboxOption[],
  allPossibleValues: ICheckboxOption[],
  option: ICheckboxOption,
): ICheckboxOption => {
  if (values.length < 1) {
    option.checked = CheckboxState.False;
  } else {
    if (values.length === allPossibleValues.length) {
      option.checked = CheckboxState.True;
    }

    if (values.length < allPossibleValues.length) {
      option.checked = CheckboxState.Indeterminate;
    }
  }

  return option;
};

export const flattenOptions = (
  optionsArr: ICheckboxOption[],
): ICheckboxOption[] =>
  optionsArr
    .flatMap((option) => [
      getSelectedOption(option),
      ...flattenOptions(option?.children || []),
    ])
    .filter((valueOutput) => valueOutput !== undefined);

export const getDefaultSelectAllOption = (label: string): ICheckboxOption => {
  return {
    id: "select-all",
    label: label,
    value: "select-all",
    hidden: false,
    disabled: false,
    expanded: true,
    checked: CheckboxState.False,
  };
};

export const SELECT_ALL_CHECKBOX_OPTION = "select-all-option";
