import { alpha } from "@mui/material";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  tabs: {
    minHeight: "unset",
    borderBottom: 1,
    borderBottomColor: (theme) => alpha(theme.palette.divider, 0.06),

    "& .MuiTabs-flexContainer": {
      gap: 4,
    },

    "& .MuiTabs-scrollButtons.Mui-disabled": {
      opacity: 0.3,
    },
  },
};

export default styles;
