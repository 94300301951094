import { TFunction } from "i18next";
import isEmpty from "lodash/isEmpty";
import { TBasicShareTrackingRecord } from "hooks/api/GQL/haq/useHAQsWithContent.models";
import { IOrganization } from "models/organizations.models";
import { AssetType, THAQAssetType } from "utils/constants/assets.constants";
import { US_DATE_FORMAT } from "utils/constants/format.constants";
import {
  IShareHistoryItem,
  ShareHistoryItemStatus,
} from "utils/constants/shareHistory.constants";
import { formatDate } from "utils/helpers";
import { isSponsorUser } from "utils/user/organization";
import { Recipient } from "./components/Recipient";
import {
  THAQHistoryRowData,
  THAQShareHistoryTableProps,
} from "./HAQShareHistoryModal.types";

const isShareHistoryFullRecord = (
  item: THAQShareHistoryTableProps,
): item is THAQShareHistoryTableProps<true> => item.id !== undefined;

const translateStatusWithDate = (
  t: TFunction,
  status: string,
  responseDate: string,
) =>
  responseDate
    ? t(`shareHistory.items.status.${status}_ON_DATE`, {
        date: formatDate(responseDate, US_DATE_FORMAT),
      })
    : t(`shareHistory.items.status.${status}`);

export const getRows = (
  data: THAQShareHistoryTableProps[] | undefined,
  organization: IOrganization | undefined,
  t: TFunction,
): THAQHistoryRowData[] => {
  const isSponsor = isSponsorUser(organization);

  return (data || []).map((item) => {
    const status =
      // for Sponsor side Acknowledged should be replaced with Received
      isSponsor && item.status === ShareHistoryItemStatus.Acknowledged
        ? ShareHistoryItemStatus.Received
        : item.status;

    return isShareHistoryFullRecord(item)
      ? {
          id: item.id,
          HAQType: item.HAQType,
          recipient: <Recipient tenantId={item.tenantId} t={t} />,
          date: formatDate(item.submissionDate, US_DATE_FORMAT),
          status:
            !item?.responseDate && item?.status === ShareHistoryItemStatus.Sent
              ? t(`shareHistory.items.status.AWAITING_RESPONSE`)
              : translateStatusWithDate(t, status || "", item.responseDate),
        }
      : populateEmptyQuestionOrResponse(item.HAQType);
  });
};

export const getShareHistoryTableProps = (
  HAQShareHistory: IShareHistoryItem[] | TBasicShareTrackingRecord[],
  HAQResponseShareHistory: IShareHistoryItem[] | TBasicShareTrackingRecord[],
  isImported: boolean,
  isHA: boolean,
): THAQShareHistoryTableProps[] => {
  if (isEmpty(HAQShareHistory) && isEmpty(HAQResponseShareHistory)) {
    return [];
  }

  return isImported
    ? [
        ...addShareHistoryTablePropsFields(
          HAQShareHistory,
          AssetType.Question,
          true,
        ),
        ...addShareHistoryTablePropsFields(
          HAQResponseShareHistory,
          AssetType.QuestionResponse,
          true,
        ),
      ]
    : isHA
    ? addShareHistoryTablePropsFields(
        HAQShareHistory,
        AssetType.Question,
        false,
      )
    : addShareHistoryTablePropsFields(
        HAQResponseShareHistory,
        AssetType.QuestionResponse,
        false,
      );
};

const populateEmptyQuestionOrResponse = (
  HAQType: THAQAssetType,
): THAQHistoryRowData => ({
  id: "",
  HAQType,
  recipient: "-",
  date: "-",
  status: "-",
});

const addShareHistoryTablePropsFields = (
  shareHistory: IShareHistoryItem[] | TBasicShareTrackingRecord[],
  HAQType: THAQAssetType,
  isImported: boolean,
): THAQShareHistoryTableProps[] =>
  // in the case where isImported and Q or R do not have a record, template should still be created
  (isEmpty(shareHistory) && isImported ? [{}] : shareHistory).map(
    (shareHistoryItem) => ({
      ...shareHistoryItem,
      HAQType,
      isImported,
    }),
  );
