function filterTree<T extends { children?: T[] }>(
  tree: T[] | undefined,
  filterFunction: (treeElement: T) => boolean,
): T[] {
  return (
    tree?.reduce((treeArray: T[], treeElement): T[] => {
      const children = treeElement.children?.length
        ? filterTree(treeElement.children, filterFunction)
        : [];

      if (children.length || filterFunction(treeElement)) {
        return treeArray.concat({
          ...treeElement,
          ...(treeElement.children && { children }),
        });
      } else {
        return treeArray;
      }
    }, []) || []
  );
}

export default filterTree;
