const uniqueArray = <T, K extends keyof T>(array: T[], key?: K): T[] => {
  const result: T[] = [];

  const map = new Map<T[K] | T, T>();

  try {
    for (const item of array) {
      const value = key ? item[key] : item;

      if (!value || !map.has(value)) {
        map.set(value, item);
        result.push(item);
      }
    }
  } catch (error) {
    return result;
  }

  return result;
};

export default uniqueArray;
