export enum HAQAction {
  AddQuestion = "ADD_QUESTION",
  // TODO: Consider moving 2 actions above to Project Actions and Document Actions
  ChangeStatus = "CHANGE_STATUS",
  RequestInternalReview = "REQUEST_INTERNAL_REVIEW",
  RequestExternalReview = "REQUEST_EXTERNAL_REVIEW",
  CreateRelatedQuestion = "CREATE_RELATED_QUESTION",
  SendCopyToSponsor = "SEND_COPY_TO_SPONSOR",
  SubmitToHA = "SUBMIT_TO_HA",
  SetToComplete = "SET_TO_COMPLETE",
  SetToFinal = "SET_TO_FINAL",
  SetToDraft = "SET_TO_DRAFT",
  Export = "EXPORT",
  Delete = "DELETE",
  Edit = "EDIT",
  ChangeDueDate = "CHANGE_DUE_DATE",
}

export enum HAQType {
  Draft = "ADD_AS_DRAFT",
  HAFinal = "ADD_AS_HA_FINAL",
  InternalReview = "REQUEST_INTERNAL_REVIEW",
  ExternalReview = "REQUEST_EXTERNAL_REVIEW",
  SendCopy = "SEND_COPY",
}

export enum HAQFieldName {
  Type = "type",
  Content = "content",
  ReferenceNumber = "referenceNumber",
  Domain = "domain",
  Topic = "topic",
  AdditionalInstructions = "additionalInstructions",
  DueDate = "dueDate",
  TeamMemberRecipients = "teamMembers",
  HARecipients = "invitedHealthAuthorityTenants",
  HAQWithContent = "HAQWithContent",
  HAQResponseWithContent = "HAQResponseWithContent",
  SponsorRecipients = "sponsorId",
  Assets = "assets",
  ContentReference = "contentReference",
}

export enum HAQStatus {
  Draft = "DRAFT",
  InReview = "IN_REVIEW",
  Reviewed = "REVIEWED",
  Final = "FINAL",
  Submitted = "SUBMITTED",
  Sent = "SENT",
  // ToDo (AV): Ask Content and Workflow team if this status is relevant
  Complete = "COMPLETE",
}

export enum HAQBulkActionType {
  QuestionOnly = "questionOnly",
  ResponseOnly = "responseOnly",
  QuestionAndResponse = "questionAndResponse",
}
