import { IFeatureFlag } from "models/featureFlags.models";

export enum FeatureFlagsTable {
  Id = "id",
  Name = "name",
  Value = "value",
  DateStamp = "dateStamp",
  ModifiedBy = "modifiedBy",
  EditAction = "editAction",
  DeleteAction = "deleteAction",
}
export type TFeatureFlagsTableData = IFeatureFlag & {
  [FeatureFlagsTable.EditAction]: IFeatureFlag;
  [FeatureFlagsTable.DeleteAction]: IFeatureFlag;
};
