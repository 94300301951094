/* eslint-disable no-restricted-imports */
import { ForwardedRef, forwardRef, useMemo } from "react";
import { Warning } from "@mui/icons-material";
import { Chip as MuiChip, ChipProps as MuiChipProps } from "@mui/material";
import styles from "./Chip.styles";

export type TChipProps = MuiChipProps & {
  label: string;
  hasError?: boolean;
};

const Chip = forwardRef(
  ({ hasError, ...props }: TChipProps, ref: ForwardedRef<HTMLDivElement>) => {
    const color = useMemo(() => {
      if (hasError) {
        return "error";
      }

      return props.color ? props.color : "default";
    }, [props.color, hasError]);

    const icon = useMemo(() => {
      if (hasError) {
        return <Warning fontSize="small" />;
      }

      return props.icon ? props.icon : undefined;
    }, [hasError, props.icon]);

    return (
      <MuiChip
        sx={styles.defaultChip}
        {...props}
        icon={icon}
        color={color}
        ref={ref}
      />
    );
  },
);

export default Chip;
