import { useEffect, useMemo, useRef, useState } from "react";
import {
  Controller,
  FieldPath,
  FieldValues,
  get,
  useFormContext,
} from "react-hook-form";
import { Box, FormHelperText, SxProps, Theme } from "@mui/material";
import { Editor as CustomEditor } from "ckeditor";
import Editor from "components/common/Editor";
import AnnotationUIProvider from "components/common/Editor/AnnotationUIContext/AnnotationUIContext";
import { TEditorProps } from "components/common/Editor/Editor";
import EditorContext from "components/common/Editor/EditorContext/EditorContext";
import styles from "./FormRichTextEditor.styles";

export type TFormRichTextEditorProps<FormType extends FieldValues> = Pick<
  TEditorProps,
  "placeholder" | "componentId" | "isReadOnly"
> & {
  name: FieldPath<FormType>;
  removeToolbarItems?: string[];
  sx?: SxProps<Theme>;
  unregisterOnUnmount?: boolean;
};

const FormRichTextEditor = <FormType extends FieldValues>({
  removeToolbarItems = ["comment", "commentsArchive"],
  sx,
  name,
  isReadOnly = false,
  unregisterOnUnmount = true,
  ...props
}: TFormRichTextEditorProps<FormType>) => {
  const toolbarRef = useRef<HTMLDivElement>();

  const {
    control,
    formState: { errors },
    unregister,
  } = useFormContext<FormType>();

  const [editor, setEditor] = useState<CustomEditor | null>(null);
  const [isQuestionInFocus, setIsQuestionInFocus] = useState(false);
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  useEffect(() => {
    setIsLayoutReady?.(true);

    return () => setIsLayoutReady?.(false);
  }, [isLayoutReady]);

  useEffect(() => {
    if (unregisterOnUnmount) {
      return () => unregister(name);
    }
  }, [unregister, name, unregisterOnUnmount]);

  useEffect(() => {
    if (isReadOnly && toolbarRef?.current?.firstChild) {
      toolbarRef?.current?.removeChild(
        toolbarRef.current.firstChild as ChildNode,
      );
    }

    if (!isReadOnly && editor?.ui.view.toolbar.element) {
      if (toolbarRef?.current?.firstChild) {
        toolbarRef?.current?.removeChild(
          toolbarRef.current.firstChild as ChildNode,
        );
      }

      toolbarRef?.current?.appendChild(editor.ui.view.toolbar.element);
    }
  }, [isReadOnly, editor]);

  const onEditorReady = (_id: string, editor: CustomEditor) => {
    setEditor(editor);
  };

  const error = get(errors, name);

  const contentEditorStyles: SxProps<Theme> = useMemo(() => {
    if (isReadOnly) {
      return {
        ...(sx ? sx : {}),
      } as SxProps<Theme>;
    }

    if (isQuestionInFocus && !Boolean(error)) {
      return {
        ...styles.contentEditorContainer,
        ...styles.contentEditorInFocus,
        ...(sx ? sx : {}),
      } as SxProps<Theme>;
    }

    if (Boolean(error)) {
      return {
        ...styles.contentEditorContainer,
        ...styles.contentEditorError,
        ...(sx ? sx : {}),
      } as SxProps<Theme>;
    }

    if (Boolean(editor)) {
      return {
        ...styles.contentEditorContainer,
        ...(sx ? sx : {}),
      } as SxProps<Theme>;
    }

    return {};
  }, [isQuestionInFocus, isReadOnly, error, editor, sx]);

  return (
    <Box sx={contentEditorStyles}>
      <Box ref={toolbarRef} />
      <EditorContext isLayoutReady={isLayoutReady}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Box sx={styles.editor} data-qaid="content-editor-content-box">
              <Editor
                {...props}
                editor={editor}
                data={field.value}
                isReadOnly={isReadOnly}
                removeToolbarItems={removeToolbarItems}
                onBlur={() => {
                  field.onBlur();
                  setIsQuestionInFocus(false);
                }}
                onFocus={() => {
                  setIsQuestionInFocus(true);
                }}
                onContentChange={field.onChange}
                onEditorReady={onEditorReady}
              />
            </Box>
          )}
        />
      </EditorContext>
      {Boolean(error) && (
        <FormHelperText
          variant="standard"
          aria-live="polite"
          sx={styles.errorMessage}
          error
        >
          {error.message}
        </FormHelperText>
      )}
    </Box>
  );
};

export default <FormType extends FieldValues>(
  props: TFormRichTextEditorProps<FormType>,
) => (
  <AnnotationUIProvider>
    <FormRichTextEditor {...props} />
  </AnnotationUIProvider>
);
