import { TStyles } from "utils/Styles";

const styles: TStyles = {
  options: {
    display: "flex",
    flexDirection: "column",
    ml: 3.5,

    "& .MuiFormControlLabel-label": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
};

export default styles;
