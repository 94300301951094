import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { gql } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";

export const query = gql`
  mutation DeleteFeatureFlag($name: String) {
    deleteFeatureFlag(name: $name)
  }
`;

const useDeleteFeatureFlag = () => {
  const { t } = useTranslation("featureFlags", {
    keyPrefix: "notifications",
  });

  const { showAlert } = useAlerts();
  const queryClient = useQueryClient();

  const { data, isError, isLoading, isSuccess, mutate } = useMutation(
    ({ name }: { name: string }) =>
      services.graphQL
        .request(query, {
          name,
        })
        .then(() => {
          showAlert({
            message: t("deleteFlagSuccess"),
            severity: AlertSeverity.Success,
          });

          queryClient.invalidateQueries(QueryAPIKey.GetFeatureFlags);
        })
        .catch((error) => {
          showAlert({
            message: t("deleteFlagFailure"),
            severity: AlertSeverity.Error,
          });

          throw error;
        }),
  );

  return { data, isLoading, isError, isSuccess, mutate };
};

export default useDeleteFeatureFlag;
