import { getBorderStyle } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  sidebarHeader: {
    display: "flex",
    alignItems: "center",
    pb: 1,
    borderBottom: getBorderStyle("1px", "solid", "divider"),
    justifyContent: "space-between",
  },

  standaloneComments: {
    flexGrow: 1,
  },

  sidebarHeaderContainer: {
    pr: 2,
  },

  commentContainer: {
    flexGrow: 1,
    pr: 2,
    overflow: "auto",
  },

  commentContainerWithPadding: {
    flexGrow: 1,
    pr: 2,
    overflow: "auto",
    ".ck-sidebar-item:last-child": {
      pb: 2,
    },
  },
};

export default styles;
