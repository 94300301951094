import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import ComboBox from "components/shared/ComboBox";
import { TComboBoxProps } from "components/shared/ComboBox/ComboBox";

export type TFormComboBoxProps<FormType extends FieldValues> = Omit<
  TComboBoxProps,
  "error" | "textFieldProps" | "value"
> & {
  name: FieldPath<FormType>;
  helperText?: string;
  errorAdornmentAriaLabel?: string;
  clearIconAriaLabel?: string;
};

const FormComboBox = <FormType extends FieldValues>({
  name,
  helperText,
  errorAdornmentAriaLabel,
  clearIconAriaLabel,
  ...props
}: TFormComboBoxProps<FormType>) => {
  const { control } = useFormContext<FormType>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <ComboBox
          {...field}
          {...props}
          id={name}
          textFieldProps={{
            size: "small",
            errorAdornmentAriaLabel,
            clearIconAriaLabel,
          }}
          error={!!error}
          helperText={error?.message || helperText}
        />
      )}
    />
  );
};

export default FormComboBox;
