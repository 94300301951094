import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useCreateAsset from "hooks/api/REST/assets/useCreateAsset";
import useUpdateAsset from "hooks/api/REST/assets/useUpdateAsset";
// eslint-disable-next-line max-len
import {
  TDossierDifferencesModalForm,
  TDossierDifferencesModalFormEdit,
} from "screens/Project/screens/GSP/GSPItems/DossierDifferenceModal/DossierDifferenceModal.types";
import { DOSSIER_DIFFERENCE_PAYLOAD_TYPE } from "screens/Project/screens/GSP/GSPItems/GSPFormItem/GSPForm.utils";
import { AssetDefaultName } from "utils/constants/assets.constants";
import { getDossierDifferenceId } from "../utils/getDossierDifferenceId";

type TUseSubmitDossierDifferenceModal = {
  onClose: () => void;
};

const useSubmitDossierDifferenceModal = ({
  onClose,
}: TUseSubmitDossierDifferenceModal) => {
  const { GSPId } = useParams<{ id: string; GSPId: string }>();

  const { t } = useTranslation("projects", {
    keyPrefix: "notifications",
  });

  const { mutateAsync: createDossierDifferences, isLoading: isLoadingCreate } =
    useCreateAsset({
      failureMessage: t("addDossierDifferencesFailure"),
      successMessage: t("addDossierDifferencesSuccess"),
    });

  const { mutateAsync: updateDossierDifferences, isLoading: isLoadingUpdate } =
    useUpdateAsset({
      failureMessage: t("editDossierDifferencesFailure"),
      successMessage: t("editDossierDifferencesSuccess"),
    });

  const handleAdd = async (
    data: TDossierDifferencesModalForm | TDossierDifferencesModalFormEdit,
  ) => {
    const dossierDifferenceId = getDossierDifferenceId(data);

    const payload = {
      parentId: GSPId || "",
      type: DOSSIER_DIFFERENCE_PAYLOAD_TYPE,
      name: AssetDefaultName.DossierDifference,
      ...(dossierDifferenceId && { id: dossierDifferenceId }),
      metadata: {
        summaryOfDifferences: data.summaryOfDifferences,
        sections: data.sections,
        applicableHealthAuthorityTenantIds: data.healthAuthorities.map(
          (HA) => HA.value,
        ),
      },
    };

    let res;

    dossierDifferenceId
      ? (res = await updateDossierDifferences({
          data: payload,
          id: dossierDifferenceId,
        }))
      : (res = await createDossierDifferences({ data: payload }));

    onClose();

    return res;
  };

  const isLoading = isLoadingCreate || isLoadingUpdate;

  return { isLoading, handleAdd };
};

export default useSubmitDossierDifferenceModal;
