import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  title: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "1rem",
    color: getPaletteColor("grey.700"),
    columnGap: 1.5,
  },
};

export default styles;
