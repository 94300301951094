import { useMutation } from "react-query";
import { TShowAlert, useAlerts } from "context/AlertContext";
import { WorkflowExecutionStatus } from "models/workflow.models";
import { services } from "services";
import { WorkflowId } from "utils/constants/workflows.constants";

export type TStartWorkflowMutation<T extends object = {}> = {
  id: WorkflowId;
  payload: T;
};

export async function startWorkflow<T extends object>(
  showAlert: TShowAlert,
  params: TStartWorkflowMutation<T>,
  successMessage?: string,
  failureMessage?: string,
) {
  const { id, payload } = params;

  try {
    const response = await services.workflows.startWorkflow<T>(id, payload);

    // TODO: (UN): This check is going to be redundant after [FTE-27145] is done
    if (response.data.status === WorkflowExecutionStatus.Failed) {
      throw new Error(response.data.failureReason);
    }

    if (successMessage) {
      showAlert({
        message: successMessage,
        severity: "success",
      });
    }

    return response;
  } catch (error) {
    if (failureMessage) {
      showAlert({
        message: failureMessage,
        severity: "error",
      });
    }

    throw error;
  }
}

export default function useStartWorkflow<T extends object>({
  successMessage,
  failureMessage,
}: {
  successMessage?: string;
  failureMessage?: string;
} = {}) {
  const { showAlert } = useAlerts();

  const {
    data: workflowResponse,
    isLoading,
    isSuccess,
    error,
    mutate,
    mutateAsync,
  } = useMutation((params: TStartWorkflowMutation<T>) =>
    startWorkflow<T>(showAlert, params, successMessage, failureMessage),
  );

  return {
    workflowResponse,
    isLoading,
    isSuccess,
    error,
    startWorkflow: mutate,
    startWorkflowAsync: mutateAsync,
  };
}
