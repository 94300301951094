import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import {
  FormComboBox,
  FormDate,
  FormTextInput,
} from "components/common/form-fields";
import ButtonLink from "components/shared/ButtonLink";
import { TComboBoxOption } from "components/shared/ComboBox/ComboBox";
import { DocumentsField } from "screens/Project/sections/Documents/modals/fields";
import {
  SendCopyDocumentFieldName,
  TSendCopyDocumentForm,
} from "screens/Project/sections/Documents/modals/SendCopyDocuments/SendCopyDocuments.types";
import styles from "./SendCopyDocuments.styles";

export type TFieldsLayoutProps = {
  setIsAddDocsView: () => void;
  canAddDocuments: boolean;
  recipientHelperText?: string;
  listOfRecipients: TComboBoxOption[];
};

const FieldsLayout = ({
  setIsAddDocsView,
  canAddDocuments,
  recipientHelperText,
  listOfRecipients,
}: TFieldsLayoutProps) => {
  const { t } = useTranslation("documents");

  return (
    <Stack gap={2} justifyContent="flex-start">
      <DocumentsField fieldName={SendCopyDocumentFieldName.Documents} />
      {canAddDocuments && (
        <ButtonLink onClick={setIsAddDocsView} sx={styles.addDocumentsButton}>
          {t("documents.commonFields.documents.addDocument")}
        </ButtonLink>
      )}
      <FormComboBox
        data-qaid="send-copy-documents-recipients-combobox"
        helperText={recipientHelperText}
        name={SendCopyDocumentFieldName.Recipients}
        label={t(
          `documents.sendCopyDocuments.fields.${SendCopyDocumentFieldName.Recipients}.label`,
        )}
        options={listOfRecipients}
        deselectAllLabelText={String(
          t("multiSelect.deselectAll", { ns: "common" }),
        )}
        selectAllLabelText={String(
          t("multiSelect.selectAll", { ns: "common" }),
        )}
        errorAdornmentAriaLabel={String(
          t("ariaLabels.textFieldError", {
            ns: "common",
          }),
        )}
      />
      <FormDate
        data-qaid="send-copy-documents-due-date-field"
        disablePast
        name={SendCopyDocumentFieldName.DueDate}
        label={t(
          `documents.sendCopyDocuments.fields.${SendCopyDocumentFieldName.DueDate}.label`,
        )}
      />
      <FormTextInput<TSendCopyDocumentForm>
        data-qaid="send-copy-documents-instructions-text-field"
        name={SendCopyDocumentFieldName.Instructions}
        multiline
        rows={3}
        label={t(
          `documents.sendCopyDocuments.fields.${SendCopyDocumentFieldName.Instructions}.label`,
        )}
      />
    </Stack>
  );
};

export default FieldsLayout;
