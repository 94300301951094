import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { People } from "@mui/icons-material";
import { IconButton, SvgIconProps } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { useProject } from "context/ProjectContext";
import useUser from "hooks/useUser";
import { EventType } from "models/tracking.models";
import { services } from "services";
import { AssetType } from "utils/constants/assets.constants";
import { isSponsorUser } from "utils/user/organization";
import HAQShareHistoryModal from "./HAQShareHistoryModal";
import { THAQShareHistoryTableProps } from "./HAQShareHistoryModal/HAQShareHistoryModal.types";

type THAQShareHistoryProps = {
  shareHistory: THAQShareHistoryTableProps[] | undefined;
  HAQName: string;
  trackingHAQId?: string;
  iconSize?: SvgIconProps["fontSize"];
};

const HAQShareHistory = ({
  shareHistory,
  HAQName,
  trackingHAQId,
  iconSize,
}: THAQShareHistoryProps) => {
  const { t } = useTranslation("HAQ");
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const { project } = useProject();
  const { organization } = useUser();

  const switchIsHistoryModalOpen =
    (isOpen: boolean) => (event: SyntheticEvent) => {
      event.stopPropagation(); // prevents row selection from firing when clicking the share icon
      setIsHistoryModalOpen(isOpen);

      if (trackingHAQId && isOpen && project?.id) {
        services.tracking.addTrackingEvent({
          assetType: AssetType.Question,
          assetId: trackingHAQId,
          projectId: project.id,
          eventType: EventType.AppHAQViewShareHistory,
        });
      }
    };

  return !isEmpty(shareHistory) ? (
    <>
      <IconButton
        onClick={switchIsHistoryModalOpen(true)}
        title={
          isSponsorUser(organization)
            ? t("shareHistory.sponsorIconTooltip")
            : t("shareHistory.HAIconTooltip")
        }
        data-testid="share-history-btn"
      >
        <People fontSize={iconSize} />
      </IconButton>
      <HAQShareHistoryModal
        onClose={switchIsHistoryModalOpen(false)}
        shareHistory={isHistoryModalOpen ? shareHistory : undefined}
        HAQName={HAQName}
      />
    </>
  ) : null;
};

export default HAQShareHistory;
