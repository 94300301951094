import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    columnGap: 2,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    pt: 2,
    rowGap: 2,
  },
  select: {
    minWidth: (theme) => theme.spacing(20),
    width: (theme) => theme.spacing(22),
  },
  datePicker: {
    width: (theme) => theme.spacing(20),
  },
};

export default styles;
