import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormDate, FormTextInput } from "components/common/form-fields";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Loading from "components/shared/Loading";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import useStartWorkflow from "hooks/api/REST/workflow/useStartWorkflow";
import useUser from "hooks/useUser";
import { IDocumentOutline } from "models/documents.models";
import { Path } from "router/paths";
import { ISO_DATE_FORMAT } from "utils/constants/format.constants";
import { WorkflowId } from "utils/constants/workflows.constants";
import { formatDate } from "utils/helpers";
import DocumentSectionsSelect from "../components/DocumentSectionsSelect";
import { TeamMembers } from "./components";
import {
  getStartReviewFormSchema,
  StartReviewModalField,
  startReviewModalValues,
  TStartReviewModalForm,
} from "./StartReviewModal.types";
import styles from "./StartReviewModal.styles";

export type TStartReviewModal = {
  isOpen: boolean;
  onClose: (shouldInvalidateDocumentQueries?: boolean) => void;
  documentOutline: IDocumentOutline[] | undefined;
};

const StartReviewModal = ({
  isOpen,
  onClose,
  documentOutline,
}: TStartReviewModal) => {
  const navigate = useNavigate();

  const { t } = useTranslation(["documents", "common", "notifications"]);

  const methods = useForm<TStartReviewModalForm>({
    mode: "all",
    defaultValues: startReviewModalValues,
    resolver: zodResolver(getStartReviewFormSchema(t)),
  });

  const { handleSubmit, reset, formState } = methods;
  const { user } = useUser();
  const { projectId } = useParams();

  const { startWorkflow, isLoading } = useStartWorkflow({
    successMessage: t("workflowMessages.startReviewSuccessMessage", {
      ns: "notifications",
    }),
    failureMessage: t("workflowMessages.startReviewErrorMessage", {
      ns: "notifications",
    }),
  });

  const onFormSubmit = useCallback(
    async (data: TStartReviewModalForm) => {
      const payloadData = {
        originator: user?.id || "",
        project: projectId,
        assignees: data.members
          ?.filter((member) => !member.disabled)
          ?.map((member) => member.value),
        assets: data.components,
        dueDate: formatDate(data.dueDate, ISO_DATE_FORMAT),
        instructions: data.instructions,
      };

      startWorkflow(
        {
          id: WorkflowId.StartSponsorReview,
          payload: payloadData,
        },
        {
          onSuccess: () => {
            onClose(true);
            // TODO: Verify if this is intended UX
            navigate(Path.Projects);
          },
        },
      );

      reset();
    },
    [user, projectId, startWorkflow, navigate, onClose, reset],
  );

  const handleClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const cancelButton = (
    <Button
      key="cancel"
      data-qaid="start-review-cancel-button"
      onClick={handleClose}
    >
      {t("button.cancel", { ns: "common" })}
    </Button>
  );

  const saveButton = (
    <Button
      key="submit"
      data-testid="start-review-submit-button"
      variant="contained"
      disabled={!formState.isValid}
      type="submit"
    >
      {isLoading ? <Loading /> : t("button.sendTask", { ns: "common" })}
    </Button>
  );

  return (
    <Modal
      data-qaid="start-review-modal"
      title={t("documentHeader.actions.startSponsorReview")}
      open={isOpen}
      sx={styles.modal}
    >
      <FormWrapper
        methods={methods}
        onSubmit={handleSubmit(onFormSubmit)}
        sx={styles.formContainer}
      >
        <DocumentSectionsSelect<TStartReviewModalForm>
          data-qaid="start-review-document-section-multi-select"
          dropdownOptions={documentOutline}
          name={StartReviewModalField.Components}
          label={t("startReviewModal.sectionsInputLabel")}
          helperText={t("startReviewModal.sectionsHelperLabel")}
          isSelectAllEnabled
          selectAllLabel={t("startReviewModal.selectAllSectionsLabel")}
        />
        <TeamMembers />
        <FormDate<TStartReviewModalForm>
          data-qaid="start-review-due-date-field"
          name={StartReviewModalField.DueDate}
          label={t("startReviewModal.dateLabel")}
          sx={{ width: 250 }}
          disablePast
        />
        <FormTextInput<TStartReviewModalForm>
          data-qaid="start-review-info-text-field"
          name={StartReviewModalField.Instructions}
          label={t("startReviewModal.infoInputLabel")}
        />
        <ModalActionButtons buttons={[cancelButton, saveButton]} />
      </FormWrapper>
    </Modal>
  );
};

export default StartReviewModal;
