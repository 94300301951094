import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, MenuItem } from "@mui/material";
import Button from "components/shared/Button";
import Modal, { TModalProps } from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import Select from "components/shared/Select";
import useTimeZones from "hooks/api/REST/user/useTimeZones";
import useUpdateUser from "hooks/api/REST/user/useUpdateUser";
import useUser from "hooks/useUser";
import styles from "./UpdateTimeZoneModal.styles";

export type TUpdateTimezoneModalProps = Pick<TModalProps, "open"> & {
  currentTimeZone: string;
  onClose?: () => void;
  onSave?: () => void;
};

type TUpdateTimeZoneForm = {
  newTimeZone: string;
};

const UpdateTimeZoneModal = (props: TUpdateTimezoneModalProps) => {
  const { open, currentTimeZone, onSave, onClose } = props;
  const { user } = useUser();
  const { updateUser } = useUpdateUser();
  const { timezones } = useTimeZones();

  const { t } = useTranslation(["user", "common"]);

  const onSubmit = async ({ newTimeZone }: TUpdateTimeZoneForm) => {
    if (user) {
      await updateUser({
        ...user,
        preferences: { ...user?.preferences, timezone: newTimeZone },
      });
    }

    onSave?.();
    reset();
  };

  const { handleSubmit, register, reset } = useForm<TUpdateTimeZoneForm>();

  const handleClose = useCallback(() => {
    onClose?.();
    reset();
  }, [onClose, reset]);

  return (
    <Modal
      data-qaid="update-timezone-modal"
      title={t("profileUpdateTimeZoneModal.modalTitle")}
      open={open}
    >
      <Box
        sx={styles.content}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Select
          {...register("newTimeZone")}
          id="time-zone"
          data-qaid="time-zone-select"
          label={t("profileUpdateTimeZoneModal.newTimeZoneLabel")}
          sx={styles.select}
          defaultValue={currentTimeZone}
        >
          {timezones?.map((timezone) => (
            <MenuItem key={timezone} value={timezone}>
              {timezone}
            </MenuItem>
          ))}
        </Select>
        <ModalActionButtons>
          <Button
            key="cancel-button"
            data-qaid="cancel-button"
            onClick={handleClose}
          >
            {t("button.cancel", { ns: "common" })}
          </Button>
          <Button
            key="save-button"
            data-qaid="save-button"
            variant="contained"
            type="submit"
          >
            {t("button.save", { ns: "common" })}
          </Button>
        </ModalActionButtons>
      </Box>
    </Modal>
  );
};

export default UpdateTimeZoneModal;
