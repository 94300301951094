import { useCallback, useEffect, useMemo, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { TMultiSelectTypeaheadOption } from "components/shared/MultiSelectTypeahead";
import { IProjectMember } from "models/projectMembers.models";
import { IUserGroupData } from "models/userGroup.models";
import {
  StartReviewModalField,
  TStartReviewModalForm,
} from "screens/Project/sections/Documents/Document/DocumentActionModals/StartReviewModal.types";
import {
  getDisabledOptions,
  getEnabledSelectedOptions,
  getSelectedGroup,
  mapGroups,
  mapMembers,
  mapMembersBySelectedGroup,
} from "screens/Project/sections/Documents/Document/DocumentActionModals/StartReviewModal.utils";

type TUseTeamMembersSelect = {
  members: IProjectMember[] | undefined;
  setValue: UseFormSetValue<TStartReviewModalForm>;
  memberGroups: IUserGroupData[] | null;
};

export const useTeamMembersSelect = ({
  setValue,
  memberGroups,
  members,
}: TUseTeamMembersSelect) => {
  const [allOptions, setAllOptions] = useState<TMultiSelectTypeaheadOption[]>(
    [],
  );

  const groupOptions: TMultiSelectTypeaheadOption[] | null = useMemo(
    () => mapGroups(memberGroups),
    [memberGroups],
  );

  const onMembersChange = useCallback(
    (selectedOptions: TMultiSelectTypeaheadOption[]) => {
      const selectedGroup = getSelectedGroup(memberGroups, selectedOptions);

      if (memberGroups && members) {
        const updatedMembers = mapMembersBySelectedGroup(
          selectedGroup,
          members,
        );

        //update disabled members in options
        setAllOptions([...groupOptions, ...updatedMembers]);

        const disabledSelectedOptions = getDisabledOptions(updatedMembers);

        const enabledSelectedOptions = getEnabledSelectedOptions(
          selectedOptions,
          disabledSelectedOptions,
        );

        return setValue(
          StartReviewModalField.Members,
          [...enabledSelectedOptions, ...disabledSelectedOptions],
          { shouldValidate: true },
        );
      }

      setValue(StartReviewModalField.Members, selectedOptions, {
        shouldValidate: true,
      });
    },
    [groupOptions, memberGroups, setValue, members],
  );

  useEffect(() => {
    if (members) {
      setAllOptions([...groupOptions, ...mapMembers(members)]);
    }
  }, [groupOptions, members]);

  return { allOptions, onMembersChange };
};
