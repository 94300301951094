import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  actionMeta: {
    fontSize: "14px",
    minWidth: "120px",
    margin: "auto",
  },

  counterText: {
    fontWeight: "500",
    color: getPaletteColor("text.secondary"),
    pr: 2,
  },
};

export default styles;
