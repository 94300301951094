import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    mb: "20px",
  },

  documentLink: {
    mt: "20px",
    mb: "20px",
    ml: "10px",

    "a, .MuiLink-button": {
      textDecoration: "none",
      fontSize: "16px",
      fontWeight: "400",
      textAlign: "left",
    },
  },
};

export default styles;
