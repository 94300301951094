import dayjs from "dayjs";
import { useCountdown } from "./hooks/useCountdown";

type TCountdownProps = {
  remainingTime: number;
};

const Countdown = ({ remainingTime }: TCountdownProps) => {
  const time = useCountdown(remainingTime);

  return <>{dayjs(time).format("mm:ss")}</>;
};

export default Countdown;
