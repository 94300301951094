import { TFunction } from "i18next";
import { IOrganization } from "models/organizations.models";
import { getCountryName } from "utils/helpers";
import { defaultCompare } from "utils/helpers/defaultCompare";

export const getOrganizationOptions = (
  organizations: IOrganization[] | undefined,
  t: TFunction,
) =>
  organizations
    ?.map((organization: IOrganization) => ({
      id: organization.id,
      label: `${organization.name} (${getCountryName(
        organization.country,
        t,
      )})`,
      value: organization.tenantId,
    }))
    .sort((a, b) => defaultCompare(a.label, b.label)) ?? [];
