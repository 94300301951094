import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, Stack } from "@mui/material";
import {
  FormRichTextEditor,
  FormTextInput,
} from "components/common/form-fields";
import { HAQFieldName } from "utils/constants/HAQ.constants";
import { THAQForm, THAQParentData } from "../AddHAQDrawer.types";
import { DocumentsField, DomainField, TopicField } from "../Fields";
import styles from "./Content.styles";

export type TContentProps = {
  projectId: string | undefined;
  parent: THAQParentData;
};

const Content = ({ projectId, parent }: TContentProps) => {
  const { t } = useTranslation(["HAQ", "common"], {
    keyPrefix: "addHAQModal",
  });

  const { watch } = useFormContext();

  const selectedDomain = watch(HAQFieldName.Domain);

  return (
    <Stack gap={2} justifyContent="flex-start" sx={styles.contentContainer}>
      <DocumentsField projectId={projectId} parent={parent} />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <DomainField />
        </Grid>
        <Grid item xs={4}>
          <TopicField domain={selectedDomain} />
        </Grid>

        <Grid item xs={4}>
          <FormTextInput<THAQForm>
            data-qaid="add-haq-reference-number-text-field"
            name={HAQFieldName.ReferenceNumber}
            label={t("fields.referenceNumber.label")}
            InputProps={{
              title: t("fields.referenceNumber.title"),
            }}
          />
        </Grid>
      </Grid>
      <FormTextInput<THAQForm>
        data-qaid="add-haq-content-reference-text-field"
        name={HAQFieldName.ContentReference}
        rows={2}
        multiline
        label={t("fields.contentReference.label")}
        InputProps={{
          title: t("fields.contentReference.title"),
        }}
      />
      <FormRichTextEditor<THAQForm>
        placeholder={t("fields.content.placeholder")}
        componentId="new-question"
        name={HAQFieldName.Content}
        sx={styles.contentEditor}
      />
    </Stack>
  );
};

export default Content;
