import { TStyles } from "utils/Styles";

const styles: TStyles = {
  buttonContainer: {
    flex: "0 0 auto",
  },

  container: {
    p: (theme) => theme.spacing(2.5, 6, 7, 6),
  },

  footerContainer: {
    pl: 3,
  },

  signOutButton: {
    whiteSpace: "nowrap",
  },

  subContainer: {
    mt: 2,
    mb: 6,
  },

  stack: {
    flex: "1 1 auto",
    maxWidth: 820,
    minWidth: 0,
  },
};

export default styles;
