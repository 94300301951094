import dayjs from "dayjs";
import { TISODateFormat } from "utils/types/common";

type TDatePossibleType = Date | number | string | TISODateFormat | null;

export const isDateValid = (date: TDatePossibleType) => {
  return dayjs(date).isValid();
};

export const isDateInFuture = (date: TDatePossibleType) => {
  return dayjs().isSame(date, "day") || dayjs(date).isAfter(dayjs());
};

export const isSameDateTime = (
  dateA: TDatePossibleType,
  dateB: TDatePossibleType,
) => {
  return dayjs(dateA).isSame(dateB);
};
