import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ButtonMenu, { TMenuOption } from "components/shared/ButtonMenu";
import styles from "./ChangeLanguageMenu.styles";

type TChangeLanguageMenuProps = {
  isSidebarExpanded: boolean;
};

const ChangeLanguageMenu = ({
  isSidebarExpanded,
}: TChangeLanguageMenuProps) => {
  const { t, i18n } = useTranslation("user", {
    keyPrefix: "notificationsGlobalNavigation",
  });

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const actionOptions: TMenuOption[] = [
    {
      id: "en",
      label: t("languageMenuOptions.english"),
      onClick: () => changeLanguage("en"),
    },
    {
      id: "es",
      label: t("languageMenuOptions.spanish"),
      onClick: () => changeLanguage("es"),
    },
    {
      id: "fr",
      label: t("languageMenuOptions.french"),
      onClick: () => changeLanguage("fr"),
    },
    {
      id: "ja",
      label: t("languageMenuOptions.japanese"),
      onClick: () => changeLanguage("ja"),
    },
  ];

  return (
    <ListItem disablePadding>
      <ButtonMenu options={actionOptions} data-qaid="change-language-button">
        <ListItemButton sx={styles.navItem}>
          <ListItemIcon>
            <LanguageIcon sx={styles.languageIcon} />
          </ListItemIcon>
          {isSidebarExpanded && (
            <ListItemText
              primary={t("languageMenu")}
              sx={styles.navMenuItemButtonText}
            />
          )}
        </ListItemButton>
      </ButtonMenu>
    </ListItem>
  );
};

export default ChangeLanguageMenu;
