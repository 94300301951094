import { TLinkedOrganizationEntity } from "../components/Organization/Organization.types";

export const mapRoleTypesToSelectedOrgs = (
  existingOrgs?: TLinkedOrganizationEntity[],
) => {
  return (
    existingOrgs?.flatMap(
      ({ id, roleTypes }) => roleTypes?.map((type) => `${id}-${type}`) ?? [],
    ) ?? []
  );
};
