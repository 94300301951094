import { useTranslation } from "react-i18next";
import { ArrowDropDown } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { Button } from "components/shared";
import ButtonMenu from "components/shared/ButtonMenu";
import { TDocumentHeaderProps } from "./DocumentHeader";
import { HeaderActionIcons } from "./HeaderActionIcons";
import { HeaderModeSelect } from "./HeaderModeSelect";
import styles from "./DocumentHeader.styles";

export const DocumentHeaderActions = ({
  actionOptions,
  viewMode,
  onViewModeChange,
  isActionsDisabled,
  activeActionOptionId,
  documentSubtype,
}: Pick<
  TDocumentHeaderProps,
  | "actionOptions"
  | "viewMode"
  | "onViewModeChange"
  | "activeActionOptionId"
  | "isActionsDisabled"
  | "documentSubtype"
>) => {
  const { t } = useTranslation("documents", {
    keyPrefix: "documentHeader",
  });

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <HeaderModeSelect
        viewMode={viewMode}
        onViewModeChange={onViewModeChange}
        isActionsDisabled={isActionsDisabled}
        documentSubtype={documentSubtype}
      />
      <HeaderActionIcons />
      <ButtonMenu
        value={activeActionOptionId}
        options={actionOptions}
        buttonProps={{
          endIcon: <ArrowDropDown />,
          variant: "contained",
          sx: styles.actionsButton,
        }}
      >
        <Button data-qaid="document-header-actions-button" variant="contained">
          {t("actionsButton")}
        </Button>
      </ButtonMenu>
    </Stack>
  );
};
