import { SetStateAction, useState } from "react";
import { PaginationState } from "@tanstack/react-table";
import isFunction from "lodash/isFunction";
import { TPaginationParams } from "components/shared/Table/types";
import { defaultPagination } from "./constants";

const getBasePaginationState = (
  initialPagination?: Partial<PaginationState>,
) => ({
  ...defaultPagination,
  ...initialPagination,
});

type TUseManualPaginationResult = {
  pagination: PaginationState;
  paginationParams: TPaginationParams;
  setPagination: (updaterOrValue: SetStateAction<PaginationState>) => void;
  resetPagination: () => void;
};

const useManualPagination = (
  initialPagination?: Partial<PaginationState>,
): TUseManualPaginationResult => {
  const [paginationState, setPaginationState] = useState<PaginationState>(
    getBasePaginationState(initialPagination),
  );

  const paginationParams: TPaginationParams = {
    page: paginationState.pageIndex,
    size: paginationState.pageSize,
  };

  const setPagination = (updaterOrValue: SetStateAction<PaginationState>) => {
    setPaginationState((prev) => {
      const { pageIndex, pageSize } = isFunction(updaterOrValue)
        ? updaterOrValue(prev)
        : updaterOrValue;

      return {
        pageIndex: pageSize !== prev.pageSize ? 0 : pageIndex,
        pageSize,
      };
    });
  };

  const resetPagination = () => {
    setPaginationState((prev) => ({
      pageIndex: getBasePaginationState(initialPagination).pageIndex,
      pageSize: prev.pageSize,
    }));
  };

  return {
    pagination: paginationState,
    paginationParams,
    setPagination,
    resetPagination,
  };
};

export default useManualPagination;
