import { IDocument } from "models/documents.models";
import { IOrganization } from "models/organizations.models";
import {
  DOCUMENT_TYPES_FOR_DOWNLOAD,
  DOCUMENT_TYPES_FOR_PREVIEW,
  DocumentStatus,
  DocumentSubtype,
} from "utils/constants/doc.constants";
import { isHAUser } from "utils/user/organization";

export const getIsDocumentUnstructuredAAID = (document: IDocument | null) =>
  document?.status === DocumentStatus.Uploaded &&
  document?.type === DocumentSubtype.AssessmentAidSource;

export const getIsDocumentStructuredAAID = (document: IDocument | null) =>
  document?.type === DocumentSubtype.AssessmentAid &&
  document?.status === DocumentStatus.Draft;

export const getIsDocumentComposite = (document: IDocument | null) =>
  document?.type === DocumentSubtype.GSP ||
  document?.type === DocumentSubtype.AssessmentAid;

export const getIsDocumentTypeSupportedForPreview = (docType?: string) =>
  DOCUMENT_TYPES_FOR_PREVIEW.some((type) => type === docType);

export const getIsDocumentTypeSupportedForDownload = (docType?: string) =>
  DOCUMENT_TYPES_FOR_DOWNLOAD.some((type) => type === docType);

export const getIsDocumentReadyForSending = (
  status: DocumentStatus | undefined,
  organization: IOrganization | undefined,
) =>
  [DocumentStatus.Final, DocumentStatus.Cleared].includes(
    status as DocumentStatus,
  ) && isHAUser(organization);
