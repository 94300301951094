import { getIsFeatureOn } from "components/Auth/accessChecks";
import useFeatureFlags from "context/FeatureFlagsContext/useFeatureFlags";

const useShouldShowProjectDetails = () => {
  const featureFlags = useFeatureFlags();

  return getIsFeatureOn("showProjectDetails")({ featureFlags });
};

export default useShouldShowProjectDetails;
