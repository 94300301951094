import { TStyles } from "utils/Styles";

const styles: TStyles = {
  documentContainer: {
    px: 5,
    py: 2.5,
    overflow: "auto",
    flexGrow: 1,
  },
};

export default styles;
