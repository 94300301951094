import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const NOTIFICATION_MIN_WIDTH = 326;
const NOTIFICATION_MAX_WIDTH = 489;

const styles: TStyles = {
  alert: {
    alignItems: "stretch",
    backgroundColor: getPaletteColor("blueGrey.900"),
    p: 0,
    minWidth: NOTIFICATION_MIN_WIDTH,
    maxWidth: NOTIFICATION_MAX_WIDTH,
    color: getPaletteColor("common.white"),
    mr: 6,
    mb: -1,

    ".MuiAlert-message": {
      p: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flex: 1,
      pl: 0,
    },

    ".MuiAlert-action": {
      alignItems: "center",
      p: 0,
      m: 0,
      borderLeft: getBorderStyle("1px", "solid", "blueGrey.700"),

      ".MuiButton-text": {
        color: getPaletteColor("blue.200"),
      },
    },

    ".MuiAlert-icon": {
      p: 1,
      mr: 0.5,
      alignItems: "center",
    },

    ".MuiAlert-icon > div": {
      color: getPaletteColor("common.white"),
      width: 40,
      height: 40,
      borderRadius: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },

    ".MuiButtonBase-root": {
      px: 1.5,
    },
  },

  title: {
    fontSize: 14,
  },

  messageWithTitle: {
    fontSize: 12,
    color: getPaletteColor("blueGrey.100"),
  },

  errorIconBox: {
    backgroundColor: getPaletteColor("red.500"),
  },

  successIconBox: {
    backgroundColor: getPaletteColor("green.400"),
  },

  warningIconBox: {
    backgroundColor: getPaletteColor("orange.400"),
  },

  infoIconBox: {
    backgroundColor: getPaletteColor("lightBlue.400"),
  },
};

export default styles;
