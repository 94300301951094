import { useContext } from "react";
import { TProjectMetadata } from "models/projects.models";
import { ProjectContext, TProjectContextValue } from "./ProjectContext";

const useProject = <T extends TProjectMetadata>(): TProjectContextValue<T> => {
  const context = useContext(ProjectContext) as
    | TProjectContextValue<T>
    | undefined;

  if (context === undefined) {
    throw new Error("useProject must be used within a ProjectProvider");
  }

  return context;
};

export default useProject;
