import { getBorderStyle } from "theme/helpers";
import { TStyles } from "utils/Styles";

const menuItem: TStyles[keyof TStyles] = {
  gap: 1,

  "&.Mui-disabled": {
    opacity: 1,

    "&:hover": {
      backgroundColor: "unset",
    },

    "& .MuiListItemText-root": {
      opacity: 0.38,
    },
  },

  "& .MuiListItemIcon-root": {
    minWidth: "auto",
  },

  "& .MuiListItemText-inset": {
    pl: 3,
  },

  "& .MuiListItemText-dense > .MuiTypography-root": {
    fontFamily: (theme) => theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.71,
    letterSpacing: 0.17,
  },
};

const styles: TStyles = {
  menu: {
    "& .MuiPaper-root": {
      mt: -1,
    },
  },

  menuItem,

  menuItemWithSeparator: {
    ...menuItem,
    borderTop: getBorderStyle("1px", "solid", "divider"),
  },

  adornment: {
    display: "flex",
    alignItem: "center",

    "& .MuiSvgIcon-root": {
      pointerEvents: "auto",
    },
  },
};

export default styles;
