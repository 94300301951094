import { useTranslation } from "react-i18next";
import Modal from "components/shared/Modal";
import { TEntityOrganization } from "../Organization.types";
import OrganizationForm from "../OrganizationForm";

type TEditOrganizationModalProps = {
  onClose: () => void;
  selectedOrganization: TEntityOrganization | null;
};

const EditOrganizationModal = ({
  onClose,
  selectedOrganization,
}: TEditOrganizationModalProps) => {
  const { t } = useTranslation(["administration", "common", "countries"]);

  if (!selectedOrganization) {
    return <></>;
  }

  return (
    <Modal
      data-testid="edit-organization-modal"
      data-qaid="edit-organization-modal"
      title={t("organization.editOrganizationalModal.title")}
      open
    >
      <OrganizationForm
        type={selectedOrganization.type}
        onClose={onClose}
        selectedOrganization={selectedOrganization}
      />
    </Modal>
  );
};

export default EditOrganizationModal;
