import { TStyles } from "utils/Styles";

const styles: TStyles = {
  contentContainer: {
    padding: 3,
    paddingTop: 1,
    height: "100%",
    overflow: "hidden",

    ".MuiFormControl-root": {
      width: "100%",
      minWidth: "unset",
    },
  },

  contentEditor: {
    overflow: "hidden",
  },
};

export default styles;
