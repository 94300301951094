import { useTranslation } from "react-i18next";
import { Paper, Typography } from "@mui/material";
import { TISODateFormat } from "utils/types";
import { getTimeStampColors } from "./DueDateTag.utils";
import useDueTime from "./hooks/useDueTime";
import useDueTimeString from "./hooks/useDueTimeString";
import styles from "./DueDateTag.styles";

export type TDueDateTagProps = {
  dueDate: TISODateFormat;
};

const DueDateTag = ({ dueDate, ...props }: TDueDateTagProps) => {
  const { t, i18n } = useTranslation("common");
  const dueTime = useDueTime(dueDate);
  const timeString = useDueTimeString(dueDate, dueTime, t, i18n.language);

  return (
    <Paper
      sx={[styles.dateTag, getTimeStampColors(dueTime)]}
      elevation={0}
      data-qaid="due-date"
      {...props}
    >
      <Typography noWrap variant="caption" component="p" fontWeight={500}>
        {timeString}
      </Typography>
    </Paper>
  );
};

export default DueDateTag;
