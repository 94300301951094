import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { gql } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { TCreateProductVariantMutationData } from "./useCreateProductVariant.models";

export const query = gql`
  mutation CreateProductVariant(
    $productId: String
    $productVariantBody: ProductVariantBody
  ) {
    createProductVariant(
      productId: $productId
      productVariantBody: $productVariantBody
    ) {
      id
    }
  }
`;

const useCreateProductVariant = () => {
  const { t } = useTranslation("administration", {
    keyPrefix: "notifications",
  });

  const { showAlert } = useAlerts();
  const queryClient = useQueryClient();

  const { data, isError, isLoading, isSuccess, mutateAsync } = useMutation(
    ({ productId, productVariantBody }: TCreateProductVariantMutationData) =>
      services.graphQL
        .request(query, {
          productId,
          productVariantBody,
        })
        .then(() => {
          showAlert({
            message: t("createProductVariantSuccess"),
            severity: AlertSeverity.Success,
          });

          queryClient.invalidateQueries(QueryAPIKey.GetEntityProducts);
        })
        .catch((error) => {
          if (error.response?.errors[0]?.extensions?.response?.status === 409) {
            showAlert({
              message: t("createProductVariantFailureAlreadyExists"),
              severity: AlertSeverity.Error,
            });

            throw error;
          }

          showAlert({
            message: t("createProductVariantFailure"),
            severity: AlertSeverity.Error,
          });

          throw error;
        }),
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    createProductVariant: mutateAsync,
  };
};

export default useCreateProductVariant;
