import { AssetImportType } from "utils/constants/assets.constants";

const getIsReferenceOrExternalHAQ = (
  importType: AssetImportType | undefined,
) => {
  return (
    importType === AssetImportType.External ||
    importType === AssetImportType.Reference
  );
};

export default getIsReferenceOrExternalHAQ;
