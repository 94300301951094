import { Editor } from "ckeditor5";
import { TrackChangesAdapter } from "ckeditor5-premium-features";
import { ICreateSuggestionResponseData } from "models/annotations.models";
import { TSuggestionForCreate } from "../useAddSuggestion";

const addSuggestion =
  (
    editor: Editor,
    mutationFn: (suggestionData: TSuggestionForCreate) => Promise<any>,
  ): TrackChangesAdapter["addSuggestion"] =>
  async (suggestion) => {
    const { id, originalSuggestionId, type, data, attributes, hasComments } =
      suggestion;

    const componentId = editor.config.get("collaboration.channelId")!;

    try {
      const { data: responseData }: { data: ICreateSuggestionResponseData } =
        await mutationFn({
          componentId,
          data: {
            id,
            originalSuggestionId,
            assetId: componentId,
            type,
            ...(data && {
              data: JSON.stringify(data),
            }),
            attributes: attributes as any,
            hasComments,
          },
        });

      return {
        id: responseData.id,
        createdAt: new Date(responseData.createdDate),
        type,
        authorId: responseData.authorId,
        attributes,
      };
    } catch {}

    // CKEditor assumes all requests are successful, so there's nothing we can do about the return
    // value if it isn't. Future work will actually handle the errors and do something more useful
    // than alerting the user.
    return undefined as any;
  };

export default addSuggestion;
