import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  listItem: {
    flexWrap: "wrap",
    width: "inherit",
    pb: 1,
  },

  collapseMenuList: {
    listStyle: "disc",
    pl: 4.5,

    "& a": {
      "&.MuiListItemButton-root": {
        height: "inherit",
      },

      "&.MuiListItemButton-root.active": {
        backgroundColor: "transparent",
      },
    },
  },

  collapseListItem: {
    p: 0,
    my: "2px",
  },

  collapsibleListItemButton: {
    display: "list-item",

    "&::marker": {
      color: "transparent",
    },

    "&.active": {
      "&::marker": {
        visibility: "visible",
        color: getPaletteColor("primary.main"),
      },
    },
  },

  collapsibleListItemButtonText: {
    fontWeight: 400,
    px: 1,
  },
};

export default styles;
