import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
};

export default styles;
