import { TStyles } from "utils/Styles";

const styles: TStyles = {
  formContainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },

  modal: {
    ".MuiDialog-paper": {
      overflowY: "inherit",
    },

    ".MuiButton-outlined": {
      marginRight: "auto",
    },
  },

  loadingIndicatorContainer: {
    position: "absolute",
    zIndex: 100,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  },
};

export default styles;
