import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    "&>div:not(:last-child)": {
      borderBottom: getBorderStyle("1px", "solid", "divider"),
    },
  },

  name: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "1rem",
    fontWeight: 500,
    color: getPaletteColor("grey.700"),
  },

  description: {
    fontFamily: "Inter",
    fontSize: "1rem",
    fontWeight: 400,
    color: "text.primary",
  },

  fixedHeightContainer: {
    maxHeight: "470px",
    overflow: "auto",
    mt: 2.5,
  },
};

export default styles;
