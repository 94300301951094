import { TFunction } from "i18next";
import isEmpty from "lodash/isEmpty";
import * as accessChecks from "components/Auth/accessChecks";
import { TUserContextType } from "context/UserContext";
import { TFeatureFlagsMap } from "models/featureFlags.models";
import { Path } from "router/paths";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import { includeInArray } from "utils/helpers";
import {
  BackpackIcon as PortfolioIcon,
  CheckSquareBrokenIcon as TasksIcon,
  FolderIcon as ProjectsIcon,
  HomeIcon,
  SettingsIcon,
} from "assets/icons";

const getBaseAdminPath = (
  user: TUserContextType,
  featureFlags: TFeatureFlagsMap,
) => {
  if (accessChecks.canManageUsers({ user, featureFlags })) {
    return Path.AdminUserManagement;
  }

  if (accessChecks.canViewEntities({ user, featureFlags })) {
    return Path.AdminEntityManagement;
  }

  if (accessChecks.canResetEnvironmentData({ user, featureFlags })) {
    return Path.AdminReset;
  }

  return Path.Admin;
};

const getAdminChildNavigationItems = (
  t: TFunction,
  user: TUserContextType,
  featureFlags: TFeatureFlagsMap,
) => [
  ...includeInArray(
    {
      key: "admin-account",
      label: t(
        "notificationsMainNavigationList.navItemData.adminOptions.userManagement",
      ),
      href: Path.AdminUserManagement,
    },
    accessChecks.canManageUsers({ user, featureFlags }),
  ),
  ...includeInArray(
    {
      key: "administration-entityManagement",
      label: t(
        "notificationsMainNavigationList.navItemData.administrationOptions.entityManagement",
      ),
      href: Path.AdminEntityManagement,
    },
    accessChecks.canViewEntities({ user, featureFlags }),
  ),
  ...includeInArray(
    {
      key: "admin-users",
      label: t(
        "notificationsMainNavigationList.navItemData.adminOptions.reset",
      ),
      href: Path.AdminReset,
    },
    accessChecks.canResetEnvironmentData({ user, featureFlags }),
  ),
  ...includeInArray(
    {
      key: "admin-audit-trail",
      label: t(
        "notificationsMainNavigationList.navItemData.administrationOptions.auditTrail",
      ),
      href: Path.AdminAuditTrail,
    },
    accessChecks.canManageAuditTrail({ user, featureFlags }),
  ),
];

export const getAdminNavigationItems = (
  t: TFunction,
  user: TUserContextType,
  featureFlags: TFeatureFlagsMap,
) => {
  const childAdminNavigationItems = getAdminChildNavigationItems(
    t,
    user,
    featureFlags,
  );

  return accessChecks.isAdmin({ user, featureFlags }) &&
    !isEmpty(childAdminNavigationItems)
    ? [
        {
          key: "admin",
          label: t("notificationsMainNavigationList.navItemData.admin"),
          icon: (
            <SettingsIcon
              aria-label={t("iconTitles.admin", { ns: "common" })}
            />
          ),
          href: getBaseAdminPath(user, featureFlags),
          items: childAdminNavigationItems,
          disabled: false,
        },
      ]
    : [];
};

export const getNonAdminNavigationItems = (
  t: TFunction,
  user: TUserContextType,
  featureFlags: TFeatureFlagsMap,
) => [
  {
    key: "home",
    label: t("notificationsMainNavigationList.navItemData.home"),
    icon: <HomeIcon aria-label={t("iconTitles.home", { ns: "common" })} />,
    href: Path.HomePage,
    items: [],
    disabled: false,
  },
  ...includeInArray(
    [
      ...includeInArray(
        {
          key: "tasks",
          label: t("notificationsMainNavigationList.navItemData.tasks"),
          icon: (
            <TasksIcon aria-label={t("iconTitles.tasks", { ns: "common" })} />
          ),
          href: Path.Tasks,
          items: [],
          disabled: false,
        },
        accessChecks.shouldShowTasks({ user, featureFlags }),
      ),
      {
        key: "projects",
        label: t("notificationsMainNavigationList.navItemData.projects"),
        icon: (
          <ProjectsIcon
            aria-label={t("iconTitles.projects", { ns: "common" })}
          />
        ),
        href: Path.Projects,
        items: [],
        disabled: false,
      },
      ...includeInArray(
        {
          key: "portfolio",
          label: t("notificationsMainNavigationList.navItemData.portfolio"),
          icon: (
            <PortfolioIcon
              aria-label={t("iconTitles.portfolio", { ns: "common" })}
            />
          ),
          href: Path.Portfolio,
          items: [],
          disabled: false,
        },
        featureFlags["enableFHIRDocuments"] === FeatureFlag.On,
      ),
    ],
    accessChecks.canAccessRegularApp({ user, featureFlags }),
  ).flat(),
];
