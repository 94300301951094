import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  headerContainer: {
    backgroundColor: getPaletteColor("common.white"),
    p: (theme) => theme.spacing(1, 4, 1.5, 4),
    boxShadow: (theme) => theme.shadows[4],
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    columnGap: "1rem",
    borderTop: getBorderStyle("2px", "solid", "blueGrey.50"),
    position: "relative",
    zIndex: 1,

    "button:disabled": {
      background: getPaletteColor("blueGrey.50"),
    },
  },
};

export default styles;
