import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { IGetDocumentsRequestParams } from "models/documents.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

const useFetchDocuments = (params?: IGetDocumentsRequestParams) => {
  const queryClient = useQueryClient();

  const fetchDocuments = useCallback(
    () =>
      queryClient.fetchQuery({
        queryKey: [QueryAPIKey.GetDocuments],
        queryFn: () =>
          services.documents
            .getDocuments(params)
            .then((res) => res.data?.content),
      }),
    [queryClient, params],
  );

  return {
    fetchDocuments,
  };
};

export default useFetchDocuments;
