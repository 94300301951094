import { isEmpty } from "lodash";
import { Role, ROLES_PRIORITIES } from "utils/constants/users.constants";

// ToDo (AV): Doing this on the FE may not be necessary in future. We will have to revisit this after the pilot
export const getSortedRolesByRank = (roles?: Role[]): Role[] => {
  if (isEmpty(roles)) {
    return [];
  }

  return roles!.sort(
    (prev, next) => ROLES_PRIORITIES[prev] - ROLES_PRIORITIES[next],
  );
};
