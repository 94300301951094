import { useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Box, Divider } from "@mui/material";
import Tabs, { Tab } from "components/shared/Tabs";
import { TableSearchURLParams } from "../ReactTable.types";
import styles from "./ReactTableTabs.styles";

export type TReactTableTabs = { label: string; accessor: string };

export type TReactTableTabsProps = {
  isControlled: boolean;
  tabs: TReactTableTabs[];
  onChange?: (newValue?: string) => void;
  value?: string;
};

function ReactTableTabs({
  tabs,
  onChange,
  value,
  isControlled,
}: TReactTableTabsProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const defaultValue = tabs[0]?.accessor || null;
  const tabValueFromUrl = searchParams.get(TableSearchURLParams.Tab);

  const isValidTabFromUrl = tabs.some(
    (tab) => tab.accessor === tabValueFromUrl,
  );

  const [activeValue, setActiveValue] = useState<string | null>(
    isValidTabFromUrl ? tabValueFromUrl : defaultValue,
  );

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    if (!isControlled) {
      setSearchParams(
        new URLSearchParams({ [TableSearchURLParams.Tab]: newValue }),
      );

      onChange?.();
    }

    onChange?.(newValue);
  };

  useEffect(() => {
    if (!isControlled) {
      const activeTab = isValidTabFromUrl ? tabValueFromUrl : activeValue;

      const activeSearchParams = activeTab
        ? createSearchParams({
            [TableSearchURLParams.Tab]: activeTab,
          }).toString()
        : "";

      navigate(
        {
          search: activeSearchParams,
        },
        { replace: true },
      );
      setActiveValue(activeTab);
    }
  }, [activeValue, isValidTabFromUrl, navigate, tabValueFromUrl, isControlled]);

  return (
    <>
      <Box>
        <Tabs
          value={isControlled ? value ?? defaultValue : activeValue}
          aria-label="table-tabs"
          sx={styles.tabs}
          onChange={handleChange}
        >
          {tabs?.map(({ label, accessor }) => (
            <Tab
              key={label}
              value={accessor}
              label={label}
              tabIndex={0}
              disableFocusRipple
              sx={styles.tab}
            />
          ))}
        </Tabs>
      </Box>
      <Divider sx={styles.divider} />
    </>
  );
}

export default ReactTableTabs;
