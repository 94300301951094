import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    p: (theme) => theme.spacing(2.5, 4, 2.5, 4),
  },

  atcHelpIcon: {
    mt: 1,
  },
};

export default styles;
