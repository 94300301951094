export const getQueryKey = (endpointName: string) => {
  return `${endpointName}_healthcheck`;
};

export const loadEndpointWithResponseTime = async (
  apiCall: Function,
): Promise<{ data: any; responseTimeMs: number }> => {
  const startTime = window.performance.now();
  const response = await apiCall();
  const data = response.data;

  return { data: data, responseTimeMs: window.performance.now() - startTime };
};
