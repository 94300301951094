import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import Button from "components/shared/Button";
import Loading from "components/shared/Loading";
import styles from "./GSPSubHeader.styles";

export type TGSPSubHeaderProps = {
  onCancel: () => void;
  onSave: () => void;
  saveDisabled?: boolean;
  isLoading: boolean;
};

export const GSPSubHeader = ({
  onCancel,
  onSave,
  saveDisabled,
  isLoading,
}: TGSPSubHeaderProps) => {
  const { t } = useTranslation("common");

  return (
    <Box sx={styles.headerContainer}>
      <Button data-qaid="gsp-subheader-cancel-button" onClick={onCancel}>
        {t("button.cancel")}
      </Button>
      <Button
        data-qaid="gsp-subheader-save-button"
        onClick={onSave}
        variant="outlined"
        disabled={saveDisabled}
      >
        {isLoading ? <Loading /> : t("button.save")}
      </Button>
    </Box>
  );
};

export default GSPSubHeader;
