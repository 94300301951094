import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ConfirmModal } from "components/shared";
import {
  TConfirmContextValue,
  TConfirmModalCallbacks,
  TConfirmModalOptions,
} from "./ConfirmContext.types";

export const ConfirmContext = createContext<TConfirmContextValue | undefined>(
  undefined,
);

const DEFAULT_OPTIONS: TConfirmModalOptions = {
  title: "",
  confirmButtonText: "",
  cancelButtonText: "",
};

export const ConfirmProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation("common");

  const [isOpen, setIsOpen] = useState(false);

  const optionsRef = useRef<TConfirmModalOptions>({});
  const onCancel = useRef<() => void>(() => {});
  const onConfirm = useRef<() => void>(() => {});

  const handleConfirm = () => {
    setIsOpen(false);
    onConfirm.current?.();
  };

  const handleCancel = () => {
    setIsOpen(false);
    onCancel.current?.();
  };

  const attemptConfirm = useCallback(
    (
      customOptions: TConfirmModalOptions,
      callbacks: TConfirmModalCallbacks,
    ) => {
      const mergedOptions: TConfirmModalOptions = {
        ...DEFAULT_OPTIONS,
        ...customOptions,
      };

      optionsRef.current = mergedOptions;

      if (callbacks?.onConfirm) {
        onConfirm.current = callbacks.onConfirm;
      }

      if (callbacks?.onCancel) {
        onCancel.current = callbacks.onCancel;
      }

      setIsOpen(true);
    },
    [],
  );

  const onConclude = (shouldConfirm: boolean) =>
    shouldConfirm ? handleConfirm() : handleCancel();

  const { confirmButtonText, cancelButtonText, title, confirmColor, content } =
    optionsRef.current;

  return (
    <ConfirmContext.Provider value={{ attemptConfirm }}>
      {children}
      <ConfirmModal
        open={isOpen}
        onConclude={onConclude}
        title={title || t("pleaseConfirm")}
        confirmButtonText={confirmButtonText || t("button.confirm")}
        cancelButtonText={cancelButtonText || t("button.cancel")}
        confirmColor={confirmColor}
      >
        {content}
      </ConfirmModal>
    </ConfirmContext.Provider>
  );
};

export const useConfirm = (): TConfirmContextValue => {
  const context = useContext(ConfirmContext);

  if (context === undefined) {
    throw new Error("useConfirm must be used within an ConfirmProvider");
  }

  return context;
};
