import { TFunction } from "i18next";
import { TMenuOption } from "components/shared/ButtonMenu";
import { TUserPermissions } from "hooks/permissions/usePermissions.types";
import { IDocument } from "models/documents.models";
import { IOrganization } from "models/organizations.models";
import { TUserData } from "models/users.models";
import { DocumentStatus } from "utils/constants/doc.constants";
import { TenantType } from "utils/constants/users.constants";
import {
  getDisplayedAssetStatus,
  getIsAssetCreatedByUser,
  getIsAssetOwnedByUserOrganization,
} from "utils/helpers";
import { isHAUser } from "utils/user/organization";
import { getIsDocumentComposite } from "./document";

export const getAvailableDocumentStatusChangeOptions = (
  document: IDocument,
  organization: IOrganization | undefined,
  user: TUserData | undefined,
  permissions: Partial<TUserPermissions>,
  t: TFunction,
) => {
  const canUserChangeState = getIsAssetCreatedByUser(document, user)
    ? permissions.canChangeStateContent
    : permissions.canChangeStateContentInAssociatedProjects;

  if (!canUserChangeState) {
    return [];
  }

  if (
    getIsAssetOwnedByUserOrganization(document, organization) &&
    !getIsDocumentComposite(document)
  ) {
    return getDocumentStatusChangeOptions(
      document.status,
      organization,
      t,
      permissions,
    );
  } else {
    return [];
  }
};

const getDocumentStatusTransitionMappingForHA = (
  status: DocumentStatus,
  permissions: Partial<TUserPermissions>,
) => {
  switch (status) {
    case DocumentStatus.Draft:
      return [DocumentStatus.Final, DocumentStatus.Cancelled];
    case DocumentStatus.Reviewed:
      return [DocumentStatus.Draft, DocumentStatus.Final];
    case DocumentStatus.Final:
      return permissions.canChangeStateContent ? [DocumentStatus.Draft] : [];
    default:
      return [];
  }
};

const getDocumentStatusTransitionMappingForSponsor = (
  status: DocumentStatus,
  permissions: Partial<TUserPermissions>,
) => {
  switch (status) {
    case DocumentStatus.Draft:
      return [DocumentStatus.Reviewed, DocumentStatus.Final];
    case DocumentStatus.Reviewed:
      return [DocumentStatus.Draft];
    case DocumentStatus.InVerification:
      return permissions.canChangeStateContent ? [DocumentStatus.Final] : [];
    default:
      return [];
  }
};

export const getDocumentStatusChangeOptions = (
  status: DocumentStatus,
  organization: IOrganization | undefined,
  t: TFunction,
  permissions: Partial<TUserPermissions>,
) => {
  const possibleTransitions = isHAUser(organization)
    ? getDocumentStatusTransitionMappingForHA(status, permissions) || []
    : getDocumentStatusTransitionMappingForSponsor(status, permissions) || [];

  if (!possibleTransitions.length) {
    return [];
  }

  const currentStatus: TMenuOption = {
    id: status,
    label: getDisplayedAssetStatus(organization?.type, status, t),
  };

  return [
    currentStatus,
    ...possibleTransitions.reduce((options: TMenuOption[], status) => {
      const transitionOptions = getDocumentStatusTransitionOptions(
        organization?.type,
        t,
      )[status];

      return transitionOptions
        ? [...options, transitionOptions as TMenuOption]
        : options;
    }, []),
  ];
};

const getDocumentStatusTransitionOptions = (
  tenantType: TenantType | undefined,
  t: TFunction,
): {
  [key in DocumentStatus]?: TMenuOption;
} => ({
  [DocumentStatus.Draft]: {
    id: DocumentStatus.Draft,
    label: getDisplayedAssetStatus(tenantType, DocumentStatus.Draft, t),
  },
  [DocumentStatus.Final]: {
    id: DocumentStatus.Final,
    label: getDisplayedAssetStatus(tenantType, DocumentStatus.Final, t),
  },
  [DocumentStatus.Cancelled]: {
    id: DocumentStatus.Cancelled,
    label: getDisplayedAssetStatus(tenantType, DocumentStatus.Cancelled, t),
  },
  [DocumentStatus.Reviewed]: {
    id: DocumentStatus.Reviewed,
    label: getDisplayedAssetStatus(tenantType, DocumentStatus.Reviewed, t),
  },
  [DocumentStatus.InVerification]: {
    id: DocumentStatus.InVerification,
    label: getDisplayedAssetStatus(
      tenantType,
      DocumentStatus.InVerification,
      t,
    ),
  },
});
