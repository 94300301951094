import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  FormWrapper,
  Modal,
  ModalActionButtons,
} from "components/shared";
import useCreateProductVariant from "hooks/api/GQL/entityManagement/useCreateProductVariant";
import useUpdateProductVariant from "hooks/api/GQL/entityManagement/useUpdateProductVariant";
import { TEntityProduct, TProductVariant } from "../Products.types";
import ProductVariantFormFields from "../ProductVariantFormFields";
import { TProductVariantForm } from "../ProductVariantFormFields/ProductVariantFormFields.types";
import {
  createProductVariantFormSchema,
  createProductVariantFormValues,
} from "../ProductVariantFormFields/ProductVariantFormFields.utils";
import { mapFormValuesToVariantFields } from "./ManageProductVariantModal.utils";

export type TManageProductVariantModalProps = {
  isOpen: boolean;
  product?: TEntityProduct;
  variantToEdit?: TProductVariant;
  onClose: () => void;
};

const ManageProductVariantModal = ({
  isOpen,
  product,
  variantToEdit,
  onClose,
}: TManageProductVariantModalProps) => {
  const { t } = useTranslation(["administration", "common"]);

  const { updateProductVariant, isLoading: isProductVariantUpdating } =
    useUpdateProductVariant();

  const { createProductVariant, isLoading: isProductVariantCreating } =
    useCreateProductVariant();

  const isEditMode = Boolean(variantToEdit);

  const modalTitle = t(
    isEditMode
      ? "manageProductVariantModal.editProductVariantTitle"
      : "manageProductVariantModal.createNewProductVariantTitle",
    {
      ns: "administration",
      productName: product?.tradeName,
    },
  );

  const defaultValues = useMemo(
    () => createProductVariantFormValues(variantToEdit),
    [variantToEdit],
  );

  const methods = useForm<TProductVariantForm>({
    mode: "all",
    defaultValues: defaultValues,
    resolver: zodResolver(createProductVariantFormSchema(t, isEditMode)),
  });

  const { formState, handleSubmit, reset } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const onSubmit = (values: TProductVariantForm) => {
    const formattedVariantData = mapFormValuesToVariantFields(values);

    if (product && variantToEdit && isEditMode) {
      const mutationData = {
        productId: product.id,
        productVariantId: variantToEdit.id,
        productVariantBody: {
          ...formattedVariantData,
          id: variantToEdit.id,
        },
      };

      updateProductVariant(mutationData).then(onCloseManageProductVariant);
    }

    if (product && !isEditMode) {
      const mutationData = {
        productId: product.id,
        productVariantBody: formattedVariantData,
      };

      createProductVariant(mutationData).then(onCloseManageProductVariant);
    }
  };

  const onCloseManageProductVariant = () => {
    reset();
    onClose();
  };

  return (
    <Modal maxWidth="md" open={isOpen} title={modalTitle}>
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <ProductVariantFormFields isEditMode={isEditMode} />
        <ModalActionButtons>
          <Button
            data-qaid="manage-product-variant-cancel-button"
            key="cancel"
            variant="text"
            onClick={onCloseManageProductVariant}
          >
            {t("button.cancel", { ns: "common" })}
          </Button>
          <Button
            key="submit-button"
            data-qaid="manage-product-variant-submit-button"
            variant="contained"
            loading={isProductVariantCreating || isProductVariantUpdating}
            disabled={!formState.isValid || (isEditMode && !formState.isDirty)}
            type="submit"
          >
            {t("button.submit", { ns: "common" })}
          </Button>
        </ModalActionButtons>
      </FormWrapper>
    </Modal>
  );
};

export default ManageProductVariantModal;
