import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    width: "100%",
    px: "25px",
    pt: "24px",
    pb: "27px",
    borderBottom: getBorderStyle("1px", "solid", "divider"),
  },

  // extra style to be applied for unread notification
  unread: {
    backgroundColor: getPaletteColor("custom.hover"),
  },

  iconParentContainer: {
    minWidth: "40px",
    height: "40px",
    display: "block",
    position: "relative",

    // These colors were not defined in design so hard coding for now
    // https://www.figma.com/file/4Bd7ipnY9SwYWGFHDat90H/MUI-Kit-v5.4.0---Accumulus-Version?node-id=6649%3A50219
    color: getPaletteColor("blueGrey.800"),
    backgroundColor: getPaletteColor("teal.50"),

    borderRadius: "4px",
  },

  newIndicator: {
    position: "absolute",
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
  },

  iconContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  contentContainer: {},

  actionContainer: {
    "& a": {
      display: "inline-block",
    },
  },

  templateText: {
    whiteSpace: "pre-line",
  },

  notificationCaption: {
    color: getPaletteColor("text.secondary"),
  },
};

export default styles;
