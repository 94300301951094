import { AxiosError } from "axios";

const getErrorMessageFromAPI = (error: unknown) => {
  if (error instanceof AxiosError) {
    return error?.response?.data?.message ?? "";
  }

  return "";
};

export default getErrorMessageFromAPI;
