import { createSvgIcon } from "@mui/material";

export const ArrowRightOutlinedIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path d="M10 17L15 12L10 7V17Z" fill-opacity="0.54" />
  </svg>,
  "ArrowRightOutlined",
);
