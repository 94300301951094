import React from "react";
import { SvgIconComponent } from "@mui/icons-material";
import { Typography } from "@mui/material";
import styles from "./SectionTitle.styles";

type TSectionTitleProps = {
  Icon: SvgIconComponent;
  title: string;
};

const SectionTitle = ({ Icon, title }: TSectionTitleProps) => (
  <Typography variant="body2" sx={styles.title}>
    <Icon color="inherit" aria-label={`${title}-icon`} />
    {title}
  </Typography>
);

export default SectionTitle;
