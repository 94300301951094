import { TFunction } from "i18next";
import differenceBy from "lodash/differenceBy";
import some from "lodash/some";
import { IDocument } from "models/documents.models";
import DocumentName from "screens/Project/sections/Documents/DocumentRow/DocumentName/index";
import { getDisplayedAssetStatus } from "utils/helpers";
import { TDocumentsRowData } from "./AddProjectDocuments.types";

export const getDocumentsForRender = (
  documents: IDocument[] | undefined,
  t: TFunction,
  alreadyAttachedDocuments?: IDocument[],
): TDocumentsRowData[] =>
  documents?.map((document) => {
    return {
      ...document,
      fileType: document.metadata?.fileType ?? "",
      name: <DocumentName document={document} />,
      version: document.latestVersion?.version,
      status: getDisplayedAssetStatus(
        document.source?.tenantType,
        document.status,
        t,
      ),
      type: t(`subtypeOptions.${document.type}`),
      ...(some(alreadyAttachedDocuments, { id: document.id }) && {
        isDisabled: true,
      }),
    };
  }) ?? [];

export const getDocumentForSubmission = (
  selectedDocuments: IDocument[],
  attachedDocuments: IDocument[] | undefined,
) => differenceBy(selectedDocuments, attachedDocuments || [], "id");
