import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  link: {
    fontSize: "14px",
  },

  disabled: {
    color: getPaletteColor("grey.A400"),
    cursor: "default",
  },
};

export default styles;
