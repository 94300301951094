import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { DateRangeFilter } from "components/shared/DateRangeFilter";
import MultiSelect from "components/shared/MultiSelect";
import {
  FilterType,
  TFilteringParams,
  TFilters,
} from "components/shared/types/filter";
import { BinaryChoice } from "utils/constants/common";
import MultiSelectTypeaheadFilter from "./components/MultiSelectTypeaheadFilter";
import styles from "./FilterSelects.styles";

export type TFilterSelectsProps = {
  filters?: TFilters;
  updateFilter: (filters: TFilteringParams) => void;
  selectedFilters?: TFilteringParams;
};

const FilterSelects = (props: TFilterSelectsProps) => (
  <Box sx={styles.container}>
    {props.filters
      ? Object.entries(props.filters).map(([key, filter]) => {
          if (filter.type === FilterType.DateRange) {
            return (
              <DateRangeFilter
                key={key}
                datesMeta={filter.options}
                selectedFilters={props.selectedFilters}
                updateFilter={props.updateFilter}
              />
            );
          }

          if (filter.type === FilterType.Toggle) {
            return (
              <FormControlLabel
                key={key}
                label={filter.label}
                control={
                  <Checkbox
                    data-qaid="filter-checkbox"
                    checked={
                      props.selectedFilters?.[key]?.[0] === BinaryChoice.Yes
                    }
                    onChange={(_event, checked: boolean) => {
                      props.updateFilter({
                        [key]: checked ? [BinaryChoice.Yes] : [],
                      });
                    }}
                  />
                }
              />
            );
          }

          if (filter.type === FilterType.Select) {
            const combinedStyles = {
              ...styles.select,
              ...(filter.sx || {}),
            };

            return (
              <MultiSelect
                id={key}
                data-qaid={`${key}-filter-multi-select`}
                items={filter.options}
                key={key}
                role="combobox"
                label={filter.label}
                onChange={(selected) => props.updateFilter({ [key]: selected })}
                selectAllLabel={filter.selectAllLabel}
                size="small"
                sx={combinedStyles}
                values={props.selectedFilters?.[key]}
              />
            );
          }

          if (filter.type === FilterType.SelectTypeahead) {
            const combinedStyles = {
              ...styles.select,
              ...(filter.sx || {}),
            };

            return (
              <MultiSelectTypeaheadFilter
                id={key}
                key={key}
                filter={filter}
                updateFilter={(filters) => props.updateFilter(filters)}
                sx={combinedStyles}
                selectedFilters={props.selectedFilters}
              />
            );
          }

          return null;
        })
      : null}
  </Box>
);

export default FilterSelects;
