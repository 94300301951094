import { PropsWithChildren, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
} from "@mui/material";
import styles from "./Modal.styles";

export type TModalProps = Pick<
  DialogProps,
  "children" | "maxWidth" | "scroll" | "sx" | "title" | "fullWidth"
> & { open: boolean; onClose?: () => void; header?: ReactNode };

const Modal = ({
  sx,
  title,
  header,
  onClose,
  ...props
}: PropsWithChildren<TModalProps>) => {
  const { t } = useTranslation("common");

  const combinedStyles = {
    ...styles.dialog,
    ...sx,
  };

  if (!props.open) {
    return null;
  }

  return (
    <Dialog sx={combinedStyles} {...props}>
      <Stack>
        <Stack
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
        >
          {title ? <DialogTitle children={title} /> : null}
          {onClose ? (
            <Button
              sx={styles.closeButton}
              variant="text"
              startIcon={<CloseIcon />}
              onClick={onClose}
              aria-label={t("button.close", { ns: "common" })}
              data-qaid="modal-close-button"
            >
              {t("button.close", { ns: "common" })}
            </Button>
          ) : null}
        </Stack>
        {header}
      </Stack>
      {props.children ? <DialogContent>{props.children}</DialogContent> : null}
    </Dialog>
  );
};

export default Modal;
