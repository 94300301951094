import React from "react";
import { Fab as MuiFab, FabProps as MuiFabProps } from "@mui/material";
import styles from "./Fab.styles";

export type TFabProps = MuiFabProps & {
  children?: React.ReactNode;
};

const Fab = ({ children, ...props }: TFabProps) => {
  const sx = {
    ...styles.fab,
    ...props.sx,
  };

  return (
    <MuiFab sx={sx} {...props}>
      {children}
    </MuiFab>
  );
};

export default Fab;
