/* istanbul ignore file */
import { SyntheticEvent } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Box, Stack, Typography } from "@mui/material";
import Button from "components/shared/Button";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import SelectedFile from "components/shared/SelectedFile/SelectedFile";
import { IDocument, IDocumentMetadata } from "models/documents.models";
import { TDocumentStatusChangeAction } from "screens/Project/sections/Documents/Documents.utils";
import { DocumentStatus } from "utils/constants/doc.constants";
import { getDisplayedAssetStatus } from "utils/helpers";
import styles from "./ChangeDocumentStatus.styles";

type TChangeDocumentStatusModalProps = {
  action: TDocumentStatusChangeAction;
  onClose: () => void;
  onSubmitStatusChange: (
    documents: IDocument<IDocumentMetadata>[],
    targetStatus: DocumentStatus,
    onClose: () => void,
  ) => void;
};

const ChangeDocumentStatus = ({
  action: { documents, targetStatus },
  onClose,
  onSubmitStatusChange,
}: TChangeDocumentStatusModalProps) => {
  const { t } = useTranslation(["documents", "common"]);

  const onSubmit = (event: SyntheticEvent) => {
    // prevent page refresh
    event?.preventDefault();
    onSubmitStatusChange(documents, targetStatus, onClose);
  };

  return (
    <Modal
      open
      data-qaid="change-document-status-modal"
      title={t("changeDocumentStatusModal.title")}
    >
      <Typography variant="body2" marginTop={1.5}>
        <Trans t={t} i18nKey="changeDocumentStatusModal.contentText">
          <b>
            {getDisplayedAssetStatus(
              documents[0]?.source?.tenantType,
              documents[0].status,
              t,
            )}
          </b>
          <b>
            {getDisplayedAssetStatus(
              documents[0]?.source?.tenantType,
              targetStatus,
              t,
            )}
          </b>
        </Trans>
      </Typography>
      <Box component="form" onSubmit={onSubmit} sx={styles.form}>
        <Stack>
          {documents?.map((document) => (
            <Box key={document.id} marginY={1}>
              <SelectedFile
                fileName={document.name || ""}
                clearIconAriaLabel={t("ariaLabels.clearIcon", {
                  ns: "common",
                  fileName: document.name || "",
                })}
              />
            </Box>
          ))}
        </Stack>

        <ModalActionButtons>
          <Button
            data-qaid="change-document-status-cancel-button"
            onClick={onClose}
          >
            {t("button.cancel", { ns: "common" })}
          </Button>
          <Button
            data-qaid="change-document-status-button"
            variant="contained"
            type="submit"
            sx={styles.submitButton}
          >
            {t("button.confirm", { ns: "common" })}
          </Button>
        </ModalActionButtons>
      </Box>
    </Modal>
  );
};

export default ChangeDocumentStatus;
