import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { gql } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { TCreateEntityOrganizationMutationData } from "./useCreateEntityOrganization.models";

export const query = gql`
  mutation CreateEntityOrganization(
    $entityOrganization: EntityOrganizationInput
  ) {
    createEntityOrganization(entityOrganization: $entityOrganization) {
      id
      name
      alias
      type
      roleTypes
      address
      city
      country
      zipCode
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
    }
  }
`;

const useCreateEntityOrganization = () => {
  const { t } = useTranslation("administration", {
    keyPrefix: "notifications",
  });

  const { showAlert } = useAlerts();
  const queryClient = useQueryClient();

  const { data, isError, isLoading, isSuccess, mutateAsync } = useMutation(
    ({ entityOrganization }: TCreateEntityOrganizationMutationData) =>
      services.graphQL
        .request(query, {
          entityOrganization,
        })
        .then(() => {
          showAlert({
            message: t("createEntityOrganizationSuccess"),
            severity: AlertSeverity.Success,
          });

          queryClient.invalidateQueries(QueryAPIKey.GetEntityOrganizations);
        })
        .catch((error) => {
          if (error.response?.errors[0]?.extensions?.response?.status === 409) {
            showAlert({
              message: t("createEntityOrganizationFailureAlreadyExists"),
              severity: AlertSeverity.Error,
            });

            throw error;
          }

          showAlert({
            message: t("createEntityOrganizationFailure"),
            severity: AlertSeverity.Error,
          });

          throw error;
        }),
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    createEntityOrganization: mutateAsync,
  };
};

export default useCreateEntityOrganization;
