import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { OktaAuth } from "@okta/okta-auth-js";
import { Path } from "router";

export const useRestoreOriginalUri = () => {
  const navigate = useNavigate();

  return useCallback(
    (_oktaAuth: OktaAuth, originalUri: string) => {
      navigate(originalUri || Path.HomePage, { replace: true });
    },
    [navigate],
  );
};
