import { forwardRef, Ref } from "react";
import { Box, SelectChangeEvent, Stack } from "@mui/material";
import Chip from "components/shared/Chip";
import { TItemAssignmentSelectProps } from "../ItemAssignmentSelect.types";
import AssignmentSelect from "./AssignmentSelect";
import ItemSelect from "./ItemSelect";
import styles from "../ItemAssignmentSelect.styles";

const ItemAssignmentSelect = forwardRef(
  (
    {
      addItemForAssignment,
      assignItem,
      getAssignmentOptions,
      removeItem,
      fields,
      itemOptions,
      itemsSelectProps,
      getError,
      testId,
      ...props
    }: TItemAssignmentSelectProps,
    ref: Ref<any>,
  ) => {
    return (
      <Box>
        <ItemSelect
          data-testid={`${testId}-project-team-members-dropdown`}
          data-qaid={`${testId}-project-team-members-dropdown`}
          id={`${testId}-item`}
          onChange={(event: SelectChangeEvent<string>) => {
            addItemForAssignment(event.target.value);
          }}
          options={itemOptions}
          size="small"
          sx={styles.itemSelect}
          {...itemsSelectProps}
        />
        {fields.length > 0 && (
          <Stack spacing={0.5} sx={styles.assignments}>
            {fields?.map(({ item, assignment, id }, index) => (
              <Box sx={styles.assignmentRow} key={id}>
                <Chip
                  data-testid={`${testId}-project-team-member-chip`}
                  data-qaid={`${testId}-project-team-member-chip`}
                  label={item.label}
                  onDelete={() => removeItem(index)}
                />
                <AssignmentSelect
                  data-testid={`${testId}-project-team-member-role-dropdown`}
                  data-qaid={`${testId}-project-team-member-role-dropdown`}
                  id={`${testId}-${id}`}
                  {...props}
                  onChange={(event) => assignItem(event.target.value, id)}
                  value={assignment.value}
                  options={getAssignmentOptions(item.value)}
                  error={!!getError?.(index)}
                  helperText={getError?.(index)?.message}
                  ref={ref}
                />
              </Box>
            ))}
          </Stack>
        )}
      </Box>
    );
  },
);

export default ItemAssignmentSelect;
