import { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { JIRA_WIDGET_TARGET } from "utils/constants/drawer.constants";

const useHideJiraWidget = (shouldHide: boolean) => {
  useEffect(() => {
    const widgetTarget = document.getElementsByName(JIRA_WIDGET_TARGET);

    if (!isEmpty(widgetTarget)) {
      if (shouldHide) {
        widgetTarget[0].style.display = "none";
      }

      return () => {
        widgetTarget[0].style.display = "block";
      };
    }
  }, [shouldHide]);
};

export default useHideJiraWidget;
