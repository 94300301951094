import { TStyles } from "utils/Styles";

const styles: TStyles = {
  autocomplete: {
    ".MuiIconButton-root": {
      marginRight: "-4px",

      ".MuiSvgIcon-root": {
        paddingTop: "2px",
      },
    },

    ".MuiInputBase-root .MuiInputBase-input": {
      minWidth: "5px",
    },
  },

  selectedText: {
    pl: 0.75,
  },

  startAdornment: {
    maxHeight: "225px",
    overflowY: "auto",
  },
};

export default styles;
