import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import { TMenuOption } from "components/shared/ButtonMenu";
import useFeatureFlags from "context/FeatureFlagsContext/useFeatureFlags";
import { useProject } from "context/ProjectContext";
import useHAQsWithContent from "hooks/api/GQL/haq/useHAQsWithContent";
import { useChangeStatusAction } from "hooks/haq";
import usePermissions from "hooks/permissions";
import { useUserRoles } from "hooks/user";
import useUser from "hooks/useUser";
import { AssetType } from "utils/constants/assets.constants";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import { HAQAction } from "utils/constants/HAQ.constants";
import { ProjectStatus } from "utils/constants/project.constants";
import {
  getCanDeleteAsset,
  getIsAssetCopied,
  getIsAssetCreatedByUser,
  getIsReferenceOrExternalHAQ,
} from "utils/helpers";
import { isHAUser } from "utils/user/organization";
import {
  getCanChangeDueDate,
  getCanChangeHAQStatus,
  getCanRequestExternalReview,
  getCanRequestInternalReview,
  getCanSendCopyToSponsor,
  getCanSubmitToHA,
} from "./HAQActions.utils";
import useHAQSetToComplete from "./useHAQSetToComplete";
import useHAQStatusChangeOptions from "./useHAQStatusChangeOptions";

export const useHAQActions = (HAQId: string) => {
  const featureFlags = useFeatureFlags();
  const { t } = useTranslation(["HAQ", "common"]);
  const { project } = useProject();
  const { isProjectManager } = useUserRoles(project?.id);

  const { organization, user } = useUser();

  const { data } = useHAQsWithContent({ HAQIds: [HAQId] });

  const HAQ = data?.questions[0]!;

  const HAQResponse = data?.questions[0]?.children?.find(
    (item) => item.type === AssetType.QuestionResponse,
  );

  const [workflowInProgressId, setWorkflowInProgressId] = useState<
    string | null
  >(null);

  const { permissions } = usePermissions(project?.id ?? "");

  const isHAQCopied = getIsAssetCopied(HAQ?.shareTracking);
  const isHAQRCopied = getIsAssetCopied(HAQResponse?.shareTracking);

  const [action, setAction] = useState<HAQAction | null>(null);

  const resetAction = useCallback(() => {
    setAction(null);
  }, []);

  const {
    statusChangeMenuItems,
    statusChangeActionQueued,
    onExecuteManualStatusChange,
  } = useHAQStatusChangeOptions({
    HAQId,
    workflowInProgressId,
    setWorkflowInProgressId,
    isHAQCopied,
    isHAQRCopied,
  });

  const { canSetToComplete, onSetToComplete } = useHAQSetToComplete(
    HAQId,
    isHAQCopied,
  );

  const hasSponsorChangeStatusPermissions = getIsReferenceOrExternalHAQ(
    HAQ?.metadata?.importType,
  )
    ? permissions.canChangeReferenceOrExternalQuestionStatus
    : permissions.canChangeQuestionResponseStatus;

  const isHAQOwnedByUser = getIsAssetCreatedByUser(HAQ, user);

  const hasHAChangeStatusPermission = !isHAQOwnedByUser
    ? permissions.canChangeQuestionStatus
    : permissions.canChangeQuestionOwnedStatus;

  const hasChangeStatusPermissions = isHAUser(organization)
    ? hasHAChangeStatusPermission
    : hasSponsorChangeStatusPermissions;

  const shouldUseHAQActionsV2 =
    featureFlags["FTE-13049_enableHAQActionsV2"] === FeatureFlag.On;

  const shouldUseHAQChangeStatusV3 =
    featureFlags["FTE-30742_enableHAQChangeStatusV3"] === FeatureFlag.On;

  const { changeStatusAction, changeStatusModal } = useChangeStatusAction({
    selectedRows: HAQ ? [{ HAQ, HAQResponse }] : [],
    isAnyHAQCopied: isHAQCopied,
  });

  const actionOptions = useMemo<TMenuOption[]>(() => {
    const options: TMenuOption[] = [];

    if (
      hasChangeStatusPermissions &&
      getCanChangeHAQStatus(HAQ, HAQResponse, organization) &&
      !isEmpty(statusChangeMenuItems) &&
      !shouldUseHAQActionsV2 &&
      !shouldUseHAQChangeStatusV3
    ) {
      options.push({
        id: HAQAction.ChangeStatus,
        label: t("viewPage.menuButton.options.changeStatus"),
        items: statusChangeMenuItems,
      });
    }

    if (
      changeStatusAction &&
      (shouldUseHAQActionsV2 || shouldUseHAQChangeStatusV3)
    ) {
      options.push(changeStatusAction);
    }

    if (canSetToComplete) {
      options.push({
        id: HAQAction.SetToComplete,
        label: t("viewPage.menuButton.options.setToComplete"),
        onClick: () => onSetToComplete(),
      });
    }

    if (
      getCanRequestInternalReview(HAQ, organization) &&
      featureFlags["enableHAQInternalReview"] === FeatureFlag.On
    ) {
      options.push({
        id: HAQAction.RequestInternalReview,
        label: t("viewPage.menuButton.options.requestInternalReview"),
      });
    }

    if (
      getCanRequestExternalReview(HAQ, organization) &&
      featureFlags["enableHAQExternalReview"] === FeatureFlag.On
    ) {
      options.push({
        id: HAQAction.RequestExternalReview,
        label: t("viewPage.menuButton.options.requestExternalReview"),
        onClick: () => setAction(HAQAction.RequestExternalReview),
      });
    }

    if (
      getCanChangeDueDate([HAQ], organization, isProjectManager) &&
      featureFlags["FTE-19414_enableChangeHAQDueDate"] === FeatureFlag.On
    ) {
      options.push({
        id: HAQAction.ChangeDueDate,
        label: t("viewPage.menuButton.options.changeDueDate"),
        onClick: () => setAction(HAQAction.ChangeDueDate),
      });
    }

    if (
      getCanSendCopyToSponsor(
        HAQ,
        permissions.canSendCopyQuestion,
        organization,
        isHAQCopied,
      )
    ) {
      options.push({
        id: HAQAction.SendCopyToSponsor,
        label: t("viewPage.menuButton.options.sendCopyToSponsor"),
        shouldBeSeparated: !isEmpty(options),
        onClick: () => setAction(HAQAction.SendCopyToSponsor),
      });
    }

    const canSubmitHAQToHA = getCanSubmitToHA(
      HAQ,
      organization,
      permissions,
      isHAQCopied,
    );

    const canSubmitHAQRToHA = getCanSubmitToHA(
      HAQResponse,
      organization,
      permissions,
      isHAQRCopied,
    );

    if (
      (!HAQ?.metadata.imported || isHAQCopied || canSubmitHAQToHA) &&
      canSubmitHAQRToHA
    ) {
      options.push({
        id: HAQAction.SubmitToHA,
        disabled: project?.status !== ProjectStatus.InProgress,
        label: t("viewPage.menuButton.options.submitToHA"),
        shouldBeSeparated: !isEmpty(options),
        onClick: () => {
          if (project?.status !== ProjectStatus.InProgress) {
            return;
          }

          setAction(HAQAction.SubmitToHA);
        },
      });
    }

    if (
      permissions.canDeleteQuestion &&
      getCanDeleteAsset(HAQ, organization) &&
      (isHAUser(organization) || getCanDeleteAsset(HAQResponse, organization))
    ) {
      options.push({
        id: HAQAction.Delete,
        label: t("viewPage.menuButton.options.delete"),
        shouldBeSeparated: !isEmpty(options),
        onClick: () => setAction(HAQAction.Delete),
      });
    }

    return options;
  }, [
    hasChangeStatusPermissions,
    HAQ,
    HAQResponse,
    organization,
    statusChangeMenuItems,
    shouldUseHAQActionsV2,
    shouldUseHAQChangeStatusV3,
    changeStatusAction,
    canSetToComplete,
    featureFlags,
    permissions,
    isHAQCopied,
    isHAQRCopied,
    t,
    onSetToComplete,
    project?.status,
    isProjectManager,
  ]);

  return {
    action,
    resetAction,
    actionOptions,
    workflowInProgressId,
    setWorkflowInProgressId,
    statusChangeActionQueued,
    onExecuteManualStatusChange,
    changeStatusModal,
  };
};
