import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormSelect } from "components/common/form-fields";
import { THAQParentData } from "components/common/HAQ";
import useDocuments from "hooks/api/REST/documents/useDocuments";
import {
  DocumentSubtype,
  SUPPORTED_SUBTYPES,
} from "utils/constants/doc.constants";
import { DocumentStatus } from "utils/constants/doc.constants";
import { HAQFieldName } from "utils/constants/HAQ.constants";
import { formatValuesToOptions } from "utils/helpers";

type TDocumentsProps = {
  projectId: string | undefined;
  parent: THAQParentData;
};

const documentsTypesToDisplay = SUPPORTED_SUBTYPES.filter(
  (type) => type !== DocumentSubtype.GSP,
);

const Documents = ({ projectId, parent }: TDocumentsProps) => {
  const { t } = useTranslation("HAQ");

  const { documents } = useDocuments({
    projectIds: projectId,
    assetTypes: documentsTypesToDisplay.join(),
    statuses: [DocumentStatus.Final],
  });

  const documentOptions = useMemo(() => {
    return formatValuesToOptions(
      documents,
      (document) => document.name,
      (document) => JSON.stringify({ id: document.id, name: document.name }),
    );
  }, [documents]);

  return (
    <FormSelect
      disabled={Boolean(parent)}
      data-qaid="add-haq-assets-select"
      name={HAQFieldName.Assets}
      unselectText={t("addHAQModal.fields.documents.unselectText")}
      label={t("addHAQModal.fields.documents.label")}
      options={documentOptions}
    />
  );
};

export default Documents;
