import { forwardRef, KeyboardEvent, Ref } from "react";
import { Link as RouterLink, LinkProps, useNavigate } from "react-router-dom";
import { Keyboard } from "utils/constants/keyboard.constants";

const Link = forwardRef(
  ({ to, children, ...otherProps }: LinkProps, ref: Ref<HTMLAnchorElement>) => {
    const navigate = useNavigate();

    const handleKeyDown = (event: KeyboardEvent<HTMLAnchorElement>) => {
      if (event.key === Keyboard.Space) {
        event.preventDefault();
        navigate(to);
      }
    };

    return (
      <RouterLink ref={ref} to={to} onKeyDown={handleKeyDown} {...otherProps}>
        {children}
      </RouterLink>
    );
  },
);

export default Link;
