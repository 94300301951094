import { useState } from "react";
import { useQueryClient } from "react-query";
import {
  BlobServiceClient,
  BlockBlobParallelUploadOptions,
} from "@azure/storage-blob";
import { useAlerts } from "context/AlertContext";
import {
  BackgroundJobType,
  TFileUploadJob,
  useBackgroundJobs,
} from "context/BackgroundJobsContext";
import { TAlertMessage } from "hooks/useShowAlert";
import { ICreateUploadTokenRequestData } from "models/assets.models";
import { services } from "services";
import {
  QueryAPIKey,
  QueryFetchingStatus,
} from "utils/constants/api.constants";
import { BLOB_SIZE_THRESHOLD_IN_BYTES } from "utils/constants/size.constants";
import { parseDataUploadUrl } from "utils/helpers/azureBlobStorage";

const useAzureBlobStorage = () => {
  const [status, setStatus] = useState(QueryFetchingStatus.Idle);

  const queryClient = useQueryClient();

  const { showAlert, hideAlert } = useAlerts();

  const { addJob, updateJobData, removeJob } = useBackgroundJobs();

  const createUploadToken = (data: ICreateUploadTokenRequestData) =>
    services.assets.createUploadToken(data);

  const uploadData = ({
    data,
    blobServiceUrl,
    containerName,
    blobName,
    options,
  }: {
    data: Blob | ArrayBuffer | ArrayBufferView;
    blobServiceUrl: string;
    containerName: string;
    blobName: string;
    options?: BlockBlobParallelUploadOptions;
  }) => {
    const blobServiceClient = new BlobServiceClient(blobServiceUrl);

    const blockBlobClient = blobServiceClient
      .getContainerClient(containerName)
      .getBlockBlobClient(blobName);

    return blockBlobClient.uploadData(data, {
      maxSingleShotSize: BLOB_SIZE_THRESHOLD_IN_BYTES,
      ...options,
    });
  };

  const uploadFile = ({
    file,
    createUploadTokenRequestData,
    onUploadStart,
    messages,
  }: {
    file: File;
    createUploadTokenRequestData: ICreateUploadTokenRequestData;
    onUploadStart?: (jobId: string) => void;
    messages: TAlertMessage;
  }) => {
    let jobId: string;

    setStatus(QueryFetchingStatus.Loading);

    createUploadToken(createUploadTokenRequestData)
      .then(({ data }) => {
        queryClient.invalidateQueries(QueryAPIKey.GetDocuments);

        const { blobServiceUrl, containerName, blobName } = parseDataUploadUrl(
          data.assetUploadUrl,
        );

        jobId = data.id;

        addJob({
          id: jobId,
          type: BackgroundJobType.FileUpload,
          createdDate: new Date(),
          data: {
            totalBytes: file.size,
            progress: {
              loadedBytes: 0,
            },
          },
        });

        onUploadStart?.(jobId);

        return uploadData({
          data: file,
          blobServiceUrl,
          containerName,
          blobName,
          options: {
            onProgress: (progress) => {
              updateJobData<TFileUploadJob>(jobId, { progress });
            },
          },
        });
      })
      .then(() => {
        setStatus(QueryFetchingStatus.Success);

        showAlert({
          severity: "success",
          message: messages.successMessage,
          isInstant: true,
        });

        queryClient.invalidateQueries(QueryAPIKey.GetDocuments);
      })
      .catch(() => {
        setStatus(QueryFetchingStatus.Error);

        showAlert({
          severity: "error",
          message: messages.failureMessage,
          isInstant: true,
        });
      })
      .finally(() => {
        if (jobId) {
          hideAlert(jobId);
          removeJob(jobId);
        }
      });
  };

  return {
    uploadFile,
    status,
  };
};

export default useAzureBlobStorage;
