import { SyntheticEvent, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Grid, Stack } from "@mui/material";
import {
  FormFileInput,
  FormSwitch,
  FormTextInput,
} from "components/common/form-fields";
import ButtonLink from "components/shared/ButtonLink";
import { useFeatureFlags } from "context/FeatureFlagsContext";
import { ImportModalType } from "screens/Project/sections/Documents/modals/ImportDocument";
import { ImportFormBaseFieldName } from "screens/Project/sections/Documents/modals/ImportDocument";
import {
  DomainField,
  LanguageField,
  SubtypeField,
  TypeField,
} from "screens/Project/sections/Documents/modals/ImportDocument/components/fields";
import { TImportFormBase } from "screens/Project/sections/Documents/modals/ImportDocument/ImportDocument.types";
import {
  ALLOWED_ECTD_MIME_TYPES,
  ALLOWED_FHIR_MIME_TYPES,
  ALLOWED_IMPORT_MIME_TYPES,
  DocumentSubtype,
} from "utils/constants/doc.constants";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import { includeInArray } from "utils/helpers";
import FHIRResourceTypeField from "../fields/FHIRResourceType/FHIRResourceType";
import AdditionalFields from "./AdditionalFields";

type TFieldLayoutProps = {
  openType: ImportModalType;
  onShowDocumentList?: (event?: SyntheticEvent) => void;
};

const FieldsLayout = ({ openType, onShowDocumentList }: TFieldLayoutProps) => {
  const { t } = useTranslation("documents", {
    keyPrefix: "importDocument",
  });

  const { watch } = useFormContext<TImportFormBase>();

  const typeSelection = watch(ImportFormBaseFieldName.Subtype);

  const isContentFHIRResource = typeSelection === DocumentSubtype.FHIR;

  const featureFlags = useFeatureFlags();

  const isFHIREnabled = featureFlags["enableFHIRDocuments"] === FeatureFlag.On;

  const isECTDEnabled =
    featureFlags["FTE-23984_enableDossierUploadCard"] === FeatureFlag.On;

  const acceptedMimeTypes = useMemo(() => {
    if (openType === ImportModalType.ECTD) {
      return [...ALLOWED_ECTD_MIME_TYPES];
    }

    return [
      ...ALLOWED_IMPORT_MIME_TYPES,
      ...includeInArray(
        ALLOWED_FHIR_MIME_TYPES,
        isFHIREnabled && openType === ImportModalType.Default,
      ).flat(),
      ...includeInArray(
        ALLOWED_ECTD_MIME_TYPES,
        isECTDEnabled && openType === ImportModalType.Default,
      ).flat(),
    ];
  }, [isECTDEnabled, isFHIREnabled, openType]);

  return (
    <Grid container spacing={2.3}>
      <Grid item xs={12}>
        <Stack gap={1.5}>
          <FormFileInput
            name={ImportFormBaseFieldName.File}
            acceptedMimeTypes={acceptedMimeTypes}
          />
          {Boolean(onShowDocumentList) && openType === ImportModalType.AAID && (
            <Box>
              <ButtonLink onClick={onShowDocumentList}>
                {t("browseFilesAction")}
              </ButtonLink>
            </Box>
          )}
        </Stack>
      </Grid>

      <Grid item xs={6}>
        <DomainField openModalType={openType} />
      </Grid>

      <Grid item xs={6}>
        <TypeField openModalType={openType} />
      </Grid>

      <Grid item xs={6}>
        <SubtypeField openModalType={openType} />
      </Grid>

      <Grid item xs={6}>
        <LanguageField fieldName={ImportFormBaseFieldName.Language} />
      </Grid>

      <AdditionalFields openModalType={openType} />

      <Grid item xs={12}>
        <FormTextInput<TImportFormBase>
          data-qaid="import-document-name-text-field"
          name={ImportFormBaseFieldName.Name}
          label={t("fields.name.label")}
        />
      </Grid>

      {isFHIREnabled && isContentFHIRResource && (
        <Grid item xs={12}>
          <FHIRResourceTypeField />
        </Grid>
      )}

      <Grid item xs={6}>
        <FormTextInput<TImportFormBase>
          data-qaid="import-document-author-text-field"
          name={ImportFormBaseFieldName.Author}
          label={t("fields.author.label")}
        />
      </Grid>

      <Grid item xs={6}>
        <FormSwitch
          data-qaid="litigation-switch-field"
          name={ImportFormBaseFieldName.LitigationHold}
          label={t("fields.litigationHold.label")}
        />
      </Grid>
    </Grid>
  );
};

export default FieldsLayout;
