import { useTranslation } from "react-i18next";
import { AssignmentLate as DossierIcon } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import SectionTitle from "screens/Project/screens/GSP/GSPItems/components/SectionTitle";
import { TDossierDifferencesModule } from "screens/Project/screens/GSP/GSPItems/GSPItems.utils";
import { DossierDifferencesModule } from "screens/Project/screens/GSP/GSPItems/GSPSummaryItem/components";
import styles from "../GSPViewItem.styles";

type TDossierDifferencesInfoProps = {
  dossierDifferencesModules: TDossierDifferencesModule[];
  parentId: string;
};

const DossierDifferencesInfo = ({
  dossierDifferencesModules,
  parentId,
}: TDossierDifferencesInfoProps) => {
  const { t } = useTranslation("documents", {
    keyPrefix: "gsp.item",
  });

  return (
    <Box py={2} pl={1}>
      <SectionTitle
        Icon={DossierIcon}
        title={t("sections.countryDossierDifference")}
      />

      <Stack sx={styles.fixedHeightContainer} spacing={2.5}>
        {dossierDifferencesModules.map((module) => (
          <DossierDifferencesModule
            module={module}
            key={module.id}
            parentId={parentId}
            isCountrySpecific
          />
        ))}
      </Stack>
    </Box>
  );
};

export default DossierDifferencesInfo;
