import { ChangeEvent, useCallback, useState } from "react";

type TOnSelectionChange = (itemsSelectedKeys: Set<string>) => void;

const getUpdatedSelectedItems = (
  prevSelectedItems: Set<string>,
  key: string,
) => {
  const selected: Set<string> = new Set(prevSelectedItems);
  const isSelected = selected.has(key);

  if (!isSelected) {
    selected.add(key);

    return selected;
  }

  selected.delete(key);

  return selected;
};

const useCheckboxGroup = (
  itemsKeys: string[],
  onSelectionChange?: TOnSelectionChange,
  initiallySelectedItems?: string[],
) => {
  const [itemsSelected, setItemsSelected] = useState<Set<string>>(
    new Set(initiallySelectedItems),
  );

  const onSelectAllClick = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newSelectionSet = new Set(e.target.checked ? itemsKeys : []);
      setItemsSelected(newSelectionSet);
      onSelectionChange && onSelectionChange(newSelectionSet);
    },
    [itemsKeys, onSelectionChange],
  );

  const onSelectClick = useCallback(
    (key: string) => {
      setItemsSelected((prevItemsSelected) => {
        const updatedSelectedItems = getUpdatedSelectedItems(
          prevItemsSelected,
          key,
        );

        onSelectionChange && onSelectionChange(updatedSelectedItems);

        return updatedSelectedItems;
      });
    },
    [onSelectionChange],
  );

  const checkIfItemSelected = useCallback(
    (key: string) => itemsSelected.has(key),
    [itemsSelected],
  );

  return {
    itemsSelected,
    onSelectClick,
    onSelectAllClick,
    checkIfItemSelected,
  };
};

export default useCheckboxGroup;
