import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  GSPContent: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },

  headerContainer: {
    backgroundColor: getPaletteColor("common.white"),
    p: (theme) => theme.spacing(1, 4, 1.5, 4),
    boxShadow: (theme) =>
      `0px 2px 5px ${getPaletteColor("action.selected")(theme)}`,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    columnGap: "1rem",
    borderTop: getBorderStyle("2px", "solid", "blueGrey.50"),
  },

  GSPForms: {
    overflow: "auto",
    flex: 1,
  },
};

export default styles;
