import { TFunction } from "i18next";
import { z } from "zod";
import { IProjectData } from "hooks/api/GQL/project/useProjectData.models";
import { TUserPermissions } from "hooks/permissions/usePermissions.types";
import { IDomain } from "models/documents.models";
import { TFHIRResource } from "models/fhir.models";
import { TProjectMetadata } from "models/projects.models";
import formatToFormData from "screens/Project/sections/Documents/utils/formatToFormData";
import { AssetDomain, AssetGroup } from "utils/constants/assets.constants";
import {
  ALLOWED_ECTD_MIME_TYPES,
  ALLOWED_FHIR_MIME_TYPES,
  ALLOWED_GENERAL_DOC_MIME_TYPES,
  ALLOWED_IMPORTED_HAQ_MIME_TYPE,
  ALLOWED_PROJECT_PARTICIPANTS_MIME_TYPES,
  DocumentSubtype,
  SUPPORTED_ECTD_VERSION,
} from "utils/constants/doc.constants";
import { FHIRResourceType } from "utils/constants/fhir.constants";
import { ISO_DATE_FORMAT } from "utils/constants/format.constants";
import { ProjectType } from "utils/constants/project.constants";
import { REGEX_MATCHING_PATTERNS } from "utils/constants/regexp.constants";
import { MAX_DOC_UPLOAD_SIZE_IN_BYTES } from "utils/constants/size.constants";
import {
  formatDate,
  getReadableFileSize,
  getTextFromBlob,
  includeInArray,
} from "utils/helpers";
import {
  getGenericFutureDateConstraint,
  MEMBER_SCHEMA,
} from "utils/validators/constraints/validationConstraints";
import {
  ImportFormBaseFieldName,
  ImportFormReferencesFieldName,
  ImportFormSharedFieldName,
} from "./components/fields/names";
import { ImportModalType } from "./ImportDocument";
import {
  TAssessmentAidForm,
  TImportForm,
  TImportFormBase,
  TReferencesImportForm,
} from "./ImportDocument.types";

const isAssessmentAidForm = (
  values: TImportForm,
): values is TAssessmentAidForm =>
  values[ImportFormBaseFieldName.Subtype] ===
  DocumentSubtype.AssessmentAidSource;

export const isReferencesForm = (
  values: TImportForm,
): values is TReferencesImportForm =>
  values[ImportFormBaseFieldName.Subtype] === DocumentSubtype.References;

// data mapping
export const getBaseUploadData = (values: TImportForm, projectId: string) => ({
  file: values[ImportFormBaseFieldName.File][0],
  domain: values[ImportFormBaseFieldName.Domain],
  group: values[ImportFormBaseFieldName.Type],
  subtype: values[ImportFormBaseFieldName.Subtype],
  language: values[ImportFormBaseFieldName.Language],
  name: values[ImportFormBaseFieldName.Name],
  litigationHold: values[ImportFormBaseFieldName.LitigationHold],
  projectIds: projectId,
  ...(values[ImportFormBaseFieldName.Author] && {
    author: values[ImportFormBaseFieldName.Author],
  }),
});

export const getSubmissionData = (
  values: TImportForm,
  project: IProjectData | null | undefined,
): FormData => {
  const baseUploadData = getBaseUploadData(values, project?.id ?? "");

  if (isAssessmentAidForm(values)) {
    const submissionDate = values[ImportFormSharedFieldName.SubmissionDate]
      ? formatDate(
          values[ImportFormSharedFieldName.SubmissionDate],
          ISO_DATE_FORMAT,
        )
      : undefined;

    const uploadDataForAAID = {
      ...baseUploadData,
      ...(Boolean(submissionDate) && {
        submissionDate,
      }),
    };

    return formatToFormData(uploadDataForAAID, project);
  }

  if (isReferencesForm(values)) {
    const sponsorAuthorizationLetter =
      values[ImportFormReferencesFieldName.SAL];

    const applicableHealthAuthorityTenantId =
      values[ImportFormReferencesFieldName.HealthAuthority];

    const formalSubmissionDate =
      values[ImportFormReferencesFieldName.FormalSubmissionDate];

    const uploadDataForReferences = {
      ...baseUploadData,
      ...(Boolean(applicableHealthAuthorityTenantId) && {
        applicableHealthAuthorityTenantId,
      }),
      ...(Boolean(formalSubmissionDate) && {
        formalSubmissionDate,
      }),
      subtype: sponsorAuthorizationLetter
        ? DocumentSubtype.LOA
        : DocumentSubtype.References,
    };

    return formatToFormData(uploadDataForReferences, project);
  }

  return formatToFormData(baseUploadData, project);
};

export const getSelectedFHIRResourceType = async (fileValue: FileList) => {
  const file = Object.values(fileValue)[0] as unknown as Blob;
  const fhirFile = (await getTextFromBlob(file)) as TFHIRResource;

  return fhirFile.resourceType || null;
};

export const getFHIRFileAsJSON = async (values: TImportForm) => {
  const file = Object.values(
    values[ImportFormBaseFieldName.File],
  )[0] as unknown as Blob;

  return (await getTextFromBlob(file)) as TFHIRResource;
};

export const getModalTitle = (
  openModalType: ImportModalType,
  t: TFunction,
): string => {
  switch (openModalType) {
    case ImportModalType.AAID:
      return t("importDocument.aaid.title");
    case ImportModalType.ECTD:
      return t("importDocument.customTitle", {
        contentType: t(`subtypeOptions.PROJECT_DOSSIER`),
      });
    case ImportModalType.AssessmentReport:
    case ImportModalType.HADecisionLetter:
      return t("importDocument.customTitle", {
        contentType: t(`subtypeOptions.${openModalType}`),
      });
    default:
      return t("importDocument.defaultTitle");
  }
};

export const importModalDefaultValues: Partial<TImportFormBase> = {
  [ImportFormBaseFieldName.Domain]: "" as AssetDomain,
  [ImportFormBaseFieldName.Type]: "" as AssetGroup,
  [ImportFormBaseFieldName.Subtype]: "" as DocumentSubtype,
  [ImportFormBaseFieldName.Language]: "",
  [ImportFormBaseFieldName.LitigationHold]: false,
  [ImportFormBaseFieldName.Name]: "",
  [ImportFormBaseFieldName.Author]: "",
  [ImportFormBaseFieldName.File]: [],
  [ImportFormBaseFieldName.FHIRResourceType]: "" as FHIRResourceType,
};

export const getImportModalSchema = (t: TFunction, locale: string) =>
  z
    .object({
      [ImportFormBaseFieldName.Name]: z.string().min(1, {
        message: t("validations.requiredField", { ns: "common" }),
      }),
      [ImportFormBaseFieldName.Domain]: z.string().min(1, {
        message: t("validations.requiredField", { ns: "common" }),
      }),
      [ImportFormBaseFieldName.Type]: z.string().min(1, {
        message: t("validations.requiredField", { ns: "common" }),
      }),
      [ImportFormBaseFieldName.Subtype]: z.string().min(1, {
        message: t("validations.requiredField", { ns: "common" }),
      }),
      [ImportFormBaseFieldName.Language]: z.string().min(1, {
        message: t("validations.requiredField", { ns: "common" }),
      }),
      [ImportFormBaseFieldName.Author]: z.string().optional(),
      [ImportFormBaseFieldName.File]: z
        .instanceof(FileList, {
          message: t("validations.requiredField", { ns: "common" }),
        })
        .refine(
          (files) => Boolean(files?.[0]?.size <= MAX_DOC_UPLOAD_SIZE_IN_BYTES),
          {
            message: t("validations.invalidFileSize", {
              size: getReadableFileSize(MAX_DOC_UPLOAD_SIZE_IN_BYTES, locale),
              ns: "common",
            }),
          },
        ),
      [ImportFormBaseFieldName.LitigationHold]: z.boolean().optional(),
      [ImportFormBaseFieldName.FHIRResourceType]: z
        .string()
        .optional()
        .nullable(),
      [ImportFormReferencesFieldName.HealthAuthority]: z
        .string(MEMBER_SCHEMA)
        .optional(),
      [ImportFormReferencesFieldName.SAL]: z.boolean().optional(),
      [ImportFormReferencesFieldName.FormalSubmissionDate]:
        getGenericFutureDateConstraint(t).nullish(),
      [ImportFormSharedFieldName.SubmissionDate]:
        getGenericFutureDateConstraint(t).nullish(),
    })
    .superRefine((data, ctx) => {
      const isSAL =
        data[ImportFormReferencesFieldName.SAL] &&
        !data[ImportFormReferencesFieldName.HealthAuthority];

      const isAllowedFHIRFileAttached = ALLOWED_FHIR_MIME_TYPES.some(
        (type) => type === data[ImportFormBaseFieldName.File]?.[0]?.type,
      );

      const isAllowedProjectParticipantsFileAttached =
        ALLOWED_PROJECT_PARTICIPANTS_MIME_TYPES.some(
          (type) => type === data[ImportFormBaseFieldName.File]?.[0]?.type,
        );

      const isAllowedImportedHAQFileAttached =
        ALLOWED_IMPORTED_HAQ_MIME_TYPE.some(
          (type) =>
            type ===
            data[ImportFormBaseFieldName.File]?.[0]?.type.toLowerCase(),
        );

      const isGeneralFileTypeAttached = ALLOWED_GENERAL_DOC_MIME_TYPES.some(
        (type) => type === data[ImportFormBaseFieldName.File][0]?.type,
      );

      const isAllowedECTDFileAttached = ALLOWED_ECTD_MIME_TYPES.some(
        (type) => type === data[ImportFormBaseFieldName.File][0]?.type,
      );

      const isValidECTDFileNameFormat = new RegExp(
        REGEX_MATCHING_PATTERNS.ECTDFileNameFormat,
      ).test(data[ImportFormBaseFieldName.File][0]?.name);

      const subtypeValue = data[ImportFormBaseFieldName.Subtype];

      if (subtypeValue) {
        switch (subtypeValue) {
          case DocumentSubtype.FHIR:
            if (!isAllowedFHIRFileAttached) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: t("validations.invalidFHIRFileType", {
                  ns: "common",
                }),
                path: [ImportFormBaseFieldName.File],
              });

              if (!data[ImportFormBaseFieldName.FHIRResourceType]) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: t("validations.requiredField", { ns: "common" }),
                  path: [ImportFormBaseFieldName.FHIRResourceType],
                });
              }
            }

            break;

          case DocumentSubtype.ImportedHAQ:
            if (!isAllowedImportedHAQFileAttached) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: t("validations.invalidRelianceImportedHAQType", {
                  ns: "common",
                }),
                path: [ImportFormBaseFieldName.File],
              });
            }

            break;

          case DocumentSubtype.ProjectParticipants:
            if (!isAllowedProjectParticipantsFileAttached) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: t("validations.invalidProjectParticipantsFileType", {
                  ns: "common",
                }),
                path: [ImportFormBaseFieldName.File],
              });
            }

            break;

          case DocumentSubtype.ECTD:
            if (!isAllowedECTDFileAttached) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: t("validations.invalidImportedZipType", {
                  ns: "common",
                }),
                path: [ImportFormBaseFieldName.File],
              });
            }

            if (!isValidECTDFileNameFormat) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: t("validations.invalidECTDFileNameFormat", {
                  ns: "common",
                }),
                path: [ImportFormBaseFieldName.File],
              });
            }

            break;

          default:
            if (!isGeneralFileTypeAttached) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: t("validations.invalidFileType", { ns: "common" }),
                path: [ImportFormBaseFieldName.File],
              });
            }
        }
      }

      if (isSAL) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("validations.requiredField", { ns: "common" }),
          path: [ImportFormReferencesFieldName.HealthAuthority],
        });
      }
    });

export const getDocumentTypesByProjectType = ({
  project,
  permissions,
  isFHIREnabled,
  isECTDEnabled,
}: {
  project: IProjectData<TProjectMetadata> | null | undefined;
  permissions: TUserPermissions;
  isFHIREnabled: boolean;
  isECTDEnabled: boolean;
}): Partial<Record<AssetDomain, AssetGroup[]>> => {
  switch (project?.type) {
    case ProjectType.Reliance:
      return {
        [AssetDomain.Regulatory]: [
          AssetGroup.Administrative,
          AssetGroup.Correspondence,
          ...includeInArray(AssetGroup.Submission, isECTDEnabled),
          ...includeInArray(AssetGroup.Data, isFHIREnabled),
        ],
        [AssetDomain.System]: [
          ...includeInArray(
            AssetGroup.Users,
            project.active && permissions.canSendHAInvitation,
          ),
        ],
      };

    case ProjectType.RegulatoryReview:
      return {
        [AssetDomain.Regulatory]: [
          AssetGroup.Administrative,
          AssetGroup.Correspondence,
          ...includeInArray(AssetGroup.Submission, isECTDEnabled),
          ...includeInArray(AssetGroup.Data, isFHIREnabled),
        ],
        [AssetDomain.System]: [
          ...includeInArray(
            AssetGroup.Users,
            project.active && permissions.canSendHAInvitation,
          ),
        ],
      };

    case ProjectType.Orbis: // TODO: (AR) At the moment we are not using this, but in the future we will actively develop Orbis
    default:
      return {
        [AssetDomain.Regulatory]: [
          AssetGroup.Administrative,
          ...includeInArray(AssetGroup.Data, isFHIREnabled),
        ],
      };
  }
};

export const getDocumentSubtypesByProjectType = ({
  projectType,
  permissions,
  isHAUser,
  isFHIREnabled,
  isECTDEnabled,
}: {
  projectType: ProjectType | undefined;
  permissions: TUserPermissions;
  isHAUser: boolean;
  isFHIREnabled: boolean;
  isECTDEnabled: boolean;
}): Partial<Record<AssetGroup, DocumentSubtype[]>> => {
  switch (projectType) {
    case ProjectType.Reliance:
      return {
        [AssetGroup.Administrative]: [
          DocumentSubtype.AssessmentReport,
          DocumentSubtype.SupportingInformation,
          ...(isHAUser
            ? [
                DocumentSubtype.InspectionReports,
                DocumentSubtype.ApplicantsStrategy,
              ]
            : []),
          ...(permissions.canImportReferenceOrExternalQuestion
            ? [DocumentSubtype.ImportedHAQ]
            : []),
        ],
        [AssetGroup.Correspondence]: [
          DocumentSubtype.HADecisionLetter,
          ...(isHAUser ? [DocumentSubtype.CoverLetter] : []),
        ],
        ...(permissions.canSendHAInvitation && {
          [AssetGroup.Users]: [DocumentSubtype.ProjectParticipants],
        }),
        ...(isFHIREnabled && { [AssetGroup.Data]: [DocumentSubtype.FHIR] }),
        ...(isECTDEnabled && {
          [AssetGroup.Submission]: [DocumentSubtype.ECTD],
        }),
      };

    case ProjectType.RegulatoryReview:
      return {
        [AssetGroup.Administrative]: [
          DocumentSubtype.AssessmentReport,
          DocumentSubtype.SupportingInformation,
          ...(isHAUser
            ? [
                DocumentSubtype.InspectionReports,
                DocumentSubtype.ApplicantsStrategy,
              ]
            : []),
          ...(permissions.canImportReferenceOrExternalQuestion
            ? [DocumentSubtype.ImportedHAQ]
            : []),
        ],
        [AssetGroup.Correspondence]: [
          DocumentSubtype.HADecisionLetter,
          ...(isHAUser ? [DocumentSubtype.CoverLetter] : []),
        ],
        ...(permissions.canSendHAInvitation && {
          [AssetGroup.Users]: [DocumentSubtype.ProjectParticipants],
        }),
        ...(isFHIREnabled && { [AssetGroup.Data]: [DocumentSubtype.FHIR] }),
        ...(isECTDEnabled && {
          [AssetGroup.Submission]: [DocumentSubtype.ECTD],
        }),
      };

    case ProjectType.Orbis: // TODO: (AR) At the moment we are not using this, but in the future we will actively develop Orbis
      return {
        [AssetGroup.Administrative]: [
          DocumentSubtype.AssessmentAidSource,
          DocumentSubtype.SupportingInformation,
          DocumentSubtype.ToplineResult,
          DocumentSubtype.References,
        ],
        ...(isFHIREnabled && { [AssetGroup.Data]: [DocumentSubtype.FHIR] }),
      };

    default:
      return {};
  }
};

export const getFilteredDomainDictionary = (
  domainDictionary: IDomain[] | undefined,
  project: IProjectData<TProjectMetadata> | null | undefined,
) => {
  const filteredDomainDictionary = domainDictionary?.filter(
    (domain) => domain.name.toUpperCase() !== AssetDomain.System,
  );

  switch (project?.type) {
    case ProjectType.Reliance:
      return project.active ? domainDictionary : filteredDomainDictionary;

    case ProjectType.RegulatoryReview:
      return project.active ? domainDictionary : filteredDomainDictionary;

    case ProjectType.Orbis:
      return filteredDomainDictionary;

    default:
      return domainDictionary;
  }
};

export const getDomainFieldOptions = (
  filteredDomainDictionary: IDomain[] | undefined,
  t: TFunction,
) =>
  filteredDomainDictionary?.map((domain) => ({
    label: t(`domain.${domain.name.toUpperCase()}`, { ns: "asset" }),
    value: domain.name.toUpperCase(),
  })) || [
    {
      label: t(`domain.${AssetDomain.Regulatory}`, { ns: "asset" }),
      value: AssetDomain.Regulatory,
    },
  ];

export const formatProjectParticipantsApiErrorMessage = (
  apiErrorMessage: string,
) => {
  const enumsToReadableStrings: { [key: string]: any } = {
    PROJECT_PARTICIPANTS: "Project Participants",
    RELIANCE: "Reliance",
  };

  Object.keys(enumsToReadableStrings).forEach((key: string) => {
    apiErrorMessage = apiErrorMessage.replaceAll(
      key,
      enumsToReadableStrings[key],
    );
  });

  return apiErrorMessage || null;
};

export const getCreateUploadTokenRequestData = (
  values: TImportForm,
  project: IProjectData | null | undefined,
) => {
  const file = values[ImportFormBaseFieldName.File][0];

  return {
    type: values[ImportFormBaseFieldName.Subtype],
    name: values[ImportFormBaseFieldName.Name],
    projectIds: [project?.id ?? ""],
    metadata: {
      domain: values[ImportFormBaseFieldName.Domain],
      group: values[ImportFormBaseFieldName.Type],
      subtype: values[ImportFormBaseFieldName.Subtype],
      language: values[ImportFormBaseFieldName.Language],
      name: file.name,
      version: SUPPORTED_ECTD_VERSION,
      fileType: file.name.split(".").pop() ?? "",
      litigationHold: values[ImportFormBaseFieldName.LitigationHold],
      ...(values[ImportFormBaseFieldName.Author] && {
        author: values[ImportFormBaseFieldName.Author],
      }),
    },
  };
};
