import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Edit as EditIcon } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { Button } from "components/shared";
import Card from "components/shared/Card";
import { useProject } from "context/ProjectContext";
import useDocuments from "hooks/api/REST/documents/useDocuments";
import { useUserRoles } from "hooks/user";
import useUser from "hooks/useUser";
import { IAssetRelationship } from "models/assets.models";
import { IHAQMetadata } from "models/HAQ.models";
import { DocumentComponentStatus } from "utils/constants/doc.constants";
import { ModalTypes } from "utils/constants/modal.constants";
import { TAssetWithContent } from "utils/helpers";
import { isSponsorUser } from "utils/user/organization";
import AttachmentsList from "./AttachmentsList/AttachmentsList";
import AddProjectDocuments from "./modals/AddProjectDocuments/AddProjectDocuments";
import EditProjectDocuments from "./modals/EditProjectDocuments/EditProjectDocuments";
import { PlusIcon } from "assets/icons";
import styles from "./Attachments.styles";

type TAttachmentsProps = {
  HAQResponseWithContent: TAssetWithContent<IHAQMetadata> | undefined;
  HAQResponseAttachments: IAssetRelationship[] | undefined;
};

const Attachments = ({
  HAQResponseWithContent,
  HAQResponseAttachments,
}: TAttachmentsProps) => {
  const { t } = useTranslation(["HAQ"]);
  const { organization } = useUser();
  const { project } = useProject();

  const { isProjectManager, isContentEditor } = useUserRoles(project?.id);

  const { documents: attachedDocuments } = useDocuments(
    {
      projectIds: project?.id || "",
      assetIds:
        HAQResponseAttachments?.map((content) => content.otherAssetArn).join(
          ",",
        ) ?? "",
    },
    {
      enabled: !isEmpty(HAQResponseAttachments),
    },
  );

  const [projectDocumentsModalType, setProjectDocumentsModalType] =
    useState<ModalTypes | null>(null);

  const isPossibleToAttachDocuments =
    project?.active &&
    HAQResponseWithContent?.status === DocumentComponentStatus.Draft &&
    isSponsorUser(organization) &&
    (isProjectManager || isContentEditor);

  return (
    <>
      <Card sx={styles.container}>
        <Stack direction="row" sx={styles.sectionHeader}>
          <Typography fontWeight={500} sx={styles.title}>
            {t("viewPage.component.attachments.attachments")}
          </Typography>
          {isPossibleToAttachDocuments && !isEmpty(HAQResponseAttachments) && (
            <Box sx={styles.iconButtonContainer}>
              <IconButton
                disableFocusRipple
                onClick={() => setProjectDocumentsModalType(ModalTypes.Edit)}
                aria-label={t(
                  "viewPage.component.attachments.editProjectDocuments",
                )}
              >
                <EditIcon sx={styles.editIconButton} />
              </IconButton>
              <IconButton
                disableFocusRipple
                onClick={() => setProjectDocumentsModalType(ModalTypes.Add)}
                aria-label={t(
                  "viewPage.component.attachments.addProjectDocuments",
                )}
              >
                <PlusIcon />
              </IconButton>
            </Box>
          )}
        </Stack>
        {isPossibleToAttachDocuments && isEmpty(HAQResponseAttachments) && (
          <Button
            data-qaid="add-project-document-button"
            onClick={() => setProjectDocumentsModalType(ModalTypes.Add)}
            variant="text"
            role="button"
            sx={styles.addProjectDocuments}
          >
            {t("viewPage.component.attachments.addProjectDocuments")}
          </Button>
        )}
        <AttachmentsList
          attachedDocuments={attachedDocuments}
          projectId={project?.id}
        />
      </Card>
      <AddProjectDocuments
        HAQResponseWithContent={HAQResponseWithContent}
        isOpen={projectDocumentsModalType === ModalTypes.Add}
        onClose={() => setProjectDocumentsModalType(null)}
        attachedDocuments={attachedDocuments}
      />
      <EditProjectDocuments
        HAQResponseAttachments={HAQResponseAttachments}
        isOpen={projectDocumentsModalType === ModalTypes.Edit}
        onClose={() => setProjectDocumentsModalType(null)}
        attachedDocuments={attachedDocuments || []}
      />
    </>
  );
};

export default Attachments;
