import { Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { renderOrganizations } from "screens/EntityManagement/EntityManagement.utils";
import { filterByArrayIncludesExactly } from "utils/helpers/filterFunctions";
import { SubstancesTable, TSubstancesRowData } from "./SubstancesTable.types";
import {
  filterByDrugClassType,
  filterByLinkedOrg,
} from "./SubstancesTable.utils";

const columnHelper = createColumnHelper<TSubstancesRowData>();

export const getColumns = (t: TFunction) => [
  columnHelper.accessor(SubstancesTable.Id, {
    header: "",
    enableColumnFilter: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor(SubstancesTable.Name, {
    header: t("substancesColumns.name"),
    filterFn: "arrIncludes",
  }),
  columnHelper.accessor(SubstancesTable.Class, {
    header: t("substancesColumns.class"),
    filterFn: filterByDrugClassType,
    cell: ({ cell: { getValue } }) => {
      return <Typography variant="body2">{getValue().name}</Typography>;
    },
  }),
  columnHelper.accessor(SubstancesTable.INN, {
    header: t("substancesColumns.INN"),
    filterFn: "arrIncludes",
  }),
  columnHelper.accessor(SubstancesTable.ATC, {
    header: t("substancesColumns.ATC"),
    filterFn: "arrIncludes",
  }),
  columnHelper.accessor(SubstancesTable.LinkedOrganizations, {
    header: t("substancesColumns.linkedOrganizations"),
    cell: ({ cell: { getValue } }) => renderOrganizations(getValue()),
    filterFn: filterByLinkedOrg,
  }),
  columnHelper.accessor(SubstancesTable.Status, {
    header: t("substancesColumns.status"),
    filterFn: filterByArrayIncludesExactly,
    cell: ({ cell: { getValue } }) => {
      return <Typography variant="body2">{t(`${getValue()}`)}</Typography>;
    },
  }),

  columnHelper.display({
    header: t("substancesColumns.actions"),
    meta: {
      width: "5%",
    },
    cell: ({ row }) => row.original.actions,
  }),
];
