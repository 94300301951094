import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  phoneInput: {
    "& .MuiFormLabel-root:not(.Mui-focused):not(.MuiFormLabel-filled)": {
      transform: "translate(15px, 8px) scale(1)",
    },

    "& .MuiInputBase-root": {
      height: 40,
    },

    "& .react-tel-input": {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink) ": {
        pl: 7,
      },

      "& .MuiOutlinedInput-input": {
        pl: 7,
      },

      "& .flag-dropdown": {
        left: 10,
      },
    },

    "& .selected-flag": {
      "& [class*='0']::before": {
        content: "'Code'",
        position: "absolute",
        width: 12,
        left: 7,
        top: -7,
        color: getPaletteColor("text.secondary"),
        cursor: "pointer",
        fontSize: 16,
      },

      "& [class*='0'] .arrow": {
        left: 50,
      },

      "& .arrow": {
        left: 25,
        borderLeft: "4px solid transparent",
        borderRight: "4px solid transparent",
        borderTop: getBorderStyle("4px", "solid", "action.active"),
      },
    },
  },

  emptyPhoneInput: {
    "& .react-tel-input": {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink) ": {
        pl: 7,
      },

      "& .MuiOutlinedInput-input": {
        pl: 9,
      },
    },
  },
};

export default styles;
