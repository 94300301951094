import { QueryClient } from "react-query";
import { type CommentsAdapter } from "@ckeditor/ckeditor5-comments/src/comments/commentsrepository";
import { Context, Editor } from "ckeditor5";
import { TFunction } from "i18next";
import { TAlertContextValue } from "context/AlertContext";
import { ICommentThread } from "models/annotations.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { omit } from "utils/helpers";

const getCommentThread =
  (
    _editorOrContext: Editor | Context, // We will need this later to implement handling of failed API requests
    showAlert: TAlertContextValue["showAlert"],
    t: TFunction,
    queryClient: QueryClient,
  ): CommentsAdapter["getCommentThread"] =>
  async (data) => {
    const { channelId: componentId, threadId } = data;

    try {
      const { data }: { data: ICommentThread } = await queryClient.fetchQuery(
        [QueryAPIKey.GetCommentThread, threadId],
        () =>
          services.documents.comments.getCommentThread(
            String(componentId),
            threadId,
          ),
      );

      const { resolvedDate, resolvedBy, ...savedThread } = data;

      const comments = savedThread.comments.map((comment) => ({
        ...omit(comment, ["id", "createdDate"]),
        commentId: comment.id,
        createdAt: new Date(comment.createdDate),
      }));

      return {
        ...savedThread,
        comments,
        isFromAdapter: true,
        attributes: {},
        ...(resolvedDate ? { resolvedAt: new Date(resolvedDate) } : {}),
        ...(resolvedBy ? { resolvedBy } : {}),
      };
    } catch {
      showAlert({
        message: t("getCommentThreadFailure"),
        severity: "error",
      });
    }

    // CKEditor assumes all requests are successful, so there's nothing we can do about the return
    // value if it isn't. Future work will actually handle the errors and do something more useful
    // than alerting the user.
    return undefined as any;
  };

export default getCommentThread;
