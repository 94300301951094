export const getAdjacentElementWithTabIndex: any = (
  elem: HTMLElement,
  direction: "prev" | "next",
) => {
  const labelChild = elem.firstChild as HTMLElement;

  if (labelChild && labelChild.tabIndex > -1) {
    return labelChild;
  }

  const adjacentElem =
    direction === "next"
      ? elem.nextElementSibling
      : elem.previousElementSibling;

  if (adjacentElem) {
    return getAdjacentElementWithTabIndex(
      adjacentElem as HTMLElement,
      direction,
    );
  }

  return null;
};
