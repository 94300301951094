import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  addHAQsButton: {
    alignSelf: "start",
    margin: "-8px 0 10px",
  },
};

export default styles;
