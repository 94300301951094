import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import MultiSelectTypeahead, {
  TMultiSelectTypeaheadProps,
} from "components/shared/MultiSelectTypeahead";

export type TFormMultiSelectTypeaheadProps<
  FormType extends FieldValues,
  T,
> = Omit<TMultiSelectTypeaheadProps<T>, "error"> & {
  name: FieldPath<FormType>;
};

function FormMultiSelectTypeahead<FormType extends FieldValues, T>({
  name,
  selectAllLabel,
  expandButtonLabel,
  noOptionsText,
  helperText,
  onClose,
  ...props
}: TFormMultiSelectTypeaheadProps<FormType, T>) {
  const { t } = useTranslation("common");

  const { control, trigger } = useFormContext<FormType>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MultiSelectTypeahead<T>
          {...field}
          {...props}
          error={!!error}
          helperText={error?.message || helperText}
          selectAllLabel={selectAllLabel || t("multiSelect.selectAll")}
          expandButtonLabel={expandButtonLabel || t("ariaLabels.expand")}
          noOptionsText={noOptionsText || t("emptyState.noResults")}
          onClose={() => {
            trigger(name);
            onClose?.();
          }}
        />
      )}
    />
  );
}

export default FormMultiSelectTypeahead;
