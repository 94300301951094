import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  listItem: {
    backgroundColor: getPaletteColor("primary.50"),
    borderRadius: 1,
    padding: "0px",
    mb: 1,
    minHeight: "50px",
  },

  iconContainer: {
    display: "flex",
    p: 1,
    mr: 1,
    color: getPaletteColor("blueGrey.800"),

    "&.MuiListItemIcon-root": {
      minWidth: "auto",
    },
  },
};

export default styles;
