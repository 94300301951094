import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { Button } from "components/shared";
import { useProject } from "context/ProjectContext";
import { useStartGSP } from "hooks/project";
import useUser from "hooks/useUser";
import { useDocumentsContext } from "screens/Project/sections/Documents/DocumentsContext";
import {
  AddDocumentTeaser,
  TeaserCard,
  TeaserImgBox,
} from "screens/Project/sections/Documents/DocumentsTeasers/components";
import styles from "screens/Project/sections/Documents/DocumentsTeasers/DocumentsTeasers.styles";
import { findCompletedDocument } from "screens/Project/sections/Documents/utils/documentHelper";
import getDocumentIcons from "screens/Project/sections/Documents/utils/getDocumentIcons";
import { getPaletteColor } from "theme/helpers";
import { QueryFetchingStatus } from "utils/constants/api.constants";
import { DocumentSubtype } from "utils/constants/doc.constants";
import { isHAUser } from "utils/user/organization";
import GSPDocImg from "assets/images/gsp.png";

const GSPTeaser = () => {
  const { organization } = useUser();
  const { project } = useProject();

  const { t } = useTranslation(["documents", "common"]);

  const { requiredDocuments } = useDocumentsContext();

  const isDocumentsLoading =
    requiredDocuments.status === QueryFetchingStatus.Loading;

  const GSPDocument = useMemo(
    () => findCompletedDocument(requiredDocuments.data, DocumentSubtype.GSP),
    [requiredDocuments.data],
  );

  const { startGSP, isGSPStartLoading, getGSPLink } = useStartGSP(project);

  // If this is a host user, and there is not a GSP document to show, show nothing
  if (isHAUser(organization) && !GSPDocument) {
    return null;
  }

  // TODO: workflowStatus for document is deprecated
  const descriptionText = GSPDocument?.workflowStatus?.toLocaleLowerCase();

  const isLoading = !project || isGSPStartLoading || isDocumentsLoading;

  return GSPDocument || isLoading ? (
    <TeaserCard
      title={t("documents.teaser.gspTitle")}
      imgBox={<TeaserImgBox isLoading={isLoading} img={GSPDocImg} />}
      description={
        isLoading ? (
          <Typography
            variant="badgeLabel"
            color={getPaletteColor("text.secondary")}
          >
            {t("teaserCard.loading")}
          </Typography>
        ) : (
          <Typography
            variant="badgeLabel"
            color={getPaletteColor("text.secondary")}
          >
            {descriptionText}
          </Typography>
        )
      }
    >
      {!isLoading && (
        <Button
          data-testid="gsp-teaser-view-button"
          data-qaid="gsp-teaser-view-button"
          variant="outlined"
          component={Link}
          sx={styles.viewButton}
          to={getGSPLink(GSPDocument?.id || "")}
          disabled={isGSPStartLoading}
        >
          {t("button.view", { ns: "common" })}
        </Button>
      )}
    </TeaserCard>
  ) : (
    <AddDocumentTeaser
      data-qaid="add-gsp-teaser"
      icon={getDocumentIcons(t, "info").GSP}
      title={t("documents.teaser.gspTitle")}
      description={
        <Typography variant="caption" sx={styles.textCenter}>
          {t("documents.teaser.gspDescription")}
        </Typography>
      }
    >
      <Button
        variant="outlined"
        onClick={startGSP}
        disabled={isGSPStartLoading}
        data-testid="gsp-teaser-start-button"
        data-qaid="gsp-teaser-start-button"
      >
        {t("button.start", { ns: "common" })}
      </Button>
    </AddDocumentTeaser>
  );
};

export default GSPTeaser;
