import { useProject } from "context/ProjectContext";
import { ProjectType } from "utils/constants/project.constants";
import OrbisRecipientsField from "./OrbisRecipients/OrbisRecipients";
import RelianceRecipientsField from "./RelianceRecipients/RelianceRecipients";

type TRecipientsField = {
  fieldName: string;
  helperText?: string;
  label: string;
};

const RecipientsField = ({
  fieldName,
  helperText,
  label,
}: TRecipientsField) => {
  const { project } = useProject();

  return (
    <>
      {project?.type === ProjectType.Reliance ||
      project?.type === ProjectType.RegulatoryReview ? (
        <RelianceRecipientsField fieldName={fieldName} />
      ) : (
        <OrbisRecipientsField
          fieldName={fieldName}
          label={label}
          helperText={helperText}
        />
      )}
    </>
  );
};

export default RecipientsField;
