import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { TFunction } from "i18next";
import {
  IGSPApplicationInfo,
  IGSPComponentBase,
  TGSPComponent,
} from "models/gsp.models";
import {
  GSPFormFieldGroupName,
  GSPFormFieldName,
  isNotSubmittingItemForm,
  isSubmittingItemForm,
  TGSPAllFields,
  TGSPDefaultValues,
  TGSPForm,
  TGSPItemForm,
  TGSPItemSubmittingFormApplicationInfo,
} from "screens/Project/screens/GSP/GSPItems/GSPFormItem/GSPForm.types";
import styles from "screens/Project/screens/GSP/GSPOutline/GSPOutline.styles";
import { ISO_DATE_FORMAT } from "utils/constants/format.constants";
import { SubmissionPlan } from "utils/constants/gsp.constants";
import { formatDate } from "utils/helpers";

export type TGSPComponentsMap = { [key: string]: TGSPComponent };

export const GSPSummaryId = "dossier";

export const statusIconConfig = {
  [SubmissionPlan.Submitting]: {
    icon: (
      <CheckCircleOutlineIcon
        data-testid="check-circle-outline-icon"
        data-qaid="check-circle-outline-icon"
        sx={styles.submitIcon}
      />
    ),
  },
  [SubmissionPlan.NotSubmitting]: {
    icon: (
      <NotInterestedIcon
        data-testid="not-interested-icon"
        data-qaid="not-interested-icon"
        sx={styles.notSubmitIcon}
      />
    ),
  },
};

export const getSubmitOptions = (t: TFunction) => [
  {
    value: SubmissionPlan.Submitting,
    label: t("optionLabels.SUBMITTING"),
  },
  {
    value: SubmissionPlan.NotSubmitting,
    label: t("optionLabels.NOT_SUBMITTING"),
  },
];

export const reviewOptions = (t: TFunction) => [
  {
    label: t("gsp.item.fields.isRtorOrRollingReview.optionLabels.yes"),
    value: "true",
  },
  {
    label: t("gsp.item.fields.isRtorOrRollingReview.optionLabels.no"),
    value: "false",
  },
];

export const transformComponentToValues = (
  component: TGSPAllFields,
): TGSPDefaultValues => {
  return {
    [GSPFormFieldName.SubmissionPlan]: component.metadata?.submissionPlan || "",
    [GSPFormFieldGroupName.SponsorInfo]: {
      [GSPFormFieldName.CompanyName]:
        component.metadata.sponsorInfo?.companyName || "",
      [GSPFormFieldName.ContactName]:
        component.metadata.sponsorInfo?.contactName || "",
      [GSPFormFieldName.ContactEmail]:
        component.metadata.sponsorInfo?.contactEmail || "",
      [GSPFormFieldName.PhoneNumber]:
        component.metadata.sponsorInfo?.phoneNumber || "",
    },
    [GSPFormFieldGroupName.ApplicationInfo]: {
      [GSPFormFieldName.ApplicationType]:
        component.metadata.applicationInfo?.type || "",
      [GSPFormFieldName.ApplicationNumber]:
        component.metadata.applicationInfo?.number || "",
      [GSPFormFieldName.StartDate]:
        component.metadata.applicationInfo?.startDate || "",
      [GSPFormFieldName.ReviewType]:
        component.metadata.applicationInfo?.isRtorOrRollingReview ?? "",
      [GSPFormFieldName.ProposedDate]:
        component.metadata.applicationInfo?.proposedFillingDate || "",
      [GSPFormFieldName.Timeline]:
        component.metadata.applicationInfo?.proposedTimeline || "",
      [GSPFormFieldName.ApprovalPathway]:
        component.metadata.applicationInfo?.proposedApprovalPathway || "",
      [GSPFormFieldName.TherapeuticIndication]:
        component.metadata.applicationInfo?.proposedTherapeuticIndication || "",
      [GSPFormFieldName.ClinicalTrialsIndication]:
        component.metadata.applicationInfo
          ?.clinicalTrialsSupportingProposedIndication || "",
      [GSPFormFieldName.AdditionalComments]:
        component.metadata.applicationInfo?.additionalComments || "",
    },
    [GSPFormFieldName.NotSubmittingReason]: component.metadata?.reason || "",
  };
};

export const getDefaultValues = (components: TGSPComponent[]) => {
  return components.reduce(
    (acc, curr) => ({ ...acc, [curr.id]: transformComponentToValues(curr) }),
    {},
  );
};

export const getApplicationInfoFormData = (
  applicationInfo: TGSPItemSubmittingFormApplicationInfo,
): IGSPApplicationInfo => {
  const appInfoData: IGSPApplicationInfo = {
    number: applicationInfo[GSPFormFieldName.ApplicationNumber],
    proposedTherapeuticIndication:
      applicationInfo[GSPFormFieldName.TherapeuticIndication],
    clinicalTrialsSupportingProposedIndication:
      applicationInfo[GSPFormFieldName.ClinicalTrialsIndication],
    additionalComments: applicationInfo[GSPFormFieldName.AdditionalComments],
  };

  // do not send field if there is not a value
  if (applicationInfo[GSPFormFieldName.ApplicationType]) {
    appInfoData.type = applicationInfo[GSPFormFieldName.ApplicationType];
  }

  if (applicationInfo[GSPFormFieldName.Timeline]) {
    appInfoData.proposedTimeline = applicationInfo[GSPFormFieldName.Timeline];
  }

  if (applicationInfo[GSPFormFieldName.ApprovalPathway]) {
    appInfoData.proposedApprovalPathway =
      applicationInfo[GSPFormFieldName.ApprovalPathway];
  }

  // If value was changed, it'll come as a string from react-hook-form. If not touched, it'll be a boolean if value exists
  if (typeof applicationInfo[GSPFormFieldName.ReviewType] === "boolean") {
    appInfoData.isRtorOrRollingReview =
      applicationInfo[GSPFormFieldName.ReviewType];
  } else if (applicationInfo[GSPFormFieldName.ReviewType]) {
    appInfoData.isRtorOrRollingReview =
      applicationInfo[GSPFormFieldName.ReviewType] === "true";
  }

  if (
    applicationInfo[GSPFormFieldName.ReviewType] &&
    applicationInfo[GSPFormFieldName.StartDate]
  ) {
    appInfoData.startDate = formatDate(
      applicationInfo[GSPFormFieldName.StartDate],
      ISO_DATE_FORMAT,
    );
  }

  if (applicationInfo[GSPFormFieldName.ProposedDate]) {
    appInfoData.proposedFillingDate = formatDate(
      applicationInfo[GSPFormFieldName.ProposedDate],
      ISO_DATE_FORMAT,
    );
  }

  return appInfoData;
};

const transformItemFormToComponent = (
  itemForm: TGSPItemForm,
  componentData: TGSPComponent,
) => {
  const componentBase: Omit<IGSPComponentBase, "healthAuthority"> = {
    ...componentData,
    metadata: {
      ...componentData?.metadata,
    },
  };

  // do not send field if there is not a value selected
  if (itemForm[GSPFormFieldName.SubmissionPlan]) {
    componentBase.metadata.submissionPlan =
      itemForm[GSPFormFieldName.SubmissionPlan];
  }

  const sponsorInfo = itemForm[GSPFormFieldGroupName.SponsorInfo];

  if (sponsorInfo) {
    componentBase.metadata.sponsorInfo = {
      companyName: sponsorInfo[GSPFormFieldName.CompanyName],
      contactName: sponsorInfo[GSPFormFieldName.ContactName],
      contactEmail: sponsorInfo[GSPFormFieldName.ContactEmail],
      phoneNumber: sponsorInfo[GSPFormFieldName.PhoneNumber],
    };
  }

  if (isSubmittingItemForm(itemForm)) {
    const applicationInfo = itemForm[GSPFormFieldGroupName.ApplicationInfo];

    return {
      ...componentBase,
      metadata: {
        ...componentBase.metadata,
        applicationInfo: {
          ...getApplicationInfoFormData(applicationInfo),
        },
        reason: null,
      },
    };
  }

  if (isNotSubmittingItemForm(itemForm)) {
    return {
      ...componentBase,
      metadata: {
        ...componentBase.metadata,
        applicationInfo: {},
        reason: itemForm[GSPFormFieldName.NotSubmittingReason],
      },
    };
  }

  return componentBase;
};

export const getGSPUpdatePayload = (
  form: TGSPForm,
  GSPComponentsMap: TGSPComponentsMap,
) =>
  Object.entries(form).map(([id, form]) =>
    transformItemFormToComponent(form, GSPComponentsMap[id]),
  );

export const DOSSIER_DIFFERENCE_PAYLOAD_TYPE = "DOSSIER_DIFFERENCE";
