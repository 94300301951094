import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { SandGlassIcon } from "assets/icons";
import DefaultDocImg from "assets/images/defaultDocImg.png";
import styles from "./TeaserImgBox.styles";

type TTeaserImgBoxProps = {
  isLoading: boolean;
  img: string;
};

const TeaserImgBox = ({ isLoading, img }: TTeaserImgBoxProps) => {
  const { t } = useTranslation("documents", {
    keyPrefix: "teaserCard",
  });

  return (
    <Box sx={styles.imgBox}>
      {isLoading ? (
        <SandGlassIcon
          data-testid="sandglassIcon"
          data-qaid="sandglassIcon"
          aria-label={t("sandglassIconAriaLabel")}
        />
      ) : (
        <Box
          sx={styles.defaultImg}
          data-testid="teaser-doc-preview-image"
          data-qaid="teaser-doc-preview-image"
          style={{
            backgroundImage: `url(${img || DefaultDocImg})`,
          }}
        />
      )}
    </Box>
  );
};

export default TeaserImgBox;
