import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import useShowAlert from "hooks/useShowAlert";
import { TUserData } from "models/users.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { TErrorData } from "utils/types";

export default function useGetCurrentUser(options?: { enabled: boolean }) {
  const { t } = useTranslation("user", {
    keyPrefix: "notifications",
  });

  const {
    data: user,
    status,
    error,
    isIdle,
    isLoading,
    isError,
    isSuccess,
  } = useQuery<TUserData, AxiosError<TErrorData>>(
    [QueryAPIKey.GetCurrentUser],
    () => services.user.getCurrentUser().then((res) => res.data),
    { enabled: options?.enabled },
  );

  useShowAlert({
    status,
    failureMessage: t("getCurrentUserFailure"),
  });

  return {
    user,
    isIdle,
    isLoading,
    isError,
    isSuccess,
    error,
    status,
  };
}
