import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  modal: {
    "& .MuiDialog-paper": {
      maxWidth: "90vw",
    },
  },

  information: {
    pb: 2,
    fontSize: "16px",
  },

  filters: {
    mb: 0,
  },

  table: {
    "& .MuiTable-root": {
      backgroundColor: getPaletteColor("primary.contrastText"),
    },

    "& .MuiTableCell-head": {
      color: "text.primary",
      fontWeight: 500,
      lineHeight: 1.43,

      "&:not(.MuiTableCell-paddingCheckbox)": {
        py: 2.25,
      },
    },

    a: {
      color: getPaletteColor("text.primary"),
      textDecoration: "none",
    },
  },

  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 260,
  },
};

export default styles;
