import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import {
  HAQHistoryTable,
  THAQHistoryRowData,
} from "./HAQShareHistoryModal.types";

const columnHelper = createColumnHelper<THAQHistoryRowData>();

const getColumns = (HAQName: string, t: TFunction) => [
  columnHelper.accessor(HAQHistoryTable.Id, {
    header: HAQName,
    cell: ({ row }) => t(`viewPage.component.${row.original.HAQType}`),
    meta: { width: "25%" },
  }),
  columnHelper.accessor(HAQHistoryTable.Recipient, {
    header: t("shareHistory.columns.recipient"),
    cell: ({ row }) => row.original.recipient,
    meta: { width: "20%" },
  }),
  columnHelper.accessor(HAQHistoryTable.Date, {
    header: t("shareHistory.columns.date"),
    meta: { width: "25%", headCellStyles: { pr: 0 } },
  }),
  columnHelper.accessor(HAQHistoryTable.Status, {
    header: t("shareHistory.columns.status"),
    cell: ({ row }) => row.original.status,
    meta: { width: "30%" },
  }),
];

export default getColumns;
