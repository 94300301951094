import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import SkeletonWrapper from "components/common/SkeletonWrapper";
import { getIsEmptyString } from "utils/helpers";
import styles from "../FooterLinks.styles";

export type TDocumentLinkProps = {
  documentName: string | undefined;
  documentPath: string;
  label?: string;
  isDocumentLoading?: boolean;
};

const DocumentLink = ({
  documentName,
  documentPath,
  label,
  isDocumentLoading,
}: TDocumentLinkProps) => {
  const { t } = useTranslation("tasks", {
    keyPrefix: "taskFooter",
  });

  return (
    <SkeletonWrapper isLoading={Boolean(isDocumentLoading)}>
      {documentName && documentPath && (
        <Grid container columnSpacing={1} alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body2" sx={styles.label}>
              {getIsEmptyString(label) ? t("linkedContent") : label}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Link
              data-qaid="task-drawer-document-link"
              to={documentPath}
              style={styles.link as CSSProperties}
            >
              {documentName}
            </Link>
          </Grid>
        </Grid>
      )}
    </SkeletonWrapper>
  );
};

export default DocumentLink;
