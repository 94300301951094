import { useTranslation } from "react-i18next";
import { MoreVert, People } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { Tooltip } from "components/shared";
import ButtonMenu, { TButtonMenuProps } from "components/shared/ButtonMenu";
import { useProject } from "context/ProjectContext";
import { IDocument } from "models/documents.models";
import { EventType } from "models/tracking.models";
import { useDocumentsContext } from "screens/Project/sections/Documents/DocumentsContext";
import { useDocumentActions } from "screens/Project/sections/Documents/hooks";
import { services } from "services";
import { AssetType } from "utils/constants/assets.constants";
import { DocumentAction } from "utils/constants/doc.constants";
import { ShowSubmitHistoryIcon } from "assets/icons";

export type TRowActionsProps = {
  document: IDocument;
};

const RowActions = ({ document }: TRowActionsProps) => {
  const { t } = useTranslation("documents");

  const { setCurrentActionOnDocument } = useDocumentsContext();

  const { project } = useProject();

  const { actionOptions } = useDocumentActions(document);

  const canViewShareHistory = actionOptions.some(
    (option) => option.id === DocumentAction.ViewShareHistory,
  );

  const canViewSubmitHistory = actionOptions.some(
    (option) => option.id === DocumentAction.ViewSubmitHistory,
  );

  const canChangeStatus = actionOptions.some(
    (option) => option.id === DocumentAction.ChangeStatus,
  );

  const buttonMenuProps: TButtonMenuProps = {
    options: actionOptions,
    onClick: (event) => {
      event.stopPropagation(); // Prevents row selection from firing when clicking a menu option
    },
    ...(canChangeStatus && { value: document.status }),
  };

  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center">
      {canViewShareHistory && (
        <Tooltip
          title={t(`documents.shareHistory.items.status.SHARED`)}
          placement="left"
        >
          <IconButton
            onClick={(event) => {
              event.stopPropagation();

              setCurrentActionOnDocument({
                documents: [document],
                documentAction: DocumentAction.ViewShareHistory,
              });
            }}
          >
            <People />
          </IconButton>
        </Tooltip>
      )}

      {canViewSubmitHistory && (
        <Tooltip
          title={t(`documents.submissionHistory.submitHistoryLabel`)}
          placement="left"
        >
          <IconButton
            onClick={(event) => {
              event.stopPropagation();

              if (project?.id) {
                services.tracking.addTrackingEvent({
                  assetId: document.id,
                  assetType: AssetType.Document,
                  eventType: EventType.AppContentSubmitHistoryView,
                  projectId: project.id,
                });
              }

              setCurrentActionOnDocument({
                documents: [document],
                documentAction: DocumentAction.ViewSubmitHistory,
              });
            }}
          >
            <ShowSubmitHistoryIcon />
          </IconButton>
        </Tooltip>
      )}

      {!isEmpty(buttonMenuProps.options) && (
        <ButtonMenu {...buttonMenuProps}>
          <IconButton
            aria-label={t("documentHeader.actionsButton")}
            size="large"
          >
            <MoreVert />
          </IconButton>
        </ButtonMenu>
      )}
    </Stack>
  );
};

export default RowActions;
