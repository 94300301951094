import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormTextInput } from "components/common/form-fields";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { queryClient } from "config/reactQueryClient";
import { useAlerts } from "context/AlertContext";
import useUpdateAssets from "hooks/api/REST/assets/useUpdateAssets";
import { IDocument } from "models/documents.models";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import {
  ChangeDocumentNameFields,
  TChangeDocumentNameProps,
  TChangeDueDateForm,
} from "./ChangeDocumentName.types";
import { documentNameInputSchema } from "./ChangeDocumentName.utils";
import styles from "./ChangeDocumentName.styles";

const ChangeDocumentName = ({
  document,
  onClose,
}: TChangeDocumentNameProps) => {
  const { t } = useTranslation(["documents", "common"]);
  const { showAlert } = useAlerts();

  const methods = useForm<TChangeDueDateForm>({
    defaultValues: { [ChangeDocumentNameFields.DossierName]: "" },
    mode: "all",
    resolver: zodResolver(
      documentNameInputSchema(
        document?.name ?? "",
        t("changeDocumentNameModal.inputValidationMessage", {
          ns: "documents",
        }),
      ),
    ),
  });

  const { mutateAsync: updateDocumentName, isLoading } =
    useUpdateAssets<IDocument[]>();

  const {
    formState: { isValid },
    handleSubmit,
  } = methods;

  const onSubmit = (data: TChangeDueDateForm) => {
    if (Boolean(document)) {
      updateDocumentName(
        {
          data: [
            {
              ...document!,
              name: data[ChangeDocumentNameFields.DossierName],
            },
          ],
        },
        {
          onSuccess: () => {
            showAlert({
              severity: AlertSeverity.Success,
              message: t("changeDocumentNameModal.successUpdateMessage", {
                ns: "documents",
                contentName: document?.name,
              }),
            });

            queryClient.invalidateQueries([QueryAPIKey.GetDocuments]);

            onClose();
          },
          onError: () => {
            showAlert({
              severity: AlertSeverity.Error,
              message: t("changeDocumentNameModal.failedUpdateMessage", {
                ns: "documents",
              }),
            });

            onClose();
          },
        },
      );
    }
  };

  return (
    <Modal
      open
      data-qaid="change-document-name-modal"
      title={t("changeDocumentNameModal.title")}
    >
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <FormTextInput
          data-qaid="document-name-change-text-field"
          label={t("changeDocumentNameModal.inputLabel")}
          name={ChangeDocumentNameFields.DossierName}
          placeholder={document?.name}
          // to always show placeholder inside of input
          InputLabelProps={{ shrink: true }}
          sx={styles.dossierNameInput}
        />
        <ModalActionButtons>
          <Button
            data-qaid="change-document-name-cancel-button"
            onClick={onClose}
          >
            {t("button.cancel", { ns: "common" })}
          </Button>
          <Button
            data-qaid="change-document-name-button"
            variant="contained"
            type="submit"
            disabled={!isValid}
            loading={isLoading}
          >
            {t("button.confirm", { ns: "common" })}
          </Button>
        </ModalActionButtons>
      </FormWrapper>
    </Modal>
  );
};

export default ChangeDocumentName;
