import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { useProject } from "context/ProjectContext";
import useUser from "hooks/useUser";
import { IDocument } from "models/documents.models";
import { TGSPComponent } from "models/gsp.models";
import { getCanDocumentBeSent } from "screens/Project/sections/Documents/utils/documentActions";
import { mapOrganizationsToSelectOptions } from "utils/helpers/mapOrganizationsToSelectOptions";
import AddDocuments, { useAddDocuments } from "../fields/AddDocuments";
import FieldsLayout from "./FieldsLayout";
import {
  getSendCopyDocumentSchema,
  SendCopyDocumentFieldName,
  TSendCopyDocumentForm,
} from "./SendCopyDocuments.types";
import { getSendCopyDefaultValues } from "./SendCopyDocuments.utils";

export type TSendCopyDocumentsProps = {
  documents: IDocument[] | TSendCopyGSP[];
  onClose: () => void;
  projectId: string | undefined;
  GSPComponents?: TGSPComponent[];
};

export type TSendCopyGSP = { id: string; name: string };

const SendCopyDocuments = ({
  documents,
  onClose,
  projectId,
  GSPComponents,
}: TSendCopyDocumentsProps) => {
  const { organization } = useUser();

  const { participatingHAOrganizationsExceptUserOrganization } = useProject();

  const recipientOptions = useMemo(
    () =>
      mapOrganizationsToSelectOptions(
        participatingHAOrganizationsExceptUserOrganization,
      ),
    [participatingHAOrganizationsExceptUserOrganization],
  );

  const { t } = useTranslation(["documents", "common"]);

  const methods = useForm<TSendCopyDocumentForm>({
    mode: "all",
    defaultValues: getSendCopyDefaultValues(documents),
    resolver: zodResolver(getSendCopyDocumentSchema(t)),
  });

  const {
    getValues,
    formState,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    watch,
  } = methods;

  useEffect(() => {
    reset(getSendCopyDefaultValues(documents));
  }, [reset, documents]);

  const selectedDocuments = watch(
    SendCopyDocumentFieldName.Documents,
  ) as IDocument[];

  const { isAddState, setIsAddState, docsToAdd, ...addDocumentsProps } =
    useAddDocuments({
      projectId,
      validateDocument: (document: IDocument) =>
        getCanDocumentBeSent(document, organization),
      selectedDocuments,
    });

  const updateDocumentsValue = useCallback(() => {
    setValue(SendCopyDocumentFieldName.Documents, [
      ...selectedDocuments,
      ...docsToAdd,
    ]);
    clearErrors(SendCopyDocumentFieldName.Documents);
  }, [docsToAdd, selectedDocuments, setValue, clearErrors]);

  //pass data to the server when Backend will be ready FTE-3124
  const handleDocumentsSubmission = async () => getValues();

  return (
    <Modal
      open
      data-qaid="send-copy-documents-modal"
      title={t("documents.sendCopyDocuments.modalTitle")}
    >
      <FormWrapper
        methods={methods}
        onSubmit={handleSubmit(handleDocumentsSubmission)}
      >
        {isAddState ? (
          <AddDocuments {...addDocumentsProps} />
        ) : (
          <FieldsLayout
            setIsAddDocsView={() => setIsAddState(true)}
            //documents cannot be added if the GSP page
            canAddDocuments={!Array.isArray(GSPComponents)}
            listOfRecipients={recipientOptions}
          />
        )}
        <ModalActionButtons
          buttons={
            isAddState
              ? [
                  <Button
                    key="cancel"
                    data-qaid="add-documents-cancel-button"
                    variant="text"
                    onClick={() => setIsAddState(false)}
                  >
                    {t("button.cancel", { ns: "common" })}
                  </Button>,
                  <Button
                    color="primary"
                    key="submit"
                    data-qaid="add-documents-submit-button"
                    variant="contained"
                    onClick={() => {
                      updateDocumentsValue();
                      setIsAddState(false);
                    }}
                    disabled={!Boolean(docsToAdd.length)}
                  >
                    {t("documents.sendCopyDocuments.modalAddDocumentsSendCopy")}
                  </Button>,
                ]
              : [
                  <Button
                    key="cancel"
                    data-qaid="send-copy-documents-cancel-button"
                    variant="text"
                    onClick={onClose}
                  >
                    {t("button.cancel", { ns: "common" })}
                  </Button>,
                  <Button
                    color="primary"
                    key="submit"
                    data-qaid="send-copy-documents-submit-button"
                    variant="contained"
                    disabled={!formState.isValid}
                    type="submit"
                  >
                    {t("button.submit", { ns: "common" })}
                  </Button>,
                ]
          }
        />
      </FormWrapper>
    </Modal>
  );
};

export default SendCopyDocuments;
