import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { queryClient } from "config/reactQueryClient";
import useTasks from "hooks/api/REST/tasks/useTasks";
import useStartWorkflow from "hooks/api/REST/workflow/useStartWorkflow";
import useUser from "hooks/useUser";
import { IProject } from "models/projects.models";
import { ITaskExtendedData } from "models/tasks.models";
import { Path } from "router/paths";
import {
  DEFAULT_POLLING_INTERVAL,
  QueryAPIKey,
} from "utils/constants/api.constants";
import { TaskType } from "utils/constants/tasks.constants";
import { WorkflowId } from "utils/constants/workflows.constants";

const useStartGSP = (project?: IProject | null) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { t } = useTranslation(["notifications"]);
  const [isLoading, setIsLoading] = useState(false);

  const { startWorkflow, isSuccess: isStartWorkflowSuccess } = useStartWorkflow(
    {
      successMessage: t("workflowMessages.startGSPSuccessMessage"),
      failureMessage: t("workflowMessages.startGSPErrorMessage"),
    },
  );

  const getGSPLink = useCallback(
    (id: string) =>
      Path.ProjectGSP.replace(":projectId", project?.id ?? "").replace(
        ":GSPId",
        id,
      ),
    [project?.id],
  );

  useTasks({
    params: {
      includeTypes: TaskType.CreateGSP,
      onlyMyTasks: false,
      content: {
        project: project?.id,
      },
    },
    options: {
      enabled: isStartWorkflowSuccess,
      refetchInterval: (data) =>
        data?.length ? false : DEFAULT_POLLING_INTERVAL,
      onSuccess: (data: ITaskExtendedData[]) => {
        if (!isEmpty(data)) {
          const taskAssets = data[0].content?.assets || [];

          if (!isEmpty(taskAssets)) {
            setIsLoading(false);
            navigate(getGSPLink(taskAssets[0]));
            queryClient.invalidateQueries(QueryAPIKey.GetDocuments);
          }
        }
      },
    },
  });

  return useMemo(
    () => ({
      startGSP: () => {
        if (project?.id && user?.id) {
          startWorkflow({
            id: WorkflowId.StartGSP,
            payload: {
              originator: user.id,
              project: project?.id,
              projectMetadata: project?.metadata,
            },
          });
          setIsLoading(true);
        }
      },
      getGSPLink,
      isGSPStartLoading: isLoading,
    }),
    [
      getGSPLink,
      isLoading,
      project?.id,
      project?.metadata,
      startWorkflow,
      user?.id,
    ],
  );
};

export default useStartGSP;
