import { Breakpoints } from "@mui/material";

const breakpoints: Partial<Breakpoints> = {
  values: {
    //MUI default used as Grid props
    xs: 0,
    sm: 600,
    md: 900,
    //minimum supported width
    lg: 1080,
    //default Figma width
    xl: 1440,
  },
};

export default breakpoints;
