import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import HealthAuthority from "components/common/HealthAuthority";
import { getHAName } from "components/common/HealthAuthority/HealthAuthority.utils";
import { IGSPHealthAuthority } from "models/gsp.models";
import { GSPFormFieldName } from "screens/Project/screens/GSP/GSPItems/GSPFormItem/GSPForm.types";
import { statusIconConfig } from "screens/Project/screens/GSP/GSPItems/GSPFormItem/GSPForm.utils";
import styles from "screens/Project/screens/GSP/GSPOutline/GSPOutline.styles";
import { SubmissionPlan } from "utils/constants/gsp.constants";

export type TOutlineItemProps = {
  HA: IGSPHealthAuthority;
  componentId: string;
};

const OutlineItem = ({ HA, componentId }: TOutlineItemProps) => {
  const { watch } = useFormContext();

  const { t } = useTranslation(["user", "countries"]);

  const selectedSubmissionPlan: SubmissionPlan = watch(
    `${componentId}.${GSPFormFieldName.SubmissionPlan}`,
  );

  return (
    <>
      <HealthAuthority
        country={HA.country}
        name={getHAName(HA.country, HA.shortName, t)}
        sx={styles.name}
      />
      {selectedSubmissionPlan && statusIconConfig[selectedSubmissionPlan]?.icon}
    </>
  );
};

export default OutlineItem;
