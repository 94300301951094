import { TFunction } from "i18next";
import {
  DocumentComponentStatus,
  DocumentStatus,
} from "utils/constants/doc.constants";
import { HAQStatus } from "utils/constants/HAQ.constants";
import { TenantType } from "utils/constants/users.constants";
import { EMPTY_LABEL } from "../getTextValue";

const getDisplayedAssetStatus = (
  tenantType: TenantType | string | undefined,
  status:
    | DocumentStatus
    | HAQStatus
    | DocumentComponentStatus
    | string
    | undefined,
  t: TFunction,
) =>
  status
    ? t(`asset.${status}`, {
        tenantType: tenantType ? t(`common:tenantType.${tenantType}`) : "",
        ns: "statuses",
      }).trim()
    : EMPTY_LABEL;

export default getDisplayedAssetStatus;
