import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TMenuOption } from "components/shared/ButtonMenu";
import { TDocumentViewMode } from "models/documents.models";
import { DocumentAction, DocumentStatus } from "utils/constants/doc.constants";
import { DocumentViewMode } from "utils/constants/doc.constants";
import { getDisplayedAssetStatus } from "utils/helpers";
import { TDocumentAction, TDocumentComponent } from "../Document.types";
import { checkIfPossibleToSendCopyComponent } from "../modals/SendCopyComponents/SendCopyComponents.utils";

type TUseDocumentComponentActions = {
  component: TDocumentComponent;
  viewMode: TDocumentViewMode;
  onActionSelect: (action: TDocumentAction) => void;
  handleEdit: () => void;
  isEditingEnabled: boolean;
};

export const useDocumentComponentActions = ({
  component,
  viewMode,
  onActionSelect,
  handleEdit,
  isEditingEnabled,
}: TUseDocumentComponentActions) => {
  const { t } = useTranslation(["documents", "common", "statuses"]);

  const actionOptions = useMemo<TMenuOption[]>(() => {
    const statusOptions: TMenuOption[] = [
      {
        id: DocumentStatus.Draft,
        label: getDisplayedAssetStatus(
          component?.componentType,
          DocumentStatus.Draft,
          t,
        ),
      },
      {
        id: DocumentStatus.Final,
        label: getDisplayedAssetStatus(
          component?.componentType,
          DocumentStatus.Final,
          t,
        ),
      },
    ];

    const options: TMenuOption[] = [
      {
        label: t("button.edit", { ns: "common" }),
        id: DocumentAction.Edit,
        disabled: !isEditingEnabled,
        onClick: handleEdit,
      },
      {
        label: t("documentHeader.actions.changeStatus"),
        id: DocumentAction.ChangeStatus,
        items: statusOptions,
      },
    ];

    options.push({
      id: DocumentAction.DeleteVersions,
      label: t("documentComponent.actions.deleteVersion"),
      disabled: viewMode !== DocumentViewMode.Editing,
      onClick: () =>
        onActionSelect({ component, type: DocumentAction.DeleteVersions }),
    });

    if (checkIfPossibleToSendCopyComponent(component.status)) {
      options.push({
        id: DocumentAction.SendCopy,
        label: t("documentComponent.actions.sendCopy"),
        onClick: () =>
          onActionSelect({ component, type: DocumentAction.SendCopy }),
      });
    }

    return options;
  }, [component, viewMode, onActionSelect, handleEdit, isEditingEnabled, t]);

  return { actionOptions };
};
