import { TAssetGraphQL } from "hooks/api/GQL/assets/useAssets.models";
import { IGSPSubmittingComponentMetadata } from "models/gsp.models";
import { DocumentSubtype } from "utils/constants/doc.constants";
import { SubmissionPlan } from "utils/constants/gsp.constants";

export const getParticipatingHATenantIds = (
  gspComponents: TAssetGraphQL<IGSPSubmittingComponentMetadata>[],
) => {
  return gspComponents.reduce<string[]>((tenantIds, component) => {
    if (
      component.type === DocumentSubtype.GSPComponent &&
      component?.metadata?.tenantId &&
      component?.metadata?.submissionPlan === SubmissionPlan.Submitting
    ) {
      tenantIds.push(component.metadata.tenantId);
    }

    return tenantIds;
  }, []);
};
