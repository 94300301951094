import React, { Ref, useState } from "react";
import { useTranslation } from "react-i18next";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { IconButton, Popover } from "@mui/material";
import TextField, { BaseTextFieldProps } from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  StaticDatePicker as MuiStaticDatePicker,
  StaticDatePickerProps as MuiStaticDatePickerProps,
} from "@mui/x-date-pickers/StaticDatePicker";
import { FALLBACK_LANGUAGE } from "utils/constants/common";
import { getDateValue } from "utils/helpers";

export type TStaticDatePickerProps = Omit<
  MuiStaticDatePickerProps<Date, Date>,
  "label" | "onChange" | "renderInput" | "value"
> &
  Pick<BaseTextFieldProps, "id" | "sx"> & {
    value?: Date | number | string;
    buttonLabel?: string;
    onChange?: (value: Date | null, keyboardInputValue?: string) => void;
    onAccept?: (value: Date | null, keyboardInputValue?: string) => void;
  };

const StaticDatePicker = React.forwardRef(
  (
    {
      id,
      value,
      disabled,
      buttonLabel,
      onChange,
      onAccept,
      ...props
    }: TStaticDatePickerProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const { i18n } = useTranslation();

    require(`dayjs/locale/${i18n.language || FALLBACK_LANGUAGE}.js`);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChange = (value: Date | null) => {
      onChange?.(value);
    };

    const handleAccept = (value: Date | null) => {
      onAccept?.(value);
      handleClose();
    };

    return (
      <>
        <IconButton
          role="button"
          aria-label={buttonLabel}
          aria-haspopup="dialog"
          onClick={handleClick}
          disabled={disabled}
        >
          <CalendarTodayIcon
            sx={{ fontSize: "1.5rem" }}
            data-qaid="calendar-today-icon"
          />
        </IconButton>
        <Popover
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handleClose}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={i18n.language}
          >
            <MuiStaticDatePicker
              data-testid={id}
              disabled={disabled}
              displayStaticWrapperAs="desktop"
              value={getDateValue(value)}
              renderInput={(params) => <TextField ref={ref} {...params} />}
              onChange={handleChange}
              onAccept={handleAccept}
              {...props}
            />
          </LocalizationProvider>
        </Popover>
      </>
    );
  },
);

export default StaticDatePicker;
