import { useTranslation } from "react-i18next";
import { MoreVert } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import ButtonMenu, { TButtonMenuProps } from "components/shared/ButtonMenu";
import { IDocument } from "models/documents.models";
import { getTeaserTitleAndDescription } from "screens/Project/sections/Documents/DocumentsTeasers/DocumentsTeasers.utils";
import { useDocumentActions } from "screens/Project/sections/Documents/hooks";
import { DocumentAction } from "utils/constants/doc.constants";

export type TTeaserActionsProps = {
  document: IDocument;
};

const TeaserActions = ({ document }: TTeaserActionsProps) => {
  const { t } = useTranslation("documents");

  const { title: teaserTitle } = getTeaserTitleAndDescription(document.type, t);

  const { actionOptions } = useDocumentActions(document);

  const canChangeStatus = actionOptions.some(
    (option) => option.id === DocumentAction.ChangeStatus,
  );

  const buttonMenuProps: TButtonMenuProps = {
    options: actionOptions,
    ...(canChangeStatus && { value: document.status }),
  };

  return !isEmpty(buttonMenuProps.options) ? (
    <ButtonMenu {...buttonMenuProps}>
      <IconButton
        aria-label={t("documents.teaser.actions", { teaserTitle })}
        size="large"
      >
        <MoreVert />
      </IconButton>
    </ButtonMenu>
  ) : null;
};

export default TeaserActions;
