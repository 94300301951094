export enum ModalTypes {
  Add = "ADD",
  Edit = "EDIT",
}

export enum TaskDrawerModalType {
  ChangeDueDate,
  AddAssignees,
  ReassignAssignee,
  RemoveAssignees,
  Cancellation,
}

export enum TaskDrawerModalType_deprecated {
  ReassignModal,
  ChangeDueDateModal,
  RejectGSPModal,
  CancelModal,
}
