import { ReactNode } from "react";

export enum ProductVariantsTable {
  Id = "id",
  Type = "type",
  RouteOfAdministration = "routeOfAdministration",
  ManufacturedDose = "manufacturedDose",
  Strength = "strength",
  Status = "status",
  Actions = "actions",
}

export type TProductVariantsRowData = {
  [ProductVariantsTable.Id]: string;
  [ProductVariantsTable.Type]: string;
  [ProductVariantsTable.RouteOfAdministration]: string;
  [ProductVariantsTable.ManufacturedDose]: string;
  [ProductVariantsTable.Strength]: string;
  [ProductVariantsTable.Status]: string;
  [ProductVariantsTable.Actions]: ReactNode;
};
