import { ReactNode } from "react";
import { EntityStatus } from "models/entities.models";
import { TLinkedOrganizationEntity } from "screens/EntityManagement/components/Organization/Organization.types";
import { TDrugClassType } from "../Substances.types";

export enum SubstancesTable {
  Id = "id",
  Name = "name",
  Class = "class",
  INN = "INN",
  ATC = "ATC",
  LinkedOrganizations = "linkedOrganizations",
  Status = "status",
  Actions = "actions",
}

export type TSubstancesRowData = {
  [SubstancesTable.Id]: string;
  [SubstancesTable.Name]: string;
  [SubstancesTable.Class]: TDrugClassType;
  [SubstancesTable.INN]: string;
  [SubstancesTable.ATC]: string;
  [SubstancesTable.LinkedOrganizations]: TLinkedOrganizationEntity[];
  [SubstancesTable.Status]: EntityStatus;
  [SubstancesTable.Actions]: ReactNode;
};
