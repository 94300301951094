import { useMemo } from "react";
import useUser from "hooks/useUser";
import { getWorkingTimeDifference } from "utils/helpers";
import { TISODateFormat } from "utils/types";

export default (dueDate: TISODateFormat) => {
  const { user } = useUser();

  return useMemo(
    () =>
      getWorkingTimeDifference(dueDate, user?.preferences.timezone?.toString()),
    [dueDate, user],
  );
};
