import { useTranslation } from "react-i18next";
import { Box, Stack, Typography } from "@mui/material";
import { useGSPContext } from "context/GSPContext";
import { TGSPComponent } from "models/gsp.models";
import { GSPSummaryId } from "screens/Project/screens/GSP/GSPItems/GSPFormItem/GSPForm.utils";
import { OutlineItem } from "./components";
import styles from "./GSPOutline.styles";

type TGSPOutlineProps = {
  components: TGSPComponent[];
};

const GSPOutline = ({ components }: TGSPOutlineProps) => {
  const { activeGSPItem, setActiveGSPItem } = useGSPContext();

  const { t } = useTranslation("projects");

  const onClick = (id: string) => {
    setActiveGSPItem((activeItem) => (activeItem === id ? "" : id));
  };

  return (
    <Box sx={styles.outlineInfoMenu}>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        sx={styles.outlineHeader}
      >
        {t("projectGSP.outlineLabel")}
      </Typography>
      <Box>
        {components?.map(({ id, healthAuthority: HA }) => (
          <Box
            key={id}
            sx={
              activeGSPItem === id ? styles.activeOutlineRow : styles.outlineRow
            }
            onClick={() => onClick(id)}
          >
            <OutlineItem HA={HA} componentId={id} />
          </Box>
        ))}
        <Box
          sx={
            activeGSPItem === GSPSummaryId
              ? styles.activeOutlineRow
              : styles.outlineRow
          }
          onClick={() => onClick(GSPSummaryId)}
        >
          <Stack direction="row" alignItems="center" sx={styles.name}>
            <Typography variant="body2" component="span">
              {t("dossierDifferences.summaryLabel")}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default GSPOutline;
