import { ProjectType } from "./project.constants";

export enum UserAction {
  ProjectCreate = "project:create",
  ProjectView = "project:view",
  AssetWrite = "asset:write",
  AssetView = "asset:view",
}

export enum UserStatus {
  Pending = "PENDING",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  LockedOut = "LOCKED_OUT",
}

export enum TenantType {
  HA = "HA",
  Sponsor = "SPONSOR",
}

export enum Role {
  // systemRoles
  AccumulusAdmin = "ACC_ADMIN",
  OrganizationAdmin = "ORG_ADMIN",
  BusinessAdmin = "BUS_ADMIN",
  ProgramAdministrator = "PROGRAM_ADMINISTRATOR",
  StandardUser = "STANDARD_USER",
  ProgramManager = "PROGRAM_MANAGER",

  // projectRoles
  ProjectManager = "PROJECT_MANAGER",
  ContentEditor = "CONTENT_EDITOR",
  ContentViewer = "CONTENT_VIEWER",
}

// ToDo (AV): Doing this on the FE may not be necessary in future. We will have to revisit this after the pilot

// ToDo (AC): Confirmed that this concept no longer applies. Will need to revisit in 1.4 ( FTE-23238 )
// A quick fix was requested in the mean time

export const ROLES_PRIORITIES = {
  [Role.AccumulusAdmin]: 0,
  [Role.OrganizationAdmin]: 1,
  [Role.BusinessAdmin]: 2,
  [Role.ProgramAdministrator]: 3,
  [Role.ProgramManager]: 4,
  [Role.ProjectManager]: 5,
  [Role.StandardUser]: 6,
  [Role.ContentEditor]: 7,
  [Role.ContentViewer]: 8,
};

export const SUPPORTED_ROLES_MAP = {
  [ProjectType.Reliance]: {
    [TenantType.HA]: [
      Role.ProjectManager,
      Role.ContentEditor,
      Role.ContentViewer,
    ],
    [TenantType.Sponsor]: [
      Role.ProjectManager,
      Role.ContentEditor,
      Role.ContentViewer,
    ],
  },
  [ProjectType.RegulatoryReview]: {
    [TenantType.HA]: [
      Role.ProjectManager,
      Role.ContentEditor,
      Role.ContentViewer,
    ],
    [TenantType.Sponsor]: [
      Role.ProjectManager,
      Role.ContentEditor,
      Role.ContentViewer,
    ],
  },
  [ProjectType.Orbis]: {
    [TenantType.HA]: [
      Role.ProjectManager,
      Role.ContentEditor,
      Role.ContentViewer,
    ],
    [TenantType.Sponsor]: [
      Role.ProjectManager,
      Role.ContentEditor,
      Role.ContentViewer,
    ],
  },
  [ProjectType.Labeling]: {
    [TenantType.HA]: [Role.ProjectManager],
    [TenantType.Sponsor]: [Role.ProjectManager],
  },
};

export const VIEW_ONLY_MODE_EXCLUDED_ROLES = [Role.ContentEditor];
