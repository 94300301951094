import { ChangeEvent } from "react";
import { InputAdornment, TextFieldProps } from "@mui/material";
import TextField from "components/shared/TextField";
import { SearchIcon } from "assets/icons";
import styles from "./KeywordFilterField.styles";

export type TKeywordFilterFieldProps = Omit<TextFieldProps, "onChange"> & {
  onChange?: (value: string) => void;
  filterByKeywordLabel?: string;
  variant?: "outlined" | "standard" | "filled";
  errorAdornmentAriaLabel: string;
};

const KeywordFilterField = (props: TKeywordFilterFieldProps) => {
  const { onChange, placeholder, filterByKeywordLabel, ...textFieldProps } =
    props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  const handleClear = () => {
    onChange?.("");
  };

  return (
    <TextField
      data-testid="filter-by-indication-text-box"
      data-qaid="filter-by-indication-text-box"
      inputProps={{
        "aria-label": placeholder || filterByKeywordLabel,
      }}
      variant={props.variant || "outlined"}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={styles.startAdornment}>
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
      onChange={handleChange}
      onClear={handleClear}
      placeholder={placeholder || filterByKeywordLabel}
      size="small"
      sx={styles.keywordFilterField}
      {...textFieldProps}
    />
  );
};

export default KeywordFilterField;
