import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    mb: 1.5,

    "& a": {
      textDecoration: "none",
    },
  },
};

export default styles;
