import { TAssetGraphQL } from "hooks/api/GQL/assets/useAssets.models";
import { IDossierDifferencesMetadata } from "models/assets.models";
import { IGSPHealthAuthority, TGSPComponent } from "models/gsp.models";
import { TDossierDifferencesModalFormEdit } from "screens/Project/screens/GSP/GSPItems";
import {
  CTOC_DOCUMENT_MODULES_MAP,
  CTOC_DOCUMENT_SECTIONS_MAP,
} from "utils/constants/CTOCDocument/CTOCDocumentMapping";
import { SubmissionPlan } from "utils/constants/gsp.constants";
import { defaultCompare } from "utils/helpers/defaultCompare";

export type TDossierDifferencesModule = {
  id: string;
  name: string;
  sections: TDossierDifferencesSection[];
};

export type TDossierDifferencesSection = {
  name: string;
  parentName?: string;
  summaryOfDifferences: string;
  healthAuthorities: IGSPHealthAuthority[];
  dossierDifferenceId: string;
};

export const mapHAToDropDownOption = (HAs: IGSPHealthAuthority[]) =>
  HAs.map((HA) => {
    return {
      label: HA.name,
      value: HA.tenantId || "",
    };
  });

const getDossierDifferencesSections = (
  dossierDifferences: TAssetGraphQL<IDossierDifferencesMetadata>[] | undefined,
  components: TGSPComponent[],
) =>
  dossierDifferences?.reduce(
    (sections: TDossierDifferencesSection[], currentDossierDifference) => {
      const {
        sections: sectionNames,
        summaryOfDifferences,
        applicableHealthAuthorityTenantIds,
      } = currentDossierDifference.metadata;

      const filteredComponents = components.filter(
        (component) =>
          component.healthAuthority.tenantId &&
          applicableHealthAuthorityTenantIds.includes(
            component.healthAuthority.tenantId,
          ),
      );

      const healthAuthorities = filteredComponents.map(
        (component) => component.healthAuthority,
      );

      sectionNames.forEach((sectionName: string) => {
        const parentName = Object.keys(CTOC_DOCUMENT_SECTIONS_MAP).find(
          (name) => CTOC_DOCUMENT_SECTIONS_MAP[name].includes(sectionName),
        );

        sections.push({
          name: sectionName,
          summaryOfDifferences,
          healthAuthorities,
          dossierDifferenceId: currentDossierDifference.id,
          ...(parentName && { parentName }),
        });
      });

      return sections;
    },
    [],
  ) || [];

/**
 * Function below forms Dossier Differences Modules, which are displayed
 * inside Summary of Differences at the bottom of the GSP page
 * @param dossierDifferences Dossier Differences assets coming form BE
 * @param components GSP components (part of `GSPData` object formed at the GSP top level by `transformGSPAssets` function)
 * @returns array of objects of `TDossierDifferencesModule` type
 */
export const getDossierDifferencesModules = (
  dossierDifferences: TAssetGraphQL<IDossierDifferencesMetadata>[] | undefined,
  components: TGSPComponent[],
) => {
  const dossierDifferencesByModules: TDossierDifferencesModule[] = [];

  const sections = getDossierDifferencesSections(
    dossierDifferences,
    components,
  );

  if (sections?.length) {
    sections.sort((a, b) => defaultCompare(a.name, b.name));

    sections.forEach((section) => {
      const moduleName = Object.keys(CTOC_DOCUMENT_MODULES_MAP).find(
        (moduleName) =>
          CTOC_DOCUMENT_MODULES_MAP[moduleName].includes(section.name),
      );

      if (moduleName) {
        const module = dossierDifferencesByModules.find(
          (module) => module.name === moduleName,
        );

        if (module) {
          module.sections.push(section);
        } else {
          const moduleIndex = Object.keys(CTOC_DOCUMENT_MODULES_MAP).findIndex(
            (key) => key === moduleName,
          );

          dossierDifferencesByModules.push({
            id: (moduleIndex + 1).toString(),
            name: moduleName,
            sections: [section],
          });
        }
      }
    });
  }

  return dossierDifferencesByModules;
};

/**
 * Function below gets Dossier Differences Modules for a specific HA by its `tenantId`
 * Modules are displayed inside Country Specific Dossier Difference block for each HA
 * @param dossierDifferencesModules Dossier Differences Modules formed by `getDossierDifferencesModules` function
 * @param tenantId `tenantId` of a particular HA
 * @returns array of objects of `TDossierDifferencesModule` type
 */
export const getDossierDifferencesModulesForHA = (
  dossierDifferencesModules: TDossierDifferencesModule[],
  tenantId: string,
) =>
  dossierDifferencesModules.reduce(
    (modules: TDossierDifferencesModule[], currentModule) => {
      const sections = currentModule.sections.reduce(
        (sections: TDossierDifferencesSection[], currentSection) => {
          const isSectionRelevant = currentSection.healthAuthorities.some(
            (HA) => HA.tenantId === tenantId,
          );

          if (isSectionRelevant) {
            const healthAuthorities = currentSection.healthAuthorities.filter(
              (HA) => HA.tenantId !== tenantId,
            );

            sections.push({
              ...currentSection,
              healthAuthorities,
            });
          }

          return sections;
        },
        [],
      );

      if (sections.length) {
        modules.push({
          ...currentModule,
          sections,
        });
      }

      return modules;
    },
    [],
  );

// function below just helpers for prepareDossierDifferenceEditData
const filterModulesBySection = (
  currentSection: TDossierDifferencesSection,
  module: TDossierDifferencesModule[],
) => {
  return module
    .reduce(
      (sections: [] | TDossierDifferencesSection[], mod) =>
        [...sections, mod.sections] as TDossierDifferencesSection[],
      [],
    )
    .flat()
    .filter(
      (section) =>
        section.dossierDifferenceId === currentSection.dossierDifferenceId,
    );
};

const prepareSections = (filteredSections: TDossierDifferencesSection[]) => {
  const preparedSections = filteredSections.reduce(
    (acc: [] | string[], element) => [...acc, element.name],
    [],
  );

  return Array.from(new Set(preparedSections));
};

/**
 * Function below gets prepare data from Dossier Difference view for form in Dossier Difference Modal
 * @param section section where Edit button has been clicked
 * @param module all Dossier Differences
 * @param initialHA for Country specific view where current HA doesn't exist in dataSet and should be added apparently
 * @returns `TDossierDifferencesModalFormEdit` type for form
 */

export const prepareDossierDifferenceEditData = (
  section: TDossierDifferencesSection,
  module: TDossierDifferencesModule[],
  initialHA?: { name: string; tenantId: string },
): TDossierDifferencesModalFormEdit => ({
  summaryOfDifferences: section.summaryOfDifferences,
  sections: prepareSections(filterModulesBySection(section, module)),
  healthAuthorities: initialHA
    ? mapHAToDropDownOption([
        ...section.healthAuthorities,
        initialHA as IGSPHealthAuthority,
      ])
    : mapHAToDropDownOption([...section.healthAuthorities]),
  dossierDifferenceId: section.dossierDifferenceId,
});

// filter for HA in Submitting status
export const submittingHAsFilter = (component: TGSPComponent) =>
  component.metadata?.submissionPlan === SubmissionPlan.Submitting &&
  component.healthAuthority.tenantId;

// filter for HA with submission plan
export const hasSubmissionPlanHAsFilter = (component: TGSPComponent) =>
  component.metadata?.submissionPlan;

// filter out the user's own HA
export const participatingHAsFilter =
  (userTenantId: string) => (component: TGSPComponent) =>
    component.healthAuthority.tenantId !== userTenantId;
