import { ProductEntityFieldName } from "../ProductEntityFormFields/ProductEntityFormFields.types";
import { ProductVariantFieldName } from "../ProductVariantFormFields/ProductVariantFormFields.types";

export const productVariantFormDefaultValues = {
  [ProductVariantFieldName.ProductType]: "",
  [ProductVariantFieldName.RouteOfAdministration]: "",
  [ProductVariantFieldName.ManufacturedDoseForm]: "",
  [ProductVariantFieldName.StrengthValue]: "",
  [ProductVariantFieldName.StrengthType]: "",
  [ProductVariantFieldName.StrengthVolumeValue]: "",
  [ProductVariantFieldName.StrengthVolumeType]: "",
  [ProductVariantFieldName.Status]: "",
};

export const productEntityFormDefaultValues = {
  [ProductEntityFieldName.ProductName]: "",
  [ProductEntityFieldName.NonproprietaryName]: "",
  [ProductEntityFieldName.LinkedSubstances]: [],
  [ProductEntityFieldName.LinkedOrganizations]: [],
  [ProductEntityFieldName.LinkedProductFamilies]: [],
  [ProductEntityFieldName.Status]: "",
  [ProductEntityFieldName.ProductVariants]: [productVariantFormDefaultValues],
};

export const MAX_PRODUCT_VARIANTS = 10;
export const MIN_PRODUCT_VARIANTS = 1;
