import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { z } from "zod";
import { useChangeHAQDueDate } from "api/orchestration/changeHAQDueDate";
import { FormDate } from "components/common/form-fields";
import { Loading } from "components/shared";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { TSelectedHAQTableRow } from "components/shared/types";
import useOrganizations from "hooks/api/REST/organizations/useOrganizations";
import { getHAQShortName } from "utils/helpers";
import { TISODateFormat } from "utils/types";
import { getGenericFutureDateConstraint } from "utils/validators/constraints/validationConstraints";
import styles from "./ChangeDueDate.styles";

export type TChangeDueDateForm = {
  dueDate: string;
};

export type TChangeHAQDueDateModalProps = {
  onClose: () => void;
  selectedRows: TSelectedHAQTableRow[];
  defaultDueDate: TISODateFormat | undefined;
};

const ChangeHAQDueDateModal = ({
  onClose,
  selectedRows,
  defaultDueDate,
}: TChangeHAQDueDateModalProps) => {
  const { t } = useTranslation(["HAQ", "common", "notifications"]);
  const { organizations } = useOrganizations();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const changeHAQDueDate = useChangeHAQDueDate();

  const methods = useForm<TChangeDueDateForm>({
    defaultValues: {
      dueDate: defaultDueDate,
    },
    resolver: zodResolver(
      z.object({ dueDate: getGenericFutureDateConstraint(t) }),
    ),
    mode: "all",
  });

  const { formState, handleSubmit, watch } = methods;

  const dueDate = watch("dueDate");

  const onSubmit = async (values: TChangeDueDateForm) => {
    if (values.dueDate) {
      setIsSubmitting(true);

      const HAQName = getHAQShortName(
        selectedRows[0].HAQ,
        t("HAQAcronym", { ns: "HAQ" }),
        organizations,
      );

      const tlnParams = {
        HAQName,
        ns: "notifications",
        count: selectedRows.length,
      };

      const successMessage = t(
        "workflowMessages.changeHAQDueDateSuccessMessage",
        tlnParams,
      );

      const errorMessage = t(
        "workflowMessages.changeHAQDueDateFailureMessage",
        tlnParams,
      );

      await changeHAQDueDate(
        selectedRows,
        new Date(values.dueDate),
        successMessage,
        errorMessage,
      );
    }

    onClose();
  };

  return (
    <Modal
      data-qaid="change-due-date-modal"
      open
      sx={styles.modal}
      title={t("changeDueDate.title")}
    >
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="caption" color="text.secondary">
          {t("changeDueDate.question", { count: selectedRows.length })}
        </Typography>
        <Typography>
          {selectedRows
            .map((row) =>
              getHAQShortName(
                row.HAQ,
                t("HAQAcronym", { ns: "HAQ" }),
                organizations,
              ),
            )
            .join(", ")}
        </Typography>
        <FormDate
          data-qaid="HAQ-change-due-date-field"
          disablePast
          label={t("changeDueDate.fieldLabel")}
          name="dueDate"
          sx={styles.dateField}
        />
        <ModalActionButtons>
          <Button
            data-qaid="change-due-date-cancel-button"
            key="cancel"
            variant="text"
            onClick={onClose}
            disabled={isSubmitting}
          >
            {t("button.cancel", { ns: "common" })}
          </Button>
          <Button
            color="primary"
            disabled={
              !formState.isValid ||
              dayjs(dueDate).isSame(defaultDueDate) ||
              isSubmitting
            }
            key="confirm"
            variant="contained"
            type="submit"
          >
            {isSubmitting ? <Loading /> : t("button.confirm", { ns: "common" })}
          </Button>
        </ModalActionButtons>
      </FormWrapper>
    </Modal>
  );
};

export default ChangeHAQDueDateModal;
