import { createContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router";
import { ClientError } from "graphql-request";
import Alert from "components/shared/Alert";
import useProjectData from "hooks/api/GQL/project/useProjectData";
import { IProjectData } from "hooks/api/GQL/project/useProjectData.models";
import { useParticipatingHAOrganizations } from "hooks/project";
import useProjectStatusOptions, {
  TProjectStatusOption,
} from "hooks/project/useProjectStatusOptions/useProjectStatusOptions";
import useARNTenant from "hooks/useARNTenant";
import { IOrganization } from "models/organizations.models";
import { LayoutError } from "utils/helpers";

export type TProjectContextValue<T> = {
  project: IProjectData<T> | null | undefined;
  isProjectLoading: boolean;
  creatorTenant: IOrganization | undefined;
  projectStatusOptions: TProjectStatusOption[];
  participatingHAOrganizations: IOrganization[];
  participatingHAOrganizationsExceptUserOrganization: IOrganization[];
  projectError: ClientError | null;
};

export const ProjectContext = createContext<
  TProjectContextValue<object> | undefined
>(undefined);

const ProjectProvider = () => {
  const { t } = useTranslation("projects");

  const { projectId } = useParams();

  const {
    data: project,
    isLoading: isProjectLoading,
    error: projectError,
  } = useProjectData(projectId);

  if (project === null) {
    throw new LayoutError({
      messageTranslationKey: "project.doesNotExistOrYouDoNotHaveAccess",
    });
  }

  const { tenant } = useARNTenant(project?.sourceArn ?? project?.id);

  const {
    participatingHAOrganizations,
    participatingHAOrganizationsExceptUserOrganization,
  } = useParticipatingHAOrganizations(project);

  const projectStatusOptions = useProjectStatusOptions(project);

  const value = useMemo(
    () => ({
      project,
      isProjectLoading,
      creatorTenant: tenant,
      participatingHAOrganizations,
      participatingHAOrganizationsExceptUserOrganization,
      projectStatusOptions,
      projectError,
    }),
    [
      project,
      isProjectLoading,
      tenant,
      participatingHAOrganizations,
      participatingHAOrganizationsExceptUserOrganization,
      projectStatusOptions,
      projectError,
    ],
  );

  return (
    <ProjectContext.Provider value={value}>
      {project?.active === false && (
        <Alert severity="info" square>
          {t("project.viewOnlyProjectModeAlertMessage")}
        </Alert>
      )}
      <Outlet />
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
