import { minModalMinWidth } from "components/shared/Modal/Modal.styles";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  organizationTypeModal: {
    "& .MuiDialog-paper": {
      minWidth: minModalMinWidth,
    },
  },
  formContainer: {
    position: "relative",
    pt: 1,
  },

  loadingIndicatorContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  },
};

export default styles;
