import getEnv from "env";
import { IOrganization } from "models/organizations.models";
import { TenantType } from "utils/constants/users.constants";

export const isHAUser = (organization: IOrganization | undefined) =>
  organization?.type === TenantType.HA;

export const isSponsorUser = (organization: IOrganization | undefined) =>
  organization?.type === TenantType.Sponsor;

export const isHATenant = () => getEnv().platformTenantType === TenantType.HA;

export const isSponsorTenant = () =>
  getEnv().platformTenantType === TenantType.Sponsor;

export const isExternalIdpEnabled = () =>
  getEnv().externalIdpEnabled === "true";
