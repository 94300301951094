import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { gql } from "graphql-request";
import { Variables } from "graphql-request/src/types";
import { isEmpty } from "lodash";
import useShowAlert from "hooks/useShowAlert";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { IHAQsWithContentResponse } from "./useHAQsWithContent.models";

export const query = gql`
  query Questions($projectId: String, $HAQIds: [String]) {
    questions(projectId: $projectId, HAQIds: $HAQIds) {
      id
      organization {
        name
        shortName
        country
      }
      source {
        tenantId
        tenantType
      }
      name
      createdBy
      metadata
      status
      sentStatus
      formalResponse
      shareTracking {
        submissionDate
        type
        id
        tenantId
        responseDate
        status
      }
      assetContent {
        content
      }
      children {
        ... on Asset {
          id
          organization {
            name
            shortName
            country
          }
          source {
            tenantId
            tenantType
          }
          name
          createdBy
          createdDate
          metadata
          status
          type
          shareTracking {
            submissionDate
            type
            id
            tenantId
            responseDate
            status
          }
          assetContent {
            content
          }
        }
      }
    }
  }
`;

const useHAQsWithContent = ({
  projectId,
  HAQIds,
}: {
  projectId?: string;
  HAQIds?: string[];
}) => {
  const { t } = useTranslation("HAQ");

  const { data, status, isLoading, isError, isSuccess, error } =
    useQuery<IHAQsWithContentResponse>(
      [QueryAPIKey.GetAssetsWithContent, projectId, HAQIds],
      () =>
        services.graphQL.request<IHAQsWithContentResponse, Variables>(query, {
          projectId,
          HAQIds,
        }),
      { enabled: !!projectId || !isEmpty(HAQIds) },
    );

  useShowAlert({
    status,
    failureMessage: t("notifications.getHAQsFailure"),
  });

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
  };
};

export default useHAQsWithContent;
