import { AnnotationsUIs, Comment } from "ckeditor5-premium-features";
import { TAnnotationFilterForm } from "./AnnotationFilters/AnnotationFilters.types";

// Add some internal methods we have to use to hide individual comments
type THidableComment = Comment & {
  _showComment: () => void;
  _hideComment: () => void;
  shouldHideComment: boolean;
  _model: any;
};

export const shouldCommentBeVisible = (
  filters: TAnnotationFilterForm,
  comment: THidableComment,
  currentUserId: string,
): boolean => {
  if (
    filters.showPrivateComments === false &&
    !comment._model.attributes.isPublic
  ) {
    return false;
  }

  if (
    filters.showOnlyMyComments === true &&
    comment._model.author.id !== currentUserId
  ) {
    return false;
  }

  return true;
};

export const onApplyAnnotationFilters = (
  annotationsUIs: AnnotationsUIs,
  filters: TAnnotationFilterForm,
  currentUserId: string,
): void => {
  if (annotationsUIs) {
    // There isn't a built-in way to hide some of the comments within a thread rather than the whole
    // thread, so this code delves into the implementation tinker with the internals of how comments
    // work in order to hide them. This was recommended by CKEditor support, but isn't supported by
    // the official types. Unfortunately that means we have to ignore the types to get it to work.
    const filter: any = (annotation: any) => {
      if (annotation.type === "comment") {
        const commentList =
          annotation.innerView.commentsListView.commentViews._items;

        commentList?.forEach((comment: THidableComment) => {
          shouldCommentBeVisible(filters, comment, currentUserId)
            ? comment._showComment()
            : comment._hideComment();
        });

        const filteredComments = commentList.filter(
          (comment: THidableComment) => comment.shouldHideComment,
        );

        if (filteredComments?.length > 0) {
          return filteredComments.length !== commentList.length;
        }
      }

      if (annotation.type !== "comment" && !filters.showTrackChanges) {
        return false;
      }

      return true;
    };

    annotationsUIs.activate("wideSidebar", filter);
  }
};
