import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { DocumentsTable, TDocumentsRowData } from "./AddProjectDocuments.types";

const columnHelper = createColumnHelper<TDocumentsRowData>();

const getColumns = (t: TFunction) => [
  columnHelper.accessor(DocumentsTable.Name, {
    header: t("documents.columns.name"),
    cell: ({ row }) => row.original.name,
    meta: { width: "30%" },
  }),

  columnHelper.accessor(DocumentsTable.FileType, {
    header: t("documents.columns.type"),
    meta: { width: "14%" },
  }),

  columnHelper.accessor(DocumentsTable.Type, {
    header: t("documents.columns.subtype"),
    meta: { width: "31%" },
  }),

  columnHelper.accessor(DocumentsTable.Status, {
    header: t("documents.columns.status"),
    cell: ({ row }) => row.original.status,
    meta: { width: "20%" },
  }),
];

export default getColumns;
