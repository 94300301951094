import { useTranslation } from "react-i18next";
import { matchRoutes, useLocation } from "react-router-dom";
import { TBreadcrumb } from "components/shared/Breadcrumbs";
import { APPLICATION_ROUTES } from "router/paths";
import { parsePath } from "utils/helpers";
import {
  getBreadcrumbName,
  getBreadcrumbsPaths,
  TRoute,
} from "./useBreadcrumbs.utils";

export type TPathNamesMapping = {
  [key in TDynamicRouteSegment]?: string | undefined;
};

type TDynamicRouteSegment =
  | "documentId"
  | "HAQId"
  | "GSPId"
  | "projectId"
  | "taskId"
  | "therapeuticId"
  | "userId";

type TBreadcrumbsConfig = {
  pathNamesMapping?: TPathNamesMapping;
  depth?: number;
};

const useBreadcrumbs = ({
  pathNamesMapping,
  depth,
}: TBreadcrumbsConfig): TBreadcrumb[] => {
  const location = useLocation();

  const { t } = useTranslation("common", {
    keyPrefix: "breadcrumbs",
  });

  const currentPathChunks = parsePath(location.pathname);

  const breadcrumbsPaths = getBreadcrumbsPaths(
    currentPathChunks.slice(0, depth),
  );

  return breadcrumbsPaths.reduce((breadcrumbs: TBreadcrumb[], path) => {
    const matches = matchRoutes<TRoute>(APPLICATION_ROUTES, path);

    if (matches?.length) {
      const name = getBreadcrumbName(t, matches[0].route, pathNamesMapping);

      return name
        ? breadcrumbs.concat({
            name,
            to: path,
          })
        : breadcrumbs;
    }

    return breadcrumbs;
  }, []);
};

export default useBreadcrumbs;
