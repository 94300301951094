import { useEffect, useState } from "react";

const useWindowVisibilityChange = (): { isWindowVisible: boolean } => {
  const [isWindowVisible, setIsWindowVisible] = useState<boolean>(true);

  const handleVisibilityChange = () => {
    setIsWindowVisible(!document.hidden);
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return { isWindowVisible };
};

export default useWindowVisibilityChange;
