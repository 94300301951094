import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  errorContainer: {
    height: "100%",
    backgroundColor: getPaletteColor("grey.100"),
    p: 6,
    textAlign: "center",
  },
};

export default styles;
