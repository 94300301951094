import {
  TherapeuticProductStatus,
  TPortfolioCountry,
} from "screens/Portfolio/Portfolio.types";

export enum TherapeuticTable {
  Id = "id",
  ProprietaryName = "proprietaryName",
  Country = "country",
  Strength = "strength",
  DosageForm = "dosageForm",
  RouteOfAdministration = "routeOfAdministration",
  Status = "status",
}

export type TTherapeuticRowData = {
  [TherapeuticTable.Id]: string;
  [TherapeuticTable.ProprietaryName]: string;
  [TherapeuticTable.Country]: TPortfolioCountry;
  [TherapeuticTable.Strength]: string[];
  [TherapeuticTable.DosageForm]: string;
  [TherapeuticTable.RouteOfAdministration]: string;
  [TherapeuticTable.Status]: TherapeuticProductStatus;
};
