import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useCheckRedirection, {
  NavigationType,
} from "components/common/UnsavedChangesModal/useCheckRedirection";
import Button from "components/shared/Button";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import styles from "./UnsavedChangesModal.styles";

type TUnsavedChangesModalProps = {
  onSave: () => void;
  onClose: () => void;
  discardChanges: () => void;
  shouldCheckRedirection: boolean;
  shouldShowWarningModal: boolean;
};

const UnsavedChangesModal = ({
  onSave,
  onClose,
  discardChanges,
  shouldCheckRedirection,
  shouldShowWarningModal,
}: TUnsavedChangesModalProps) => {
  const {
    alertNavigationType,
    setAlertNavigationType,
    proceed,
    hasToSetReadOnly,
  } = useCheckRedirection(shouldCheckRedirection);

  const { t } = useTranslation("documents");

  const save = useCallback(() => {
    setAlertNavigationType(null);
    onSave();
  }, [onSave, setAlertNavigationType]);

  const onCancel = useCallback(() => {
    setAlertNavigationType(null);
    onClose();
  }, [onClose, setAlertNavigationType]);

  const onDiscardChanges = useCallback(() => {
    if (
      alertNavigationType === NavigationType.InApp ||
      alertNavigationType === NavigationType.Browser
    ) {
      proceed?.();
    }

    setAlertNavigationType(null);
    discardChanges();
  }, [setAlertNavigationType, discardChanges, proceed, alertNavigationType]);

  useEffect(() => {
    if (hasToSetReadOnly && shouldCheckRedirection) {
      onDiscardChanges();
    }
  }, [hasToSetReadOnly, onDiscardChanges, shouldCheckRedirection]);

  const buttons = useMemo(
    () => [
      [
        <Button
          key="cancel"
          data-qaid="unsaved-changes-cancel-button"
          onClick={onCancel}
        >
          {t("documentComponent.unsavedChanges.cancel")}
        </Button>,
        // Hides this button in closing tab because there's nowhere to "navigate" to, and window.alert triggers the same thing
        (alertNavigationType === NavigationType.InApp ||
          shouldShowWarningModal) && (
          <Button
            variant="outlined"
            onClick={onDiscardChanges}
            key="button-leave"
            data-qaid="discard-changes-button"
          >
            {t("documentComponent.unsavedChanges.discardChanges")}
          </Button>
        ),
        <Button
          data-qaid="unsaved-changes-save-button"
          variant="contained"
          key="save"
          onClick={save}
        >
          {t("documentComponent.unsavedChanges.saveChanges")}
        </Button>,
      ],
    ],
    [
      save,
      alertNavigationType,
      onCancel,
      onDiscardChanges,
      shouldShowWarningModal,
      t,
    ],
  );

  const title = alertNavigationType
    ? t("documentComponent.unsavedChanges.titleLeavingPage")
    : t("documentComponent.unsavedChanges.title");

  return (
    <Modal
      open={Boolean(alertNavigationType) || shouldShowWarningModal}
      data-qaid="unsaved-changes-modal"
      title={title}
      sx={styles.modal}
    >
      {t("documentComponent.unsavedChanges.body")}
      <ModalActionButtons buttons={buttons} />
    </Modal>
  );
};

export default UnsavedChangesModal;
