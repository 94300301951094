import { useMutation, useQueryClient } from "react-query";
import useShowAlert, { TAlertMessage } from "hooks/useShowAlert";
import { IAssetRelationship } from "models/assets.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export type TDeleteAssetsMutationData = {
  assetId: string;
  relationshipId: string;
};

const useDeleteAssetRelationship = (messages?: TAlertMessage) => {
  const queryClient = useQueryClient();

  const deleteAssetsRelationship = useMutation(
    ({ assetId, relationshipId }: TDeleteAssetsMutationData) =>
      services.assets
        .deleteAssetRelationship(assetId, relationshipId)
        .then((res) => res.data as IAssetRelationship),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryAPIKey.GetAssetRelationships);
      },
    },
  );

  useShowAlert({
    status: deleteAssetsRelationship.status,
    ...messages,
  });

  return deleteAssetsRelationship;
};

export default useDeleteAssetRelationship;
