import { ReactNode } from "react";
import { IDocument } from "models/documents.models";
import { IShareHistoryItem } from "utils/constants/shareHistory.constants";

export enum DocumentHistoryTable {
  Id = "id",
  Recipient = "recipient",
  Date = "date",
  Status = "status",
  Actions = "actions",
}

export type TDocumentHistoryRowData = {
  [DocumentHistoryTable.Id]: string;
  [DocumentHistoryTable.Recipient]: ReactNode;
  [DocumentHistoryTable.Date]: string;
  [DocumentHistoryTable.Status]: string | ReactNode;
  [DocumentHistoryTable.Actions]: ReactNode;
};

export type TDocumentShareHistoryModalProps = {
  document: IDocument;
  onClose: () => void;
};

export type TLocalMap<T> = { [tenantId: string]: T };

export type TShareHistoryTableActions = {
  openConfirm: () => void;
  revokeShare: (item: IShareHistoryItem) => void;
};
