import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Stack, Typography } from "@mui/material";
import * as accessChecks from "components/Auth/accessChecks";
import Button from "components/shared/Button";
import DetailsCard from "components/shared/DetailsCard";
import useUser from "hooks/useUser";
import { Modal } from "../Settings/settings.constants";
import styles from "./ProfileCard.styles";

export type TProfileCardField = {
  id: string;
  label: string;
  onEdit?: () => void;
  value: string | ReactNode;
};

export type TProfileCardProps = {
  avatar: ReactNode;
  fields: TProfileCardField[];
  title: string;
};

const ProfileCard = ({ avatar, fields, title }: TProfileCardProps) => {
  const { t } = useTranslation("common");

  const user = useUser();

  const canManageUsers = accessChecks.canManageUsers({
    user,
    featureFlags: {},
  });

  return (
    <DetailsCard sx={styles.card} avatar={avatar} title={title}>
      <Stack
        direction="column"
        spacing={1}
        data-qaid="profile-card-fields-block"
      >
        {fields.map((field) => (
          <Grid
            container
            direction="row"
            key={field.id}
            sx={styles.row}
            data-qaid="profile-card-row-line"
          >
            <Grid item>
              <Typography
                sx={styles.label}
                variant="body2"
                data-qaid="profile-card-row-name"
              >
                {field.label}
              </Typography>
            </Grid>
            <Grid item display="flex" alignItems="center">
              {typeof field.value === "string" ? (
                <Typography variant="body2" data-qaid="profile-card-row-value">
                  {field.value}
                </Typography>
              ) : (
                field.value
              )}
              {(canManageUsers || field.id === Modal.Timezone) &&
              field.onEdit ? (
                <Button
                  data-qaid="edit-profile-button"
                  onClick={field.onEdit}
                  sx={styles.editButton}
                  size="large"
                >
                  {t("button.edit")}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        ))}
      </Stack>
    </DetailsCard>
  );
};

export default ProfileCard;
