export type TObjectKeys<T extends object> = Extract<keyof T, string>;

/**
A strongly-typed version of `Object.keys()`.
Doesn't work with numeric keys, so please don't use numeric enums

Stay alert and use it with caution! [See more in explanation]
This is useful since `Object.keys()` always returns an array of strings.

Short explanation:
It makes sense only in the domain of type variables (i.e. T and keyof T). Once you move to the instantiated type world,
it degenerates because an object can (and often does) have more properties at run-time than are statically known at compile time

- [Explanation](https://stackoverflow.com/questions/55012174/why-doesnt-object-keys-return-a-keyof-type-in-typescript)
- [TypeScript issues about this](https://github.com/microsoft/TypeScript/issues/45390)

@example
```
const typedItems = objectKeys({ a: 1, b: 2, c: 3 }); // => Array<'a' | 'b' | 'c'>
const untypedItems = Object.keys(items); // => Array<string>
```
*/
export const objectKeys = Object.keys as <T extends object>(
  value: T,
) => Array<TObjectKeys<T>>;
