import { useEffect } from "react";
import {
  Controller,
  FieldPath,
  FieldValues,
  get,
  useFormContext,
} from "react-hook-form";
import { Box } from "@mui/material";
import CharacterLimit from "components/shared/CharacterLimit/CharacterLimit";
import TextField, { TTextFieldProps } from "components/shared/TextField";

export type TFormTextInputProps<FormType extends FieldValues> =
  TTextFieldProps & {
    name: FieldPath<FormType>;
    unregisterOnUnmount?: boolean;
    maxLength?: number;
    fieldTransform?: (fieldValue: string) => string;
  };

const FormTextInput = <FormType extends FieldValues>({
  name,
  unregisterOnUnmount = true,
  helperText,
  maxLength,
  fieldTransform,
  ...props
}: TFormTextInputProps<FormType>) => {
  const { control, formState, unregister } = useFormContext<FormType>();

  const error = get(formState.errors, name);

  useEffect(() => {
    if (unregisterOnUnmount) {
      return () => unregister(name);
    }
  }, [unregister, name, unregisterOnUnmount]);

  const visibleHelperText = error?.message || helperText;

  const shouldShowHelperText = Boolean(visibleHelperText || maxLength);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <TextField
          {...field}
          {...props}
          value={fieldTransform ? fieldTransform(field.value) : field.value}
          id={name}
          size="small"
          error={!!error}
          helperText={
            shouldShowHelperText ? (
              <Box component="span" display="flex">
                {visibleHelperText && (
                  <Box component="span">{visibleHelperText}</Box>
                )}
                {!!maxLength && (
                  <CharacterLimit value={field.value} maxLength={maxLength} />
                )}
              </Box>
            ) : null
          }
        />
      )}
    />
  );
};

export default FormTextInput;
