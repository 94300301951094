import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { Box, Stack, Typography } from "@mui/material";
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { Breadcrumbs, Card, Loading, ReactTable } from "components/shared";
import usePortfolioData from "hooks/api/GQL/fhirData/usePortfolioData";
import useTherapeuticSummaryData from "hooks/api/GQL/fhirData/useTherapeuticSummaryData";
import useBreadcrumbs from "hooks/breadcrumbs/useBreadcrumbs";
import { Path } from "router/paths";
import { TPortfolioCountry } from "screens/Portfolio/Portfolio.types";
import { getColumns } from "./columns";
import {
  TherapeuticTable,
  TTherapeuticRowData,
} from "./TherapeuticSummary.types";
import {
  getTherapeuticProductsForRender,
  getTherapeuticSummaryPageTitle,
} from "./TherapeuticSummary.utils";
import TherapeuticSummaryHeader from "./TherapeuticSummaryHeader";
import styles from "./TherapeuticSummary.styles";

const TherapeuticSummary = () => {
  const { t } = useTranslation(["portfolio", "common", "statuses"]);

  const breadcrumbs = useBreadcrumbs({
    depth: 1,
  });

  const { therapeuticId } = useParams();
  const navigate = useNavigate();

  const { data: portfolioData } = usePortfolioData();

  const { data: therapeuticSummaryData, isLoading } =
    useTherapeuticSummaryData();

  const activeIngredientPageTitle = useMemo(
    () =>
      getTherapeuticSummaryPageTitle(
        therapeuticId,
        portfolioData?.portfolio?.report,
      ),
    [therapeuticId, portfolioData],
  );

  const columns = useMemo(() => getColumns(t), [t]);

  const rows = useMemo(
    () =>
      getTherapeuticProductsForRender(
        t,
        therapeuticSummaryData?.therapeuticSummary?.report,
      ),
    [t, therapeuticSummaryData],
  );

  const goToProduct = useCallback(
    (row: Row<TTherapeuticRowData>) => {
      navigate(
        Path.CountryProduct.replace(":therapeuticId", therapeuticId || "")
          .replace(":medicationId", row?.original?.id)
          .replace(
            ":countryId",
            (row?.getValue("country") as TPortfolioCountry)?.id,
          ),
      );
    },
    [navigate, therapeuticId],
  );

  const {
    setGlobalFilter,
    setColumnFilters,
    ...tableInstance
  }: Table<TTherapeuticRowData> = useReactTable({
    columns: columns,
    data: rows,
    enableHiding: true,
    enableColumnFilters: true,
    enableFilters: true,
    enableSorting: true,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableGlobalFilter: true,
    enableGrouping: true,
    globalFilterFn: "includesString",
    initialState: {
      pagination: {
        pageSize: 20,
      },
      columnVisibility: {
        [TherapeuticTable.Id]: false,
      },
      grouping: [TherapeuticTable.Status],
    },
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <Box sx={styles.container}>
      <Breadcrumbs
        ariaLabel={t("ariaLabels.breadcrumbs")}
        breadcrumbs={breadcrumbs}
      />
      <Stack direction="row" sx={styles.header}>
        <Typography variant="h5" component="h1">
          {activeIngredientPageTitle}
        </Typography>
      </Stack>
      <Box sx={styles.filters}>
        <TherapeuticSummaryHeader
          setGlobalFilter={setGlobalFilter}
          setAllFilters={setColumnFilters}
          therapeuticItems={rows}
        />
      </Box>
      <Card>
        {isLoading ? (
          <Loading sx={styles.loading} />
        ) : (
          <ReactTable<TTherapeuticRowData>
            sx={styles.table}
            tableInstance={tableInstance}
            isPaginated
            onRowClick={(row) => {
              // TODO: Implement action in FTE-14136
              !Boolean(row.getIsGrouped()) && goToProduct(row);
            }}
          />
        )}
      </Card>
    </Box>
  );
};

export default TherapeuticSummary;
