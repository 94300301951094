import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  contentEditorContainer: {
    position: "relative",
    outline: "solid 1px",
    outlineColor: getPaletteColor("grey.400"),
    borderRadius: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",

    "&:hover": {
      outlineColor: "unset",
    },

    ".ck-toolbar": {
      border: "none",
      borderBottomStyle: "solid",
      borderWidth: "1px",
      borderColor: getPaletteColor("grey.400"),
      backgroundColor: "unset",
    },

    "& .ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items": {
      flexWrap: "wrap",
    },
  },

  contentEditorInFocus: {
    outlineColor: getPaletteColor("primary.main"),
    outlineWidth: "2px",
    outlineStyle: "solid",

    "&:hover": {
      outlineColor: getPaletteColor("primary.main"),
    },
  },

  contentEditorError: {
    outlineColor: getPaletteColor("red.700"),
    outlineWidth: "1px",
    overflow: "unset",

    "&:hover": {
      outlineColor: getPaletteColor("red.700"),
    },
  },

  errorMessage: {
    position: "absolute",
    bottom: "-22px",
  },

  editor: {
    overflow: "auto",
    height: "100%",

    "& > div": {
      mb: 0,
    },

    ".ck-content": {
      border: "none !important",
      boxShadow: "none !important",
      height: "100%",
    },
  },
};

export default styles;
