import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  IconTypeMap,
  SxProps,
  Theme,
  Tooltip,
  TooltipProps,
} from "@mui/material";

export type TInfoTooltipProps = Omit<TooltipProps, "children"> & {
  textAriaLabel?: string;
  size?: IconTypeMap["props"]["fontSize"];
  tabIndex?: number;
  iconStyles?: SxProps<Theme>;
};

const InfoTooltip = (props: TInfoTooltipProps) => {
  const {
    textAriaLabel,
    size = "small",
    tabIndex = 0,
    iconStyles,
    ...tooltipProps
  } = props;

  return (
    <Tooltip arrow {...tooltipProps}>
      <InfoIcon
        aria-label={textAriaLabel}
        tabIndex={tabIndex}
        fontSize={size}
        color="action"
        sx={iconStyles}
      />
    </Tooltip>
  );
};

export default InfoTooltip;
