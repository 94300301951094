import { QueryClient } from "react-query";
import { type CommentsAdapter } from "@ckeditor/ckeditor5-comments/src/comments/commentsrepository";
import { Context, Editor } from "ckeditor5";
import { TFunction } from "i18next";
import { TAlertContextValue } from "context/AlertContext";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

const getAddCommentThread = (
  queryClient: QueryClient,
  showAlert: TAlertContextValue["showAlert"],
  t: TFunction,
  // We will need this later to implement handling of failed API requests
  _editorOrContext: Editor | Context,
): CommentsAdapter["addCommentThread"] => {
  return async (data) => {
    try {
      const { channelId, threadId, comments } = data;

      const savedComments = await Promise.all(
        comments.map(async (comment) => {
          const { data } = await services.documents.comments.createComment(
            String(channelId),
            threadId,
            {
              id: comment.commentId!,
              attributes: comment.attributes,
              content: comment.content,
            },
          );

          return {
            commentId: comment.commentId!,
            createdAt: new Date(data.createdDate),
          };
        }),
      );

      queryClient.invalidateQueries([QueryAPIKey.GetCommentThread, threadId]);

      return {
        threadId,
        comments: savedComments,
      };
    } catch (error) {
      showAlert({
        message: String(t("createCommentFailure")),
        severity: "error",
      });
      throw error;
    }
  };
};

export default getAddCommentThread;
