import { getBorderStyle } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  defaultSelect: {
    minWidth: 200,
    ".MuiSelect-select.MuiSelect-outlined.MuiInputBase-inputSizeSmall": {
      padding: "8px 12px",
      fontSize: "16px",
    },

    ".MuiSelect-outlined": {
      padding: "16px 12px",
      lineHeight: (theme) => `${theme.typography.inputText?.lineHeight}rem`,
    },

    ".MuiSvgIcon-root": {
      paddingTop: "2px",
    },

    ".MuiSelect-select.Mui-disabled ~ .MuiOutlinedInput-notchedOutline": {
      border: getBorderStyle("1px", "dashed", "custom.inputBorder"),
    },

    "& .MuiSelect-nativeInput": {
      lineHeight: (theme) => `${theme.typography.inputText?.lineHeight}rem`,
    },

    "& .MuiInputLabel-shrink.MuiInputLabel-outlined": {
      lineHeight: (theme) => theme.typography.inputLabel?.lineHeight,
    },

    "& .MuiInputLabel-root": {
      paddingRight: (theme) => theme.spacing(2),
    },
  },

  unselect: { fontStyle: "italic" },
};

export default styles;
