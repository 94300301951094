import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import isEmpty from "lodash/isEmpty";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import useDeleteAssetRelationship from "hooks/api/REST/assets/useDeleteAssetRelationship";
import { IAssetRelationship } from "models/assets.models";
import { IDocument } from "models/documents.models";
import { AttachmentsList } from "screens/Project/screens/HAQ/components/Attachments";

export type TEditProjectDocuments = {
  isOpen: boolean;
  onClose: () => void;
  HAQResponseAttachments: IAssetRelationship[] | undefined;
  attachedDocuments: IDocument[];
};

const EditProjectDocuments = ({
  isOpen,
  onClose,
  HAQResponseAttachments,
  attachedDocuments,
}: TEditProjectDocuments) => {
  const { t } = useTranslation(["documents", "HAQ", "common"]);
  const { projectId } = useParams();
  const [documents, setDocuments] = useState<IDocument[]>();
  const [documentsToRemove, setDocumentsToRemove] = useState<IDocument[]>([]);
  const methods = useForm();

  const { handleSubmit } = methods;

  const { mutateAsync: deleteAssetsRelationship } = useDeleteAssetRelationship({
    failureMessage: t("notifications.errorUpdated", {
      ns: "HAQ",
    }),
    successMessage: t("notifications.successfullyUpdated", {
      ns: "HAQ",
    }),
  });

  useEffect(() => {
    setDocuments(attachedDocuments);
    setDocumentsToRemove([]);
  }, [attachedDocuments, isOpen]);

  const onConfirmChanges = async () => {
    const relationshipsForRemoving = documentsToRemove.map((document) => {
      return HAQResponseAttachments?.find(
        (relationship) => relationship.otherAssetArn === document.id,
      );
    });

    try {
      await Promise.allSettled(
        relationshipsForRemoving.map((relationship) =>
          deleteAssetsRelationship({
            assetId: relationship?.assetId || "",
            relationshipId: relationship?.id || "",
          }),
        ),
      );
      onClose();
    } catch {
      onClose();
    }
  };

  const onRemoveClick = (document: IDocument) => {
    setDocuments(documents?.filter((doc) => doc.id !== document.id));
    setDocumentsToRemove([...documentsToRemove, document]);
  };

  const cancelButton = (
    <Button
      key="cancel"
      data-qaid="edit-project-documents-cancel-button"
      variant="text"
      onClick={onClose}
    >
      {t("button.cancel", { ns: "common" })}
    </Button>
  );

  const confirmButton = (
    <Button
      key="done"
      color="primary"
      data-qaid="edit-project-documents-save-button"
      variant="contained"
      disabled={isEmpty(documentsToRemove)}
      type="submit"
    >
      {t("button.save", { ns: "common" })}
    </Button>
  );

  return (
    <Modal
      data-qaid="edit-project-documents-modal"
      open={isOpen}
      title={t("viewPage.component.attachments.editProjectDocuments", {
        ns: "HAQ",
      })}
    >
      <FormWrapper methods={methods} onSubmit={handleSubmit(onConfirmChanges)}>
        <AttachmentsList
          attachedDocuments={documents}
          projectId={projectId}
          isEditMode
          onRemoveClick={onRemoveClick}
        />
        <ModalActionButtons buttons={[cancelButton, confirmButton]} />
      </FormWrapper>
    </Modal>
  );
};

export default EditProjectDocuments;
