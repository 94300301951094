import { TStyles } from "utils/Styles";

const styles: TStyles = {
  radioCell: {
    padding: 0,
  },

  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 200,
  },
};

export default styles;
