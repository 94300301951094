import { IOrganization } from "models/organizations.models";
import { TPossibleAsset } from "utils/types";
import getIsAssetOwnedByUserOrganization from "../getIsAssetOwnedByUserOrganization";
import getIsAssetTenantTypeSameAsCurrentUser from "../getIsAssetTenantTypeSameAsCurrentUser";

const getIsEligibleToManipulateAsset = (
  asset: TPossibleAsset,
  organization: IOrganization | undefined,
) =>
  getIsAssetTenantTypeSameAsCurrentUser(asset, organization) &&
  getIsAssetOwnedByUserOrganization(asset, organization);

export default getIsEligibleToManipulateAsset;
