import { TSingleSelectTypeaheadOption } from "components/shared/SingleSelectTypeahead";
import { TProductVariantForm } from "../ProductVariantFormFields/ProductVariantFormFields.types";

export enum ProductEntityFieldName {
  ProductName = "name",
  NonproprietaryName = "nonproprietaryName",
  LinkedSubstances = "linkedSubstances",
  LinkedOrganizations = "linkedOrganizations",
  LinkedProductFamilies = "linkedProductFamilies",
  Status = "status",
  ProductVariants = "productVariants",
}

export type TProductEntityForm = {
  [ProductEntityFieldName.ProductName]: string;
  [ProductEntityFieldName.NonproprietaryName]: string;
  [ProductEntityFieldName.LinkedSubstances]: TSingleSelectTypeaheadOption[];
  [ProductEntityFieldName.LinkedOrganizations]: string[];
  [ProductEntityFieldName.LinkedProductFamilies]: TSingleSelectTypeaheadOption[];
  [ProductEntityFieldName.Status]: string;
  [ProductEntityFieldName.ProductVariants]?: TProductVariantForm[];
};
