import { minModalMinWidth } from "components/shared/Modal/Modal.styles";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  modal: {
    ".MuiDialog-paper": { minWidth: minModalMinWidth },
  },

  dateField: {
    mt: 3,
  },
};

export default styles;
