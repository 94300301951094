import { intersection } from "lodash";
import { IHAQWithContent } from "hooks/api/GQL/haq/useHAQsWithContent.models";
import { IHAQ } from "models/HAQ.models";
import { IOrganization } from "models/organizations.models";
import { HAQStatus } from "utils/constants/HAQ.constants";
import { getIsEligibleToManipulateAsset } from "utils/helpers/asset";
import { getIsAssetCopied } from "utils/helpers/asset/getIsAssetCopied";
import { includeInArray } from "utils/helpers/includeInArray";
import { isHAUser, isSponsorUser } from "utils/user/organization";

export type TManualHAQStatusOptions =
  | HAQStatus.Draft
  | HAQStatus.Complete
  | HAQStatus.Final;

export type THAQStatusChangeOptions = {
  HAQ: IHAQ | IHAQWithContent | undefined;
  HAQResponse: IHAQ | IHAQWithContent | undefined;
  organization: IOrganization | undefined;
  isProjectManager: boolean;
  hasHAQContent: boolean;
  hasHAQResponseContent: boolean;
  isHAQCopied: boolean;
  isHAQRCopied: boolean;
};

const getHAQStatusChangeOptions = ({
  HAQ,
  HAQResponse,
  organization,
  isProjectManager,
  hasHAQContent,
  hasHAQResponseContent,
  isHAQCopied,
  isHAQRCopied,
}: THAQStatusChangeOptions): TManualHAQStatusOptions[] => {
  const HAQAssetStatus = HAQ?.status as HAQStatus;
  const HAQResponseStatus = HAQResponse?.status as HAQStatus;

  const HAQStatusTransitionMappingForHAQ = getHAQStatusTransitionMappingForHAQ(
    HAQAssetStatus,
    isProjectManager,
    hasHAQContent,
    isHAQCopied,
  );

  const HAQStatusTransitionMappingForHAQR =
    getHAQStatusTransitionMappingForHAQR(
      HAQResponseStatus,
      isProjectManager,
      hasHAQResponseContent,
      isHAQRCopied,
    );

  if (isHAUser(organization)) {
    return HAQStatusTransitionMappingForHAQ;
  }

  if (!Boolean(HAQ?.metadata.imported)) {
    return HAQStatusTransitionMappingForHAQR;
  }

  return intersection(
    HAQStatusTransitionMappingForHAQ,
    HAQStatusTransitionMappingForHAQR,
  );
};

export const getCanSetToComplete = (
  status: HAQStatus | undefined,
  isHAQRSent: boolean,
  isProjectManager: boolean,
  organization: IOrganization | undefined,
  isHAQCompleted: boolean,
) =>
  status === HAQStatus.Final &&
  isHAUser(organization) &&
  isProjectManager &&
  isHAQRSent &&
  !isHAQCompleted;

export type TActionAllowable<TReason extends string> =
  | { isAllowed: true }
  | { isAllowed: false; reason: TReason };

export const isAllowed = <
  TReason extends string,
>(): TActionAllowable<TReason> => ({ isAllowed: true });

export const isDenied = <TReason extends string>(
  reason: TReason,
): TActionAllowable<TReason> => ({ isAllowed: false, reason });

export enum StatusChangeDeniedReason {
  NotImplemented = "NOT_IMPLEMENTED",
  NoPermission = "NO_PERMISSION",
  AlreadyCopied = "ALREADY_COPIED",
  NoContent = "NO_CONTENT",
  AlreadyInState = "ALREADY_IN_STATE",
}

type THAQStatusChangeRules = {
  [Key in HAQStatus]?: {
    [Key in HAQStatus]?: TActionAllowable<StatusChangeDeniedReason>;
  };
};

export const getCanHAQTransitionToStatus = (
  HAQ: IHAQWithContent,
  targetStatus: HAQStatus,
  organization: IOrganization | undefined,
  isProjectManager: boolean,
): TActionAllowable<StatusChangeDeniedReason> => {
  if (!HAQ.status) {
    return {
      isAllowed: false,
      reason: StatusChangeDeniedReason.NotImplemented,
    };
  }

  if (!getIsEligibleToManipulateAsset(HAQ, organization)) {
    return { isAllowed: false, reason: StatusChangeDeniedReason.NoPermission };
  }

  const rules: THAQStatusChangeRules = {
    [HAQStatus.Draft]: {
      [HAQStatus.Final]: getIsAssetCopied(HAQ.shareTracking)
        ? { isAllowed: false, reason: StatusChangeDeniedReason.AlreadyCopied }
        : !HAQ.assetContent?.content
        ? { isAllowed: false, reason: StatusChangeDeniedReason.NoContent }
        : { isAllowed: true },
      [HAQStatus.Draft]: {
        isAllowed: false,
        reason: StatusChangeDeniedReason.AlreadyInState,
      },
    },
    [HAQStatus.Final]: {
      [HAQStatus.Draft]: !isProjectManager
        ? { isAllowed: false, reason: StatusChangeDeniedReason.NoPermission }
        : getIsAssetCopied(HAQ.shareTracking)
        ? { isAllowed: false, reason: StatusChangeDeniedReason.AlreadyCopied }
        : { isAllowed: true },
      [HAQStatus.Final]: getIsAssetCopied(HAQ.shareTracking)
        ? { isAllowed: false, reason: StatusChangeDeniedReason.AlreadyCopied }
        : { isAllowed: false, reason: StatusChangeDeniedReason.AlreadyInState },
    },
  };

  return (
    rules[HAQ.status as HAQStatus]?.[targetStatus] || {
      isAllowed: false,
      reason: StatusChangeDeniedReason.NotImplemented,
    }
  );
};

export const getHAQStatusTransitionMappingForHAQ = (
  HAQAssetStatus: HAQStatus | undefined,
  isProjectManager: boolean,
  hasHAQContent: boolean,
  isHAQCopied: boolean,
): TManualHAQStatusOptions[] => {
  switch (HAQAssetStatus) {
    case HAQStatus.Draft:
      return includeInArray(HAQStatus.Final, hasHAQContent && !isHAQCopied);
    case HAQStatus.Reviewed:
      return [HAQStatus.Draft, HAQStatus.Final];
    case HAQStatus.Final:
      return includeInArray(HAQStatus.Draft, isProjectManager && !isHAQCopied);
    default:
      return [];
  }
};

export const getCanHAQRTransitionToStatus = (
  HAQR: IHAQWithContent | undefined,
  targetStatus: HAQStatus,
  organization: IOrganization | undefined,
  isProjectManager: boolean,
): TActionAllowable<StatusChangeDeniedReason> => {
  if (!HAQR || !HAQR.status) {
    return {
      isAllowed: false,
      reason: StatusChangeDeniedReason.NotImplemented,
    };
  }

  if (
    !getIsEligibleToManipulateAsset(HAQR, organization) ||
    !isSponsorUser(organization)
  ) {
    return { isAllowed: false, reason: StatusChangeDeniedReason.NoPermission };
  }

  const rules: THAQStatusChangeRules = {
    [HAQStatus.Draft]: {
      [HAQStatus.Final]: getIsAssetCopied(HAQR.shareTracking)
        ? { isAllowed: false, reason: StatusChangeDeniedReason.AlreadyCopied }
        : !HAQR.assetContent?.content
        ? { isAllowed: false, reason: StatusChangeDeniedReason.NoContent }
        : { isAllowed: true },
      [HAQStatus.Draft]: !HAQR.assetContent?.content
        ? { isAllowed: false, reason: StatusChangeDeniedReason.NoContent }
        : { isAllowed: false, reason: StatusChangeDeniedReason.AlreadyInState },
    },
    [HAQStatus.Final]: {
      [HAQStatus.Draft]: !isProjectManager
        ? { isAllowed: false, reason: StatusChangeDeniedReason.NoPermission }
        : getIsAssetCopied(HAQR.shareTracking)
        ? { isAllowed: false, reason: StatusChangeDeniedReason.AlreadyCopied }
        : { isAllowed: true },
      [HAQStatus.Final]: getIsAssetCopied(HAQR.shareTracking)
        ? { isAllowed: false, reason: StatusChangeDeniedReason.AlreadyCopied }
        : { isAllowed: false, reason: StatusChangeDeniedReason.AlreadyInState },
    },
  };

  return (
    rules[HAQR.status as HAQStatus]?.[targetStatus] || {
      isAllowed: false,
      reason: StatusChangeDeniedReason.NotImplemented,
    }
  );
};

export const getHAQStatusTransitionMappingForHAQR = (
  HAQResponseStatus: HAQStatus | undefined,
  isProjectManager: boolean,
  hasHAQResponseContent: boolean,
  isHAQCopied: boolean,
): TManualHAQStatusOptions[] => {
  if (isHAQCopied) {
    return [];
  }

  switch (HAQResponseStatus) {
    case HAQStatus.Draft:
      return includeInArray(
        HAQStatus.Final,
        hasHAQResponseContent && !isHAQCopied,
      );
    case HAQStatus.Reviewed:
      return [HAQStatus.Draft];
    case HAQStatus.Final:
      return includeInArray(
        HAQStatus.Draft,
        isProjectManager && hasHAQResponseContent && !isHAQCopied,
      );
    default:
      return [];
  }
};

export default getHAQStatusChangeOptions;
