import { TFunction } from "i18next";
import { DocumentSubtype } from "utils/constants/doc.constants";
import { US_DATE_FORMAT } from "utils/constants/format.constants";
import {
  IShareHistoryItem,
  ShareHistoryItemStatus,
  ShareHistoryItemType,
} from "utils/constants/shareHistory.constants";
import { formatDate } from "utils/helpers";
import { Recipient } from "./components/Recipient";
import { RevokeButton } from "./components/RevokeButton";
import type {
  TDocumentHistoryRowData,
  TLocalMap,
} from "./DocumentShareHistory.types";

export const getShareHistoryForRender = (
  data: IShareHistoryItem[],
  t: TFunction,
  revokeMap?: TLocalMap<ShareHistoryItemType>,
): TDocumentHistoryRowData[] => {
  return !!data
    ? data.map((item) => {
        return {
          id: item.id,
          recipient: <Recipient tenantId={item.tenantId} t={t} />,
          date: formatDate(item.submissionDate, US_DATE_FORMAT),
          status: `${t(`documents.shareHistory.items.status.${item?.status}`)}${
            item?.responseDate
              ? ` ${t("miscellaneous.on", {
                  ns: "common",
                })} ${formatDate(item?.responseDate, US_DATE_FORMAT)}`
              : ""
          }`,
          actions: (
            <RevokeButton
              item={item}
              shouldShow={
                item.documentSubtype !== DocumentSubtype.GSP &&
                item.status === ShareHistoryItemStatus.Acknowledged &&
                revokeMap?.[item.tenantId] !== ShareHistoryItemType.Revoke
              }
              t={t}
            />
          ),
        };
      })
    : [];
};

export const getRows = (
  shareHistory: { content: IShareHistoryItem[] } | undefined,
  documentSubtype: DocumentSubtype,
  t: TFunction,
) => {
  if (!shareHistory) {
    return {
      submitted: [],
      shared: [],
    };
  }

  const submittedItems = shareHistory.content
    .filter((item) => item.type === ShareHistoryItemType.Submission)
    .map((item) => ({ ...item, documentSubtype }));

  const sharedItems = shareHistory.content.filter(
    (item) =>
      item.type === ShareHistoryItemType.Share ||
      item.type === ShareHistoryItemType.Revoke,
  );

  const revokeMap: TLocalMap<ShareHistoryItemType> = Object.fromEntries(
    shareHistory.content.map((item) => [item.tenantId, item.type]),
  );

  return {
    submitted: getShareHistoryForRender(submittedItems, t),
    shared: getShareHistoryForRender(sharedItems, t, revokeMap),
  };
};
