import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { isEmpty } from "lodash";
import * as accessChecks from "components/Auth/accessChecks";
import { Card, ReactTable, TableFilter } from "components/shared";
import { Button } from "components/shared";
import NoDataFallback from "components/shared/NoDataFallback";
import { useFeatureFlags } from "context/FeatureFlagsContext";
import useReactTableFilters from "hooks/filters/useReactTableFilters";
import useUser from "hooks/useUser";
import EditProductFamilyModal from "../EditProductFamilyModal/EditProductFamilyModal";
import { TProductFamily } from "../ProductFamily.types";
import { getColumns } from "./columns";
import {
  ProductFamilyTable as ProductFamilyTableColumns,
  TProductFamilyRowData,
} from "./ProductFamilyTable.types";
import { getProductFamilyForRender } from "./ProductFamilyTable.utils";
import { useProductFamilyFilters } from "./useProductFamilyFilters";
import { PlusIcon } from "assets/icons";
import styles from "./ProductFamilyTable.styles";

type TProductFamilyTableProps = {
  onClickCreateProductFamily: () => void;
  productFamilies: TProductFamily[] | undefined;
};

const ProductFamilyTable = ({
  onClickCreateProductFamily,
  productFamilies,
}: TProductFamilyTableProps) => {
  const { t } = useTranslation(["administration", "projects", "common"]);

  const [selectedProductFamily, setSelectedProductFamily] =
    useState<TProductFamily | null>(null);

  const user = useUser();

  const featureFlags = useFeatureFlags();

  const canManageEntities = useMemo(
    () => accessChecks.canManageEntities({ user, featureFlags }),
    [user, featureFlags],
  );

  const noResults = isEmpty(productFamilies);

  const columnsData = useMemo(() => getColumns(t), [t]);

  const onEditProductFamily = useCallback(
    (selectedProductFamily: TProductFamily) => {
      setSelectedProductFamily(selectedProductFamily);
    },
    [],
  );

  const rowData = useMemo(
    () =>
      getProductFamilyForRender(
        productFamilies,
        t,
        onEditProductFamily,
        canManageEntities,
      ),
    [productFamilies, t, onEditProductFamily, canManageEntities],
  );

  const handleCloseEditProductFamilyModal = () => {
    setSelectedProductFamily(null);
  };

  const {
    setGlobalFilter,
    setColumnFilters,
    ...tableInstance
  }: Table<TProductFamilyRowData> = useReactTable({
    columns: columnsData,
    data: rowData,
    enableColumnFilters: true,
    enableFilters: true,
    enableSorting: true,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableGlobalFilter: true,
    globalFilterFn: "includesString",
    initialState: {
      columnVisibility: {
        [ProductFamilyTableColumns.Id]: false,
      },
      pagination: {
        pageSize: 20,
      },
    },
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const { filterParams, keywordParam, onKeywordChange, onFiltersChange } =
    useReactTableFilters(setGlobalFilter, setColumnFilters);

  const productFamilyFilters = useProductFamilyFilters({ productFamilies });

  return (
    <Stack direction="column" spacing={2}>
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <TableFilter
          filters={productFamilyFilters}
          selectedFilters={filterParams}
          keywordFilterValue={keywordParam}
          onFiltersChange={onFiltersChange}
          onKeywordChange={onKeywordChange}
          filterByKeywordLabel={t("keywordFilter.filterByKeyword", {
            ns: "common",
          })}
          showButtonLabel={t("filter.showFilterButton", { ns: "common" })}
          hideButtonLabel={t("filter.hideFilterButton", { ns: "common" })}
          clearAllFiltersLabel={t("filter.clearAllFiltersLabel", {
            ns: "common",
          })}
          errorAdornmentAriaLabel={t("ariaLabels.textFieldError", {
            ns: "common",
          })}
        />

        {canManageEntities && (
          <Button
            startIcon={<PlusIcon />}
            variant="contained"
            onClick={onClickCreateProductFamily} // TODO create ProductFamily in FTE-23915
            aria-label={t("productFamily.addNewProductFamily")}
            sx={{ ml: 2 }}
          >
            {t("productFamily.addNewProductFamily")}
          </Button>
        )}
      </Stack>
      {noResults ? (
        <NoDataFallback
          sx={{ mt: 2 }}
          message={t("productFamily.noProductFamilyFound")}
        />
      ) : (
        <Card>
          <ReactTable<TProductFamilyRowData>
            sx={styles.table}
            tableInstance={tableInstance}
            isPaginated
          />
        </Card>
      )}
      {selectedProductFamily && (
        <EditProductFamilyModal
          onClose={handleCloseEditProductFamilyModal}
          selectedProductFamily={selectedProductFamily}
        />
      )}
    </Stack>
  );
};

export default ProductFamilyTable;
