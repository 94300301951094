import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import useAssets from "hooks/api/GQL/assets/useAssets";
import { IDossierDifferencesMetadata } from "models/assets.models";
import { TGSPComponent } from "models/gsp.models";
import { AssetType } from "utils/constants/assets.constants";
import { DocumentViewMode } from "utils/constants/doc.constants";
import { DossierDifferenceModal } from "./DossierDifferenceModal";
import DossierDifferenceModalProvider from "./DossierDifferenceModal/context/DossierDifferenceModalContext";
import GSPFormItem from "./GSPFormItem";
import {
  getDossierDifferencesModules,
  getDossierDifferencesModulesForHA,
  TDossierDifferencesModule,
} from "./GSPItems.utils";
import GSPSummaryItem from "./GSPSummaryItem";
import GSPViewItem from "./GSPViewItem";
import styles from "./GSPItems.styles";

type TGSPItemsProps = {
  viewMode: DocumentViewMode;
  components: TGSPComponent[];
};

type TDossierDifferencesModulesMap = {
  [key: string]: TDossierDifferencesModule[];
};

const GSPItems = ({ viewMode, components }: TGSPItemsProps) => {
  const { GSPId } = useParams<{ id: string; GSPId: string }>();

  const { assetsData: dossierDifferences } =
    useAssets<IDossierDifferencesMetadata>(true, {
      assetIds: GSPId ? [GSPId] : [],
      assetTypes: [AssetType.DossierDifference],
    });

  const dossierDifferencesModules = useMemo(
    () => getDossierDifferencesModules(dossierDifferences?.assets, components),
    [dossierDifferences?.assets, components],
  );

  const dossierDifferencesModulesMap = useMemo(
    () =>
      components.reduce(
        (modules: TDossierDifferencesModulesMap, currentComponent) => {
          const { tenantId } = currentComponent.healthAuthority;

          if (tenantId) {
            modules[tenantId] = getDossierDifferencesModulesForHA(
              dossierDifferencesModules,
              tenantId,
            );
          }

          return modules;
        },
        {},
      ),
    [components, dossierDifferencesModules],
  );

  const getDossierDifferencesModulesByTenantId = (tenantId: string) =>
    tenantId && dossierDifferencesModulesMap[tenantId]
      ? dossierDifferencesModulesMap[tenantId]
      : [];

  return (
    <DossierDifferenceModalProvider
      components={components}
      dossierDifferencesModules={dossierDifferencesModules}
    >
      <Stack sx={styles.documentContainer} spacing={2}>
        {viewMode === DocumentViewMode.Viewing &&
          components.map((component) => (
            <GSPViewItem
              key={component.id}
              component={component}
              dossierDifferencesModules={getDossierDifferencesModulesByTenantId(
                component.healthAuthority.tenantId,
              )}
            />
          ))}

        {viewMode === DocumentViewMode.Editing &&
          components.map((component) => {
            return (
              <GSPFormItem
                key={component.id}
                component={component}
                dossierDifferencesModules={getDossierDifferencesModulesByTenantId(
                  component.healthAuthority.tenantId,
                )}
              />
            );
          })}
        <GSPSummaryItem
          isEditMode={viewMode === DocumentViewMode.Editing}
          dossierDifferencesModules={dossierDifferencesModules}
        />
        <DossierDifferenceModal />
      </Stack>
    </DossierDifferenceModalProvider>
  );
};

export default GSPItems;
