import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    pt: 2,
  },
  filterChip: {
    fontSize: 13,
    lineHeight: "18px",

    "& .MuiChip-label": {
      px: "10px",
    },

    "& .filterName": {
      color: "text.secondary",
    },
  },
};

export default styles;
