import { Components, Theme } from "@mui/material/styles";

const components: Components<Theme> = {
  MuiCssBaseline: {
    // global style overrides
    styleOverrides: (theme) => ({
      a: {
        color: theme.palette.primary.main,
      },
    }),
  },

  MuiAlertTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.alertTitle,
      }),
    },
  },

  MuiAutocomplete: {
    styleOverrides: {
      endAdornment: {
        marginTop: -2,
      },

      popupIndicator: {
        "&:hover": {
          backgroundColor: "unset",
        },

        ".MuiTouchRipple-root": {
          display: "none",
        },
      },
    },
  },

  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },

    styleOverrides: {
      root: {
        textTransform: "none",
        minWidth: "unset",
      },

      sizeLarge: ({ theme }) => ({
        ...theme.typography.buttonLarge,
      }),

      sizeMedium: ({ theme }) => ({
        ...theme.typography.button,
      }),

      sizeSmall: ({ theme }) => ({
        ...theme.typography.buttonSmall,
      }),
    },
  },

  MuiIconButton: {
    styleOverrides: {
      sizeSmall: {
        fontSize: "1.125rem",
      },

      sizeMedium: {
        fontSize: "1.5rem",
      },

      sizeLarge: {
        fontSize: "1.75rem",
      },
    },
  },

  MuiSvgIcon: {
    styleOverrides: {
      fontSizeSmall: {
        fontSize: "1.25rem",
      },

      fontSizeMedium: {
        fontSize: "1.5rem",
      },

      fontSizeLarge: {
        fontSize: "2.1875rem",
      },
    },
  },

  MuiCardContent: {
    styleOverrides: {
      root: {
        paddingTop: 8,
        paddingRight: 24,
        paddingLeft: 24,

        "&:last-child": {
          paddingBottom: 16,
        },
      },
    },
  },

  MuiCardHeader: {
    styleOverrides: {
      root: {
        paddingBottom: 8,
        paddingLeft: 24,
        paddingRight: 24,
      },

      title: ({ theme }) => ({
        ...theme.typography.h6,
        padding: 0,
      }),

      subheader: ({ theme }) => ({
        ...theme.typography.body2,
        padding: 0,
      }),
    },
  },

  MuiCheckbox: {
    styleOverrides: {
      root: {
        svg: {
          height: 24,
          width: 24,

          "&.MuiSvgIcon-fontSizeSmall": {
            height: 20,
            width: 20,
          },
        },
      },
    },
  },

  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: theme.palette.blueGrey?.[50],
      }),
    },
  },

  MuiDialogContent: {
    styleOverrides: {
      root: {
        paddingBottom: 24,
        paddingLeft: 32,
        paddingRight: 32,
      },
    },
  },

  MuiDialogTitle: {
    styleOverrides: {
      root: {
        paddingTop: 24,
        paddingBottom: 16,
        paddingLeft: 32,
        paddingRight: 32,
      },
    },
  },

  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: "0.875em",
        lineHeight: 1.5,
      },
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: 14,
        lineHeight: 1.5,
      },
    },
  },

  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        fontSize: 14,
        lineHeight: 1.5,
      },
    },
  },

  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontWeight: 500,
      },

      secondary: {
        fontSize: 13,
      },
    },
  },

  MuiTabs: {
    styleOverrides: {
      indicator: {
        transition: "none", // disable animated tab indicator transition
      },
    },
  },

  MuiTablePagination: {
    styleOverrides: {
      selectLabel: ({ theme }) => ({
        fontSize: 12,
        color: theme.palette.text.secondary,
        letterSpacing: 0.4,
      }),

      selectRoot: {
        fontSize: 12,
        letterSpacing: 0.4,

        "& .MuiSelect-select": {
          paddingTop: 5,
        },

        "& .MuiSvgIcon-root": {
          fontSize: "1.5rem",
        },
      },

      menuItem: {
        fontSize: 12,
      },

      displayedRows: {
        fontSize: 12,
        letterSpacing: 0.4,
      },

      actions: ({ theme }) => ({
        "& .MuiSvgIcon-root": {
          fontSize: "1.5rem",
        },

        "& .MuiButtonBase-root:not(.Mui-disabled)": {
          color: theme.palette.action.active,
        },
      }),
    },
  },

  MuiAvatar: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.blueGrey?.[50],
      }),
    },
  },
};

export default components;
