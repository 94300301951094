export enum ProductVariantFieldName {
  ProductType = "type",
  RouteOfAdministration = "routeOfAdministration",
  ManufacturedDoseForm = "manufacturedDoseForm",
  StrengthValue = "strengthValue",
  StrengthType = "strengthType",
  StrengthVolumeValue = "strengthVolumeValue",
  StrengthVolumeType = "strengthVolumeType",
  Status = "status",
}

export type TProductVariantForm = {
  [ProductVariantFieldName.ProductType]: string;
  [ProductVariantFieldName.RouteOfAdministration]: string;
  [ProductVariantFieldName.ManufacturedDoseForm]: string;
  [ProductVariantFieldName.StrengthValue]: string;
  [ProductVariantFieldName.StrengthType]: string;
  [ProductVariantFieldName.StrengthVolumeValue]: string;
  [ProductVariantFieldName.StrengthVolumeType]: string;
  [ProductVariantFieldName.Status]: string;
};
