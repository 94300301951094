import { TStyles } from "utils/Styles";

const styles: TStyles = {
  addDocumentsButton: {
    alignSelf: "start",
    margin: "-8px 0 10px",
  },
};

export default styles;
