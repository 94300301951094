import { useTranslation } from "react-i18next";
import { QueryObserverOptions, useQuery } from "react-query";
import { IClientConfig } from "api/types";
import useShowAlert from "hooks/useShowAlert";
import { IDocumentResponseData } from "models/documents.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export default function useGetDocument(
  id: string,
  config?: IClientConfig,
  options?: QueryObserverOptions,
) {
  const { t } = useTranslation("projects", {
    keyPrefix: "notifications",
  });

  const { data, status, error, isLoading, isError, isSuccess } = useQuery<
    IDocumentResponseData,
    Error
  >(
    [QueryAPIKey.GetDocument, id],
    () => services.documents.getDocument(id, config).then((res) => res.data),
    {
      enabled: !!id && (options?.enabled ?? true),
    },
  );

  useShowAlert({
    status,
    failureMessage: t("getProjectDocumentFailure"),
  });

  return {
    document: data,
    isLoading,
    isError,
    isSuccess,
    error,
  };
}
