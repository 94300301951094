import { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import { LayoutError } from "utils/helpers";
import ErrorBoundary from "../ErrorBoundary";
import { MainLayoutFallback } from "../ErrorBoundaryFallbacks";

class MainLayoutErrorBoundary extends ErrorBoundary {
  componentDidCatch(error: Error | LayoutError | null) {
    // Re-throw all unhandled errors, so that AppInsightsErrorBoundary can catch them
    if (!(error instanceof LayoutError)) {
      throw error;
    }
  }
}

export default ({ children }: PropsWithChildren) => {
  const location = useLocation();

  return (
    <MainLayoutErrorBoundary
      fallbackRenderer={(props) => <MainLayoutFallback {...props} />}
      locationKey={location.key}
    >
      {children}
    </MainLayoutErrorBoundary>
  );
};
