import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    padding: "10px",
    borderRadius: "4px",
    margin: "10px",

    "& .MuiCardHeader-content": {
      ".MuiCardHeader-title": {
        display: "inline-block",
      },
      ".MuiTypography-body1": {
        display: "inline-block",
        marginLeft: "20px",
      },
    },
  },
};

export default styles;
