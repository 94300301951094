import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import parse from "html-react-parser";
import SkeletonWrapper from "components/common/SkeletonWrapper";
import { IAssetContent } from "models/assets.models";

export interface IHAQDetailsContentProps {
  HAQContent: IAssetContent | undefined;
  HAQResponseContent: IAssetContent | undefined;
  isHAQResponseContentLoading: boolean;
  isHAQContentLoading: boolean;
}

const Content = ({
  HAQContent,
  HAQResponseContent,
  isHAQResponseContentLoading,
  isHAQContentLoading,
}: IHAQDetailsContentProps) => {
  const { t } = useTranslation(["HAQ", "common"]);

  return (
    <Stack rowGap={2}>
      <Stack rowGap={1} data-qaid="haq-drawer-haq-question-section">
        <Typography variant="subtitle2">
          {t("detailsPreview.content.question")}
        </Typography>
        <SkeletonWrapper isLoading={isHAQContentLoading}>
          <Typography variant="body2">
            {HAQContent?.content
              ? parse(HAQContent.content || "")
              : t("common:emptyState.none")}
          </Typography>
        </SkeletonWrapper>
      </Stack>
      <Stack rowGap={1} data-qaid="haq-drawer-haq-response-section">
        <Typography variant="subtitle2">
          {t("detailsPreview.content.response")}
        </Typography>
        <SkeletonWrapper isLoading={isHAQResponseContentLoading}>
          <Typography variant="body2">
            {HAQResponseContent?.content
              ? parse(HAQResponseContent.content || "")
              : t("common:emptyState.none")}
          </Typography>
        </SkeletonWrapper>
      </Stack>
    </Stack>
  );
};

export default Content;
