import { ReactNode } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { Box } from "@mui/material";
import { SxProps, Theme } from "@mui/material";
import { SystemProps } from "@mui/system";

type TFormWrapperProps = {
  methods: UseFormReturn<any>;
  onSubmit: () => void;
  children?: ReactNode;
  sx?: SxProps<Theme>;
} & SystemProps;

const FormWrapper = ({
  methods,
  onSubmit,
  children,
  sx,
  ...systemProps
}: TFormWrapperProps) => (
  <FormProvider {...methods}>
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={sx}
      data-testid="form-wrapper"
      data-qaid="form-wrapper"
      {...systemProps}
    >
      {children}
    </Box>
  </FormProvider>
);

export default FormWrapper;
