import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import {
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import * as accessChecks from "components/Auth/accessChecks";
import {
  Button,
  Card,
  Loading,
  ReactTable,
  TableFilter,
} from "components/shared";
import NoDataFallback from "components/shared/NoDataFallback";
import ReactTableRow from "components/shared/ReactTable/ReactTableRow";
import { useFeatureFlags } from "context/FeatureFlagsContext";
import useEntityProducts from "hooks/api/GQL/entityManagement/useEntityProducts";
import useReactTableFilters from "hooks/filters/useReactTableFilters";
import useUser from "hooks/useUser";
import ChangeProductStatusModal from "../ChangeProductStatusModal";
import ChangeProductVariantStatusModal from "../ChangeProductVariantStatusModal";
import ManageProductVariantModal from "../ManageProductVariantModal";
import {
  ProductModificationAction,
  TEntityProduct,
  TProductVariant,
} from "../Products.types";
import UpdateProductModal from "../UpdateProductModal";
import { getColumns } from "./columns";
import {
  ProductsTable as ProductsTableColumns,
  TProductsRowData,
} from "./ProductsTable.types";
import { getProductsForRender } from "./ProductsTable.utils";
import ProductVariantsTable from "./ProductVariantsTable";
import { useProductsFilters } from "./useProductsFilters";
import { PlusIcon } from "assets/icons";
import styles from "./ProductsTable.styles";

type TProductsTableProps = {
  onClickCreateProduct: () => void;
};

const ProductsTable = ({ onClickCreateProduct }: TProductsTableProps) => {
  const { t } = useTranslation(["administration", "common"]);
  const [isExpanded, setIsExpanded] = useState<ExpandedState>({});

  const [productToEdit, setProductToEdit] = useState<TEntityProduct>();

  const [productVariantToEdit, setProductVariantToEdit] =
    useState<TProductVariant>();

  const [productModificationAction, setProductModificationAction] =
    useState<ProductModificationAction | null>(null);

  const [activeVariantIndex, setActiveVariantIndex] = useState<number>();

  const onCreateProductVariant = useCallback(
    (product: TEntityProduct) => {
      setProductToEdit(product);
      setProductModificationAction(ProductModificationAction.ManageVariant);
    },
    [setProductToEdit, setProductModificationAction],
  );

  const onEditProductVariant = (
    product: TEntityProduct | undefined,
    productVariant: TProductVariant,
  ) => {
    setProductToEdit(product);
    setProductVariantToEdit(productVariant);
    setProductModificationAction(ProductModificationAction.ManageVariant);
  };

  const onEditProduct = useCallback(
    (product: TEntityProduct) => {
      setProductToEdit(product);
      setProductModificationAction(ProductModificationAction.EditProduct);
    },
    [setProductToEdit, setProductModificationAction],
  );

  const onChangeProductVariantStatus = (
    product: TEntityProduct | undefined,
    productVariant: TProductVariant,
    variantIndex: number,
  ) => {
    setProductToEdit(product);
    setProductVariantToEdit(productVariant);
    setActiveVariantIndex(variantIndex + 1);
    setProductModificationAction(ProductModificationAction.ChangeVariantStatus);
  };

  const onConcludeProductModification = () => {
    setProductModificationAction(null);
    setProductToEdit(undefined);
    setProductVariantToEdit(undefined);
    setActiveVariantIndex(undefined);
  };

  const onChangeProductStatus = useCallback(
    (product: TEntityProduct | undefined) => {
      setProductToEdit(product);
      setProductModificationAction(
        ProductModificationAction.ChangeOverallStatus,
      );
    },
    [setProductToEdit, setProductModificationAction],
  );

  const { entityProducts, isLoading } = useEntityProducts();

  const user = useUser();

  const featureFlags = useFeatureFlags();

  const canManageEntities = useMemo(
    () => accessChecks.canManageEntities({ user, featureFlags }),
    [user, featureFlags],
  );

  const columnsData = useMemo(() => getColumns(t), [t]);

  const rowData = useMemo(
    () =>
      getProductsForRender(
        entityProducts,
        t,
        onEditProduct,
        onCreateProductVariant,
        onChangeProductStatus,
        canManageEntities,
      ),
    [
      entityProducts,
      onEditProduct,
      onCreateProductVariant,
      onChangeProductStatus,
      t,
      canManageEntities,
    ],
  );

  const { setGlobalFilter, setColumnFilters, ...tableInstance } =
    useReactTable<TProductsRowData>({
      columns: columnsData,
      data: rowData,
      state: { expanded: isExpanded },
      enableColumnFilters: true,
      enableFilters: true,
      enableSorting: true,
      enableRowSelection: false,
      enableMultiRowSelection: false,
      enableGlobalFilter: true,
      meta: {
        expandableRowProps: {
          allColumnsSpanned: false,
        },
      },
      initialState: {
        columnVisibility: {
          [ProductsTableColumns.Id]: false,
        },
        pagination: {
          pageSize: 20,
        },
      },
      onExpandedChange: setIsExpanded,
      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getCoreRowModel: getCoreRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
    });

  const { filterParams, keywordParam, onKeywordChange, onFiltersChange } =
    useReactTableFilters(setGlobalFilter, setColumnFilters);

  const productsFilters = useProductsFilters({ products: entityProducts });

  return (
    <Stack direction="column" spacing={2}>
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <TableFilter
          filters={productsFilters}
          selectedFilters={filterParams}
          keywordFilterValue={keywordParam}
          onFiltersChange={onFiltersChange}
          onKeywordChange={onKeywordChange}
          filterByKeywordLabel={t("keywordFilter.filterByKeyword", {
            ns: "common",
          })}
          showButtonLabel={t("filter.showFilterButton", { ns: "common" })}
          hideButtonLabel={t("filter.hideFilterButton", { ns: "common" })}
          clearAllFiltersLabel={t("filter.clearAllFiltersLabel", {
            ns: "common",
          })}
          errorAdornmentAriaLabel={t("ariaLabels.textFieldError", {
            ns: "common",
          })}
        />

        {canManageEntities && (
          <Button
            startIcon={<PlusIcon />}
            variant="contained"
            onClick={onClickCreateProduct}
            aria-label={t("product.addNewProduct")}
            sx={{ ml: 2 }}
          >
            {t("product.addNewProduct")}
          </Button>
        )}
      </Stack>
      {isLoading ? (
        <Loading />
      ) : (
        <Card>
          <ReactTable<TProductsRowData>
            sx={styles.table}
            tableInstance={tableInstance}
            isPaginated
            onRowClick={(row) => row.toggleExpanded()}
            rowRenderer={(props) => (
              <ReactTableRow<TProductsRowData>
                {...props}
                renderSubComponent={(row) => (
                  <ProductVariantsTable
                    productId={row.original[ProductsTableColumns.Id]}
                    onEditProductVariant={(productVariant) =>
                      onEditProductVariant(
                        entityProducts?.find(
                          (product) =>
                            product?.id ===
                            row.original[ProductsTableColumns.Id],
                        ),
                        productVariant,
                      )
                    }
                    onChangeProductVariantStatus={(
                      productVariant,
                      variantIndex,
                    ) =>
                      onChangeProductVariantStatus(
                        entityProducts?.find(
                          (product) =>
                            product?.id ===
                            row.original[ProductsTableColumns.Id],
                        ),
                        productVariant,
                        variantIndex,
                      )
                    }
                  />
                )}
              />
            )}
            EmptyStateComponent={
              <NoDataFallback message={t("noProductsFound")} noBorders />
            }
          />
        </Card>
      )}
      <ManageProductVariantModal
        isOpen={
          productModificationAction === ProductModificationAction.ManageVariant
        }
        product={productToEdit}
        variantToEdit={productVariantToEdit}
        onClose={onConcludeProductModification}
      />
      <ChangeProductStatusModal
        isOpen={
          productModificationAction ===
          ProductModificationAction.ChangeOverallStatus
        }
        product={productToEdit}
        onClose={onConcludeProductModification}
      />
      <ChangeProductVariantStatusModal
        isOpen={
          productModificationAction ===
          ProductModificationAction.ChangeVariantStatus
        }
        product={productToEdit}
        productVariant={productVariantToEdit}
        onClose={onConcludeProductModification}
        variantIndex={activeVariantIndex}
      />
      <UpdateProductModal
        isOpen={Boolean(
          productToEdit &&
            productModificationAction === ProductModificationAction.EditProduct,
        )}
        product={productToEdit}
        onClose={onConcludeProductModification}
      />
    </Stack>
  );
};

export default ProductsTable;
