import { useMatch } from "react-router";
import { Path } from "router";

const useGlobalNavigation = () => {
  const projectECTDPathInformation = useMatch(Path.ProjectECTD);

  return {
    isGlobalNavigationVisible: !Boolean(projectECTDPathInformation),
  };
};

export default useGlobalNavigation;
