import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormSelect } from "components/common/form-fields";
import { TQuestionOptions } from "components/common/HAQ";
import { formatOptionsFromDataDictionary } from "components/common/HAQ/drawers/AddHAQDrawer/AddHAQDrawer.utils";
import useDataDictionary, {
  DataDictionaryKey,
} from "hooks/api/REST/assets/useDataDictionary";
import { HAQFieldName } from "utils/constants/HAQ.constants";

const Domain = () => {
  const { t } = useTranslation("HAQ");

  const { dataDictionary } = useDataDictionary<TQuestionOptions>(
    DataDictionaryKey.Question,
  );

  const options = useMemo(
    () =>
      formatOptionsFromDataDictionary(t, dataDictionary, HAQFieldName.Domain),
    [dataDictionary, t],
  );

  return (
    <FormSelect
      data-qaid="add-haq-domain-select"
      title={t("addHAQModal.fields.domain.title")}
      name={HAQFieldName.Domain}
      label={t("addHAQModal.fields.domain.label")}
      options={options}
    />
  );
};

export default Domain;
