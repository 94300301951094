/* istanbul ignore file */
import { z } from "zod";
import { IAssetVersion } from "models/assets.models";

export enum DeleteVersions {
  Versions = "versions",
}

export const getDocumentVersionOptions = (
  versions: IAssetVersion[] | undefined,
) => {
  return versions
    ? versions.map((version, index) => {
        return {
          id: version.version,
          label: version.version,
          disabled: false,
          latestVersion: index === 0,
          createdDate: version.createdDate,
        };
      })
    : [];
};

export const deleteVersionsSchema = z.object({
  [DeleteVersions.Versions]: z.array(z.string()).min(1),
});
