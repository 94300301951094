import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loading from "components/shared/Loading";
import useAssets from "hooks/api/GQL/assets/useAssets";
import useAsset from "hooks/api/REST/assets/useAsset";
import useOrganizations from "hooks/api/REST/organizations/useOrganizations";
import { MAX_SIZE } from "utils/constants/api.constants";
import { AssetType } from "utils/constants/assets.constants";
import { OrganizationType } from "utils/constants/organization.constants";
import { transformGSPAssets } from "./GSP.utils";
import GSPContent from "./GSPContent";

const orgParams = {
  types: OrganizationType.HA,
  size: MAX_SIZE,
};

const GSP = () => {
  const { GSPId } = useParams<{ id: string; GSPId: string }>();

  const { t } = useTranslation("user");

  const {
    asset: GSP,
    isLoading: isGSPLoading,
    isSuccess: isGSPSuccess,
    isError: isGSPError,
  } = useAsset(GSPId || "");

  const hasGSP = useMemo(() => {
    return !!GSP && isGSPSuccess;
  }, [GSP, isGSPSuccess]);

  // If we have a GSP, we can fetch the GSP assets and HA data
  const {
    organizations,
    isLoading: isOrganizationsLoading,
    isError: isOrganizationsError,
  } = useOrganizations(orgParams, hasGSP);

  const {
    assetsData: GSPAssets,
    isLoading: isGSPAssetsLoading,
    error: GSPAssetsIsError,
  } = useAssets(
    true,
    {
      assetIds: [GSPId ?? ""],
      assetTypes: [AssetType.GSPComponent],
    },
    { enabled: hasGSP },
  );

  const GSPData = useMemo(() => {
    if (GSP && GSPAssets?.assets && organizations?.content) {
      return transformGSPAssets(
        GSP,
        GSPAssets.assets,
        organizations.content,
        t,
      );
    }
  }, [GSP, GSPAssets, organizations, t]);

  if (isGSPLoading || isGSPAssetsLoading || isOrganizationsLoading) {
    return <Loading />;
  }

  if (isGSPError || GSPAssetsIsError || isOrganizationsError) {
    throw new Error("No gsp data was received");
  }

  return GSPData ? <GSPContent GSPData={GSPData} /> : null;
};

export default GSP;
