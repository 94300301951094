import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Divider,
  Grid,
  List,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import { CheckboxTree, ConfirmModal, Select } from "components/shared";
import { ICheckboxOption } from "components/shared/CheckboxTree/CheckboxTree.utils";
import { TSelectedHAQTableRow } from "components/shared/types";
import { useProject } from "context/ProjectContext";
import { useUserRoles } from "hooks/user";
import useUser from "hooks/useUser";
import { HAQStatus } from "utils/constants/HAQ.constants";
import { TenantType } from "utils/constants/users.constants";
import { getDisplayedAssetStatus, getHAQShortName } from "utils/helpers";
import {
  getCanHAQRTransitionToStatus,
  getCanHAQTransitionToStatus,
} from "utils/helpers/HAQ/getHAQStatusChangeOptions";
import {
  getHAQRSecondaryText,
  getHAQSecondaryText,
} from "./ConfirmStatusChangeAdvanced.utils";

export type TConfirmStatusChangeModalResult =
  | {
      targetAssets: string[];
      targetStatus: HAQStatus;
      HAQIdsToTrack: string[];
    }
  | undefined;

type TConfirmStatusChangeModalProps = {
  isOpen: boolean;
  handleConfirm: (result: TConfirmStatusChangeModalResult) => void;
  confirmationText: string;
  selectedRows: TSelectedHAQTableRow[];
  preselectedTargetStatus?: HAQStatus;
};

const ConfirmStatusChangeModal = (props: TConfirmStatusChangeModalProps) => {
  const { organization } = useUser();
  const { project } = useProject();
  const { isProjectManager } = useUserRoles(project?.id);
  const { t } = useTranslation(["HAQ", "common"]);

  const {
    isOpen,
    handleConfirm,
    confirmationText,
    selectedRows,
    preselectedTargetStatus,
  } = props;

  const [targetStatus, setTargetStatus] = useState<HAQStatus | "">(
    preselectedTargetStatus ?? "",
  );

  const [selectedValues, setSelectedValues] = useState<ICheckboxOption[]>([]);

  const { checkboxTreeOptions, targetAssets, HAQIdsToTrack } = useMemo(() => {
    if (!targetStatus) {
      setSelectedValues([]);

      return { checkboxTreeOptions: [], targetAssets: [], HAQIdsToTrack: [] };
    }

    const targetAssets: string[] = [];
    const HAQIdsToTrack: string[] = [];
    const checkboxTreeOptions: ICheckboxOption[] = [];
    const initialSelectedOptions: ICheckboxOption[] = [];

    for (const row of selectedRows) {
      HAQIdsToTrack.push(row.HAQ!.id);

      const HAQShortName = getHAQShortName(
        row.HAQ,
        t("HAQAcronym", { ns: "HAQ" }),
      );

      const newCheckboxTreeOption: ICheckboxOption = {
        id: HAQShortName,
        label: HAQShortName,
        value: HAQShortName,
        // For V3, the status is passed in by the action and we want all of the options open by default.
        expanded: Boolean(preselectedTargetStatus),
        children: [],
      };

      /*** Question ***/

      const canHAQTransitionToStatus = getCanHAQTransitionToStatus(
        row.HAQ,
        targetStatus,
        organization,
        isProjectManager,
      );

      const HAQSecondaryText = getHAQSecondaryText(
        canHAQTransitionToStatus,
        row,
        organization,
        t,
      );

      const HAQOptionChild = {
        id: row.HAQ.id,
        label: t("HAQ:question"),
        secondary: `(${HAQSecondaryText})`,
        value: row.HAQ.id,
        disabled: !canHAQTransitionToStatus.isAllowed,
      };

      newCheckboxTreeOption.children?.push(HAQOptionChild);

      if (canHAQTransitionToStatus.isAllowed) {
        targetAssets.push(row.HAQ.id);
        initialSelectedOptions.push(HAQOptionChild);
      } else {
        // expand the parent if any of the children are disabled so that the user can see what got disabled
        newCheckboxTreeOption.expanded = true;
      }

      /*** Response ***/

      const canHAQRTransitionToStatus = getCanHAQRTransitionToStatus(
        row.HAQResponse,
        targetStatus,
        organization,
        isProjectManager,
      );

      const HAQRSecondaryText = getHAQRSecondaryText(
        canHAQRTransitionToStatus,
        row,
        t,
      );

      const HAQROptionChild = {
        id: row.HAQResponse!.id,
        label: t("HAQ:response"),
        secondary: `(${HAQRSecondaryText})`,
        value: row.HAQResponse!.id,
        disabled: !canHAQRTransitionToStatus.isAllowed,
      };

      if (row.HAQResponse) {
        newCheckboxTreeOption.children?.push(HAQROptionChild);
      }

      if (canHAQRTransitionToStatus.isAllowed) {
        targetAssets.push(row.HAQResponse!.id);
        initialSelectedOptions.push(HAQROptionChild);
      } else {
        // expand the parent if any of the children are disabled so that the user can see what got disabled
        newCheckboxTreeOption.expanded = true;
      }

      /*** Parent ***/

      const enabledChildrenCount = (
        newCheckboxTreeOption.children || []
      ).filter((child) => !child.disabled).length;

      if (enabledChildrenCount === 0) {
        newCheckboxTreeOption.secondary = t(
          "HAQ:manualStatusChange.noneAvailable",
        );
      } else if (
        newCheckboxTreeOption.children &&
        enabledChildrenCount < newCheckboxTreeOption.children.length
      ) {
        newCheckboxTreeOption.secondary = t(
          "HAQ:manualStatusChange.someUnavailable",
        );
      } else {
        initialSelectedOptions.push(newCheckboxTreeOption);
      }

      checkboxTreeOptions.push(newCheckboxTreeOption);
    }

    setSelectedValues(initialSelectedOptions);

    return { checkboxTreeOptions, targetAssets, HAQIdsToTrack };
  }, [
    isProjectManager,
    organization,
    selectedRows,
    t,
    targetStatus,
    preselectedTargetStatus,
  ]);

  function handleConclude(isConfirm: boolean) {
    if (isConfirm && targetStatus !== "") {
      handleConfirm({
        targetAssets: intersection(
          targetAssets,
          selectedValues.map((value) => value.id),
        ),
        targetStatus,
        HAQIdsToTrack,
      });
    } else {
      handleConfirm(undefined);
    }
  }

  const title = preselectedTargetStatus
    ? t("actionOptionsChangeStatusToLabel", {
        targetStatus: getDisplayedAssetStatus(
          TenantType.Sponsor,
          preselectedTargetStatus,
          t,
        ),
      })
    : t("actionOptionsChangeStatusLabel");

  return (
    <ConfirmModal
      confirmColor="primary"
      open={isOpen}
      title={title}
      onConclude={handleConclude}
      confirmButtonText={t("button.confirm", { ns: "common" })}
      cancelButtonText={t("button.cancel", { ns: "common" })}
      isConfirmButtonDisabled={isEmpty(selectedValues)}
    >
      <Stack spacing={2}>
        <Typography variant="body2">{confirmationText}</Typography>
        <Divider />
        {!preselectedTargetStatus && (
          <Grid container>
            <Grid item xs={6}>
              <Select
                id="status"
                value={targetStatus}
                size="small"
                onChange={(event) =>
                  setTargetStatus(event.target.value as HAQStatus)
                }
                label={t("HAQ:manualStatusChange.newStatus")}
              >
                <MenuItem value="" disabled>
                  {t("HAQ:manualStatusChange.selectStatus")}
                </MenuItem>
                {isProjectManager && (
                  <MenuItem value={HAQStatus.Draft}>
                    <Typography noWrap>
                      {getDisplayedAssetStatus(
                        TenantType.Sponsor,
                        HAQStatus.Draft,
                        t,
                      )}
                    </Typography>
                  </MenuItem>
                )}
                <MenuItem value={HAQStatus.Final}>
                  <Typography noWrap>
                    {getDisplayedAssetStatus(
                      TenantType.Sponsor,
                      HAQStatus.Final,
                      t,
                    )}
                  </Typography>
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
        )}
        {!isEmpty(checkboxTreeOptions) && (
          <div>
            {/* The extra div here is to avoid the padding between elements in the modal */}
            <Typography fontWeight={600}>
              {t("HAQ:manualStatusChange.HAQComponentList")}
            </Typography>
            <List>
              <CheckboxTree<ICheckboxOption>
                options={checkboxTreeOptions}
                onChange={setSelectedValues}
                values={selectedValues}
                selectAllSectionsLabel={t("common:multiSelect.selectAll")}
                expandButtonLabel={t("common:ariaLabels.expand")}
                getValue={(option) => option}
                isSelectAllEnabled
              />
            </List>
          </div>
        )}
      </Stack>
    </ConfirmModal>
  );
};

export default ConfirmStatusChangeModal;
