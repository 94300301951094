import { del, get, post, put, routes } from "api";
import {
  ICreateFeatureFlagRequestParams,
  IUpdateFeatureFlagRequestParams,
} from "models/featureFlags.models";

const featureFlags = {
  getFeatureFlags() {
    return get(routes.identity.featureFlags.all());
  },

  createFeatureFlag(data: ICreateFeatureFlagRequestParams) {
    return post(routes.identity.featureFlags.all(), { data });
  },

  //TODO: check is correct usage flagName
  updateFeatureFlag(flagName: string, data: IUpdateFeatureFlagRequestParams) {
    return put(routes.identity.featureFlags.byFlagName(flagName), {
      data,
    });
  },

  deleteFeatureFlag(flagName: string) {
    return del(routes.identity.featureFlags.byFlagName(flagName));
  },
};

export default featureFlags;
