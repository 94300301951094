import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactJson from "react-json-view";
import { Box, CardHeader, Typography } from "@mui/material";
import { Card } from "components/shared";
import { useAlerts } from "context/AlertContext";
import { TDocumentViewMode } from "models/documents.models";
import { TFHIRResource } from "models/fhir.models";
import { DocumentViewMode } from "utils/constants/doc.constants";
import { FHIRResourceType } from "utils/constants/fhir.constants";
import styles from "./UniversalFHIRResource.styles";

type TUniversalFHIRResourceProps = {
  resource: TFHIRResource;
  viewMode: TDocumentViewMode;
  fieldsPristine: boolean;
  onFieldsUpdated: (updated: boolean) => void;
};

const UniversalFHIRResource = ({
  resource,
  viewMode,
  fieldsPristine,
  onFieldsUpdated,
}: TUniversalFHIRResourceProps) => {
  const { t } = useTranslation("dataX", {
    keyPrefix: "notifications",
  });

  const { showAlert } = useAlerts();

  const [editableContent, setEditableContent] = useState(resource);

  const hasEntryChildren = Boolean(
    resource.entry && resource.entry?.length > 0,
  );

  const hasContainedChildren = Boolean(
    resource.contained && resource.contained?.length > 0,
  );

  // TODO: remove 'NONE' below during FTE-9120 integration with BE
  const resourceType = resource.resourceType || "NONE";

  const onHandleUpdates = (edits: any) => {
    const readOnlyProperties = ["id", "resourceType"];

    if (edits && readOnlyProperties.includes(edits.name)) {
      showAlert({
        message: t("editJSONFieldProhibited", { fieldName: edits.name }),
        severity: "error",
      });

      return false;
    }

    if (fieldsPristine) {
      onFieldsUpdated(true);
    }

    setEditableContent({ ...editableContent, ...edits.updated_src });

    return true;
  };

  return (
    <Card sx={styles.container}>
      <CardHeader
        sx={{ borderBottom: "1px solid grey" }}
        title={`${resourceType}`}
        subheader={<Typography variant="body1">{resource.id}</Typography>}
      />
      {editableContent && resourceType !== FHIRResourceType.Bundle && (
        <Box sx={{ margin: "10px" }}>
          <ReactJson
            src={editableContent}
            displayDataTypes={false}
            enableClipboard={false}
            name={false}
            onEdit={viewMode === DocumentViewMode.Editing && onHandleUpdates}
            quotesOnKeys={false}
          />
        </Box>
      )}
      {hasEntryChildren &&
        resource.entry?.map((item) => {
          if (item.resource) {
            return (
              <UniversalFHIRResource
                resource={item.resource}
                viewMode={viewMode}
                fieldsPristine
                onFieldsUpdated={onFieldsUpdated}
                key={item.resource.id}
              />
            );
          }

          return <></>;
        })}
      {hasContainedChildren &&
        resource.contained?.map((item) => {
          if (item.resource) {
            return (
              <UniversalFHIRResource
                resource={item.resource}
                viewMode={viewMode}
                fieldsPristine
                onFieldsUpdated={onFieldsUpdated}
                key={item.resource.id}
              />
            );
          } else {
            return (
              <UniversalFHIRResource
                resource={item}
                viewMode={viewMode}
                key={item.id}
                fieldsPristine
                onFieldsUpdated={onFieldsUpdated}
              />
            );
          }
        })}
    </Card>
  );
};

export default UniversalFHIRResource;
