import { Trans, useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import styles from "../ConfirmModalContent.styles";

type TECTDUploadContent = {
  preUploadChecks: string;
  preUploadChecksList?: Array<readonly [string, string]>;
  postUploadChecks: string;
  postUploadChecksList?: Array<readonly [string, string]>;
};

const ECTDUpload = () => {
  const { t } = useTranslation("documents", {
    keyPrefix: "importDocument",
  });

  const {
    preUploadChecks,
    preUploadChecksList,
    postUploadChecks,
    postUploadChecksList,
  }: TECTDUploadContent = t("ECTDUploadConfirmModal", {
    returnObjects: true,
  });

  return (
    <Box sx={styles.container}>
      <Trans t={t} i18nKey="ECTDUploadConfirmModal.modalContent">
        {!isEmpty(preUploadChecksList) && (
          <>
            <Typography fontWeight={700}>{preUploadChecks}</Typography>
            <Box component="ul" sx={styles.unorderedList}>
              {preUploadChecksList?.map((check, index) => (
                <Box component="li" key={index} sx={styles.listItem}>
                  <Typography fontWeight={700} component="span">
                    {`${check[0]}: `}
                  </Typography>
                  {check[1]}
                </Box>
              ))}
            </Box>
          </>
        )}

        {!isEmpty(postUploadChecksList) && (
          <>
            <Typography fontWeight={700}>{postUploadChecks}</Typography>
            <Box component="ul" sx={styles.unorderedList}>
              {postUploadChecksList?.map((check, index) => (
                <Box component="li" key={index} sx={styles.listItem}>
                  <Typography fontWeight={700} component="span">
                    {`${check[0]}: `}
                  </Typography>
                  {check[1]}
                </Box>
              ))}
            </Box>
          </>
        )}
      </Trans>
    </Box>
  );
};

export default ECTDUpload;
