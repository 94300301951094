import { Theme } from "@mui/material";
import { getPaletteColor } from "theme/helpers";

export const DOCUMENTS_VISUALS: {
  [key: string]: { abbreviation: string; color: (theme: Theme) => string };
} = {
  word: {
    abbreviation: "W",
    color: getPaletteColor("blue.800"),
  },

  assessmentAid: {
    abbreviation: "AA",
    color: getPaletteColor("green.600"),
  },
};
