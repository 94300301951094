import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormComboBox } from "components/common/form-fields";
import useOrganizations from "hooks/api/REST/organizations/useOrganizations";
import { MAX_SIZE } from "utils/constants/api.constants";
import { DEFAULT_ORBIS_HOST_HA_TENANT_ID } from "utils/constants/organization.constants";
import { OrganizationType } from "utils/constants/organization.constants";
import { getRecipientsForDisplay } from "../Recipients.utils";

const orgParams = {
  types: OrganizationType.HA,
  size: MAX_SIZE,
};

type TOrbisRecipientsField = {
  fieldName: string;
  helperText?: string;
  label: string;
};

const OrbisRecipientsField = ({
  fieldName,
  helperText,
  label,
}: TOrbisRecipientsField) => {
  const { setValue, trigger } = useFormContext();

  const { t } = useTranslation(["common", "user"]);

  const { organizations, isLoading } = useOrganizations(orgParams);

  const recipientsOptions = useMemo(() => {
    return organizations?.content
      ? getRecipientsForDisplay(organizations.content, t)
      : [];
  }, [organizations, t]);

  useEffect(() => {
    // For time being, we are defaulting the recipients to FDA and disabling the field so it cannot be changed
    if (recipientsOptions?.length) {
      const defaultOption = recipientsOptions.find(
        (option) => option.value === DEFAULT_ORBIS_HOST_HA_TENANT_ID,
      );

      setValue(fieldName, [defaultOption]);
      trigger(fieldName);
    }
  }, [recipientsOptions, setValue, trigger, fieldName]);

  return (
    <FormComboBox
      name={fieldName}
      data-qaid="orbis-recipients-combobox"
      errorAdornmentAriaLabel={t("ariaLabels.textFieldError")}
      deselectAllLabelText={t("multiSelect.deselectAll")}
      selectAllLabelText={t("multiSelect.selectAll")}
      label={label}
      loading={isLoading}
      disabled
      helperText={helperText}
      options={recipientsOptions}
    />
  );
};

export default OrbisRecipientsField;
