import { useTranslation } from "react-i18next";
import { QueryObserverOptions, useQuery } from "react-query";
import { Bundle, FhirResource } from "fhir/r5";
import useShowAlert from "hooks/useShowAlert";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { FHIRResourceType } from "utils/constants/fhir.constants";

export default function useFHIRResource(
  resourceType: FHIRResourceType,
  id: string,
  options?: QueryObserverOptions,
) {
  const { t } = useTranslation("dataX", {
    keyPrefix: "notifications",
  });

  const { data, status, isLoading, isFetching, isError, isSuccess, error } =
    useQuery<Bundle<FhirResource>, Error>(
      [QueryAPIKey.GetFHIRResource, id],
      () =>
        services.dataX.getFHIRResource(resourceType, id).then((res) => {
          return res.data;
        }),
      {
        enabled: !!id && (options?.enabled ?? true),
      },
    );

  useShowAlert({
    status,
    failureMessage: t("getResourceFailure"),
  });

  return {
    data,
    status,
    error,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  };
}
