import { useCallback, useEffect, useMemo, useState } from "react";
import useDocuments from "hooks/api/REST/documents/useDocuments";
import { IDocument } from "models/documents.models";
import { MAX_SIZE } from "utils/constants/api.constants";
import { SUPPORTED_SUBTYPES } from "utils/constants/doc.constants";

interface IUseAddDocuments {
  selectedDocuments: IDocument[];
  projectId: string | undefined;
  validateDocument?: (document: IDocument) => Boolean;
}

const useAddDocuments = ({
  projectId,
  validateDocument,
  selectedDocuments,
}: IUseAddDocuments) => {
  const [isAddState, setIsAddState] = useState<Boolean>(false);

  const [docsToAdd, setDocsToAdd] = useState<IDocument[]>([]);

  const { documents, isError, isLoading, isSuccess } = useDocuments({
    projectIds: projectId,
    size: MAX_SIZE,
    assetTypes: SUPPORTED_SUBTYPES.join(),
  });

  useEffect(() => {
    if (!isAddState) {
      setDocsToAdd([]);
    }
  }, [isAddState]);

  const selectedDocumentsIds = useMemo(
    () => selectedDocuments.map((doc) => doc.id),
    [selectedDocuments],
  );

  const filteredDocuments = useMemo(() => {
    if (!validateDocument) {
      return documents;
    }

    return documents?.filter(validateDocument);
  }, [validateDocument, documents]);

  const displayedDocuments = useMemo(() => {
    return filteredDocuments?.filter(
      (doc) => !selectedDocumentsIds.includes(doc.id),
    );
  }, [selectedDocumentsIds, filteredDocuments]);

  const handleDocsChange = useCallback(
    (document: IDocument) => {
      const docsToAddIds = docsToAdd?.map((doc: IDocument) => doc.id);

      if (docsToAddIds.includes(document.id)) {
        setDocsToAdd(
          docsToAdd.filter((doc: IDocument) => document.id !== doc.id),
        );
      } else {
        setDocsToAdd([...docsToAdd, document]);
      }
    },
    [docsToAdd, setDocsToAdd],
  );

  return {
    isAddState,
    setIsAddState,
    docsToAdd,
    isError,
    isSuccess,
    isLoading,
    documents: displayedDocuments,
    onChange: handleDocsChange,
  };
};

export default useAddDocuments;
