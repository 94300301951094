import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { services } from "services";

export default function useUnlockAssetEditing() {
  const { t } = useTranslation("annotations");

  const { status, error, isLoading, isError, isSuccess, mutate } = useMutation(
    (assetId: string) =>
      services.documents.unlockAssetEditing(assetId).then((res) => res.data),
  );

  useShowAlert({
    status,
    failureMessage: t("notifications.releaseLockFailure"),
  });

  return {
    isAssetSuccessfullyUnlocked: isSuccess,
    error,
    isLoading,
    isError,
    isSuccess,
    unlockAssetEditing: mutate,
  };
}
