import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { isEmpty } from "lodash";
import * as accessChecks from "components/Auth/accessChecks";
import { Card, ReactTable, TableFilter } from "components/shared";
import { Button } from "components/shared";
import NoDataFallback from "components/shared/NoDataFallback";
import { useFeatureFlags } from "context/FeatureFlagsContext";
import useReactTableFilters from "hooks/filters/useReactTableFilters";
import useUser from "hooks/useUser";
import ManageSubstanceModal from "../ManageSubstanceModal";
import { TSubstance } from "../Substances.types";
import { getColumns } from "./columns";
import {
  SubstancesTable as SubstancesTableColumns,
  TSubstancesRowData,
} from "./SubstancesTable.types";
import { getSubstancesForRender } from "./SubstancesTable.utils";
import { useSubstancesFilters } from "./useSubstancesFilters";
import { PlusIcon } from "assets/icons";
import styles from "./SubstancesTable.styles";

type TSubstancesTableProps = {
  substances: TSubstance[] | undefined;
};

const SubstancesTable = ({ substances }: TSubstancesTableProps) => {
  const { t } = useTranslation(["administration", "projects", "common"]);

  const [shouldShowSubstanceControlModal, setShouldShowSubstanceControlModal] =
    useState(false);

  const [substanceToEdit, setSubstanceToEdit] = useState<TSubstance>();

  const onClickManageSubstance = useCallback((substance?: TSubstance) => {
    substance && setSubstanceToEdit(substance);
    setShouldShowSubstanceControlModal(true);
  }, []);

  const onCloseManageSubstanceModal = () => {
    setSubstanceToEdit(undefined);
    setShouldShowSubstanceControlModal(false);
  };

  const user = useUser();

  const featureFlags = useFeatureFlags();

  const canManageEntities = useMemo(
    () => accessChecks.canManageEntities({ user, featureFlags }),
    [user, featureFlags],
  );

  const noResults = isEmpty(substances);

  const columnsData = useMemo(() => getColumns(t), [t]);

  const rowData = useMemo(
    () =>
      getSubstancesForRender(
        substances,
        t,
        onClickManageSubstance,
        canManageEntities,
      ),
    [substances, t, onClickManageSubstance, canManageEntities],
  );

  const {
    setGlobalFilter,
    setColumnFilters,
    ...tableInstance
  }: Table<TSubstancesRowData> = useReactTable({
    columns: columnsData,
    data: rowData,
    enableColumnFilters: true,
    enableFilters: true,
    enableSorting: true,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableGlobalFilter: true,
    globalFilterFn: "includesString",
    initialState: {
      columnVisibility: {
        [SubstancesTableColumns.Id]: false,
      },
      pagination: {
        pageSize: 20,
      },
    },
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const { filterParams, keywordParam, onKeywordChange, onFiltersChange } =
    useReactTableFilters(setGlobalFilter, setColumnFilters);

  const substancesFilters = useSubstancesFilters({ substances });

  return (
    <Stack direction="column" spacing={2}>
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <TableFilter
          filters={substancesFilters}
          selectedFilters={filterParams}
          keywordFilterValue={keywordParam}
          onFiltersChange={onFiltersChange}
          onKeywordChange={onKeywordChange}
          filterByKeywordLabel={t("keywordFilter.filterByKeyword", {
            ns: "common",
          })}
          showButtonLabel={t("filter.showFilterButton", { ns: "common" })}
          hideButtonLabel={t("filter.hideFilterButton", { ns: "common" })}
          clearAllFiltersLabel={t("filter.clearAllFiltersLabel", {
            ns: "common",
          })}
          errorAdornmentAriaLabel={t("ariaLabels.textFieldError", {
            ns: "common",
          })}
        />

        {canManageEntities && (
          <Button
            startIcon={<PlusIcon />}
            variant="contained"
            onClick={() => onClickManageSubstance()}
            aria-label={t("addNewSubstance")}
            sx={{ ml: 2 }}
          >
            {t("addNewSubstance")}
          </Button>
        )}
      </Stack>

      {noResults ? (
        <NoDataFallback sx={{ mt: 2 }} message={t("noSubstancesFound")} />
      ) : (
        <Card>
          <ReactTable<TSubstancesRowData>
            sx={styles.table}
            tableInstance={tableInstance}
            isPaginated
            onRowClick={() => {
              // TODO FTE-23130
            }}
          />
        </Card>
      )}

      <ManageSubstanceModal
        open={shouldShowSubstanceControlModal}
        substanceToEdit={substanceToEdit}
        onClose={onCloseManageSubstanceModal}
      />
    </Stack>
  );
};

export default SubstancesTable;
