import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { IDocument } from "models/documents.models";
import { EventType } from "models/tracking.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { AssetType } from "utils/constants/assets.constants";
import { DocumentSubtype } from "utils/constants/doc.constants";

export default function useDocumentUpload() {
  const { t } = useTranslation("documents", {
    keyPrefix: "notifications",
  });

  const queryClient = useQueryClient();

  const {
    data,
    mutate: uploadDocument,
    isError,
    isLoading,
    status,
  } = useMutation<IDocument, Error, FormData>((uploadFormData: FormData) =>
    services.documents.addDocument(uploadFormData).then((res) => {
      const data = res.data;

      if (
        data.type !== DocumentSubtype.ProjectParticipants &&
        data.type !== DocumentSubtype.ImportedHAQ
      ) {
        services.tracking.addTrackingEvent({
          assetType: AssetType.Document,
          assetId: data.id,
          projectId: data.projectIds?.[0] || "",
          eventType: EventType.AppContentImport,
        });
      }

      queryClient.invalidateQueries(QueryAPIKey.GetDocuments);

      return data;
    }),
  );

  useShowAlert({
    status,
    failureMessage: t("uploadDocumentFailure"),
  });

  return { data, uploadDocument, isError, isLoading };
}
