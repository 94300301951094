import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "@mui/material";
import { HARecipientsField } from "components/common/HAQ";
import { HAQFormLayout } from "components/common/HAQ/HAQFormLayout";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { useProject } from "context/ProjectContext";
import useStartWorkflow from "hooks/api/REST/workflow/useStartWorkflow";
import { useHAQ } from "hooks/haq";
import useUser from "hooks/useUser";
import { QueryAPIKey } from "utils/constants/api.constants";
import { ISO_DATE_FORMAT } from "utils/constants/format.constants";
import { HAQFieldName } from "utils/constants/HAQ.constants";
import { WorkflowId } from "utils/constants/workflows.constants";
import { formatDate, getIsEmptyString } from "utils/helpers";
import {
  getRequestHAQExternalReviewSchema,
  requestHAQExternalReviewDefaultValues,
  TRequestHAQExternalReviewForm,
} from "./RequestHAQExternalReview.types";

type TRequestHAQExternalReviewModalProps = {
  id?: string;
  onClose: () => void;
  isOpen: boolean;
};

const RequestHAQExternalReview = ({
  id,
  onClose,
  isOpen,
}: TRequestHAQExternalReviewModalProps) => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const { HAQId } = useParams();
  const { t } = useTranslation(["HAQ", "common", "notifications"]);

  const { startWorkflowAsync } = useStartWorkflow({
    successMessage: t("workflowMessages.externalReviewRequestSuccessMessage", {
      ns: "notifications",
    }),
    failureMessage: t("workflowMessages.externalReviewRequestErrorMessage", {
      ns: "notifications",
    }),
  });

  const { HAQ, relatedAssetName } = useHAQ(id ?? HAQId);
  const { project } = useProject();

  const relatedEntityName = relatedAssetName || project?.name;

  const methods = useForm<TRequestHAQExternalReviewForm>({
    mode: "all",
    defaultValues: requestHAQExternalReviewDefaultValues,
    resolver: zodResolver(getRequestHAQExternalReviewSchema(t)),
  });

  const { formState, handleSubmit, watch } = methods;

  const dueDate = watch(HAQFieldName.DueDate);

  const handleRequestHAQExternalReview = async (
    data: TRequestHAQExternalReviewForm,
  ) => {
    if (user?.id && dueDate) {
      const targetTenants = data.invitedHealthAuthorityTenants.map(
        (invitedTenant) => invitedTenant.value,
      );

      startWorkflowAsync(
        {
          id: WorkflowId.HAQRequestExternalReview,
          payload: {
            originator: user?.id,
            targetTenants,
            assets: [HAQ?.id || ""],
            instructions: data?.additionalInstructions,
            dueDate: formatDate(dueDate, ISO_DATE_FORMAT),
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryAPIKey.GetAsset);
            queryClient.invalidateQueries(QueryAPIKey.GetAssetContent);
          },
        },
      );
    }

    onClose();
  };

  const cancelButton = (
    <Button
      key="cancel"
      data-qaid="request-haq-review-cancel-button"
      variant="text"
      onClick={onClose}
    >
      {t("button.cancel", { ns: "common" })}
    </Button>
  );

  const submitButton = (
    <Button
      key="submit"
      data-qaid="request-haq-review-cancel-button"
      type="submit"
      variant="contained"
      disabled={!formState.isValid}
    >
      {t("button.send", { ns: "common" })}
    </Button>
  );

  return (
    <Modal
      data-qaid="request-haq-external-review-modal"
      open={isOpen}
      title={t("requestExternalReview.title", { ns: "HAQ" })}
    >
      <FormWrapper
        methods={methods}
        onSubmit={handleSubmit(handleRequestHAQExternalReview)}
      >
        <HAQFormLayout<TRequestHAQExternalReviewForm>
          dateFieldName={HAQFieldName.DueDate}
          infoFieldName={HAQFieldName.AdditionalInstructions}
        >
          {!getIsEmptyString(relatedEntityName) && (
            <Typography variant="body2" color="text.secondary">
              {t("viewPage.component.relatedTo", {
                parentComponent: relatedEntityName,
              })}
            </Typography>
          )}
          <HARecipientsField />
        </HAQFormLayout>
        <ModalActionButtons buttons={[cancelButton, submitButton]} />
      </FormWrapper>
    </Modal>
  );
};

export default RequestHAQExternalReview;
