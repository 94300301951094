import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export const useDayjsLocalization = () => {
  const { i18n } = useTranslation();

  require(`dayjs/locale/${i18n.language}.js`);

  dayjs.locale(i18n.language);
};
