import { TFunction } from "i18next";
import { z } from "zod";
import {
  TCreateProductEntity,
  TCreateProductVariant,
} from "hooks/api/GQL/entityManagement/useCreateProductEntity/useCreateProductEntity.models";
import { EntityStatus } from "models/entities.models";
import { getEntityOrganizationRoles } from "screens/EntityManagement/utils/getEntityOrganizationRoles";
import { MEMBER_SCHEMA } from "utils/validators/constraints/validationConstraints";
import {
  ProductEntityFieldName,
  TProductEntityForm,
} from "../ProductEntityFormFields/ProductEntityFormFields.types";
import {
  ProductVariantFieldName,
  TProductVariantForm,
} from "../ProductVariantFormFields/ProductVariantFormFields.types";
import { createProductVariantFormSchema } from "../ProductVariantFormFields/ProductVariantFormFields.utils";

export const getCreateProductModalSchema = (t: TFunction) =>
  z.object({
    [ProductEntityFieldName.ProductName]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductEntityFieldName.NonproprietaryName]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductEntityFieldName.LinkedSubstances]: z.array(MEMBER_SCHEMA).min(0, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductEntityFieldName.LinkedOrganizations]: z
      .array(z.string())
      .optional(),
    [ProductEntityFieldName.LinkedProductFamilies]: z
      .array(MEMBER_SCHEMA)
      .min(1, {
        message: t("validations.requiredField", { ns: "common" }),
      }),
    [ProductEntityFieldName.Status]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductEntityFieldName.ProductVariants]: z
      .array(createProductVariantFormSchema(t, false))
      .min(1),
  });

const getProductVariantValuesForCreation = (
  values: TProductVariantForm,
): TCreateProductVariant => ({
  productTypeCode: values[ProductVariantFieldName.ProductType],
  routeOfAdministrationTypeCode:
    values[ProductVariantFieldName.RouteOfAdministration],
  manufacturedDoseFormTypeCode:
    values[ProductVariantFieldName.ManufacturedDoseForm],
  numeratorUnitValue: values[ProductVariantFieldName.StrengthValue],
  numeratorUnitTypeCode: values[ProductVariantFieldName.StrengthType],
  denominatorUnitValue:
    values[ProductVariantFieldName.StrengthVolumeValue]?.toString() ?? null,
  denominatorUnitTypeCode:
    values[ProductVariantFieldName.StrengthVolumeType] ?? "",
  active: values[ProductVariantFieldName.Status] === EntityStatus.Active,
});

export const getProductValuesForCreation = (
  values: TProductEntityForm,
): TCreateProductEntity => {
  return {
    tradeName: values[ProductEntityFieldName.ProductName].trim(),
    nonproprietaryName:
      values[ProductEntityFieldName.NonproprietaryName].trim(),
    substanceIds:
      values[ProductEntityFieldName.LinkedSubstances]?.map(
        (linkedSubstance) => linkedSubstance.value,
      ) ?? [],
    entityOrganizationRoles:
      getEntityOrganizationRoles(
        values[ProductEntityFieldName.LinkedOrganizations],
      ) ?? [],
    productFamilyIds:
      values[ProductEntityFieldName.LinkedProductFamilies]?.map(
        (linkedProductFamily) => linkedProductFamily.value,
      ) ?? [],
    active: values[ProductEntityFieldName.Status] === EntityStatus.Active,
    productVariants:
      values[ProductEntityFieldName.ProductVariants]?.map((variant) =>
        getProductVariantValuesForCreation(variant),
      ) ?? [],
  };
};
