import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormSelect from "components/common/form-fields/FormSelect";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import useUpdateUser from "hooks/api/REST/user/useUpdateUser";
import useUser from "hooks/useUser";
import { BinaryChoice } from "utils/constants/common";
import {
  TUpdateEmailNotificationsForm,
  TUpdateEmailNotificationsModalProps,
  UpdateEmailNotificationsForm,
} from "./UpdateEmailNotificationsModal.types";
import { getEmailNotificationsOptions } from "./UpdateEmailNotificationsModal.utils";

const UpdateEmailNotificationsModal = (
  props: TUpdateEmailNotificationsModalProps,
) => {
  const { t } = useTranslation(["user", "common", "emailNotifications"]);
  const { open, isEnabled, onSave, onClose } = props;
  const { user } = useUser();
  const { updateUser } = useUpdateUser();
  const options = getEmailNotificationsOptions(t);

  const methods = useForm<TUpdateEmailNotificationsForm>({
    mode: "all",
    values: {
      [UpdateEmailNotificationsForm.EmailNotifications]:
        BinaryChoice.fromBoolean(isEnabled),
    },
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async (data: TUpdateEmailNotificationsForm) => {
    if (user) {
      await updateUser({
        ...user,
        preferences: {
          ...user.preferences,
          emailNotificationsEnabled: BinaryChoice.toBoolean(
            data[UpdateEmailNotificationsForm.EmailNotifications],
          ),
        },
      });
    }

    onSave?.();
    reset();
  };

  const handleClose = useCallback(() => {
    onClose?.();
    reset();
  }, [onClose, reset]);

  return (
    <Modal
      data-qaid="update-email-notifications-modal"
      title={t("profileUpdateEmailNotificationsModal.modalTitle")}
      open={open}
    >
      <FormWrapper
        paddingTop={2}
        paddingBottom={4}
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormSelect
          fullWidth
          data-qaid="email-notifications-select"
          name={UpdateEmailNotificationsForm.EmailNotifications}
          label={t("emailNotifications:label")}
          options={options}
        />
        <ModalActionButtons>
          <Button
            key="cancel-button"
            data-qaid="cancel-button"
            onClick={handleClose}
          >
            {t("button.cancel", { ns: "common" })}
          </Button>
          <Button
            key="save-button"
            data-qaid="save-button"
            variant="contained"
            type="submit"
          >
            {t("button.save", { ns: "common" })}
          </Button>
        </ModalActionButtons>
      </FormWrapper>
    </Modal>
  );
};

export default UpdateEmailNotificationsModal;
