import { TFunction } from "i18next";
import { z } from "zod";

export enum UpdateDocumentMetadataField {
  Language = "language",
  Name = "name",
  Author = "author",
  LitigationHold = "litigationHold",
}

export type TUpdateDocumentMetadataForm = {
  [UpdateDocumentMetadataField.Language]: string;
  [UpdateDocumentMetadataField.Name]: string;
  [UpdateDocumentMetadataField.Author]?: string;
  [UpdateDocumentMetadataField.LitigationHold]: boolean;
};

export const getMetadataFormSchema = (t: TFunction) =>
  z.object({
    [UpdateDocumentMetadataField.Language]: z.string().nonempty({
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [UpdateDocumentMetadataField.Name]: z.string().nonempty({
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [UpdateDocumentMetadataField.Author]: z.string().optional(),
    [UpdateDocumentMetadataField.LitigationHold]: z.boolean().optional(),
  });
