import { createSvgIcon } from "@mui/material";

export const BeenhereIcon = createSvgIcon(
  <svg fill="none">
    <path
      d="M19 1H5C3.9 1 3.01 1.9 3.01 3L3 15.93C3 16.62 3.35 17.23 3.88 17.59L11.45 22.63C11.79 22.85 12.22 22.85 12.56 22.63L20.12 17.59C20.65 17.23 21 16.62 21 15.93V3C21 1.9 20.1 1 19 1ZM18.3 7.7L10.71 15.29C10.32 15.68 9.69 15.68 9.3 15.29L5.71 11.7C5.32 11.31 5.32 10.68 5.71 10.29C6.1 9.9 6.73 9.9 7.12 10.29L10 13.17L16.88 6.29C17.27 5.9 17.9 5.9 18.29 6.29C18.68 6.68 18.69 7.31 18.3 7.7Z"
      fill="currentColor"
      fillOpacity="0.54"
    />
  </svg>,
  "BeenhereIcon",
);
