import { useRef, useState } from "react";
import useInterval from "hooks/useInterval";

export function useCountdown(remainingTime: number, precision = 1000) {
  const [time, setTime] = useState(remainingTime);
  const savedStamp = useRef<number>(performance.now());

  const tick = () => {
    const actualTimeElapsed = performance.now() - savedStamp.current;
    const step = actualTimeElapsed > precision ? actualTimeElapsed : precision;

    setTime(time - step);

    savedStamp.current = performance.now();
  };

  useInterval({
    callback: tick,
    delay: time > 0 ? precision : null,
  });

  // Cast to zero if remainingTime is not a multiple of precision, thus enabling possibility for time to be subzero
  return time < 0 ? 0 : time;
}
