import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { Button } from "components/shared";
import styles from "./Footer.styles";

const Footer = ({ onClose, handleSubmitHAQ, isSubmitting }: any) => {
  const methods = useFormContext();

  const { formState, handleSubmit } = methods;

  const { t } = useTranslation(["common"]);

  return (
    <Box sx={styles.footer}>
      <Box sx={styles.actions}>
        <Button
          variant="text"
          onClick={onClose}
          data-qaid="add-haq-drawer-cancel-button"
        >
          {t("button.cancel")}
        </Button>
        <Button
          onClick={handleSubmit(handleSubmitHAQ)}
          variant="contained"
          disabled={!formState.isValid || isSubmitting}
          data-qaid="add-haq-drawer-save-button"
        >
          {t("button.save")}
        </Button>
      </Box>
    </Box>
  );
};

export default Footer;
