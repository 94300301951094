import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormComboBox } from "components/common/form-fields";
import { useProject } from "context/ProjectContext";
import { HAQFieldName } from "utils/constants/HAQ.constants";
import { mapOrganizationsToSelectOptions } from "utils/helpers/mapOrganizationsToSelectOptions";

const HARecipients = () => {
  const { t } = useTranslation("HAQ");

  const { participatingHAOrganizationsExceptUserOrganization } = useProject();

  const { unregister } = useFormContext();

  useEffect(() => () => unregister(HAQFieldName.HARecipients), [unregister]);

  const recipientOptions = useMemo(
    () =>
      mapOrganizationsToSelectOptions(
        participatingHAOrganizationsExceptUserOrganization,
      ),
    [participatingHAOrganizationsExceptUserOrganization],
  );

  return (
    <FormComboBox
      data-qaid="ha-recipients-combobox"
      helperText={String(t("addHAQModal.fields.HARecipients.helperText"))}
      name={HAQFieldName.HARecipients}
      label={String(t("addHAQModal.fields.HARecipients.label"))}
      options={recipientOptions}
    />
  );
};

export default HARecipients;
