import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import {
  getCoreRowModel,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table";
import { isEmpty } from "lodash";
import { ReactTable } from "components/shared";
import Button from "components/shared/Button";
import Loading from "components/shared/Loading";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import useShareHistory from "hooks/api/REST/shareHistory/useShareHistory";
import getColumns from "./columns";
import {
  TDocumentHistoryRowData,
  TDocumentShareHistoryModalProps,
} from "./DocumentShareHistory.types";
import { getRows } from "./DocumentShareHistory.utils";
import styles from "./DocumentShareHistory.styles";

const tableOptions: TableOptions<TDocumentHistoryRowData> = {
  columns: [],
  data: [],
  enableFilters: false,
  enableSorting: false,
  enableRowSelection: false,
  enableMultiRowSelection: false,
  enableGlobalFilter: false,
  getCoreRowModel: getCoreRowModel(),
};

const DocumentShareHistory = ({
  document,
  onClose,
}: TDocumentShareHistoryModalProps) => {
  const { t } = useTranslation(["documents", "common", "notifications"]);

  const { shareHistory, isLoading } = useShareHistory(document.id);

  const columns = useMemo(() => getColumns(t), [t]);

  const rows = useMemo(
    () => getRows(shareHistory, document.type, t),
    [shareHistory, document, t],
  );

  const tableInstance = {
    submitted: useReactTable<TDocumentHistoryRowData>({
      ...tableOptions,
      columns: columns.submitted,
      data: rows.submitted,
    }),

    shared: useReactTable<TDocumentHistoryRowData>({
      ...tableOptions,
      columns: columns.shared,
      data: rows.shared,
    }),
  };

  return (
    <Modal
      open
      data-qaid="document-share-history-modal"
      title={t("documents.shareHistory.modalTitle")}
      sx={styles.container}
    >
      {isLoading ? (
        <Stack justifyContent="center" direction="row" sx={{ p: 8 }}>
          <Loading size="1.5rem" />
        </Stack>
      ) : (
        <Stack spacing={2}>
          {!isEmpty(rows.submitted) && (
            <ReactTable
              sx={styles.section}
              tableInstance={tableInstance.submitted}
              isPaginated={false}
              bodyRowHover={false}
            />
          )}
          {!isEmpty(rows.shared) && (
            <ReactTable
              sx={styles.section}
              tableInstance={tableInstance.shared}
              isPaginated={false}
              bodyRowHover={false}
            />
          )}
        </Stack>
      )}
      <ModalActionButtons>
        <Button
          data-qaid="share-histore-close-button"
          variant="text"
          onClick={onClose}
        >
          {t("button.close", { ns: "common" })}
        </Button>
      </ModalActionButtons>
    </Modal>
  );
};

export default DocumentShareHistory;
