import { useProject } from "context/ProjectContext";
import { ProjectType } from "utils/constants/project.constants";
import { TDocumentsTeasersProps } from "./DocumentsTeasers.types";
import OrbisDocumentsTeasers from "./OrbisDocumentsTeasers";
import RelianceDocumentsTeasers from "./RelianceDocumentsTeasers";

const DocumentsTeasers = (props: TDocumentsTeasersProps) => {
  const { project } = useProject();

  //todo don't forget to distribute this component into different folders in scope of FTE-26614
  switch (project?.type) {
    case ProjectType.Orbis:
      return <OrbisDocumentsTeasers {...props} />;

    case ProjectType.Reliance:
      return <RelianceDocumentsTeasers {...props} />;

    default:
      return null;
  }
};

export default DocumentsTeasers;
