import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import Button from "components/shared/Button";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";

export type TSecurityNoticeModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
};

const SecurityNotice = ({
  isOpen,
  onCancel,
  onSubmit,
  isSubmitting,
}: TSecurityNoticeModalProps) => {
  const { t } = useTranslation(["documents", "common"]);

  return (
    <Modal
      data-qaid="security-notice-modal"
      open={isOpen}
      title={t("documents.shareDocument.securityNotice.title")}
    >
      <Typography variant="body2" marginBottom={1.5}>
        {t("documents.shareDocument.securityNotice.content")}
      </Typography>
      <ModalActionButtons>
        <Button data-qaid="security-notice-cancel" onClick={onCancel}>
          {t("button.no", { ns: "common" })}
        </Button>
        <Button
          data-qaid="security-notice-submit"
          variant="contained"
          onClick={onSubmit}
          disabled={isSubmitting}
        >
          {t("button.yes", { ns: "common" })}
        </Button>
      </ModalActionButtons>
    </Modal>
  );
};

export default SecurityNotice;
