import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { IAssetVersion } from "models/assets.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export default function useAllAssetVersions(id: string) {
  const { t } = useTranslation("asset", {
    keyPrefix: "notifications",
  });

  const { data, status, isLoading, isFetching, isError, isSuccess, error } =
    useQuery<IAssetVersion[], Error>(
      [QueryAPIKey.GetAllAssetVersions, id],
      () => services.assets.getAllAssetVersion(id).then((res) => res.data),
      { enabled: !!id },
    );

  useShowAlert({
    status,
    failureMessage: t("getAllAssetVersionFailure"),
  });

  return {
    versions: data,
    status,
    error,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  };
}
