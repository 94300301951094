import { shouldShowTasks } from "components/Auth/accessChecks";
import useFeatureFlags from "context/FeatureFlagsContext/useFeatureFlags";
import useUser from "hooks/useUser";

const useShouldShowTasks = () => {
  const featureFlags = useFeatureFlags();
  const user = useUser();

  return shouldShowTasks({ user, featureFlags });
};

export default useShouldShowTasks;
