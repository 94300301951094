import { services } from "services";
import { LOGOUT_BROADCAST_CHANNEL_ID } from "utils/constants/broadcastChannel.constants";
import { oktaAuth } from "./okta";

export async function signOut(options: {
  shouldRestorePath: boolean;
  shouldLogoutEverywhere: boolean;
}) {
  if (options.shouldRestorePath) {
    oktaAuth.setOriginalUri(window.location.pathname);
  }

  if (options.shouldLogoutEverywhere) {
    const broadcastChannel = new BroadcastChannel(LOGOUT_BROADCAST_CHANNEL_ID);
    broadcastChannel.postMessage({ isLoggingOut: true });
    broadcastChannel.close();
  } else {
    await actualLogout();
  }
}

export const logoutHandler = async (
  event: BroadcastChannelEventMap["message"],
) => {
  if (event.data.isLoggingOut) {
    await actualLogout();
  }
};

const actualLogout = async () => {
  const isAuthenticated = await oktaAuth.isAuthenticated();

  if (isAuthenticated) {
    services.user
      .logout()
      .then(() => oktaAuth.signOut())
      .catch(console.error);
  }
};
