import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { IParseJob } from "models/assets.models";
import { IProcessDocumentRequestBody } from "models/documents.models";
import { services } from "services";

type TProcessDocumentProps = { data?: IProcessDocumentRequestBody };

export default function useProcessDocument() {
  const { t } = useTranslation("documents", {
    keyPrefix: "notifications",
  });

  const {
    data: documentInfo,
    status,
    error,
    isLoading,
    mutate: triggerProcessingDocument,
  } = useMutation<IParseJob, Error, TProcessDocumentProps>(({ data }) =>
    services.documents.processDocument(data).then((res) => res.data),
  );

  useShowAlert({
    status,
    failureMessage: t("processDocumentFailure"),
  });

  return {
    documentInfo,
    error,
    isLoading,
    triggerProcessingDocument,
  };
}
