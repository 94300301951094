import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const iconWidth = "24px";

const outlineRowStyles: TStyles[keyof TStyles] = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderTop: getBorderStyle("1px", "solid", "divider"),
  textDecoration: "none",
  cursor: "pointer",
  px: 1,
  py: 2.125,
};

const styles: TStyles = {
  outlineHeader: {
    textTransform: "uppercase",
    pt: 2.5,
    mb: 1,
    mt: 1,
  },

  outline: {
    overflow: "auto",
    height: "auto",
    width: 300,
  },

  outlineInfoMenu: {
    overflow: "auto",
    height: "auto",
  },

  name: {
    "& .MuiTypography-root": {
      fontWeight: 500,
    },
  },

  outlineRow: {
    ...outlineRowStyles,
  },

  activeOutlineRow: {
    ...outlineRowStyles,
    backgroundColor: getPaletteColor("primary.50"),
  },

  notSubmitIcon: {
    color: getPaletteColor("grey.500"),
    transform: "rotate(90deg)",
    width: iconWidth,
    height: "auto",
    mr: 2,
    my: -0.25,
  },

  submitIcon: {
    color: getPaletteColor("green.800"),
    width: iconWidth,
    height: "auto",
    mr: 2,
    my: -0.25,
  },
};

export default styles;
