import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import { TMenuOption } from "components/shared/ButtonMenu";
import { useFeatureFlags } from "context/FeatureFlagsContext";
import { useProject } from "context/ProjectContext";
import useShareHistory from "hooks/api/REST/shareHistory/useShareHistory";
import usePermissions from "hooks/permissions";
import useDownloadBlob from "hooks/useDownloadBlob";
import { useUserRoles } from "hooks/user";
import useUser from "hooks/useUser";
import { IDocument } from "models/documents.models";
import { EventType } from "models/tracking.models";
import { useDocumentsContext } from "screens/Project/sections/Documents/DocumentsContext";
import {
  getCanDocumentBeConverted,
  getCanDocumentBeDeleted,
  getCanDocumentBeDownloaded,
  getCanDocumentBeHAQed,
  getCanDocumentBePreviewed,
  getCanDocumentBeSent,
  getCanDocumentBeShared,
  getCanDocumentBeSubmitted,
  getCanDocumentBeViewed,
  getCanEditDocumentName,
  getCanViewSubmitHistory,
} from "screens/Project/sections/Documents/utils/documentActions";
import { getAvailableDocumentStatusChangeOptions } from "screens/Project/sections/Documents/utils/documentStatusChanges";
import { services } from "services";
import { AssetType } from "utils/constants/assets.constants";
import {
  DocumentAction,
  DocumentStatus,
  DocumentSubtype,
} from "utils/constants/doc.constants";
import { ProjectStatus } from "utils/constants/project.constants";
import { getDocumentPath } from "utils/helpers";

const useDocumentActions = (document: IDocument) => {
  const { t } = useTranslation(["documents", "common", "statuses"]);

  const { project, participatingHAOrganizationsExceptUserOrganization } =
    useProject();

  const { user, organization } = useUser();

  const featureFlags = useFeatureFlags();

  const downloadBlob = useDownloadBlob(document);

  const { permissions } = usePermissions(project?.id ?? "");

  const { setCurrentActionOnDocument } = useDocumentsContext();

  const { shareHistory } = useShareHistory(document.id);

  const userRoles = useUserRoles(project?.id);

  const navigate = useNavigate();

  const [, setSearchParams] = useSearchParams();

  const documentStatusChangeOptions = getAvailableDocumentStatusChangeOptions(
    document,
    organization,
    user,
    permissions,
    t,
  );

  // `Edit name` action for eCTD should be revised in scope of FTE-30570
  // `Change status` action for eCTD should be revised in scope of FTE-30286
  // `Submit` action for eCTD should be revised in scope of FTE-30287
  // `Delete` action for eCTD should be revised in scope of FTE-30557
  // Last 3 probably should't and won't be changed since those actions are common for all document types

  const isViewActionAvailable = getCanDocumentBeViewed(document);

  const isEditNameActionAvailable =
    project?.active && getCanEditDocumentName(document);

  const isConvertActionAvailable =
    project?.active &&
    getCanDocumentBeConverted(document, organization, permissions);

  const isSubmitActionAvailable =
    permissions.canSendContentToTenancy &&
    project?.status === ProjectStatus.InProgress &&
    getCanDocumentBeSubmitted(document, organization);

  const isAddQuestionActionAvailable =
    project?.active && getCanDocumentBeHAQed(document, organization);

  const isShareActionAvailable =
    project?.active &&
    !isEmpty(participatingHAOrganizationsExceptUserOrganization) &&
    getCanDocumentBeShared(document, organization, userRoles);

  const isSendCopyActionAvailable =
    project?.active && getCanDocumentBeSent(document, organization);

  const isPreviewActionAvailable = getCanDocumentBePreviewed(
    document,
    permissions,
  );

  const isDownloadActionAvailable = getCanDocumentBeDownloaded(document);

  const isDeleteActionAvailable = getCanDocumentBeDeleted({
    project,
    document,
    user,
    organization,
    userRoles,
    permissions,
    featureFlags,
  });

  const isChangeStatusActionAvailable =
    project?.active && !isEmpty(documentStatusChangeOptions);

  const isHandleClearedStatusActionAvailable =
    document.status === DocumentStatus.Acknowledged;

  const isViewContentInfoActionAvailable = ![DocumentSubtype.ECTD].includes(
    document.type,
  );

  const isViewShareHistoryActionAvailable =
    permissions.canSeeShareHistory && !isEmpty(shareHistory?.content);

  const isViewSubmitHistoryActionAvailable = getCanViewSubmitHistory(
    document,
    organization,
  );

  const actionOptions: TMenuOption[] = [];

  if (isViewActionAvailable) {
    actionOptions.push({
      id: DocumentAction.View,
      label: t("button.view", { ns: "common" }),
      onClick: () => navigate(getDocumentPath(project?.id, document)),
    });
  }

  if (isEditNameActionAvailable) {
    actionOptions.push({
      id: DocumentAction.EditName,
      label: t("documents.utils.options.editName"),
      onClick: () => {},
    });
  }

  if (isConvertActionAvailable) {
    actionOptions.push({
      id: DocumentAction.Convert,
      label: t("documents.utils.options.convertToStructuredAAID"),
      onClick: () =>
        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.Convert,
        }),
    });
  }

  if (isSubmitActionAvailable) {
    actionOptions.push({
      id: DocumentAction.Submit,
      label: t("documents.utils.options.submitDocument"),
      onClick: () =>
        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.Submit,
        }),
    });
  }

  if (isAddQuestionActionAvailable) {
    actionOptions.push({
      id: DocumentAction.AddQuestion,
      label: t("documents.utils.options.createHAQ"),
      onClick: () =>
        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.AddQuestion,
        }),
    });
  }

  if (isShareActionAvailable) {
    actionOptions.push({
      id: DocumentAction.Share,
      label: t("documents.utils.options.shareDocument"),
      onClick: () =>
        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.Share,
        }),
    });
  }

  if (isSendCopyActionAvailable) {
    actionOptions.push({
      id: DocumentAction.SendCopy,
      label: t("documents.utils.options.sendCopy"),
      onClick: () =>
        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.SendCopy,
        }),
    });
  }

  if (isPreviewActionAvailable) {
    actionOptions.push({
      id: DocumentAction.Preview,
      label: t("documents.utils.options.previewDocument"),
      onClick: () => {
        setSearchParams(new URLSearchParams({ documentId: document.id }));
        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.Preview,
        });
      },
    });
  }

  if (isDownloadActionAvailable) {
    actionOptions.push({
      id: DocumentAction.Download,
      label: t("documents.utils.options.downloadDocument"),
      onClick: downloadBlob,
    });
  }

  if (isDeleteActionAvailable) {
    actionOptions.push({
      id: DocumentAction.Delete,
      label: t("documents.utils.options.deleteDocument"),
      onClick: () =>
        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.Delete,
        }),
    });
  }

  if (isChangeStatusActionAvailable) {
    actionOptions.push({
      id: DocumentAction.ChangeStatus,
      label: t("documents.utils.options.changeStatus"),
      items: documentStatusChangeOptions,
      onClick: (option) => {
        if (option && document.status !== option.id) {
          setCurrentActionOnDocument({
            documents: [document],
            documentAction: DocumentAction.ChangeStatus,
            targetStatus: option.id as DocumentStatus,
          });
        }
      },
    });
  }

  if (isViewContentInfoActionAvailable) {
    actionOptions.push({
      id: DocumentAction.ViewContentInfo,
      label: t("documents.utils.options.viewContentInformation"),
      onClick: () => {
        if (
          document.type !== DocumentSubtype.ProjectParticipants &&
          project?.id
        ) {
          services.tracking.addTrackingEvent({
            assetId: document.id,
            assetType: AssetType.Document,
            eventType: EventType.AppContentMetadataView,
            projectId: project.id,
          });
        }

        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.ViewContentInfo,
        });
      },
    });
  }

  if (isHandleClearedStatusActionAvailable) {
    const isCleared = Boolean(document.metadata?.cleared);

    const label = isCleared
      ? t("documents.utils.options.unmarkCleared")
      : t("documents.utils.options.markCleared");

    actionOptions.push({
      id: DocumentAction.HandleClearedStatus,
      label,
      // ToDo (DL): Replace handler with BE request with integration ticket
      onClick: noop,
    });
  }

  if (isViewShareHistoryActionAvailable) {
    actionOptions.push({
      id: DocumentAction.ViewShareHistory,
      label: t("documents.utils.options.viewShareHistory"),
      onClick: () =>
        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.ViewShareHistory,
        }),
    });
  }

  if (isViewSubmitHistoryActionAvailable) {
    actionOptions.push({
      id: DocumentAction.ViewSubmitHistory,
      label: t("documents.utils.options.viewSubmitHistory"),
      onClick: () => {
        if (project?.id) {
          services.tracking.addTrackingEvent({
            assetId: document.id,
            assetType: AssetType.Document,
            eventType: EventType.AppContentSubmitHistoryView,
            projectId: project.id,
          });
        }

        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.ViewSubmitHistory,
        });
      },
    });
  }

  return { actionOptions };
};

export default useDocumentActions;
