import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  submissionHistory: {
    "& .MuiTableCell-head": {
      color: getPaletteColor("text.primary"),
      fontWeight: "500",
      lineHeight: 1.43,
    },
  },
};

export default styles;
