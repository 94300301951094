export enum WorkflowExecutionStatus {
  Pending = "PENDING",
  Running = "RUNNING",
  Complete = "COMPLETE",
  Cancelled = "CANCELLED",
  Failed = "FAILED",
}

export interface IWorkflowExecution<T extends object = {}> {
  id: string;
  input: T;
  name: string;
  status: WorkflowExecutionStatus;
  workflowSpec: string;
  parentStateExecutionId: string;
  failureReason: string;
}

export interface IEventRequestData {
  [key: string]: any;
}

export type TCancelWorkflowTasks = {
  workflowId: string;
};
