import { useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import useAssets from "hooks/api/GQL/assets/useAssets";
import { IProjectData } from "hooks/api/GQL/project/useProjectData.models";
import useOrganizations from "hooks/api/REST/organizations/useOrganizations";
import usePermissions from "hooks/permissions";
import useUser from "hooks/useUser";
import { TProjectMetadata } from "models/projects.models";
import { MAX_SIZE } from "utils/constants/api.constants";
import { DocumentSubtype } from "utils/constants/doc.constants";
import { OrganizationType } from "utils/constants/organization.constants";
import { getParticipatingHATenantIds } from "../projectHooks.utils";

const useParticipatingHAOrganizations = (
  project: IProjectData<TProjectMetadata> | null | undefined,
) => {
  const { organization: userOrganization } = useUser();
  const { permissions } = usePermissions(project?.id ?? "");

  // Fetch the GSP components for projects with enabled "hasParticipatingHAOrganizations"
  const {
    assetsData,
    isLoading: isAssetsResponseLoading,
    error: isAssetsResponseError,
    isSuccess: isAssetsResponseSuccess,
  } = useAssets(
    true,
    {
      projectIds: project ? [project?.id] : [],
      assetTypes: [DocumentSubtype.GSPComponent],
    },
    {
      enabled:
        Boolean(project?.id) && permissions.hasParticipatingHAOrganizations,
    },
  );

  // For projects with enabled "hasParticipatingHAOrganizations":
  // get tenant ids from GSP components (for HAs set to `Submitting` in GSP)
  // For projects with disabled "hasParticipatingHAOrganizations": tbd later
  const participatingHATenantIds = useMemo(() => {
    return permissions.hasParticipatingHAOrganizations
      ? getParticipatingHATenantIds(assetsData?.assets ?? [])
      : [];
  }, [assetsData?.assets, permissions]);

  const {
    organizations,
    isLoading: isOrganizationsLoading,
    isError: isOrganizationsError,
    isSuccess: isOrganizationsSuccess,
  } = useOrganizations(
    {
      types: OrganizationType.HA,
      size: MAX_SIZE,
      tenantIds: participatingHATenantIds,
    },
    !isEmpty(participatingHATenantIds),
  );

  const participatingHAOrganizationsExceptUserOrganization = useMemo(
    () =>
      organizations?.content?.filter(
        (organization) => organization.tenantId !== userOrganization?.tenantId,
      ) || [],
    [organizations, userOrganization],
  );

  return {
    participatingHAOrganizations: organizations?.content ?? [],
    participatingHAOrganizationsExceptUserOrganization,
    isLoading: isAssetsResponseLoading || isOrganizationsLoading,
    isError: isAssetsResponseError || isOrganizationsError,
    isSuccess: isAssetsResponseSuccess || isOrganizationsSuccess,
  };
};

export default useParticipatingHAOrganizations;
