import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { TFunction } from "i18next";
import ButtonMenu from "components/shared/ButtonMenu";
import { TProductFamily } from "../ProductFamily.types";
import { TProductFamilyRowData } from "./ProductFamilyTable.types";

export const getProductFamilyForRender = (
  productFamilies: TProductFamily[] | undefined | null,
  t: TFunction,
  onEditProductFamily: (productFamily: TProductFamily) => void,
  canManageEntities: boolean,
): TProductFamilyRowData[] =>
  productFamilies?.map((productFamily) => ({
    ...productFamily,
    actions: (
      <ButtonMenu
        options={[
          {
            id: "edit-productFamily",
            label: t("productFamily.editProductFamily", {
              ns: "administration",
            }),
            onClick: () => {
              onEditProductFamily(productFamily);
            },
            disabled: !productFamily.actions.canEditEntity,
          },
        ]}
      >
        <IconButton aria-label="actionsButton" disabled={!canManageEntities}>
          <MoreVertIcon />
        </IconButton>
      </ButtonMenu>
    ),
  })) ?? [];
