import { TFunction } from "i18next";
import { TFeatureFlagsMap } from "models/featureFlags.models";
import {
  INotification,
  NotificationAssetType,
  NotificationCTAType,
  TNotificationTemplateMetadata,
} from "models/notificationItem.models";
import { Path } from "router/paths";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import { NotificationTemplateType } from "utils/constants/notifications.constants";
import { ProjectStatus } from "utils/constants/project.constants";
import { getDocumentPath, getHAQPath } from "utils/helpers";
import { ReactComponent as GlobeIcon } from "assets/images/globe-06.svg";
import { ReactComponent as FolderIcon } from "assets/images/notification-folder.svg";

export const getReadableAssetType = (
  assetType: NotificationAssetType,
  t: TFunction,
) => {
  const assetTypeMap: { [key in NotificationAssetType]: string } = {
    [NotificationAssetType.Document]: t("notificationAssetTypes.document"),
    [NotificationAssetType.Component]: t("notificationAssetTypes.component"),
    [NotificationAssetType.GSP]: t("notificationAssetTypes.GSP"),
    [NotificationAssetType.AssessmentAid]: t(
      "notificationAssetTypes.assessmentAid",
    ),
    [NotificationAssetType.LOA]: t("notificationAssetTypes.LOA"),
    [NotificationAssetType.SupportingInformation]: t(
      "notificationAssetTypes.supportingInformation",
    ),
    [NotificationAssetType.ToplineResult]: t(
      "notificationAssetTypes.toplineResult",
    ),
    [NotificationAssetType.References]: t("notificationAssetTypes.references"),
    [NotificationAssetType.Comment]: t("notificationAssetTypes.comment"),
    [NotificationAssetType.Question]: t("notificationAssetTypes.question"),
    [NotificationAssetType.QuestionResponse]: t(
      "notificationAssetTypes.questionResponse",
    ),
    [NotificationAssetType.Product]: t("notificationAssetTypes.product"),
    [NotificationAssetType.ProductFamily]: t(
      "notificationAssetTypes.productFamily",
    ),
    [NotificationAssetType.Substance]: t("notificationAssetTypes.substance"),
    [NotificationAssetType.GSPComponent]: t(
      "notificationAssetTypes.GSPComponent",
    ),
    [NotificationAssetType.DossierDifference]: t(
      "notificationAssetTypes.dossierDifference",
    ),
    [NotificationAssetType.AssessmentAidSource]: t(
      "notificationAssetTypes.assessmentAidSource",
    ),
    [NotificationAssetType.AssessmentReport]: t(
      "notificationAssetTypes.assessmentReport",
    ),
    [NotificationAssetType.ImportedHAQ]: t("notificationAssetTypes.questions"),
    [NotificationAssetType.HADecisionLetter]: t(
      "notificationAssetTypes.HADecisionLetter",
    ),
    [NotificationAssetType.ECTD]: t("notificationAssetTypes.ECTD"),
  };

  return assetTypeMap[assetType] || assetType;
};

// This will eventually be expanded when other notification types are supported
export const getReadableCTAType = (
  metadata: TNotificationTemplateMetadata,
  t: TFunction,
): string => {
  const { ctaType, assetType } = metadata;

  if (ctaType === NotificationCTAType.Project) {
    return t("notificationCTATypes.project");
  }

  if (ctaType === NotificationCTAType.Task) {
    return t("notificationCTATypes.task");
  }

  if (ctaType === NotificationCTAType.Asset && assetType) {
    return getReadableAssetType(assetType, t);
  }

  if (ctaType === NotificationCTAType.HAQs) {
    return t("notificationCTATypes.questions");
  }

  if (ctaType === NotificationCTAType.Content) {
    const { numOfDocuments, documentNames } = metadata;

    return documentNames && numOfDocuments && Number(numOfDocuments) <= 1
      ? documentNames.slice(1, -1).split(",")[0]
      : t("notificationCTATypes.content");
  }

  return "";
};

export const getCTALink = (
  metadata: TNotificationTemplateMetadata,
  featureFlags: TFeatureFlagsMap,
): string => {
  const {
    ctaType,
    assetType,
    taskId,
    assetId,
    projectId,
    parentAssetId,
    groupId,
  } = metadata;

  const shouldShowNewTasks =
    featureFlags["shouldShowNewTasksTable"] === FeatureFlag.On;

  if (ctaType === NotificationCTAType.Project && projectId) {
    return Path.Project.replace(":projectId", projectId ?? "");
  }

  if (ctaType === NotificationCTAType.Task && groupId && shouldShowNewTasks) {
    return Path.Task.replace(":taskId", groupId ?? "");
  }

  // deprecated
  if (ctaType === NotificationCTAType.Task && taskId && !shouldShowNewTasks) {
    return Path.Task.replace(":taskId", taskId ?? "");
  }

  if (ctaType === NotificationCTAType.HAQs) {
    return Path.ProjectHAQs.replace(":projectId", projectId ?? "");
  }

  if (ctaType === NotificationCTAType.Content) {
    return Path.ProjectDocuments.replace(":projectId", projectId ?? "");
  }

  if (ctaType === NotificationCTAType.Asset) {
    // This will eventually be expanded with other values
    switch (assetType) {
      case NotificationAssetType.Question:
        return getHAQPath(projectId, assetId);

      case NotificationAssetType.QuestionResponse:
        return parentAssetId ? getHAQPath(projectId, parentAssetId) : "";

      case NotificationAssetType.ImportedHAQ:
        return Path.ProjectHAQs.replace(":projectId", projectId ?? "");

      default:
        return getDocumentPath(projectId, {
          id: assetId,
          type: assetType,
        });
    }
  }

  return "";
};

export const getNotificationIcon = (type: NotificationCTAType) => {
  // More notification icon types will be supported in the future
  switch (type) {
    case NotificationCTAType.Task:
      return <GlobeIcon />;
    default:
      return <FolderIcon />;
  }
};

export const getNotificationTitleForReason = (
  { templateType, metadata }: INotification,
  t: TFunction,
) => {
  if (
    templateType ===
      NotificationTemplateType.ProjectRelianceStatusUpdateWithStateAndReason &&
    metadata.targetStatus === ProjectStatus.Cancelled
  ) {
    return t("reasonForCancellation");
  }

  if (
    templateType ===
    NotificationTemplateType.ProjectRelianceStatusUpdateWithStateAndReasonRevert
  ) {
    return t("reasonForReversion");
  }

  return t("reason");
};
