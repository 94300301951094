import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import * as accessChecks from "components/Auth/accessChecks";
import { Loading, ReactTable } from "components/shared";
import NoDataFallback from "components/shared/NoDataFallback";
import { useFeatureFlags } from "context/FeatureFlagsContext";
import useProductVariants from "hooks/api/GQL/entityManagement/useProductVariants";
import useUser from "hooks/useUser";
import { TProductVariant } from "screens/EntityManagement/components/Products/Products.types";
import { getColumns } from "./columns";
import {
  ProductVariantsTable as ProductVariantsTableColumns,
  TProductVariantsRowData,
} from "./ProductVariantsTable.types";
import { getProductVariantsForRender } from "./ProductVariantsTable.utils";
import styles from "./ProductVariantsTable.styles";

type TProductsTableProps = {
  productId: string;
  onEditProductVariant: (productVariant: TProductVariant) => void;
  onChangeProductVariantStatus: (
    productVariant: TProductVariant,
    variantIndex: number,
  ) => void;
};

const ProductVariantsTable = ({
  productId,
  onEditProductVariant,
  onChangeProductVariantStatus,
}: TProductsTableProps) => {
  const { t } = useTranslation(["administration", "common"]);

  const { productVariants, isLoading } = useProductVariants({ productId });

  const user = useUser();

  const featureFlags = useFeatureFlags();

  const canManageEntities = useMemo(
    () => accessChecks.canManageEntities({ user, featureFlags }),
    [user, featureFlags],
  );

  const columnsData = useMemo(() => getColumns(t), [t]);

  const rowData = useMemo(
    () =>
      getProductVariantsForRender(
        productVariants,
        t,
        onEditProductVariant,
        onChangeProductVariantStatus,
        canManageEntities,
      ),
    [
      productVariants,
      t,
      onEditProductVariant,
      onChangeProductVariantStatus,
      canManageEntities,
    ],
  );

  const tableInstance = useReactTable<TProductVariantsRowData>({
    columns: columnsData,
    data: rowData,
    enableColumnFilters: false,
    enableFilters: false,
    enableSorting: true,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    initialState: {
      columnVisibility: {
        [ProductVariantsTableColumns.Id]: false,
      },
      pagination: {
        pageSize: 20,
      },
    },
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return isLoading ? (
    <Stack paddingY={3}>
      <Loading />
    </Stack>
  ) : (
    <ReactTable<TProductVariantsRowData>
      sx={styles.table}
      tableInstance={tableInstance}
      isPaginated={false}
      EmptyStateComponent={
        <NoDataFallback message={t("noProductVariantsFound")} noBorders />
      }
    />
  );
};

export default ProductVariantsTable;
