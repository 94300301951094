import { ReactNode } from "react";
import { ColumnFilter, ColumnFiltersState } from "@tanstack/table-core";
import { TFilteringParams } from "components/shared/types/filter";
import { IFeatureFlag } from "models/featureFlags.models";

export type TFlagsRowData = {
  id: string;
  name: ReactNode;
  value: string;
  dateStamp: string;
  modifiedBy: string;
  editAction: IFeatureFlag;
  deleteAction: IFeatureFlag;
};

export const getFeatureFlagsForRender = (featureFlags?: IFeatureFlag[]) =>
  featureFlags?.map((flag) => ({
    ...flag,
    id: flag.name,
    editAction: flag,
    deleteAction: flag,
  })) || [];

export const getFilterChips = (filters: ColumnFiltersState) => {
  return filters
    ? filters.reduce(
        (result: TFilteringParams, { id, value }: ColumnFilter) => {
          result[id] = value as string[];

          return result;
        },
        {},
      )
    : {};
};
