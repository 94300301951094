import { InfoOutlined as InfoIcon, SaveAltRounded } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import styles from "./DocumentHeader.styles";

export const HeaderActionIcons = () => {
  return (
    <Box>
      <IconButton>
        <SaveAltRounded sx={styles.iconAction} fontSize="medium" />
      </IconButton>
      <IconButton>
        <InfoIcon />
      </IconButton>
    </Box>
  );
};
