import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import Breadcrumbs from "components/shared/Breadcrumbs";
import { useProject } from "context/ProjectContext";
import useBreadcrumbs from "hooks/breadcrumbs/useBreadcrumbs";
import { Path } from "router/paths";
import { ReactComponent as LogoIcon } from "assets/images/accumulus_logo_nav_icon_primary.svg";
import styles from "./AssetPageHeader.styles";

type TAssetPageHeaderProps = {
  title: string;
  actions?: React.ReactNode;
  hasLogo?: boolean;
  version?: string;
};

const AssetPageHeader = ({
  title,
  actions,
  hasLogo = false,
  version,
}: TAssetPageHeaderProps) => {
  const { t } = useTranslation("common");

  const { project } = useProject();

  const breadcrumbs = useBreadcrumbs({
    pathNamesMapping: { projectId: project?.name },
    depth: 3,
  });

  return (
    <Box sx={styles.container}>
      <Stack direction="row" gap={4} alignItems="center">
        {hasLogo && (
          <Link to={Path.HomePage} aria-label={t("ariaLabels.logo")}>
            <Stack pt={1}>
              <LogoIcon data-testid="logo-icon-test-id" />
            </Stack>
          </Link>
        )}
        <Stack direction="column">
          <Breadcrumbs
            ariaLabel={t("ariaLabels.breadcrumbs")}
            breadcrumbs={breadcrumbs}
          />
          <Typography variant="h5" component="h1">
            {title}
          </Typography>
        </Stack>
        {version && (
          <Typography
            variant="caption"
            color="text.secondary"
            ml={6}
            alignSelf="flex-end"
          >
            {t("longVersion", { version })}
          </Typography>
        )}
      </Stack>

      {actions}
    </Box>
  );
};

export default AssetPageHeader;
