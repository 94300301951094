import { useFormContext } from "react-hook-form";
import { TGSPComponent } from "models/gsp.models";
import { GSPFormFieldName } from "screens/Project/screens/GSP/GSPItems/GSPFormItem/GSPForm.types";
import { ItemLayout } from "../components";
import { TDossierDifferencesModule } from "../GSPItems.utils";
import FieldsLayout from "./FieldsLayout";

export type TGSPFormItemProps = {
  component: TGSPComponent;
  dossierDifferencesModules: TDossierDifferencesModule[];
};

const GSPFormItem = ({
  component,
  dossierDifferencesModules,
}: TGSPFormItemProps) => {
  const { watch } = useFormContext();

  const selectedSubmissionPlan = watch(
    `${component.id}.${GSPFormFieldName.SubmissionPlan}`,
  );

  return (
    <ItemLayout
      HA={component.healthAuthority}
      id={component.id}
      selectedSubmissionPlan={selectedSubmissionPlan}
    >
      <FieldsLayout
        id={component.id}
        HAData={{
          country: component.healthAuthority.country,
          name: component.healthAuthority.name,
          tenantId: component.metadata?.tenantId || "",
        }}
        dossierDifferencesModules={dossierDifferencesModules}
      />
    </ItemLayout>
  );
};

export default GSPFormItem;
