import { TFunction } from "i18next";
import { z } from "zod";
import { mapRoleTypesToSelectedOrgs } from "screens/EntityManagement/utils/organizationRoleTypes.utils";
import {
  SubstanceEntityFieldName,
  TSubstanceEntityForm,
} from "../SubstanceEntityFormFields/SubstanceEntityFormFields.types";
import { TSubstance } from "../Substances.types";

export const getManageSubstanceModalSchema = (t: TFunction) =>
  z.object({
    [SubstanceEntityFieldName.SubstanceName]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [SubstanceEntityFieldName.DrugClass]: z.object({
      value: z.string().min(1, {
        message: t("validations.requiredField", { ns: "common" }),
      }),
    }),
    [SubstanceEntityFieldName.INN]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [SubstanceEntityFieldName.ATCCode]: z
      .string()
      .min(5, {
        message: t("validations.invalidATCCodeLength", { ns: "common" }),
      })
      .max(7, {
        message: t("validations.invalidATCCodeLength", { ns: "common" }),
      })
      .refine((value) => Boolean(value.length === 5 || value.length === 7), {
        message: t("validations.invalidATCCodeLength", { ns: "common" }),
      })
      .refine(
        (value) => /^[A-Z]{1}[0-9]{2}[A-Z]{2}([0-9]{2})?$/.test(value ?? ""),
        {
          message: t("validations.invalidATCCodeFormat", { ns: "common" }),
        },
      ),
    [SubstanceEntityFieldName.Status]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [SubstanceEntityFieldName.LinkedOrganizations]: z
      .array(z.string())
      .optional(),
  });

export const getSubstanceEntityFormValues = (
  substance: TSubstance | undefined,
): TSubstanceEntityForm => {
  const { name, drugClassType, INN, ATC, linkedOrganizations, status } =
    substance ?? {};

  return {
    [SubstanceEntityFieldName.SubstanceName]: name ?? "",
    [SubstanceEntityFieldName.DrugClass]: drugClassType
      ? { label: drugClassType.name, value: drugClassType.code }
      : { label: "", value: "" },
    [SubstanceEntityFieldName.INN]: INN ?? "",
    [SubstanceEntityFieldName.ATCCode]: ATC ?? "",
    [SubstanceEntityFieldName.LinkedOrganizations]:
      mapRoleTypesToSelectedOrgs(linkedOrganizations),
    [SubstanceEntityFieldName.Status]: status ?? "",
  };
};
