import { Editor, Plugin } from "ckeditor5";

class CollaborationPlugin extends Plugin {
  editor: Editor;

  constructor(editor: Editor) {
    super(editor);
    this.editor = editor;
  }

  static get requires() {
    return ["CommentsRepository", "TrackChanges", "Users"];
  }

  static get pluginName() {
    return "CollaborationPlugin";
  }

  async init() {
    await this.initUsers();
    await this.initTrackChanges();
    await this.initComments();
  }

  private async initComments() {
    const commentsRepositoryPlugin =
      this.editor.plugins.get("CommentsRepository");

    const commentsAdapter = this.editor.config
      .get("adapters")
      ?.getCommentsAdapter?.(this.editor);

    if (commentsAdapter) {
      commentsRepositoryPlugin.adapter = commentsAdapter;
    }
  }

  private async initTrackChanges() {
    const trackChangesPlugin = this.editor.plugins.get("TrackChanges");

    const trackChangesAdapter = this.editor.config
      .get("adapters")
      ?.getTrackChangesAdapter?.(this.editor);

    if (trackChangesAdapter) {
      trackChangesPlugin.adapter = trackChangesAdapter;
    }
  }

  private async initUsers() {
    const usersPlugin = this.editor.plugins.get("Users");

    const adapters = this.editor.config.get("adapters");

    if (adapters?.users && adapters?.currentUser) {
      adapters?.users.forEach((user) => {
        const name =
          user.org === adapters?.currentUser?.org
            ? `${user.firstName} ${user.lastName}`
            : user.org;

        usersPlugin.addUser({
          ...user,
          name,
        });
      });

      // set the logged-in user as the active user in the editor
      usersPlugin.defineMe(adapters?.currentUser.id);
    }
  }
}

export default CollaborationPlugin;
