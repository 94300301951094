import { TFunction } from "i18next";
import { z } from "zod";
import { TComboBoxOption } from "components/shared/ComboBox/ComboBox";
import { TISODateFormat } from "utils/types";
import {
  getGenericFutureDateConstraint,
  getGenericStringArrayConstraint,
  MEMBER_SCHEMA,
} from "utils/validators/constraints/validationConstraints";

export enum SendCopyComponentsFieldName {
  Components = "components",
  Recipients = "recipients",
  DueDate = "dueDate",
  Instructions = "instructions",
}

export type TSendCopyComponentsForm = {
  [SendCopyComponentsFieldName.Components]: string[];
  [SendCopyComponentsFieldName.Recipients]: TComboBoxOption[];
  [SendCopyComponentsFieldName.DueDate]: TISODateFormat | "";
  [SendCopyComponentsFieldName.Instructions]: string;
};

export const SendCopyComponentsSchema = (t: TFunction) =>
  z.object({
    [SendCopyComponentsFieldName.Components]:
      getGenericStringArrayConstraint(t),
    [SendCopyComponentsFieldName.Recipients]: z.array(MEMBER_SCHEMA).min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [SendCopyComponentsFieldName.DueDate]: getGenericFutureDateConstraint(t),
    [SendCopyComponentsFieldName.Instructions]: z.string().optional(),
  });
