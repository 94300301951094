import { SyntheticEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { zodResolver } from "@hookform/resolvers/zod";
import { AccessAlarm } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import FileUploadStatus from "components/common/FileUploadStatus";
import Alert from "components/shared/Alert";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Loading from "components/shared/Loading";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { useAlerts } from "context/AlertContext";
import { useConfirm } from "context/ConfirmationDialogContext";
import { useFeatureFlags } from "context/FeatureFlagsContext";
import { useProject } from "context/ProjectContext";
import useAzureBlobStorage from "hooks/api/REST/assets/useAzureBlobStorage";
import useFHIRResourceUpload from "hooks/api/REST/dataX/useFHIRResourceUpload";
import useDocumentUpload from "hooks/api/REST/documents/useDocumentUpload";
import useProcessDocument from "hooks/api/REST/documents/useProcessDocument";
import { IParseJob } from "models/assets.models";
import { IDocument } from "models/documents.models";
import { Path } from "router/paths";
import { useDocumentsContext } from "screens/Project/sections/Documents/DocumentsContext";
import { QueryFetchingStatus } from "utils/constants/api.constants";
import { AssetDomain, AssetGroup } from "utils/constants/assets.constants";
import {
  ALLOWED_FHIR_MIME_TYPES,
  DocumentAction,
  DocumentSubtype,
  ParseJobStatus,
} from "utils/constants/doc.constants";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import { FHIRResourceType } from "utils/constants/fhir.constants";
import { GENERIC_ERROR_ID } from "utils/constants/form.constants";
import { ProjectStatus } from "utils/constants/project.constants";
import { getErrorMessageFromAPI } from "utils/helpers";
import AAIDNote from "./components/AAIDNote";
import ECTDUploadConfirmModalContent from "./components/ConfirmModalContent/ECTDUpload";
import ProjectParticipantsSubmissionConfirmModalContent from "./components/ConfirmModalContent/ProjectParticipantsSubmission";
import DocumentsRadioList from "./components/DocumentsRadioList";
import {
  ImportFormBaseFieldName,
  ImportFormReferencesFieldName,
} from "./components/fields/names";
import FieldsLayout from "./components/FieldsLayout";
import useDeletePreviouslySubmittedLOAs from "./hooks/useDeletePreviouslySubmittedLOAs";
import { TImportForm } from "./ImportDocument.types";
import {
  formatProjectParticipantsApiErrorMessage,
  getCreateUploadTokenRequestData,
  getFHIRFileAsJSON,
  getImportModalSchema,
  getModalTitle,
  getSelectedFHIRResourceType,
  getSubmissionData,
  importModalDefaultValues,
  isReferencesForm,
} from "./ImportDocument.utils";
import styles from "./ImportDocument.styles";

export enum ImportModalType {
  Default = "DEFAULT",
  AAID = "AAID",
  SAL = "SAL",
  ECTD = "ECTD",
  AssessmentReport = "ASSESSMENT_REPORT",
  HADecisionLetter = "HA_DECISION_LETTER",
}

type TImportModalProps = {
  openType: ImportModalType;
  onClose: (type: ImportModalType | null) => void;
};

const importModalDefaultValuesMap: {
  [key in ImportModalType]?: Partial<TImportForm>;
} = {
  [ImportModalType.AAID]: {
    ...importModalDefaultValues,
    [ImportFormBaseFieldName.Domain]: AssetDomain.Regulatory,
    [ImportFormBaseFieldName.Type]: AssetGroup.Administrative,
    [ImportFormBaseFieldName.Subtype]: DocumentSubtype.AssessmentAidSource,
  },
  [ImportModalType.SAL]: {
    ...importModalDefaultValues,
    [ImportFormBaseFieldName.Domain]: AssetDomain.Regulatory,
    [ImportFormBaseFieldName.Type]: AssetGroup.Administrative,
    [ImportFormBaseFieldName.Subtype]: DocumentSubtype.References,
    [ImportFormReferencesFieldName.SAL]: true,
    [ImportFormReferencesFieldName.HealthAuthority]: "",
  },
  [ImportModalType.ECTD]: {
    ...importModalDefaultValues,
    [ImportFormBaseFieldName.Domain]: AssetDomain.Regulatory,
    [ImportFormBaseFieldName.Type]: AssetGroup.Submission,
    [ImportFormBaseFieldName.Subtype]: DocumentSubtype.ECTD,
  },
  [ImportModalType.AssessmentReport]: {
    ...importModalDefaultValues,
    [ImportFormBaseFieldName.Domain]: AssetDomain.Regulatory,
    [ImportFormBaseFieldName.Type]: AssetGroup.Administrative,
    [ImportFormBaseFieldName.Subtype]: DocumentSubtype.AssessmentReport,
  },
  [ImportModalType.HADecisionLetter]: {
    ...importModalDefaultValues,
    [ImportFormBaseFieldName.Domain]: AssetDomain.Regulatory,
    [ImportFormBaseFieldName.Type]: AssetGroup.Correspondence,
    [ImportFormBaseFieldName.Subtype]: DocumentSubtype.HADecisionLetter,
  },
};

const ImportDocument = ({ openType, onClose }: TImportModalProps) => {
  const navigate = useNavigate();

  const {
    t,
    i18n: { language },
  } = useTranslation(["documents", "common"]);

  const featureFlags = useFeatureFlags();

  const isFHIREnabled = featureFlags["enableFHIRDocuments"] === FeatureFlag.On;

  const { project } = useProject();

  const { uploadFile, status: fileUploadingStatus } = useAzureBlobStorage();

  const { uploadDocument, isLoading: isDocumentUploadingInProgress } =
    useDocumentUpload();

  const { uploadFHIRResource, isLoading: isFHIRUploadingInProgress } =
    useFHIRResourceUpload(project?.id ?? "");

  const {
    triggerProcessingDocument,
    isLoading: isDocumentProcessingInProgress,
  } = useProcessDocument();

  const { showAlert } = useAlerts();

  const { attemptConfirm } = useConfirm();

  const [
    selectedExistingUnconvertedDocument,
    setSelectedExistingUnconvertedDocument,
  ] = useState<IDocument | null>(null);

  const [
    isSelectFromExistingUnconvertedDocumentsOn,
    setIsSelectFromExistingUnconvertedDocumentsOn,
  ] = useState<boolean>(false);

  const methods = useForm<TImportForm>({
    mode: "all",
    defaultValues:
      importModalDefaultValuesMap[openType] || importModalDefaultValues,
    resolver: zodResolver(getImportModalSchema(t, language)),
  });

  const {
    setValue,
    watch,
    trigger,
    formState: { isValid, errors },
    handleSubmit,
  } = methods;

  const fileValue = watch(ImportFormBaseFieldName.File) as FileList;
  const subtypeValue = watch(ImportFormBaseFieldName.Subtype);

  useEffect(() => {
    if (
      isFHIREnabled &&
      subtypeValue === DocumentSubtype.FHIR &&
      fileValue?.length &&
      fileValue[0].type === ALLOWED_FHIR_MIME_TYPES[0]
    ) {
      const getFHIRType = async () => {
        const FHIRtype = await getSelectedFHIRResourceType(fileValue);

        if (FHIRtype) {
          setValue(
            ImportFormBaseFieldName.FHIRResourceType,
            FHIRtype as FHIRResourceType,
            { shouldValidate: true },
          );
        }
      };

      getFHIRType().catch(console.error);
    }
  }, [fileValue, subtypeValue, setValue, isFHIREnabled]);

  useEffect(() => {
    if (
      fileValue?.length &&
      subtypeValue &&
      openType === ImportModalType.Default
    ) {
      trigger("file");
    }
  }, [fileValue, subtypeValue, trigger, openType]);

  const { setCurrentActionOnDocument, setParseJobContext } =
    useDocumentsContext();

  const { deleteLOAs } = useDeletePreviouslySubmittedLOAs(project?.id ?? "");

  const closeModal = () => {
    onClose(null);
  };

  const onECTDUploadStart = (jobId: string) => {
    closeModal();

    showAlert({
      id: jobId,
      title: t("notifications.dossierUploadInProgress"),
      message: <FileUploadStatus jobId={jobId} />,
      icon: (
        <Box sx={styles.accessAlarmIconBox}>
          <AccessAlarm />
        </Box>
      ),
      isPersistent: true,
    });
  };

  const handleSubmitFHIRData = async (values: TImportForm) => {
    const FHIRdata = await getFHIRFileAsJSON(values);

    if (FHIRdata?.resourceType) {
      uploadFHIRResource(
        {
          resourceType: values.FHIRResourceType || FHIRResourceType.Patient,
          fhirData: FHIRdata,
        },
        {
          onSuccess: (response) => {
            showAlert({
              severity: "success",
              message: t("notifications.uploadDocumentSuccess"),
            });

            if (response.resourceType && response.id) {
              navigate(
                Path.ProjectFHIRResource.replace(
                  ":projectId",
                  project?.id || "",
                )
                  .replace(":resourceType", response.resourceType)
                  .replace(":FHIRId", response.id),
              );
            }

            closeModal();
          },
          onError: () => {
            showAlert({
              severity: "error",
              message: t("notifications.uploadDocumentFailure"),
            });
          },
        },
      );
    }
  };

  const handleSubmitImportedHAQ = (values: TImportForm) => {
    const submissionData = getSubmissionData(values, project);

    uploadDocument(submissionData, {
      onSuccess: (uploadedDocument) => {
        triggerProcessingDocument(
          {
            data: {
              sourceAssetId: uploadedDocument.id,
            },
          },
          {
            onSuccess: async (parseJob: IParseJob) => {
              if (parseJob) {
                if (parseJob.status === ParseJobStatus.Completed) {
                  showAlert({
                    severity: "success",
                    message: t("importDocument.processingCompleted"),
                  });
                } else if (parseJob.status === ParseJobStatus.Processing) {
                  setParseJobContext({
                    ...parseJob,
                    documentName: uploadedDocument.name,
                    isImportedHAQ: true,
                  });
                }
              }

              closeModal();
            },
          },
        );
      },
    });
  };

  const handleSubmitAAID = (values: TImportForm) => {
    const submissionData = getSubmissionData(values, project);

    uploadDocument(submissionData, {
      onSuccess: (uploadedDocument) => {
        triggerProcessingDocument(
          {
            data: {
              sourceAssetId: uploadedDocument.id,
            },
          },
          {
            onSuccess: async (parseJob: IParseJob) => {
              if (parseJob) {
                if (parseJob.status === ParseJobStatus.Completed) {
                  showAlert({
                    severity: "success",
                    message: t("importDocument.processingCompleted"),
                  });
                } else if (parseJob.status === ParseJobStatus.Processing) {
                  setCurrentActionOnDocument({
                    documents: [uploadedDocument],
                    documentAction: DocumentAction.Convert,
                  });
                  setParseJobContext({
                    ...parseJob,
                    documentName: uploadedDocument.name,
                  });
                }
              }

              closeModal();
            },
          },
        );
      },
    });
  };

  const handleSubmitECTD = async (values: TImportForm) => {
    const createUploadTokenRequestData = getCreateUploadTokenRequestData(
      values,
      project,
    );

    uploadFile({
      file: values[ImportFormBaseFieldName.File][0],
      createUploadTokenRequestData,
      onUploadStart: onECTDUploadStart,
      messages: {
        successMessage: t("notifications.dossierUploadSuccess"),
        failureMessage: t("notifications.dossierUploadFailure"),
      },
    });
  };

  const handleSubmitDefault = (values: TImportForm) => {
    const submissionData = getSubmissionData(values, project);

    const handleUpload = () =>
      uploadDocument(submissionData, {
        onSuccess: () => {
          showAlert({
            severity: "success",
            message: t("notifications.uploadDocumentSuccess"),
          });

          closeModal();
        },
        onError: (error) => {
          // This is a temporary measure to show errors related to the import of PROJECT_PARTICIPANTS directly from BE
          // BE will be refactoring the error messaging here to provide codes, which the UI can use to provide matched translation strings
          const apiErrorMessage = getErrorMessageFromAPI(error);

          const readableApiErrorMessage =
            formatProjectParticipantsApiErrorMessage(apiErrorMessage);

          showAlert({
            severity: "error",
            message:
              values[ImportFormBaseFieldName.Subtype] ===
                DocumentSubtype.ProjectParticipants && readableApiErrorMessage
                ? `${t(
                    "notifications.uploadDocumentFailure",
                  )}: ${readableApiErrorMessage}`
                : t("notifications.uploadDocumentFailure"),
          });
        },
      });

    if (
      isReferencesForm(values) &&
      values[ImportFormReferencesFieldName.SAL] &&
      values[ImportFormReferencesFieldName.HealthAuthority]
    ) {
      deleteLOAs(values[ImportFormReferencesFieldName.HealthAuthority])
        .then(handleUpload)
        .catch(() => {
          showAlert({
            severity: "error",
            message: t("notifications.uploadDocumentFailure"),
          });
        });
    } else {
      handleUpload();
    }
  };

  const startConvertingDocument = () => {
    if (!selectedExistingUnconvertedDocument) {
      return;
    }

    setCurrentActionOnDocument({
      documents: [selectedExistingUnconvertedDocument],
      documentAction: DocumentAction.Convert,
    });

    closeModal();
  };

  const submitButtonTitle =
    openType === ImportModalType.AAID
      ? t("importDocument.submitButton.AAIDLabel")
      : t("importDocument.submitButton.importLabel");

  const shouldShowLoading =
    isDocumentUploadingInProgress ||
    isDocumentProcessingInProgress ||
    isFHIRUploadingInProgress ||
    fileUploadingStatus === QueryFetchingStatus.Loading;

  const shouldDisableSubmitButton =
    (!isSelectFromExistingUnconvertedDocumentsOn && !isValid) ||
    shouldShowLoading;

  const canStartConvertingImmediately =
    isSelectFromExistingUnconvertedDocumentsOn &&
    selectedExistingUnconvertedDocument;

  const onSubmit = (values: TImportForm) => {
    switch (values.subtype) {
      case DocumentSubtype.FHIR:
        return handleSubmitFHIRData(values);

      case DocumentSubtype.ProjectParticipants:
        return attemptConfirm(
          {
            title:
              project?.status === ProjectStatus.Planned
                ? t(
                    "importDocument.projectParticipantsFirstSubmissionConfirmModal.modalTitle",
                  )
                : t(
                    "importDocument.projectParticipantsSubmissionConfirmModal.modalTitle",
                  ),
            content: (
              <ProjectParticipantsSubmissionConfirmModalContent
                projectStatus={project?.status}
              />
            ),
            confirmColor: "primary",
          },
          {
            onConfirm: () => handleSubmitDefault(values),
          },
        );

      case DocumentSubtype.ImportedHAQ:
        return handleSubmitImportedHAQ(values);

      case DocumentSubtype.ECTD:
        return attemptConfirm(
          {
            title: t("importDocument.ECTDUploadConfirmModal.modalTitle"),
            content: <ECTDUploadConfirmModalContent />,
            confirmColor: "primary",
          },
          {
            onConfirm: () => handleSubmitECTD(values),
          },
        );

      default:
        if (openType === ImportModalType.AAID) {
          return handleSubmitAAID(values);
        } else {
          return handleSubmitDefault(values);
        }
    }
  };

  const submitButton = (
    <Button
      key="submit-button"
      data-qaid="import-document-submit-button"
      type="submit"
      variant="contained"
      disabled={shouldDisableSubmitButton}
    >
      {shouldShowLoading ? <Loading /> : submitButtonTitle}
    </Button>
  );

  const cancelButton = (
    <Button
      key="cancel-button"
      data-qaid="import-document-cancel-button"
      onClick={closeModal}
    >
      {t("importDocument.cancelButton")}
    </Button>
  );

  const genericError = errors?.[GENERIC_ERROR_ID]?.message;

  return (
    <Modal
      data-testid="import-document-modal"
      data-qaid="import-document-modal"
      open={Boolean(openType)}
      title={getModalTitle(openType, t)}
      sx={styles.container}
    >
      <FormWrapper
        methods={methods}
        onSubmit={
          canStartConvertingImmediately
            ? () => startConvertingDocument()
            : handleSubmit(onSubmit)
        }
      >
        <Stack gap={2} justifyContent="flex-start">
          {(genericError || !Boolean(project?.id)) && (
            <Alert severity="error">
              {genericError || t("importDocument.errors.genericFailure")}
            </Alert>
          )}

          {openType === ImportModalType.AAID && <AAIDNote />}

          {isSelectFromExistingUnconvertedDocumentsOn ? (
            <DocumentsRadioList
              selectedDocument={selectedExistingUnconvertedDocument}
              onSelectDocument={setSelectedExistingUnconvertedDocument}
              onChooseImportFile={(event?: SyntheticEvent) => {
                event?.preventDefault();
                setIsSelectFromExistingUnconvertedDocumentsOn(false);
              }}
            />
          ) : isFHIREnabled && isFHIRUploadingInProgress ? (
            <Loading />
          ) : (
            <FieldsLayout
              openType={openType}
              onShowDocumentList={(event?: SyntheticEvent) => {
                event?.preventDefault();
                setIsSelectFromExistingUnconvertedDocumentsOn(true);
              }}
            />
          )}
        </Stack>

        <ModalActionButtons buttons={[cancelButton, submitButton]} />
      </FormWrapper>
    </Modal>
  );
};

export default ImportDocument;
