import { ReactNode, SyntheticEvent } from "react";
import { TBasicShareTrackingRecord } from "hooks/api/GQL/haq/useHAQsWithContent.models";
import { THAQAssetType } from "utils/constants/assets.constants";

export enum HAQHistoryTable {
  Id = "id",
  HAQType = "HAQType",
  Recipient = "recipient",
  Date = "date",
  Status = "status",
}

export type THAQHistoryRowData = {
  [HAQHistoryTable.Id]: string;
  [HAQHistoryTable.HAQType]: THAQAssetType;
  [HAQHistoryTable.Recipient]: ReactNode;
  [HAQHistoryTable.Date]: string;
  [HAQHistoryTable.Status]: string | ReactNode;
};

export type THAQShareHistoryModalProps = {
  shareHistory: THAQShareHistoryTableProps[] | undefined;
  onClose: (event: SyntheticEvent) => void;
  HAQName: string;
};

export type THAQShareHistoryTableProps<
  FullShareHistoryRecord extends boolean = false,
> = (FullShareHistoryRecord extends false
  ? Partial<TBasicShareTrackingRecord>
  : TBasicShareTrackingRecord) & {
  isImported: boolean;
  HAQType: THAQAssetType;
};
