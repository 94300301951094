import {
  Controller,
  FieldPath,
  FieldValues,
  get,
  useFormContext,
} from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
} from "@mui/material";
import RadioGroup from "components/shared/RadioGroup";
import { TRadioGroupProps } from "components/shared/RadioGroup/RadioGroup";
import styles from "./FormRadioGroup.styles";

export type TFormRadioGroupProps<FormType extends FieldValues> = Omit<
  TRadioGroupProps,
  "name" | "id" | "onChange" | "value"
> & {
  name: FieldPath<FormType>;
  label: string;
  shouldHideLabel?: boolean;
  helperText?: string;
  onValueChange?: (value: string) => void;
  disabled?: boolean;
};

const FormRadioGroup = <FormType extends FieldValues>({
  name,
  label,
  shouldHideLabel = false,
  helperText,
  onValueChange,
  disabled,
  ...props
}: TFormRadioGroupProps<FormType>) => {
  const { control, formState } = useFormContext<FormType>();

  const error = get(formState.errors, name);

  return (
    <FormControl error={Boolean(error)} disabled={disabled}>
      {!shouldHideLabel && (
        <FormLabel sx={styles.label}>
          <Typography variant="body2">{label}</Typography>
        </FormLabel>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup
            {...field}
            {...props}
            id={name}
            aria-label={label}
            onChange={(_event, value) => {
              field.onChange(value);
              onValueChange?.(value);
            }}
          />
        )}
      />
      {helperText && (
        <FormHelperText
          data-testid={`${name}-helper-text`}
          data-qaid={`${name}-helper-text`}
          variant="standard"
          aria-live="polite"
        >
          {helperText}
        </FormHelperText>
      )}
      {error && (
        <FormHelperText
          data-testid={`${name}-error-message`}
          data-qaid={`${name}-error-message`}
          variant="standard"
          aria-live="polite"
          error
        >
          {error.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormRadioGroup;
