import { Editor } from "ckeditor5";
import { TrackChangesAdapter } from "ckeditor5-premium-features";
import {
  IUpdateSuggestionRequestData,
  TAnnotationAttributes,
} from "models/annotations.models";
import { SuggestionState } from "utils/constants/annotations.constants";
import { TSuggestionForUpdate } from "../useUpdateSuggestion";

export type TUpdateSuggestionData = {
  attributes?: TAnnotationAttributes;
  hasComments?: boolean;
  state?: SuggestionState;
};

const updateSuggestion =
  (
    editor: Editor,
    mutationFn: (suggestionData: TSuggestionForUpdate) => Promise<any>,
  ): TrackChangesAdapter["updateSuggestion"] =>
  async (suggestionId: string, suggestionData: TUpdateSuggestionData) => {
    const componentId = editor.config.get("collaboration.channelId")!;

    const trackChangesPlugin = editor.plugins.get("TrackChanges");
    const suggestionFromPlugin = trackChangesPlugin.getSuggestion(suggestionId);

    const state = (
      suggestionData.state || SuggestionState.Open
    ).toUpperCase() as IUpdateSuggestionRequestData["state"];

    try {
      await mutationFn({
        componentId,
        suggestionId,
        data: {
          hasComments: suggestionFromPlugin?.hasComments,
          attributes: suggestionFromPlugin.attributes,
          state,
        },
      });
    } catch {}
  };

export default updateSuggestion;
