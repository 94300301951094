import { isEmpty } from "lodash";
import { IProjectData } from "hooks/api/GQL/project/useProjectData.models";
import { TUserPermissions } from "hooks/permissions/usePermissions.types";
import { TUseUserRoles } from "hooks/user";
import { IDocument } from "models/documents.models";
import { TFeatureFlagsMap } from "models/featureFlags.models";
import { IOrganization } from "models/organizations.models";
import { TProjectMetadata } from "models/projects.models";
import { TUserData } from "models/users.models";
import { DocumentStatus } from "utils/constants/doc.constants";
import {
  getCanDocumentBeDeleted,
  getCanDocumentBeSubmitted,
} from "../utils/documentActions";

export const getAllDocumentsHaveSameStatus = (
  selectedDocuments: IDocument[],
  indicatedStatus: DocumentStatus,
) => {
  return selectedDocuments.every(
    (document) => document.status === indicatedStatus,
  );
};

export const getDocumentsContainSubmittedContent = (
  selectedDocuments: IDocument[],
) => {
  return selectedDocuments.some(
    (document) => document?.metadata?.submissionDate,
  );
};

export const getAreDocumentsEligibleForStatusChange = (
  selectedDocuments: IDocument[],
) => {
  if (!isEmpty(selectedDocuments)) {
    return getAllDocumentsHaveSameStatus(
      selectedDocuments,
      DocumentStatus.InVerification,
    );
  }

  return false;
};

export const isDocumentsEligibleForDeletion = ({
  selectedDocuments,
  project,
  user,
  organization,
  userRoles,
  permissions,
  featureFlags,
}: {
  selectedDocuments: IDocument[];
  project: IProjectData<TProjectMetadata> | null | undefined;
  user: TUserData | undefined;
  organization: IOrganization | undefined;
  userRoles: TUseUserRoles;
  permissions: Partial<TUserPermissions>;
  featureFlags: TFeatureFlagsMap;
}) =>
  selectedDocuments.every((document) =>
    getCanDocumentBeDeleted({
      project,
      document,
      user,
      organization,
      userRoles,
      permissions,
      featureFlags,
    }),
  );

export const getCanDocumentsBeSubmitted = (
  selectedDocuments: IDocument[],
  organization: IOrganization | undefined,
) =>
  selectedDocuments.every((document) =>
    getCanDocumentBeSubmitted(document, organization),
  );
