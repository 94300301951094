import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { TFunction } from "i18next";
import ButtonMenu from "components/shared/ButtonMenu";
import { TProductVariant } from "screens/EntityManagement/components/Products/Products.types";
import {
  ProductVariantsTable,
  TProductVariantsRowData,
} from "./ProductVariantsTable.types";

export const getProductVariantMenuOptions = (
  productVariants: TProductVariant[],
  productVariant: TProductVariant,
  index: number,
  t: TFunction,
  onEditProductVariant: (productVariant: TProductVariant) => void,
  onChangeProductVariantStatus: (
    productVariant: TProductVariant,
    index: number,
  ) => void,
  canManageEntities: boolean,
) =>
  canManageEntities
    ? [
        {
          id: "edit-productVariant",
          label: t("editProductVariant", { ns: "administration" }),
          onClick: () => {
            onEditProductVariant(productVariant);
          },
          disabled: !productVariant.actions?.canEditEntity,
        },
        {
          id: "change-productVariant-status",
          label: t("changeProductVariantStatus", {
            ns: "administration",
          }),
          onClick: () => {
            onChangeProductVariantStatus(productVariant, index);
          },
          disabled: !productVariant.actions?.canChangeStatus,
        },
      ]
    : [];

export const getProductVariantsForRender = (
  productVariants: TProductVariant[],
  t: TFunction,
  onEditProductVariant: (productVariant: TProductVariant) => void,
  onChangeProductVariantStatus: (
    productVariant: TProductVariant,
    index: number,
  ) => void,
  canManageEntities: boolean,
): TProductVariantsRowData[] =>
  productVariants.map((productVariant, index) => ({
    [ProductVariantsTable.Id]: productVariant.id,
    [ProductVariantsTable.Type]: productVariant.productType.name,
    [ProductVariantsTable.RouteOfAdministration]:
      productVariant.routeOfAdministrationType.name,
    [ProductVariantsTable.ManufacturedDose]:
      productVariant.manufacturedDoseFormType.name,
    [ProductVariantsTable.Strength]: getProductStrength(productVariant) ?? "",
    [ProductVariantsTable.Status]: t(productVariant.status),
    [ProductVariantsTable.Actions]: (
      <ButtonMenu
        options={getProductVariantMenuOptions(
          productVariants,
          productVariant,
          index,
          t,
          onEditProductVariant,
          onChangeProductVariantStatus,
          canManageEntities,
        )}
      >
        <IconButton aria-label="actionsButton" disabled={!canManageEntities}>
          <MoreVertIcon />
        </IconButton>
      </ButtonMenu>
    ),
  }));

export const getProductStrength = (productVariant: TProductVariant): string => {
  return productVariant.strengthVolumeValue &&
    productVariant.strengthVolumeType?.code
    ? // eslint-disable-next-line max-len
      `${productVariant.strengthValue} ${productVariant.strengthType.code} / ${productVariant.strengthVolumeValue} ${productVariant.strengthVolumeType?.code}`
    : `${productVariant.strengthValue} ${productVariant.strengthType.code} `;
};
