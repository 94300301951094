import { z } from "zod";

export enum AnnotationFilterField {
  ShowPrivateComments = "showPrivateComments",
  ShowOnlyMyComments = "showOnlyMyComments",
  ShowTrackChanges = "showTrackChanges",
}

export type TAnnotationFilterForm = {
  [AnnotationFilterField.ShowPrivateComments]: boolean;
  [AnnotationFilterField.ShowOnlyMyComments]: boolean;
  [AnnotationFilterField.ShowTrackChanges]: boolean;
};

export const annotationFilterValues: TAnnotationFilterForm = {
  [AnnotationFilterField.ShowPrivateComments]: true,
  [AnnotationFilterField.ShowOnlyMyComments]: false,
  [AnnotationFilterField.ShowTrackChanges]: true,
};

export const annotationFilterSchema = () =>
  z.object({
    [AnnotationFilterField.ShowPrivateComments]: z.boolean(),
    [AnnotationFilterField.ShowOnlyMyComments]: z.boolean(),
    [AnnotationFilterField.ShowTrackChanges]: z.boolean(),
  });
