import { TFunction } from "i18next";
import { z } from "zod";
import { IDocumentOutline } from "models/documents.models";
import { DocumentStatus } from "utils/constants/doc.constants";
import { TFlattenedOutline } from "./BulkStatusChangeModal.utils";

export enum BulkStatusChangeForm {
  CurrentStatus = "currentStatus",
  NewStatus = "newStatus",
  ComponentIds = "componentIds",
}

export type TBulkStatusChangeModalProps = {
  isOpen: boolean;
  outline: IDocumentOutline[] | undefined;
  onClose: () => void;
};

export type TBulkStatusChangeForm = {
  [BulkStatusChangeForm.CurrentStatus]: DocumentStatus | "";
  [BulkStatusChangeForm.NewStatus]: DocumentStatus | "";
  [BulkStatusChangeForm.ComponentIds]: string[];
};

export type TGroupedOutline = {
  [key in DocumentStatus]: TFlattenedOutline[];
};

export const bulkStatusChangeSchema = (t: TFunction) =>
  z.object({
    [BulkStatusChangeForm.CurrentStatus]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [BulkStatusChangeForm.NewStatus]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [BulkStatusChangeForm.ComponentIds]: z.string().array().min(1),
  });

export const bulkStatusChangeDefaultValues: TBulkStatusChangeForm = {
  [BulkStatusChangeForm.CurrentStatus]: "",
  [BulkStatusChangeForm.NewStatus]: "",
  [BulkStatusChangeForm.ComponentIds]: [],
};
