import { Stack } from "@mui/material";
import { DocumentSubtype } from "utils/constants/doc.constants";
import { TDocumentsTeasersProps } from "../DocumentsTeasers.types";
import AAIDTeaser from "./AAIDTeaser";
import GSPTeaser from "./GSPTeaser";
import LOATeaser from "./LOATeaser";

type TOrbisDocumentsTeasersProps = Omit<
  TDocumentsTeasersProps,
  "onViewDocument"
>;

const OrbisDocumentsTeasers = ({
  onAddDocument,
}: TOrbisDocumentsTeasersProps) => {
  return (
    <Stack direction={"row"} spacing={2} marginBottom={2}>
      <GSPTeaser key={DocumentSubtype.GSP} />
      <LOATeaser key={DocumentSubtype.LOA} onAddDocument={onAddDocument} />
      <AAIDTeaser
        key={DocumentSubtype.AssessmentAid}
        onAddDocument={onAddDocument}
      />
    </Stack>
  );
};

export default OrbisDocumentsTeasers;
