import { IHAQWithContent } from "hooks/api/GQL/haq/useHAQsWithContent.models";
import { IAsset } from "models/assets.models";
import { TUserData } from "models/users.models";
import { TAssetWithContent } from "utils/helpers/mergeAssetWithContent";
import { TAtLeast } from "utils/types";

const getIsAssetCreatedByUser = (
  asset:
    | TAtLeast<
        IAsset<object> | IHAQWithContent | TAssetWithContent<object>,
        "createdBy"
      >
    | null
    | undefined,
  user: TUserData | undefined,
) =>
  Boolean(asset?.createdBy) &&
  Boolean(user?.email) &&
  asset?.createdBy === user?.email;

export default getIsAssetCreatedByUser;
