import COUNTRIES, { TCountry } from "utils/constants/countries.constants";

export const getHAName = (
  country: keyof TCountry,
  alias: string,
  t: (key: string, options: Record<string, string>) => string,
) => {
  const countryName = COUNTRIES[country]?.name;

  if (!countryName) {
    return country || alias;
  }

  return `${t(countryName, {
    ns: "countries",
  })} - ${alias}`;
};
