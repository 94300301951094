import { useTranslation } from "react-i18next";
import Button from "components/shared/Button";
import GenericFallback from "../GenericFallback";

export type TUserContextFallbackProps = {
  reason: string | undefined;
  onLogout: () => void;
  logoutTimeout?: number;
};

const UserContextFallback = ({
  reason,
  onLogout,
}: TUserContextFallbackProps) => {
  const { t } = useTranslation("user", {
    keyPrefix: "errorBoundaryFallbacks.userContext",
  });

  return (
    <GenericFallback
      message={t("dontHaveAccess", { reason: reason || t("unknownReason") })}
    >
      <Button onClick={onLogout} variant="contained">
        {t("loggedOut")}
      </Button>
    </GenericFallback>
  );
};

export default UserContextFallback;
