import { TFunction } from "i18next";
import { z } from "zod";
export enum UpdateTitleModalForm {
  NewTitle = "newTitle",
}

export const getUpdateTitleModalSchema = (t: TFunction) =>
  z.object({
    [UpdateTitleModalForm.NewTitle]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
  });
