import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import {
  IUserGroupData,
  IUserGroupsRequestParams,
} from "models/userGroup.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export default function useUserGroups(
  params: IUserGroupsRequestParams,
  options?: { enabled: boolean },
) {
  const { t } = useTranslation("user", {
    keyPrefix: "notifications",
  });

  const {
    data: memberGroups,
    status,
    error,
    isLoading,
    isError,
    isSuccess,
  } = useQuery<IUserGroupData[], Error>(
    [QueryAPIKey.GetUserGroups],
    () => services.user.getUserGroups(params).then((res) => res.data),
    options,
  );

  useShowAlert({
    status,
    failureMessage: t("getMemberGroupsFailure"),
  });

  return {
    memberGroups,
    isLoading,
    isError,
    isSuccess,
    error,
  };
}
