import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { getHAName } from "components/common/HealthAuthority/HealthAuthority.utils";
import Button from "components/shared/Button";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { useAlerts } from "context/AlertContext";
import { useProject } from "context/ProjectContext";
import useOrganizations from "hooks/api/REST/organizations/useOrganizations";
import useGetWorkflowExecution from "hooks/api/REST/workflow/useGetWorkflowExecution";
import useStartWorkflow from "hooks/api/REST/workflow/useStartWorkflow";
import { useHAQ } from "hooks/haq";
import useARNTenant from "hooks/useARNTenant";
import useUser from "hooks/useUser";
import {
  IWorkflowExecution,
  WorkflowExecutionStatus,
} from "models/workflow.models";
import { getWorkflowDetailsForSubmitToHA } from "screens/Project/screens/HAQ/HAQ.utils";
import { useHAQContext } from "screens/Project/screens/HAQ/HAQContext/HAQContextProvider";
import { AlertSeverity } from "utils/constants/alerts.constants";
import {
  DEFAULT_POLLING_INTERVAL,
  QueryAPIKey,
} from "utils/constants/api.constants";
import { AssetType, THAQAssetType } from "utils/constants/assets.constants";
import { DEFAULT_RELIANCE_HA_COLLAB_TENANT_ID } from "utils/constants/organization.constants";
import {
  checkIsRegulatoryReviewProject,
  checkIsRelianceProject,
  getHAQShortName,
} from "utils/helpers";
import getIsReferenceOrExternalHAQ from "utils/helpers/HAQ/getIsReferenceOrExternalHAQ";

export type TSubmitHAQResponseModalProps = {
  HAQId: string | undefined;
  HAQName: string;
  HAQType?: THAQAssetType | undefined;
  onClose: () => void;
  isOpen: boolean;
};

export const SubmitHAQResponse = ({
  HAQId,
  HAQName,
  onClose,
  isOpen,
  HAQType,
}: TSubmitHAQResponseModalProps) => {
  const { t } = useTranslation(["HAQ", "common", "countries"]);
  const { user } = useUser();

  const { startWorkflow } = useStartWorkflow();

  const { HAQ, HAQResponse } = useHAQ(HAQId);
  const { tenant } = useARNTenant(HAQ?.sourceArn);
  const { project } = useProject();

  const { showAlert } = useAlerts();

  const { organizations } = useOrganizations();

  const queryClient = useQueryClient();

  const handleClose = () => {
    onClose();
  };

  const isQuestionHAQ = HAQType === AssetType.Question;

  const [workflowInProgressId, setWorkflowInProgressId] = useState<
    string | null
  >(null);

  const { setIsAnyWorkflowInProgress } = useHAQContext();

  const { workflowId, assets, successMessage } =
    getWorkflowDetailsForSubmitToHA({ HAQ, HAQResponse, HAQType, project });

  const handleSubmitToHA = () => {
    if (HAQ?.source?.tenantId && user?.id && HAQ?.id && HAQResponse?.id) {
      startWorkflow(
        {
          id: workflowId,
          payload: {
            project: project?.id,
            originator: user.id,
            targetTenant: getIsReferenceOrExternalHAQ(HAQ?.metadata?.importType)
              ? // TODO: revisit solution for RR project when will implement one
                DEFAULT_RELIANCE_HA_COLLAB_TENANT_ID
              : HAQ.source.tenantId,
            assets,
          },
        },
        {
          onSuccess: (data) => {
            setWorkflowInProgressId(data?.data.id);
            setIsAnyWorkflowInProgress(!!data?.data?.id);
            handleClose();
          },
        },
      );
    }
  };

  useGetWorkflowExecution(workflowInProgressId ?? "", {
    enabled: Boolean(workflowInProgressId),
    refetchInterval: (data) => {
      return data?.status === WorkflowExecutionStatus.Complete
        ? false
        : DEFAULT_POLLING_INTERVAL;
    },
    onSuccess: (workflowInfoResponse: IWorkflowExecution) => {
      if (workflowInfoResponse?.status === WorkflowExecutionStatus.Complete) {
        showAlert({
          severity: AlertSeverity.Success,
          message: t(successMessage, {
            HAQName,
            ns: "notifications",
          }),
        });
        queryClient.invalidateQueries([QueryAPIKey.GetAsset]);
        queryClient.invalidateQueries([QueryAPIKey.GetAssetBulkShareHistory]);
        setWorkflowInProgressId(null);
        setIsAnyWorkflowInProgress(false);
      }
    },
  });

  const recipientsField = () =>
    checkIsRelianceProject(project)
      ? t("submitHAQResponse.relianceRecipients", { ns: "HAQ" })
      : checkIsRegulatoryReviewProject(project)
      ? t("submitHAQResponse.regReviewRecipients", { ns: "HAQ" })
      : tenant && getHAName(tenant.country, tenant.shortName || tenant.name, t);

  const cancelButton = (
    <Button
      key="cancel"
      data-qaid="submit-haq-cancel-button"
      variant="text"
      onClick={handleClose}
    >
      {t("button.cancel", { ns: "common" })}
    </Button>
  );

  const submitButton = (
    <Button
      key="submit"
      data-qaid="submit-haq-response-submit-button"
      variant="contained"
      disabled={false}
      onClick={handleSubmitToHA}
    >
      {t("button.submit", { ns: "common" })}
    </Button>
  );

  return (
    <Modal
      data-qaid="submit-haq-response-modal"
      open={isOpen}
      title={t("submitHAQResponse.title", { ns: "HAQ" })}
    >
      <Stack gap={3}>
        <Stack gap={1}>
          <Typography>
            {isQuestionHAQ
              ? t("submitHAQResponse.subtitleQuestionOnly", {
                  ns: "HAQ",
                })
              : t("submitHAQResponse.subtitle", {
                  ns: "HAQ",
                })}
          </Typography>

          <Typography>
            {t("submitHAQResponse.confirmation", {
              component: isQuestionHAQ
                ? t("HAQ:viewPage.component.QUESTION")
                : t("HAQ:viewPage.component.QUESTION_RESPONSE"),
              ns: "HAQ",
            })}
          </Typography>
        </Stack>
        <Divider />
        <Box>
          <Typography variant="body2" color="text.secondary">
            {t("submitHAQResponse.responseLabel", { ns: "HAQ" })}
          </Typography>
          {HAQ && (
            <Typography>
              {getHAQShortName(
                HAQ,
                t("HAQAcronym", { ns: "HAQ" }),
                organizations,
              )}
            </Typography>
          )}
        </Box>
        <Box>
          <Typography variant="body2" color="text.secondary">
            {t("submitHAQResponse.recipientsLabel", { ns: "HAQ" })}
          </Typography>
          <Typography>{recipientsField()}</Typography>
        </Box>
      </Stack>
      <ModalActionButtons buttons={[cancelButton, submitButton]} />
    </Modal>
  );
};
