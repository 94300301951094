import assets from "./assets";
import dataX from "./dataX";
import documents from "./documents";
import featureFlags from "./featureFlags";
import graphQL from "./graphQL";
import notifications from "./notifications";
import organizations from "./organizations";
import projectMembers from "./projectMembers";
import projects from "./projects";
import resources from "./resources";
import tasks from "./tasks";
import tracking from "./tracking";
import user from "./user";
import workflows from "./workflows";

export const services = {
  assets,
  dataX,
  documents,
  featureFlags,
  graphQL,
  notifications,
  organizations,
  projects,
  tasks,
  tracking,
  user,
  workflows,
  resources,
  projectMembers,
};
