import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, Stack } from "@mui/material";
import isEqual from "lodash/isEqual";
import { FormSwitch, FormTextInput } from "components/common/form-fields";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Loading from "components/shared/Loading";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import useUpdateAsset from "hooks/api/REST/assets/useUpdateAsset";
import { IDocument, IDocumentMetadata } from "models/documents.models";
import { useDocumentsContext } from "screens/Project/sections/Documents/DocumentsContext";
import { QueryAPIKey } from "utils/constants/api.constants";
import { DocumentAction } from "utils/constants/doc.constants";
import { LanguageField } from "../ImportDocument/components/fields";
import {
  getMetadataFormSchema,
  TUpdateDocumentMetadataForm,
  UpdateDocumentMetadataField,
} from "./UpdateDocumentMetadata.types";
import { getMetadataFormDefaultValues } from "./UpdateDocumentMetadata.utils";
import styles from "./UpdateDocumentMetadata.styles";

type TUpdateDocumentMetadataProps = {
  document: IDocument;
  onClose: () => void;
};

const UpdateDocumentMetadata = ({
  document,
  onClose,
}: TUpdateDocumentMetadataProps) => {
  const { t } = useTranslation(["documents", "common"]);

  const queryClient = useQueryClient();

  const { setCurrentActionOnDocument } = useDocumentsContext();

  const documentMetadata = useMemo(
    () => ({
      [UpdateDocumentMetadataField.Language]: document.metadata?.language || "",
      [UpdateDocumentMetadataField.LitigationHold]:
        document?.metadata?.litigationHold,
      [UpdateDocumentMetadataField.Author]: document?.metadata?.author || "",
      [UpdateDocumentMetadataField.Name]: document?.name || "",
    }),
    [document],
  );

  const metadataFormDefaultValues = useMemo(
    () => getMetadataFormDefaultValues(documentMetadata),
    [documentMetadata],
  );

  const [isMetadataChanged, setIsMetadataChanged] = useState<boolean>(false);

  const methods = useForm<TUpdateDocumentMetadataForm>({
    mode: "all",
    defaultValues: metadataFormDefaultValues,
    resolver: zodResolver(getMetadataFormSchema(t)),
  });

  const {
    getValues,
    watch,
    formState: { isValid },
    handleSubmit,
  } = methods;

  useEffect(() => {
    const subscription = watch(() =>
      setIsMetadataChanged(!isEqual(documentMetadata, getValues())),
    );

    return () => subscription.unsubscribe();
  }, [watch, getValues, documentMetadata]);

  const {
    mutateAsync: updateDocumentMetadata,
    isLoading: isDocumentMetadataUpdateInProgress,
  } = useUpdateAsset({
    failureMessage: t("notifications.updateContentMetadataFailure"),
    successMessage: t("notifications.updateContentMetadataSuccess"),
  });

  const onSubmit = useCallback(
    async (values: TUpdateDocumentMetadataForm) => {
      if (values) {
        const payload = {
          ...document,
          name: values.name,
          metadata: {
            ...document.metadata,
            language: values.language,
            author: values.author,
            litigationHold: values.litigationHold,
          },
        } as IDocument<IDocumentMetadata>;

        const response = await updateDocumentMetadata({
          data: payload,
          id: document.id,
        });

        if (response) {
          queryClient.invalidateQueries(QueryAPIKey.GetDocuments);
          setCurrentActionOnDocument({
            documents: [response as IDocument],
            documentAction: DocumentAction.ViewContentInfo,
          });
          onClose();
        }
      }
    },
    [
      updateDocumentMetadata,
      document,
      queryClient,
      onClose,
      setCurrentActionOnDocument,
    ],
  );

  const submitButton = (
    <Button
      key="submit-button"
      type="submit"
      variant="contained"
      disabled={
        isDocumentMetadataUpdateInProgress || !isMetadataChanged || !isValid
      }
    >
      {isDocumentMetadataUpdateInProgress ? (
        <Loading />
      ) : (
        t("button.submit", { ns: "common" })
      )}
    </Button>
  );

  const cancelButton = (
    <Button key="cancel-button" onClick={onClose}>
      {t("button.cancel", { ns: "common" })}
    </Button>
  );

  return (
    <Modal
      data-testid="update-document-metadata-modal"
      data-qaid="update-document-metadata-modal"
      open
      title={t("updateDocumentMetadata.title")}
      sx={styles.container}
    >
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2} justifyContent="flex-start">
          <Grid container spacing={2.3}>
            <Grid item xs={6}>
              <LanguageField
                fieldName={UpdateDocumentMetadataField.Language}
                defaultValue={documentMetadata.language}
              />
            </Grid>

            <Grid item xs={6}>
              <FormTextInput<TUpdateDocumentMetadataForm>
                name={UpdateDocumentMetadataField.Author}
                label={t("updateDocumentMetadata.fields.author.label")}
              />
            </Grid>

            <Grid item xs={12}>
              <FormTextInput<TUpdateDocumentMetadataForm>
                name={UpdateDocumentMetadataField.Name}
                label={t("updateDocumentMetadata.fields.name.label")}
              />
            </Grid>

            <Grid item xs={12}>
              <FormSwitch
                name={UpdateDocumentMetadataField.LitigationHold}
                label={t("updateDocumentMetadata.fields.litigationHold.label")}
              />
            </Grid>
          </Grid>
        </Stack>

        <ModalActionButtons buttons={[cancelButton, submitButton]} />
      </FormWrapper>
    </Modal>
  );
};

export default UpdateDocumentMetadata;
