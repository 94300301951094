import { IOrganization } from "models/organizations.models";
import { uniqueArray } from "../";

export const mapOrganizationsToSelectOptions = (
  organizations: IOrganization[],
) =>
  uniqueArray(
    organizations?.map((org: IOrganization) => ({
      label: org.name,
      value: org.tenantId || org.id,
    })),
    "label",
  );
