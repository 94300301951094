import { FHIRResourceType } from "utils/constants/fhir.constants";
import { dataXBasePart } from "./constants";

export default {
  byId: (resourceType: FHIRResourceType, id: string) =>
    `${dataXBasePart}/fhir/${resourceType}/${id}`,
  byType: (resourceType: FHIRResourceType) =>
    `${dataXBasePart}/fhir/${resourceType}`,
  add: (resourceType: FHIRResourceType) =>
    `${dataXBasePart}/fhir/${resourceType}`,
  portfolio: (id: string) => `${dataXBasePart}/portfolio/${id}`,
};
