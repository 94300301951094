import { TFunction } from "i18next";
import { TColumn } from "components/shared/Table/types";
import { IDocument } from "models/documents.models";
import { formatDate } from "utils/helpers";

export type TSubmissionHistoryDocsRowData = {
  id: string;
  submittedTo: string;
  dateSubmitted: string;
  status: "Accepted";
};

export const getColumns = (
  t: TFunction,
): TColumn<TSubmissionHistoryDocsRowData>[] => [
  {
    key: "submittedTo",
    content: t("documents.submissionHistory.submittedTo"),
  },
  {
    key: "dateSubmitted",
    content: t("documents.submissionHistory.dateSubmitted"),
  },
  {
    key: "status",
    content: t("documents.submissionHistory.status"),
  },
];

export const getDocumentSubmissionHistoryForRender = (
  documents: IDocument[],
  t: TFunction,
): TSubmissionHistoryDocsRowData[] =>
  documents?.map((document) => {
    return {
      id: document.id,
      submittedTo: t("documents.submissionHistory.submittedToReliance"),
      dateSubmitted: document.metadata?.submissionDate
        ? formatDate(document.metadata?.submissionDate)
        : "",
      status: t("documents.submissionHistory.submissionStatusReliance"),
    };
  }) || [];
