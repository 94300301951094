import { TFilteringParams } from "components/shared/types/filter";

const formatFilterParams = (params: TFilteringParams): Record<string, string> =>
  Object.entries(params).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(value?.length && { [key]: value.join() }),
    }),
    {},
  );

export default formatFilterParams;
