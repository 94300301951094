import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { Editor as CustomEditor } from "ckeditor";
import useUnlockAssetEditing from "hooks/api/REST/documents/useUnlockAssetEditing";
import useCreateNotification from "hooks/api/REST/notifications/useCreateNotification";
import useUser from "hooks/useUser";
import { NOTIFICATIONS_CURRENT_USER } from "utils/constants/api.constants";
import { TDocumentComponent } from "../Document.types";

type TDocumentContextProviderProps = {
  children: ReactNode;
};

type TDocumentContextValue = {
  editingComponent: {
    component: TDocumentComponent | null;
    setComponent: React.Dispatch<
      React.SetStateAction<TDocumentComponent | null>
    >;
    isContentChanged: boolean;
    setIsContentChanged: React.Dispatch<React.SetStateAction<boolean>>;
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    editor: CustomEditor | null;
    setEditor: React.Dispatch<CustomEditor | null>;
  };
  resetEditingComponent: () => void;
  toolbarRef: React.RefObject<HTMLDivElement>;
  handleEditModeTimeout: () => void;
  visibleEditorOrder: number;
  setVisibleEditorOrder: (order: number) => void;
};

const DocumentContext = createContext<TDocumentContextValue | undefined>(
  undefined,
);

const DocumentContextProvider = (props: TDocumentContextProviderProps) => {
  const { user } = useUser();
  const toolbarRef = useRef<HTMLDivElement>(null);
  const { unlockAssetEditing } = useUnlockAssetEditing();
  const { triggerCreateNotification } = useCreateNotification();

  const [component, setComponent] = useState<TDocumentComponent | null>(null);

  const [editor, setEditor] = useState<CustomEditor | null>(null);

  const [isContentChanged, setIsContentChanged] = useState<boolean>(false);

  const [isVisible, setIsVisible] = useState(false);

  const [visibleEditorOrder, setVisibleEditorOrder] = useState<number>(0);

  const resetEditingComponent = useCallback(() => {
    component?.assetId && unlockAssetEditing(component?.assetId);
    setComponent(null);
    setEditor(null);
    setIsContentChanged(false);
  }, [component?.assetId, unlockAssetEditing]);

  const handleEditModeTimeout = useCallback(() => {
    resetEditingComponent();

    // TODO: update content metadata once BE work is complete
    // Wire up callback to edit locking timeout
    const notificationItem = {
      createdBy: user?.email,
      createdDate: new Date().toISOString(),
      templateType: "UNDEFINED",
      metadata: {
        custom: "test content",
      },
    };

    triggerCreateNotification({
      userId: NOTIFICATIONS_CURRENT_USER,
      notification: notificationItem,
    });
  }, [resetEditingComponent, triggerCreateNotification, user?.email]);

  const contextValue: TDocumentContextValue = useMemo(
    () => ({
      editingComponent: {
        component,
        setComponent,
        isContentChanged,
        setIsContentChanged,
        isVisible,
        setIsVisible,
        setEditor,
        editor,
      },
      resetEditingComponent,
      toolbarRef,
      handleEditModeTimeout,
      visibleEditorOrder,
      setVisibleEditorOrder,
    }),
    [
      component,
      isContentChanged,
      isVisible,
      editor,
      resetEditingComponent,
      handleEditModeTimeout,
      visibleEditorOrder,
    ],
  );

  return (
    <DocumentContext.Provider value={contextValue}>
      {props.children}
    </DocumentContext.Provider>
  );
};

const useDocumentContext = (): TDocumentContextValue => {
  const context = useContext(DocumentContext);

  if (context === undefined) {
    throw new Error(
      "useDocumentContext must be used within a DocumentContextProvider",
    );
  }

  return context;
};

export { useDocumentContext };
export { DocumentContextProvider, DocumentContext };
export type { TDocumentContextValue };
