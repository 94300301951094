import { Stack, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import PortfolioCountryFlags from "screens/Portfolio/components/CountryFlag/CountryFlag";
import {
  TherapeuticTable,
  TTherapeuticRowData,
} from "./TherapeuticSummary.types";
import { filterByCountry } from "./TherapeuticSummaryHeader/TherapeuticSummaryHeader.utils";

const columnHelper = createColumnHelper<TTherapeuticRowData>();

export const getColumns = (t: TFunction) => [
  columnHelper.accessor(TherapeuticTable.ProprietaryName, {
    header: t("therapeutics.columns.proprietaryName"),
    enableSorting: true,
    cell: ({ cell: { getValue } }) => {
      return <Typography variant="body2">{getValue()}</Typography>;
    },
    meta: {
      width: "20%",
    },
  }),
  columnHelper.accessor(TherapeuticTable.Country, {
    header: t("therapeutics.columns.country"),
    enableSorting: true,
    enableColumnFilter: true,
    cell: ({ cell: { getValue } }) => {
      const value = getValue();
      const countries = value ? [value] : [];

      return <PortfolioCountryFlags countries={countries} includeCountryCode />;
    },
    meta: {
      width: "20%",
    },
    filterFn: filterByCountry,
  }),
  columnHelper.accessor(TherapeuticTable.Strength, {
    header: t("therapeutics.columns.strength"),
    enableSorting: true,
    enableColumnFilter: false,
    cell: ({ cell: { getValue } }) => {
      return (
        <Stack direction="column" spacing={1}>
          {getValue()?.map((entry) => (
            <Typography variant="body2" key={entry}>
              {entry}
            </Typography>
          ))}
        </Stack>
      );
    },
    meta: {
      width: "20%",
    },
  }),
  columnHelper.accessor(TherapeuticTable.DosageForm, {
    header: t("therapeutics.columns.dosageForm"),
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: "arrIncludesSome",
    meta: {
      width: "20%",
    },
  }),
  columnHelper.accessor(TherapeuticTable.RouteOfAdministration, {
    header: t("therapeutics.columns.routeOfAdministration"),
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: "arrIncludesSome",
    meta: {
      width: "20%",
    },
  }),
  columnHelper.accessor(TherapeuticTable.Status, {
    header: "",
    enableSorting: false,
    enableColumnFilter: false,
    meta: {
      width: "0%",
    },
  }),
];
