import { Controller, get, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormHelperText, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import SelectedFile from "components/shared/SelectedFile/SelectedFile";
import { TSelectedHAQTableRow } from "components/shared/types";
import useUser from "hooks/useUser";
import { getHAQShortName } from "utils/helpers";
import { isHAUser } from "utils/user/organization";
import styles from "./SelectedHAQs.styles";

type TSelectedHAQsProps = {
  fieldName: string;
};

const SelectedHAQs = ({ fieldName }: TSelectedHAQsProps) => {
  const { control, formState, setValue, watch } = useFormContext();

  const { t } = useTranslation(["HAQ", "common"]);

  // This is used for both HAQs and HAQRs, but the HAQ id and name represent both of them, so that's
  // how we're displaying and keeping track of them here. The form that uses this component will
  // ultimately decided whether to use the HAQs or HAQRs from it.
  const rows: TSelectedHAQTableRow[] = watch(fieldName);

  const handleHAQRemove = (id: string) => {
    const updatedRows = rows.filter((row) => row.HAQ.id !== id);
    setValue(fieldName, updatedRows, { shouldValidate: true });
  };

  const { organization } = useUser();
  const isHA = isHAUser(organization);

  const error = get(formState.errors, fieldName);

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{
        required: t("validations.requiredField", { ns: "common" }),
      }}
      render={() => {
        return (
          <>
            {!isEmpty(rows) ? (
              rows.map((row) => (
                <SelectedFile
                  key={row.HAQ.id}
                  fileName={getHAQShortName(row.HAQ, t("HAQAcronym"))}
                  onClear={() => handleHAQRemove(row.HAQ.id)}
                  clearIconAriaLabel={t("ariaLabels.clearIcon", {
                    ns: "common",
                    fileName: getHAQShortName(row.HAQ, t("HAQAcronym")),
                  })}
                />
              ))
            ) : (
              <Typography variant="body1">
                {t(
                  `${isHA ? "sendHAQCopy" : "submitHAQResponse"}.emptyMessage`,
                )}
              </Typography>
            )}
            {error && (
              <FormHelperText sx={styles.errorMessage}>
                {t(`${isHA ? "sendHAQCopy" : "submitHAQResponse"}.emptyError`)}
              </FormHelperText>
            )}
          </>
        );
      }}
    />
  );
};

export default SelectedHAQs;
