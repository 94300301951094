import { DocumentSubtype } from "./doc.constants";

export enum ShareHistoryItemType {
  Submission = "SUBMISSION",
  Share = "SHARE",
  Revoke = "REVOKED",
  Copy = "COPY",
}

export enum ShareHistoryItemStatus {
  Accepted = "ACCEPTED",
  Acknowledged = "ACKNOWLEDGED",
  AwaitingResponse = "AWAITING_RESPONSE",
  Copied = "COPIED",
  Shared = "SHARED",
  Rejected = "REJECTED",
  Sent = "SENT",
  Received = "RECEIVED",
}

export interface IShareHistoryItem {
  id: string;
  objectId: string;
  type: ShareHistoryItemType;
  tenantId: string;
  submissionDate: string;
  status: ShareHistoryItemStatus;
  responseDate: string;
  documentSubtype: DocumentSubtype;
}
