import { AxiosError, AxiosResponse } from "axios";
import { get, post, put, routes } from "api";
import {
  IGetNotificationsForUser,
  INotification,
} from "models/notificationItem.models";
import { TPaginationRequestParams } from "models/pagination.models";

const notifications = {
  markNotificationRead(notificationId: string, data: INotification) {
    return put(routes.notifications.notifications(notificationId), {
      data,
    });
  },
  getNotificationsForUser(
    userId: string,
    params: TPaginationRequestParams,
  ): Promise<AxiosResponse<IGetNotificationsForUser> | AxiosError> {
    return get(routes.notifications.getNotificationsForUser(userId), {
      params,
    });
  },
  markAllNotificationsRead(userId: string) {
    return post(routes.notifications.markAllNotificationsRead(userId));
  },
  createNotification(userId: string, data: INotification) {
    return post(routes.notifications.createNotification(userId), { data });
  },
};

export default notifications;
