import { getBorderStyle } from "theme/helpers";
import { TStylesObject } from "utils/Styles";

const styles: TStylesObject = {
  noData: {
    width: "100%",
    padding: 4,
    border: getBorderStyle("1px", "solid", "blueGrey.100"),
    borderRadius: "8px",
    textAlign: "center",
  },
};

export default styles;
