import { Theme } from "@mui/material";
import { isDue, isOverdue, isUpcoming } from "utils/helpers";

export const getTimeStampColors = (hoursUntilDue: number) => (theme: Theme) => {
  if (isUpcoming(hoursUntilDue)) {
    return theme.palette.timeStamp?.upcoming ?? {};
  }

  if (isDue(hoursUntilDue)) {
    return theme.palette.timeStamp?.due ?? {};
  }

  if (isOverdue(hoursUntilDue)) {
    return theme.palette.timeStamp?.overdue ?? {};
  }

  return theme.palette.timeStamp?.main ?? {};
};
