import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { DocumentsTable, TDocumentsRowData } from "./Documents.types";

const columnHelper = createColumnHelper<TDocumentsRowData>();

const getColumns = (t: TFunction) => [
  columnHelper.accessor(DocumentsTable.Name, {
    header: t("documents.columns.name"),
    cell: ({ row }) => row.original.name,
    enableSorting: false,
    meta: { width: "37%" },
  }),

  columnHelper.accessor(DocumentsTable.FileType, {
    header: t("documents.columns.type"),
    enableSorting: false,
    meta: { width: "8%" },
  }),

  columnHelper.accessor(DocumentsTable.Type, {
    header: t("documents.columns.subtype"),
    enableSorting: false,
    meta: { width: "15%" },
  }),

  columnHelper.accessor(DocumentsTable.Status, {
    header: t("documents.columns.status"),
    cell: ({ row }) => row.original.status,
    enableSorting: false,
    meta: { width: "9%" },
  }),

  columnHelper.accessor(DocumentsTable.Version, {
    header: t("documents.columns.version"),
    enableSorting: false,
    meta: { width: "7%" },
  }),

  columnHelper.accessor(DocumentsTable.LastModified, {
    header: t("documents.columns.lastModified"),
    enableSorting: true,
    meta: { width: "13%" },
  }),

  columnHelper.display({
    header: t("documents.columns.actions"),
    cell: ({ row }) => row.original.actions,
    meta: { SROnly: true },
  }),
];

export default getColumns;
