import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  dropdownHelper: {
    fontSize: "12px",
    opacity: "60%",
    mt: 0.5,
  },

  componentsInput: {
    width: "100%",
    borderRadius: "4px",
    border: "1px solid lightgrey",
    minHeight: "46px",
    padding: "6px",
    flexWrap: "wrap",
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
  },

  placeholder: {
    color: getPaletteColor("text.secondary"),
    pl: 1,
  },

  groupLabel: {
    textTransform: "uppercase",
    fontSize: 12,
    pl: 3,

    "&:last-of-type": {
      pt: 1,
      borderTop: getBorderStyle("1px", "solid", "grey.200"),
    },
  },

  membersDropdown: {
    "& .MuiChip-root": {
      width: "130px",
      backgroundColor: getPaletteColor("grey.200"),
      border: "none",
      p: 0.5,
      m: 0.5,
    },
  },

  sectionsSelect: {
    width: "535px",

    "& .MuiListItem-root": {
      pt: 0,
      pb: 0,
    },

    "& .MuiCollapse-root": {
      pt: 0,
      pb: 0,
    },

    "& .MuiInputBase-input": {
      width: 0,
    },
  },

  componentsMenu: {
    "& .MuiMenu-paper": {
      width: 520,
      maxHeight: 400,

      "& .MuiListItem-root": {
        py: 0,
      },
    },
  },
};

export default styles;
