import dayjs, { Dayjs } from "dayjs";
import {
  DEFAULT_DATE_FORMAT,
  ISO_DATE_FORMAT,
} from "utils/constants/format.constants";
import { TISODateFormat } from "utils/types";

//TS Function Overloads makes the return type dependent on the passed param type
//https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads

function formatDate(
  date: string | Date | Dayjs | null | undefined,
  format?: typeof ISO_DATE_FORMAT,
): TISODateFormat;

function formatDate(
  date: string | Date | Dayjs | null | undefined,
  format?: string,
): string;

function formatDate(
  date: string | Date | Dayjs | null | undefined,
  format: string = DEFAULT_DATE_FORMAT,
) {
  return date ? dayjs(date).format(format) : "";
}

export default formatDate;
