import { TStyles } from "utils/Styles";

const styles: TStyles = {
  addDocumentsButton: {
    alignSelf: "start",
    margin: "-8px 0 10px",
  },

  errorMessage: {
    color: "error.dark",
    ml: 1.75,
  },
};

export default styles;
