import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { IGetUsersListRequestParams, TUserData } from "models/users.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export default function useUsers(
  params?: IGetUsersListRequestParams,
  options?: { enabled: boolean },
) {
  const { t } = useTranslation("user", {
    keyPrefix: "notifications",
  });

  const {
    data: users,
    status,
    error,
    isLoading,
    isError,
    isSuccess,
  } = useQuery<TUserData[], Error>(
    [QueryAPIKey.GetUsersList],
    () => services.user.getUsersList(params).then((res) => res.data),
    options,
  );

  useShowAlert({
    status,
    failureMessage: t("getUsersListFailure"),
  });

  return {
    users,
    isLoading,
    isError,
    isSuccess,
    error,
  };
}
