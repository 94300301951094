import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TMenuOption } from "components/shared/ButtonMenu";
import useUser from "hooks/useUser";
import { TDocumentAction } from "screens/Project/sections/Documents/Document/Document.types";
import {
  DocumentAction,
  DocumentActionId,
  DocumentComponentStatus,
  DocumentStatus,
} from "utils/constants/doc.constants";
import { isHAUser } from "utils/user/organization";

export const useDocumentHeaderActions = (
  setActiveAction: (activeAction: TDocumentAction) => void,
  shouldShowSendCopyAction: boolean,
  documentStatus?: DocumentStatus | DocumentComponentStatus,
  isDocumentCleared?: boolean,
) => {
  const { organization } = useUser();

  const shouldShowHAQAction = isHAUser(organization);

  const { t } = useTranslation("documents", {
    keyPrefix: "documentHeader",
  });

  const actionOptions = useMemo<TMenuOption[]>(() => {
    const options = [
      {
        id: DocumentActionId.SetToDraft,
        label: t("actions.setToDraft"),
        onClick: () => {},
      },
      {
        id: DocumentActionId.StartSponsorReview,
        label: t("actions.startSponsorReview"),
        onClick: () =>
          setActiveAction({
            component: null,
            type: DocumentAction.StartSponsorReview,
          }),
      },
      {
        id: DocumentActionId.StartQC,
        label: t("actions.startQC"),
        onClick: () => {},
      },
      {
        id: DocumentActionId.SubmitToHA,
        label: t("actions.submitToHA"),
        onClick: () => {},
      },
    ];

    options.push({
      id: DocumentActionId.ChangeStatus,
      label: t("actions.changeStatus"),
      onClick: () =>
        setActiveAction({
          component: null,
          type: DocumentAction.ChangeStatus,
        }),
    });

    options.push({
      id: DocumentActionId.DeleteVersions,
      label: t("actions.deleteVersions"),
      onClick: () =>
        setActiveAction({
          component: null,
          type: DocumentAction.DeleteVersions,
        }),
    });

    if (shouldShowSendCopyAction) {
      options.push({
        id: DocumentActionId.SendCopy,
        label: t("actions.sendCopy"),
        onClick: () =>
          setActiveAction({
            component: null,
            type: DocumentAction.SendCopy,
          }),
      });
    }

    if (shouldShowHAQAction) {
      options.push({
        id: DocumentActionId.AddHAQ,
        label: String(t("actions.addHAQ")),
        onClick: () =>
          setActiveAction({
            component: null,
            type: DocumentAction.AddQuestion,
          }),
      });
    }

    if (documentStatus === DocumentStatus.Acknowledged) {
      const label = Boolean(isDocumentCleared)
        ? t("actions.unmarkCleared")
        : t("actions.markCleared");

      options.push({
        id: DocumentActionId.HandleClearedStatus,
        label,
        onClick: () => {},
      });
    }

    return options;
  }, [
    setActiveAction,
    shouldShowSendCopyAction,
    shouldShowHAQAction,
    t,
    documentStatus,
    isDocumentCleared,
  ]);

  return { actionOptions };
};
