import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { CKEditorContext } from "@ckeditor/ckeditor5-react";
import { Box, Stack, Typography } from "@mui/material";
import { ContextWatchdog } from "ckeditor5";
import {
  CommentsRepository,
  CommentThread,
  NarrowSidebar,
  WideSidebar,
} from "ckeditor5-premium-features";
import getEnv from "env";
import { Context as CustomEditorContext } from "ckeditor";
import AnnotationsFilters from "components/common/Editor/AnnotationFilters";
import AnnotationUIProvider, {
  useAnnotationUIContext,
} from "components/common/Editor/AnnotationUIContext/AnnotationUIContext";
import { Button } from "components/shared";
import useComments from "hooks/api/REST/documents/comments/useComments";
import useUser from "hooks/useUser";
import { ICommentThread } from "models/annotations.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import CollaborationContextPlugin from "./CollaborationContextPlugin";
import { PlusIcon } from "assets/icons";
import styles from "./StandaloneComments.styles";

class StandaloneCommentsContext extends CustomEditorContext {}

StandaloneCommentsContext.builtinPlugins = [
  NarrowSidebar,
  WideSidebar,
  CommentsRepository,
  CollaborationContextPlugin,
];

type TStandaloneCommentsProps = {
  assetId: string | undefined;
  targetRef: RefObject<HTMLElement>;
  getAllowResolving: (commentThread?: CommentThread) => boolean;
  allowCreateComments: boolean;
  isReadOnly: boolean;
  hideHeader?: boolean;
};

const StandaloneComments = ({
  assetId,
  targetRef,
  getAllowResolving,
  allowCreateComments,
  isReadOnly,
  hideHeader,
}: TStandaloneCommentsProps) => {
  const commentsAdapter = useComments();
  const { setAnnotationUI } = useAnnotationUIContext();
  const queryClient = useQueryClient();
  const { t } = useTranslation("documents");
  const { user } = useUser();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isSidebarMounted, setIsSidebarMounted] = useState(false);
  useEffect(() => setIsSidebarMounted(true), []);

  const [includeCommentPanePadding, setIncludeCommentPanePadding] =
    useState(false);

  const [ckeditorContext, setCkeditorContext] =
    useState<StandaloneCommentsContext | null>(null);

  const updateCommentThreadProps = useCallback(
    (fetchedThread: CommentThread) => {
      // CommentThread supports setting isResolvable, and it's included in the constructor type,
      // but for some reason it's not included in the definition of the class variables.
      (fetchedThread as any).isResolvable = getAllowResolving(fetchedThread);

      fetchedThread.comments.map((comment) => {
        comment.set({
          isEditable: !isReadOnly && user?.id === comment.author.id,
          isRemovable: !isReadOnly && user?.id === comment.author.id,
        });

        return comment;
      });
    },
    [getAllowResolving, isReadOnly, user?.id],
  );

  useEffect(() => {
    if (ckeditorContext) {
      const commentsRepository =
        ckeditorContext!.plugins.get("CommentsRepository");

      const threads = commentsRepository.getCommentThreads();

      commentsRepository.switchReadOnly(isReadOnly);
      threads.map((thread) => updateCommentThreadProps(thread));
    }
  }, [assetId, ckeditorContext, isReadOnly, updateCommentThreadProps]);

  async function initComments(context: StandaloneCommentsContext) {
    if (!assetId) {
      return;
    }

    const commentsRepository = context.plugins.get("CommentsRepository");
    commentsRepository.adapter = commentsAdapter(context);

    const { data: commentThreads } = await queryClient.fetchQuery<{
      data: ICommentThread[];
    }>([QueryAPIKey.GetCommentThreads, assetId], () =>
      services.assets.getCommentThreads(assetId),
    );

    // The threads from the above list fetch don't include the actual comments, so each thread needs
    // to be fetched individually.
    const fetchedThreads = await Promise.all(
      commentThreads.map((commentThread: ICommentThread) =>
        commentsRepository.fetchCommentThread({
          channelId: commentThread.assetId,
          threadId: commentThread.threadId,
        }),
      ),
    );

    fetchedThreads
      .filter((thread): thread is CommentThread => !!thread)
      // threads ids use an epoch timestamp, so a string compare sorts by created time
      .sort((threadA, threadB) => threadA.id.localeCompare(threadB.id))
      .forEach((fetchedThread) => {
        updateCommentThreadProps(fetchedThread);

        // We can't run the actual DOM manipulations in unit tests
        // istanbul ignore next
        if (fetchedThread?.comments.length) {
          fetchedThread?.attachTo(targetRef.current);
        }
      });

    // The styles for the CKEditor comment pane have some extra padding at the bottom so the last
    // comment doesn't run right up to the edge of the window, but for some reason the layout engine
    // CKEditor uses takes that into account and tries to spread the rest of the comments out by the
    // same amount. In order to avoid this behavior, we don't add that padding until after the
    // comments have loaded and animated in.
    setIncludeCommentPanePadding(true);
  }

  function initAnnotationsUIs(context: StandaloneCommentsContext) {
    // set AnnotationUIContext for controlling filters
    const annotationsUIs = context.plugins.get("AnnotationsUIs");

    // enable wide sidebar display mode for annotations (comments)
    annotationsUIs.switchTo("wideSidebar");

    setAnnotationUI(annotationsUIs);
  }

  const onReady = (context: StandaloneCommentsContext) => {
    initComments(context);
    initAnnotationsUIs(context);
    setCkeditorContext(context);
  };

  const addComment = () => {
    if (!assetId) {
      return;
    }

    // The context must have been initialized, otherwise the add button wouldn't be visible
    const commentsRepository =
      ckeditorContext!.plugins.get("CommentsRepository");

    const threadId = `${assetId}:${new Date().getTime()}`;

    commentsRepository.openNewCommentThread({
      channelId: assetId,
      threadId,
      target: () => targetRef.current,
      isResolvable: getAllowResolving(),
    });

    // scroll to the end of the comment area in case the new comment was created off-screen
    setTimeout(() => {
      sidebarRef.current?.scroll?.({
        top: sidebarRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 0);
  };

  const shouldShowAddCommentsButton = !isReadOnly && ckeditorContext && assetId;

  return (
    <Stack direction="column" sx={styles.standaloneComments} spacing={2}>
      <Stack direction="column" sx={styles.sidebarHeaderContainer} spacing={2}>
        {!hideHeader && (
          <Stack direction="row" sx={styles.sidebarHeader}>
            <Typography
              variant="subtitle2"
              component="h3"
              color="text.secondary"
            >
              {t("comments.comments")}
            </Typography>
            <AnnotationsFilters includeSuggestions={false} />
          </Stack>
        )}
        <CKEditorContext<StandaloneCommentsContext>
          context={StandaloneCommentsContext}
          contextWatchdog={ContextWatchdog}
          config={{
            licenseKey: getEnv().CKEditorKey,
            collaboration: { channelId: assetId },
            sidebar: {
              container: sidebarRef.current!,
              preventScrollOutOfView: true,
            },
            adapters: user ? { currentUser: user } : {},
          }}
          isLayoutReady={isSidebarMounted}
          onReady={onReady}
        >
          {shouldShowAddCommentsButton && (
            <Button
              startIcon={<PlusIcon />}
              variant="outlined"
              disabled={!allowCreateComments}
              onClick={addComment}
              data-qaid="add-comment-button"
            >
              {t("comments.addComment")}
            </Button>
          )}
        </CKEditorContext>
      </Stack>
      <Box
        ref={sidebarRef}
        sx={
          includeCommentPanePadding
            ? styles.commentContainerWithPadding
            : styles.commentContainer
        }
        data-testid="Annotations"
        data-qaid="Annotations"
      />
    </Stack>
  );
};

export default (props: TStandaloneCommentsProps) => (
  <AnnotationUIProvider>
    <StandaloneComments {...props} />
  </AnnotationUIProvider>
);
