import { TFunction } from "i18next";
import { z } from "zod";
import { getGenericFutureDateConstraint } from "utils/validators/constraints/validationConstraints";

export enum SendHAQCopyFieldName {
  DueDate = "dueDate",
  Instructions = "instructions",
}

export type TSendHAQCopyForm = {
  [SendHAQCopyFieldName.DueDate]: Date | null;
  [SendHAQCopyFieldName.Instructions]: string;
};

export const sendHAQCopyDefaultValues = {
  [SendHAQCopyFieldName.DueDate]: null,
  [SendHAQCopyFieldName.Instructions]: "",
};

export const getSendHAQCopySchema = (t: TFunction) =>
  z.object({
    [SendHAQCopyFieldName.DueDate]: getGenericFutureDateConstraint(t),
    [SendHAQCopyFieldName.Instructions]: z.string().optional(),
  });
