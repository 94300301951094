const omit = <T extends Record<string, any>, K extends keyof T>(
  obj: T,
  keys: Array<K>,
): Omit<T, K> => {
  // In order for the filter to work, the predicate has to be a proper type guard.
  const isNotExcluded = (key: any): key is Exclude<keyof T, K> =>
    !keys.includes(key);

  const objKeys: Array<keyof T> = Object.keys(obj);

  return objKeys
    .filter(isNotExcluded)
    .reduce<Omit<T, K>>((result, currentKey) => {
      result[currentKey] = obj[currentKey];

      return result;
    }, {} as any);
};

export default omit;
