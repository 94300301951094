import { IOrganization } from "models/organizations.models";
import { AssetStatus } from "utils/constants/assets.constants";
import { TPossibleAsset } from "utils/types";
import getIsEligibleToManipulateAsset from "../getIsEligibleToManipulateAsset";

const getCanDeleteAsset = (
  asset: TPossibleAsset,
  organization: IOrganization | undefined,
) =>
  getIsEligibleToManipulateAsset(asset, organization) &&
  asset?.status === AssetStatus.Draft;

export default getCanDeleteAsset;
