import { getBorderStyle } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  table: {
    border: getBorderStyle("4px", "solid", "grey.300"),
    borderTop: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,

    "& .MuiTableCell-head": {
      lineHeight: 1.43,
      py: 2.25,
    },
  },
};

export default styles;
