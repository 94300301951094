import { useContext } from "react";
import { FeatureFlagsContext, TFeatureFlagsValue } from "./FeatureFlagsContext";

const useFeatureFlags = (): TFeatureFlagsValue => {
  const context = useContext(FeatureFlagsContext);

  if (context === undefined) {
    throw new Error(
      "useFeatureFlags must be used within a FeatureFlagsProvider",
    );
  }

  return context;
};

export default useFeatureFlags;
