import { ReactNode } from "react";
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem as MuiMenuItem,
  MenuItemProps,
} from "@mui/material";
import Tooltip from "components/shared/Tooltip";
import { TMenuOption } from "./ButtonMenu";
import { ArrowRightIcon, CheckFilledIcon } from "assets/icons";
import styles from "./ButtonMenu.styles";

export type TMenuItemProps = Omit<
  MenuItemProps,
  "aria-label" | "aria-selected" | "value" | "onSelect"
> & {
  isSelected?: boolean;
  onClick?: (option: TMenuOption) => void;
  option: TMenuOption;
  disabled?: boolean;
  adornment?: ReactNode;
};

const MenuItem = ({
  disabled,
  option,
  dense,
  onClick,
  isSelected,
  adornment = null,
  sx,
}: TMenuItemProps) => {
  return (
    <Tooltip title={option?.additionalInfo} placement="left">
      <MuiMenuItem
        aria-label={option.label}
        aria-selected={Boolean(isSelected)}
        dense={dense}
        onClick={onClick}
        selected={Boolean(isSelected)}
        sx={sx}
        disabled={disabled}
        tabIndex={0}
      >
        {isSelected && (
          <ListItemIcon>
            <CheckFilledIcon fontSize="small" />
          </ListItemIcon>
        )}
        <ListItemText inset={typeof isSelected === "boolean" && !isSelected}>
          {option.label}
        </ListItemText>
        {adornment && (
          <Box
            onClick={(e) => disabled && e.stopPropagation()}
            sx={styles.adornment}
          >
            {adornment}
          </Box>
        )}
        {option.items && (
          <ListItemIcon>
            <ArrowRightIcon fontSize="small" />
          </ListItemIcon>
        )}
      </MuiMenuItem>
    </Tooltip>
  );
};

export default MenuItem;
