import { type CommentsAdapter } from "@ckeditor/ckeditor5-comments/src/comments/commentsrepository";
import { Context, Editor } from "ckeditor5";
import { TFunction } from "i18next";
import { TAlertContextValue } from "context/AlertContext";
import { services } from "services";

// Ideally in the future we would have a way to delete a thread itself, but for now the backend only
// supports deleting individual comments. We emulate deleting a thread by individually deleting all
// the comments in the thread, and only displaying threads that have comments in them.
const removeCommentThread =
  (
    _editorOrContext: Editor | Context,
    showAlert: TAlertContextValue["showAlert"],
    t: TFunction,
  ): CommentsAdapter["removeCommentThread"] =>
  async ({ channelId, threadId }) => {
    try {
      await services.documents.comments.deleteCommentThread(
        String(channelId),
        threadId,
      );
    } catch (error) {
      showAlert({
        message: t("deleteCommentThreadFailure"),
        severity: "error",
      });
      throw error;
    }
  };

export default removeCommentThread;
