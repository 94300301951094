import dayjs from "dayjs";

const compareDates = (
  dateA: string | null = "",
  dateB: string | null = "",
  reverse?: boolean,
) => {
  const dayjsDateA = dayjs(dateA);
  const dayjsDateB = dayjs(dateB);

  if (!dayjsDateB.isValid() && !dayjsDateA.isValid()) {
    return 0;
  }

  if (!dayjsDateB.isValid() && dayjsDateA.isValid()) {
    return -1;
  }

  if (dayjsDateB.isValid() && !dayjsDateA.isValid()) {
    return 1;
  }

  if (reverse) {
    return dayjsDateB.diff(dayjsDateA);
  }

  return dayjsDateA.diff(dayjsDateB);
};

export default compareDates;
