import { useTranslation } from "react-i18next";
import { Business as BusinessIcon } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { IGSPMetadataSponsorInfo } from "models/gsp.models";
import SectionTitle from "screens/Project/screens/GSP/GSPItems/components/SectionTitle";
import ItemRow from "./ItemRow";

type TSponsorInfoProps = {
  sponsorInfo?: IGSPMetadataSponsorInfo;
};

const SponsorInfo = ({ sponsorInfo }: TSponsorInfoProps) => {
  const { t } = useTranslation("documents", {
    keyPrefix: "gsp.item",
  });

  return (
    <Box py={2} pl={1}>
      <SectionTitle Icon={BusinessIcon} title={t("sections.sponsorInfo")} />
      <Grid container spacing={1.6} p={2.5}>
        <ItemRow
          name={t("fields.companyName.label")}
          description={sponsorInfo?.companyName}
        />
        <ItemRow
          name={t("fields.contactName.label")}
          description={sponsorInfo?.contactName}
        />
        <ItemRow
          name={t("fields.contactEmail.label")}
          description={sponsorInfo?.contactEmail}
        />
        <ItemRow
          name={t("fields.contactPhone.label")}
          description={sponsorInfo?.phoneNumber}
        />
      </Grid>
    </Box>
  );
};

export default SponsorInfo;
