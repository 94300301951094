import { Stack } from "@mui/material";
import ItemLayout from "screens/Project/screens/GSP/GSPItems/components/ItemLayout/ItemLayout";
import { GSPSummaryId } from "screens/Project/screens/GSP/GSPItems/GSPFormItem/GSPForm.utils";
import { TDossierDifferencesModule } from "screens/Project/screens/GSP/GSPItems/GSPItems.utils";
import { useDossierDifferenceModal } from "../DossierDifferenceModal/context";
import { DossierDifferencesModule, EmptyState } from "./components";
import styles from "./GSPSummaryItem.styles";

type TGSPSummaryItemProps = {
  isEditMode: boolean;
  dossierDifferencesModules: TDossierDifferencesModule[];
};

const GSPSummaryItem = ({
  isEditMode,
  dossierDifferencesModules,
}: TGSPSummaryItemProps) => {
  const { onOpen } = useDossierDifferenceModal();

  return (
    <ItemLayout
      id={GSPSummaryId}
      onOpenDossierDifferenceModal={isEditMode ? onOpen : undefined}
    >
      {dossierDifferencesModules.length ? (
        <Stack sx={styles.fixedHeightContainer} spacing={2.5}>
          {dossierDifferencesModules.map((module) => (
            <DossierDifferencesModule
              module={module}
              isEditable={isEditMode}
              key={module.id}
              parentId={GSPSummaryId}
            />
          ))}
        </Stack>
      ) : (
        <EmptyState isEditMode={isEditMode} />
      )}
    </ItemLayout>
  );
};

export default GSPSummaryItem;
