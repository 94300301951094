import { SetStateAction, useCallback, useMemo, useState } from "react";
import { TSortOrder } from "components/shared/Table";

export type TColumnSort<T extends object> = {
  id: Extract<keyof T, string>;
  desc: boolean;
};

export type TSortingState<T extends object> = TColumnSort<T>[];

export type TSortingParams<T extends object> = {
  sort?: `${Extract<keyof T, string>},${TSortOrder}`;
};

type TUseManualSortingResult<T extends object> = {
  sorting: TSortingState<T>;
  sortingParams: TSortingParams<T>;
  setSorting: (updaterOrValue: SetStateAction<TSortingState<T>>) => void;
};

const fromBoolean = (isDesc: boolean): TSortOrder => {
  return isDesc ? "desc" : "asc";
};

const useManualSorting = <T extends object>(
  initialSorting: TSortingState<T>,
  resetPagination?: () => void,
): TUseManualSortingResult<T> => {
  const [sortingState, setSortingState] =
    useState<TSortingState<T>>(initialSorting);

  const sortingParams = useMemo<TSortingParams<T>>(
    () => ({
      sort: sortingState
        .map(({ id, desc }) => `${id},${fromBoolean(desc)}` as const)
        // multi-sorting is not supported
        .at(0),
    }),
    [sortingState],
  );

  const setSorting = useCallback(
    (updaterOrValue: SetStateAction<TSortingState<T>>) => {
      setSortingState(updaterOrValue);
      resetPagination?.();
    },
    [resetPagination],
  );

  return {
    sorting: sortingState,
    sortingParams,
    setSorting,
  };
};

export default useManualSorting;
