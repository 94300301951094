import { useParams } from "react-router-dom";
import useFHIRResource from "hooks/api/REST/dataX/useFHIRResource";
import { TFHIRResource } from "models/fhir.models";
import { FHIRResourceType } from "utils/constants/fhir.constants";
import FHIRContent from "./FHIRContent";

const FHIRResource = () => {
  const { FHIRId, resourceType } = useParams<{
    id: string;
    resourceType: FHIRResourceType;
    FHIRId: string;
  }>();

  const { data: FHIRData } = useFHIRResource(
    resourceType ?? ("" as FHIRResourceType),
    FHIRId || "",
    {
      enabled: Boolean(resourceType && FHIRId),
    },
  );

  return <FHIRContent FHIRData={FHIRData as TFHIRResource} />;
};

export default FHIRResource;
