import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  textCenter: {
    textAlign: "center",
  },

  successIcon: {
    color: getPaletteColor("green.500"),
  },

  errorText: {
    color: getPaletteColor("red.700"),
  },

  viewButton: {
    padding: "3px 8px",
  },
};

export default styles;
