import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { ICreateCommentRequestData } from "models/annotations.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export type TCommentForCreate = {
  componentId: string;
  threadId: string;
  requestData: ICreateCommentRequestData;
};

const useAddComment = () => {
  const queryClient = useQueryClient();

  const { t } = useTranslation("annotations", { keyPrefix: "notifications" });

  const addCommentMutation = useMutation(
    ["addComment"],
    (mutationData: TCommentForCreate) => {
      const { componentId, threadId, requestData } = mutationData;

      return services.documents.comments.createComment(
        componentId,
        threadId,
        requestData,
      );
    },
    {
      onSuccess: (data, { threadId }) => {
        queryClient.invalidateQueries([QueryAPIKey.GetCommentThread, threadId]);
      },
    },
  );

  useShowAlert({
    status: addCommentMutation.status,
    failureMessage: t("createCommentFailure"),
  });

  return addCommentMutation;
};

export default useAddComment;
