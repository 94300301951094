import { PropsWithChildren } from "react";
import { DialogActions, SxProps, Theme } from "@mui/material";
import styles from "./ModalActionButtons.styles";

const ModalActionButtons = ({
  buttons,
  children,
  sx,
}: PropsWithChildren<{ buttons?: React.ReactNode[]; sx?: SxProps<Theme> }>) => (
  <DialogActions sx={{ ...styles.MuiDialogActions, ...sx } as SxProps}>
    {buttons || children}
  </DialogActions>
);

export default ModalActionButtons;
