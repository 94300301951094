import { useTranslation } from "react-i18next";
import { LayoutError } from "utils/helpers";
import GenericFallback from "../GenericFallback";

const MainLayoutFallback = ({ error }: { error: unknown }) => {
  const { t } = useTranslation("errors", { useSuspense: false });

  return error instanceof LayoutError ? (
    <GenericFallback
      message={
        error?.messageTranslationKey
          ? t(error?.messageTranslationKey)
          : error.message
      }
    />
  ) : (
    <></>
  );
};

export default MainLayoutFallback;
