import { useCallback, useMemo, useState } from "react";
import {
  TFilteringParams,
  TFiltersUpdater,
} from "components/shared/types/filter";

const useReactTableFilters = (
  setGlobalFilter: (text: string) => void,
  setAllFilters: (updater: TFiltersUpdater) => void,
) => {
  const [filterParams, setFilterParams] = useState<TFilteringParams>({});

  const [keywordParam, setKeywordParam] = useState("");

  const onKeywordChange = useCallback(
    (text: string) => {
      setGlobalFilter(text);
      setKeywordParam(text);
    },
    [setGlobalFilter, setKeywordParam],
  );

  const getFiltersForReactTable = useCallback((filters: TFilteringParams) => {
    return Object.keys(filters).map((filterName) => ({
      id: filterName,
      value: filters[filterName],
    }));
  }, []);

  const onFiltersChange = useCallback(
    (props: TFilteringParams) => {
      setFilterParams(props);
      setAllFilters(getFiltersForReactTable(props));
    },
    [setFilterParams, setAllFilters, getFiltersForReactTable],
  );

  return useMemo(
    () => ({
      filterParams,
      keywordParam,
      onFiltersChange,
      onKeywordChange,
    }),
    [filterParams, keywordParam, onFiltersChange, onKeywordChange],
  );
};

export default useReactTableFilters;
