import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  radioTileGroup: {
    width: "100%",
  },

  formLabel: {
    mb: "15px",
    color: getPaletteColor("text.primary"),
  },

  radioGroup: {
    px: "13px",
    display: "flex",
    flexDirection: "row",
    gap: "30px",
    justifyContent: "space-around",
  },
};

export default styles;
