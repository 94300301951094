import { useTranslation } from "react-i18next";
import { Query, QueryKey, useQuery } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { IParseJob } from "models/assets.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

type TOptions = {
  refetchInterval?:
    | number
    | ((
        data: IParseJob | undefined,
        refetchInterval?: Query<IParseJob, Error, IParseJob, QueryKey>,
      ) => number | false);
  onSuccess?: (data: IParseJob | undefined) => void;
  onError?: () => void;
};

export default function useParseJob(id?: string, options?: TOptions) {
  const { t } = useTranslation("documents", {
    keyPrefix: "notifications",
  });

  const { data, status, error, isLoading, isError, isSuccess } = useQuery<
    IParseJob,
    Error
  >(
    [QueryAPIKey.GetParseJob, id],
    () => services.assets.getParseJob(id || "").then((res) => res.data),
    {
      enabled: !!id,
      ...options,
    },
  );

  useShowAlert({
    status,
    failureMessage: t("getParsingJobFailure"),
  });

  return {
    parseJob: data,
    isLoading,
    isError,
    isSuccess,
    error,
  };
}
