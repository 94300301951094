import { useEffect, useMemo } from "react";
import { FieldValues, useFormContext } from "react-hook-form";
import {
  FormMultiSelectTypeahead,
  TFormMultiSelectTypeaheadProps,
} from "components/common/form-fields";
import { ICheckboxOption } from "components/shared/CheckboxTree/CheckboxTree.utils";
import {
  TCTOCDocumentModule,
  TCTOCItem,
} from "utils/constants/CTOCDocument/CTOCDocument";

export type TDocumentSectionsSelectProps<FormType extends FieldValues> = Omit<
  TFormMultiSelectTypeaheadProps<FormType, string>,
  "options" | "getValue"
> & {
  dropdownOptions: TCTOCDocumentModule[];
};

const mapCTOCToDocumentForMultiSelect = (
  dropdownOptions: TCTOCDocumentModule[] | TCTOCItem[],
): ICheckboxOption[] => {
  return (
    dropdownOptions?.map((document) => {
      return {
        id: document.id,
        ...(!document.name.includes("Module") && {
          value: document.name,
        }),
        label: document.name,
        ...(document?.children?.length && {
          children: mapCTOCToDocumentForMultiSelect(document.children),
        }),
      } as ICheckboxOption;
    }) || []
  );
};

function CTOCDocumentSelect<FormType extends FieldValues>({
  dropdownOptions,
  name,
  ...props
}: TDocumentSectionsSelectProps<FormType>) {
  const { unregister } = useFormContext();

  const options = useMemo(
    () => mapCTOCToDocumentForMultiSelect(dropdownOptions),
    [dropdownOptions],
  );

  useEffect(() => () => unregister(name), [unregister, name]);

  return (
    <FormMultiSelectTypeahead<FormType, string>
      name={name}
      options={options}
      {...props}
    />
  );
}

export default CTOCDocumentSelect;
