import { Box } from "@mui/material";
import Chip, { TChipProps } from "components/shared/Chip";
import { omit } from "utils/helpers";
import styles from "./StatusChip.styles";

export type TStatusChipProps = {
  status: string | null;
  isIconVisible?: boolean;
  chipProps?: TChipProps;
};

const StatusChip = (props: TStatusChipProps) => {
  if (!props.status || !props.chipProps) {
    return <Box sx={styles.noStatus}>&mdash;</Box>;
  }

  return (
    <Chip
      data-qaid={props.status}
      size="small"
      sx={styles.chip}
      variant="outlined"
      {...(props.isIconVisible
        ? props.chipProps
        : omit(props.chipProps, ["icon"]))}
    />
  );
};

export default StatusChip;
