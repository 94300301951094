import { TStyles } from "utils/Styles";

const styles: TStyles = {
  root: {
    "& .MuiTableRow-root": {
      border: "1px solid transparent",
      // This is needed in order to show accessibility focus outline styles
    },

    "& .MuiTableCell-head": {
      color: "text.secondary",
      fontWeight: 400,
      lineHeight: 1.43,
      py: 1,
    },

    "& .MuiTablePagination-selectLabel": {
      color: "text.primary",
    },

    "& .MuiTableSortLabel-root": {
      verticalAlign: "initial",
    },
  },

  bodyRow: {
    "& .MuiButtonBase-root.MuiIconButton-root": {
      my: -1.5,
    },
  },

  bodyRowAlignTop: {
    "& .MuiTableRow-root": {
      verticalAlign: "top",
    },
  },

  bodyRowAlignMiddle: {
    "& .MuiTableRow-root": {
      verticalAlign: "middle",
    },
  },

  bodyRowAlignBottom: {
    "& .MuiTableRow-root": {
      verticalAlign: "bottom",
    },
  },

  headless: {
    "& .MuiTableBody-root .MuiTableRow-root:first-of-type": {
      boxShadow: "none",
    },
  },

  tableCellDisabled: {
    pointerEvents: "none",
    color: "text.disabled",
  },
};

export default styles;
