import { useMemo } from "react";
import useProjectData from "hooks/api/GQL/project/useProjectData";
import { useUserRoles } from "hooks/user";
import useUser from "hooks/useUser";
import { TUserPermissions } from "./usePermissions.types";
import {
  deriveDeleteContentToTenancyPermission,
  deriveEditContentInAllProjectsPermission,
  deriveEditContentMetadataPermission,
  deriveEditContentPermission,
  deriveSendContentToTenancyPermission,
  deriveUploadContentPermission,
  deriveUploadContentToAllProjectsPermission,
  deriveViewContentInAllProjectsPermission,
  deriveViewContentPermission,
} from "./utils/contentHandlingPermissions";
import {
  deriveChangeQuestionOwnedStatusPermission,
  deriveChangeQuestionResponseStatusPermission,
  deriveChangeQuestionStatusPermission,
  deriveChangeReferenceOrExternalQuestionStatusPermission,
  deriveCreateCommentsOwnedAssetPermission,
  deriveCreateCommentsPermission,
  deriveCreateQuestionPermission,
  deriveCreateQuestionResponsePermission,
  deriveDeleteQuestionPermission,
  deriveEditQuestionOwnedPermission,
  deriveEditQuestionPermission,
  deriveEditQuestionResponsePermission,
  deriveEditReferenceOrExternalQuestionPermission,
  deriveExportQuestionAndResponsePermission,
  deriveImportReferenceOrExternalQuestionPermission,
  deriveResolveCommentsPermission,
  deriveSendCopyQuestionPermission,
  deriveSubmitQuestionResponsePermission,
  deriveSubmitReferenceOrExternalQuestionPermission,
  deriveViewQuestionAndResponsePermission,
} from "./utils/HAQPermissions";
import {
  deriveManageProjectInvitationsPermission,
  deriveSendHAInvitationPermission,
} from "./utils/invitationsPermissions";
import { deriveCreateMilestonePermission } from "./utils/milestonesPermissions";
import {
  deriveProjectConfiguration,
  TProjectConfiguration,
} from "./utils/projectConfiguration";
import {
  deriveAssignUserAccountRoleTypePermission,
  deriveAssignUserAccountToProjectPermission,
  deriveChangeProjectMembersStatusPermission,
  deriveChangeProjectStatusPermission,
  deriveCreateNewProjectPermission,
  deriveEditParticipationTypePermission,
  deriveEditProjectInformationPermission,
  deriveEditProjectMembersPermission,
  deriveRemoveProjectMembersPermission,
  deriveViewAllProjectsPermission,
} from "./utils/projectManagementPermissions";
import {
  deriveAssignTasksToIndividualsPermission,
  deriveCanAddTaskAssigneesPermission,
  deriveCancelTaskPermission,
  deriveCancelTasksInAssociatedProjectsPermission,
  deriveCancelTasksPermission_deprecated,
  deriveCanReassignTaskAssigneesPermission,
  deriveCanRemoveTaskAssigneesPermission,
  deriveCompleteTaskPermission,
  deriveForceCompleteionTaskPermission,
  deriveReassignTaskPermission,
  deriveUpdateTaskDueDatePermission,
  deriveUpdateTaskDueDatePermission_deprecated,
  deriveViewAllTasksPermission,
  deriveViewAssignToAllParentTaskPermission,
} from "./utils/projectTasksPermissions";
import {
  deriveChangeStateContentInAssociatedProjectsPermission,
  deriveChangeStateContentPermission,
  deriveReceiveAndCompleteAcknowledgeReceiptPermission,
  deriveStartStructuredAndUnstructuredContentWorkflowPermission,
  deriveUpdateDueDatesPermission,
} from "./utils/workflowManagementPermissions";

export type TPermissions = TUserPermissions & TProjectConfiguration;

type TUsePermissions = {
  permissions: TPermissions;
};

export const usePermissions = (projectId: string): TUsePermissions => {
  const { data: project } = useProjectData(projectId);
  const { organization, user, isGlobalPM } = useUser();
  const userRoles = useUserRoles(projectId);

  const props = useMemo(
    () => ({
      project,
      organization,
      user,
      isGlobalPM,
      userRoles,
    }),
    [project, organization, user, isGlobalPM, userRoles],
  );

  const permissions = useMemo(() => {
    return {
      // ----------- Project Management -----------

      // View all projects (in organization's tenancy)
      canViewAllProjects: deriveViewAllProjectsPermission(props),
      // Assign user account to associated project
      canAssignUserAccountToProject:
        deriveAssignUserAccountToProjectPermission(props),
      // Assign user account to a role type in associated projects
      canAssignUserAccountRoleType:
        deriveAssignUserAccountRoleTypePermission(props),
      // Edit user accounts in associated projects
      canEditProjectMembers: deriveEditProjectMembersPermission(props),
      // Remove user accounts from associated projects
      canRemoveProjectMembers: deriveRemoveProjectMembersPermission(props),
      // Inactivate/reactivate users in associated project
      canChangeProjectMembersStatus:
        deriveChangeProjectMembersStatusPermission(props),
      // Create new project
      canCreateNewProject: deriveCreateNewProjectPermission(props),
      // Change Project status (based on rules - see Project Lifecycle diagram for states)
      canChangeProjectStatus: deriveChangeProjectStatusPermission(props),
      // Can edit project information
      canEditProjectInformation: deriveEditProjectInformationPermission(props),
      canEditParticipationType: deriveEditParticipationTypePermission(props),

      // ----------- Content Creation and Management -----------

      // Can view content in all projects
      canViewContentInAllProjects:
        deriveViewContentInAllProjectsPermission(props),
      // Can view content of associated projects
      canViewContent: deriveViewContentPermission(props),
      // Can upload content to all projects
      canUploadContentToAllProjects:
        deriveUploadContentToAllProjectsPermission(props),
      // Can upload content to associated project
      canUploadContent: deriveUploadContentPermission(props),
      // Can edit content in all projects
      canEditContentInAllProjects:
        deriveEditContentInAllProjectsPermission(props),
      // Can edit content in associated projects
      canEditContent: deriveEditContentPermission(props),
      // Can edit content information (metadata) in associated projects
      canEditContentMetadata: deriveEditContentMetadataPermission(props),
      // Can send content to tenancy (initiate cross-tenant workflow) for associated projects
      canSendContentToTenancy: deriveSendContentToTenancyPermission(props),
      // Can delete content (according to lifecycle rules) for associated projects
      canDeleteContentToTenancy: deriveDeleteContentToTenancyPermission(props),

      // ----------- Health Authority Questions -----------

      // Can delete a question in associated projects
      canDeleteQuestion: deriveDeleteQuestionPermission(props),
      // Can author or edit a question in associated projects
      canCreateQuestion: deriveCreateQuestionPermission(props),
      // Can edit a question owned by them in associated projects
      canEditQuestionOwned: deriveEditQuestionOwnedPermission(props),
      // Can edit a question in associated projects
      canEditQuestion: deriveEditQuestionPermission(props),
      // Can author or edit a response in associated projects (based on lifecycle state)
      canCreateQuestionResponse: deriveCreateQuestionResponsePermission(props),
      // Can edit a response in associated projects (based on lifecycle state)
      canEditQuestionResponse: deriveEditQuestionResponsePermission(props),
      // Can manually change the status of Question owned by them
      canChangeQuestionOwnedStatus:
        deriveChangeQuestionOwnedStatusPermission(props),
      // Can manually change the status of Question owned by their organization (based on lifecycle state) in associated projects
      canChangeQuestionStatus: deriveChangeQuestionStatusPermission(props),
      // Can manually change the status of Response owned by their organization (based on lifecycle state) in associated projects
      canChangeQuestionResponseStatus:
        deriveChangeQuestionResponseStatusPermission(props),
      // Can import Reference or External questions in associated projects
      canImportReferenceOrExternalQuestion:
        deriveImportReferenceOrExternalQuestionPermission(props),
      // Can edit a Reference or External question in associated projects (based on lifecycle state)
      canEditReferenceOrExternalQuestion:
        deriveEditReferenceOrExternalQuestionPermission(props),
      // Can manually change the status of Reference or External question in associated projects (based on lifecycle state)
      canChangeReferenceOrExternalQuestionStatus:
        deriveChangeReferenceOrExternalQuestionStatusPermission(props),
      // Can send copy of a question to a sponsor in associated projects
      canSendCopyQuestion: deriveSendCopyQuestionPermission(props),
      // Can submit a response to a question in associated projects
      canSubmitQuestionResponse: deriveSubmitQuestionResponsePermission(props),
      // Can submit a Reference or External question (sponsor only) in associated projects
      canSubmitReferenceOrExternalQuestion:
        deriveSubmitReferenceOrExternalQuestionPermission(props),
      // Can view project related questions and answers (in users tenant) in associated projects
      canViewQuestionAndResponse:
        deriveViewQuestionAndResponsePermission(props),
      // Can export questions and responses in associated projects
      canExportQuestionAndResponse:
        deriveExportQuestionAndResponsePermission(props),
      canCreateCommentsOwnedAsset:
        deriveCreateCommentsOwnedAssetPermission(props),
      // Can create comments (in users tenant)
      canCreateComments: deriveCreateCommentsPermission(props),
      // Can resolve comments (author organization only)
      canResolveComments: deriveResolveCommentsPermission(props),

      // ----------- Project Tasks -----------

      // Can assign tasks to individuals (within same tenant) in associated projects
      canAssignTasksToIndividuals:
        deriveAssignTasksToIndividualsPermission(props),
      // Can complete tasks
      canCompleteTask: deriveCompleteTaskPermission(props),
      // Can cancel tasks for other users in associated projects
      canCancelTasksInAssociatedProjects:
        deriveCancelTasksInAssociatedProjectsPermission(props),
      // Can cancel tasks they created for other users
      canCancelTasks_deprecated: deriveCancelTasksPermission_deprecated(props),
      // Can cancel tasks they created for other users
      canCancelTask: deriveCancelTaskPermission(props),
      // Can force a task to completion
      canForceCompletionTask: deriveForceCompleteionTaskPermission(props),
      // Can update task due date (deprecated)
      canUpdateTaskDueDate_deprecated:
        deriveUpdateTaskDueDatePermission_deprecated(props),
      // Can update task due date
      canUpdateTaskDueDate: deriveUpdateTaskDueDatePermission(props),
      // Can reassign a task to another user (within the same tenant)
      canReassignTask: deriveReassignTaskPermission(props),
      // Can add task assignees
      canAddTaskAssignees: deriveCanAddTaskAssigneesPermission(props),
      // Can reassign task assignees
      canReassignTaskAssignees: deriveCanReassignTaskAssigneesPermission(props),
      // Can remove task assignees
      canRemoveTaskAssignees: deriveCanRemoveTaskAssigneesPermission(props),
      // Can see "All Tasks" tab
      canViewAllTasksTab: deriveViewAllTasksPermission(props),
      // Can see "Assign To All" parent task drawer
      canViewAssignToAllParentTask:
        deriveViewAssignToAllParentTaskPermission(props),

      // ----------- Workflow Management -----------

      // Can manually change the state of content owned by their organization (based on lifecycle state) in associated projects
      canChangeStateContent: deriveChangeStateContentPermission(props),
      // Can manually change the state of content created by other users, owned by their organization (based on lifecycle state)
      // in associated projects
      canChangeStateContentInAssociatedProjects:
        deriveChangeStateContentInAssociatedProjectsPermission(props),
      // Can initiate cross-tenant workflows for structured and unstructured content
      // owned by their organization (based on lifecycle state) in associated projects
      canStartStructuredAndUnstructuredContentWorkflow:
        deriveStartStructuredAndUnstructuredContentWorkflowPermission(props),
      // Can receive and complete cross-tenant workflow tasks to Acknowledge Receipt of content in associated projects
      canReceiveAndCompleteAcknowledgeReceipt:
        deriveReceiveAndCompleteAcknowledgeReceiptPermission(props),
      // Update due dates
      canUpdateDueDates: deriveUpdateDueDatesPermission(props),

      // ----------- Invitations -----------

      // Can Send HA invitation (HA Only)
      canSendHAInvitation: deriveSendHAInvitationPermission(props),
      // Can create/update/view project invitations (Sponsor PMs only)
      canManageProjectInvitations:
        deriveManageProjectInvitationsPermission(props),

      // ----------- Project Configuration -----------

      ...deriveProjectConfiguration(props.project?.type),

      // ----------- Milestones -----------

      canCreateMilestone: deriveCreateMilestonePermission(props),
    };
  }, [props]);

  return { permissions };
};
