import { useState } from "react";
import { useTranslation } from "react-i18next";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useFeatureFlags } from "context/FeatureFlagsContext";
import { IUserPreferencesData } from "models/users.models";
import ProfileCard from "screens/Profile/ProfileCard";
import UpdateTimeZoneModal from "screens/Profile/UpdateTimeZoneModal";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import { includeInArray } from "utils/helpers";
import UpdateEmailNotificationsModal, {
  getEmailNotificationLabel,
} from "../UpdateEmailNotificationsModal";
import { Modal } from "./settings.constants";

type TSettingsProps = {
  preferences: IUserPreferencesData;
};

const DEFAULT_TIMEZONE = "Europe/London";

const Settings = ({ preferences }: TSettingsProps) => {
  const { t } = useTranslation(["user", "emailNotifications"]);
  const featureFlags = useFeatureFlags();
  const [currentModal, setCurrentModal] = useState<Modal | null>(null);

  const timezone = preferences.timezone?.toString() ?? DEFAULT_TIMEZONE;

  const emailNotifications = {
    isEnabled: Boolean(preferences.emailNotificationsEnabled),
  };

  const isEmailNotificationsEnabled =
    featureFlags["FTE-28007_enableEmailNotifications"] === FeatureFlag.On;

  const fields = [
    {
      id: Modal.Timezone,
      label: t("profileSettings.fields.timezone"),
      value: timezone,
      onEdit: () => setCurrentModal(Modal.Timezone),
    },
    ...includeInArray(
      {
        id: Modal.EmailNotifications,
        label: t("profileSettings.fields.emailNotifications"),
        value: getEmailNotificationLabel(emailNotifications.isEnabled, t),
        onEdit: () => setCurrentModal(Modal.EmailNotifications),
      },
      isEmailNotificationsEnabled,
    ),
  ];

  const closeModal = () => setCurrentModal(null);

  const handleSave = () => {
    closeModal();
  };

  return (
    <>
      <ProfileCard
        avatar={<SettingsOutlinedIcon />}
        title={t("profileSettings.profileCardTitle")}
        fields={fields}
      />
      <UpdateTimeZoneModal
        currentTimeZone={timezone}
        open={currentModal === Modal.Timezone}
        onSave={handleSave}
        onClose={closeModal}
      />
      <UpdateEmailNotificationsModal
        isEnabled={emailNotifications.isEnabled}
        open={currentModal === Modal.EmailNotifications}
        onSave={handleSave}
        onClose={closeModal}
      />
    </>
  );
};

export default Settings;
