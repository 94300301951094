import { Controller, get, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import ComboBox from "components/shared/ComboBox";
import useProjectMembers from "hooks/api/REST/projects/members/useProjectMembers";
import useUserGroups from "hooks/api/REST/user/useUserGroups";
import {
  StartReviewModalField,
  TStartReviewModalForm,
} from "screens/Project/sections/Documents/Document/DocumentActionModals/StartReviewModal.types";
import GroupedDropdown from "./GroupedDropdown";
import { useTeamMembersSelect } from "./hooks/useTeamMembersSelect";
import styles from "../CommonStyles.styles";

const TeamMembers = () => {
  const { projectId } = useParams();

  const { t } = useTranslation(["documents", "common"]);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<TStartReviewModalForm>();

  const error = get(errors, StartReviewModalField.Members);

  const { memberGroups, isLoading: isLoadingGroupStatus } = useUserGroups({
    projectId,
  });

  const { projectMembers, isLoading: isLoadingMemberStatus } =
    useProjectMembers(projectId);

  const { allOptions, onMembersChange } = useTeamMembersSelect({
    members: projectMembers,
    memberGroups: memberGroups || null,
    setValue,
  });

  return (
    <Controller
      name="members"
      control={control}
      render={({ field }) => (
        <Box>
          <ComboBox
            data-qaid="team-members-combobox"
            sx={styles.membersDropdown}
            label={t("startReviewModal.membersInputLabel")}
            textFieldProps={{
              size: "small",
              errorAdornmentAriaLabel: t("ariaLabels.textFieldError", {
                ns: "common",
              }),
            }}
            deselectAllLabelText={t("multiSelect.deselectAll", {
              ns: "common",
            })}
            selectAllLabelText={t("multiSelect.selectAll", { ns: "common" })}
            loading={isLoadingMemberStatus || isLoadingGroupStatus}
            options={allOptions || []}
            groupBy={(option) => option.type as string}
            renderGroup={GroupedDropdown}
            getOptionDisabled={(option) => !!option.disabled}
            error={!!error}
            helperText={
              error?.message || t("startReviewModal.memberInputsHelperLabel")
            }
            {...field}
            onChange={onMembersChange}
          />
        </Box>
      )}
    />
  );
};

export default TeamMembers;
