import assets from "./assets";
import dataResources from "./dataX";
import documents from "./documents";
import featureFlags from "./featureFlags";
import notifications from "./notifications";
import organizations from "./organizations";
import projectMembers from "./projectMembers";
import projects from "./projects";
import resources from "./resources";
import tasks from "./tasks";
import tracking from "./tracking";
import user from "./user";
import workflows from "./workflows";

export const routes = {
  asset: {
    documents,
    assets,
    projects,
  },
  dataX: {
    dataResources,
  },
  identity: {
    user,
    organizations,
    resources,
    featureFlags,
    projectMembers,
  },
  workflows: {
    tasks,
    workflows,
  },
  notifications,
  tracking,
};
