import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  SxProps,
  Typography,
} from "@mui/material";
import { Button } from "components/shared";
import { useGSPContext } from "context/GSPContext";
import { IGSPHealthAuthority } from "models/gsp.models";
import { GSPSummaryId } from "screens/Project/screens/GSP/GSPItems/GSPFormItem/GSPForm.utils";
import { SubmissionPlan } from "utils/constants/gsp.constants";
import HealthAuthorityHeading from "./components/HealthAuthorityHeading";
import { PlusIcon } from "assets/icons";
import styles from "./ItemLayout.styles";

export type TGSPItemLayoutProps = PropsWithChildren<{
  HA?: IGSPHealthAuthority;
  id: string;
  selectedSubmissionPlan?: SubmissionPlan | "";
  onOpenDossierDifferenceModal?: () => void;
}>;

export const ACCORDION_ANIMATION_DELAY = 250;

const ItemLayout = ({
  id,
  HA,
  children,
  selectedSubmissionPlan,
  onOpenDossierDifferenceModal,
}: TGSPItemLayoutProps) => {
  const { activeGSPItem, setActiveGSPItem } = useGSPContext();

  const { t } = useTranslation("projects");

  const updateActiveGSPItem = useCallback(() => {
    setActiveGSPItem((prev) => (prev === id ? "" : id));
  }, [id, setActiveGSPItem]);

  const itemRef = useRef<HTMLDivElement>(null);
  const isGSPItemActive = activeGSPItem === id;

  const { formState } = useFormContext();

  const hasFormError = Boolean(formState.errors[id] && !isGSPItemActive);

  useEffect(() => {
    if (!isGSPItemActive) {
      return;
    }

    // Timeout delays the scroll until the open/close animations finish on the accordion
    const scrollTimer = setTimeout(
      () =>
        itemRef?.current?.scrollIntoView({
          block: "start",
          behavior: "smooth",
        }),
      ACCORDION_ANIMATION_DELAY,
    );

    return () => clearTimeout(scrollTimer);
  }, [isGSPItemActive]);

  const isSummarySection = id === GSPSummaryId;

  const handleAddMore = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();
    onOpenDossierDifferenceModal && onOpenDossierDifferenceModal();
  };

  return (
    <Box>
      <Accordion
        expanded={isGSPItemActive}
        onChange={updateActiveGSPItem}
        sx={styles.accordion}
        ref={itemRef}
        TransitionProps={{
          timeout: { enter: ACCORDION_ANIMATION_DELAY },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`ha-${id}-content`}
          id={`ha-${id}-header`}
          sx={
            {
              ...styles.accordionSummary,
              ...(hasFormError && styles.error),
            } as SxProps
          }
        >
          <Box sx={!isSummarySection ? styles.heading : styles.headingSummary}>
            {isSummarySection ? (
              <Typography variant="body2" component="span">
                {t("dossierDifferences.summaryLabel")}
              </Typography>
            ) : (
              HA && (
                <HealthAuthorityHeading
                  HA={HA}
                  selectedSubmissionPlan={selectedSubmissionPlan}
                />
              )
            )}
          </Box>
          {onOpenDossierDifferenceModal && (
            <Box sx={styles.addMoreBtn}>
              <Button
                data-qaid="add-more-dossier-differences-button"
                variant={"outlined"}
                startIcon={<PlusIcon />}
                onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                  handleAddMore(e)
                }
              >
                {t("projectGSP.addMoreButton")}
              </Button>
            </Box>
          )}
        </AccordionSummary>
        <AccordionDetails sx={styles.accordionDetails}>
          <Box sx={styles.content}>{children}</Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ItemLayout;
