export const primary = {
  50: "#e0eff3",
  100: "#b3d7e0",
  200: "#80bdcc",
  300: "#4da3b8",
  400: "#268fa8",
  500: "#007b99",
  600: "#007391",
  700: "#006886",
  800: "#005e7c",
  900: "#004b6b",
};

export const secondary = {
  50: "#e3e0e8",
  100: "#b9b3c5",
  200: "#8b809f",
  300: "#5c4d79",
  400: "#39265c",
  500: "#16003f",
  600: "#130039",
  700: "#100031",
  800: "#0c0029",
  900: "#06001b",
};
