import { DocumentComponentStatus } from "utils/constants/doc.constants";
import { TISODateFormat } from "utils/types";
import { SendCopyComponentsFieldName } from "./SendCopyComponents.types";

export const getSendCopyComponentsDefaultValues = (
  componentsToSend: (string | undefined)[],
) => ({
  [SendCopyComponentsFieldName.Components]: componentsToSend,
  [SendCopyComponentsFieldName.Recipients]: [],
  [SendCopyComponentsFieldName.DueDate]: "" as TISODateFormat,
  [SendCopyComponentsFieldName.Instructions]: "",
});

export const checkIfPossibleToSendCopyComponent = (
  status: DocumentComponentStatus,
) =>
  [DocumentComponentStatus.Final, DocumentComponentStatus.Cleared].includes(
    status,
  );
