import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import uniqBy from "lodash/uniqBy";
import { FilterType, TSelectFilter } from "components/shared/types/filter";
import { TProductFamily } from "../ProductFamily.types";
import { ProductFamilyTable } from "./ProductFamilyTable.types";

type TUseProductFamilyFilters = {
  productFamilies: TProductFamily[] | undefined;
};

export const useProductFamilyFilters = ({
  productFamilies,
}: TUseProductFamilyFilters): Record<string, TSelectFilter> => {
  const { t } = useTranslation(["administration", "common"]);

  return useMemo(
    () => ({
      [ProductFamilyTable.Status]: {
        type: FilterType.Select,
        label: t("productFamily.productFamilyColumns.status"),
        options:
          uniqBy(
            productFamilies?.map((productFamily) => ({
              text: t(`${productFamily.status}`),
              value: productFamily.status,
            })),
            "value",
          ) ?? [],
        selectAllLabel: t("multiSelect.selectAll", { ns: "common" }),
      },
    }),
    [t, productFamilies],
  );
};
