import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const drawerWidth = 360;

const styles: TStyles = {
  drawer: {
    transform: `translateX(0)`,
    zIndex: (theme) => theme.zIndex.drawer,

    "& .MuiDrawer-paper": {
      width: drawerWidth,
      boxSizing: "border-box",
      backgroundColor: getPaletteColor("common.white"),
      boxShadow: (theme) => theme.shadows[7],
    },
  },

  container: {
    height: "100%",
    overflow: "hidden",
  },

  headerContainer: {
    p: "19px 4px 16px 24px",
  },

  notificationsListContainer: {
    height: "100%",
    overflowX: "hidden",
    overflowY: "scroll",
  },

  notificationsList: {
    "& .MuiListItem-root": {},
  },

  loadingItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default styles;
