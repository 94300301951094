import { Row } from "@tanstack/react-table";
import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import {
  DateRangeType,
  FilterType,
  TDateRangeFilter,
  TFilteringParams,
  TFilters,
} from "components/shared/types/filter";
import { TActiveFilter } from "./FilterChips";

/**
 * Creates an array of active filters (for use with the FilterChips component)
 * based on provided filters info and selected filters.
 * @param allFilters Filter data (aka display labels and option text)
 * @param selectedFilters Currently selected filters
 */

const getActiveFilters = (
  allFilters?: TFilters,
  selectedFilters?: TFilteringParams,
): TActiveFilter[] => {
  if (!allFilters || !selectedFilters) {
    return [];
  }

  return Object.entries(selectedFilters).flatMap(
    ([key, values]) =>
      values?.map((value) => {
        const filter = allFilters[key];

        if (filter?.type === FilterType.Toggle) {
          return {
            key,
            label: filter.label,
            option: {
              text: value,
              value: value,
            },
            type: filter.type,
          };
        }

        if (filter?.type === FilterType.Select) {
          return {
            key,
            label: filter.label,
            option: filter.options.find((option) => option.value === value) ?? {
              text: "",
              value: "",
            },
            type: filter.type,
          };
        }

        if (filter?.type === FilterType.SelectTypeahead) {
          const activeOption = filter.options.find(
            (option) => option.value === value,
          );

          return {
            key,
            label: filter.label,
            option: {
              text: activeOption?.label ?? "",
              value: activeOption?.value ?? "",
            },
            type: filter.type,
          };
        }

        // ToDo (AV): Think about refactoring of this. Maybe reconfigure DueDate filters object.
        /**
         * DateRage filters are constructed in such a way that "startDate" and "endDate" are never in "allFilters" directly.
         * They both are a part of options of a DateRange filter. We presume that if allFilters[key] is "undefined" then we
         * are dealing with DateRange's startDate or endDate filters. "getDateFilterFromAll" is there to extract one of these.
         * */

        const activeDateFilter = getDateFilterFromAll(allFilters, key);

        return {
          key,
          label: activeDateFilter?.label ?? "",
          option: {
            text: value,
            value: value,
          },
          type: filter?.type || activeDateFilter?.type,
        };
      }) ?? [],
  );
};

/**
 * Returns the count of total selected filter options across all filters.
 * @param selectedFilters Currently selected filters
 */
const getSelectedFilterCount = (
  selectedFilters: TFilteringParams | undefined,
) =>
  isEmpty(selectedFilters)
    ? 0
    : Object.values(selectedFilters).reduce(
        (acc, values) => acc + (values?.length ?? 0),
        0,
      );

export const getDateFilterFromAll = (allFilters: TFilters, key: string) => {
  // ToDo (SY): The following code may cause undesired behavior if more then 1 DateRange filter is used:
  const dateRangeFilters = Object.values(allFilters).find(
    (tableFilter) => tableFilter.type === FilterType.DateRange,
  ) as TDateRangeFilter;

  return (
    dateRangeFilters &&
    Object.values(dateRangeFilters.options).find(
      (filter) => filter.filterKey === key,
    )
  );
};

export const getDateFilterFunction =
  (dateFieldName: string, type: DateRangeType) =>
  (row: Row<any>, _: string, filterValue: string | undefined) => {
    if (!row.getValue(dateFieldName)) {
      return false;
    }

    const diff = dayjs(filterValue?.[0]).diff(row.getValue(dateFieldName));

    return type === DateRangeType.Start ? diff <= 0 : diff >= 0;
  };

export { getActiveFilters, getSelectedFilterCount };
