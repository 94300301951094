import { useTranslation } from "react-i18next";
import { useQuery, UseQueryOptions } from "react-query";
import { gql, Variables } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { IEntityProductsResponse } from "./useEntityProducts.models";

export const query = gql`
  query GetEntityProducts($isActive: Boolean, $isAssignedToProject: Boolean) {
    entityProducts(
      isActive: $isActive
      isAssignedToProject: $isAssignedToProject
    ) {
      id
      tradeName
      nonproprietaryName
      ownerTenantId
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      actions {
        canChangeStatus
        canEditEntity
      }
      substances {
        id
        name
        drugClassType {
          id
          code
          name
        }
        INN
        ATC
        status
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
      }
      productFamilies {
        id
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
        name
        status
        description
      }
      linkedOrganizations {
        id
        name
        organizationType
        roleTypes
      }
      status
    }
  }
`;

export type TQueryEntityProductsArgs = {
  isActive?: boolean;
  isAssignedToProject?: boolean;
};

const useEntityProducts = (
  { isActive, isAssignedToProject }: TQueryEntityProductsArgs = {},
  queryOptions?: UseQueryOptions<IEntityProductsResponse>,
) => {
  const { t } = useTranslation("administration");
  const { showAlert } = useAlerts();

  const { data, isLoading, isSuccess } = useQuery<IEntityProductsResponse>(
    [QueryAPIKey.GetEntityProducts, isActive, isAssignedToProject],
    () =>
      services.graphQL
        .request<IEntityProductsResponse, Variables>(query, {
          isActive,
          isAssignedToProject,
        })
        .catch((error) => {
          showAlert({
            severity: AlertSeverity.Error,
            message: t("notifications.getEntityProductsFailure"),
          });

          throw error;
        }),
    {
      enabled: queryOptions?.enabled ?? true,
    },
  );

  return {
    entityProducts: data?.entityProducts ?? [],
    isLoading,
    isSuccess,
  };
};

export default useEntityProducts;
