import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormSelect } from "components/common/form-fields";
import Loading from "components/shared/Loading";
import { ProposedApprovalPathway } from "utils/constants/gsp.constants";
import { formatValuesToOptions } from "utils/helpers";

const ApprovalPathwayField = ({
  name,
  approvalPathwayValues,
}: {
  name: string;
  approvalPathwayValues: ProposedApprovalPathway[] | undefined;
}) => {
  const { t } = useTranslation("documents", {
    keyPrefix: "gsp.item.fields.approvalPathway",
  });

  const approvalPathwayOptions = useMemo(() => {
    return formatValuesToOptions(
      approvalPathwayValues,
      (value) => t(`optionLabels.${value}`),
      (value) => value,
    );
  }, [approvalPathwayValues, t]);

  if (!approvalPathwayValues) {
    return <Loading />;
  }

  return (
    <FormSelect
      name={name}
      data-qaid="approval-pathway-select"
      label={t("label")}
      options={approvalPathwayOptions}
    />
  );
};

export default ApprovalPathwayField;
