import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import poll from "api/utils/poll";
import { queryClient } from "config/reactQueryClient";
import { TShowAlert, useAlerts } from "context/AlertContext";
import { useProject } from "context/ProjectContext";
import { lockBulkAssetsEditing } from "hooks/api/REST/assets/useLockBulkAssetsEditing";
import { getWorkflowExecution } from "hooks/api/REST/workflow/useGetWorkflowExecution";
import { startWorkflow } from "hooks/api/REST/workflow/useStartWorkflow";
import useUser from "hooks/useUser";
import { IOrganization } from "models/organizations.models";
import { TUserData } from "models/users.models";
import { WorkflowExecutionStatus } from "models/workflow.models";
import { AlertSeverity } from "utils/constants/alerts.constants";
import {
  DEFAULT_POLLING_INTERVAL,
  QueryAPIKey,
} from "utils/constants/api.constants";
import { HAQStatus } from "utils/constants/HAQ.constants";
import { WorkflowId } from "utils/constants/workflows.constants";
import { isHAUser } from "utils/user/organization";

type TGetChangeAssetStatus = {
  t: TFunction;
  showAlert: TShowAlert;
  user: TUserData | undefined;
  organization: IOrganization | undefined;
  projectId: string | undefined;
};

export const getChangeAssetStatus =
  ({ t, showAlert, user, organization, projectId }: TGetChangeAssetStatus) =>
  async (
    assetIds: string[],
    targetStatus: HAQStatus,
    successMessage: string,
    failureMessage: string,
  ) => {
    if (!user?.id || !organization || !projectId) {
      return;
    }

    await lockBulkAssetsEditing(t, showAlert, assetIds);

    const startWorkflowResponse = await startWorkflow(showAlert, {
      id: isHAUser(organization)
        ? WorkflowId.HAAssetManualStatusChange
        : WorkflowId.SponsorAssetManualStatusChange,
      payload: {
        project: projectId,
        originator: user.id,
        assets: assetIds,
        status: targetStatus,
      },
    });

    const id = startWorkflowResponse?.data?.id;

    if (!id) {
      return;
    }

    const { status } = await poll(
      () => getWorkflowExecution(t, showAlert, id),
      (data) => data.status !== WorkflowExecutionStatus.Running,
      DEFAULT_POLLING_INTERVAL,
    );

    if (status === WorkflowExecutionStatus.Complete) {
      showAlert({
        severity: AlertSeverity.Success,
        message: successMessage,
      });
    }

    if (status === WorkflowExecutionStatus.Failed) {
      showAlert({
        severity: AlertSeverity.Success,
        message: failureMessage,
      });
    }

    queryClient.invalidateQueries(QueryAPIKey.GetAsset);
    queryClient.invalidateQueries(QueryAPIKey.GetAssetContent);
    queryClient.invalidateQueries(QueryAPIKey.GetAssetsWithContent);
    queryClient.invalidateQueries(QueryAPIKey.GetAssetShareHistory);
  };

export const useChangeAssetStatus = () => {
  const { t } = useTranslation("notifications");
  const { showAlert } = useAlerts();
  const { user, organization } = useUser();
  const { project } = useProject();

  return useMemo(
    () =>
      getChangeAssetStatus({
        t,
        showAlert,
        user,
        organization,
        projectId: project?.id,
      }),
    [t, showAlert, user, organization, project?.id],
  );
};
