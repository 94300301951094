import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";
import { focusOutlineStyle } from "styles/accessibility.styles";

const styles: TStyles = {
  tab: {
    fontFamily: "Inter",
    textTransform: "none",
    minWidth: "unset",
    color: getPaletteColor("blueGrey.600"),
    px: 1,

    "&.Mui-focusVisible": {
      outline: focusOutlineStyle,
    },
  },
};

export default styles;
