import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  AssignmentLate as DossierIcon,
  Business as BusinessIcon,
  InsertDriveFile as AppIcon,
} from "@mui/icons-material";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import {
  FormDate,
  FormPhoneInput,
  FormRadioGroup,
  FormTextInput,
} from "components/common/form-fields";
import useAssetMetadata from "hooks/api/REST/assets/useAssetMetadata";
import SectionTitle from "screens/Project/screens/GSP/GSPItems/components/SectionTitle/SectionTitle";
import styles from "screens/Project/screens/GSP/GSPItems/GSPFormItem/GSPForm.styles";
import {
  GSPFormFieldGroupName,
  GSPFormFieldName,
  TGSPForm,
  TGSPItemForm,
} from "screens/Project/screens/GSP/GSPItems/GSPFormItem/GSPForm.types";
import { reviewOptions } from "screens/Project/screens/GSP/GSPItems/GSPFormItem/GSPForm.utils";
import { TDossierDifferencesModule } from "screens/Project/screens/GSP/GSPItems/GSPItems.utils";
import { DossierDifferencesModule } from "screens/Project/screens/GSP/GSPItems/GSPSummaryItem/components";
import { AssetType } from "utils/constants/assets.constants";
import { TCountry } from "utils/constants/countries.constants";
import { SubmissionPlan } from "utils/constants/gsp.constants";
import { DEFAULT_ORBIS_HOST_HA_TENANT_ID } from "utils/constants/organization.constants";
import ApplicationType from "./SelectFields/ApplicationType";
import ApprovalPathway from "./SelectFields/ApprovalPathway";
import SubmissionPlanField from "./SelectFields/SubmissionPlan";
import Timeline from "./SelectFields/Timeline";
import "react-phone-input-material-ui/lib/style.css";

type TFieldsLayoutProps = {
  id: string;
  HAData: {
    country: keyof TCountry;
    name: string;
    tenantId: string;
  };
  dossierDifferencesModules: TDossierDifferencesModule[];
};

const FieldsLayout = ({
  id,
  HAData: { country, tenantId, name },
  dossierDifferencesModules,
}: TFieldsLayoutProps) => {
  const { watch, resetField } = useFormContext<TGSPForm>();

  const { t } = useTranslation(["documents", "common"]);

  const { assetMeta } = useAssetMetadata(AssetType.GSPComponent);

  const assetProperties = useMemo(() => {
    return assetMeta?.metadataSchema?.allOf.find(
      (el) => el.if.properties.tenantId.const === tenantId,
    )?.then.properties.applicationInfo.properties;
  }, [assetMeta?.metadataSchema?.allOf, tenantId]);

  const selectedSubmissionPlan = watch(
    `${id}.${GSPFormFieldName.SubmissionPlan}`,
  );

  const selectedReviewType = watch(
    `${id}.${GSPFormFieldGroupName.ApplicationInfo}.${GSPFormFieldName.ReviewType}`,
  );

  const isSubmittingView = useMemo(
    () =>
      selectedSubmissionPlan === SubmissionPlan.Submitting ||
      !selectedSubmissionPlan,
    [selectedSubmissionPlan],
  );

  const onReviewTypeChange = useCallback(
    (value: string) => {
      if (value === "false") {
        const resetName = `${id}.${GSPFormFieldGroupName.ApplicationInfo}.${GSPFormFieldName.StartDate}`;
        const options = { defaultValue: "" };
        resetField(
          resetName,
          options as { defaultValue: Partial<TGSPItemForm> },
        );
      }
    },
    [resetField, id],
  );

  return (
    <Box sx={styles.formContainer}>
      <Grid container spacing={2.3} sx={styles.formSectionBottomMargin}>
        <Grid item xs={5}>
          <SubmissionPlanField
            name={`${id}.${GSPFormFieldName.SubmissionPlan}`}
            isDisabled={tenantId === DEFAULT_ORBIS_HOST_HA_TENANT_ID}
          />
        </Grid>
      </Grid>
      {!isSubmittingView && (
        <Box sx={styles.formSectionBottomMargin}>
          <FormTextInput<TGSPForm>
            data-qaid="gsp-form-not-submiting-reason-text-field"
            name={`${id}.${GSPFormFieldName.NotSubmittingReason}`}
            multiline
            rows={3}
            label={t(
              `gsp.item.fields.${GSPFormFieldName.NotSubmittingReason}.label`,
            )}
          />
        </Box>
      )}
      <Box>
        <SectionTitle
          Icon={BusinessIcon}
          title={t("gsp.item.sections.sponsorInfo")}
        />

        <Grid container spacing={2.3} pt={2}>
          <Grid item xs={6}>
            <FormTextInput<TGSPForm>
              data-qaid="gsp-form-sponsor-info-text-field"
              name={`${id}.${GSPFormFieldGroupName.SponsorInfo}.${GSPFormFieldName.CompanyName}`}
              label={t("gsp.item.fields.companyName.label")}
            />
          </Grid>

          <Grid item xs={6}>
            <FormTextInput<TGSPForm>
              data-qaid="gsp-form-contact-name-text-field"
              name={`${id}.${GSPFormFieldGroupName.SponsorInfo}.${GSPFormFieldName.ContactName}`}
              label={t("gsp.item.fields.contactName.label")}
            />
          </Grid>

          <Grid item xs={6}>
            <FormTextInput<TGSPForm>
              data-qaid="gsp-form-sponsor-info-text-field"
              name={`${id}.${GSPFormFieldGroupName.SponsorInfo}.${GSPFormFieldName.ContactEmail}`}
              label={t("gsp.item.fields.contactEmail.label")}
            />
          </Grid>

          <Grid item xs={6}>
            <FormPhoneInput<TGSPForm>
              data-qaid="prone-input"
              name={`${id}.${GSPFormFieldGroupName.SponsorInfo}.${GSPFormFieldName.PhoneNumber}`}
              label={t("gsp.item.fields.phoneNumber.label")}
              country={country.toLowerCase()}
            />
          </Grid>
        </Grid>
      </Box>
      {isSubmittingView && (
        <>
          <Divider sx={styles.divider} />
          <Box>
            <SectionTitle
              Icon={AppIcon}
              title={t("gsp.item.sections.applicationInfo")}
            />

            <Grid container spacing={2.3} pt={2}>
              <Grid item xs={6}>
                <ApplicationType
                  name={`${id}.${GSPFormFieldGroupName.ApplicationInfo}.${GSPFormFieldName.ApplicationType}`}
                  typeValues={assetProperties?.type.enum}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextInput<TGSPForm>
                  data-qaid="gsp-form-application-number-text-field"
                  name={`${id}.${GSPFormFieldGroupName.ApplicationInfo}.${GSPFormFieldName.ApplicationNumber}`}
                  label={t("gsp.item.fields.applicationNumber.label")}
                />
              </Grid>
              <Grid item xs={12}>
                <FormRadioGroup<TGSPForm>
                  name={`${id}.${GSPFormFieldGroupName.ApplicationInfo}.${GSPFormFieldName.ReviewType}`}
                  label={t("gsp.item.fields.isRtorOrRollingReview.label")}
                  onValueChange={onReviewTypeChange}
                  options={reviewOptions(t)}
                />
              </Grid>
              <Grid item xs={5}>
                <FormDate<TGSPForm>
                  data-qaid="gsp-form-start-date-field"
                  name={`${id}.${GSPFormFieldGroupName.ApplicationInfo}.${GSPFormFieldName.StartDate}`}
                  disabled={
                    !selectedReviewType || selectedReviewType === "false"
                  }
                  label={t("gsp.item.fields.startDate.label")}
                />
              </Grid>
            </Grid>
          </Box>
          <Divider sx={styles.divider} />
          <Box>
            <Grid container spacing={2.3}>
              <Grid item xs={5}>
                <FormDate<TGSPForm>
                  data-qaid="gsp-from-proposed-date-field"
                  name={`${id}.${GSPFormFieldGroupName.ApplicationInfo}.${GSPFormFieldName.ProposedDate}`}
                  label={t("gsp.item.fields.proposedFillingDate.label")}
                />
              </Grid>
              <Grid item xs={7}>
                <Timeline
                  name={`${id}.${GSPFormFieldGroupName.ApplicationInfo}.${GSPFormFieldName.Timeline}`}
                />
              </Grid>
              <Grid item xs={12}>
                <ApprovalPathway
                  name={`${id}.${GSPFormFieldGroupName.ApplicationInfo}.${GSPFormFieldName.ApprovalPathway}`}
                  approvalPathwayValues={
                    assetProperties?.proposedApprovalPathway.enum
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Divider sx={styles.divider} />
          <Box>
            <Grid container spacing={2.3}>
              <Grid item xs={12}>
                <FormTextInput<TGSPForm>
                  data-qaid="gsp-form-therapeutic-indication-text-field"
                  name={`${id}.${GSPFormFieldGroupName.ApplicationInfo}.${GSPFormFieldName.TherapeuticIndication}`}
                  multiline
                  rows={3}
                  label={t("gsp.item.fields.therapeuticIndication.label")}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextInput<TGSPForm>
                  data-qaid="gsp-form-clinical-trials-text-field"
                  name={`${id}.${GSPFormFieldGroupName.ApplicationInfo}.${GSPFormFieldName.ClinicalTrialsIndication}`}
                  multiline
                  rows={3}
                  label={t("gsp.item.fields.clinicalTrialsIndication.label")}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextInput<TGSPForm>
                  data-qaid="gsp-form-application-comments-text-field"
                  name={`${id}.${GSPFormFieldGroupName.ApplicationInfo}.${GSPFormFieldName.AdditionalComments}`}
                  multiline
                  rows={3}
                  label={t("gsp.item.fields.additionalComments.label")}
                />
              </Grid>
            </Grid>
          </Box>
          <Divider sx={styles.divider} />
          <Box>
            <SectionTitle
              Icon={DossierIcon}
              title={t("gsp.item.sections.countryDossierDifference")}
            />

            {dossierDifferencesModules.length ? (
              <Stack sx={styles.fixedHeightContainer} spacing={2.5}>
                {dossierDifferencesModules.map((module) => (
                  <DossierDifferencesModule
                    module={module}
                    isEditable
                    key={module.id}
                    initialHA={{ tenantId, name }}
                    isCountrySpecific
                    parentId={id}
                  />
                ))}
              </Stack>
            ) : (
              <Typography sx={styles.dossierDifference} pt={2}>
                {t("gsp.item.helperText.dossierDifference")}
              </Typography>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default FieldsLayout;
