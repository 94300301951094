import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { queryClient } from "config/reactQueryClient";
import { useProject } from "context/ProjectContext";
import useUpdateAsset from "hooks/api/REST/assets/useUpdateAsset";
import { useHAQ } from "hooks/haq";
import { useUserRoles } from "hooks/user";
import useUser from "hooks/useUser";
import { IHAQ } from "models/HAQ.models";
import { QueryAPIKey } from "utils/constants/api.constants";
import { HAQStatus } from "utils/constants/HAQ.constants";
import { getIsEligibleToManipulateAsset } from "utils/helpers";
import { getCanSetToComplete } from "utils/helpers/HAQ/getHAQStatusChangeOptions/getHAQStatusChangeOptions";

const useHAQSetToComplete = (HAQId: string, isHAQCopied: boolean) => {
  const { project } = useProject();
  const { isProjectManager } = useUserRoles(project?.id);
  const { HAQ, HAQResponse } = useHAQ(HAQId);
  const { organization } = useUser();
  const { t } = useTranslation(["HAQ", "common"]);

  const targetAssets = [HAQ, HAQResponse].filter((asset): asset is IHAQ =>
    getIsEligibleToManipulateAsset(asset, organization),
  );

  const { mutateAsync: setHAQToComplete } = useUpdateAsset({
    failureMessage: t("notifications.setHAQToCompleteFailure"),
    successMessage: t("notifications.setHAQToCompleteSuccess"),
  });

  const canSetToComplete =
    getCanSetToComplete(
      HAQ?.status as HAQStatus,
      Boolean(HAQResponse),
      isProjectManager,
      organization,
      Boolean(HAQ?.metadata?.completed),
    ) &&
    isHAQCopied &&
    getIsEligibleToManipulateAsset(HAQ, organization);

  const onSetToComplete = useCallback(() => {
    // this action will only ever be done on one asset
    if (targetAssets && targetAssets.length === 1 && targetAssets[0]) {
      const data = targetAssets[0];

      const payload = {
        ...data,
        metadata: { ...data.metadata, completed: true },
      };

      setHAQToComplete(
        { data: payload, id: data.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryAPIKey.GetAsset);
          },
        },
      );
    }
  }, [targetAssets, setHAQToComplete]);

  return { canSetToComplete, onSetToComplete };
};

export default useHAQSetToComplete;
