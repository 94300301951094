import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TKeywordParams } from "components/shared/types/filter";
import {
  FilterType,
  TFilters,
  TPossibleFilter,
} from "components/shared/types/filter";
import {
  TFilteringParams,
  TFiltersUpdater,
} from "components/shared/types/filter";
import useKeywordFilter from "hooks/filters/useKeywordFilter";
import { IFeatureFlag } from "models/featureFlags.models";
import { FeatureFlagsTable } from "./FeatureFlagsTable.types";

type TUseFilterResult = {
  allFilters: TFilters;
  onKeywordChange: (keyword: string) => void;
  keywordParam: TKeywordParams;
  updateFilters: (filters: TFilteringParams) => void;
};

const useFeatureFlagFilters = (
  setAllFilters: (updater: TFiltersUpdater) => void,
  featureFlags?: IFeatureFlag[],
): TUseFilterResult => {
  const keywordFilter = useKeywordFilter();

  const { t } = useTranslation("featureFlags", {
    keyPrefix: "utils.filters",
  });

  const modifiedByValues = useMemo(() => {
    if (!featureFlags) {
      return [];
    }

    return featureFlags
      .map((flag) => flag.modifiedBy)
      .filter((value, index, array) => array.indexOf(value) === index);
  }, [featureFlags]);

  const allFilters: Record<string, TPossibleFilter> = useMemo(
    () => ({
      [FeatureFlagsTable.ModifiedBy]: {
        type: FilterType.Select,
        label: t("modifiedBy"),
        options: modifiedByValues.map((name) => ({
          text: name,
          value: name,
        })),
      },
      [FeatureFlagsTable.Value]: {
        type: FilterType.Select,
        label: t("value"),
        options: [
          {
            text: "true",
            value: "true",
          },
          {
            text: "false",
            value: "false",
          },
        ],
      },
    }),
    [modifiedByValues, t],
  );

  const updateFilters = useCallback(
    (filters: TFilteringParams) => {
      const filtersForReactTable = Object.keys(filters).map((filterKey) => ({
        id: filterKey,
        value: filters[filterKey],
      }));

      setAllFilters(filtersForReactTable);
    },
    [setAllFilters],
  );

  return useMemo(
    () => ({
      allFilters,
      updateFilters,
      ...keywordFilter,
    }),
    [keywordFilter, allFilters, updateFilters],
  );
};

export default useFeatureFlagFilters;

export type { TUseFilterResult };
