import { Paper, PaperProps, Typography } from "@mui/material";
import styles from "./DocumentCard.styles";

type TDocumentCardProps = PaperProps;

const DocumentCard = (props: TDocumentCardProps) => {
  const { sx: sxProps, children, ...restProps } = props;
  const sx = { ...styles.card, ...sxProps };

  return (
    <Paper sx={sx} {...restProps}>
      <Typography sx={styles.text}>{children}</Typography>
    </Paper>
  );
};

export default DocumentCard;
