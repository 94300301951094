import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  heading: {
    width: "100%",
    display: "flex",
    height: 64,
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 2,

    "& .MuiTypography-root": {
      color: getPaletteColor("grey.900"),
    },
  },

  accordion: {
    boxShadow: 0,
    border: getBorderStyle("1px", "solid", "grey.200"),

    ".MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded": {
      margin: 0,
    },
  },

  accordionSummary: {
    px: 2.5,

    "& .MuiAccordionSummary-expandIconWrapper": {
      color: "inherit",
    },
  },

  accordionDetails: {
    p: (theme) => theme.spacing(0, 2.5, 2.5, 2.5),
  },

  section: {
    px: 2.5,
    mt: 3.75,

    "&:first-of-type": {
      mt: 0,
    },
  },

  sectionHeader: {
    fontWeight: 500,

    "& i": {
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: 0.4,
    },
  },

  sectionContent: {
    p: (theme) => theme.spacing(2.5, 2.5, 0, 2.5),
    fontSize: 14,
  },

  sectionContentLabel: {
    color: getPaletteColor("custom.labelDefault"),
  },

  sectionButton: {
    display: "flex",
    justifyContent: "flex-end",
  },

  sectionContentHA: {
    "& .MuiTypography-root": {
      fontWeight: 500,
    },
  },
};

export default styles;
