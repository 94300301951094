import { QueryClient } from "react-query";
import { type CommentsAdapter } from "@ckeditor/ckeditor5-comments/src/comments/commentsrepository";
import { Context, Editor } from "ckeditor5";
import { TFunction } from "i18next";
import { TAlertContextValue } from "context/AlertContext";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

const reopenCommentThread = (
  queryClient: QueryClient,
  showAlert: TAlertContextValue["showAlert"],
  t: TFunction,
  // We will need this later to implement handling of failed API requests
  editorOrContext: Editor | Context,
): CommentsAdapter["reopenCommentThread"] => {
  return async (data) => {
    try {
      const { threadId } = data;

      const commentsRepository =
        editorOrContext.plugins.get("CommentsRepository");

      const thread = commentsRepository.getCommentThread(threadId);

      if (!thread) {
        return;
      }

      await services.documents.comments.updateCommentThread({
        assetId: String(thread.channelId),
        threadId: thread.id,
        isResolved: false,
      });

      queryClient.invalidateQueries([QueryAPIKey.GetCommentThread, threadId]);
    } catch (error) {
      showAlert({
        message: String(t("reopenCommentThreadFailure")),
        severity: "error",
      });
      throw error;
    }
  };
};

export default reopenCommentThread;
