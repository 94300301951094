import { TFunction } from "i18next";
import { TColumn } from "./ReactTable.types";

function getTitle<T extends object = {}>(column: TColumn<T>): string {
  if (typeof column.title === "string") {
    return column.title;
  }

  if (typeof column.columnDef.header === "string") {
    return column.columnDef.header;
  }

  return "";
}

export function getColumnTitle<T extends object = {}>(
  column: TColumn<T>,
  t: TFunction,
): string {
  const title = getTitle(column);

  return column.getCanSort() ? t("sortBy", { title }) : title;
}
