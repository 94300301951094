import { TStyles } from "utils/Styles";

const styles: TStyles = {
  textfield: {
    "& .MuiOutlinedInput-input": {
      typography: "inputText",
      height: 24,
      px: 2,
      py: 1.5,
    },

    "& .MuiInputBase-inputSizeSmall": {
      py: "7px",
      pl: 0,
      pr: 1.5,
    },

    "& .MuiOutlinedInput-input.MuiInputBase-inputSizeSmall": {
      p: (theme) => theme.spacing(1, 1.5),
    },

    "& .MuiInputBase-sizeSmall.MuiInputBase-inputMultiline": {
      p: (theme) => theme.spacing(0, 0.25, 0, 0),
      lineHeight: 2.5,
    },

    "& .MuiInputBase-multiline": {
      p: "9.5px 12px",
    },

    "& .MuiOutlinedInput-input.MuiInputBase-inputMultiline": {
      px: 0,
      pt: "5px",
    },
  },

  errorIcon: {
    display: "flex",
    alignItems: "center",
    width: 24,
    height: 24,
    p: 0.25,
  },
};

export default styles;
