import flatMap from "lodash/flatMap";
import uniqBy from "lodash/uniqBy";
import {
  TPortfolioCountry,
  TPortfolioRowData,
} from "screens/Portfolio/Portfolio.types";
import { TTherapeuticRowData } from "screens/Portfolio/TherapeuticSummary/TherapeuticSummary.types";
import { defaultCompare } from "../defaultCompare";

export const formatCountriesAsOptions = (
  dataArray: TTherapeuticRowData[] | TPortfolioRowData[],
  countriesPropertyName: string,
) => {
  return uniqBy(
    flatMap(dataArray, countriesPropertyName).map(
      (country: TPortfolioCountry) => {
        return {
          text: country.description,
          value: country.id,
        };
      },
    ),
    "value",
  ).sort((optionA, optionB) => defaultCompare(optionA.text, optionB.text));
};
