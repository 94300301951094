import { TStyles } from "utils/Styles";

const styles: TStyles = {
  documentNameCell: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  icon: {
    fontSize: 18,
  },
};

export default styles;
