import { Trans, useTranslation } from "react-i18next";
import Alert from "components/shared/Alert";
import styles from "./AAIDNote.styles";

const AAIDNote = () => {
  const { t } = useTranslation("documents");

  return (
    <Alert sx={styles.container} severity="info" variant="outlined">
      <Trans t={t} i18nKey="AAIDNote.uploadMessage">
        <a href="/#" target="_blank" rel="noopener noreferrer">
          {t("AAIDNote.linkText")}
        </a>
      </Trans>
    </Alert>
  );
};

export default AAIDNote;
