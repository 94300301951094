import { ForwardedRef, forwardRef } from "react";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { ButtonProps as MUIButtonProps, IconButton } from "@mui/material";
import styles from "./InfoButton.styles";

const InfoButton = forwardRef(function ButtonBase<C extends React.ElementType>(
  props: MUIButtonProps<C, { component?: C }>, //https://mui.com/material-ui/guides/composition/#with-typescript
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <IconButton ref={ref} sx={styles.infoButton} {...props}>
      <InfoIcon />
    </IconButton>
  );
});

export default InfoButton;
