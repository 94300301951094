import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import { useChangeAssetStatus } from "api/orchestration/changeAssetStatus";
import {
  TBulkStatusChangeAction,
  TSelectedHAQTableRow,
} from "components/shared/types";
import { useProject } from "context/ProjectContext";
import { useUserRoles } from "hooks/user";
import useUser from "hooks/useUser";
import { EventType } from "models/tracking.models";
import { ConfirmStatusChangeModalBasic } from "screens/Project/screens/HAQ/components";
import { services } from "services";
import { AssetType } from "utils/constants/assets.constants";
import { HAQBulkActionType, HAQStatus } from "utils/constants/HAQ.constants";
import {
  getHAQShortName,
  getIsAssetCopied,
  getIsAssetOwnedByUserOrganization,
} from "utils/helpers";
import {
  getHAQStatusTransitionMappingForHAQ,
  getHAQStatusTransitionMappingForHAQR,
  TManualHAQStatusOptions,
} from "utils/helpers/HAQ/getHAQStatusChangeOptions";
import { isSponsorUser } from "utils/user/organization";

export function useHandleActionClickV1(
  selectedRows: TSelectedHAQTableRow[],
  setChangeStatusModal: Dispatch<SetStateAction<JSX.Element>>,
  setWorkflowInProgress: Dispatch<SetStateAction<boolean>>,
  shouldEnableTrackingEvent?: boolean,
) {
  const { t } = useTranslation(["HAQ", "notifications"]);
  const { organization } = useUser();
  const { project } = useProject();
  const { isProjectManager } = useUserRoles(project?.id);
  const changeAssetStatus = useChangeAssetStatus();

  return async function handleActionClickV1(action: TBulkStatusChangeAction) {
    const targetAssets: string[] = [];
    const selectedContent: string[] = [];
    const selectedHAQIds: string[] = [];

    const { actionType, status } = action;

    for (const row of selectedRows) {
      const partsToInclude = [];

      selectedHAQIds.push(row.HAQ!.id);

      const statusMappingForQuestion = getHAQStatusTransitionMappingForHAQ(
        row.HAQ.status as HAQStatus,
        isProjectManager,
        Boolean(row.HAQ.assetContent?.content),
        getIsAssetCopied(row.HAQ.shareTracking),
      );

      const shouldIncludeQuestion =
        [
          HAQBulkActionType.QuestionOnly,
          HAQBulkActionType.QuestionAndResponse,
        ].includes(actionType) &&
        getIsAssetOwnedByUserOrganization(row.HAQ, organization) &&
        statusMappingForQuestion.includes(status as TManualHAQStatusOptions) &&
        row.HAQ.id;

      if (shouldIncludeQuestion) {
        targetAssets.push(row.HAQ.id);
        partsToInclude.push(t("HAQ:viewPage.component.QUESTION"));
      }

      const statusMappingForResponse = getHAQStatusTransitionMappingForHAQR(
        row.HAQResponse?.status as HAQStatus,
        isProjectManager,
        Boolean(row.HAQResponse?.assetContent?.content),
        getIsAssetCopied(row.HAQResponse?.shareTracking),
      );

      const shouldIncludeResponse =
        [
          HAQBulkActionType.ResponseOnly,
          HAQBulkActionType.QuestionAndResponse,
        ].includes(actionType) &&
        isSponsorUser(organization) &&
        statusMappingForResponse.includes(status as TManualHAQStatusOptions) &&
        row.HAQResponse?.id;

      if (shouldIncludeResponse) {
        targetAssets.push(row.HAQResponse!.id);
        partsToInclude.push(t("HAQ:viewPage.component.QUESTION_RESPONSE"));
      }

      if (row.HAQ && !isEmpty(partsToInclude)) {
        selectedContent.push(
          `${getHAQShortName(
            row.HAQ,
            t("HAQAcronym", { ns: "HAQ" }),
          )} (${partsToInclude.join(", ")})`,
        );
      }
    }

    const isConfirm = await new Promise((resolve) => {
      setChangeStatusModal(
        <ConfirmStatusChangeModalBasic
          isOpen={true}
          selectedContent={selectedContent}
          handleConfirm={resolve}
          confirmationText={t(
            "HAQ:manualStatusChange.confirmIntentOfStatusChange",
          )}
        />,
      );
    });

    setChangeStatusModal(<></>);

    if (!isConfirm) {
      return;
    }

    setWorkflowInProgress(true);

    await changeAssetStatus(
      targetAssets,
      status,
      t("workflowMessages.bulkStatusChangeSuccessMessage", {
        ns: "notifications",
      }),
      t("workflowMessages.bulkStatusChangeErrorMessage", {
        ns: "notifications",
      }),
    );

    setWorkflowInProgress(false);

    shouldEnableTrackingEvent &&
      services.tracking.addBulkTrackingEvent(
        selectedHAQIds.map((HAQId) => ({
          assetId: HAQId,
          assetType: AssetType.Question,
          eventType: EventType.AppHAQChangeStatus,
          projectId: project?.id || "",
        })),
      );
  };
}
