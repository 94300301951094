import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import styles from "./ChosenAssets.styles";

type TChosenItemsProps = {
  content: string[];
};

const ChosenAssets = (props: TChosenItemsProps) => (
  <List>
    {props.content?.map((item, index) => (
      <ListItem sx={styles.listItem} key={index}>
        <ListItemIcon sx={styles.iconContainer}>
          <DescriptionOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={item} />
      </ListItem>
    ))}
  </List>
);

export default ChosenAssets;
