import { ChangeEvent } from "react";
import { FormControl, FormLabel, RadioGroup, Typography } from "@mui/material";
import RadioTile, { TRadioTileProps } from "components/shared/RadioTile";
import styles from "./RadioTileGroup.styles";

type TRadioTileGroupProps = {
  ariaLabel: string;
  formLabel: string;
  name: string;
  defaultValue?: string;
  radios: TRadioTileProps[];
  /**
   * Event handler called when a selection is changed.
   */
  onChange?: (arg0: string) => void;
};

const RadioTileGroup = (props: TRadioTileGroupProps) => {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) =>
    props.onChange?.((event.target as HTMLInputElement).value);

  return (
    <FormControl sx={styles.radioTileGroup}>
      <FormLabel id={props.ariaLabel} sx={styles.formLabel}>
        <Typography>{props.formLabel}</Typography>
      </FormLabel>
      <RadioGroup
        aria-labelledby={props.ariaLabel}
        defaultValue={props.defaultValue}
        name={props.name}
        onChange={handleOnChange}
        sx={styles.radioGroup}
      >
        {props.radios.map((radio) => {
          return (
            <RadioTile
              key={radio.value ? radio.value : radio.title}
              {...radio}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioTileGroup;
