import { memo, useMemo } from "react";
import { cloneDeep, getIsEmptyString } from "utils/helpers";
import {
  CheckboxState,
  flattenOptions,
  generateSelectAllCheckedState,
  getDefaultSelectAllOption,
  getNewSelections,
  ICheckboxOption,
  ICheckboxTreeProps,
  SELECT_ALL_CHECKBOX_OPTION,
  updateOptionsStatus,
} from "./CheckboxTree.utils";
import Option from "./Option";

const CheckboxTree = memo(
  <T extends unknown>({
    options,
    onChange,
    values = [],
    isSelectAllEnabled,
    selectAllSectionsLabel,
    expandButtonLabel,
    isAllOptionsExpanded,
    getValue,
  }: ICheckboxTreeProps<T>) => {
    const allNestedOptions = useMemo(
      () =>
        isSelectAllEnabled
          ? flattenOptions(options).filter(
              (option) =>
                !getIsEmptyString(option.label) &&
                !getIsEmptyString(option.value) &&
                !option.disabled,
            )
          : [],
      [options, isSelectAllEnabled],
    );

    const selectOptions = useMemo(() => {
      const clonedOptions = cloneDeep(options);

      // Update the options with their status (checked, unchecked, indeterminate) based on parent/child relationships
      return updateOptionsStatus(values, clonedOptions);
    }, [options, values]);

    const isNeedToApplyExtraIndent = selectOptions.some(
      (option) => option.children && option.id !== SELECT_ALL_CHECKBOX_OPTION,
    );

    const handleOnChange = (selection: ICheckboxOption) => {
      // Get all new selections based on parent/child relationships
      const newSelections = getNewSelections(values, selection, options);

      onChange?.(newSelections.map((selection) => getValue(selection)));
    };

    const handleSelectAllChange = (selection: ICheckboxOption) => {
      const newSelections =
        selection.checked === CheckboxState.False ||
        selection.checked === CheckboxState.Indeterminate
          ? allNestedOptions
          : [];

      onChange?.(newSelections.map((selection) => getValue(selection)));
    };

    const selectAllOption = useMemo(() => {
      const defaultSelectAll = getDefaultSelectAllOption(
        selectAllSectionsLabel,
      );

      // Update the checked state of Select All based upon values

      return isSelectAllEnabled
        ? generateSelectAllCheckedState(
            values,
            allNestedOptions,
            defaultSelectAll,
          )
        : defaultSelectAll;
    }, [values, allNestedOptions, isSelectAllEnabled, selectAllSectionsLabel]);

    return (
      <>
        {isSelectAllEnabled && (
          <Option
            key={SELECT_ALL_CHECKBOX_OPTION}
            option={selectAllOption}
            depth={0}
            toggle={(selection: ICheckboxOption) =>
              handleSelectAllChange(selection)
            }
          />
        )}
        {selectOptions.map((option) => {
          return (
            !option.hidden && (
              <Option
                key={option.id}
                depth={0}
                option={{ ...option }}
                toggle={(selection: ICheckboxOption) =>
                  handleOnChange(selection)
                }
                expandButtonLabel={expandButtonLabel}
                isExpanded={option.expanded || isAllOptionsExpanded}
                shouldApplyExtraIndent={isNeedToApplyExtraIndent}
              />
            )
          );
        })}
      </>
    );
  },
);

export default CheckboxTree as <T>(
  props: ICheckboxTreeProps<T>,
) => ReturnType<typeof CheckboxTree>;
