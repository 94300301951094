import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { gql } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { TUpdateProductEntityMutationData } from "./useUpdateProductEntity.models";

export const query = gql`
  mutation UpdateProduct($product: EntityProductUpdateBody) {
    updateProduct(product: $product) {
      id
    }
  }
`;

const useUpdateProductEntity = () => {
  const { t } = useTranslation("administration", {
    keyPrefix: "notifications",
  });

  const { showAlert } = useAlerts();
  const queryClient = useQueryClient();

  const { data, isError, isLoading, isSuccess, mutateAsync } = useMutation(
    (product: TUpdateProductEntityMutationData) =>
      services.graphQL
        .request(query, {
          product,
        })
        .then(() => {
          showAlert({
            message: t("updateProductSuccess"),
            severity: AlertSeverity.Success,
          });

          queryClient.invalidateQueries(QueryAPIKey.GetEntityProducts);
        })
        .catch((error) => {
          if (error.response?.errors[0]?.extensions?.response?.status === 409) {
            showAlert({
              message: t("updateProductEntityFailureAlreadyExists"),
              severity: AlertSeverity.Error,
            });

            throw error;
          }

          showAlert({
            message: t("updateProductFailure"),
            severity: AlertSeverity.Error,
          });

          throw error;
        }),
  );

  return { data, isLoading, isError, isSuccess, updateProduct: mutateAsync };
};

export default useUpdateProductEntity;
