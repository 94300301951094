import { TFunction } from "i18next";
import { z } from "zod";
import { IDocument } from "models/documents.models";
import { TGSPComponent } from "models/gsp.models";
import { TISODateFormat } from "utils/types";
import { TOption } from "utils/types";
import {
  DOCUMENT_SCHEMA,
  getGenericFutureDateConstraint,
  MEMBER_SCHEMA,
} from "utils/validators/constraints/validationConstraints";

export enum SendCopyDocumentFieldName {
  Documents = "documents",
  Recipients = "recipients",
  DueDate = "dueDate",
  Instructions = "instructions",
}

export type TSendCopyDocumentForm = {
  [SendCopyDocumentFieldName.Documents]: IDocument[] | TGSPComponent[];
  [SendCopyDocumentFieldName.Recipients]: TOption<string>[];
  [SendCopyDocumentFieldName.DueDate]: TISODateFormat;
  [SendCopyDocumentFieldName.Instructions]: string;
};

const SEND_COPY_GSP_SCHEMA = z.object({
  id: z.string(),
  name: z.string(),
});

export const getSendCopyDocumentSchema = (t: TFunction) =>
  z.object({
    [SendCopyDocumentFieldName.Documents]: z
      .array(DOCUMENT_SCHEMA)
      .min(1, {
        message: t("validations.requiredField", { ns: "common" }),
      })
      .or(
        z.array(SEND_COPY_GSP_SCHEMA).min(1, {
          message: t("validations.requiredField", { ns: "common" }),
        }),
      ),
    [SendCopyDocumentFieldName.Recipients]: z.array(MEMBER_SCHEMA).min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [SendCopyDocumentFieldName.DueDate]: getGenericFutureDateConstraint(t),
    [SendCopyDocumentFieldName.Instructions]: z.string().optional(),
  });
