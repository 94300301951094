import AA from "assets/icons/flags/aa.svg";
import AD from "assets/icons/flags/ad.svg";
import AE from "assets/icons/flags/ae.svg";
import AF from "assets/icons/flags/af.svg";
import AG from "assets/icons/flags/ag.svg";
import AL from "assets/icons/flags/al.svg";
import AM from "assets/icons/flags/am.svg";
import AO from "assets/icons/flags/ao.svg";
import AR from "assets/icons/flags/ar.svg";
import AT from "assets/icons/flags/at.svg";
import AU from "assets/icons/flags/au.svg";
import AZ from "assets/icons/flags/az.svg";
import BA from "assets/icons/flags/ba.svg";
import BB from "assets/icons/flags/bb.svg";
import BD from "assets/icons/flags/bd.svg";
import BE from "assets/icons/flags/be.svg";
import BF from "assets/icons/flags/bf.svg";
import BG from "assets/icons/flags/bg.svg";
import BH from "assets/icons/flags/bh.svg";
import BI from "assets/icons/flags/bi.svg";
import BJ from "assets/icons/flags/bj.svg";
import BN from "assets/icons/flags/bn.svg";
import BO from "assets/icons/flags/bo.svg";
import BR from "assets/icons/flags/br.svg";
import BS from "assets/icons/flags/bs.svg";
import BT from "assets/icons/flags/bt.svg";
import BW from "assets/icons/flags/bw.svg";
import BY from "assets/icons/flags/by.svg";
import BZ from "assets/icons/flags/bz.svg";
import CA from "assets/icons/flags/ca.svg";
import CD from "assets/icons/flags/cd.svg";
import CF from "assets/icons/flags/cf.svg";
import CG from "assets/icons/flags/cg.svg";
import CH from "assets/icons/flags/ch.svg";
import CI from "assets/icons/flags/ci.svg";
import CL from "assets/icons/flags/cl.svg";
import CM from "assets/icons/flags/cm.svg";
import CN from "assets/icons/flags/cn.svg";
import CO from "assets/icons/flags/co.svg";
import CR from "assets/icons/flags/cr.svg";
import CU from "assets/icons/flags/cu.svg";
import CV from "assets/icons/flags/cv.svg";
import CY from "assets/icons/flags/cy.svg";
import CZ from "assets/icons/flags/cz.svg";
import DE from "assets/icons/flags/de.svg";
import DK from "assets/icons/flags/dk.svg";
import DO from "assets/icons/flags/do.svg";
import DZ from "assets/icons/flags/dz.svg";
import EC from "assets/icons/flags/ec.svg";
import EE from "assets/icons/flags/ee.svg";
import EG from "assets/icons/flags/eg.svg";
import ES from "assets/icons/flags/es.svg";
import ET from "assets/icons/flags/et.svg";
import EU from "assets/icons/flags/eu.svg";
import FI from "assets/icons/flags/fi.svg";
import FJ from "assets/icons/flags/fj.svg";
import FR from "assets/icons/flags/fr.svg";
import GA from "assets/icons/flags/ga.svg";
import GE from "assets/icons/flags/ge.svg";
import GH from "assets/icons/flags/gh.svg";
import GM from "assets/icons/flags/gm.svg";
import GN from "assets/icons/flags/gn.svg";
import GR from "assets/icons/flags/gr.svg";
import GT from "assets/icons/flags/gt.svg";
import GW from "assets/icons/flags/gw.svg";
import HK from "assets/icons/flags/hk.svg";
import HN from "assets/icons/flags/hn.svg";
import HR from "assets/icons/flags/hr.svg";
import HT from "assets/icons/flags/ht.svg";
import HU from "assets/icons/flags/hu.svg";
import ID from "assets/icons/flags/id.svg";
import IE from "assets/icons/flags/ie.svg";
import IL from "assets/icons/flags/il.svg";
import IN from "assets/icons/flags/in.svg";
import IQ from "assets/icons/flags/iq.svg";
import IR from "assets/icons/flags/ir.svg";
import IS from "assets/icons/flags/is.svg";
import IT from "assets/icons/flags/it.svg";
import JM from "assets/icons/flags/jm.svg";
import JO from "assets/icons/flags/jo.svg";
import JP from "assets/icons/flags/jp.svg";
import KE from "assets/icons/flags/ke.svg";
import KG from "assets/icons/flags/kg.svg";
import KH from "assets/icons/flags/kh.svg";
import KM from "assets/icons/flags/km.svg";
import KP from "assets/icons/flags/kp.svg";
import KR from "assets/icons/flags/kr.svg";
import KW from "assets/icons/flags/kw.svg";
import KZ from "assets/icons/flags/kz.svg";
import LA from "assets/icons/flags/la.svg";
import LB from "assets/icons/flags/lb.svg";
import LI from "assets/icons/flags/li.svg";
import LK from "assets/icons/flags/lk.svg";
import LR from "assets/icons/flags/lr.svg";
import LS from "assets/icons/flags/ls.svg";
import LT from "assets/icons/flags/lt.svg";
import LU from "assets/icons/flags/lu.svg";
import LV from "assets/icons/flags/lv.svg";
import LY from "assets/icons/flags/ly.svg";
import MA from "assets/icons/flags/ma.svg";
import MC from "assets/icons/flags/mc.svg";
import MD from "assets/icons/flags/md.svg";
import ME from "assets/icons/flags/me.svg";
import MK from "assets/icons/flags/mk.svg";
import ML from "assets/icons/flags/ml.svg";
import MN from "assets/icons/flags/mn.svg";
import MO from "assets/icons/flags/mo.svg";
import MR from "assets/icons/flags/mr.svg";
import MT from "assets/icons/flags/mt.svg";
import MU from "assets/icons/flags/mu.svg";
import MV from "assets/icons/flags/mv.svg";
import MW from "assets/icons/flags/mw.svg";
import MX from "assets/icons/flags/mx.svg";
import MY from "assets/icons/flags/my.svg";
import NA from "assets/icons/flags/na.svg";
import NE from "assets/icons/flags/ne.svg";
import NG from "assets/icons/flags/ng.svg";
import NI from "assets/icons/flags/ni.svg";
import NL from "assets/icons/flags/nl.svg";
import NO from "assets/icons/flags/no.svg";
import NP from "assets/icons/flags/np.svg";
import NZ from "assets/icons/flags/nz.svg";
import OM from "assets/icons/flags/om.svg";
import PA from "assets/icons/flags/pa.svg";
import PE from "assets/icons/flags/pe.svg";
import PG from "assets/icons/flags/pg.svg";
import PH from "assets/icons/flags/ph.svg";
import PK from "assets/icons/flags/pk.svg";
import PL from "assets/icons/flags/pl.svg";
import PS from "assets/icons/flags/ps.svg";
import PT from "assets/icons/flags/pt.svg";
import PY from "assets/icons/flags/py.svg";
import QA from "assets/icons/flags/qa.svg";
import RO from "assets/icons/flags/ro.svg";
import RS from "assets/icons/flags/rs.svg";
import RU from "assets/icons/flags/ru.svg";
import RW from "assets/icons/flags/rw.svg";
import SA from "assets/icons/flags/sa.svg";
import SC from "assets/icons/flags/sc.svg";
import SD from "assets/icons/flags/sd.svg";
import SE from "assets/icons/flags/se.svg";
import SG from "assets/icons/flags/sg.svg";
import SI from "assets/icons/flags/si.svg";
import SK from "assets/icons/flags/sk.svg";
import SL from "assets/icons/flags/sl.svg";
import SN from "assets/icons/flags/sn.svg";
import SR from "assets/icons/flags/sr.svg";
import SS from "assets/icons/flags/ss.svg";
import SV from "assets/icons/flags/sv.svg";
import TD from "assets/icons/flags/td.svg";
import TG from "assets/icons/flags/tg.svg";
import TH from "assets/icons/flags/th.svg";
import TJ from "assets/icons/flags/tj.svg";
import TM from "assets/icons/flags/tm.svg";
import TN from "assets/icons/flags/tn.svg";
import TR from "assets/icons/flags/tr.svg";
import TT from "assets/icons/flags/tt.svg";
import TW from "assets/icons/flags/tw.svg";
import TZ from "assets/icons/flags/tz.svg";
import UA from "assets/icons/flags/ua.svg";
import UG from "assets/icons/flags/ug.svg";
import UK from "assets/icons/flags/uk.svg";
import US from "assets/icons/flags/us.svg";
import UY from "assets/icons/flags/uy.svg";
import UZ from "assets/icons/flags/uz.svg";
import VE from "assets/icons/flags/ve.svg";
import VN from "assets/icons/flags/vn.svg";
import VU from "assets/icons/flags/vu.svg";
import XK from "assets/icons/flags/xk.svg";
import YE from "assets/icons/flags/ye.svg";
import ZA from "assets/icons/flags/za.svg";
import ZM from "assets/icons/flags/zm.svg";
import ZW from "assets/icons/flags/zw.svg";

type TCountryData = {
  name: string;
  flag: string;
};

export type TCountryCode =
  | "AA"
  | "AD"
  | "AE"
  | "AF"
  | "AG"
  | "AL"
  | "AM"
  | "AO"
  | "AR"
  | "AT"
  | "AU"
  | "AZ"
  | "BA"
  | "BB"
  | "BD"
  | "BE"
  | "BF"
  | "BG"
  | "BH"
  | "BI"
  | "BJ"
  | "BN"
  | "BO"
  | "BR"
  | "BS"
  | "BT"
  | "BW"
  | "BY"
  | "BZ"
  | "CA"
  | "CD"
  | "CF"
  | "CG"
  | "CH"
  | "CI"
  | "CL"
  | "CM"
  | "CN"
  | "CO"
  | "CR"
  | "CU"
  | "CV"
  | "CY"
  | "CZ"
  | "DE"
  | "DK"
  | "DO"
  | "DZ"
  | "EC"
  | "EE"
  | "EG"
  | "ES"
  | "ET"
  | "EU"
  | "FI"
  | "FJ"
  | "FR"
  | "GA"
  | "GB"
  | "GE"
  | "GH"
  | "GM"
  | "GN"
  | "GR"
  | "GT"
  | "GW"
  | "HK"
  | "HN"
  | "HR"
  | "HT"
  | "HU"
  | "ID"
  | "IE"
  | "IL"
  | "IN"
  | "IQ"
  | "IR"
  | "IS"
  | "IT"
  | "JM"
  | "JO"
  | "JP"
  | "KE"
  | "KG"
  | "KH"
  | "KM"
  | "KP"
  | "KR"
  | "KW"
  | "KZ"
  | "LA"
  | "LB"
  | "LI"
  | "LK"
  | "LR"
  | "LS"
  | "LT"
  | "LU"
  | "LV"
  | "LY"
  | "MA"
  | "MC"
  | "MD"
  | "ME"
  | "MK"
  | "ML"
  | "MN"
  | "MO"
  | "MR"
  | "MT"
  | "MU"
  | "MV"
  | "MW"
  | "MX"
  | "MY"
  | "NA"
  | "NE"
  | "NG"
  | "NI"
  | "NL"
  | "NO"
  | "NP"
  | "NZ"
  | "OM"
  | "PA"
  | "PE"
  | "PG"
  | "PH"
  | "PK"
  | "PL"
  | "PS"
  | "PT"
  | "PY"
  | "QA"
  | "RO"
  | "RS"
  | "RU"
  | "RW"
  | "SA"
  | "SC"
  | "SD"
  | "SE"
  | "SG"
  | "SI"
  | "SK"
  | "SL"
  | "SN"
  | "SR"
  | "SS"
  | "SV"
  | "TD"
  | "TG"
  | "TH"
  | "TJ"
  | "TM"
  | "TN"
  | "TR"
  | "TT"
  | "TW"
  | "TZ"
  | "UA"
  | "UG"
  | "UK"
  | "US"
  | "UY"
  | "UZ"
  | "VE"
  | "VN"
  | "VU"
  | "XK"
  | "YE"
  | "ZA"
  | "ZM"
  | "ZW";

export type TCountry = Record<TCountryCode, Readonly<TCountryData>>;

const COUNTRIES: Readonly<TCountry> = {
  AA: { name: "aa", flag: AA },
  AD: { name: "ad", flag: AD },
  AE: { name: "ae", flag: AE },
  AF: { name: "af", flag: AF },
  AG: { name: "ag", flag: AG },
  AL: { name: "al", flag: AL },
  AM: { name: "am", flag: AM },
  AO: { name: "ao", flag: AO },
  AR: { name: "ar", flag: AR },
  AT: { name: "at", flag: AT },
  AU: { name: "au", flag: AU },
  AZ: { name: "az", flag: AZ },
  BA: { name: "ba", flag: BA },
  BB: { name: "bb", flag: BB },
  BD: { name: "bd", flag: BD },
  BE: { name: "be", flag: BE },
  BF: { name: "bf", flag: BF },
  BG: { name: "bg", flag: BG },
  BH: { name: "bh", flag: BH },
  BI: { name: "bi", flag: BI },
  BJ: { name: "bj", flag: BJ },
  BN: { name: "bn", flag: BN },
  BO: { name: "bo", flag: BO },
  BR: { name: "br", flag: BR },
  BS: { name: "bs", flag: BS },
  BT: { name: "bt", flag: BT },
  BW: { name: "bw", flag: BW },
  BY: { name: "by", flag: BY },
  BZ: { name: "bz", flag: BZ },
  CA: { name: "ca", flag: CA },
  CD: { name: "cd", flag: CD },
  CF: { name: "cf", flag: CF },
  CG: { name: "cg", flag: CG },
  CH: { name: "ch", flag: CH },
  CI: { name: "ci", flag: CI },
  CL: { name: "cl", flag: CL },
  CM: { name: "cm", flag: CM },
  CN: { name: "cn", flag: CN },
  CO: { name: "co", flag: CO },
  CR: { name: "cr", flag: CR },
  CU: { name: "cu", flag: CU },
  CV: { name: "cv", flag: CV },
  CY: { name: "cy", flag: CY },
  CZ: { name: "cz", flag: CZ },
  DE: { name: "de", flag: DE },
  DK: { name: "dk", flag: DK },
  DO: { name: "do", flag: DO },
  DZ: { name: "dz", flag: DZ },
  EC: { name: "ec", flag: EC },
  EE: { name: "ee", flag: EE },
  EG: { name: "eg", flag: EG },
  ES: { name: "es", flag: ES },
  ET: { name: "et", flag: ET },
  EU: { name: "eu", flag: EU },
  FI: { name: "fi", flag: FI },
  FJ: { name: "fj", flag: FJ },
  FR: { name: "fr", flag: FR },
  GA: { name: "ga", flag: GA },
  GB: { name: "gb", flag: UK },
  GE: { name: "ge", flag: GE },
  GH: { name: "gh", flag: GH },
  GM: { name: "gm", flag: GM },
  GN: { name: "gn", flag: GN },
  GR: { name: "gr", flag: GR },
  GT: { name: "gt", flag: GT },
  GW: { name: "gw", flag: GW },
  HK: { name: "hk", flag: HK },
  HN: { name: "hn", flag: HN },
  HR: { name: "hr", flag: HR },
  HT: { name: "ht", flag: HT },
  HU: { name: "hu", flag: HU },
  ID: { name: "id", flag: ID },
  IE: { name: "ie", flag: IE },
  IL: { name: "il", flag: IL },
  IN: { name: "in", flag: IN },
  IQ: { name: "iq", flag: IQ },
  IR: { name: "ir", flag: IR },
  IS: { name: "is", flag: IS },
  IT: { name: "it", flag: IT },
  JM: { name: "jm", flag: JM },
  JO: { name: "jo", flag: JO },
  JP: { name: "jp", flag: JP },
  KE: { name: "ke", flag: KE },
  KG: { name: "kg", flag: KG },
  KH: { name: "kh", flag: KH },
  KM: { name: "km", flag: KM },
  KP: { name: "kp", flag: KP },
  KR: { name: "kr", flag: KR },
  KW: { name: "kw", flag: KW },
  KZ: { name: "kz", flag: KZ },
  LA: { name: "la", flag: LA },
  LB: { name: "lb", flag: LB },
  LI: { name: "li", flag: LI },
  LK: { name: "lk", flag: LK },
  LR: { name: "lr", flag: LR },
  LS: { name: "ls", flag: LS },
  LT: { name: "lt", flag: LT },
  LU: { name: "lu", flag: LU },
  LV: { name: "lv", flag: LV },
  LY: { name: "ly", flag: LY },
  MA: { name: "ma", flag: MA },
  MC: { name: "mc", flag: MC },
  MD: { name: "md", flag: MD },
  ME: { name: "me", flag: ME },
  MK: { name: "mk", flag: MK },
  ML: { name: "ml", flag: ML },
  MN: { name: "mn", flag: MN },
  MO: { name: "mo", flag: MO },
  MR: { name: "mr", flag: MR },
  MT: { name: "mt", flag: MT },
  MU: { name: "mu", flag: MU },
  MV: { name: "mv", flag: MV },
  MW: { name: "mw", flag: MW },
  MX: { name: "mx", flag: MX },
  MY: { name: "my", flag: MY },
  NA: { name: "na", flag: NA },
  NE: { name: "ne", flag: NE },
  NG: { name: "ng", flag: NG },
  NI: { name: "ni", flag: NI },
  NL: { name: "nl", flag: NL },
  NO: { name: "no", flag: NO },
  NP: { name: "np", flag: NP },
  NZ: { name: "nz", flag: NZ },
  OM: { name: "om", flag: OM },
  PA: { name: "pa", flag: PA },
  PE: { name: "pe", flag: PE },
  PG: { name: "pg", flag: PG },
  PH: { name: "ph", flag: PH },
  PK: { name: "pk", flag: PK },
  PL: { name: "pl", flag: PL },
  PS: { name: "ps", flag: PS },
  PT: { name: "pt", flag: PT },
  PY: { name: "py", flag: PY },
  QA: { name: "qa", flag: QA },
  RO: { name: "ro", flag: RO },
  RS: { name: "rs", flag: RS },
  RU: { name: "ru", flag: RU },
  RW: { name: "rw", flag: RW },
  SA: { name: "sa", flag: SA },
  SC: { name: "sc", flag: SC },
  SD: { name: "sd", flag: SD },
  SE: { name: "se", flag: SE },
  SG: { name: "sg", flag: SG },
  SI: { name: "si", flag: SI },
  SK: { name: "sk", flag: SK },
  SL: { name: "sl", flag: SL },
  SN: { name: "sn", flag: SN },
  SR: { name: "sr", flag: SR },
  SS: { name: "ss", flag: SS },
  SV: { name: "sv", flag: SV },
  TD: { name: "td", flag: TD },
  TG: { name: "tg", flag: TG },
  TH: { name: "th", flag: TH },
  TJ: { name: "tj", flag: TJ },
  TM: { name: "tm", flag: TM },
  TN: { name: "tn", flag: TN },
  TR: { name: "tr", flag: TR },
  TT: { name: "tt", flag: TT },
  TW: { name: "tw", flag: TW },
  TZ: { name: "tz", flag: TZ },
  UA: { name: "ua", flag: UA },
  UG: { name: "ug", flag: UG },
  UK: { name: "uk", flag: UK },
  US: { name: "us", flag: US },
  UY: { name: "uy", flag: UY },
  UZ: { name: "uz", flag: UZ },
  VE: { name: "ve", flag: VE },
  VN: { name: "vn", flag: VN },
  VU: { name: "vu", flag: VU },
  XK: { name: "xk", flag: XK },
  YE: { name: "ye", flag: YE },
  ZA: { name: "za", flag: ZA },
  ZM: { name: "zm", flag: ZM },
  ZW: { name: "zw", flag: ZW },
};

export default COUNTRIES;
