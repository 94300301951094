import { z } from "zod";
import { IDocument } from "models/documents.models";
import {
  DOCUMENT_SCHEMA,
  MEMBER_SCHEMA,
} from "utils/validators/constraints/validationConstraints";
import { SubmitDocumentFieldName } from "./SubmitDocuments.types";

export const getSubmitDocumentsDefaultValues = (
  documentsToSubmit: IDocument[],
) => ({
  [SubmitDocumentFieldName.Documents]: documentsToSubmit,
  [SubmitDocumentFieldName.Recipients]: [],
  [SubmitDocumentFieldName.Message]: "",
});

export const submitDocumentsSchema = z.object({
  [SubmitDocumentFieldName.Documents]: z
    .array(DOCUMENT_SCHEMA)
    .refine((val) => val.length),
  [SubmitDocumentFieldName.Recipients]: z
    .array(MEMBER_SCHEMA)
    .refine((val) => val.length),
  [SubmitDocumentFieldName.Message]: z.string().optional(),
});
