import { Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { TDictionary } from "screens/EntityManagement/components/Products/Products.types";
import { filterByArrayIncludesExactly } from "utils/helpers/filterFunctions";
import {
  OrganizationsTable,
  TOrganizationsRowData,
} from "./OrganizationsTable.types";

const columnHelper = createColumnHelper<TOrganizationsRowData>();

export const getColumns = (t: TFunction, countriesList: TDictionary[]) => [
  columnHelper.accessor(OrganizationsTable.Id, {
    header: "",
    enableColumnFilter: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor(OrganizationsTable.Name, {
    header: t("organizationColumns.name"),
    filterFn: "arrIncludes",
  }),
  columnHelper.accessor(OrganizationsTable.Alias, {
    header: t("organizationColumns.alias"),
    filterFn: "arrIncludes",
  }),
  columnHelper.accessor(OrganizationsTable.Type, {
    header: t("organizationColumns.type"),
    filterFn: filterByArrayIncludesExactly,
    cell: ({ cell: { getValue } }) => (
      <Typography variant="body2">
        {t(`organization.types.${getValue()}`)}
      </Typography>
    ),
  }),
  columnHelper.accessor(OrganizationsTable.Address, {
    header: t("organizationColumns.address"),
    filterFn: "arrIncludes",
  }),
  columnHelper.accessor(OrganizationsTable.City, {
    header: t("organizationColumns.city"),
    filterFn: "arrIncludes",
  }),
  columnHelper.accessor(OrganizationsTable.ZipCode, {
    header: t("organizationColumns.zipCode"),
    filterFn: "arrIncludes",
  }),
  columnHelper.accessor(OrganizationsTable.Country, {
    header: t("organizationColumns.country"),
    filterFn: filterByArrayIncludesExactly,
    cell: ({ cell: { getValue } }) => (
      <Typography variant="body2">
        {countriesList.find((country) => country.code === getValue())?.name}
      </Typography>
    ),
  }),
  columnHelper.accessor(OrganizationsTable.Status, {
    header: t("organizationColumns.status"),
    cell: ({ cell: { getValue } }) => (
      <Typography variant="body2">{t(`${getValue()}`)}</Typography>
    ),
    filterFn: filterByArrayIncludesExactly,
  }),
  columnHelper.display({
    header: t("organizationColumns.actions"),
    meta: {
      width: "5%",
    },
    cell: ({ row }) => row.original.actions,
  }),
];
