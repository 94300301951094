import { TStylesObject } from "utils/Styles";

const styles: TStylesObject = {
  dateTag: {
    height: 26,
    paddingY: 0.5,
    paddingX: 1,
    display: "inline-flex",
    alignItems: "center",
    maxWidth: "100%",
  },
};

export default styles;
