export enum TaskEvent {
  ReadyHASubmission = "ready-for-ha-submission",
  CompleteOrbisReview = "complete-orbis-review",
  Complete = "complete",
  Acquire = "acquire",
  Submit = "submit",
  Accept = "accept",
  Reject = "reject",
  Cancel = "cancel",
}

export enum TaskType {
  AdHoc = "ad-hoc", // Arbitrary project tasks
  CreateGSP = "fulfill-gsp", // GSP is created
  AcceptGSP = "orbis-gsp-acceptance", // HA receives task to review submitted GSP
  SubmitGSP = "submit-gsp", // GSP status changes from Draft to Final
  FirstToAcquire = "acquire-task", // Related to all first to Acquire task
  CompleteReview = "orbis-complete-review", // AAID chunks review requested from sponsor
  AcceptAssetShare = "orbis-asset-acceptance", // Asset is being shared
  AcceptHAQCopy = "haq-acceptance", // HAQ is sent from HA to Sponsor for response
  AcceptHAQExternalReview = "question-complete-review", // HAQ is being sent from Host HA to PHA for review
  AcceptHAAssets = "orbis-assets-acceptance", // Assets are being shared
  QCCompleteReview = "qc-complete-review", // User initiates Start Quality Control for asset in status Draft or Reviewed
  HAQRegisterInterest = "question-register-interest", // ToDo (AV): Alex N still figures it out. Circle back on it.
  HAQProvideCommentsSuggestions = "question-provide-comments-suggestions", // ToDo (AV): Alex N still figures it out. Circle back on it
}

export enum TaskAssignType {
  AssignToAll = "ASSIGN_TO_ALL",
  FirstToAcquire = "FIRST_TO_ACQUIRE",
}

export enum TaskStatus {
  Acquired = "ACQUIRED",
  NotAcquired = "NOT_ACQUIRED",
  Pending = "PENDING",
  InProgress = "IN_PROGRESS",
  Complete = "COMPLETE",
  Cancelled = "CANCELLED",
}

export const ACTIVE_TASKS_STATUSES = [
  TaskStatus.Pending,
  TaskStatus.InProgress,
] as const;

export const RESOLVED_TASKS_STATUSES = [
  TaskStatus.Cancelled,
  TaskStatus.Complete,
] as const;

export const PROJECT_TASK_TYPES = [
  TaskType.AdHoc,
  TaskType.FirstToAcquire,
] as const;

// TODO: deprecated `tasks.skipCompleteConfirmation` is used ONLY for REST updateUser endpoint in `TaskDrawer/TaskDetails_deprecated`
export const TASK_SKIP_PREFERENCE_DEPRECATED = "tasks.skipCompleteConfirmation";

export const NUMBER_OF_VISIBLE_PRIORITY_TASKS = 5;

export const NUMBER_OF_VISIBLE_SUMMARY_TASKS = 3;

export const DEFAULT_TASKS_SORT = "active,desc;dueDate,asc;status";

export const CUSTOM_DUE_DATE_SORT = {
  asc: DEFAULT_TASKS_SORT,
  desc: "active,desc;dueDate,desc;status",
};

export const TASK_CANCELATION_REASON_MAX_LENGTH = 255;

export enum ReassignAssigneeStep {
  Select,
  Reassign,
}
