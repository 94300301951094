import { useMemo } from "react";
import { FieldValues } from "react-hook-form";
import {
  FormMultiSelectTypeahead,
  TFormMultiSelectTypeaheadProps,
} from "components/common/form-fields";
import { IDocumentOutline } from "models/documents.models";
import { mapDocumentToCheckboxOption } from "utils/helpers";

type TDocumentDropDownOption = {
  dropdownOptions: IDocumentOutline[] | undefined;
};

export type TDocumentSectionsSelectProps<FormType extends FieldValues> = Omit<
  TFormMultiSelectTypeaheadProps<FormType, string>,
  "options" | "getValue"
> &
  TDocumentDropDownOption;

function DocumentSectionsSelect<FormType extends FieldValues>({
  dropdownOptions,
  name,
  ...props
}: TDocumentSectionsSelectProps<FormType>) {
  const options = useMemo(
    () => mapDocumentToCheckboxOption(dropdownOptions),
    [dropdownOptions],
  );

  return (
    <FormMultiSelectTypeahead<FormType, string>
      name={name}
      options={options}
      {...props}
    />
  );
}

export default DocumentSectionsSelect;
