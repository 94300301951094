import { MutationCache, QueryCache, QueryClient } from "react-query";

const queryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetech: 3,
    },
  },
  mutationCache: new MutationCache({
    onError: () => {},
  }),
  queryCache: new QueryCache({
    onError: () => {},
  }),
};

export const queryClient = new QueryClient(queryClientConfig);
