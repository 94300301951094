import { createContext, useContext, useMemo, useState } from "react";

type TGSPContextValue = {
  activeGSPItem: string;
  setActiveGSPItem: React.Dispatch<React.SetStateAction<string>>;
};

type TGSPProvider = {
  children: React.ReactNode;
  defaultActiveGSPItem?: string;
};

const GSPContext = createContext<undefined | TGSPContextValue>(undefined);

export const useGSPContext = () => {
  const context = useContext(GSPContext);

  if (context === undefined) {
    throw new Error("useGSPContext must be used within an GSPProvider");
  }

  return context;
};

const GSPProvider = ({ children, defaultActiveGSPItem = "" }: TGSPProvider) => {
  const [activeGSPItem, setActiveGSPItem] =
    useState<string>(defaultActiveGSPItem);

  const value = useMemo(
    () => ({
      activeGSPItem,
      setActiveGSPItem,
    }),
    [activeGSPItem, setActiveGSPItem],
  );

  return <GSPContext.Provider value={value}>{children}</GSPContext.Provider>;
};

export default GSPProvider;
