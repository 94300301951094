import {
  INotification,
  NotificationCTAType,
} from "models/notificationItem.models";

export const getShouldShowNotification = (
  notificationItem: INotification,
  shouldShowTasks: boolean,
) => {
  return (
    shouldShowTasks ||
    notificationItem.metadata.ctaType !== NotificationCTAType.Task
  );
};
