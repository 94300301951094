import { TStyles } from "utils/Styles";

const styles: TStyles = {
  form: {
    maxHeight: "240px",
    overflowX: "auto",
    paddingRight: 1,
  },

  submitButton: {
    minWidth: "75px",
    minHeight: "36px",
  },
};

export default styles;
