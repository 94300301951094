import { OktaAuth } from "@okta/okta-auth-js";
import getEnv from "env";
import last from "lodash/last";
import { Path } from "router/paths";
import { signOut } from "./signOut";

const oktaAuth = (() => {
  // ToDo (AV): Eventually this will be removed in favor of using the whole ISSUER URI variable
  const issuerId = last(getEnv().issuerURI.split("/")) || "default";

  return new OktaAuth({
    issuer: `https://${getEnv().oktaDomain}/oauth2/${issuerId}`,
    clientId: getEnv().oktaClientId,
    redirectUri: `${window.location.origin}${Path.LoginCallback}`,
    postLogoutRedirectUri: `${window.location.origin}${Path.Login}`,
    // "offline_access" is necessary to activate the refresh token mechanism.
    scopes: ["offline_access", "openid", "email", "profile"],
    storageManager: {
      token: {
        storageProvider: sessionStorage,
      },
    },
  });
})();

oktaAuth.tokenManager.on("error", function () {
  signOut({
    shouldRestorePath: true,
    shouldLogoutEverywhere: true,
  }).catch(console.error);
});

export { oktaAuth };
