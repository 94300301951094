import { AxiosResponse } from "axios";
import { post, routes } from "api";
import {
  ITrackingEventRequestData,
  TTrackingEventData,
} from "models/tracking.models";

const getRequestData = ({
  eventType,
  projectId,
  assetId,
  assetType,
}: TTrackingEventData): ITrackingEventRequestData => ({
  eventType,
  projectId,
  assetId,
  eventData: {
    assetType,
  },
});

const tracking = {
  addTrackingEvent(data: TTrackingEventData): Promise<AxiosResponse> {
    return post(routes.tracking.trackingEvent(), {
      data: getRequestData(data),
    });
  },

  addBulkTrackingEvent(data: TTrackingEventData[]): Promise<AxiosResponse> {
    return post(routes.tracking.bulkTrackingEvent(), {
      data: data.map(getRequestData),
    });
  },
};

export default tracking;
