import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { gql, Variables } from "graphql-request";
import useShowAlert from "hooks/useShowAlert";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { ISubstancesResponse } from "./useSubstances.models";

export const query = gql`
  query Substances {
    substances {
      id
      name
      drugClassType {
        id
        code
        name
      }
      INN
      ATC
      linkedOrganizations {
        id
        name
        organizationType
        roleTypes
      }
      actions {
        canChangeStatus
        canEditEntity
      }
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
    }
  }
`;

const useSubstances = (enabled: boolean = true) => {
  const { t } = useTranslation("administration");

  const { data, status, isLoading, isSuccess } = useQuery<ISubstancesResponse>(
    [QueryAPIKey.GetSubstances],
    () => services.graphQL.request<ISubstancesResponse, Variables>(query),
    { enabled },
  );

  useShowAlert({
    status,
    failureMessage: t("notifications.getSubstancesFailure"),
  });

  return {
    data: data?.substances ?? [],
    isLoading,
    isSuccess,
  };
};

export default useSubstances;
