import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { IUpdateCommentRequestData } from "models/annotations.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export type TCommentForUpdate = {
  componentId: string;
  threadId: string;
  commentId: string;
  requestData: IUpdateCommentRequestData;
};

const useUpdateComment = () => {
  const queryClient = useQueryClient();

  const { t } = useTranslation("annotations", { keyPrefix: "notifications" });

  const updateCommentMutation = useMutation(
    ["updateComment"],
    (mutationData: TCommentForUpdate) => {
      const { componentId, threadId, commentId, requestData } = mutationData;

      return services.documents.comments.updateComment(
        componentId,
        threadId,
        commentId,
        requestData,
      );
    },
    {
      onSuccess: (data, { threadId }) => {
        queryClient.invalidateQueries([QueryAPIKey.GetCommentThread, threadId]);
      },
    },
  );

  useShowAlert({
    status: updateCommentMutation.status,
    failureMessage: t("updateCommentFailure"),
  });

  return updateCommentMutation;
};

export default useUpdateComment;
