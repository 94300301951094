export enum ProductFamilyEntityFieldName {
  Name = "name",
  Description = "description",
  Status = "status",
}

export type TProductFamilyEntityForm = {
  [ProductFamilyEntityFieldName.Name]: string;
  [ProductFamilyEntityFieldName.Description]: string;
  [ProductFamilyEntityFieldName.Status]: string;
};
