import { useMemo } from "react";
import { useInView } from "react-cool-inview";
import { useTranslation } from "react-i18next";
import {
  Box,
  Drawer,
  DrawerProps,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import Button from "components/shared/Button";
import Loading from "components/shared/Loading";
import { useShouldShowTasks } from "hooks/access";
import useGetNotificationsForUser from "hooks/api/REST/notifications/useGetNotificationsForUser";
import useMarkAllNotifications from "hooks/api/REST/notifications/useMarkAllNotifications";
import useUser from "hooks/useUser";
import { getShouldShowNotification } from "./Notifications.utils";
import NotificationsListItem from "./NotificationsListItem";
import { ReactComponent as XCloseIcon } from "assets/images/x-close.svg";
import styles from "./Notifications.styles";

type TNotificationsDrawerProps = Omit<
  DrawerProps,
  "anchor" | "onClose" | "sx" | "variant"
> & {
  onClose?: () => void;
  isOpen: boolean;
};

const NOTIFICATIONS_SIZE = 10;

const Notifications = ({
  onClose,
  isOpen,
  ...props
}: TNotificationsDrawerProps) => {
  const { user: currentUser } = useUser();

  const { t } = useTranslation("user", {
    keyPrefix: "notificationsMetaMessages",
  });

  const shouldShowTasks = useShouldShowTasks();

  const { notifications, fetchNextPage, hasNextPage } =
    useGetNotificationsForUser(currentUser?.id, {
      size: NOTIFICATIONS_SIZE,
    });

  const filteredNotifications = useMemo(
    () =>
      notifications?.filter((notification) =>
        getShouldShowNotification(notification, shouldShowTasks),
      ),
    [notifications, shouldShowTasks],
  );

  const { markAllNotificationsRead } = useMarkAllNotifications();

  const { observe } = useInView({
    onEnter: fetchNextPage,
  });

  return (
    <Drawer
      anchor="left"
      variant="persistent"
      role="alertdialog"
      aria-label={t("title")}
      sx={styles.drawer}
      onClose={onClose}
      open={isOpen}
      {...props}
    >
      <Stack direction="column" sx={styles.container}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={styles.headerContainer}
          spacing={1}
        >
          <Typography variant="h6" data-qaid="notifications-list-title">
            {t("title")}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Button
              aria-label={t("markAllButtonAriaLabel")}
              variant="text"
              onClick={markAllNotificationsRead}
              size="small"
              data-qaid="notifications-list-mark-all-as-read-button"
            >
              {t("markAllButton")}
            </Button>
            <IconButton
              aria-label={t("closeXButtonAriaLabel")}
              onClick={onClose}
              size="small"
              data-qaid="notifications-list-panel-close-button"
            >
              <XCloseIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Box sx={styles.notificationsListContainer}>
          <List disablePadding data-qaid="notification-items-list">
            {filteredNotifications?.map((notificationItem) => (
              <ListItem
                key={notificationItem.id}
                disablePadding
                data-qaid="notifications-list-item"
              >
                <NotificationsListItem data={notificationItem} />
              </ListItem>
            ))}
            {hasNextPage && (
              <ListItem sx={styles.loadingItem} ref={observe}>
                <Loading />
              </ListItem>
            )}
          </List>
        </Box>
      </Stack>
    </Drawer>
  );
};

export default Notifications;
