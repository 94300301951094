import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useOktaAuth } from "@okta/okta-react";
import { Path } from "router";

export const useSessionCheck = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authState && !authState?.isAuthenticated) {
      oktaAuth.session
        .exists()
        .then((exists) => {
          oktaAuth.setOriginalUri(window.location.pathname);

          return exists ? oktaAuth.signInWithRedirect() : navigate(Path.Login);
        })
        .catch(console.error);
    }
  }, [oktaAuth, authState, navigate]);

  return { authState };
};
