import { IProjectData } from "hooks/api/GQL/project/useProjectData.models";
import { deriveProjectConfiguration } from "hooks/permissions/utils/projectConfiguration";
import { IDocumentDTO } from "models/documents.models";
import { TransferField } from "utils/constants/assets.constants";
import { DocumentSubtype, ENG_LANG_CODE } from "utils/constants/doc.constants";
import { FHIRResourceType } from "utils/constants/fhir.constants";
import { ISO_DATE_FORMAT } from "utils/constants/format.constants";
import { formatDate } from "utils/helpers";

type TUploadFormData = {
  file: File;
  subtype: DocumentSubtype;
  domain: string;
  name: string;
  litigationHold: boolean;
  language: string;
  projectIds: string;
  type?: DocumentSubtype;
  group: string;
  author?: string;
  submissionDate?: string;
  formalSubmissionDate?: string;
  applicableHealthAuthorityTenantId?: string;
  resourceType?: FHIRResourceType;
};

const formatToFormData = (
  data: TUploadFormData,
  project: IProjectData | null | undefined,
): FormData => {
  const formData = new FormData();
  const name = data.name || "";
  const file = data?.file as unknown as Blob;

  const isAAID = data.subtype === DocumentSubtype.AssessmentAidSource;
  const isReference = data.subtype === DocumentSubtype.References;
  const isLOA = data.subtype === DocumentSubtype.LOA;
  const isFHIR = data.subtype === DocumentSubtype.FHIR;

  const documentDTO: IDocumentDTO = {
    type: data.subtype,
    name,
    projectIds: [String(data?.projectIds)],
    metadata: {
      group: data?.group,
      subgroup: deriveProjectConfiguration(project?.type)[
        "shouldUseDocumentSubtype"
      ]
        ? data.subtype
        : DocumentSubtype.References,
      domain: data.domain,
      language: ENG_LANG_CODE,
      author: data?.author ? String(data?.author) : undefined,
      litigationHold: data.litigationHold,
    },
  };

  if (isReference || isLOA) {
    documentDTO.metadata = {
      ...documentDTO.metadata,
      submissionDate: formatDate(data?.formalSubmissionDate, ISO_DATE_FORMAT),
    };
  }

  if (isAAID) {
    documentDTO.metadata = {
      ...documentDTO.metadata,
      submissionDate: formatDate(data?.submissionDate, ISO_DATE_FORMAT),
    };
  }

  if (isLOA) {
    documentDTO.metadata = {
      ...documentDTO.metadata,
      applicableHealthAuthorityTenantId: String(
        data.applicableHealthAuthorityTenantId,
      ),
    };
  }

  if (isFHIR) {
    formData.append(TransferField.File, file);
  } else {
    // converting the documentDTO object to Blob in order for the BE to consume it - as per BE requirements
    // setting type: "application/json" is also BE requirement
    const assetInfo = new Blob([JSON.stringify(documentDTO)], {
      type: "application/json",
    });

    if (file && typeof file === "object") {
      formData.append(TransferField.AssetDTO, assetInfo);
      formData.append(TransferField.File, file);
    }
  }

  return formData;
};

export default formatToFormData;
