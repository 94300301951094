import { useTranslation } from "react-i18next";
import { useQuery, UseQueryOptions } from "react-query";
import { TFunction } from "i18next";
import { TShowAlert, useAlerts } from "context/AlertContext";
import { IWorkflowExecution } from "models/workflow.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export async function getWorkflowExecution(
  t: TFunction,
  showAlert: TShowAlert,
  id: string,
) {
  try {
    const response = await services.workflows.getWorkflowExecution(id);

    return response.data;
  } catch (error) {
    showAlert({
      message: t("notifications.getWorkflowExecutionStatusFailure"),
      severity: "error",
    });

    throw error;
  }
}

export default function useGetWorkflowExecution(
  id: string,
  options?: UseQueryOptions<IWorkflowExecution, Error>,
) {
  const { t } = useTranslation("documents");
  const { showAlert } = useAlerts();

  const { data, error, isLoading, isError, isSuccess } = useQuery<
    IWorkflowExecution,
    Error
  >(
    [QueryAPIKey.GetWorkflow, id],
    () => getWorkflowExecution(t, showAlert, id),
    options,
  );

  return {
    workflowInfoResponse: data,
    isLoading,
    isError,
    isSuccess,
    error,
  };
}
