import { Column } from "@tanstack/react-table";

export type TRowAlign = "top" | "middle" | "bottom";

// Here we can extend our columns with arbitrary properties
export type TColumn<T extends object = {}> = Column<T> & { title?: string };

export enum TableSearchURLParams {
  Tab = "tableTab",
}

export type TRowSpanColumn = {
  [key: string]: {
    depth: number;
    rowSpan: number;
  };
};
