import { TOption } from "utils/types";

type TSourceItem<T> = T & { id?: string; name?: string };

function formatValuesToOptions<T = Record<string, string>, D = string>(
  source: TSourceItem<T>[] | undefined,
  getLabel = (item: TSourceItem<T>) => item?.name || "",
  getValue = (item: TSourceItem<T>) => item?.id || "",
): TOption<D | string>[] {
  return (
    source?.map((item) => {
      return {
        label: getLabel(item),
        value: getValue(item),
      };
    }) || []
  );
}

export default formatValuesToOptions;
