import { TStyles } from "utils/Styles";

const styles: TStyles = {
  button: {
    position: "relative",
  },

  loading: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  hidden: {
    color: "transparent",
  },
};

export default styles;
