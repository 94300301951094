import {
  SxProps,
  Tab as MuiTab,
  TabProps as MUITabProps,
  Theme,
} from "@mui/material";
import styles from "./Tab.style";

type TTabProps = MUITabProps<
  React.ElementType,
  {
    component?: React.ElementType;
  }
> & {
  sx?: SxProps<Theme>;
};

const Tab = ({ sx, ...props }: TTabProps) => {
  const tabStyles = {
    ...styles.tab,
    ...sx,
  };

  return (
    <MuiTab sx={tabStyles} {...props}>
      {props.children}
    </MuiTab>
  );
};

export default Tab;
