import { UserClaims } from "@okta/okta-auth-js";
import getEnv from "env";
import isEmpty from "lodash/isEmpty";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import { GroupClaim } from "utils/constants/permissions.constants";
import { isHAUser } from "utils/user/organization";
import { TAccessCheck, TAccessCheckParams } from "./accessChecks.types";

export const getIsFeatureOn =
  (featureFlag: string) =>
  ({ featureFlags }: Pick<TAccessCheckParams, "featureFlags">) =>
    featureFlags[featureFlag] === FeatureFlag.On;

export const shouldShowTasks: TAccessCheck = ({ user, featureFlags }) => {
  return (
    !isHAUser(user.organization) ||
    getIsFeatureOn("showTasksForHA")({ featureFlags })
  );
};

export const shouldShowProjectMembersEditAccessCTA: TAccessCheck = ({
  user,
  featureFlags,
}) =>
  !isHAUser(user.organization) ||
  getIsFeatureOn("FTE-19419_enableNewMemberManagementFeatures")({
    featureFlags,
  });

export const canAccessRegularApp: TAccessCheck = ({ user }) =>
  user.isProgramAdministrator ||
  user.isStandardUser ||
  user.isGlobalPM ||
  user.isCEOnAnyOfTheProjects;

export const isPreProd = () => getEnv().environmentType === "PREPROD";

export const isAdmin: TAccessCheck = ({ user }) =>
  user.isOrganizationAdmin ||
  user.isAccumulusAdmin ||
  user.isBusinessAdmin ||
  user.isProgramAdministrator;

export const canManageUsers: TAccessCheck = ({ user }) =>
  user.isOrganizationAdmin || user.isAccumulusAdmin;

export const canViewEntities: TAccessCheck = ({ user, featureFlags }) =>
  (user.isOrganizationAdmin ||
    user.isBusinessAdmin ||
    user.isProgramAdministrator) &&
  !isHAUser(user.organization) &&
  featureFlags["FTE-21267_enableEntityManagement"] === FeatureFlag.On;

export const canManageEntities: TAccessCheck = ({ user, featureFlags }) =>
  canViewEntities({ user, featureFlags }) && user.isBusinessAdmin;

export const canManageAuditTrail: TAccessCheck = ({ user, featureFlags }) =>
  user.isOrganizationAdmin &&
  featureFlags["FTE-24984_showAuditTrail"] === FeatureFlag.On;

export const canResetEnvironmentData: TAccessCheck = ({ user }) =>
  isPreProd() && user.isAccumulusAdmin;

export const canUseApp: TAccessCheck = ({ user }) =>
  isEmpty(user.acknowledgements);

export const hasLegalAcknowledgments: TAccessCheck = ({ user }) =>
  !isEmpty(user.acknowledgements);

export const getAccessByGroupClaim =
  (targetGroup: GroupClaim) =>
  ({ authState }: Pick<TAccessCheckParams, "authState">) => {
    const claims: UserClaims<{ groups?: string[] }> | undefined =
      authState?.accessToken?.claims;

    return (
      claims?.groups?.some((group: string) => group === targetGroup) ?? false
    );
  };

export const canManageFeatureFlags = getAccessByGroupClaim(
  GroupClaim.FeatureFlagsAdmins,
);

export const canOnlyReadFeatureFlags = getAccessByGroupClaim(
  GroupClaim.FeatureFlagsReadOnlyAccess,
);
