import { useTranslation } from "react-i18next";
import { AssetPageHeader } from "components/shared";
import { TMenuOption } from "components/shared/ButtonMenu";
import { TDocumentViewMode } from "models/documents.models";
import { DocumentSubtype } from "utils/constants/doc.constants";
import { DocumentHeaderActions } from "./DocumentHeaderActions";

export type TDocumentHeaderProps = {
  actionOptions: TMenuOption[];
  activeActionOptionId?: string;
  documentName?: string;
  viewMode: TDocumentViewMode;
  isActionsDisabled?: boolean;
  onViewModeChange: (mode: TDocumentViewMode) => void;
  documentSubtype?: DocumentSubtype;
};

export const DocumentHeader = ({
  actionOptions,
  documentName,
  viewMode,
  onViewModeChange,
  isActionsDisabled,
  activeActionOptionId,
  documentSubtype,
}: TDocumentHeaderProps) => {
  const { t } = useTranslation("documents", {
    keyPrefix: "document",
  });

  return (
    <AssetPageHeader
      title={documentName || t("documentNameDefault")}
      actions={
        <DocumentHeaderActions
          activeActionOptionId={activeActionOptionId}
          actionOptions={actionOptions}
          onViewModeChange={onViewModeChange}
          viewMode={viewMode}
          isActionsDisabled={isActionsDisabled}
          documentSubtype={documentSubtype}
        />
      }
    />
  );
};

export default DocumentHeader;
