import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import useUser from "hooks/useUser";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

const useMarkAllNotifications = () => {
  const { user } = useUser();
  const queryClient = useQueryClient();

  const { t } = useTranslation("user", {
    keyPrefix: "notifications",
  });

  const {
    status,
    error,
    isLoading,
    isError,
    isSuccess,
    mutate: markAllNotificationsRead,
  } = useMutation(
    () => services.notifications.markAllNotificationsRead(user?.id || ""),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryAPIKey.GetNotificationsForUser);
      },
    },
  );

  useShowAlert({
    status,
    successMessage: t("markAllNotificationAsReadSuccess"),
    failureMessage: t("markAllNotificationAsReadFailure"),
  });

  return {
    status,
    error,
    isLoading,
    isError,
    isSuccess,
    markAllNotificationsRead,
  };
};

export default useMarkAllNotifications;
