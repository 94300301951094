import { useEffect } from "react";
import getEnv from "env";
import { isLocalhost } from "utils/helpers";

const useDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = isLocalhost()
      ? `${title} - ${getEnv().tenant.toUpperCase()}`
      : title;
  }, [title]);
};

export default useDocumentTitle;
