import { MenuItem } from "@mui/material";
import Select, { TOption, TSelectProps } from "components/shared/Select";

type TItemSelectProps = TSelectProps<string> & {
  options: TOption[];
};

const ItemSelect = (props: TItemSelectProps) => {
  const { options, ...selectProps } = props;

  return (
    <Select
      defaultValue=""
      value="" // force select to always remain empty and never display a selected value.
      disabled={!options.length}
      {...selectProps}
    >
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ItemSelect;
