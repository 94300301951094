type TComparable = string | number;

export default function defaultCompare(
  itemA: TComparable,
  itemB: TComparable,
  reverse: boolean = false,
) {
  const reverseFactor = reverse ? -1 : 1;

  if (itemA < itemB) {
    return -1 * reverseFactor;
  } else if (itemA > itemB) {
    return 1 * reverseFactor;
  } else {
    return 0;
  }
}
