import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export type TCommentForDelete = {
  componentId: string;
  threadId: string;
  commentId: string;
};

const useRemoveComment = () => {
  const queryClient = useQueryClient();

  const { t } = useTranslation("annotations", { keyPrefix: "notifications" });

  const updateCommentMutation = useMutation(
    ["removeComment"],
    (mutationData: TCommentForDelete) => {
      const { componentId, threadId, commentId } = mutationData;

      return services.documents.comments.deleteComment(
        componentId,
        threadId,
        commentId,
      );
    },
    {
      onSuccess: (data, { threadId }) => {
        queryClient.invalidateQueries([QueryAPIKey.GetCommentThread, threadId]);
      },
    },
  );

  useShowAlert({
    status: updateCommentMutation.status,
    failureMessage: t("deleteCommentFailure"),
  });

  return updateCommentMutation;
};

export default useRemoveComment;
