import { TStyles } from "utils/Styles";

const styles: TStyles = {
  avatar: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "10px",
    position: "relative",
  },
};

export default styles;
