import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  inputWrapper: {
    "&:focus-visible": {
      outline: getBorderStyle("2px", "solid", "primary.main"),
      borderRadius: "8px",
    },
  },

  container: {
    width: "100%",
    backgroundColor: getPaletteColor("primary.50"),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    py: 2,
    borderRadius: 2,
    border: getBorderStyle("2px", "dashed", "primary.200"),
    cursor: "pointer",
    color: getPaletteColor("primary.main"),

    '&[data-has-error="true"]': {
      borderColor: getPaletteColor("error.light"),
    },
  },

  selectFile: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: 16,
    ml: 1,
  },

  dragAndDrop: {
    fontSize: 14,
    ml: 0.5,
    color: getPaletteColor("blueGrey.600"),
  },
};

export default styles;
