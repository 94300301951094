import { ReactNode } from "react";
import { TableCell, TableRow } from "@mui/material";
import { TRowProps } from "components/shared/Table";
import SelectionCell from "components/shared/Table/components/SelectionCell";
import styles from "components/shared/Table/Table.styles";
import { TRowBase } from "components/shared/Table/types";

function Row<T extends TRowBase>(props: TRowProps<T>) {
  const { row, columns, isSelectable, isRowSelected, onSelectClick } = props;

  if (!row) {
    return null;
  }

  return (
    <TableRow hover={isSelectable} selected={isRowSelected}>
      {isSelectable && (
        <SelectionCell
          checked={isRowSelected}
          disabled={row.isDisabled}
          onChange={() => onSelectClick(row.id)}
          ariaLabel={`Checkbox for ${row[columns[0].key]}`} // TODO i18n
        />
      )}
      {columns.map((column, colIndex) => (
        <TableCell
          key={colIndex}
          sx={row.isDisabled ? styles.tableCellDisabled : undefined}
          onClick={isSelectable ? () => onSelectClick(row.id) : undefined}
          width={column?.width}
        >
          {row[column.key] as ReactNode}
        </TableCell>
      ))}
    </TableRow>
  );
}

export default Row;
