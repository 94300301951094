const Styles = {
  autocomplete: {
    ".MuiInputLabel-root.MuiInputLabel-shrink.MuiInputLabel-outlined": {
      lineHeight: 1.5,
    },
    ".MuiOutlinedInput-root .MuiAutocomplete-input": {
      padding: "6px 4px 6px 3px",
    },
  },
};

export default Styles;
