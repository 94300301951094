import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * source: https://ncoughlin.com/posts/react-router-v6-hash-links/
 */
const HashElementScroll = () => {
  let location = useLocation();

  let hashElement = useMemo(() => {
    let hash = location.hash;

    const removeHashCharacter = (str: string) => {
      const result = str.slice(1);

      return result;
    };

    if (hash) {
      let element = document.getElementById(removeHashCharacter(hash));

      return element;
    } else {
      return null;
    }
  }, [location]);

  useEffect(() => {
    if (hashElement) {
      hashElement.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
      });
    }
  }, [hashElement]);

  return null;
};

export default HashElementScroll;
