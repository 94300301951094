import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { gql, Variables } from "graphql-request";
import useShowAlert from "hooks/useShowAlert";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { ITherapeuticSummaryDataResponse } from "./useTherapeuticSummaryData.models";

export const query = gql`
  query TherapeuticSummary(
    $isMockVersion: Boolean
    $templateIdentifier: String
  ) {
    therapeuticSummary(
      isMockVersion: $isMockVersion
      templateIdentifier: $templateIdentifier
    ) {
      report {
        id
        proprietaryName
        status
        jurisdiction {
          id
          description: display
        }
        strength
        dosageForm
        routeOfAdministration: route
      }
    }
  }
`;

type TUseTherapeuticSummary = {
  isMockVersion?: boolean;
  templateIdentifier?: string;
};

const useTherapeuticSummaryData = (
  { isMockVersion = false, templateIdentifier }: TUseTherapeuticSummary = {},
  enabled: boolean = true,
) => {
  const { t } = useTranslation("portfolio");

  const { data, status, isLoading, isSuccess } =
    useQuery<ITherapeuticSummaryDataResponse>(
      [QueryAPIKey.GetTherapeuticSummary, isMockVersion, templateIdentifier],
      () =>
        services.graphQL.request<ITherapeuticSummaryDataResponse, Variables>(
          query,
          {
            isMockVersion,
            templateIdentifier,
          },
        ),
      { enabled },
    );

  useShowAlert({
    status,
    failureMessage: t("notifications.getTherapeuticSummaryFailure"),
  });

  return {
    data,
    isLoading,
    isSuccess,
  };
};

export default useTherapeuticSummaryData;
