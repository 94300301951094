import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBase, Grid, Stack, Typography } from "@mui/material";
import styles from "./TeaserCard.styles";

type TTeaserCardProps = PropsWithChildren<{
  title: string;
  description?: JSX.Element;
  imgBox: JSX.Element;
  onTeaserClick?: () => void;
}>;

const TeaserCard = ({
  title,
  imgBox,
  description,
  children,
  onTeaserClick,
}: TTeaserCardProps) => {
  const { t } = useTranslation("common");

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      flexWrap="nowrap"
      sx={styles.teaserContainer}
    >
      <Grid container item xs={5}>
        {onTeaserClick ? (
          <ButtonBase
            component="a"
            onClick={onTeaserClick}
            sx={styles.imgButton}
            aria-label={t("button.view", { ns: "common" })}
            disableRipple
          >
            <Stack sx={styles.imgBlock}>{imgBox}</Stack>
          </ButtonBase>
        ) : (
          <Stack sx={styles.imgBlock}>{imgBox}</Stack>
        )}
      </Grid>
      <Grid container item xs={4} sx={styles.contentBlock} alignItems="center">
        <Grid item xs zeroMinWidth>
          <Stack
            data-testid="teaser-document-name"
            data-qaid="teaser-document-name"
            mb={0.5}
          >
            <Typography variant="subtitle1" component="h2" noWrap>
              {title}
            </Typography>
            {description}
          </Stack>
        </Grid>
        {children && (
          <Grid item pl={1}>
            {children}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default TeaserCard;
