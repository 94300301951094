import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import MultiSelectTypeahead from "components/shared/MultiSelectTypeahead";
import {
  TypeaheadChipDisplayMode,
  TypeaheadFilterVariant,
} from "components/shared/MultiSelectTypeahead/MultiSelectTypeahead.constants";
import {
  TFilteringParams,
  TSelectTypeaheadFilter,
} from "components/shared/types/filter";

type TMultiSelectTypeaheadFilterProps = {
  id: string;
  filter: TSelectTypeaheadFilter;
  updateFilter: (filters: TFilteringParams) => void;
  selectedFilters?: TFilteringParams;
  listHeaderLabel?: string;
  sx?: SxProps<Theme>;
};

const MultiSelectTypeaheadFilter = ({
  id,
  filter,
  updateFilter,
  selectedFilters,
  sx,
}: TMultiSelectTypeaheadFilterProps) => {
  const { t } = useTranslation("common");

  return (
    <MultiSelectTypeahead<string>
      id={id}
      options={filter.options}
      key={id}
      role="combobox"
      label={filter.label}
      onChange={(selected) => {
        updateFilter({ [id]: selected });
      }}
      hideTagsOnOpen
      selectAllLabel={filter.selectAllLabel}
      size="medium"
      sx={sx}
      isSelectAllEnabled
      value={selectedFilters?.[id] ?? []}
      variant={TypeaheadFilterVariant.Chips}
      chipDisplayMode={TypeaheadChipDisplayMode.Squashed}
      componentsProps={{
        popper: { style: { width: "fit-content" } },
      }}
      disableClearable
      noOptionsText={t("emptyState.noResults")}
    />
  );
};

export default MultiSelectTypeaheadFilter;
