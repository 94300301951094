import { TFunction } from "i18next";
import { Path } from "router/paths";
import { parsePath } from "utils/helpers";
import { TPathNamesMapping } from "./useBreadcrumbs";

export type TRoute = {
  path: Path;
  name?: string;
};

export const getBreadcrumbsPaths = (pathChunks: string[]) =>
  pathChunks.map(
    (_chunk, index, chunks) => `/${chunks.slice(0, index + 1).join("/")}`,
  );

export const getBreadcrumbName = (
  t: TFunction,
  route: TRoute,
  pathNamesMapping?: TPathNamesMapping,
) => {
  if (checkIsDynamicRoute(route.path)) {
    const pathEnding = getPathEnding(route.path)?.replace(
      ":",
      "",
    ) as keyof TPathNamesMapping;

    return (pathEnding && pathNamesMapping?.[pathEnding]) ?? "";
  } else if (route.name) {
    return t(route.name);
  } else {
    return "";
  }
};

const getPathEnding = (path: string) => parsePath(path).pop();

const checkIsDynamicRoute = (path: string) =>
  Boolean(getPathEnding(path)?.startsWith(":"));
