import { useMemo, useState } from "react";
import { TGSPComponent } from "models/gsp.models";
import {
  mapHAToDropDownOption,
  submittingHAsFilter,
} from "screens/Project/screens/GSP/GSPItems/GSPItems.utils";
import {
  DossierDifferencesModalFormInitialValues,
  TDossierDifferencesModalForm,
  TDossierDifferencesModalFormEdit,
} from "../DossierDifferenceModal.types";

const useOpenDossierDifferenceModal = (components: TGSPComponent[]) => {
  const [isDossierDifferenceModalOpen, setIsDossierDifferenceModalOpen] =
    useState<boolean>(false);

  const [initialFormData, setInitialFormData] = useState<
    TDossierDifferencesModalForm | TDossierDifferencesModalFormEdit
  >({
    ...DossierDifferencesModalFormInitialValues,
  });

  const listOfSubmittingHAs = useMemo(() => {
    return mapHAToDropDownOption(
      components
        .filter(submittingHAsFilter)
        .map((component) => component.healthAuthority),
    );
  }, [components]);

  const handleCloseDossierDifferenceModal = () => {
    setInitialFormData({ ...DossierDifferencesModalFormInitialValues });
    setIsDossierDifferenceModalOpen(false);
  };

  const handleOpenDossierDifferenceModal = (
    initialData?: TDossierDifferencesModalForm,
  ) => {
    if (initialData) {
      setInitialFormData(initialData);
    }

    setIsDossierDifferenceModalOpen(true);
  };

  return [
    isDossierDifferenceModalOpen,
    listOfSubmittingHAs,
    handleCloseDossierDifferenceModal,
    handleOpenDossierDifferenceModal,
    initialFormData,
  ] as const;
};

export default useOpenDossierDifferenceModal;
