import { AxiosResponse } from "axios";
import { get, routes } from "api";

const resources = {
  getResourceById(id: string, isMockApiOn?: boolean): Promise<AxiosResponse> {
    return get(routes.identity.resources.byId(id), { isMockApiOn });
  },
};

export default resources;
