import { useProject } from "context/ProjectContext";
import usePermissions from "hooks/permissions";
import { TUserPermissions } from "hooks/permissions/usePermissions.types";
import { deriveHAQWorkFlowId } from "hooks/permissions/utils/projectConfiguration";
import useUser from "hooks/useUser";
import { IAsset } from "models/assets.models";
import { IHAQMetadata } from "models/HAQ.models";
import { IProject } from "models/projects.models";
import { TUserData } from "models/users.models";
import { AssetType, THAQAssetType } from "utils/constants/assets.constants";
import { DocumentViewMode } from "utils/constants/doc.constants";
import { HAQStatus } from "utils/constants/HAQ.constants";
import { WorkflowId } from "utils/constants/workflows.constants";
import {
  getIsAssetCreatedByUser,
  getIsEligibleToManipulateAsset,
  getIsReferenceOrExternalHAQ,
  TAssetWithContent,
} from "utils/helpers";

const getUserHasPermission = (
  permissions: TUserPermissions,
  asset: TAssetWithContent<IHAQMetadata> | undefined,
  user: TUserData | undefined,
) => {
  if (getIsReferenceOrExternalHAQ(asset?.metadata?.importType)) {
    return permissions.canEditReferenceOrExternalQuestion;
  }

  if (asset?.type === AssetType.Question) {
    return getIsAssetCreatedByUser(asset, user)
      ? permissions.canEditQuestionOwned
      : permissions.canEditQuestion;
  }

  if (asset?.type === AssetType.QuestionResponse) {
    return permissions.canEditQuestionResponse;
  }

  return false;
};

const getIsInDraftStatus = (
  asset: TAssetWithContent<IHAQMetadata> | undefined,
) => asset?.status === HAQStatus.Draft;

export const useViewOrEditMode = (
  asset: TAssetWithContent<IHAQMetadata> | undefined,
) => {
  return useCanEditAssetContent(asset)
    ? DocumentViewMode.Editing
    : DocumentViewMode.Viewing;
};

const useCanEditAssetContent = (
  asset: TAssetWithContent<IHAQMetadata> | undefined,
) => {
  const { project } = useProject();
  const { user, organization } = useUser();
  const { permissions } = usePermissions(project?.id ?? "");

  return (
    getIsInDraftStatus(asset) &&
    getUserHasPermission(permissions, asset, user) &&
    getIsEligibleToManipulateAsset(asset, organization)
  );
};

type TWorkflowDetailsForSubmitToHA = Partial<{
  HAQ: IAsset<IHAQMetadata>;
  HAQResponse: IAsset<IHAQMetadata>;
  HAQType: THAQAssetType;
  project: IProject | null;
}>;

export const getWorkflowDetailsForSubmitToHA = ({
  HAQ,
  HAQResponse,
  HAQType,
  project,
}: TWorkflowDetailsForSubmitToHA) => {
  const type =
    HAQType && HAQType === AssetType.Question
      ? AssetType.Question
      : AssetType.QuestionResponse;

  const workflowId = deriveHAQWorkFlowId(project?.type, type);

  if (workflowId === WorkflowId.RelianceSubmitImportedHAQ) {
    return {
      workflowId,
      assets: [HAQ?.id || ""],
      successMessage: "workflowMessages.sendHAQQuestionSuccessMessage",
    };
  } else {
    return {
      workflowId,
      assets: [HAQResponse?.id || ""],
      successMessage: "workflowMessages.sendHAQResponseSuccessMessage",
    };
  }
};
