import { Controller, get, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, FormHelperText, Typography } from "@mui/material";
import SelectedFile from "components/shared/SelectedFile/SelectedFile";
import { IDocument } from "models/documents.models";
import { DocumentSubtype } from "utils/constants/doc.constants";
import { GlobeLocatorIcon } from "assets/icons";
import styles from "./Documents.styles";

type TDocumentsFieldProps = {
  fieldName: string;
};

const DocumentsField = ({ fieldName }: TDocumentsFieldProps) => {
  const { control, formState, setValue } = useFormContext();

  const { t } = useTranslation(["documents", "common"]);

  const handleDocumentRemove = (documents: IDocument[], id: string) => {
    const updatedDocs = documents.filter((doc) => doc.id !== id);
    setValue(fieldName, updatedDocs, { shouldValidate: true });
  };

  const error = get(formState.errors, fieldName);

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{
        required: t("validations.requiredField", { ns: "common" }),
      }}
      render={({ field }) => {
        const documents = field.value as IDocument[];

        return (
          <Box>
            {documents?.length ? (
              documents.map((document) => (
                <Box key={document.id} sx={styles.selectedFileContainer}>
                  <SelectedFile
                    fileName={document.name}
                    onClear={() => handleDocumentRemove(documents, document.id)}
                    clearIconAriaLabel={t("ariaLabels.clearIcon", {
                      ns: "common",
                      fileName: document.name,
                    })}
                    fileIcon={
                      document.type === DocumentSubtype.GSP ? (
                        <GlobeLocatorIcon
                          aria-label={t("iconTitles.globe", { ns: "common" })}
                        />
                      ) : undefined
                    }
                  />
                </Box>
              ))
            ) : (
              <Typography variant="body1">
                {t("documents.commonFields.documents.emptyMessage")}
              </Typography>
            )}
            {error && (
              <FormHelperText variant="standard" aria-live="polite" error>
                {t("documents.commonFields.documents.emptyError")}
              </FormHelperText>
            )}
          </Box>
        );
      }}
    />
  );
};

export default DocumentsField;
