import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useProject } from "context/ProjectContext";
import useLockBulkAssetsEditing from "hooks/api/REST/assets/useLockBulkAssetsEditing";
import useStartWorkflow from "hooks/api/REST/workflow/useStartWorkflow";
import { useUserRoles } from "hooks/user";
import useUser from "hooks/useUser";
import { IDocument } from "models/documents.models";
import { WorkflowId } from "utils/constants/workflows.constants";
import { isSponsorUser } from "utils/user/organization";
import { TSubmitDocumentForm } from "./SubmitDocuments.types";

export const useSubmitDocuments = (onStartWorkflowResolved: () => void) => {
  const { project } = useProject();
  const { isProjectManager } = useUserRoles(project?.id);
  const { user, organization } = useUser();
  const isSponsor = isSponsorUser(organization);

  const { t } = useTranslation("notifications");

  const { startWorkflow } = useStartWorkflow({
    successMessage: t("workflowMessages.submitDocumentSuccessMessage", {
      ns: "notifications",
    }),
    failureMessage: t("workflowMessages.submitDocumentErrorMessage", {
      ns: "notifications",
    }),
  });

  const [workflowInProgressId, setWorkflowInProgressId] = useState<
    string | null
  >(null);

  const { lockBulkAssetsEditing } = useLockBulkAssetsEditing();

  const initiateWorkflow = useCallback(
    (submissionData: TSubmitDocumentForm) => {
      if (workflowInProgressId) {
        return;
      }

      if (
        user?.id &&
        organization &&
        isSponsor &&
        isProjectManager &&
        submissionData
      ) {
        const assetsForSubmission =
          submissionData?.documents?.map(
            (document: IDocument) => document.id,
          ) ?? [];

        const orgsForSubmission =
          submissionData?.recipients?.map((recipient) => recipient.value) || [];

        lockBulkAssetsEditing(assetsForSubmission, {
          onSuccess: () => {
            startWorkflow(
              {
                id: WorkflowId.RelianceSubmitDocument,
                payload: {
                  originator: user.id,
                  targetProject: project?.sourceArn,
                  assets: assetsForSubmission,
                  targetTenants: orgsForSubmission,
                  message: submissionData.message,
                },
              },
              {
                onSuccess: (response) => {
                  setWorkflowInProgressId(response?.data?.id);
                  onStartWorkflowResolved();
                },
                onError: () => {
                  onStartWorkflowResolved();
                },
              },
            );
          },
        });
      }
    },
    [
      isProjectManager,
      isSponsor,
      onStartWorkflowResolved,
      project?.sourceArn,
      startWorkflow,
      user?.id,
      organization,
      workflowInProgressId,
      lockBulkAssetsEditing,
    ],
  );

  return {
    initiateWorkflow,
    workflowInProgressId,
    setWorkflowInProgressId,
  };
};
