import { TFunction } from "i18next";
import { z } from "zod";
import { DocumentStatus, DocumentSubtype } from "utils/constants/doc.constants";
import { isDateInFuture } from "../date.validator";

// Generic schema that can be reused or extended throughout the app
export const MEMBER_SCHEMA = z.object({
  label: z.string(),
  value: z.string(),
  type: z.string().optional(),
  disabled: z.boolean().optional(),
});

export const DOCUMENT_SCHEMA = z.object({
  id: z.string(),
  name: z.string(),
  fileType: z.string().nullish(),
  lastModifiedDate: z.string(),
  type: z.nativeEnum(DocumentSubtype),
  workflowStatus: z.string().nullish(),
  status: z.nativeEnum(DocumentStatus),
  version: z.string().nullish(),
});

export const getGenericStringArrayConstraint = (t: TFunction) =>
  z
    .string()
    .array()
    .min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    });

export const getGenericDateConstraint = (t: TFunction) =>
  z.coerce
    .date({
      errorMap: () => ({
        message: t("validations.invalidDate", { ns: "common" }),
      }),
    })
    .superRefine((val, ctx) => {
      if (!val || !val.getTime()) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("validations.requiredDate", { ns: "common" }),
        });
      }
    });

export const getGenericFutureDateConstraint = (t: TFunction) =>
  getGenericDateConstraint(t).superRefine((val, ctx) => {
    if (!isDateInFuture(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t("validations.invalidFutureDate", { ns: "common" }),
      });
    }
  });

export const getGenericItemAssignmentConstraint = (t: TFunction) =>
  z.array(
    z.object({
      item: z.object({
        value: z.string(),
        label: z.string(),
      }),
      assignment: z.object({
        value: z.string().min(1, {
          message: t("validations.requiredAssignmentsRole", { ns: "common" }),
        }),
        label: z.string(),
      }),
    }),
  );
