import { useEffect } from "react";

type TUseBroadcastChannel = {
  name: string;
  handler: (event: BroadcastChannelEventMap["message"]) => void;
};

function useBroadcastChannel({ name, handler }: TUseBroadcastChannel): void {
  useEffect(() => {
    const broadcastChannel = new BroadcastChannel(name);
    broadcastChannel.addEventListener("message", handler);

    return () => {
      broadcastChannel.removeEventListener("message", handler);
      broadcastChannel.close();
    };
  }, [handler, name]);
}

export default useBroadcastChannel;
