import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import styles from "../FooterLinks.styles";

export type TProjectLinkProps = {
  projectName: string | undefined;
  projectPath: string;
};

const ProjectLink = ({ projectName, projectPath }: TProjectLinkProps) => {
  const { t } = useTranslation("tasks", {
    keyPrefix: "taskFooter",
  });

  return projectName ? (
    <Grid container columnSpacing={1} alignItems="center">
      <Grid item xs={3}>
        <Typography variant="body2" sx={styles.label}>
          {t("project")}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Link
          data-qaid="drawer-project-link"
          to={projectPath}
          style={styles.link as CSSProperties}
        >
          {projectName}
        </Link>
      </Grid>
    </Grid>
  ) : null;
};

export default ProjectLink;
