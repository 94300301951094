import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { gql, Variables } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { IProductEntityControlledListsResponse } from "./useProductEntityControlledLists.models";

export const query = gql`
  query GetProductEntityControlledLists {
    productEntityControlledLists {
      controlledProductTypes {
        id
        code
        name
      }
      controlledStrengthTypes {
        id
        code
        name
      }
      controlledStrengthVolumeTypes {
        id
        code
        name
      }
      controlledRouteOfAdministrationTypes {
        id
        code
        name
      }
      controlledManufacturedDoseFormTypes {
        id
        code
        name
      }
    }
  }
`;

const useProductEntityControlledLists = (enabled: boolean = true) => {
  const { t } = useTranslation("administration");
  const { showAlert } = useAlerts();

  const { data, isLoading, isSuccess } =
    useQuery<IProductEntityControlledListsResponse>(
      [QueryAPIKey.GetProductEntityControlledLists],
      () =>
        services.graphQL.request<
          IProductEntityControlledListsResponse,
          Variables
        >(query),
      {
        enabled,
        onError: () => {
          showAlert({
            severity: AlertSeverity.Error,
            message: t("notifications.getProductEntityControlledListsFailure"),
          });
        },
      },
    );

  return {
    data,
    isLoading,
    isSuccess,
  };
};

export default useProductEntityControlledLists;
