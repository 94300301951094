import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormSelect } from "components/common/form-fields";
import { TQuestionOptions } from "components/common/HAQ";
import { formatOptionsFromDataDictionary } from "components/common/HAQ/drawers/AddHAQDrawer/AddHAQDrawer.utils";
import useDataDictionary, {
  DataDictionaryKey,
} from "hooks/api/REST/assets/useDataDictionary";
import { HAQFieldName } from "utils/constants/HAQ.constants";

type TTopicProps = {
  domain?: string;
};

const Topic = ({ domain }: TTopicProps) => {
  const { t } = useTranslation("HAQ");

  const { dataDictionary } = useDataDictionary<TQuestionOptions>(
    DataDictionaryKey.Question,
  );

  const options = useMemo(
    () =>
      formatOptionsFromDataDictionary(
        t,
        dataDictionary,
        HAQFieldName.Topic,
        domain,
      ),
    [dataDictionary, t, domain],
  );

  return (
    <FormSelect
      data-qaid="add-haq-topic-select"
      name={HAQFieldName.Topic}
      title={t("addHAQModal.fields.topic.title")}
      label={t("addHAQModal.fields.topic.label")}
      options={options}
    />
  );
};

export default Topic;
