import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import useFeatureFlags from "context/FeatureFlagsContext/useFeatureFlags";
import useShowAlert from "hooks/useShowAlert";
import {
  ITriggerTaskEventRequestData,
  ITriggerTaskEventResponseData,
} from "models/tasks.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { FeatureFlag } from "utils/constants/featureFlags.constants";

export type TUseTriggerTaskEvent = {
  id: string;
  data: ITriggerTaskEventRequestData;
};

const useTriggerTaskEvent = (
  successMessage?: string,
  failureMessage?: string,
) => {
  const featureFlags = useFeatureFlags();

  const shouldShowNewTask =
    featureFlags["shouldShowNewTasksTable"] === FeatureFlag.On;

  const queryClient = useQueryClient();

  const { t } = useTranslation("tasks", {
    keyPrefix: "notifications",
  });

  const { mutateAsync, isLoading, status } = useMutation(
    ({ id, data }: TUseTriggerTaskEvent) =>
      services.tasks
        .triggerTaskEvent(id, data)
        .then((res) => res.data as ITriggerTaskEventResponseData),
    {
      onSuccess: () => {
        if (shouldShowNewTask) {
          queryClient.invalidateQueries(QueryAPIKey.GetGroupedTask);
          queryClient.invalidateQueries(QueryAPIKey.GetTasksPaginated);
        } else {
          queryClient.invalidateQueries(QueryAPIKey.GetTasks);
          queryClient.invalidateQueries(QueryAPIKey.GetTask);
        }
      },
    },
  );

  useShowAlert({
    status: status,
    successMessage: successMessage || t("triggerTaskEventSuccess"),
    failureMessage: failureMessage || t("triggerTaskEventFailure"),
  });

  return { triggerTaskEvent: mutateAsync, isLoading };
};

export default useTriggerTaskEvent;
