import { CSSProperties, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import Button from "components/shared/Button";
import Loading from "components/shared/Loading";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { useProject } from "context/ProjectContext";
import useAssetContent from "hooks/api/REST/assets/useAssetContent";
import { TPreviewDocument } from "models/documents.models";
import { EventType } from "models/tracking.models";
import { services } from "services";
import { AssetType } from "utils/constants/assets.constants";
import styles from "./PreviewContent.styles";

export type TPreviewContentModalProps = {
  onClose: () => void;
  document: TPreviewDocument | null;
};

const PreviewContent = ({ document, onClose }: TPreviewContentModalProps) => {
  const { t } = useTranslation(["documents", "common"]);

  const { project } = useProject();

  const { data, isLoading } = useAssetContent(
    document?.id ?? "",
    {
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    },
    {
      onSuccess: () => {
        services.tracking.addTrackingEvent({
          assetId: document?.id ?? "",
          assetType: AssetType.Document,
          eventType: EventType.AppContentView,
          projectId: project?.id ?? "",
        });
      },
    },
  );

  const url = useMemo(
    () =>
      data &&
      URL.createObjectURL(
        new Blob([data as unknown as BlobPart], { type: "application/pdf" }),
      ),
    [data],
  );

  return (
    <Modal
      data-qaid="preview-content-modal"
      title={document?.name}
      sx={styles.modal}
      open={Boolean(document)}
    >
      <Box sx={styles.documentContainer}>
        {isLoading ? (
          <Loading />
        ) : (
          <embed
            title={t("previewDocument.pdfObjectTitle")}
            style={styles.pdfObject as CSSProperties}
            src={url}
          />
        )}
      </Box>
      <ModalActionButtons
        buttons={[
          <Button
            key="preview-modal-close-button"
            variant="contained"
            onClick={onClose}
          >
            {t("button.close", { ns: "common" })}
          </Button>,
        ]}
      />
    </Modal>
  );
};

export default PreviewContent;
