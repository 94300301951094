import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import omit from "lodash/omit";
import ToastNotification from "components/shared/ToastNotification";
import { DEFAULT_AUTO_HIDE_DURATION } from "./AlertContext.constants";
import { TAlert, TAlertContextValue } from "./AlertContext.types";

const AlertContext = createContext<TAlertContextValue | undefined>(undefined);

export const useAlerts = (): TAlertContextValue => {
  const context = useContext(AlertContext);

  if (context === undefined) {
    throw new Error("useAlerts must be used within an AlertsProvider");
  }

  return context;
};

const AlertProvider = (props: PropsWithChildren) => {
  const [alerts, setAlerts] = useState<TAlert[]>([]);

  const showAlert = useCallback(
    (alert: TAlert) => {
      if (alert.isInstant) {
        setAlerts((alerts) => [alert, ...alerts]);
      } else {
        setAlerts((alerts) => [...alerts, alert]);
      }
    },
    [setAlerts],
  );

  const hideAlert = useCallback(
    (alertId?: string) => {
      if (alertId) {
        setAlerts((alerts) => alerts.filter((alert) => alert.id !== alertId));
      } else {
        setAlerts((alerts) => alerts.slice(1));
      }
    },
    [setAlerts],
  );

  const onNotificationClose = useCallback(() => {
    hideAlert();
  }, [hideAlert]);

  const currentNotification = useMemo(() => alerts[0], [alerts]);

  const value = useMemo(
    () => ({ showAlert, hideAlert }),
    [showAlert, hideAlert],
  );

  return (
    <AlertContext.Provider value={value}>
      {currentNotification && (
        <ToastNotification
          open
          onClose={onNotificationClose}
          autoHideDuration={
            currentNotification.isPersistent ? null : DEFAULT_AUTO_HIDE_DURATION
          }
          ClickAwayListenerProps={
            currentNotification.isPersistent ? { mouseEvent: false } : undefined
          }
          {...omit(currentNotification, ["isPersistent", "isInstant"])}
        />
      )}
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
