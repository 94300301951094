type TLayoutErrorProps = {
  messageTranslationKey?: string;
  message?: string;
  statusCode?: number;
};

class LayoutError extends Error {
  private statusCode: number;
  public messageTranslationKey: string;

  constructor({
    messageTranslationKey,
    message,
    statusCode,
  }: TLayoutErrorProps) {
    super(message);

    this.name = "LayoutError";
    this.statusCode = statusCode || 0;
    this.message = message || "";
    this.messageTranslationKey = messageTranslationKey || "";

    Error.captureStackTrace(this, LayoutError);
  }
}

export default LayoutError;
