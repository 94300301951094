import { useTranslation } from "react-i18next";
import GenericFallback from "../GenericFallback";

const AppInsightsFallback = () => {
  const { t } = useTranslation("errors", { useSuspense: false });

  return <GenericFallback message={t("genericErrorMessage")} />;
};

export default AppInsightsFallback;
