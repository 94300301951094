import { useMemo } from "react";
import { TFunction } from "i18next";
import { getDueTimeString } from "utils/helpers";
import { TISODateFormat } from "utils/types";

export default (
  dueDate: TISODateFormat,
  dueTime: number,
  t: TFunction,
  currentLanguage: string,
) =>
  useMemo(
    () => getDueTimeString(dueTime, dueDate, t, currentLanguage),
    [dueTime, dueDate, t, currentLanguage],
  );
