import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { gql } from "graphql-request";
import { queryClient } from "config/reactQueryClient";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";

export const query = gql`
  mutation UpdateProductFamily(
    $productFamily: ProductFamilyManageBody
    $familyId: String
  ) {
    updateProductFamily(productFamily: $productFamily, familyId: $familyId) {
      id
      name
      description
    }
  }
`;

type TProductFamilyManageBody = {
  id: string;
  name: string;
  description: string;
  active: boolean;
};

type TUseUpdateProductFamilyMutationData = {
  familyId: string;
  productFamily: TProductFamilyManageBody;
};

const useUpdateProductFamily = () => {
  const { t } = useTranslation("administration");
  const { showAlert } = useAlerts();

  const { data, isLoading, isSuccess, isError, mutateAsync } = useMutation(
    ({ familyId, productFamily }: TUseUpdateProductFamilyMutationData) =>
      services.graphQL
        .request(query, {
          familyId,
          productFamily,
        })
        .then(() => {
          showAlert({
            message: t("notifications.updateProductFamilySuccess"),
            severity: AlertSeverity.Success,
          });

          queryClient.invalidateQueries(QueryAPIKey.GetProductFamilies);
        })
        .catch((error) => {
          if (error.response?.errors[0]?.extensions?.response?.status === 409) {
            showAlert({
              message: t(
                "notifications.updateProductFamilyFailureAlreadyExists",
              ),
              severity: AlertSeverity.Error,
            });

            throw error;
          }

          showAlert({
            message: t("notifications.updateProductFamilyFailure"),
            severity: AlertSeverity.Error,
          });

          throw error;
        }),
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    updateProductFamily: mutateAsync,
  };
};

export default useUpdateProductFamily;
