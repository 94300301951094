import React, { Ref, useMemo } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Icon,
  IconButton,
  InputAdornment,
  SvgIconProps,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { getIsEmptyString } from "utils/helpers";
import { ReactComponent as ErrorAdornment } from "assets/images/textfield_error.svg";
import styles from "./TextField.styles";

export type TTextFieldProps = MuiTextFieldProps & {
  combobox?: boolean;
  onClear?: () => void;
  multiline?: boolean;
  errorAdornmentAriaLabel?: string;
  clearIconAriaLabel?: string;
  emptyLabel?: string;
  variant?: "outlined" | "standard" | "filled";
  iconSize?: SvgIconProps["fontSize"];
};

const errorAdornment = (errorAdornmentAriaLabel?: string) => (
  <InputAdornment position="end">
    {/* // TODO i18n "textfield error" */}
    <Icon sx={styles.errorIcon} aria-label={errorAdornmentAriaLabel}>
      <ErrorAdornment />
    </Icon>
  </InputAdornment>
);

const TextField = React.forwardRef((props: TTextFieldProps, ref: Ref<any>) => {
  const {
    onClear,
    clearIconAriaLabel,
    errorAdornmentAriaLabel,
    label,
    emptyLabel,
    ...textFieldProps
  } = props;

  const clearAdornment = useMemo(
    () => (
      <InputAdornment position="end">
        <IconButton
          data-testid="clear-text-field"
          data-qaid="clear-text-field"
          aria-label={clearIconAriaLabel}
          edge="end"
          onClick={onClear}
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      </InputAdornment>
    ),
    [clearIconAriaLabel, onClear],
  );

  const endAdornment = useMemo(() => {
    if (props.error) {
      return errorAdornment(errorAdornmentAriaLabel);
    }

    if (onClear && props.value) {
      return clearAdornment;
    }

    return null;
  }, [
    clearAdornment,
    onClear,
    props.error,
    props.value,
    errorAdornmentAriaLabel,
  ]);

  const sx = {
    ...styles.textfield,
    ...props.sx,
  };

  const displayedLabel = useMemo(
    () =>
      textFieldProps.value || getIsEmptyString(emptyLabel) ? label : emptyLabel,
    [emptyLabel, textFieldProps.value, label],
  );

  return (
    <MuiTextField
      {...textFieldProps}
      label={displayedLabel}
      ref={ref}
      sx={sx}
      InputProps={{
        endAdornment,
        ...props.InputProps,
      }}
      inputProps={props.inputProps}
      FormHelperTextProps={{
        variant: "standard",
        "aria-live": "polite",
      }}
    />
  );
});

export default TextField;
