import { z } from "zod";
import { TCheckboxGroupOption } from "components/shared/CheckboxGroup";
import { IAssetVersion } from "models/assets.models";

export enum DeleteVersions {
  Versions = "versions",
}

export type TCheckboxVersionOption = TCheckboxGroupOption & {
  latestVersion?: boolean;
  createdDate?: string;
};

export const getDocumentVersionOptions = (
  versions: IAssetVersion[] | undefined,
) => {
  return versions
    ? versions.map((version, index) => {
        //TO DO it will be good to get information about initialVersion from the back-end in the future
        // (https://accumulus.atlassian.net/browse/FTE-2916?focusedCommentId=18685)
        const isInitialVersion = versions.length - 1 === index;

        return {
          id: version.version,
          label: version.version,
          disabled: isInitialVersion,
          latestVersion: index === 0,
          createdDate: version.createdDate,
        };
      })
    : [];
};

export const deleteVersionsSchema = z.object({
  [DeleteVersions.Versions]: z.array(z.string()).min(1),
});
