import { isFunction } from "lodash";

// nested ternaries in types are hard to read with prettier's default formatting
// prettier-ignore
export type TRecursivePartial<T> =
// A function can always be passed in to either calculate a value based on the previous value, or
// to overwrite it completely. In that case, the return value has to be the complete type, not a
// partial type, and the returned value isn't recursively merged.
  | ((original: T) => T)
  | (T extends (infer U)[] ? (
  // If the type is an array, recursively apply the transformation to each element,
  // or allow a function which replaces the whole thing
  TRecursivePartial<U>[]
  ) : T extends Record<any, any> ? (
  // If the type is a simple object, recursively apply the transformation to each key
  { [P in keyof T]?: TRecursivePartial<T[P]> }
  ) : (
  // Otherwise leave the type as-is, e.g. number, Date, etc
  T
  ));

// Checking the constructor limits the recursive merge to just simple objects, which allows it to
// avoid trying to merge things like Dates, which are objects but have their own constructors.
const isRecord = (obj: any): obj is Record<any, any> =>
  !!obj && obj.constructor === Object;

// This is a separate overload type because the compiler's narrowing can't match with the
// conditional types in TRecursivePartial. That type will enforce that the function is being called
// with valid arguments, but in order to handle them correctly within the function we have to use
// type guards anyway.
export function mergeDeep<A>(defaults: A, overrides: TRecursivePartial<A>): A;

export function mergeDeep(defaults: unknown, overrides: unknown): any {
  if (isFunction(overrides)) {
    return overrides(defaults);
  }

  if (isRecord(defaults) && isRecord(overrides)) {
    Object.keys(overrides).forEach((key) => {
      defaults[key] = mergeDeep(defaults[key], overrides[key]);
    });

    return defaults;
  }

  // Allow partial overrides of arrays by passing in an array with empty slots
  if (Array.isArray(defaults) && Array.isArray(overrides)) {
    defaults.forEach((value, index) => {
      if (overrides.hasOwnProperty(index)) {
        defaults[index] = mergeDeep(value, overrides[index]);
      }
    });

    overrides.forEach((value, index) => {
      if (!defaults.hasOwnProperty(index)) {
        defaults[index] = overrides[index];
      }
    });

    return defaults;
  }

  // If the values are neither arrays nor objects, return the override directly. This allows
  // things like dates to be overridden.
  return overrides;
}
