import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import useShowAlert, { TAlertMessage } from "hooks/useShowAlert";
import { IDeleteAssetsRequestData } from "models/assets.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

const useDeleteAssets = ({
  messages,
  options,
}: {
  messages?: TAlertMessage;
  options?: Omit<
    UseMutationOptions<{}, Error, IDeleteAssetsRequestData>,
    "mutationFn"
  >;
} = {}) => {
  const queryClient = useQueryClient();

  const deleteAssetsMutation = useMutation(
    ({ assetIds }: IDeleteAssetsRequestData) =>
      services.assets.deleteAssets({ assetIds }).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryAPIKey.GetAssets);
      },
      ...options,
    },
  );

  useShowAlert({
    status: deleteAssetsMutation.status,
    ...messages,
  });

  return deleteAssetsMutation;
};

export default useDeleteAssets;
