import { FieldPath } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelect } from "components/common/form-fields";
import { TGSPForm } from "screens/Project/screens/GSP/GSPItems/GSPFormItem/GSPForm.types";
import { getSubmitOptions } from "screens/Project/screens/GSP/GSPItems/GSPFormItem/GSPForm.utils";

const SubmissionPlan = ({
  name,
  isDisabled = false,
}: {
  name: FieldPath<TGSPForm>;
  isDisabled?: boolean;
}) => {
  const { t } = useTranslation("documents", {
    keyPrefix: "gsp.item.fields.submissionPlan",
  });

  return (
    <FormSelect
      name={name}
      data-qaid="submission-plan-select"
      label={t("label")}
      disabled={isDisabled}
      options={getSubmitOptions(t)}
    />
  );
};

export default SubmissionPlan;
