export enum DecisionStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Denied = "DENIED",
  NoLongerParticipating = "NO_LONGER_PARTICIPATING",
  Withdrawn = "WITHDRAWN",
}

export enum InviteeResponseStatus {
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
  Pending = "PENDING",
}

export enum InvitedHAsAction {
  ManageInvitation = "MANAGE_INVITATION",
}

export enum ParticipationType {
  LeadHA = "LEAD_HEALTH_AUTHORITY",
  ReferenceHA = "REFERENCE_HEALTH_AUTHORITY",
  Rapporteur = "RAPPORTEUR",
  CoRapporteur = "CO_RAPPORTEUR",
  Module3Reviewer = "MODULE_3_REVIEWER",
  Module4Reviewer = "MODULE_4_REVIEWER",
  Module5Reviewer = "MODULE_5_REVIEWER",
  Participant = "PARTICIPANT",
  RelianceHA = "RELIANCE_HEALTH_AUTHORITY",
  Observer = "OBSERVER",
}

export const DEFAULT_DECISION_STATUS = DecisionStatus.Pending;

export const DEFAULT_DECISION_STATUS_ORDER = [
  DecisionStatus.Pending,
  DecisionStatus.Approved,
  DecisionStatus.Denied,
  DecisionStatus.NoLongerParticipating,
  DecisionStatus.Withdrawn,
];
export const HA_DECISION_STATUS_ORDER = [
  DecisionStatus.Approved,
  DecisionStatus.Pending,
  DecisionStatus.Denied,
  DecisionStatus.NoLongerParticipating,
  DecisionStatus.Withdrawn,
];

export const DEFAULT_RESPONSE_STATUS_ORDER = [
  InviteeResponseStatus.Pending,
  InviteeResponseStatus.Accepted,
  InviteeResponseStatus.Declined,
];
