import InformationIcon from "@mui/icons-material/ErrorOutlineOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { TFunction } from "i18next";
import Tooltip from "components/shared/Tooltip";
import { IProjectData } from "hooks/api/GQL/project/useProjectData.models";
import { deriveProjectConfiguration } from "hooks/permissions/utils/projectConfiguration";
import { IOrganization } from "models/organizations.models";
import { IRelianceProjectMetadata } from "models/projects.models";
import { DecisionStatus } from "utils/constants/healthAuthorities.constants";
import { ProjectStatus } from "utils/constants/project.constants";
import { includeInArray } from "utils/helpers";
import { isHAUser, isSponsorUser } from "utils/user/organization";

export const getShouldBeAbleToComplete = (
  project: IProjectData<IRelianceProjectMetadata>,
) => {
  const { referenceHA, relianceParticipants } = project.metadata;

  const { hasReferenceHAOrganization } = deriveProjectConfiguration(
    project?.type,
  );

  const hasReferenceHANecessaryData =
    !hasReferenceHAOrganization ||
    (Boolean(referenceHA.decisionDate) &&
      Boolean(referenceHA.decisionStatus) &&
      referenceHA.decisionStatus !== DecisionStatus.Pending);

  const hasAllRelianceParticipantsNecessaryData = relianceParticipants?.every(
    (participant) =>
      Boolean(participant.decisionDate) &&
      Boolean(participant.decisionStatus) &&
      participant.decisionStatus !== DecisionStatus.Pending,
  );

  return hasReferenceHANecessaryData && hasAllRelianceParticipantsNecessaryData;
};

const getOptionsFromStatuses = (statuses: ProjectStatus[], t: TFunction) =>
  statuses.map((status) => ({
    id: status,
    label: t(`project.${status}`),
  }));

export const getOrbisProjectStatuses = (
  currentStatus: ProjectStatus | undefined,
  organization: IOrganization | undefined,
  t: TFunction,
) => {
  const shouldIncludeCancelled =
    (isSponsorUser(organization) &&
      currentStatus !== ProjectStatus.Completed) ||
    (isHAUser(organization) &&
      currentStatus !== ProjectStatus.Planned &&
      currentStatus !== ProjectStatus.Completed);

  return getOptionsFromStatuses(
    [
      ProjectStatus.InProgress,
      ProjectStatus.Completed,
      ...includeInArray(ProjectStatus.Cancelled, shouldIncludeCancelled),
    ],
    t,
  );
};

export const getRelianceProjectStatuses = (
  project: IProjectData<IRelianceProjectMetadata> | undefined,
  t: TFunction,
) => {
  switch (project?.status) {
    case ProjectStatus.Planned:
      return getOptionsFromStatuses([ProjectStatus.Cancelled], t);

    case ProjectStatus.InProgress:
      const isCompletedDisabled = !getShouldBeAbleToComplete(project);

      const completeStatusOption = {
        id: ProjectStatus.Completed,
        label: t(`project.${ProjectStatus.Completed}`),
        disabled: isCompletedDisabled,
        adornment: isCompletedDisabled ? (
          <Tooltip
            title={t(`project.statusCantBeInCompletedState`, {
              ns: "projects",
            })}
          >
            <WarningAmberIcon color="warning" fontSize="small" />
          </Tooltip>
        ) : null,
      };

      return [
        completeStatusOption,
        ...getOptionsFromStatuses([ProjectStatus.Cancelled], t),
      ];

    case ProjectStatus.Cancelled:
      return [
        {
          id: project.previousStatus || "",
          label: t(`project.${project.previousStatus}`),
          adornment: (
            <Tooltip
              title={t(`project.statusCanBeRevertedToPrevState`, {
                ns: "projects",
              })}
            >
              <InformationIcon color="action" fontSize="small" />
            </Tooltip>
          ),
        },
      ];

    default:
      return [];
  }
};
