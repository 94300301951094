import { identityBasePart } from "./constants";

export default {
  all: () => `${identityBasePart}/users`,
  current: () => `${identityBasePart}/users/current`,
  acknowledgement: () => `${identityBasePart}/users/legal`,
  acknowledgementById: (id: string) => `${identityBasePart}/users/legal/${id}`,
  byId: (id: string) => `${identityBasePart}/users/${id}`,
  groups: () => `${identityBasePart}/users/groups`,
  getCurrentUserRoles: () => `${identityBasePart}/users/self/roles`,
  getUserProjectRoles: () => `${identityBasePart}/users/roles`,
  getUsersTemplate: () => `${identityBasePart}/users/template`,
  uploadUserTemplate: () =>
    `${identityBasePart}/users/template/bulk-upsert-request`,
  activationRequestById: (id: string) =>
    `${identityBasePart}/users/${id}/activation-request`,
  deactivationRequestById: (id: string) =>
    `${identityBasePart}/users/${id}/deactivation-request`,
  logout: () => `${identityBasePart}/logout`,
  timezones: () => `${identityBasePart}/nomenclatures/timezones`,
  // ToDo (AM): Might be deprecated. Use only after confirmation with BE
  memberGroups: () => `${identityBasePart}/member-groups`,
};
