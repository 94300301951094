export enum ProjectMembersModalType {
  EditAccess = "EDIT_ACCESS",
  RemoveAccess = "REMOVE_ACCESS",
  ToggleStatus = "TOGGLE_STATUS",
}

export enum MemberModalType {
  User,
  Group,
}

export enum ProjectMemberStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export namespace ProjectMemberStatus {
  export const fromBoolean = (isActive: boolean): ProjectMemberStatus => {
    return isActive ? ProjectMemberStatus.Active : ProjectMemberStatus.Inactive;
  };
}
