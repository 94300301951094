import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Grid } from "@mui/material";
import {
  FormComboBox,
  FormDate,
  FormTextInput,
} from "components/common/form-fields";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { useProject } from "context/ProjectContext";
import { IDocumentOutline } from "models/documents.models";
import DocumentSectionsSelect from "screens/Project/sections/Documents/Document/components/DocumentSectionsSelect";
import { TDocumentComponent } from "screens/Project/sections/Documents/Document/Document.types";
import { includeInArray } from "utils/helpers";
import { mapOrganizationsToSelectOptions } from "utils/helpers/mapOrganizationsToSelectOptions";
import {
  SendCopyComponentsFieldName,
  SendCopyComponentsSchema,
  TSendCopyComponentsForm,
} from "./SendCopyComponents.types";
import { getSendCopyComponentsDefaultValues } from "./SendCopyComponents.utils";
import styles from "./SendCopyComponents.styles";

export type TSendCopyComponentsProps = {
  isOpen: boolean;
  onClose: (shouldInvalidateDocumentQueries?: boolean) => void;
  component: TDocumentComponent | null;
  documentOutline: IDocumentOutline[] | undefined;
};

const SendCopyComponents = ({
  isOpen,
  onClose,
  component,
  documentOutline,
}: TSendCopyComponentsProps) => {
  const { participatingHAOrganizationsExceptUserOrganization } = useProject();

  const { t } = useTranslation(["documents", "common"]);

  const recipientOptions = useMemo(
    () =>
      mapOrganizationsToSelectOptions(
        participatingHAOrganizationsExceptUserOrganization,
      ),
    [participatingHAOrganizationsExceptUserOrganization],
  );

  const defaultValues = useMemo(
    () =>
      getSendCopyComponentsDefaultValues(
        includeInArray(component?.assetId, Boolean(component)),
      ),
    [component],
  );

  const methods = useForm<TSendCopyComponentsForm>({
    mode: "all",
    defaultValues,
    resolver: zodResolver(SendCopyComponentsSchema(t)),
  });

  const { formState, handleSubmit, reset } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  //TODO FTE-3447 - wire up with BE endpoints
  const onSubmit = (data: TSendCopyComponentsForm) => {
    onClose();
    reset();

    return data;
  };

  const onCancel = () => {
    onClose();
    reset();
  };

  const cancelButton = (
    <Button
      key="cancel"
      data-qaid="send-copy-components-cancel-button"
      variant="text"
      onClick={onCancel}
    >
      {t("button.cancel", { ns: "common" })}
    </Button>
  );

  const submitButton = (
    <Button
      key="submit"
      color="primary"
      data-qaid="send-copy-components-submit-button"
      variant="contained"
      disabled={!formState.isValid}
      type="submit"
    >
      {t("button.submit", { ns: "common" })}
    </Button>
  );

  return (
    <Modal
      data-qaid="send-copy-components-modal"
      open={isOpen}
      title={t("sendComponentsCopy.modalTitle")}
    >
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} sx={styles.container}>
          <Grid item xs={12}>
            {component ? (
              <b>{`${t("sendComponentsCopy.section")} ${
                component.headerText
              }`}</b>
            ) : (
              <DocumentSectionsSelect<TSendCopyComponentsForm>
                data-qaid="send-copy-document-sections-multi-select"
                dropdownOptions={documentOutline}
                name={SendCopyComponentsFieldName.Components}
                label={t("sendComponentsCopy.fields.components.label")}
                helperText={t(
                  "sendComponentsCopy.fields.components.helperText",
                )}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <FormComboBox
              data-qaid="send-copy-components-recipients-combobox"
              name={SendCopyComponentsFieldName.Recipients}
              label={t("sendComponentsCopy.fields.recipients.label")}
              options={recipientOptions}
              deselectAllLabelText={String(
                t("multiSelect.deselectAll", { ns: "common" }),
              )}
              selectAllLabelText={String(
                t("multiSelect.selectAll", { ns: "common" }),
              )}
              errorAdornmentAriaLabel={String(
                t("ariaLabels.textFieldError", {
                  ns: "common",
                }),
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <FormDate<TSendCopyComponentsForm>
              data-qaid="send-components-date-field"
              name={SendCopyComponentsFieldName.DueDate}
              label={t("sendComponentsCopy.fields.dueDate.label")}
              disablePast
            />
          </Grid>

          <Grid item xs={12}>
            <FormTextInput<TSendCopyComponentsForm>
              data-qaid="send-components-copy-instructions-text-field"
              name={SendCopyComponentsFieldName.Instructions}
              multiline
              rows={3}
              label={t("sendComponentsCopy.fields.instructions.label")}
            />
          </Grid>
        </Grid>
        <ModalActionButtons buttons={[cancelButton, submitButton]} />
      </FormWrapper>
    </Modal>
  );
};

export default SendCopyComponents;
