import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  clearAllButton: {
    fontFamily: "Inter",
    fontSize: 13,
    fontWeight: 400,
    lineHeight: "18px",
    p: 0.5,
  },

  showHideFiltersButton: {
    color: getPaletteColor("blueGrey.600"),
    height: 36,
    border: getBorderStyle("1px", "solid", "blueGrey.100"),
    flexShrink: 0,
    minWidth: 200,

    "& .MuiButton-startIcon": {
      marginLeft: 0,
    },

    "& .MuiButton-endIcon": {
      display: "flex",
      minWidth: 20,
      height: 20,
      px: "6px",
      borderRadius: 64,
      fontSize: 12,
      fontWeight: 500,
      backgroundColor: getPaletteColor("primary.main"),
      color: getPaletteColor("common.white"),
      marginRight: 0,
    },
  },
};

export default styles;
