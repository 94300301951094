import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  document: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },

  selectedHeader: {
    mb: 1,

    "&.selected": {
      color: getPaletteColor("primary.main"),
    },
  },

  selected: {
    boxShadow: (theme) =>
      `0px 1px 2px 1px ${getPaletteColor("primary.light")(theme)}`,
  },

  documentContainer: {
    overflow: "auto",
    flex: 1,
  },

  documentContent: {
    px: 5,
    py: 2.5,
    overflow: "auto",
    flexGrow: 1,
  },
};

export default styles;
