import { TStyles } from "utils/Styles";

const styles: TStyles = {
  flagContainer: {
    justifyContent: "start",
  },
  countryCode: {
    ml: 2,
    fontWeight: 500,
  },
};

export default styles;
