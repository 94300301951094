import { createSvgIcon } from "@mui/material";

export const CheckFilledIcon = createSvgIcon(
  <svg fill="none">
    <path
      d="M8.795 15.8749L4.625 11.7049L3.205 13.1149L8.795 18.7049L20.795 6.70492L19.385 5.29492L8.795 15.8749Z"
      fill="currentColor"
      fillOpacity="0.54"
    />
  </svg>,
  "CheckFilledIcon",
);
