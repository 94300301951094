import { TAvatarItem } from "components/shared/AvatarGroup/AvatarGroup";
import { EMPTY_LABEL } from "../getTextValue";

export type TInput = {
  id?: string;
  firstName?: string;
  lastName?: string;
};

export function getInitials(input: TInput | undefined) {
  const nameInitial = input?.firstName?.charAt(0).toUpperCase() ?? "";
  const lastNameInitial = input?.lastName?.charAt(0).toUpperCase() ?? "";

  return nameInitial && lastNameInitial
    ? `${nameInitial}${lastNameInitial}`
    : EMPTY_LABEL;
}

export function getFullName(input: TInput | undefined) {
  return input?.firstName && input?.lastName
    ? `${input?.firstName} ${input?.lastName}`
    : EMPTY_LABEL;
}

export function getAvatarProps(input: TInput | undefined): TAvatarItem {
  const fullName = getFullName(input);

  return {
    id: input?.id || fullName,
    tooltip: fullName,
    value: getInitials(input),
  };
}
