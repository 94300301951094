import { useTranslation } from "react-i18next";
import { ErrorOutline } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { ConfirmModal } from "components/shared";
import useUpdateProductVariantStatus from "hooks/api/GQL/entityManagement/useUpdateProductVariantStatus";
import {
  ProductVariantStatus,
  TEntityProduct,
  TProductVariant,
} from "../Products.types";
import styles from "./ChangeProductVariantStatusModal.styles";

export type TChangeProductVariantStatusModalProps = {
  isOpen: boolean;
  product?: TEntityProduct;
  productVariant?: TProductVariant;
  variantIndex?: number;
  onClose: () => void;
};

const ChangeProductVariantStatusModal = ({
  isOpen,
  product,
  productVariant,
  variantIndex,
  onClose,
}: TChangeProductVariantStatusModalProps) => {
  const { t } = useTranslation(["administration", "common"]);

  const { updateStatus } = useUpdateProductVariantStatus();

  const actionDescriptionText =
    productVariant?.status === ProductVariantStatus.Active
      ? t("inactivateProductVariant", {
          productName: product?.tradeName,
          index: variantIndex,
        })
      : t("activateProductVariant", {
          productName: product?.tradeName,
          index: variantIndex,
        });

  const onChangeStatus = (shouldChangeStatus: boolean) => {
    if (shouldChangeStatus && product && productVariant) {
      updateStatus({
        productVariantBody: {
          productId: product.id,
          productVariant: {
            id: productVariant.id,
            active: productVariant.status === ProductVariantStatus.Inactive,
          },
        },
      }).then(onClose);
    } else {
      onClose();
    }
  };

  return (
    <ConfirmModal
      maxWidth="md"
      open={isOpen}
      title={t("changeProductVariantStatus")}
      onConclude={onChangeStatus}
      confirmButtonText={t("button.ok", { ns: "common" })}
      cancelButtonText={t("button.cancel", { ns: "common" })}
      confirmColor="primary"
    >
      <Stack direction="column" alignItems="center" spacing={2} mb={4}>
        <ErrorOutline fontSize="large" sx={styles.errorIcon} />
        <Typography variant="body1">{actionDescriptionText}</Typography>
      </Stack>
    </ConfirmModal>
  );
};

export default ChangeProductVariantStatusModal;
