import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { Button } from "components/shared";
import { useProject } from "context/ProjectContext";
import { DocumentAction, DocumentStatus } from "utils/constants/doc.constants";
import { useDocumentsContext } from "../DocumentsContext";
import { useShareableDocuments } from "../modals/ShareDocument";

export const DocumentsActionButtons = () => {
  const { t } = useTranslation(["documents", "common"]);

  const { selectedDocuments, setCurrentActionOnDocument } =
    useDocumentsContext();

  const { participatingHAOrganizationsExceptUserOrganization } = useProject();

  const { shareableDocuments } = useShareableDocuments(selectedDocuments);

  const selectedStatusFinalDocs = useMemo(
    () =>
      selectedDocuments.filter((doc) => doc.status === DocumentStatus.Final),
    [selectedDocuments],
  );

  return (
    <Stack direction="row" alignItems="flex-start" spacing={1}>
      {!isEmpty(selectedStatusFinalDocs) && (
        <Button
          variant="outlined"
          onClick={() =>
            setCurrentActionOnDocument({
              documents: selectedStatusFinalDocs,
              documentAction: DocumentAction.Submit,
            })
          }
        >
          {t("button.submit", { ns: "common" })}
        </Button>
      )}
      {!isEmpty(participatingHAOrganizationsExceptUserOrganization) &&
        !isEmpty(shareableDocuments) && (
          <Button
            variant="outlined"
            onClick={() =>
              setCurrentActionOnDocument({
                documents: shareableDocuments,
                documentAction: DocumentAction.Share,
              })
            }
          >
            {t("documents.shareDocument.buttonShare")}
          </Button>
        )}
    </Stack>
  );
};
