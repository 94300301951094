import React, { KeyboardEvent, RefObject } from "react";
import { useTranslation } from "react-i18next";
import {
  Stack,
  SxProps,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import { Cell, flexRender, Row } from "@tanstack/react-table";
import { isFunction } from "lodash";
import SelectionCell from "components/shared/Table/components/SelectionCell";
import { formatStringToCamelCase } from "utils/helpers";
import ExpandCollapseIcon from "../ExpandCollapseIcon";
import { TRowAlign } from "../ReactTable.types";
import ReactTableCell from "./ReactTableCell";
import { TExpandableRowProps } from "./ReactTableRow.types";
import styles from "./ReactTableRow.styles";

const getCellStyles = <T extends object>(
  cell: Cell<T, unknown>,
  row: Row<T>,
) => {
  const { meta } = cell.column.columnDef;
  const cellStyles = meta?.bodyCellStyles;

  return isFunction(cellStyles) ? cellStyles(cell, row) : cellStyles;
};

export type TReactTableRowBaseProps<T extends object> = {
  rowData: Row<T> | null;
  onClick?: (row: Row<T>) => void;
  isDisabled?: boolean;
  isSelectable?: boolean;
  tableBodyRef?: RefObject<any>;
  hover?: boolean;
  rowAlign?: TRowAlign;
  expandableRowProps?: TExpandableRowProps<T>;
  rowStyles?:
    | SystemStyleObject<Theme>
    | ((row: Row<T>) => SxProps<Theme> | undefined);
};

function ReactTableRow<T extends object>({
  rowData,
  onClick,
  isDisabled,
  isSelectable,
  tableBodyRef,
  hover,
  rowAlign = "top",
  expandableRowProps,
  rowStyles,
  renderSubComponent,
}: TReactTableRowBaseProps<T> & {
  renderSubComponent?: (row: Row<T>) => React.ReactNode;
}) {
  const { t } = useTranslation("common");

  if (!rowData) {
    return null;
  }

  // TODO: maybe it's time to introduce "capitalize" utility with proper typings instead of TS assertion?
  const rowAlignProp = formatStringToCamelCase(
    rowAlign,
    "upper",
  ) as Capitalize<TRowAlign>;

  const combinedStyles = {
    ...styles.row,
    ...styles.rowAlign,
    ...styles[`rowAlign${rowAlignProp}`],
    ...(isDisabled && styles.rowDisabled),
    ...(isFunction(rowStyles) ? rowStyles(rowData) : rowStyles),
    ...(isFunction(onClick) && styles.rowClickable),
    ...(rowData.getIsExpanded() && renderSubComponent && styles.rowExpandable),
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTableRowElement>) => {
    const currentRow = tableBodyRef?.current?.children.namedItem(rowData.id);

    event.stopPropagation(); // necessary for pressing enter to work correctly when tabbing inside an expanded collapsible element

    switch (event.code.toLowerCase()) {
      case "enter":
        onClick?.(rowData);
        break;
      case "arrowdown":
        currentRow?.nextElementSibling?.focus();
        break;
      case "arrowup":
        currentRow?.previousElementSibling?.focus();
        break;
      default:
        break;
    }
  };

  const renderDefinedCellValue = (cell: Cell<T, any>) => {
    return flexRender(cell.column.columnDef.cell, cell.getContext());
  };

  const getAllColumnsSpanned = (row: Row<T>): boolean => {
    const { allColumnsSpanned = false } = expandableRowProps ?? {};

    return isFunction(allColumnsSpanned)
      ? allColumnsSpanned(row)
      : allColumnsSpanned;
  };

  const getTableRowTemplate = () => {
    if (rowData.getParentRow() && getAllColumnsSpanned(rowData)) {
      // There is an expanded row with only one cell per row. Do not display anything else.
      return rowData.getVisibleCells().map((cell, index) => (
        <React.Fragment key={cell.id}>
          {index === 0 ? (
            <ReactTableCell
              sx={getCellStyles(cell, rowData)}
              colSpan={rowData.getVisibleCells().length}
            >
              <Stack direction="row" sx={styles.groupedRowContainer}>
                {renderDefinedCellValue(cell)}
              </Stack>
            </ReactTableCell>
          ) : (
            <TableCell sx={styles.hiddenTableCell} />
          )}
        </React.Fragment>
      ));
    } else if (rowData.getIsGrouped()) {
      // This is a row with only one cell per grouping. Do not display anything else.
      return rowData.getVisibleCells().map((cell) => (
        <React.Fragment key={cell.id}>
          {cell.getIsGrouped() ? (
            <ReactTableCell
              sx={getCellStyles(cell, rowData)}
              colSpan={rowData.getVisibleCells().length}
              onClick={rowData.getToggleExpandedHandler()}
            >
              <Stack direction="row" sx={styles.groupedRowContainer}>
                <ExpandCollapseIcon row={rowData} />
                {renderDefinedCellValue(cell)}
                <Typography variant="body2" sx={styles.rowDataCount}>
                  ({rowData.subRows.length})
                </Typography>
              </Stack>
            </ReactTableCell>
          ) : (
            <TableCell sx={styles.hiddenTableCell} />
          )}
        </React.Fragment>
      ));
    } else {
      // This is a regular row with cells. Do not display cells that were grouped by.
      return rowData
        .getVisibleCells()
        .map((cell) => (
          <React.Fragment key={cell.id}>
            {!cell.getIsPlaceholder() ? (
              <ReactTableCell sx={getCellStyles(cell, rowData)}>
                {renderDefinedCellValue(cell)}
              </ReactTableCell>
            ) : (
              <TableCell sx={styles.hiddenTableCell} />
            )}
          </React.Fragment>
        ));
    }
  };

  return (
    <React.Fragment key={rowData.id}>
      <TableRow
        data-testid="table-body-row"
        data-qaid="table-body-row"
        onKeyDown={handleKeyDown}
        onClick={() => onClick?.(rowData)}
        sx={combinedStyles}
        tabIndex={0}
        id={rowData.id}
        hover={hover}
      >
        {isSelectable && (
          <SelectionCell
            disabled={isDisabled}
            checked={rowData.getIsSelected()}
            onChange={rowData.getToggleSelectedHandler()}
            onClick={(event) => event.stopPropagation()} // have to stop propagation in order to avoid triggering onRowClick
            ariaLabel={t("ariaLabels.checkboxFor", { id: rowData.id })}
          />
        )}
        {getTableRowTemplate()}
      </TableRow>

      {rowData.getIsExpanded() && renderSubComponent && (
        <TableRow
          key={`${rowData.id}-subcomponent`}
          data-testid="table-body-row-subcomponent"
          data-qaid="table-body-row-subcomponent"
          onKeyDown={handleKeyDown}
          sx={{ ...combinedStyles, ...styles.rowExpandedContent }}
          tabIndex={0}
          id={`${rowData.id}-subcomponent`}
        >
          {rowData.getVisibleCells().map((_, index) =>
            index === 0 ? (
              <ReactTableCell
                sx={{ padding: 0 }}
                colSpan={rowData.getVisibleCells().length}
                key={index}
              >
                {renderSubComponent(rowData)}
              </ReactTableCell>
            ) : (
              <TableCell sx={styles.hiddenTableCell} key={index} />
            ),
          )}
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default ReactTableRow;
