import { alpha } from "@mui/material";
import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  chip: {
    backgroundColor: getPaletteColor("common.white"),
    fontSize: 13,
    height: 24,
    lineHeight: "18px",

    "&.MuiChip-outlinedDefault": {
      borderColor: getPaletteColor("blueGrey.300"),
    },

    "&.MuiChip-colorDefault": {
      color: getPaletteColor("blueGrey.600"),
    },

    "& .MuiChip-icon": {
      color: "inherit",
    },

    "&.MuiChip-outlinedSuccess": {
      borderColor: (theme) => alpha(theme.palette.success.main, 0.5),
    },
  },

  noStatus: {
    color: getPaletteColor("text.secondary"),
  },
};

export default styles;
