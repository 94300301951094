/* istanbul ignore file */
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import getEnv from "env";

export const reactPlugin = new ReactPlugin();

const clickPluginInstance = new ClickAnalyticsPlugin();

const clickPluginConfig = {
  dataTags: {
    parentDataTag: "group",
    useDefaultContentNameOrId: true,
    customDataPrefix: "data-insights-",
  },
  autoCapture: true,
};

const connectionString = getEnv().appInsightsConnectionString;

if (connectionString) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: getEnv().appInsightsConnectionString,
      enableAutoRouteTracking: true,
      // @ts-expect-error missing type definitions
      extensions: [reactPlugin, clickPluginInstance],
      extensionConfig: {
        [clickPluginInstance.identifier]: clickPluginConfig,
      },
    },
  });

  appInsights.loadAppInsights();
}
