const styles = {
  container: {
    "& .MuiPaper-root": {
      minWidth: 750,
      maxWidth: "initial",
    },
  },

  section: {
    "& .MuiTableCell-head": {
      py: 1,
      lineHeight: 1.43,
    },

    "& .MuiTableCell-body:not(:last-of-type)": {
      py: 1,
    },
  },
};

export default styles;
