import { groupBy } from "lodash";
import { TEntityOrganizationRole } from "hooks/api/GQL/entityManagement/useCreateProductEntity/useCreateProductEntity.models";

export const getEntityOrganizationRoles = (
  entityOrganizationIds: string[],
): TEntityOrganizationRole[] => {
  const filteredIdToTypePairs = entityOrganizationIds
    .map((id) => id.split("-"))
    .filter((parsed) => parsed.length > 1);

  const groupedById = groupBy(filteredIdToTypePairs, ([id]) => id);

  return Object.entries(groupedById).map(
    ([id, idToTypePair]): TEntityOrganizationRole => ({
      entityOrganizationId: id,
      organizationRoleTypeCodes: idToTypePair.flatMap(
        (code) => code.pop() ?? [],
      ),
    }),
  );
};
