import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import flatMap from "lodash/flatMap";
import uniqBy from "lodash/uniqBy";
import { FilterType, TSelectFilter } from "components/shared/types/filter";
import { TLinkedOrganizationEntity } from "screens/EntityManagement/components/Organization/Organization.types";
import { TSubstance } from "../Substances.types";
import { SubstancesTable } from "./SubstancesTable.types";

type TUseSubstancesFilters = {
  substances: TSubstance[] | undefined;
};

export const useSubstancesFilters = ({
  substances,
}: TUseSubstancesFilters): Record<string, TSelectFilter> => {
  const { t } = useTranslation(["administration", "common", "projects"]);

  return useMemo(
    () => ({
      [SubstancesTable.Class]: {
        type: FilterType.Select,
        label: t("substancesColumns.class"),
        options:
          uniqBy(
            substances?.map((substance) => ({
              text: substance.drugClassType.name,
              value: substance.drugClassType.code,
            })),
            "value",
          ) ?? [],
        selectAllLabel: t("multiSelect.selectAll", { ns: "common" }),
      },
      [SubstancesTable.LinkedOrganizations]: {
        type: FilterType.Select,
        label: t("substancesColumns.linkedOrganizations"),
        options: substances
          ? uniqBy(
              flatMap(substances, "linkedOrganizations").map(
                (organization: TLinkedOrganizationEntity) => ({
                  text: organization.name,
                  value: organization.id,
                }),
              ),
              "value",
            )
          : [],
        selectAllLabel: t("multiSelect.selectAll", { ns: "common" }),
      },
      [SubstancesTable.Status]: {
        type: FilterType.Select,
        label: t("substancesColumns.status"),
        options:
          uniqBy(
            substances?.map((substance) => ({
              text: t(`${substance.status}`),
              value: substance.status,
            })),
            "value",
          ) ?? [],
        selectAllLabel: t("multiSelect.selectAll", { ns: "common" }),
      },
    }),
    [t, substances],
  );
};
