import { TFunction } from "i18next";
import { getIsFeatureOn } from "components/Auth/accessChecks";
import { IDocument } from "models/documents.models";
import { TFeatureFlagsMap } from "models/featureFlags.models";
import { IOrganization } from "models/organizations.models";
import { DocumentStatus } from "utils/constants/doc.constants";
import { US_DATE_FORMAT } from "utils/constants/format.constants";
import { ProjectType } from "utils/constants/project.constants";
import { EMPTY_LABEL, formatDate } from "utils/helpers";
import { isHAUser } from "utils/user/organization";
import DocumentName from "./DocumentRow/DocumentName";
import RowActions from "./DocumentRow/RowActions";
import { RowStatus } from "./DocumentRow/RowStatus";
import { TDocumentsRowData } from "./Documents.types";

export type TDocumentStatusChangeAction = {
  documents: IDocument[];
  targetStatus: DocumentStatus;
};

export const getDocumentsForRender = (
  documents: IDocument[] | undefined,
  t: TFunction,
): TDocumentsRowData[] =>
  documents?.map((document) => {
    const version = document.latestVersion?.version;
    const fileType = document.metadata?.fileType;

    return {
      ...document,
      version: version ? `v${version}` : EMPTY_LABEL,
      fileType: fileType ? t(`fileTypes.${fileType}`) : EMPTY_LABEL,
      name: <DocumentName document={document} />,
      status: <RowStatus document={document} t={t} />,
      type: t(`subtypeOptions.${document.type}`),
      lastModifiedDate: formatDate(document.lastModifiedDate, US_DATE_FORMAT),
      actions: <RowActions document={document} />,
    };
  }) ?? [];

export const shouldShowImportButton = ({
  organization,
  featureFlags,
  projectType,
}: {
  organization: IOrganization | undefined;
  featureFlags: TFeatureFlagsMap;
  projectType: ProjectType;
}) =>
  !isHAUser(organization) ||
  projectType === ProjectType.Orbis ||
  getIsFeatureOn("FTE-23951_enableHAUnstructuredContent")({
    featureFlags,
  });
