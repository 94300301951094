/* eslint-disable no-restricted-imports */
import { Card as MuiCard, CardProps as MuiCardProps } from "@mui/material";
import styles from "./Card.styles";

export type TCardProps = MuiCardProps;

const Card = (props: TCardProps) => {
  const { sx: _sx, ...rest } = props;

  const sx = { ...styles.default, ..._sx };

  return <MuiCard sx={sx} {...rest} />;
};

export default Card;
