import { useState } from "react";
import useUser from "hooks/useUser";
import { TPreviewDocument } from "models/documents.models";
import { getIsAssetOwnedByUserOrganization } from "utils/helpers";
import PreviewContent from "./components/PreviewContent";
import SecurityNotice from "./components/SecurityNotice";

export type TPreviewDocumentModalProps = {
  onClose: () => void;
  document: TPreviewDocument;
};

const PreviewDocument = ({ document, onClose }: TPreviewDocumentModalProps) => {
  const { organization } = useUser();

  const [isSecurityNoticeAccepted, setIsSecurityNoticeAccepted] =
    useState(false);

  const isSecurityNoticeOpen =
    // This is a quick fix to disable the security notice for product preview. The condition will
    // need to be updated to the proper logic in FTE-9717
    false &&
    !isSecurityNoticeAccepted &&
    !getIsAssetOwnedByUserOrganization(document, organization);

  return isSecurityNoticeOpen ? (
    <SecurityNotice
      onCancel={onClose}
      onAccept={() => setIsSecurityNoticeAccepted(true)}
    />
  ) : (
    <PreviewContent document={document} onClose={onClose} />
  );
};

export default PreviewDocument;
