import { ICheckboxOption } from "../CheckboxTree/CheckboxTree.utils";
import SelectionChip from "../SelectionChip/SelectionChip";

type TSelectionChipsProps = {
  chips: ICheckboxOption[];
  isRemovable: boolean;
  onChange: (chip: ICheckboxOption) => void;
};

const SelectionChips = ({
  chips,
  isRemovable,
  onChange,
}: TSelectionChipsProps) => {
  if (isRemovable) {
    return (
      <>
        {chips.map((chip) => (
          <SelectionChip key={chip.id} chip={chip} onRemove={onChange} />
        ))}
      </>
    );
  } else {
    const [firstOption, ...rest] = chips;

    return (
      <>
        <SelectionChip chip={firstOption} />
        {Boolean(rest.length) && <span>{`+${rest.length}`}</span>}
      </>
    );
  }
};

export default SelectionChips;
