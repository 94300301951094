export enum FHIRResourceType {
  Account = "Account",
  ActivityDefinition = "ActivityDefinition",
  AdverseEvent = "AdverseEvent",
  AllergyIntolerance = "AllergyIntolerance",
  Appointment = "Appointment",
  AppointmentResponse = "AppointmentResponse",
  Basic = "Basic",
  Binary = "Binary",
  BodySite = "BodySite",
  Bundle = "Bundle",
  CapabilityStatement = "CapabilityStatement",
  CarePlan = "CarePlan",
  CareTeam = "CareTeam",
  ChargeItem = "ChargeItem",
  Claim = "Claim",
  ClaimResponse = "ClaimResponse",
  ClinicalImpression = "ClinicalImpression",
  CodeSystem = "CodeSystem",
  Communication = "Communication",
  CommunicationRequest = "CommunicationRequest",
  CompartmentDefinition = "CompartmentDefinition",
  Composition = "Composition",
  ConceptMap = "ConceptMap",
  Condition = "Condition",
  Consent = "Consent",
  Contract = "Contract",
  Coverage = "Coverage",
  DataElement = "DataElement",
  DetectedIssue = "DetectedIssue",
  Device = "Device",
  DeviceComponent = "DeviceComponent",
  DeviceMetric = "DeviceMetric",
  DeviceRequest = "DeviceRequest",
  DeviceUseStatement = "DeviceUseStatement",
  DiagnosticReport = "DiagnosticReport",
  DocumentManifest = "DocumentManifest",
  DocumentReference = "DocumentReference",
  DomainResource = "DomainResource",
  EligibilityRequest = "EligibilityRequest",
  EligibilityResponse = "EligibilityResponse",
  Encounter = "Encounter",
  Endpoint = "Endpoint",
  EnrollmentRequest = "EnrollmentRequest",
  EnrollmentResponse = "EnrollmentResponse",
  EpisodeOfCare = "EpisodeOfCare",
  ExpansionProfile = "ExpansionProfile",
  ExplanationOfBenefit = "ExplanationOfBenefit",
  FamilyMemberHistory = "FamilyMemberHistory",
  Flag = "Flag",
  Goal = "Goal",
  GraphDefinition = "GraphDefinition",
  Group = "Group",
  GuidanceResponse = "GuidanceResponse",
  HealthcareService = "HealthcareService",
  ImagingManifest = "ImagingManifest",
  ImagingStudy = "ImagingStudy",
  Immunization = "Immunization",
  ImmunizationRecommendation = "ImmunizationRecommendation",
  ImplementationGuide = "ImplementationGuide",
  Ingredient = "Ingredient",
  Library = "Library",
  Linkage = "Linkage",
  List = "List",
  Location = "Location",
  Measure = "Measure",
  MeasureReport = "MeasureReport",
  Media = "Media",
  Medication = "Medication",
  MedicationAdministration = "MedicationAdministration",
  MedicationDispense = "MedicationDispense",
  MedicationRequest = "MedicationRequest",
  MedicationStatement = "MedicationStatement",
  MedicinalProductDefinition = "MedicinalProductDefinition",
  MessageDefinition = "MessageDefinition",
  MessageHeader = "MessageHeader",
  NamingSystem = "NamingSystem",
  NutritionOrder = "NutritionOrder",
  Observation = "Observation",
  OperationDefinition = "OperationDefinition",
  OperationOutcome = "OperationOutcome",
  Organization = "Organization",
  Parameters = "Parameters",
  Patient = "Patient",
  PaymentNotice = "PaymentNotice",
  PaymentReconciliation = "PaymentReconciliation",
  Person = "Person",
  PlanDefinition = "PlanDefinition",
  Practitioner = "Practitioner",
  PractitionerRole = "PractitionerRole",
  Procedure = "Procedure",
  ProcedureRequest = "ProcedureRequest",
  ProcessRequest = "ProcessRequest",
  ProcessResponse = "ProcessResponse",
  Provenance = "Provenance",
  Questionnaire = "Questionnaire",
  QuestionnaireResponse = "QuestionnaireResponse",
  ReferralRequest = "ReferralRequest",
  RelatedPerson = "RelatedPerson",
  RequestGroup = "RequestGroup",
  ResearchStudy = "ResearchStudy",
  ResearchSubject = "ResearchSubject",
  Resource = "Resource",
  RiskAssessment = "RiskAssessment",
  Schedule = "Schedule",
  SearchParameter = "SearchParameter",
  Sequence = "Sequence",
  ServiceDefinition = "ServiceDefinition",
  Slot = "Slot",
  Specimen = "Specimen",
  StructureDefinition = "StructureDefinition",
  StructureMap = "StructureMap",
  Subscription = "Subscription",
  Substance = "Substance",
  SubstanceDefinition = "SubstanceDefinition",
  SupplyDelivery = "SupplyDelivery",
  SupplyRequest = "SupplyRequest",
  Task = "Task",
  TestReport = "TestReport",
  TestScript = "TestScript",
  ValueSet = "ValueSet",
  VisionSubscription = "VisionSubscription",
}

export enum FHIRResourceViewMode {
  Editing = "Editing",
  Viewing = "Viewing",
}

export enum FHIRResourceIndentifier {
  Portfolio = "portfolio",
  Products = "products",
  Therapeutic = "therapeutic",
  Outline = "country-product-outline",
}

export enum FHIRResourceStatus {
  Active = "active",
  Inactive = "inactive",
  EnteredInError = "entered-in-error",
}
