import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { gql } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { TUpdateProductVariantMutationData } from "./useUpdateProductVariant.models";

export const query = gql`
  mutation UpdateProductVariant(
    $productId: String
    $productVariantId: String
    $productVariantBody: ProductVariantBody
  ) {
    updateProductVariant(
      productId: $productId
      productVariantId: $productVariantId
      productVariantBody: $productVariantBody
    ) {
      id
    }
  }
`;

const useUpdateProductVariant = () => {
  const { t } = useTranslation("administration", {
    keyPrefix: "notifications",
  });

  const { showAlert } = useAlerts();
  const queryClient = useQueryClient();

  const { data, isError, isLoading, isSuccess, mutateAsync } = useMutation(
    ({
      productId,
      productVariantId,
      productVariantBody,
    }: TUpdateProductVariantMutationData) =>
      services.graphQL
        .request(query, {
          productId,
          productVariantId,
          productVariantBody,
        })
        .then(() => {
          showAlert({
            message: t("updateProductVariantSuccess"),
            severity: AlertSeverity.Success,
          });

          queryClient.invalidateQueries(QueryAPIKey.GetEntityProducts);
          queryClient.invalidateQueries(QueryAPIKey.GetProductVariants);
        })
        .catch((error) => {
          if (error.response?.errors[0]?.extensions?.response?.status === 409) {
            showAlert({
              message: t("updateProductVariantFailureAlreadyExists"),
              severity: AlertSeverity.Error,
            });

            throw error;
          }

          showAlert({
            message: t("updateProductVariantFailure"),
            severity: AlertSeverity.Error,
          });

          throw error;
        }),
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    updateProductVariant: mutateAsync,
  };
};

export default useUpdateProductVariant;
