import { getIsFeatureOn } from "components/Auth/accessChecks";
import { IProjectData } from "hooks/api/GQL/project/useProjectData.models";
import { TUserPermissions } from "hooks/permissions/usePermissions.types";
import { TUseUserRoles } from "hooks/user/useUserRoles/useUserRoles";
import { IDocument } from "models/documents.models";
import { TFeatureFlagsMap } from "models/featureFlags.models";
import { IOrganization } from "models/organizations.models";
import { TProjectMetadata } from "models/projects.models";
import { TUserData } from "models/users.models";
import { DocumentStatus, DocumentSubtype } from "utils/constants/doc.constants";
import { ProjectType } from "utils/constants/project.constants";
import {
  getIsAssetCreatedByUser,
  getIsAssetOwnedByUserOrganization,
} from "utils/helpers";
import { isHAUser, isSponsorUser } from "utils/user/organization";
import {
  getIsDocumentComposite,
  getIsDocumentReadyForSending,
  getIsDocumentTypeSupportedForDownload,
  getIsDocumentTypeSupportedForPreview,
  getIsDocumentUnstructuredAAID,
} from "./document";

export const getCanDocumentBeSubmitted = (
  document: IDocument | null,
  organization: IOrganization | undefined,
) =>
  getIsAssetOwnedByUserOrganization(document, organization) &&
  !Boolean(document?.metadata?.submissionDate) &&
  document?.status === DocumentStatus.Final;

export const getCanDocumentBeDeleted = ({
  project,
  document,
  user,
  organization,
  userRoles,
  permissions,
  featureFlags,
}: {
  project: IProjectData<TProjectMetadata> | null | undefined;
  document: IDocument | null;
  user: TUserData | undefined;
  organization: IOrganization | undefined;
  userRoles: TUseUserRoles;
  permissions: Partial<TUserPermissions>;
  featureFlags: TFeatureFlagsMap;
}) => {
  if (!project?.active) {
    return false;
  }

  switch (project?.type) {
    case ProjectType.Reliance:
    case ProjectType.RegulatoryReview:
      return getCanDocumentBeDeletedForRelianceAndRR(
        document,
        user,
        organization,
        userRoles,
        Boolean(permissions.canDeleteContentToTenancy),
        featureFlags,
      );

    default:
      return getCanDocumentBeDeletedByDefault(document, organization);
  }
};

export const getCanDocumentBeDeletedForRelianceAndRR = (
  document: IDocument | null,
  user: TUserData | undefined,
  organization: IOrganization | undefined,
  { isContentEditor }: TUseUserRoles,
  canDeleteContentToTenancy: boolean,
  featureFlags: TFeatureFlagsMap,
) => {
  if (isContentEditor && !getIsAssetCreatedByUser(document, user)) {
    return false;
  }

  if (
    isHAUser(organization) &&
    !getIsFeatureOn("FTE-25852_enableHAUnstructuredContentManagement")({
      featureFlags,
    })
  ) {
    return false;
  }

  return (
    canDeleteContentToTenancy &&
    document?.status === DocumentStatus.InVerification &&
    getIsAssetOwnedByUserOrganization(document, organization)
  );
};

export const getCanDocumentBeDeletedByDefault = (
  document: IDocument | null,
  organization: IOrganization | undefined,
) =>
  document?.status !== DocumentStatus.Final &&
  document?.type !== DocumentSubtype.GSP &&
  getIsAssetOwnedByUserOrganization(document, organization);

export const getCanDocumentBeSent = (
  document: IDocument | null,
  organization: IOrganization | undefined,
) =>
  getIsAssetOwnedByUserOrganization(document, organization) &&
  getIsDocumentReadyForSending(document?.status, organization);

export const getCanDocumentBeConverted = (
  document: IDocument | null,
  organization: IOrganization | undefined,
  permissions: Partial<TUserPermissions>,
) =>
  (permissions.canEditContentInAllProjects || permissions.canEditContent) &&
  getIsAssetOwnedByUserOrganization(document, organization) &&
  getIsDocumentUnstructuredAAID(document);

export const getCanDocumentBeShared = (
  document: IDocument,
  organization: IOrganization | undefined,
  { isProjectManager }: TUseUserRoles,
): Boolean => {
  const { status } = document;

  if (!getIsAssetOwnedByUserOrganization(document, organization)) {
    return false;
  }

  if (
    !Object.values(DocumentSubtype).includes(document.type) ||
    [DocumentSubtype.GSP, DocumentSubtype.ECTD].includes(document.type)
  ) {
    return false;
  }

  if (!isProjectManager || status === DocumentStatus.Shared) {
    return false;
  }

  if (isSponsorUser(organization) && status === DocumentStatus.Final) {
    return true;
  }

  if (isHAUser(organization) && status === DocumentStatus.Final) {
    return true;
  }

  return false;
};

export const getCanDocumentBePreviewed = (
  document: IDocument | null,
  permissions: Partial<TUserPermissions>,
) =>
  (permissions.canViewContentInAllProjects || permissions.canViewContent) &&
  !getIsDocumentComposite(document) &&
  getIsDocumentTypeSupportedForPreview(document?.metadata?.fileType);

export const getCanDocumentBeDownloaded = (document: IDocument | null) =>
  !getIsDocumentComposite(document) &&
  getIsDocumentTypeSupportedForDownload(document?.metadata?.fileType);

export const getIsDocumentReadyForStartReview = (
  document: IDocument,
  organization: IOrganization | undefined,
) =>
  getIsAssetOwnedByUserOrganization(document, organization) &&
  document.status === DocumentStatus.Draft;

export const getCanDocumentBeHAQed = (
  document: IDocument,
  organization: IOrganization | undefined,
) =>
  ![DocumentSubtype.GSP, DocumentSubtype.ECTD].includes(document.type) &&
  isHAUser(organization);

export const getCanViewSubmitHistory = (
  document: IDocument,
  organization: IOrganization | undefined,
) =>
  // This permission is not covered by our usePermissions hook
  Boolean(
    document.status === DocumentStatus.Final &&
      isSponsorUser(organization) &&
      document.metadata?.submissionDate,
  );

export const getCanDocumentBeViewed = (document: IDocument) =>
  [DocumentSubtype.ECTD].includes(document.type) &&
  [DocumentStatus.InVerification, DocumentStatus.Final].includes(
    document.status,
  );

export const getCanEditDocumentName = (document: IDocument) =>
  [DocumentSubtype.ECTD].includes(document.type) &&
  [DocumentStatus.InVerification].includes(document.status);
