/* eslint-disable no-restricted-imports */
import { Link } from "react-router-dom";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Link as MuiLink,
  LinkProps,
} from "@mui/material";
import styles from "./Breadcrumbs.styles";

export type TBreadcrumb = {
  name: string;
  to: string;
};

type TBreadcrumbsProps = {
  breadcrumbs: TBreadcrumb[];
  ariaLabel: string;
};

type TBreadcrumbLinkProps = LinkProps & {
  to: string;
  replace?: boolean;
};

const Breadcrumbs = ({ breadcrumbs, ariaLabel }: TBreadcrumbsProps) => {
  return (
    <MuiBreadcrumbs aria-label={ariaLabel} sx={styles.breadcrumbs}>
      {breadcrumbs.map((crumb) => (
        <BreadcrumbLink
          key={crumb.name}
          underline="hover"
          color="inherit"
          to={crumb.to}
        >
          {crumb.name}
        </BreadcrumbLink>
      ))}
    </MuiBreadcrumbs>
  );
};

const BreadcrumbLink = (props: TBreadcrumbLinkProps) => {
  return <MuiLink {...props} component={Link} />;
};

export default Breadcrumbs;
