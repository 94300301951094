import { useTranslation } from "react-i18next";
import { FormComboBox } from "components/common/form-fields";
import { TComboBoxOption } from "components/shared/ComboBox/ComboBox";
import { DossierDifferencesModalFields } from "screens/Project/screens/GSP/GSPItems";
import { styles } from "screens/Project/screens/GSP/GSPItems";
import { GroupedDropdown } from "screens/Project/sections/Documents";

export type TApplicableHASelectProps = {
  options: TComboBoxOption[];
  isLoading?: boolean;
};

function ApplicableHASelect(props: TApplicableHASelectProps) {
  const { isLoading, options } = props;
  const { t } = useTranslation(["projects", "common"]);

  return (
    <FormComboBox
      name={DossierDifferencesModalFields.HealthAuthorities}
      sx={styles.membersDropdown}
      label={t("dossierDifferences.chooseHA")}
      deselectAllLabelText={t("multiSelect.deselectAll", {
        ns: "common",
      })}
      errorAdornmentAriaLabel={t("ariaLabels.textFieldError", {
        ns: "common",
      })}
      selectAllLabelText={t("multiSelect.selectAll", { ns: "common" })}
      loading={isLoading}
      groupBy={(option) => option.type as string}
      renderGroup={GroupedDropdown}
      getOptionDisabled={(option) => !!option.disabled}
      options={options}
      data-qaid="dossier-differences-ha-multi-select"
    />
  );
}

export default ApplicableHASelect;
