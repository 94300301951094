import { TFunction } from "i18next";
/* eslint-disable max-len */
import { TUpdateEntityOrganizationBody } from "hooks/api/GQL/entityManagement/useUpdateEntityOrganization/useUpdateEntityOrganization.models";
/* eslint-enable max-len */
import { EntityStatus } from "models/entities.models";
import { TDictionary } from "screens/EntityManagement/components/Products/Products.types";
import { formatValuesToOptions } from "utils/helpers";
import { objectKeys, TOption } from "utils/types";
import { TEntityOrganization } from "../Organization.types";
import {
  OrganizationFieldName,
  TOrganizationForm,
} from "./OrganizationForm.types";

export const getOrganizationRole = (
  organizationRole: TDictionary[],
  t: TFunction,
): TOption<string>[] => {
  const getLabel = (type: TDictionary) =>
    t(`createOrganizationalModal.organizationRoles.${type.code}`);

  const getValue = (type: TDictionary) => type.code;

  return formatValuesToOptions(organizationRole, getLabel, getValue);
};

export const getStatusFieldOptions = (t: TFunction) =>
  objectKeys(EntityStatus).map((key) => ({
    label: t(EntityStatus[key]),
    value: EntityStatus[key],
  }));

export const getCountryFieldOptions = (countriesList: TDictionary[]) =>
  formatValuesToOptions(
    countriesList,
    (country) => country.name,
    (country) => country.code,
  );

export const getOrganizationFormValuesForEdit = (
  organization: TEntityOrganization,
  organizationRoleTypeOptions: TOption<string>[],
): TOrganizationForm => ({
  [OrganizationFieldName.OrganizationName]: organization.name ?? "",
  [OrganizationFieldName.OrganizationAlias]: organization.alias ?? "",
  [OrganizationFieldName.OrganizationRole]:
    organizationRoleTypeOptions.filter((option) =>
      organization.roleTypes?.includes(option.value),
    ) ?? [],
  [OrganizationFieldName.Address]: organization.address ?? "",
  [OrganizationFieldName.City]: organization.city ?? "",
  [OrganizationFieldName.ZipCode]: organization.zipCode ?? "",
  [OrganizationFieldName.Country]: organization.country ?? "",
  [OrganizationFieldName.Status]: organization.status ?? "",
});

export const getOrganizationValuesForUpdate = (
  originalOrganizationEntity: TEntityOrganization,
  values: TOrganizationForm,
): TUpdateEntityOrganizationBody => {
  return {
    id: originalOrganizationEntity.id,
    name: values[OrganizationFieldName.OrganizationName].trim(),
    alias: values[OrganizationFieldName.OrganizationAlias],
    organizationTypeCode: originalOrganizationEntity.type,
    organizationRoleTypeCodes: values[
      OrganizationFieldName.OrganizationRole
    ].map((roleType) => roleType.value),
    address: values[OrganizationFieldName.Address],
    city: values[OrganizationFieldName.City],
    postCode: values[OrganizationFieldName.ZipCode],
    countryTypeCode: values[OrganizationFieldName.Country],
    active: values[OrganizationFieldName.Status] === EntityStatus.Active,
  };
};
