import { TStyles } from "utils/Styles";

const styles: TStyles = {
  loadingIndicator: {
    position: "absolute",
    left: "50%",
    top: "50%",
    translate: "-50% -50%",
  },

  hidden: {
    display: "none",
  },
};

export default styles;
