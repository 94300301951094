import { forwardRef } from "react";
import { MenuItem } from "@mui/material";
import Select, { TOption, TSelectProps } from "components/shared/Select";

type TAssignmentSelectProps = TSelectProps<string> & {
  options: TOption[];
};

const AssignmentSelect = forwardRef(
  ({ options, ...selectProps }: TAssignmentSelectProps, ref) => {
    return (
      <Select variant="standard" {...selectProps} ref={ref}>
        {options.map(({ label, value, disabled }) => (
          <MenuItem key={value} value={value} disabled={disabled}>
            {label}
          </MenuItem>
        ))}
      </Select>
    );
  },
);

export default AssignmentSelect;
