import { TStyles } from "utils/Styles";

const styles: TStyles = {
  content: {
    pt: 2,
    pb: 4,
  },
  select: {
    width: 1,
  },
};

export default styles;
