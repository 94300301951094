import { isEmpty } from "lodash";
import { TBasicShareTrackingRecord } from "hooks/api/GQL/haq/useHAQsWithContent.models";
import { ISO_DATE_FORMAT } from "utils/constants/format.constants";
import {
  IShareHistoryItem,
  ShareHistoryItemStatus,
  ShareHistoryItemType,
} from "utils/constants/shareHistory.constants";
import { formatDate } from "utils/helpers/formatDate";

export const getIsAssetCopiedById = (
  id: string | undefined,
  shareHistory: Record<string, IShareHistoryItem[]> | undefined,
) => {
  if (!id || isEmpty(shareHistory)) {
    return false;
  }

  return (shareHistory[id] || []).some(hasCopyRecord);
};

export const getIsAssetCopied = (
  shareHistory: Pick<IShareHistoryItem, "type">[] | undefined,
) => {
  if (isEmpty(shareHistory)) {
    return false;
  }

  return shareHistory?.some(hasCopyRecord) ?? false;
};

export const getIsHAQAcknowledged = (
  shareHistory: TBasicShareTrackingRecord[] | undefined,
) => {
  if (isEmpty(shareHistory)) {
    return false;
  }

  return shareHistory?.some(hasAcknowledgeRecord) ?? false;
};

export const getCopiedDate = (
  shareHistory: TBasicShareTrackingRecord[] | undefined,
) => {
  const copyRecord: TBasicShareTrackingRecord | undefined =
    shareHistory?.find(hasCopyRecord);

  return copyRecord && formatDate(copyRecord.submissionDate, ISO_DATE_FORMAT);
};

export const hasCopyRecord = (el: Pick<IShareHistoryItem, "type">) =>
  el.type === ShareHistoryItemType.Copy;

export const hasAcknowledgeRecord = (el: TBasicShareTrackingRecord) =>
  el.status === ShareHistoryItemStatus.Acknowledged;
