import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Stack, Typography } from "@mui/material";
import HAQActions from "components/common/HAQ/HAQHeader/components/HAQActions";
import HAQShareHistory from "components/common/HAQ/HAQShareHistory/HAQShareHistory";
import { THAQShareHistoryTableProps } from "components/common/HAQ/HAQShareHistory/HAQShareHistoryModal/HAQShareHistoryModal.types";
import { Button } from "components/shared";
import useProject from "context/ProjectContext/useProject";
import useOrganizations from "hooks/api/REST/organizations/useOrganizations";
import usePermissions from "hooks/permissions";
import { IHAQ } from "models/HAQ.models";
import { Path } from "router/paths";
import { HAQContextProvider } from "screens/Project/screens/HAQ/HAQContext";
import {
  getDisplayedAssetStatus,
  getFormattedInterestedPHAs,
  getHAQShortName,
} from "utils/helpers";
import styles from "./Header.styles";

export type THAQDrawerHeaderProps = {
  HAQ: IHAQ | undefined;
  HAQResponse: IHAQ | undefined;
  onCloseDrawer: () => void;
  shareHistory: THAQShareHistoryTableProps[] | undefined;
};

const Header = ({
  HAQ,
  HAQResponse,
  onCloseDrawer,
  shareHistory,
}: THAQDrawerHeaderProps) => {
  const { t } = useTranslation(["HAQ", "common", "statuses", "asset"]);
  const { project } = useProject();

  const {
    permissions: { canViewQuestionAndResponse, hasInterestedPHAs },
  } = usePermissions(project?.id ?? "");

  const { organizations } = useOrganizations();

  const HAQName = useMemo(() => {
    if (!HAQ) {
      return "";
    }

    return getHAQShortName(HAQ, t("HAQAcronym", { ns: "HAQ" }), organizations);
  }, [HAQ, organizations, t]);

  const interestedPHAs = useMemo(() => {
    return HAQ && organizations
      ? getFormattedInterestedPHAs(HAQ, organizations)
      : [];
  }, [HAQ, organizations]);

  const HAQPath = Path.ProjectHAQ.replace(
    ":projectId",
    project?.id ?? "",
  ).replace(":HAQId", HAQ?.id ?? "");

  return (
    <Box>
      <Button
        variant="text"
        startIcon={<CloseIcon />}
        onClick={onCloseDrawer}
        aria-label={t("button.close", { ns: "common" })}
        data-qaid="haq-drawer-close-button"
      >
        {t("button.close", { ns: "common" })}
      </Button>

      <Box display="flex" alignItems="center" paddingBottom={2} paddingTop={4}>
        <Typography
          variant="h6"
          paddingRight={2}
          data-qaid="haq-drawer-haq-name"
        >
          {HAQName}
        </Typography>
      </Box>

      <Box>
        <Box marginBottom={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2" sx={styles.label}>
                {t("detailsPreview.header.questionStatus")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                data-qaid="haq-drawer-haq-status-value"
              >
                {getDisplayedAssetStatus(
                  HAQ?.source?.tenantType,
                  HAQ?.status,
                  t,
                )}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body2" sx={styles.label}>
                {t("detailsPreview.header.responseStatus")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                data-qaid="haq-drawer-response-status-value"
              >
                {getDisplayedAssetStatus(
                  HAQResponse?.source?.tenantType,
                  HAQResponse?.status,
                  t,
                )}
              </Typography>
            </Grid>

            {hasInterestedPHAs && (
              <>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={styles.label}>
                    {t("detailsPreview.header.registeredInterest")}
                  </Typography>
                </Grid>
                <Grid item xs={8} display="flex">
                  {interestedPHAs?.map((pHAName, index) => (
                    <Typography variant="body2" key={pHAName}>
                      {`${index ? ", " : ""}${pHAName}`}
                    </Typography>
                  ))}
                </Grid>
              </>
            )}
          </Grid>
        </Box>
        <Box>
          <Stack gap={1} direction="row" flexWrap="wrap" paddingTop={1}>
            <Button
              data-qaid="haq-drawer-open-button"
              component={Link}
              variant="contained"
              disabled={!canViewQuestionAndResponse}
              to={HAQPath}
            >
              {t("detailsPreview.header.open")}
            </Button>
            <HAQContextProvider>
              {HAQ && (
                <HAQActions
                  HAQName={HAQName}
                  HAQId={HAQ.id}
                  sx={{ height: 36 }}
                  onDeleteHAQ={onCloseDrawer}
                />
              )}
            </HAQContextProvider>
            <HAQShareHistory shareHistory={shareHistory} HAQName={HAQName} />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
