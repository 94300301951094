import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Lock } from "@mui/icons-material";
import { TUserData } from "models/users.models";
import ProfileCard from "screens/Profile/ProfileCard";

type TSignInAndSecurityProps = {
  user: TUserData;
};

const SignInAndSecurity = ({ user }: TSignInAndSecurityProps) => {
  const { t } = useTranslation("user", {
    keyPrefix: "profileSignInAndSecurity",
  });

  const fields = useMemo(() => {
    const { email } = user;

    return [
      {
        id: "0",
        label: t("fields.email"),
        value: email,
      },
    ];
  }, [user, t]);

  return (
    <ProfileCard
      avatar={<Lock />}
      title={t("profileCardTitle")}
      fields={fields}
    />
  );
};

export default SignInAndSecurity;
