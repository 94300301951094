import { workflowBasePart } from "./constants";

export default {
  start: (id: string) =>
    `${workflowBasePart}/workflows/${id}/workflow-executions`,
  getWorkflowExecution: (id: string) =>
    `${workflowBasePart}/workflow-executions/${id}`,
  createProjectTask: () =>
    `${workflowBasePart}/workflows/project-task-create/workflow-executions`,
  events: (id: string) => `${workflowBasePart}/workflows/${id}/events`,
  cancelWorkflowTasks: (workflowExecutionId: string) =>
    `${workflowBasePart}/workflow-executions/${workflowExecutionId}/cancellation-request`,
};
