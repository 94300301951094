import { useEffect } from "react";
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { SxProps, Theme } from "@mui/material";
import { DatePickerProps as MUIDatePickerProps } from "@mui/x-date-pickers/DatePicker/DatePicker";
import DatePicker from "components/shared/DatePicker";

export type TFormDateProps<FormType extends FieldValues> = Omit<
  MUIDatePickerProps<Date, Date>,
  "onChange" | "renderInput" | "value"
> & {
  name: FieldPath<FormType>;
  label: string;
  sx?: SxProps<Theme>;
  onValueChange?: (key: string, value: Date | null) => void;
  unregisterOnUnmount?: boolean;
};

const FormDate = <FormType extends FieldValues>({
  name,
  onValueChange,
  unregisterOnUnmount = true,
  ...props
}: TFormDateProps<FormType>) => {
  const { control, unregister } = useFormContext<FormType>();

  useEffect(() => {
    if (unregisterOnUnmount) {
      return () => unregister(name);
    }
  }, [unregister, name, unregisterOnUnmount]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          {...props}
          size="small"
          error={!!error}
          helperText={error?.message}
          onChange={(val) => {
            field.onChange(val);
            onValueChange?.(name, val);
          }}
        />
      )}
    />
  );
};

export default FormDate;
