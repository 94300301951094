import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import { useProject } from "context/ProjectContext";
import useStartWorkflow from "hooks/api/REST/workflow/useStartWorkflow";
import { useHAQ } from "hooks/haq";
import { useUserRoles } from "hooks/user";
import useUser from "hooks/useUser";
import { IHAQ } from "models/HAQ.models";
import { useHAQContext } from "screens/Project/screens/HAQ/HAQContext/HAQContextProvider";
import { HAQAction, HAQStatus } from "utils/constants/HAQ.constants";
import { WorkflowId } from "utils/constants/workflows.constants";
import {
  getDisplayedAssetStatus,
  getHAQStatusChangeOptions,
  getIsEligibleToManipulateAsset,
} from "utils/helpers";
import { TManualHAQStatusOptions } from "utils/helpers/HAQ/getHAQStatusChangeOptions/getHAQStatusChangeOptions";
import { isHAUser } from "utils/user/organization";

type TUseHAQStatusChangeOptions = {
  HAQId: string;
  workflowInProgressId: string | null;
  setWorkflowInProgressId: (id: string) => void;
  isHAQCopied: boolean;
  isHAQRCopied: boolean;
};

const useHAQStatusChangeOptions = ({
  HAQId,
  workflowInProgressId,
  setWorkflowInProgressId,
  isHAQCopied,
  isHAQRCopied,
}: TUseHAQStatusChangeOptions) => {
  const { t } = useTranslation(["HAQ", "statuses", "common", "notifications"]);
  const { project } = useProject();

  const { user, organization } = useUser();
  const { isProjectManager } = useUserRoles(project?.id);

  const { startWorkflow } = useStartWorkflow({
    failureMessage: t("workflowMessages.HAQStatusChangeErrorMessage", {
      ns: "notifications",
    }),
  });

  const { setIsAnyWorkflowInProgress } = useHAQContext();
  const { HAQ, HAQContent, HAQResponse, HAQResponseContent } = useHAQ(HAQId);

  const hasHAQContent = Boolean(HAQContent?.content);
  const hasHAQResponseContent = Boolean(HAQResponseContent?.content);

  const [statusChangeActionQueued, setStatusChangeActionQueued] =
    useState<HAQStatus | null>(null);

  const [assetsPendingStatusChange, setAssetsPendingStatusChange] = useState<
    IHAQ[] | null
  >(null);

  const targetAssets = [HAQ, HAQResponse].filter((asset): asset is IHAQ =>
    getIsEligibleToManipulateAsset(asset, organization),
  );

  const initiateWorkflow = useCallback(
    (assets: IHAQ[], status: HAQStatus) => {
      if (workflowInProgressId) {
        return;
      }

      if (user?.id && organization && !isEmpty(assets) && project?.id) {
        startWorkflow(
          {
            id: isHAUser(organization)
              ? WorkflowId.HAAssetManualStatusChange
              : WorkflowId.SponsorAssetManualStatusChange,
            payload: {
              project: project.id,
              originator: user.id,
              assets: assets.map((asset) => asset?.id),
              status,
            },
          },
          {
            onSuccess: (response) => {
              setWorkflowInProgressId(response?.data?.id);
              setIsAnyWorkflowInProgress(!!response?.data?.id);
              resetManualStatusChange();
            },
          },
        );
      }
    },
    [
      startWorkflow,
      user?.id,
      organization,
      workflowInProgressId,
      setWorkflowInProgressId,
      setIsAnyWorkflowInProgress,
      project?.id,
    ],
  );

  const onQueueManualStatusChange = (assets: IHAQ[], type: HAQStatus) => {
    setAssetsPendingStatusChange(assets);
    setStatusChangeActionQueued(type);
  };

  const resetManualStatusChange = () => {
    setStatusChangeActionQueued(null);
    setAssetsPendingStatusChange(null);
  };

  const onExecuteManualStatusChange = (shouldExecute: boolean) => {
    if (!shouldExecute) {
      resetManualStatusChange();

      return;
    }

    if (assetsPendingStatusChange && statusChangeActionQueued) {
      initiateWorkflow(assetsPendingStatusChange, statusChangeActionQueued);
    }
  };

  const possibleStatusChangeOptions = getHAQStatusChangeOptions({
    HAQ,
    HAQResponse,
    organization,
    isProjectManager,
    hasHAQContent,
    hasHAQResponseContent,
    isHAQCopied,
    isHAQRCopied,
  });

  const statusChangeMenuItems = useMemo(
    () => [
      {
        id: HAQAction.SetToDraft,
        status: HAQStatus.Draft,
        label: getDisplayedAssetStatus(organization?.type, HAQStatus.Draft, t),
        onClick: () => onQueueManualStatusChange(targetAssets, HAQStatus.Draft),
      },
      {
        id: HAQAction.SetToFinal,
        status: HAQStatus.Final,
        label: getDisplayedAssetStatus(organization?.type, HAQStatus.Final, t),
        onClick: () => onQueueManualStatusChange(targetAssets, HAQStatus.Final),
      },
    ],
    [targetAssets, organization?.type, t],
  );

  return {
    statusChangeMenuItems: statusChangeMenuItems.filter(({ status }) =>
      possibleStatusChangeOptions.includes(status as TManualHAQStatusOptions),
    ),
    statusChangeActionQueued,
    onExecuteManualStatusChange,
  };
};

export default useHAQStatusChangeOptions;
