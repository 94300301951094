import { createSvgIcon } from "@mui/material";

export const PlusIcon = createSvgIcon(
  <svg fill="none">
    <path
      d="M12 5V19M5 12H19"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "PlusIcon",
);
