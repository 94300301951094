import { TStyles } from "utils/Styles";

const styles: TStyles = {
  submitButton: {
    minWidth: "75px",
    minHeight: "36px",
  },

  selectedFile: {
    margin: "0 0 10px 0",
  },

  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
  },
};

export default styles;
