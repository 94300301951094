import { TFunction } from "i18next";
import { DocumentSubtype } from "utils/constants/doc.constants";

export const getTeaserTitleAndDescription = (
  documentType: DocumentSubtype,
  t: TFunction,
) =>
  documentType === DocumentSubtype.ECTD
    ? {
        title: t("subtypeOptions.PROJECT_DOSSIER"),
        description: t("documents.teaser.PROJECT_DOSSIER.description"),
      }
    : {
        title: t(`subtypeOptions.${documentType}`),
        description: t(`documents.teaser.${documentType}.description`),
      };
