import { useTranslation } from "react-i18next";
import { Box, Stack, Typography } from "@mui/material";
import Button from "components/shared/Button";
import { RoleScope } from "hooks/api/GQL/admin/useUsers.models";
import useUserData from "hooks/api/GQL/user/useUserData";
import useUser from "hooks/useUser";
import PersonalInfo from "screens/Profile/PersonalInfo";
import Settings from "screens/Profile/Settings";
import { signOut } from "utils/auth";
import SignInAndSecurity from "./SignInAndSecurity";
import styles from "./Profile.styles";

const Profile = () => {
  const { user, organization } = useUser();

  const { data: userData } = useUserData(user?.id, RoleScope.System);

  const { t } = useTranslation("user", {
    keyPrefix: "profileProfile",
  });

  const handleLogout = () => {
    signOut({
      shouldRestorePath: false,
      shouldLogoutEverywhere: true,
    }).catch(console.error);
  };

  return (
    <Stack gap={0} sx={styles.container}>
      <Typography variant="h5" component="h1">
        {t("settings")}
      </Typography>
      <Stack sx={styles.subContainer} direction="row" spacing={13.875}>
        <Stack sx={styles.stack} spacing={6} direction="column">
          {user && (
            <>
              <PersonalInfo
                user={user}
                organization={organization}
                systemRoles={userData?.roles}
              />
              <SignInAndSecurity user={user} />
              <Settings preferences={user.preferences} />
            </>
          )}
        </Stack>
        <Box sx={styles.buttonContainer}>
          <Button
            data-qaid="sign-out-button"
            aria-label={t("signOutButtonAriaLabel")}
            variant="outlined"
            size="large"
            sx={styles.signOutButton}
            onClick={handleLogout}
          >
            {t("signOutButton")}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Profile;
