import { useFormContext } from "react-hook-form";
import { ImportModalType } from "screens/Project/sections/Documents/modals/ImportDocument";
import { ImportFormBaseFieldName } from "screens/Project/sections/Documents/modals/ImportDocument";
import { TImportFormBase } from "screens/Project/sections/Documents/modals/ImportDocument/ImportDocument.types";
import { DocumentSubtype } from "utils/constants/doc.constants";
import AAIDAdditionalFields from "./AAIDAdditionalFields";
import ReferencesAdditionalFields from "./References";

type TAdditionalFieldsProps = {
  openModalType: ImportModalType;
};

const AdditionalFields = (props: TAdditionalFieldsProps) => {
  const { watch } = useFormContext<TImportFormBase>();

  const subtype = watch(ImportFormBaseFieldName.Subtype);

  if (subtype === DocumentSubtype.AssessmentAidSource) {
    return <AAIDAdditionalFields />;
  }

  if (subtype === DocumentSubtype.References) {
    return <ReferencesAdditionalFields openModalType={props.openModalType} />;
  }

  return null;
};

export default AdditionalFields;
