import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import CheckboxGroup from "components/shared/CheckboxGroup";
import {
  TCheckboxGroupOption,
  TCheckboxGroupProps,
} from "components/shared/CheckboxGroup/types";

export type TFormCheckboxGroupProps<
  FormType extends FieldValues,
  T extends TCheckboxGroupOption,
> = TCheckboxGroupProps<T> & {
  name: FieldPath<FormType>;
};

const FormCheckboxGroup = <
  FormType extends FieldValues,
  T extends TCheckboxGroupOption,
>({
  name,
  ...props
}: TFormCheckboxGroupProps<FormType, T>) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      key={`checkbox-group-${name}`}
      name={name}
      render={({ field }) => <CheckboxGroup {...field} {...props} />}
    />
  );
};

export default FormCheckboxGroup;
