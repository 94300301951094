import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    p: (theme) => theme.spacing(2.5, 4, 2.5, 4),
  },

  tabContent: {
    mt: 4.5,
  },

  linkedOrgAvatar: {
    ml: "10px",
    top: "-4px",
  },
};

export default styles;
