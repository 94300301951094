import { AssetType, THAQAssetType } from "utils/constants/assets.constants";
import { ProjectType } from "utils/constants/project.constants";
import { WorkflowId } from "utils/constants/workflows.constants";

export type TProjectConfiguration = {
  canBeCreatedByHAUsers: boolean;
  canSeeShareHistory: boolean;
  canDoDocumentBulkActions: boolean;
  canSubmitDocument: boolean;
  hasInterestedPHAs: boolean;
  canViewMemberGroupsAccess: boolean;
  hasReferenceHAOrganization: boolean;
  hasParticipatingHAOrganizations: boolean;
  shouldUseDocumentSubtype: boolean;
};

export type TProjectConfigurationByProjectType = Partial<
  Record<ProjectType, TProjectConfiguration>
>;

export const projectConfigurations: TProjectConfigurationByProjectType = {
  [ProjectType.Reliance]: {
    canBeCreatedByHAUsers: false,
    canSeeShareHistory: false,
    canDoDocumentBulkActions: true,
    canSubmitDocument: true,
    hasInterestedPHAs: false,
    canViewMemberGroupsAccess: false,
    hasReferenceHAOrganization: true,
    hasParticipatingHAOrganizations: false,
    shouldUseDocumentSubtype: true,
  },
  [ProjectType.Orbis]: {
    canBeCreatedByHAUsers: true,
    canSeeShareHistory: true,
    canDoDocumentBulkActions: false,
    canSubmitDocument: false,
    hasInterestedPHAs: true,
    canViewMemberGroupsAccess: true,
    hasReferenceHAOrganization: true,
    hasParticipatingHAOrganizations: true,
    shouldUseDocumentSubtype: false,
  },
  [ProjectType.RegulatoryReview]: {
    canBeCreatedByHAUsers: false,
    canSeeShareHistory: false,
    canDoDocumentBulkActions: true,
    canSubmitDocument: true,
    hasInterestedPHAs: false,
    canViewMemberGroupsAccess: false,
    hasReferenceHAOrganization: false,
    hasParticipatingHAOrganizations: false,
    shouldUseDocumentSubtype: true,
  },
};

const projectConfigurationFallback: {
  [K in keyof TProjectConfiguration]: false;
} = {
  canBeCreatedByHAUsers: false,
  canSeeShareHistory: false,
  canDoDocumentBulkActions: false,
  canSubmitDocument: false,
  hasInterestedPHAs: false,
  canViewMemberGroupsAccess: false,
  hasReferenceHAOrganization: false,
  hasParticipatingHAOrganizations: false,
  shouldUseDocumentSubtype: false,
};

type TProjectConfigurationKeys = keyof typeof projectConfigurations;

function isProjectTypeExistInConfiguration(
  projectType: ProjectType | undefined | null,
): projectType is TProjectConfigurationKeys {
  return (
    projectType !== undefined &&
    projectType !== null &&
    projectType !== ProjectType.Labeling
  );
}

function isProjectConfiguration(
  configuration: TProjectConfiguration | undefined,
): configuration is TProjectConfiguration {
  return configuration !== undefined;
}

export const deriveProjectConfiguration = (
  projectType: ProjectType | undefined,
) => {
  if (isProjectTypeExistInConfiguration(projectType)) {
    const projectConfiguration = projectConfigurations[projectType];

    return isProjectConfiguration(projectConfiguration)
      ? projectConfiguration
      : projectConfigurationFallback;
  } else {
    return projectConfigurationFallback;
  }
};

const projectConfigurationHAQWorkflow = {
  [ProjectType.Reliance]: {
    [AssetType.Question]: WorkflowId.RelianceSubmitImportedHAQ,
    [AssetType.QuestionResponse]: WorkflowId.RelianceSubmitHAQResponse,
  },
  [ProjectType.RegulatoryReview]: {
    [AssetType.Question]: WorkflowId.RelianceSubmitImportedHAQ,
    [AssetType.QuestionResponse]: WorkflowId.RelianceSubmitHAQResponse,
  },
  [ProjectType.Orbis]: {
    [AssetType.Question]: WorkflowId.OrbisSubmitHAQResponse,
    [AssetType.QuestionResponse]: WorkflowId.OrbisSubmitHAQResponse,
  },
};

type TProjectConfigurationHAQWorkflowKeys =
  keyof typeof projectConfigurationHAQWorkflow;

export const deriveHAQWorkFlowId = (
  projectType: ProjectType | undefined,
  HAQType: THAQAssetType | undefined,
) =>
  projectConfigurationHAQWorkflow[
    projectType as TProjectConfigurationHAQWorkflowKeys
  ]?.[HAQType as THAQAssetType];
