import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import { FormTextInput } from "components/common/form-fields";
import ButtonLink from "components/shared/ButtonLink";
import {
  DocumentsField,
  RecipientsField,
} from "screens/Project/sections/Documents/modals/fields";
import {
  SubmitDocumentFieldName,
  TSubmitDocumentForm,
} from "screens/Project/sections/Documents/modals/SubmitDocuments/SubmitDocuments.types";
import styles from "./fieldsLayout.styles";

type TFieldsLayoutProps = {
  setIsAddDocsView: (event?: SyntheticEvent) => void;
  recipientHelperText?: string;
};

const FieldsLayout = ({
  setIsAddDocsView,
  recipientHelperText,
}: TFieldsLayoutProps) => {
  const { t } = useTranslation("documents", {
    keyPrefix: "documents",
  });

  return (
    <Box>
      <Stack gap={2} justifyContent="flex-start">
        <DocumentsField fieldName={SubmitDocumentFieldName.Documents} />
        <ButtonLink onClick={setIsAddDocsView} sx={styles.addDocumentsButton}>
          {t("commonFields.documents.addDocument")}
        </ButtonLink>
        <RecipientsField
          fieldName={SubmitDocumentFieldName.Recipients}
          label={t(
            `submitDocuments.fields.${SubmitDocumentFieldName.Recipients}.label`,
          )}
          helperText={recipientHelperText}
        />

        <FormTextInput<TSubmitDocumentForm>
          data-qaid="submit-copy-documents-message-text-field"
          name={SubmitDocumentFieldName.Message}
          multiline
          rows={3}
          label={t(
            `submitDocuments.fields.${SubmitDocumentFieldName.Message}.label`,
          )}
        />
      </Stack>
    </Box>
  );
};

export default FieldsLayout;
