import { useMemo } from "react";
import {
  Check as CheckIcon,
  ErrorOutline as ErrorOutlineIcon,
  InfoOutlined as InfoOutlinedIcon,
  WarningAmber as WarningAmberIcon,
} from "@mui/icons-material";
import {
  Alert,
  AlertProps,
  Box,
  Snackbar,
  SnackbarProps,
  Typography,
} from "@mui/material";
import styles from "./ToastNotification.styles";

export type TToastNotificationProps = SnackbarProps & {
  onClose: () => void;
  severity?: AlertProps["severity"];
  title?: string;
  icon?: AlertProps["icon"];
};

const getToastNotificationIcon = (severity: AlertProps["severity"]) => {
  if (severity === "error") {
    return (
      <Box sx={styles.errorIconBox}>
        <ErrorOutlineIcon fontSize="inherit" />
      </Box>
    );
  }

  if (severity === "warning") {
    return (
      <Box sx={styles.warningIconBox}>
        <WarningAmberIcon fontSize="inherit" />
      </Box>
    );
  }

  if (severity === "info") {
    return (
      <Box sx={styles.infoIconBox}>
        <InfoOutlinedIcon fontSize="inherit" />
      </Box>
    );
  }

  if (severity === "success") {
    return (
      <Box sx={styles.successIconBox}>
        <CheckIcon fontSize="inherit" />
      </Box>
    );
  }
};

const ToastNotification = (props: TToastNotificationProps) => {
  const notificationIcon = useMemo(
    () => getToastNotificationIcon(props.severity),
    [props.severity],
  );

  return (
    <Snackbar
      {...props}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        icon={props.icon || notificationIcon}
        action={props.action}
        onClose={props.onClose}
        sx={styles.alert}
      >
        {props.title && (
          <Typography noWrap sx={styles.title}>
            {props.title}
          </Typography>
        )}
        <Typography sx={props.title ? styles.messageWithTitle : styles.title}>
          {props.message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default ToastNotification;
