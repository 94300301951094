import { z, ZodDate, ZodEffects, ZodNullable, ZodOptional } from "zod";
import { FilterType } from "components/shared/types/filter";

export enum DateRangeKey {
  "StartDate" = "startDate",
  "EndDate" = "endDate",
}

export type TDateRangeFilterSchema = Record<
  DateRangeKey,
  { label: string; filterKey: string; type?: FilterType }
>;

export const dateRangeSchema = (
  startDate: string,
  endDate: string,
  invalidStartDateText: string,
  invalidEndDateText: string,
  genericDateConstraint: ZodOptional<
    ZodNullable<ZodEffects<ZodDate, Date, Date>>
  >,
) =>
  z
    .object({
      [startDate]: genericDateConstraint,
      [endDate]: genericDateConstraint,
    })
    .superRefine((data, ctx) => {
      const start = data[startDate];
      const end = data[endDate];

      if (start && end) {
        if (start > end) {
          ctx.addIssue({
            path: [startDate],
            message: invalidStartDateText,
            code: z.ZodIssueCode.custom,
          });
          ctx.addIssue({
            path: [endDate],
            message: invalidEndDateText,
            code: z.ZodIssueCode.custom,
          });
        }
      }
    });
