import useOrganizations from "hooks/api/REST/organizations/useOrganizations";
import { IOrganization } from "models/organizations.models";
import { getParsedARN } from "utils/helpers";

const useARNTenant = (ARN?: string): { tenant?: IOrganization } => {
  const parsedARN = getParsedARN(ARN);

  const { organizations } = useOrganizations(
    { tenantIds: [parsedARN?.tenantId ?? ""] },
    Boolean(parsedARN?.tenantId),
  );

  return { tenant: organizations?.content[0] };
};

export default useARNTenant;
