import dayjs from "dayjs";
import { getRelativeTimeArray } from "utils/helpers/time";

const getRemainingUploadTime = ({
  startDate,
  totalBytes,
  loadedBytes,
  currentLanguage,
}: {
  startDate: Date;
  totalBytes: number;
  loadedBytes: number;
  currentLanguage: string;
}) => {
  if (loadedBytes <= 0 || dayjs(startDate).isAfter(dayjs())) {
    return undefined;
  }

  const remainingBytes = totalBytes - loadedBytes;
  const elapsedTimeInSeconds = dayjs().diff(dayjs(startDate), "s");

  const averageUploadSpeed = Math.round(loadedBytes / elapsedTimeInSeconds);

  const remainingTimeInSeconds = Math.round(
    remainingBytes / averageUploadSpeed,
  );

  const estimatedUploadEndDate = dayjs().add(remainingTimeInSeconds, "s");

  const relativeTimeArray = getRelativeTimeArray(
    estimatedUploadEndDate.toDate(),
    currentLanguage,
  );

  return relativeTimeArray
    .slice(1)
    .map((part) => part.value)
    .join("");
};

export default getRemainingUploadTime;
