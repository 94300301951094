import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { gql, Variables } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { IOrganizationTypeListResponse } from "./useOrganizationTypeList.models";

export const query = gql`
  query OrganizationType {
    organizationType {
      id
      name
      code
    }
  }
`;

const useOrganizationTypeList = (enabled: boolean = true) => {
  const { t } = useTranslation("administration");
  const { showAlert } = useAlerts();

  const { data, isLoading, isSuccess } =
    useQuery<IOrganizationTypeListResponse>(
      [QueryAPIKey.GetOrganizationTypeList],
      () =>
        services.graphQL
          .request<IOrganizationTypeListResponse, Variables>(query)
          .catch((error) => {
            showAlert({
              severity: AlertSeverity.Error,
              message: t("notifications.getOrganizationTypeFailure"),
            });
            throw error;
          }),
      {
        enabled,
      },
    );

  return {
    data: data?.organizationType ?? [],
    isLoading,
    isSuccess,
  };
};

export default useOrganizationTypeList;
