import { Link } from "react-router-dom";
import { TFunction } from "i18next";
import { IDocument } from "models/documents.models";
import { IOrganization } from "models/organizations.models";
import { TCountryCode } from "utils/constants/countries.constants";
import {
  EMPTY_LABEL,
  formatDate,
  getCountryName,
  getDisplayedAssetStatus,
  getProjectPath,
  getTextValue,
  includeInArray,
} from "utils/helpers";
import { isHAUser, isSponsorUser } from "utils/user/organization";
import { isEmail } from "utils/validators/email.validator";

const getCreator = (
  createdBy: string | undefined,
  countryCode: TCountryCode | undefined,
  t: TFunction,
) => {
  if (isEmail(createdBy)) {
    return createdBy;
  } else {
    return countryCode ? getCountryName(countryCode, t) : EMPTY_LABEL;
  }
};

export type TAdditionalInformation = {
  projectName: string | undefined;
  genericName: string | undefined;
  regulatoryEvent: string | undefined;
  productFamily: string | undefined;
  authoringOrganization: IOrganization | undefined;
  organization?: IOrganization;
};

const getDefaultDocumentInformationStructure = (
  document: IDocument | null,
  additionalInformation: TAdditionalInformation,
  t: TFunction,
) =>
  [
    {
      key: "status",
      label: t("documentInformation.status"),
      value: getDisplayedAssetStatus(
        document?.source?.tenantType,
        document?.status,
        t,
      ),
      separator: true,
    },
    {
      key: "modifyDate",
      label: t("documentInformation.modifyDate"),
      value: formatDate(document?.lastModifiedDate),
      separator: false,
    },
    {
      key: "versionLabel",
      label: t("documentInformation.versionLabel"),
      value: EMPTY_LABEL, // TODO must be connected as part of the task FTE-6185
      separator: false,
    },
    {
      key: "versionNumber",
      label: t("documentInformation.versionNumber"),
      value: EMPTY_LABEL, // TODO must be connected as part of the task FTE-6185
      separator: false,
    },
    {
      key: "fileType",
      label: t("documentInformation.fileType"),
      value: getTextValue(document?.metadata?.fileType),
      separator: false,
    },
    {
      key: "uniqueContentId",
      label: t("documentInformation.uniqueContentId"),
      value: getTextValue(document?.id),
      separator: false,
    },
    {
      key: "litigationHold",
      label: t("documentInformation.litigationHold"),
      value: getTextValue(String(document?.metadata?.litigationHold)),
      separator: false,
    },
    {
      key: "language",
      label: t("documentInformation.language"),
      value: getTextValue(document?.metadata?.language),
      separator: false,
    },
    {
      key: "submissionDate",
      label: t("documentInformation.submissionDate"),
      value: formatDate(document?.metadata?.submissionDate),
      separator: false,
    },
    {
      key: "contentName",
      label: t("documentInformation.contentName"),
      value: getTextValue(document?.metadata?.fileName || document?.name),
      separator: true,
    },
    {
      key: "author",
      label: t("documentInformation.author"),
      value: getTextValue(document?.metadata?.author),
      separator: false,
    },
    {
      key: "creationDate",
      label: t("documentInformation.creationDate"),
      value: formatDate(document?.createdDate),
      separator: false,
    },
    {
      key: "creator",
      label: t("documentInformation.creator"),
      value: getCreator(
        document?.createdBy,
        additionalInformation.authoringOrganization?.country,
        t,
      ),
      separator: false,
    },
    {
      key: "lockOwner",
      label: t("documentInformation.lockOwner"),
      value: EMPTY_LABEL, // TODO must be connected as part of the task FTE-6185
      separator: false,
    },
    {
      key: "lockDate",
      label: t("documentInformation.lockDate"),
      value: EMPTY_LABEL, // TODO must be connected as part of the task FTE-6185
      separator: false,
    },
    {
      key: "domain",
      label: t("documentInformation.domain"),
      value: getTextValue(document?.metadata?.domain).toLowerCase(),
      separator: true,
    },
    {
      key: "type",
      label: t("documentInformation.type"),
      value: getTextValue(document?.metadata?.group).toLowerCase(),
      separator: false,
    },
    {
      key: "subtype",
      label: t("documentInformation.subtype"),
      value: t(`subtypeOptions.${document?.type}`),
      separator: false,
    },
    {
      key: "projectId",
      label: t("documentInformation.projectId"),
      value: (
        <Link to={getProjectPath(document?.projectIds?.[0])}>
          {getTextValue(additionalInformation.projectName)}
        </Link>
      ),
      separator: false,
    },
    {
      key: "genericName",
      label: t("documentInformation.genericName"),
      value: getTextValue(additionalInformation.genericName),
      separator: false,
    },
    {
      key: "regulatorEvent",
      label: t("documentInformation.regulatorEvent"),
      value: getTextValue(additionalInformation.regulatoryEvent),
      separator: false,
    },
    {
      key: "productFamily",
      label: t("documentInformation.productFamily"),
      value: getTextValue(additionalInformation.productFamily),
      separator: false,
    },
  ] as const;

const getRelianceDocumentInformationStructure = (
  document: IDocument | null,
  additionalInformation: TAdditionalInformation,
  t: TFunction,
) =>
  [
    {
      key: "contentName",
      label: t("documentInformation.contentName"),
      value: getTextValue(document?.name || document?.metadata?.fileName),
      separator: true,
    },
    {
      key: "creationDate",
      label: t("documentInformation.creationDate"),
      value: formatDate(document?.createdDate),
      separator: false,
    },
    {
      key: "fileType",
      label: t("documentInformation.fileType"),
      value: getTextValue(document?.metadata?.fileType),
      separator: false,
    },
    {
      key: "status",
      label: t("documentInformation.status"),
      value: getDisplayedAssetStatus(
        document?.source?.tenantType,
        document?.status,
        t,
      ),
      separator: false,
    },
    {
      key: "modifyDate",
      label: t("documentInformation.modifyDate"),
      value: formatDate(document?.lastModifiedDate),
      separator: false,
    },
    ...includeInArray(
      {
        key: "modifiedBy",
        label: t("documentInformation.modifiedBy"),
        value: getTextValue(document?.lastModifiedBy).toLowerCase(),
        separator: false,
      },
      isSponsorUser(additionalInformation?.organization),
    ),
    {
      key: "submissionDate",
      label: t("documentInformation.submissionDate"),
      value: formatDate(document?.metadata?.submissionDate),
      separator: false,
    },
    {
      key: "projectId",
      label: t("documentInformation.projectId"),
      value: getTextValue(document?.projectIds?.[0]),
      separator: false,
    },
    {
      key: "genericName",
      label: t("documentInformation.genericName"),
      value: getTextValue(additionalInformation.genericName),
      separator: false,
    },
    ...includeInArray(
      {
        key: "creator",
        label: t("documentInformation.creator"),
        value: getCreator(
          document?.createdBy,
          additionalInformation.authoringOrganization?.country,
          t,
        ),
        separator: false,
      },
      isSponsorUser(additionalInformation?.organization),
    ),
    ...includeInArray(
      {
        key: "creator",
        label: t("documentInformation.creator"),
        value: getTextValue(additionalInformation.authoringOrganization?.name),
        separator: false,
      },
      isHAUser(additionalInformation?.organization),
    ),
    {
      key: "uniqueContentId",
      label: t("documentInformation.uniqueContentId"),
      value: getTextValue(document?.id),
      separator: false,
    },
    {
      key: "versionNumber",
      label: t("documentInformation.versionNumber"),
      value: getTextValue(document?.latestVersion?.version),
      separator: false,
    },
    {
      key: "litigationHold",
      label: t("documentInformation.litigationHold"),
      value: getTextValue(String(document?.metadata?.litigationHold)),
      separator: false,
    },
    {
      key: "language",
      label: t("documentInformation.language"),
      value: getTextValue(document?.metadata?.language),
      separator: false,
    },
    ...includeInArray(
      {
        key: "author",
        label: t("documentInformation.author"),
        value: getTextValue(document?.metadata?.author),
        separator: false,
      },
      isSponsorUser(additionalInformation?.organization),
    ),
    {
      key: "domain",
      label: t("documentInformation.domain"),
      value: getTextValue(document?.metadata?.domain).toLowerCase(),
      separator: false,
    },
    {
      key: "type",
      label: t("documentInformation.type"),
      value: getTextValue(document?.metadata?.group).toLowerCase(),
      separator: false,
    },
    {
      key: "subtype",
      label: t("documentInformation.subtype"),
      value: t(`subtypeOptions.${document?.type}`),
      separator: false,
    },
  ] as const;

export {
  getDefaultDocumentInformationStructure,
  getRelianceDocumentInformationStructure,
};
