import { TFunction } from "i18next";
import { z } from "zod";
import { EntityStatus } from "models/entities.models";
import { formatValuesToOptions } from "utils/helpers";
import { ProductFamilyEntityFieldName } from "./CreateProductFamilyModal.types";

export const getCreateProductFamilyModalSchema = (t: TFunction) =>
  z.object({
    [ProductFamilyEntityFieldName.Name]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductFamilyEntityFieldName.Description]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductFamilyEntityFieldName.Status]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
  });

export const getStatusFieldOptions = (t: TFunction) =>
  formatValuesToOptions(
    [EntityStatus.Active, EntityStatus.Inactive],
    (status) => t(`${status}`),
    (status) => status,
  );
