import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { flatMap } from "lodash";
import uniqBy from "lodash/uniqBy";
import {
  FilterType,
  TSelectFilter,
  TSelectTypeaheadFilter,
} from "components/shared/types/filter";
import { TLinkedOrganizationEntity } from "screens/EntityManagement/components/Organization/Organization.types";
import { TProductFamily } from "screens/EntityManagement/components/ProductFamily/ProductFamily.types";
import { TSubstance } from "screens/EntityManagement/components/Substances/Substances.types";
import { TEntityProduct } from "../Products.types";
import { ProductsTable } from "./ProductsTable.types";

type TUseProductsFilters = {
  products: TEntityProduct[] | undefined;
};

export const useProductsFilters = ({
  products,
}: TUseProductsFilters): Record<
  string,
  TSelectFilter | TSelectTypeaheadFilter
> => {
  const { t } = useTranslation(["administration", "common", "projects"]);

  return useMemo(
    () => ({
      [ProductsTable.Name]: {
        type: FilterType.Select,
        label: t("productsColumns.name"),
        options:
          uniqBy(
            products?.map((product) => ({
              text: product.tradeName,
              value: product.tradeName,
            })),
            "value",
          ) ?? [],
        selectAllLabel: t("multiSelect.selectAll", { ns: "common" }),
      },
      [ProductsTable.NonProprietaryName]: {
        type: FilterType.Select,
        label: t("productsColumns.nonproprietaryName"),
        options:
          uniqBy(
            products?.map((product) => ({
              text: product.nonproprietaryName,
              value: product.nonproprietaryName,
            })),
            "value",
          ) ?? [],
        selectAllLabel: t("multiSelect.selectAll", { ns: "common" }),
      },
      [ProductsTable.LinkedOrganizations]: {
        type: FilterType.Select,
        label: t("productsColumns.linkedOrganizations"),
        options: products
          ? uniqBy(
              flatMap(products, "linkedOrganizations").map(
                (organization: TLinkedOrganizationEntity) => ({
                  text: organization.name,
                  value: organization.id,
                }),
              ),
              "value",
            )
          : [],
        selectAllLabel: t("multiSelect.selectAll", { ns: "common" }),
      },
      [ProductsTable.ProductFamily]: {
        type: FilterType.Select,
        label: t("productsColumns.linkedProductFamily"),
        options: products
          ? uniqBy(
              flatMap(products, "productFamilies").map(
                (productFamily: TProductFamily) => ({
                  text: productFamily.name,
                  value: productFamily.id,
                }),
              ),
              "value",
            )
          : [],
        selectAllLabel: t("multiSelect.selectAll", { ns: "common" }),
      },
      [ProductsTable.Substance]: {
        type: FilterType.Select,
        label: t("productsColumns.linkedSubstances"),
        options: products
          ? uniqBy(
              flatMap(products, "substances").map((substance: TSubstance) => ({
                text: substance.name,
                value: substance.id,
              })),
              "value",
            )
          : [],
        selectAllLabel: t("multiSelect.selectAll", { ns: "common" }),
      },
      [ProductsTable.Status]: {
        type: FilterType.Select,
        label: t("productsColumns.status"),
        options:
          uniqBy(
            products?.map((product) => ({
              text: t(`${product.status}`),
              value: product.status,
            })),
            "value",
          ) ?? [],
        selectAllLabel: t("multiSelect.selectAll", { ns: "common" }),
      },
    }),

    [t, products],
  );
};
