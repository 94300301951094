import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Box, Stack, Typography } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { canOnlyReadFeatureFlags } from "components/Auth/accessChecks";
import {
  Button,
  ConfirmModal,
  Loading,
  ReactTable,
  TableFilter,
} from "components/shared";
import useCreateFeatureFlag from "hooks/api/GQL/featureFlags/useCreateFeatureFlag";
import useDeleteFeatureFlag from "hooks/api/GQL/featureFlags/useDeleteFeatureFlag";
import useGetFeatureFlags from "hooks/api/GQL/featureFlags/useGetFeatureFlags";
import useUpdateFeatureFlag from "hooks/api/GQL/featureFlags/useUpdateFeatureFlag";
import { IFeatureFlag } from "models/featureFlags.models";
import AddFlag from "./AddFlag";
import getColumns from "./columns";
import EditFlag from "./EditFlag";
import { getFeatureFlagsForRender, getFilterChips } from "./FeatureFlags.utils";
import { TFeatureFlagsTableData } from "./FeatureFlagsTable.types";
import useFeatureFlagFilters from "./useFeatureFlagFilters";
import { PlusIcon } from "assets/icons";
import styles from "./FeatureFlags.styles";

export const FeatureFlags = () => {
  const { authState } = useOktaAuth();

  const { featureFlags, isLoading, isError, isSuccess } = useGetFeatureFlags();

  const deleteFeatureFlag = useDeleteFeatureFlag();
  const createFeatureFlag = useCreateFeatureFlag();
  const updateFeatureFlag = useUpdateFeatureFlag();

  const { t } = useTranslation(["featureFlags", "common", "user"]);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showCreateNewFlagModal, setShowCreateNewFlagModal] = useState(false);
  const [currentFlag, setCurrentFlag] = useState<IFeatureFlag | null>(null);
  const [editFlagModal, setEditFlagModal] = useState(false);

  const isReadOnly = canOnlyReadFeatureFlags({ authState });

  const onDeleteFlag = (currentFlag: IFeatureFlag) => {
    setCurrentFlag(currentFlag);
    setShowConfirmationModal(true);
  };

  const handleConfirmDeleteAction = (confirm: boolean) => {
    confirm && deleteFeatureFlag.mutate({ name: currentFlag?.name ?? "" });
    setShowConfirmationModal(false);
  };

  const onCreateNewFlag = () => setShowCreateNewFlagModal(true);
  const closeCreateNewFlagModal = () => setShowCreateNewFlagModal(false);

  const onEditFlag = (flag: IFeatureFlag) => {
    setEditFlagModal(true);
    setCurrentFlag(flag);
  };

  const closeEditFlagModal = () => setEditFlagModal(false);

  const rowData = useMemo(
    () => getFeatureFlagsForRender(featureFlags),
    [featureFlags],
  );

  const columns = useMemo(
    () => getColumns({ isReadOnly, onDeleteFlag, onEditFlag }, t),
    [isReadOnly, t],
  );

  const {
    setColumnFilters,
    setGlobalFilter,
    ...tableInstance
  }: Table<TFeatureFlagsTableData> = useReactTable({
    columns,
    data: rowData as TFeatureFlagsTableData[],
    enableHiding: false,
    enableColumnFilters: true,
    enableFilters: true,
    enableSorting: true,
    enableGlobalFilter: true,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    globalFilterFn: "includesString",
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const { columnFilters, globalFilter } = tableInstance.getState();

  const { allFilters, updateFilters } = useFeatureFlagFilters(
    setColumnFilters,
    featureFlags,
  );

  return (
    <Box sx={styles.container}>
      <Stack direction="row" sx={styles.header} marginBottom={5}>
        <Typography variant="h5">{t("title")}</Typography>
        {!isReadOnly ? (
          <Button
            startIcon={<PlusIcon />}
            variant="contained"
            onClick={onCreateNewFlag}
            aria-label={t("createNewFlagAriaLabel")}
          >
            {t("createNewFlag")}
          </Button>
        ) : null}
      </Stack>
      {isLoading && <Loading />}
      {isError && <Alert severity="error">{t("isFailedAlertMessage")}</Alert>}
      {isSuccess && !featureFlags?.length && (
        <Typography>{t("noFeatureFlagsMessage")}</Typography>
      )}
      {isSuccess && !!featureFlags?.length && (
        <>
          <TableFilter
            filters={allFilters}
            selectedFilters={getFilterChips(columnFilters)}
            onFiltersChange={updateFilters}
            keywordFilterValue={globalFilter || ""}
            onKeywordChange={setGlobalFilter}
            filterByKeywordLabel={t("keywordFilter.filterByKeyword", {
              ns: "common",
            })}
            showButtonLabel={t("filter.showFilterButton", { ns: "common" })}
            hideButtonLabel={t("filter.hideFilterButton", { ns: "common" })}
            clearAllFiltersLabel={t("filter.clearAllFiltersLabel", {
              ns: "common",
            })}
            errorAdornmentAriaLabel={t("ariaLabels.textFieldError", {
              ns: "common",
            })}
          />
          <ReactTable<TFeatureFlagsTableData>
            sx={styles.table}
            tableInstance={tableInstance}
            isPaginated
          />
        </>
      )}
      {showCreateNewFlagModal && (
        <AddFlag
          onClose={closeCreateNewFlagModal}
          createFlag={createFeatureFlag.mutate}
        />
      )}
      {currentFlag && editFlagModal && (
        <EditFlag
          currentFlag={currentFlag}
          onClose={closeEditFlagModal}
          updateFlag={updateFeatureFlag.mutate}
        />
      )}
      <ConfirmModal
        open={showConfirmationModal}
        title={t("confirmModalTitle")}
        onConclude={handleConfirmDeleteAction}
        confirmButtonText={t("button.confirm", { ns: "common" })}
        cancelButtonText={t("button.cancel", { ns: "common" })}
      />
    </Box>
  );
};

export default FeatureFlags;
