import { TStyles } from "utils/Styles";

const styles: TStyles = {
  characterLimit: {
    margin: "0 0 0 auto",
    paddingLeft: 4,
    whiteSpace: "nowrap",
  },
};

export default styles;
