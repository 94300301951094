import { useState } from "react";
import { Stack } from "@mui/material";
import CreateProductModal from "./CreateProductModal";
import ProductsTable from "./ProductsTable";

export const Products = () => {
  const [shouldShowCreateProductModal, setShouldShowCreateProductModal] =
    useState(false);

  const onClickCreateProduct = () => {
    setShouldShowCreateProductModal(true);
  };

  return (
    <Stack direction="column" spacing={2}>
      <ProductsTable onClickCreateProduct={onClickCreateProduct} />
      <CreateProductModal
        isOpen={shouldShowCreateProductModal}
        onClose={() => setShouldShowCreateProductModal(false)}
      />
    </Stack>
  );
};

export default Products;
