import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { IProjectMember } from "models/projectMembers.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export default function useProjectMembers(projectId: string | undefined) {
  const { t } = useTranslation("projects", {
    keyPrefix: "notifications",
  });

  const { data, status, error, isLoading, isError, isIdle, isSuccess } =
    useQuery<IProjectMember[], Error>(
      [QueryAPIKey.GetProjectMembers, projectId],
      () =>
        services.projectMembers
          .getProjectMembers(projectId ?? "")
          .then((res) => res.data),
      {
        enabled: !!projectId,
        staleTime: Infinity,
      },
    );

  useShowAlert({
    status,
    failureMessage: t("getProjectMembersFailure"),
  });

  return {
    projectMembers: data,
    isLoading,
    isError,
    isIdle,
    isSuccess,
    error,
  };
}
