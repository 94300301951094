import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import uniqBy from "lodash/uniqBy";
import {
  FilterType,
  TFilterOption,
  TSelectFilter,
} from "components/shared/types/filter";
import {
  TherapeuticTable,
  TTherapeuticRowData,
} from "../TherapeuticSummary.types";

const getOptions = (therapeuticItems: TTherapeuticRowData[]): TFilterOption[] =>
  uniqBy(therapeuticItems, TherapeuticTable.DosageForm).map(
    (therapeuticItem) => ({
      text: therapeuticItem[TherapeuticTable.DosageForm],
      value: therapeuticItem[TherapeuticTable.DosageForm],
    }),
  );

const useDosageFormFilter = (
  therapeuticItems: TTherapeuticRowData[],
): Record<TherapeuticTable.DosageForm, TSelectFilter> => {
  const { t } = useTranslation("portfolio");

  return useMemo(
    () => ({
      [TherapeuticTable.DosageForm]: {
        type: FilterType.Select,
        label: t(`therapeutics.columns.dosageForm`, {
          ns: "portfolio",
        }),
        options: getOptions(therapeuticItems),
        selectAllLabel: t("multiSelect.selectAll", { ns: "common" }),
      },
    }),
    [therapeuticItems, t],
  );
};

export default useDosageFormFilter;
