import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { FormDate } from "components/common/form-fields";
import { ImportFormSharedFieldName } from "screens/Project/sections/Documents/modals/ImportDocument";

const AAIDAdditionalFields = () => {
  const { t } = useTranslation("documents", {
    keyPrefix: "importDocument.fields",
  });

  return (
    <Grid item xs={12}>
      <FormDate
        data-qaid="import-document-submission-date-field"
        name={ImportFormSharedFieldName.SubmissionDate}
        label={t("submissionDate.label")}
      />
    </Grid>
  );
};

export default AAIDAdditionalFields;
