import { z } from "zod";
import { ChangeDocumentNameFields } from "./ChangeDocumentName.types";

export const documentNameInputSchema = (
  documentName: string,
  message: string,
) =>
  z.object({
    [ChangeDocumentNameFields.DossierName]: z
      .string()
      .min(1)
      .refine((value) => value !== documentName, { message }),
  });
