import { Stack, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import ExpandCollapseIcon from "components/shared/ReactTable/ExpandCollapseIcon";
import { renderOrganizations } from "screens/EntityManagement/EntityManagement.utils";
import { filterByArrayIncludesExactly } from "utils/helpers/filterFunctions";
import { ProductsTable, TProductsRowData } from "./ProductsTable.types";
import {
  filterByLinkedOrg,
  filterByLinkedProductFamily,
  filterByLinkedSubstance,
} from "./ProductsTable.utils";

const columnHelper = createColumnHelper<TProductsRowData>();

export const getColumns = (t: TFunction) => [
  columnHelper.accessor(ProductsTable.Id, {
    header: "",
    enableColumnFilter: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor(ProductsTable.Name, {
    header: t("productsColumns.name"),
    cell: ({ row, getValue }) => (
      <Stack direction="row" alignItems="center">
        {!row.getParentRow() && (
          <ExpandCollapseIcon
            row={row}
            color="action"
            sx={{ mx: 1, fontSize: "1.5rem" }}
          />
        )}
        <Typography variant="body2" fontWeight={500}>
          {getValue()}
        </Typography>
      </Stack>
    ),
    meta: { width: "20%", headCellStyles: { paddingLeft: 7 } },
    filterFn: filterByArrayIncludesExactly,
  }),
  columnHelper.accessor(ProductsTable.NonProprietaryName, {
    header: t("productsColumns.nonproprietaryName"),
    meta: {
      headerTooltip: t("productsColumns.nonproprietaryNameTooltip"),
      width: "17%",
    },
    filterFn: filterByArrayIncludesExactly,
  }),
  columnHelper.accessor(ProductsTable.LinkedOrganizations, {
    header: t("productsColumns.linkedOrganizations"),
    cell: ({ cell: { getValue } }) => renderOrganizations(getValue()),
    meta: {
      headerTooltip: t("productsColumns.linkedOrganizationsTooltip"),
      width: "17%",
    },
    filterFn: filterByLinkedOrg,
  }),
  columnHelper.accessor(ProductsTable.ProductFamily, {
    header: t("productsColumns.linkedProductFamily"),
    cell: ({ cell: { getValue } }) =>
      getValue()
        .map((productFamily) => productFamily.name)
        .join(", "),
    meta: {
      headerTooltip: t("productsColumns.linkedProductFamilyTooltip"),
      width: "17%",
    },
    filterFn: filterByLinkedProductFamily,
  }),
  columnHelper.accessor(ProductsTable.Substance, {
    header: t("productsColumns.linkedSubstances"),
    cell: ({ cell: { getValue } }) =>
      getValue()
        .map((substance) => substance.name)
        .join(", "),
    meta: {
      headerTooltip: t("productsColumns.linkedSubstancesTooltip"),
      width: "17%",
    },
    filterFn: filterByLinkedSubstance,
  }),
  columnHelper.accessor(ProductsTable.Status, {
    header: t("productsColumns.status"),
    cell: ({ cell: { getValue } }) => {
      return t(`${getValue()}`);
    },
    meta: { width: "7%" },
    filterFn: filterByArrayIncludesExactly,
  }),
  columnHelper.display({
    header: t("productsColumns.actions"),
    cell: ({ row }) => row.original.actions,
    meta: { SROnly: true, width: "5%" },
  }),
];
