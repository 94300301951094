import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { gql, Variables } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { IDrugClassListResponse } from "./useDrugClassList.models";

export const query = gql`
  query DrugClassList {
    drugClassList {
      id
      name
      code
    }
  }
`;

const useDrugClassList = (enabled: boolean = true) => {
  const { t } = useTranslation("administration");
  const { showAlert } = useAlerts();

  const { data, isLoading, isSuccess } = useQuery<IDrugClassListResponse>(
    [QueryAPIKey.GetDrugClassList],
    () =>
      services.graphQL
        .request<IDrugClassListResponse, Variables>(query)
        .catch((error) => {
          showAlert({
            severity: AlertSeverity.Error,
            message: t("notifications.getDrugClassListFailure"),
          });
          throw error;
        }),
    {
      enabled,
    },
  );

  return {
    data: data?.drugClassList ?? [],
    isLoading,
    isSuccess,
  };
};

export default useDrugClassList;
