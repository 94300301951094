import { useEffect } from "react";
import { QueryStatus } from "react-query";
import { useAlerts } from "context/AlertContext";
import { QueryFetchingStatus } from "utils/constants/api.constants";
import { TAtLeast } from "utils/types";

export type TAlertBase = {
  status: QueryStatus;
  successMessage: string;
  failureMessage: string;
};

export type TShowAlertProps = TAtLeast<TAlertBase, "status">;
export type TAlertMessage = Omit<TAlertBase, "status">;

const useShowAlert = ({
  status,
  successMessage,
  failureMessage,
}: TShowAlertProps) => {
  const { showAlert } = useAlerts();

  useEffect(() => {
    if (successMessage && status === QueryFetchingStatus.Success) {
      showAlert({
        message: successMessage,
        severity: "success",
      });
    } else if (failureMessage && status === QueryFetchingStatus.Error) {
      showAlert({
        message: failureMessage,
        severity: "error",
      });
    }
  }, [status, showAlert, successMessage, failureMessage]);
};

export default useShowAlert;
