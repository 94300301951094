/* istanbul ignore file */
import { PropsWithChildren, RefObject } from "react";
import { useTranslation } from "react-i18next";
import { CKEditorContext } from "@ckeditor/ckeditor5-react";
import { ContextWatchdog } from "ckeditor5";
import getEnv from "env";
import { Context as CustomEditorContext } from "ckeditor";
import { useAnnotationUIContext } from "../AnnotationUIContext/AnnotationUIContext";

type TEditorContextProps = PropsWithChildren<{
  sidebarRef?: RefObject<HTMLDivElement>;
  isLayoutReady: boolean;
}>;

type TContextConfig = {
  licenseKey: string;
  language: string;
  sidebar?: {
    preventScrollOutOfView: boolean;
    container: HTMLDivElement;
  };
};

const EditorContext = ({
  children,
  sidebarRef,
  isLayoutReady,
}: TEditorContextProps) => {
  const { i18n } = useTranslation();

  const contextConfig: TContextConfig = {
    licenseKey: getEnv().CKEditorKey,
    language: i18n.language,
  };

  if (sidebarRef) {
    contextConfig.sidebar = {
      container: sidebarRef.current!,
      preventScrollOutOfView: true,
    };
  }

  const { setAnnotationUI } = useAnnotationUIContext();

  const onReady = (context: CustomEditorContext) => {
    if (!context || !sidebarRef) {
      return;
    }

    // set AnnotationUIContext for controlling filters
    const annotationsUIs = context.plugins.get("AnnotationsUIs");

    if (annotationsUIs) {
      // enable wide sidebar display mode for annotations (comments/track changes)
      annotationsUIs.switchTo("wideSidebar");
      setAnnotationUI(annotationsUIs);
    }
  };

  return (
    <CKEditorContext<CustomEditorContext>
      context={CustomEditorContext}
      contextWatchdog={ContextWatchdog}
      config={contextConfig}
      isLayoutReady={isLayoutReady}
      onReady={onReady}
    >
      {isLayoutReady && children}
    </CKEditorContext>
  );
};

export default EditorContext;
