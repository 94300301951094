import { IHAQWithContent } from "hooks/api/GQL/haq/useHAQsWithContent.models";
import { IHAQ } from "models/HAQ.models";
import { IOrganizationResponse } from "models/organizations.models";
import { AssetImportType } from "utils/constants/assets.constants";
import getOrganizationByTenantId from "utils/helpers/organizations/getOrganizationByTenantId";

const getCountryNameFromHAQ = (HAQ: IHAQ | IHAQWithContent) =>
  "organization" in HAQ && HAQ?.organization?.country;

enum HAQImportTypePrefix {
  Ext = "EXT",
  Ref = "REF",
}

const ImportTypeToPrefixMap = {
  [AssetImportType.External]: HAQImportTypePrefix.Ext,
  [AssetImportType.Reference]: HAQImportTypePrefix.Ref,
} as const;

const getHAQShortName = (
  HAQ: IHAQ | IHAQWithContent | undefined,
  HAQAcronym: string,
  organizations: IOrganizationResponse | undefined = undefined,
) => {
  if (!HAQ) {
    return "";
  }

  const prefix = HAQ?.metadata?.importType
    ? ImportTypeToPrefixMap[HAQ.metadata.importType]
    : "";

  const countryName =
    getCountryNameFromHAQ(HAQ) ||
    getOrganizationByTenantId(
      organizations?.content,
      HAQ.metadata?.authoringTenantId,
    )?.country ||
    "";

  const sequenceNumber = HAQ?.metadata?.assetTypeSequenceNum || "";

  return [prefix, countryName, HAQAcronym, sequenceNumber]
    .filter(Boolean)
    .join("-")
    .toUpperCase();
};

export default getHAQShortName;
