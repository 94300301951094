import { IDocumentOutline } from "models/documents.models";
import { TenantType } from "utils/constants/users.constants";
import { TSkeletonComponent } from "../Document.types";

const mapOutlineToSkeletonComponents = (
  outline: IDocumentOutline[],
): TSkeletonComponent[] => {
  let skeletonComponentsList: TSkeletonComponent[] = [];
  outline.forEach((outlineSection) => {
    skeletonComponentsList.push({
      assetId: outlineSection.id,
      assetContentId: outlineSection.latestVersion?.assetContentId,
      order: outlineSection.metadata.order,
      headerText: outlineSection.metadata.headerText,
      name: outlineSection.name,
      parentId: outlineSection.parentId,
      status: outlineSection.status,
      componentType: outlineSection.metadata.componentType as TenantType,
    });

    if (Array.isArray(outlineSection.children)) {
      skeletonComponentsList = skeletonComponentsList.concat(
        mapOutlineToSkeletonComponents(outlineSection.children),
      );
    }
  });

  return skeletonComponentsList;
};

export { mapOutlineToSkeletonComponents };
