import { useTranslation } from "react-i18next";
import { QueryObserverOptions, useQuery } from "react-query";
import { IClientConfig } from "api/types";
import useShowAlert from "hooks/useShowAlert";
import { IAssetContent } from "models/assets.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export default function useAssetContent<T = IAssetContent>(
  assetId: string,
  config?: IClientConfig,
  options?: QueryObserverOptions<T, Error>,
) {
  const { t } = useTranslation("asset", {
    keyPrefix: "notifications",
  });

  const { data, status, isLoading, isError, isSuccess, error } = useQuery<
    T,
    Error
  >(
    [QueryAPIKey.GetAssetContent, assetId],
    () =>
      services.assets.getAssetContent(assetId, config).then((res) => res.data),
    {
      ...options,
      enabled: !!assetId && (options?.enabled ?? true),
    },
  );

  useShowAlert({
    status,
    failureMessage: t("getAssetFailure"),
  });

  return {
    data,
    status,
    error,
    isLoading,
    isError,
    isSuccess,
  };
}
