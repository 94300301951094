import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Box, Divider, Stack, Typography } from "@mui/material";
import Button from "components/shared/Button";
import useFeatureFlags from "context/FeatureFlagsContext/useFeatureFlags";
import useMarkNotificationRead from "hooks/api/REST/notifications/useMarkNotificationRead";
import { INotification } from "models/notificationItem.models";
import { getIsEmptyString, getRelativeTimeString } from "utils/helpers";
import {
  getCTALink,
  getNotificationIcon,
  getNotificationTitleForReason,
  getReadableAssetType,
  getReadableCTAType,
} from "./NotificationsListItem.utils";
import { ReactComponent as NewIndicator } from "assets/images/new-indicator.svg";
import styles from "./NotificationsListItem.styles";

type TNotificationsListItemProps = {
  data: INotification;
};

const NotificationsListItem = ({ data }: TNotificationsListItemProps) => {
  const { id, templateType, metadata, createdDate, read: isRead } = data;
  const { templateText, ctaType, assetType } = metadata;

  const featureFlags = useFeatureFlags();

  const containerStyle = useMemo(
    () => ({
      ...styles.container,
      // append extra css for unread notification item
      ...(!isRead && styles.unread),
    }),
    [isRead],
  );

  const { t, i18n } = useTranslation("notifications");

  const { triggerMarkNotificationAsRead } = useMarkNotificationRead();

  const onMarkAsRead = () => {
    if (isRead) {
      return;
    }

    triggerMarkNotificationAsRead({
      id,
      notification: { ...data, read: true },
    });
  };

  const subjectLink = getCTALink(metadata, featureFlags);

  return (
    <Stack
      direction="row"
      gap={2}
      sx={containerStyle}
      aria-label={templateType}
      onClick={onMarkAsRead}
    >
      <Box sx={styles.iconParentContainer} data-qaid="notification-item-icon">
        {!isRead && (
          <Box
            sx={styles.newIndicator}
            data-qaid="notification-item-icon-new-indicator"
          >
            <NewIndicator />
          </Box>
        )}
        <Box sx={styles.iconContainer}>{getNotificationIcon(ctaType)}</Box>
      </Box>
      <Stack direction="column" gap={1.5}>
        <Stack direction="column" gap={1} sx={styles.contentContainer}>
          {!getIsEmptyString(templateText) && (
            <Typography
              variant="body2"
              aria-label="content"
              sx={styles.templateText}
              data-qaid="notification-item-text-content"
            >
              {t(templateText, {
                ...metadata,
                assetType: getReadableAssetType(assetType, t),
              })}
            </Typography>
          )}
          <Typography
            variant="caption"
            sx={styles.notificationCaption}
            data-qaid="notification-item-creation-time"
          >
            {createdDate &&
              getRelativeTimeString(new Date(createdDate), i18n.language)}
          </Typography>
        </Stack>
        {Boolean(data?.metadata?.reason) && (
          <Stack direction="column" gap={1}>
            <Divider style={{ width: "50%" }} />
            <Box>
              <Typography variant="caption" sx={styles.notificationCaption}>
                {getNotificationTitleForReason(data, t)}
              </Typography>
              <Typography variant="body2">{data?.metadata?.reason}</Typography>
            </Box>
          </Stack>
        )}
        {metadata && ctaType && subjectLink && (
          <Box sx={styles.actionContainer}>
            <Link to={subjectLink} data-qaid="notification-item-button-link">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                aria-label={templateType}
              >
                {t("templateButtonText.view", {
                  subject: (ctaType && getReadableCTAType(metadata, t)) || "",
                })}
              </Button>
            </Link>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default NotificationsListItem;
