import { TFunction } from "i18next";
import { Tooltip } from "components/shared";
import { IDocument } from "models/documents.models";
import { DocumentStatus } from "utils/constants/doc.constants";
import { getDisplayedAssetStatus } from "utils/helpers";
import { BeenhereIcon } from "assets/icons";

const RowStatus = ({ document, t }: { document: IDocument; t: TFunction }) => {
  const isAcknowledged = document.status === DocumentStatus.Acknowledged;
  const isCleared = Boolean(document?.metadata?.cleared);

  return (
    <div style={{ position: "relative" }}>
      <span>
        {getDisplayedAssetStatus(
          document.source?.tenantType,
          document.status,
          t,
        )}
      </span>
      {isAcknowledged && isCleared ? (
        <Tooltip title={t("documents.clearedStatusAltImageText")}>
          <BeenhereIcon
            sx={{ fontSize: 18 }}
            aria-label={t("documents.clearedStatusAltImageText")}
            style={{ position: "relative", left: "5px", top: "3px" }}
          />
        </Tooltip>
      ) : null}
    </div>
  );
};

export default RowStatus;
