import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import HealthAuthority from "components/common/HealthAuthority";
import { getHAName } from "components/common/HealthAuthority/HealthAuthority.utils";
import Button from "components/shared/Button";
import { useGSPContext } from "context/GSPContext";
import {
  TDossierDifferencesModule,
  useDossierDifferenceModal,
} from "screens/Project/screens/GSP/GSPItems";
import styles from "./DossierDifferencesModule.styles";

type TDossierDifferencesModuleProps = {
  module: TDossierDifferencesModule;
  parentId: string;
  isEditable?: boolean;
  initialHA?: { name: string; tenantId: string };
  isCountrySpecific?: boolean;
};

const DossierDifferencesModule = ({
  module,
  parentId,
  isEditable,
  initialHA,
  isCountrySpecific,
}: TDossierDifferencesModuleProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { activeGSPItem } = useGSPContext();
  const isParentExpanded = parentId === activeGSPItem;

  const { t } = useTranslation(["projects", "user", "common", "countries"]);

  const { handleClickEditDossierDifference } = useDossierDifferenceModal();

  return (
    <Box>
      <Accordion
        expanded={isExpanded && isParentExpanded}
        onChange={() => setIsExpanded((isExpanded) => !isExpanded)}
        sx={styles.accordion}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          aria-controls={`module-${module.id}-content`}
          id={`module-${module.id}-header`}
          expandIcon={<ExpandMoreIcon />}
          sx={styles.accordionSummary}
        >
          <Box sx={styles.heading}>
            <Typography variant="subtitle1" component="span">
              {module.name}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={styles.accordionDetails}>
          {module.sections.map((section, index) => (
            <Box sx={styles.section} key={index}>
              <Typography
                variant="body2"
                component="div"
                sx={styles.sectionHeader}
              >
                {section.name}{" "}
                {section.parentName && (
                  <i>
                    ({t("projectGSP.inSection")} {section.parentName})
                  </i>
                )}
              </Typography>

              <Box sx={styles.sectionContent}>
                <Grid container rowSpacing={2.5}>
                  <Grid item xs={4} sx={styles.sectionContentLabel}>
                    {t("dossierDifferences.summaryOfDifferenceLabel")}
                  </Grid>
                  <Grid item xs={4}>
                    {section.summaryOfDifferences}
                  </Grid>

                  <Grid item xs={4} sx={styles.sectionButton}>
                    {isEditable && (
                      <Button
                        data-qaid="edit-dossier-differences-button"
                        onClick={() => {
                          handleClickEditDossierDifference(section, initialHA);
                        }}
                      >
                        {t("button.edit", { ns: "common" })}
                      </Button>
                    )}
                  </Grid>

                  <Grid item xs={4} sx={styles.sectionContentLabel}>
                    {isCountrySpecific
                      ? t("dossierDifferences.alsoAppliesTo")
                      : t("projectGSP.agenciesLabel")}
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container spacing={2.5}>
                      {section.healthAuthorities.map((HA) => (
                        <Grid item xs={6} key={HA.id}>
                          <HealthAuthority
                            country={HA.country}
                            name={getHAName(HA.country, HA.shortName, t)}
                            sx={styles.sectionContentHA}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default DossierDifferencesModule;
