import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import Cancel from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import ErrorIcon from "@mui/icons-material/Error";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { TFunction } from "i18next";
import { TChipProps } from "components/shared/Chip";
import { DocumentStatus } from "utils/constants/doc.constants";
import {
  InvitationStatus,
  SubmissionPlan,
} from "utils/constants/gsp.constants";
import { DecisionStatus } from "utils/constants/healthAuthorities.constants";
import { ProcessingStatus } from "utils/constants/project.constants";
import { ProjectMemberStatus } from "utils/constants/projectMembers.constants";
import { TenantType, UserStatus } from "utils/constants/users.constants";
import { getDisplayedAssetStatus } from "../getDisplayedAssetStatus";

export type TStatusVariant =
  | DocumentStatus
  | UserStatus
  | ProjectMemberStatus
  | SubmissionPlan
  | ProcessingStatus
  | InvitationStatus
  | DecisionStatus;

export const getStatusChipConfig = ({
  status: statusVariant,
  tenantType,
  t,
}: {
  status: TStatusVariant | null;
  tenantType?: TenantType;
  t: TFunction;
}): TChipProps => {
  const status = statusVariant?.toUpperCase();

  switch (status) {
    case ProjectMemberStatus.Active:
      return {
        color: "success",
        label: t("members.status.ACTIVE", { ns: "projects" }),
      };
    case ProjectMemberStatus.Inactive:
      return {
        label: t("members.status.INACTIVE", { ns: "projects" }),
      };
    case DocumentStatus.InVerification:
      return {
        color: "default",
        icon: <AccessTimeFilledIcon />,
        label: t("asset.IN_VERIFICATION", { ns: "statuses" }),
      };
    case ProcessingStatus.Pending:
    case DecisionStatus.Pending:
      return {
        icon: <AccessTimeFilledIcon />,
        label: t("processingStatus.PENDING", { ns: "common" }),
      };
    case ProcessingStatus.Approved:
    case DecisionStatus.Approved:
      return {
        color: "success",
        icon: <CheckCircleIcon />,
        label: t("processingStatus.APPROVED", { ns: "common" }),
      };
    case ProcessingStatus.Rejected:
      return {
        color: "error",
        icon: <ErrorIcon />,
        label: t("processingStatus.REJECTED", { ns: "common" }),
      };
    case ProcessingStatus.Accepted:
      return {
        color: "success",
        icon: <CheckCircleIcon />,
        label: t("processingStatus.ACCEPTED", { ns: "common" }),
      };
    case SubmissionPlan.Submitting:
      return {
        color: "success",
        icon: <CheckCircleOutlineIcon />,
        label: t("gsp.item.fields.submissionPlan.optionLabels.SUBMITTING", {
          ns: "documents",
        }),
      };
    case SubmissionPlan.NotSubmitting:
      return {
        icon: <NotInterestedIcon />,
        label: t("gsp.item.fields.submissionPlan.optionLabels.NOT_SUBMITTING", {
          ns: "documents",
        }),
      };
    case DocumentStatus.Uploaded:
    case DocumentStatus.Final:
      return {
        color: "success",
        icon: <CheckCircleIcon />,
        label: getDisplayedAssetStatus(tenantType, status, t),
      };
    case DocumentStatus.Completed:
      return {
        color: "success",
        icon: <CheckCircleIcon />,
        label: getDisplayedAssetStatus(tenantType, status, t),
      };
    case DocumentStatus.Processing:
      return {
        icon: <AccessTimeFilledIcon />,
        label: getDisplayedAssetStatus(tenantType, status, t),
      };
    case DocumentStatus.Failure:
      return {
        color: "error",
        icon: <ErrorIcon />,
        label: getDisplayedAssetStatus(tenantType, status, t),
      };
    case DocumentStatus.Draft:
    case DocumentStatus.Acknowledged:
    case DocumentStatus.Cleared:
    case DocumentStatus.Cancelled:
      return {
        label: getDisplayedAssetStatus(tenantType, status, t),
      };
    case DecisionStatus.Denied:
      return {
        icon: <Cancel />,
        label: t("decisionStatus.DENIED", { ns: "statuses" }),
      };
    case DecisionStatus.NoLongerParticipating:
      return {
        icon: <DoNotDisturbOnIcon />,
        label: t("decisionStatus.NO_LONGER_PARTICIPATING", { ns: "statuses" }),
      };
    case DecisionStatus.Withdrawn:
      return {
        icon: <DoNotDisturbOnIcon />,
        label: t("decisionStatus.WITHDRAWN", { ns: "statuses" }),
      };
    default:
      return {
        label: status ?? "",
      };
  }
};
