import { useTranslation } from "react-i18next";
import { Checkbox, CheckboxProps, TableCell } from "@mui/material";

type TSelectionCellProps = CheckboxProps & {
  ariaLabel?: string;
};

const SelectionCell = (props: TSelectionCellProps) => {
  const { ariaLabel, sx, ...checkboxProps } = props;

  const { t } = useTranslation("common");

  return (
    <TableCell
      sx={{ ...sx, pt: 0.625 }}
      padding="checkbox"
      aria-label={t("accessibility.label.selectionCell")}
    >
      <Checkbox
        color="primary"
        indeterminate={props.indeterminate}
        checked={props.checked}
        disabled={props.disabled}
        onChange={props.onChange}
        inputProps={{ "aria-label": ariaLabel }}
        {...checkboxProps}
      />
    </TableCell>
  );
};

export default SelectionCell;
