import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormSelect } from "components/common/form-fields";
import Loading from "components/shared/Loading";
import { ApplicationType } from "utils/constants/gsp.constants";
import { formatValuesToOptions } from "utils/helpers";

const ApplicationTypeField = ({
  name,
  typeValues,
}: {
  name: string;
  typeValues: ApplicationType[] | undefined;
}) => {
  const { t } = useTranslation("documents", {
    keyPrefix: "gsp.item.fields.applicationType",
  });

  const typeOptions = useMemo(() => {
    return formatValuesToOptions(
      typeValues,
      (value) => t(`optionLabels.${value}`),
      (value) => value,
    );
  }, [typeValues, t]);

  if (!typeValues) {
    return <Loading />;
  }

  return (
    <FormSelect
      name={name}
      data-qaid="application-type-select"
      label={t("label")}
      options={typeOptions}
    />
  );
};

export default ApplicationTypeField;
