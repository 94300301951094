import { useInView } from "react-cool-inview";
import { useDocumentContext } from "screens/Project/sections/Documents/Document/DocumentContext/DocumentContext";

export const useComponentVisibility = (isEditingComponent: boolean) => {
  const { editingComponent } = useDocumentContext();

  const { observe, unobserve } = useInView({
    threshold: 0.5,
    onEnter: () => {
      editingComponent.setIsVisible(true);
    },
    onLeave: () => {
      editingComponent.setIsVisible(false);
    },
  });

  isEditingComponent && observe();
  !isEditingComponent && unobserve();

  return { currentComponentRef: observe };
};
