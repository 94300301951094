import { notificationsBasePart } from "./constants";

export default {
  notifications: (id: string) => `${notificationsBasePart}/notifications/${id}`,
  getNotificationsForUser: (userId: string) =>
    `${notificationsBasePart}/users/${userId}/notifications`,
  markAllNotificationsRead: (userId: string) =>
    `${notificationsBasePart}/users/${userId}/notifications/mark-all-read-request`,
  createNotification: (userId: string) =>
    `${notificationsBasePart}/users/${userId}/notifications`,
};
