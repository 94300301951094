import { MouseEventHandler, PropsWithChildren } from "react";
import { SxProps, TableCell, Theme } from "@mui/material";

export type TReactTableCellProps = PropsWithChildren<{
  sx?: SxProps<Theme>;
  colSpan?: number;
  onClick?: MouseEventHandler<HTMLTableCellElement>;
}>;

const ReactTableCell = ({
  sx,
  colSpan,
  children,
  onClick,
}: TReactTableCellProps) => (
  <TableCell
    sx={sx}
    colSpan={colSpan}
    data-testid="table-body-cell"
    data-qaid="table-body-cell"
    onClick={onClick}
  >
    {children}
  </TableCell>
);

export default ReactTableCell;
