const getParsedARN = (ARN?: string) => {
  const ARNFormatRegexTest = /^[a-zA-Z0-9]+_[a-zA-Z0-9-]+_[a-zA-Z0-9]+$/;

  // If not a string, or not a string containing numbers or letters in three groupings separated by underscores, return null
  if (typeof ARN !== "string" || !ARNFormatRegexTest.test(ARN)) {
    return null;
  }

  const splitARN = ARN?.split("_");

  return {
    resourceType: splitARN?.[0] || "",
    tenantId: splitARN?.[1] || "",
    resourceId: splitARN?.[2] || "",
  };
};

export default getParsedARN;
