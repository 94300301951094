import { useMemo } from "react";
import useDocuments from "hooks/api/REST/documents/useDocuments";
import { IDocument } from "models/documents.models";
import { MAX_SIZE } from "utils/constants/api.constants";
import { DocumentSubtype } from "utils/constants/doc.constants";
import { DEFAULT_ORBIS_HOST_HA_TENANT_ID } from "utils/constants/organization.constants";
import { uniqueArray } from "utils/helpers";

const useProjectLOASubmittedTenantIds = (projectId: string | undefined) => {
  const { isLoading, isError, documents } = useDocuments({
    assetTypes: DocumentSubtype.LOA,
    projectIds: projectId,
    size: MAX_SIZE,
  });

  const loaSubmittedTenantIds = useMemo(
    () =>
      uniqueArray(
        documents
          ?.flatMap(
            (document: IDocument) =>
              document?.metadata?.applicableHealthAuthorityTenantId,
          )
          ?.filter((id) => id !== DEFAULT_ORBIS_HOST_HA_TENANT_ID) || [],
      ),
    [documents],
  );

  return {
    loaSubmittedTenantIds,
    isLoading,
    isError,
  };
};

export default useProjectLOASubmittedTenantIds;
