import { TFunction } from "i18next";
import { TProductVariant } from "../Products.types";

export const getProductVariantOptions = (
  t: TFunction,
  variants?: TProductVariant[],
) =>
  variants?.map((variant, index) => ({
    id: variant.id,
    label: t("variantIndexLabel", { index: index + 1 }),
    value: variant.id,
  })) || [];
