import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Grid, SxProps, Theme, Typography } from "@mui/material";
import { useProject } from "context/ProjectContext";
import useAsset from "hooks/api/REST/assets/useAsset";
import useGetAssetRelationships from "hooks/api/REST/assets/useGetAssetRelationships";
import useOrganizations from "hooks/api/REST/organizations/useOrganizations";
import useUser from "hooks/useUser";
import { IAssetProductMetadata } from "models/assets.models";
import { IDocument } from "models/documents.models";
import { AssetRelationship } from "utils/constants/assets.constants";
import { ProjectType } from "utils/constants/project.constants";
import {
  getDefaultDocumentInformationStructure,
  getRelianceDocumentInformationStructure,
} from "./DocumentInformationContent.utils";
import styles from "./DocumentInformationContent.styles";

type TDocumentInformationContentProps = {
  document: IDocument | null;
};

const DocumentInformationContent = ({
  document,
}: TDocumentInformationContentProps) => {
  const { t } = useTranslation("documents");

  const { project } = useProject();

  const { organization } = useUser();

  const { organizations } = useOrganizations({
    tenantIds: [document?.metadata?.authoringTenantId ?? ""],
  });

  const { asset: product } = useAsset<IAssetProductMetadata>(
    project?.metadata?.productId || "",
    {},
    { enabled: Boolean(project?.metadata?.productId) },
  );

  const { asset: assetRelationships } = useGetAssetRelationships(
    product?.id || "",
    undefined,
    {
      enabled: Boolean(product?.id),
    },
  );

  const productFamilyRelationshipId = assetRelationships?.find(
    (asset) => asset?.relationship === AssetRelationship.BelongsTo,
  )?.id;

  const productFamilyId =
    productFamilyRelationshipId || product?.metadata?.primaryProductFamilyId;

  const { asset: productFamily } = useAsset<IAssetProductMetadata>(
    productFamilyId || "",
    {},
    { enabled: Boolean(product?.metadata?.primaryProductFamilyId) },
  );

  const documentInformationStructure = useMemo(() => {
    switch (project?.type) {
      case ProjectType.Reliance:
        return getRelianceDocumentInformationStructure(
          document,
          {
            projectName: project?.name,
            genericName: product?.name,
            regulatoryEvent: project?.metadata?.regulatoryEvent,
            productFamily: productFamily?.name,
            authoringOrganization: organizations?.content?.[0],
            organization,
          },
          t,
        );

      default:
        return getDefaultDocumentInformationStructure(
          document,
          {
            projectName: project?.name,
            genericName: product?.name,
            regulatoryEvent: project?.metadata?.regulatoryEvent,
            productFamily: productFamily?.name,
            authoringOrganization: organizations?.content?.[0],
          },
          t,
        );
    }
  }, [
    document,
    project,
    organizations,
    productFamily,
    product,
    organization,
    t,
  ]);

  return (
    <>
      {documentInformationStructure.map((row) => {
        const containerStyles = row.separator
          ? ({
              ...styles.gridContainer,
              ...styles.separator,
            } as SxProps<Theme>)
          : styles.gridContainer;

        return (
          <Grid container key={row.key} sx={containerStyles}>
            <Grid item>
              <Typography variant="body2" sx={styles.label}>
                {row.label}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" sx={styles.value}>
                {row.value}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default DocumentInformationContent;
