import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { gql, Variables } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { IOrganizationRoleListResponse } from "./useOrganizationRoleList.models";

export const query = gql`
  query OrganizationRole($organizationTypeCode: String) {
    organizationRole(organizationTypeCode: $organizationTypeCode) {
      id
      name
      code
    }
  }
`;

const useOrganizationRoleList = (
  organizationTypeCode?: string,
  enabled: boolean = true,
) => {
  const { t } = useTranslation("administration");
  const { showAlert } = useAlerts();

  const { data, isLoading, isSuccess } =
    useQuery<IOrganizationRoleListResponse>(
      [QueryAPIKey.GetOrganizationRoleList],
      () =>
        services.graphQL
          .request<IOrganizationRoleListResponse, Variables>(query, {
            organizationTypeCode,
          })
          .catch((error) => {
            showAlert({
              severity: AlertSeverity.Error,
              message: t("notifications.getOrganizationRoleFailure"),
            });
            throw error;
          }),
      {
        enabled,
      },
    );

  return {
    data: data?.organizationRole ?? [],
    isLoading,
    isSuccess,
  };
};

export default useOrganizationRoleList;
