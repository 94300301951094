import { TFunction } from "i18next";
import { z } from "zod";
import {
  IGSPNotSubmittingComponent,
  IGSPSubmittingComponent,
} from "models/gsp.models";
import {
  ApplicationType,
  ProposedApprovalPathway,
  ProposedTimeline,
  SubmissionPlan,
} from "utils/constants/gsp.constants";
import { getGenericFutureDateConstraint } from "utils/validators/constraints/validationConstraints";

export enum GSPFormFieldName {
  SubmissionPlan = "submissionPlan",
  CompanyName = "companyName",
  ContactName = "contactName",
  ContactEmail = "contactEmail",
  PhoneNumber = "phoneNumber",
  ApplicationType = "applicationType",
  ApplicationNumber = "applicationNumber",
  ReviewType = "isRtorOrRollingReview",
  StartDate = "startDate",
  ProposedDate = "proposedFillingDate",
  Timeline = "timeline",
  ApprovalPathway = "approvalPathway",
  TherapeuticIndication = "therapeuticIndication",
  ClinicalTrialsIndication = "clinicalTrialsIndication",
  AdditionalComments = "additionalComments",
  NotSubmittingReason = "reason",
}

export enum GSPFormFieldGroupName {
  SponsorInfo = "sponsorInfo",
  ApplicationInfo = "applicationInfo",
}

export type TGSPItemFormBase = {
  [GSPFormFieldName.SubmissionPlan]?: SubmissionPlan | "";
  [GSPFormFieldGroupName.SponsorInfo]?: {
    [GSPFormFieldName.CompanyName]?: string;
    [GSPFormFieldName.ContactName]?: string;
    [GSPFormFieldName.ContactEmail]?: string;
    [GSPFormFieldName.PhoneNumber]?: string;
  };
};

export type TGSPItemNotSubmittingForm = TGSPItemFormBase & {
  [GSPFormFieldName.NotSubmittingReason]?: string;
};

export type TGSPItemSubmittingFormApplicationInfo = {
  [GSPFormFieldName.ApplicationType]?: ApplicationType | "";
  [GSPFormFieldName.ApplicationNumber]?: string;
  [GSPFormFieldName.StartDate]?: string;
  [GSPFormFieldName.ReviewType]?: string | boolean;
  [GSPFormFieldName.ProposedDate]?: string;
  [GSPFormFieldName.Timeline]: ProposedTimeline | "";
  [GSPFormFieldName.ApprovalPathway]?: ProposedApprovalPathway | "";
  [GSPFormFieldName.TherapeuticIndication]?: string;
  [GSPFormFieldName.ClinicalTrialsIndication]?: string;
  [GSPFormFieldName.AdditionalComments]?: string;
};

export type TGSPItemSubmittingForm = TGSPItemFormBase & {
  [GSPFormFieldGroupName.ApplicationInfo]: TGSPItemSubmittingFormApplicationInfo;
};

export type TGSPItemForm = TGSPItemSubmittingForm | TGSPItemNotSubmittingForm;

type TUUID = string;

export type TGSPForm = {
  [key: TUUID]: TGSPItemForm;
};

export const isSubmittingItemForm = (
  values: TGSPItemSubmittingForm | TGSPItemFormBase,
): values is TGSPItemSubmittingForm =>
  values.submissionPlan === SubmissionPlan.Submitting ||
  values.submissionPlan === "";

export const isNotSubmittingItemForm = (
  values: TGSPItemSubmittingForm | TGSPItemFormBase,
): values is TGSPItemNotSubmittingForm =>
  values.submissionPlan === SubmissionPlan.NotSubmitting;

export type TGSPAllFields = IGSPSubmittingComponent &
  IGSPNotSubmittingComponent;

export type TGSPDefaultValues = TGSPItemSubmittingForm &
  TGSPItemNotSubmittingForm;

export const getGSPFormSchema = (t: TFunction) =>
  z.record(
    z.string(),
    z
      .object({
        [GSPFormFieldGroupName.SponsorInfo]: z
          .object({
            [GSPFormFieldName.ContactEmail]: z
              .string()
              .email()
              .nullish()
              .or(z.literal("")),
            [GSPFormFieldName.PhoneNumber]: z
              .string()
              .nullish()
              .or(z.literal("")),
          })
          .passthrough(),
        [GSPFormFieldGroupName.ApplicationInfo]: z
          .object({
            [GSPFormFieldName.ProposedDate]: getGenericFutureDateConstraint(t)
              .nullish()
              .or(z.literal("")),
            [GSPFormFieldName.StartDate]: getGenericFutureDateConstraint(t)
              .nullish()
              .or(z.literal("")),
          })
          .passthrough(),
      })
      .passthrough(),
  );
