const getTextFromBlob = (file: File | Blob) =>
  new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(JSON.parse(reader.result as string));
    };

    reader.readAsText(file);
  });

export default getTextFromBlob;
