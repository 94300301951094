import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import { isSubmittingGSPComponent, TGSPComponent } from "models/gsp.models";
import { ItemLayout } from "screens/Project/screens/GSP/GSPItems/components";
import { TDossierDifferencesModule } from "screens/Project/screens/GSP/GSPItems/GSPItems.utils";
import {
  ApplicationInfo,
  DossierDifferencesInfo,
  ItemRow,
  SponsorInfo,
} from "./components";
import styles from "./GSPViewItem.styles";

export type TGSPViewItemProps = {
  component: TGSPComponent;
  dossierDifferencesModules: TDossierDifferencesModule[];
};

const GSPViewItem = ({
  component,
  dossierDifferencesModules,
}: TGSPViewItemProps) => {
  const { t } = useTranslation("documents", {
    keyPrefix: "gsp.item",
  });

  return (
    <ItemLayout
      HA={component.healthAuthority}
      id={component.id}
      selectedSubmissionPlan={component.metadata.submissionPlan}
    >
      <Box sx={styles.container}>
        {component.metadata.submissionPlan && (
          <Grid container py={2} pl={1}>
            <ItemRow
              name={t("fields.submissionPlan.label")}
              description={t(
                `fields.submissionPlan.optionLabels.${component.metadata.submissionPlan}`,
              )}
            />
          </Grid>
        )}

        <SponsorInfo sponsorInfo={component.metadata.sponsorInfo} />

        {isSubmittingGSPComponent(component) && (
          <>
            <ApplicationInfo
              applicationInfo={component.metadata.applicationInfo}
            />

            {!!dossierDifferencesModules.length && (
              <DossierDifferencesInfo
                parentId={component.id}
                dossierDifferencesModules={dossierDifferencesModules}
              />
            )}
          </>
        )}
      </Box>
    </ItemLayout>
  );
};

export default GSPViewItem;
