import { RequestDocument, Variables } from "graphql-request/src/types";
import { oktaAuth } from "utils/auth";
import { configureRequest } from "../utils/request";
import { getClient } from "./client";

export async function makeGQLRequest<T, V extends Variables = Variables>(
  query: RequestDocument,
  variables?: V,
) {
  return configureRequest(() =>
    getClient(oktaAuth).request<T>(query, variables),
  );
}
