import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { SettingsOutlined } from "@mui/icons-material";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { useAnnotationUIContext } from "components/common/Editor/AnnotationUIContext/AnnotationUIContext";
import { onApplyAnnotationFilters } from "components/common/Editor/Editor.utils";
import { FormSwitch } from "components/common/form-fields";
import useUser from "hooks/useUser";
import {
  AnnotationFilterField,
  annotationFilterSchema,
  annotationFilterValues,
  TAnnotationFilterForm,
} from "./AnnotationFilters.types";

type TCommentsActionsProps = {
  includeSuggestions: boolean;
};

const AnnotationFilters = ({ includeSuggestions }: TCommentsActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { t } = useTranslation("documents");

  const { annotationUI } = useAnnotationUIContext();
  const { user: currentUser } = useUser();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const methods = useForm<TAnnotationFilterForm>({
    mode: "all",
    defaultValues: annotationFilterValues,
    resolver: zodResolver(annotationFilterSchema()),
  });

  const { watch, getValues } = methods;

  const showPrivateComments = watch(AnnotationFilterField.ShowPrivateComments);
  const showOnlyMyComments = watch(AnnotationFilterField.ShowOnlyMyComments);
  const showTrackChanges = watch(AnnotationFilterField.ShowTrackChanges);

  useEffect(() => {
    if (annotationUI && currentUser) {
      onApplyAnnotationFilters(annotationUI, getValues(), currentUser?.id);
    }
  }, [
    annotationUI,
    currentUser,
    showPrivateComments,
    showOnlyMyComments,
    showTrackChanges,
    getValues,
  ]);

  return (
    <>
      <IconButton
        id="annotations-filter-button"
        size="small"
        aria-label={t("documents.annotations.buttonLabel")}
        aria-controls={open ? "annotatons-filter-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        data-testid="annotations-filter-dropdown-button"
        data-qaid="annotations-filter-dropdown-button"
      >
        <SettingsOutlined fontSize="inherit" />
      </IconButton>
      <Menu
        id="annotatons-filter-menu"
        MenuListProps={{
          "aria-labelledby": "annotations-filter-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <FormProvider {...methods}>
          <MenuItem>
            <FormSwitch
              data-qaid="my-comments-switch-field"
              name={AnnotationFilterField.ShowOnlyMyComments}
              label={t("documents.annotations.filters.showOnlyMyComments")}
            />
          </MenuItem>
          {includeSuggestions && (
            <>
              <Divider />
              <MenuItem>
                <FormSwitch
                  data-qaid="track-changes-field"
                  name={AnnotationFilterField.ShowTrackChanges}
                  label={t("documents.annotations.filters.showTrackChanges")}
                />
              </MenuItem>
            </>
          )}
        </FormProvider>
      </Menu>
    </>
  );
};

export default AnnotationFilters;
