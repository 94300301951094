import { ContextPlugin } from "ckeditor5";
import { queryClient } from "config/reactQueryClient";
import {
  IOrganization,
  IOrganizationResponse,
} from "models/organizations.models";
import { TUserData } from "models/users.models";
import { services } from "services";
import { MAX_SIZE, QueryAPIKey } from "utils/constants/api.constants";

class CollaborationContextPlugin extends ContextPlugin {
  static get requires() {
    return ["CommentsRepository", "Users"];
  }

  static get pluginName() {
    return "CollaborationContextPlugin";
  }

  async init() {
    const currentUser = await queryClient.fetchQuery<TUserData>(
      [QueryAPIKey.GetCurrentUser],
      () => services.user.getCurrentUser(false).then((res) => res.data),
    );

    const users = await queryClient.fetchQuery<TUserData[]>(
      [QueryAPIKey.GetUsersList],
      () =>
        services.user
          .getUsersList({ showAllOrganizations: true }, false)
          .then((res) => res.data),
    );

    const getOrganizationsParams = { size: MAX_SIZE };

    const { content: organizations } =
      await queryClient.fetchQuery<IOrganizationResponse>(
        [QueryAPIKey.GetOrganizations, getOrganizationsParams],
        () =>
          services.organizations
            .getOrganizations(getOrganizationsParams)
            .then((res) => res.data),
      );

    const indexedOrganizations = organizations.reduce<
      Record<string, IOrganization>
    >((previous, organization) => {
      previous[organization.id] = organization;

      return previous;
    }, {});

    const usersPlugin = this.context.plugins.get("Users");

    users.forEach((user) => {
      const organization = indexedOrganizations[user.org];

      const name =
        user.org === currentUser?.org
          ? `${user.firstName} ${user.lastName}`
          : [
              organization?.country,
              organization?.shortName || organization?.name,
            ].join(" ");

      usersPlugin.addUser({
        ...user,
        name,
      });
    });

    // set the logged-in user as the active user in the editor
    usersPlugin.defineMe(currentUser.id);
  }
}

export default CollaborationContextPlugin;
