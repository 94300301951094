import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import DocumentHeader from "components/common/Document/DocumentHeader";
import { ConfirmModal, Loading } from "components/shared";
import { TDocumentViewMode } from "models/documents.models";
import { TFHIRResource } from "models/fhir.models";
import { DocumentViewMode } from "utils/constants/doc.constants";
import UniversalFHIRResource from "./UniversalFHIRResource";
import styles from "./FHIRContent.styles";

type TFHIRContentProps = {
  FHIRData?: TFHIRResource;
};

const FHIRContent = ({ FHIRData }: TFHIRContentProps) => {
  const { t } = useTranslation(["dataX", "common"]);

  const [shouldShowCancelConfirm, setShouldShowCancelConfirm] =
    useState<boolean>(false);

  const [viewMode, setViewMode] = useState<TDocumentViewMode>(
    DocumentViewMode.Editing,
  );

  const [areFieldsChanged, setAreFieldsChanged] = useState<boolean>(false);

  const handleCancelConfirm = useCallback((shouldConfirm: boolean) => {
    shouldConfirm && setViewMode(DocumentViewMode.Viewing);
    setShouldShowCancelConfirm(false);
  }, []);

  const toggleViewMode = (mode: TDocumentViewMode) => {
    if (viewMode === DocumentViewMode.Editing && areFieldsChanged) {
      setShouldShowCancelConfirm(true);
    } else {
      setViewMode(mode);
    }
  };

  return (
    <Box sx={styles.FHIRContent}>
      <Box>
        <DocumentHeader
          actionOptions={[]}
          viewMode={viewMode}
          onViewModeChange={toggleViewMode}
        />
      </Box>
      <Stack direction="row" sx={styles.documentContainer}>
        <Box sx={styles.documentContent}>
          {FHIRData ? (
            <UniversalFHIRResource
              resource={FHIRData}
              viewMode={viewMode}
              fieldsPristine={!areFieldsChanged}
              onFieldsUpdated={setAreFieldsChanged}
            />
          ) : (
            <Loading />
          )}
        </Box>
      </Stack>
      <ConfirmModal
        open={shouldShowCancelConfirm}
        title={t("FHIRResource.unsavedChangesModal.title", { ns: "dataX" })}
        onConclude={handleCancelConfirm}
        confirmButtonText={t("button.confirm", { ns: "common" })}
        cancelButtonText={t("button.cancel", { ns: "common" })}
      >
        {t("FHIRResource.unsavedChangesModal.body", { ns: "dataX" })}
      </ConfirmModal>
    </Box>
  );
};

export default FHIRContent;
