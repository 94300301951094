import { TFunction } from "i18next";
import { TAssetGraphQL } from "hooks/api/GQL/assets/useAssets.models";
import { IAsset, IAssetMetadata } from "models/assets.models";
import { IGSP, TGSPComponent } from "models/gsp.models";
import { IOrganization } from "models/organizations.models";
import COUNTRIES from "utils/constants/countries.constants";
import { DEFAULT_ORBIS_HOST_HA_TENANT_ID } from "utils/constants/organization.constants";
import { defaultCompare } from "utils/helpers/defaultCompare";

export type THAMap = { [key: string]: IOrganization };

const getHAMap = (HAs: IOrganization[]): THAMap =>
  HAs.reduce((haMap: THAMap, curr) => {
    if (curr.tenantId && !haMap[curr.tenantId]) {
      haMap[curr.tenantId] = {
        ...curr,
      };
    }

    return haMap;
  }, {});

const sortGSPComponents = (components: TGSPComponent[], t: TFunction) => {
  // Sort alphabetically by country name
  const sortedComponents = components.sort((a, b) =>
    defaultCompare(
      t(COUNTRIES[a.healthAuthority.country]?.name, { ns: "countries" }),
      t(COUNTRIES[b.healthAuthority.country]?.name, { ns: "countries" }),
    ),
  );

  // Find FDA component and move to top of list
  const FDAComponentIndex = sortedComponents
    .map((item) => item.metadata.tenantId)
    .indexOf(DEFAULT_ORBIS_HOST_HA_TENANT_ID);

  if (FDAComponentIndex > -1) {
    const FDAComponent = sortedComponents.splice(FDAComponentIndex, 1);

    sortedComponents.unshift(...FDAComponent);
  }

  return sortedComponents;
};

export const transformGSPAssets = (
  GSPAsset: IAsset,
  GSPAssets: TAssetGraphQL<IAssetMetadata>[],
  HAs: IOrganization[],
  t: TFunction,
): IGSP => {
  const HAMap = getHAMap(HAs);

  const gspComponents = GSPAssets.reduce((comps: TGSPComponent[], asset) => {
    const tenantId = asset.metadata.tenantId;

    if (tenantId && HAMap) {
      const HAData = HAMap[tenantId];

      comps.push({
        id: asset.id,
        parentId: GSPAsset.id,
        type: asset.type,
        name: asset.name,
        status: asset.status,
        healthAuthority: {
          id: HAData.id,
          tenantId: HAData.tenantId,
          name: HAData.name,
          shortName: HAData.shortName || HAData.name,
          country: HAData?.country,
        },
        metadata: {
          ...asset.metadata,
        },
      });
    }

    return comps;
  }, []);

  return {
    id: GSPAsset.id,
    name: GSPAsset.name,
    status: GSPAsset.status,
    components: sortGSPComponents(gspComponents, t),
  };
};
