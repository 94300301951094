import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    "p:first-of-type": {
      mt: 0,
    },
  },

  orderedList: {
    ml: 7,
  },

  listItem: {
    py: 1,
  },
};

export default styles;
