import { Suspense } from "react";
import { Outlet } from "react-router";
import { Box, Stack } from "@mui/material";
import { MainLayoutErrorBoundary } from "components/common/ErrorBoundaries";
import GlobalNavigation from "components/GlobalNavigation";
import Loading from "components/shared/Loading";
import { JIRA_BUTTON_SIZE } from "theme/theme.constants";
import useGlobalNavigation from "./useGlobalNavigation";
import styles from "./MainLayout.styles";

const MainLayout = () => {
  const { isGlobalNavigationVisible } = useGlobalNavigation();

  return (
    <Stack spacing={0} direction="row">
      {isGlobalNavigationVisible && <GlobalNavigation />}
      <Box
        component="main"
        sx={styles.content}
        pb={isGlobalNavigationVisible ? JIRA_BUTTON_SIZE : 0}
      >
        <Suspense fallback={<Loading />}>
          <MainLayoutErrorBoundary>
            <Outlet />
          </MainLayoutErrorBoundary>
        </Suspense>
      </Box>
    </Stack>
  );
};

export default MainLayout;
