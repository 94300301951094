import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { TFilteringParams } from "components/shared/types/filter";
import { getGenericDateConstraint } from "utils/validators/constraints/validationConstraints";
import {
  DateRange,
  DateRangeKey,
  dateRangeSchema,
  TDateRangeFilterSchema,
} from "./DateRange";

export type TDateRangeProps = {
  datesMeta: TDateRangeFilterSchema;
  selectedFilters?: TFilteringParams;
  updateFilter: (filters: TFilteringParams) => void;
};

export const DateRangeFilter = ({
  datesMeta,
  selectedFilters,
  updateFilter,
}: TDateRangeProps) => {
  const startDate = datesMeta[DateRangeKey.StartDate];
  const endDate = datesMeta[DateRangeKey.EndDate];

  const { t } = useTranslation("common");

  const methods = useForm({
    resolver: zodResolver(
      dateRangeSchema(
        startDate.filterKey,
        endDate.filterKey,
        t("validations.invalidStartDate", { ns: "common" }),
        t("validations.invalidEndDate", { ns: "common" }),
        getGenericDateConstraint(t).nullish(),
      ),
    ),
    mode: "all",
    defaultValues: {
      [startDate.filterKey]: selectedFilters?.[startDate.filterKey]?.[0],
      [endDate.filterKey]: selectedFilters?.[endDate.filterKey]?.[0],
    },
  });

  const { getValues, trigger, formState } = methods;

  const dateValues = getValues();

  const handleFilterChange = async (key: string, value: Date | null) => {
    await trigger([startDate.filterKey, endDate.filterKey]);

    const isDateValid = !formState.errors[key];

    if (!value || !isDateValid) {
      return updateFilter({ [key]: [] });
    }

    const newFilters = {
      [key]: [value.toString()],
    };

    const secondFilterKey = Object.keys(dateValues).find(
      (dateKey) => dateKey !== key,
    );

    if (secondFilterKey) {
      const secondFilterValue = dateValues[secondFilterKey];
      const isSecondDateValid = !formState.errors[secondFilterKey];

      if (secondFilterValue && isSecondDateValid) {
        newFilters[secondFilterKey] = [
          dateValues[secondFilterKey]?.toString() || "",
        ];
      }
    }

    return isDateValid && updateFilter(newFilters);
  };

  return (
    <FormProvider {...methods}>
      <DateRange
        startDateName={startDate.filterKey}
        startDateLabel={startDate.label}
        endDateName={endDate.filterKey}
        endDateLabel={endDate.label}
        onValueChange={handleFilterChange}
      />
    </FormProvider>
  );
};
