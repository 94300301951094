import { TFunction } from "i18next";
import { z } from "zod";
import { EntityStatus } from "models/entities.models";
import { getEntityOrganizationRoles } from "screens/EntityManagement/utils/getEntityOrganizationRoles";
import { mapRoleTypesToSelectedOrgs } from "screens/EntityManagement/utils/organizationRoleTypes.utils";
import { MEMBER_SCHEMA } from "utils/validators/constraints/validationConstraints";
import {
  ProductEntityFieldName,
  TProductEntityForm,
} from "../ProductEntityFormFields/ProductEntityFormFields.types";
import { TEntityProduct } from "../Products.types";

export const getCreateProductModalSchema = (
  t: TFunction,
  isEditMode: boolean,
) =>
  z.object({
    [ProductEntityFieldName.ProductName]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductEntityFieldName.NonproprietaryName]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductEntityFieldName.LinkedSubstances]: z.array(MEMBER_SCHEMA).min(0, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductEntityFieldName.LinkedProductFamilies]: z
      .array(MEMBER_SCHEMA)
      .optional(),
    [ProductEntityFieldName.LinkedOrganizations]: z
      .array(z.string())
      .optional(),
    [ProductEntityFieldName.Status]: !isEditMode
      ? z.string().min(1, {
          message: t("validations.requiredField", { ns: "common" }),
        })
      : z.string().optional(),
  });

export const getProductValuesForUpdate = (
  product: TEntityProduct,
  formValues: TProductEntityForm,
) => {
  return {
    id: product.id,
    ownerTenantId: product.ownerTenantId ?? "",
    tradeName: formValues[ProductEntityFieldName.ProductName].trim(),
    nonproprietaryName:
      formValues[ProductEntityFieldName.NonproprietaryName].trim(),
    substanceIds:
      formValues[ProductEntityFieldName.LinkedSubstances]?.map(
        (linkedSubstance) => linkedSubstance.value,
      ) ?? [],
    entityOrganizationRoles: getEntityOrganizationRoles(
      formValues[ProductEntityFieldName.LinkedOrganizations] ?? [],
    ),
    productFamilyIds:
      formValues[ProductEntityFieldName.LinkedProductFamilies]?.map(
        (linkedProductFamily) => linkedProductFamily.value,
      ) ?? [],
    active: formValues[ProductEntityFieldName.Status] === EntityStatus.Active,
  };
};

export const getFormValuesForExistingProduct = (product?: TEntityProduct) => {
  return {
    [ProductEntityFieldName.ProductName]: product?.tradeName ?? "",
    [ProductEntityFieldName.NonproprietaryName]:
      product?.nonproprietaryName ?? "",
    [ProductEntityFieldName.LinkedSubstances]:
      product?.substances?.map((substance) => ({
        label: substance.name,
        value: substance.id,
      })) || [],
    [ProductEntityFieldName.LinkedProductFamilies]:
      product?.productFamilies?.map((productFamily) => ({
        label: productFamily.name,
        value: productFamily.id,
      })) || [],
    [ProductEntityFieldName.LinkedOrganizations]: mapRoleTypesToSelectedOrgs(
      product?.linkedOrganizations,
    ),
    [ProductEntityFieldName.Status]: product?.status,
  };
};
