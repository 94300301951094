import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  latest: {
    color: getPaletteColor("green.500"),
    ml: 0.5,
  },

  createdDate: {
    ml: 0.5,
    color: getPaletteColor("text.secondary"),
  },
};

export default styles;
