import { Fragment } from "react";
import { AutocompleteRenderGroupParams, Box, Typography } from "@mui/material";
import styles from "../CommonStyles.styles";

const GroupedDropdown = ({
  group,
  children,
}: AutocompleteRenderGroupParams) => (
  <Fragment key={`dropdown-${group}`}>
    <Typography sx={styles.groupLabel}>{group}</Typography>
    <Box sx={styles.selectItem}>{children}</Box>
  </Fragment>
);

export default GroupedDropdown;
