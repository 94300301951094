import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { ProjectStatus } from "utils/constants/project.constants";
import styles from "../ConfirmModalContent.styles";

type TProjectParticipantsSubmissionProps = {
  projectStatus: ProjectStatus | undefined;
};

type TProjectParticipantsSubmissionContent = {
  modalTitle?: string;
  modalTextOutcomes?: string[];
  modalTextCopiedContent?: string[];
};

const ProjectParticipantsSubmission = ({
  projectStatus,
}: TProjectParticipantsSubmissionProps) => {
  const { t } = useTranslation("documents");

  const {
    modalTextOutcomes,
    modalTextCopiedContent,
  }: TProjectParticipantsSubmissionContent =
    projectStatus === ProjectStatus.Planned
      ? t("importDocument.projectParticipantsFirstSubmissionConfirmModal", {
          returnObjects: true,
        })
      : t("importDocument.projectParticipantsSubmissionConfirmModal", {
          returnObjects: true,
        });

  return (
    <Box sx={styles.container}>
      {!isEmpty(modalTextOutcomes) && (
        <Box component="ul" sx={styles.unorderedList}>
          {modalTextOutcomes?.map((outcome: string, index: number) => (
            <Box component="li" key={index} sx={styles.listItem}>
              {outcome}
            </Box>
          ))}
        </Box>
      )}
      {!isEmpty(modalTextCopiedContent) && (
        <Box component="ol" sx={styles.orderedList}>
          {modalTextCopiedContent?.map((content: string, index: number) => (
            <Box component="li" key={index} sx={styles.listItem}>
              {content}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ProjectParticipantsSubmission;
