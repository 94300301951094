import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { TFHIRResource } from "models/fhir.models";
import { EventType } from "models/tracking.models";
import { services } from "services";
import { AssetType } from "utils/constants/assets.constants";
import { FHIRResourceType } from "utils/constants/fhir.constants";

export type TUploadFHIRResourceMutationData = {
  resourceType: FHIRResourceType;
  fhirData: TFHIRResource;
};

export default function useFHIRResourceUpload(projectId: string) {
  const { t } = useTranslation("dataX", {
    keyPrefix: "notifications",
  });

  const {
    data,
    mutate: uploadFHIRResource,
    isError,
    isLoading,
    status,
  } = useMutation((uploadData: TUploadFHIRResourceMutationData) => {
    const { resourceType, fhirData } = uploadData;

    return services.dataX
      .addResource(resourceType, JSON.stringify(fhirData))
      .then((res) => res.data);
  });

  if (data) {
    services.tracking.addTrackingEvent({
      assetType: AssetType.Document,
      assetId: data.id,
      projectId: projectId || "",
      eventType: EventType.AppContentImport,
    });
  }

  useShowAlert({
    status,
    failureMessage: t("uploadFHIRResourceFailure"),
  });

  return { data, uploadFHIRResource, isError, isLoading };
}
