import { getPaletteColor } from "theme/helpers";
import { TStylesObject } from "utils/Styles";
import { focusOutlineStyle } from "styles/accessibility.styles";

const styles = {
  rowAlign: {
    "& .MuiButtonBase-root.MuiIconButton-root": {
      my: -1.5,
    },
  },

  rowAlignTop: {
    verticalAlign: "top",
  },

  rowAlignMiddle: {
    verticalAlign: "middle",
  },

  rowAlignBottom: {
    verticalAlign: "bottom",
  },

  row: {
    "&.MuiTableRow-hover:hover": {
      background: getPaletteColor("custom.hover"),
    },

    ":focus-visible": {
      outline: focusOutlineStyle,
    },
  },

  rowClickable: {
    cursor: "pointer",
  },

  hiddenTableCell: {
    "&.MuiTableCell-root.MuiTableCell-body": {
      paddingRight: "0px",
      paddingLeft: "0px",
    },
  },

  groupedRowContainer: {
    paddingLeft: "24px",
    alignItems: "center",

    "& .MuiSvgIcon-root": {
      marginRight: "10px",
    },
  },

  rowDataCount: {
    marginLeft: "5px",
  },

  rowDisabled: {
    pointerEvents: "none",

    "& .MuiTableCell-body": {
      color: "text.disabled",
    },
  },

  rowExpandable: {
    background: getPaletteColor("grey.300"),
  },

  rowExpandedContent: {
    background: getPaletteColor("common.white"),
  },
} satisfies TStylesObject;

export default styles;
