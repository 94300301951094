import { Component, PropsWithChildren } from "react";

export type TFallbackRenderer = (props: { error: Error | null }) => JSX.Element;

type TErrorBoundaryProps = PropsWithChildren<{
  fallbackRenderer: TFallbackRenderer;
  locationKey: string;
}>;

type TErrorBoundaryState = {
  hasError: boolean;
  error: Error | null;
};

/**
 * Shared error boundary component.
 *
 * Error boundaries do NOT catch errors for:
 *   - Event handlers (learn more)
 *   - Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
 *   - Server side rendering
 *   - Errors thrown in the error boundary itself (rather than its children)
 *
 * @link https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends Component<
  TErrorBoundaryProps,
  TErrorBoundaryState
> {
  state: TErrorBoundaryState = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error: Error | null): TErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  clearErrorState = () => {
    if (this.state.hasError) {
      this.setState({ hasError: false, error: null });
    }
  };

  componentDidUpdate(
    prevProps: TErrorBoundaryProps,
    _previousState: TErrorBoundaryState,
  ) {
    if (this.props.locationKey !== prevProps.locationKey) {
      this.clearErrorState();
    }
  }

  render() {
    return this.state.hasError
      ? this.props.fallbackRenderer({ error: this.state.error })
      : this.props.children;
  }
}

export default ErrorBoundary;
