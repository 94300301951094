import { useTranslation } from "react-i18next";
import HealthAuthority from "components/common/HealthAuthority";
import { getHAName } from "components/common/HealthAuthority/HealthAuthority.utils";
import StatusChip from "components/shared/StatusChip";
import { IGSPHealthAuthority } from "models/gsp.models";
import { SubmissionPlan } from "utils/constants/gsp.constants";
import { getStatusChipConfig } from "utils/helpers/getStatusChipConfig";

type THealthAuthorityHeadingProps = {
  HA: IGSPHealthAuthority;
  selectedSubmissionPlan?: SubmissionPlan | "";
};

const HealthAuthorityHeading = ({
  HA,
  selectedSubmissionPlan,
}: THealthAuthorityHeadingProps) => {
  const { t } = useTranslation(["user", "countries", "statuses"]);

  return (
    <>
      <HealthAuthority
        country={HA.country}
        name={getHAName(HA.country, HA.shortName, t)}
      />
      {selectedSubmissionPlan && (
        <StatusChip
          status={selectedSubmissionPlan}
          chipProps={getStatusChipConfig({ status: selectedSubmissionPlan, t })}
          isIconVisible
        />
      )}
    </>
  );
};

export default HealthAuthorityHeading;
