import { ButtonProps } from "@mui/material";
import Button from "components/shared/Button";
import Modal, { TModalProps } from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import Loading from "../Loading";

export type TConfirmModalProps = Omit<TModalProps, "buttons" | "onClose"> & {
  isConfirmButtonDisabled?: boolean;
  onConclude?: (result: boolean) => void;
  confirmColor?: ButtonProps["color"];
  confirmButtonText: string;
  cancelButtonText: string;
  isConfirmButtonLoading?: boolean;
};

const ConfirmModal = ({
  isConfirmButtonDisabled = false,
  onConclude,
  confirmColor = "error",
  confirmButtonText,
  cancelButtonText,
  isConfirmButtonLoading,
  ...props
}: TConfirmModalProps) => {
  const handleCancelClick = () => {
    onConclude?.(false);
  };

  const cancelButton = (
    <Button
      key="Cancel"
      variant="text"
      onClick={handleCancelClick}
      data-qaid="cancel-button"
    >
      {cancelButtonText}
    </Button>
  );

  const handleConfirmClick = () => {
    onConclude?.(true);
  };

  const confirmButton = (
    <Button
      color={confirmColor}
      disabled={isConfirmButtonDisabled}
      key="Confirm"
      variant="contained"
      onClick={handleConfirmClick}
      data-qaid="confirm-button"
    >
      {isConfirmButtonLoading ? <Loading /> : confirmButtonText}
    </Button>
  );

  return (
    <Modal data-qaid="confirm-modal" {...props}>
      {props.children}
      <ModalActionButtons buttons={[cancelButton, confirmButton]} />
    </Modal>
  );
};

export default ConfirmModal;
