import { Skeleton } from "@mui/material";
import { TFunction } from "i18next";
import HealthAuthority from "components/common/HealthAuthority";
import useOrganizations from "hooks/api/REST/organizations/useOrganizations";
import { MAX_SIZE } from "utils/constants/api.constants";
import { OrganizationType } from "utils/constants/organization.constants";

type TRecipientProps = {
  tenantId: string;
  t: TFunction;
};

export const Recipient = ({ tenantId, t }: TRecipientProps) => {
  const { organizations, isLoading } = useOrganizations(
    { tenantIds: [tenantId], types: OrganizationType.HA, size: MAX_SIZE },
    !!tenantId,
  );

  const organization = organizations?.content.find(
    (org) => org.tenantId === tenantId,
  );

  const HACountryShortName = organization?.country;
  const HAName = organization?.shortName ?? "";

  const missingInfo = !HACountryShortName || !HAName;

  if (isLoading) {
    return <Skeleton />;
  }

  if (missingInfo) {
    return <>{t("documents.shareHistory.missingInfo")}</>;
  }

  return (
    <HealthAuthority
      country={HACountryShortName}
      name={`${HACountryShortName} - ${HAName}`}
    />
  );
};
