import { IHAQWithContent } from "hooks/api/GQL/haq/useHAQsWithContent.models";
import { IHAQ } from "models/HAQ.models";
import { IOrganizationResponse } from "models/organizations.models";
import { getOrganizationByTenantId } from "utils/helpers";

const getFormattedInterestedPHAs = (
  HAQ: IHAQWithContent | IHAQ,
  organizations: IOrganizationResponse,
) =>
  HAQ?.metadata?.invitedHealthAuthorityTenants?.reduce(
    (haTenantIds: string[], haTenant) => {
      if (haTenant.registeredInterest) {
        const HAOrganization = getOrganizationByTenantId(
          organizations?.content,
          haTenant?.tenantId,
        );

        return [...haTenantIds, HAOrganization?.shortName || haTenant.tenantId];
      } else {
        return haTenantIds;
      }
    },
    [],
  ) || [];

export default getFormattedInterestedPHAs;
