import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  documentImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "21px",
  },

  arrow: {
    color: getPaletteColor("blueGrey.300"),
    fontSize: 32,
  },
};

export default styles;
