import {
  IAsset,
  IAssetContent,
  IAssetMetadata,
  IAssetSource,
} from "models/assets.models";
import {
  TAssetContentId,
  TAssetId,
  TLatestVersion,
} from "models/documents.models";
import { AssetStatus, AssetType } from "utils/constants/assets.constants";
import { DocumentComponentStatus } from "utils/constants/doc.constants";
import { HAQStatus } from "utils/constants/HAQ.constants";

export type TAssetWithContent<T> = {
  assetId: TAssetId;
  assetContentId: TAssetContentId;
  order?: number;
  createdBy: string;
  parentId: string;
  name: string;
  status: DocumentComponentStatus | HAQStatus | AssetStatus;
  headerText: string;
  type: AssetType;
  children?: string[];
  content: IAssetContent;
  metadata: T;
  latestVersion?: TLatestVersion;
  source: IAssetSource;
};

export const mergeAssetWithContent = <T = IAssetMetadata>(
  asset: IAsset<T> | undefined,
  assetContent: IAssetContent | undefined,
  relatedEntityName?: string,
): TAssetWithContent<T> | undefined => {
  if (!asset || !assetContent) {
    return;
  }

  return {
    ...asset,
    assetId: assetContent?.assetId,
    assetContentId: assetContent?.id || "",
    parentId: asset?.id || "",
    content: assetContent,
    status: asset?.status as DocumentComponentStatus,
    name: asset?.name || "",
    headerText: relatedEntityName || "",
  } as TAssetWithContent<T>;
};
