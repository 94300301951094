import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  strengthContainer: {
    pr: 2,
    pl: 2,
    pt: 2,
    backgroundColor: getPaletteColor("grey.50"),

    "& .MuiFormControl-root": {
      backgroundColor: getPaletteColor("common.white"),
    },
  },
};

export default styles;
