import { PropsWithChildren, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import { useOktaAuth } from "@okta/okta-react";
import isFunction from "lodash/isFunction";
import { TAccessCheck } from "components/Auth/accessChecks";
import { GenericFallback } from "components/common/ErrorBoundaries";
import useFeatureFlags from "context/FeatureFlagsContext/useFeatureFlags";
import useUser from "hooks/useUser";

type TGuardProps = PropsWithChildren<{
  check: TAccessCheck;
  onDenied?: () => ReactElement | void;
}>;

export const Guard = ({ check, onDenied }: TGuardProps): ReactElement => {
  const { t } = useTranslation("auth");
  const user = useUser();
  const { authState } = useOktaAuth();
  const featureFlags = useFeatureFlags();

  const isAccessGranted = check({
    featureFlags,
    user,
    authState,
  });

  if (isAccessGranted) {
    return <Outlet />;
  }

  if (isFunction(onDenied)) {
    return onDenied() ?? <></>;
  }

  return <GenericFallback message={t("noAccess")} />;
};
