import { matchRoutes } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { Avatar, Tooltip } from "components/shared";
import { Path } from "router/paths";
import { parsePath } from "utils/helpers";
import { EMPTY_LABEL } from "utils/helpers";
import { TEntityManagementTab } from "./EntityManagement.types";
import styles from "./EntityManagement.styles";

export const getTabValue = (path: string) =>
  parsePath(path).pop()?.toLowerCase();

export const getCurrentTabValue = (
  projectTabs: TEntityManagementTab[],
  pathname: string,
) => {
  const matches = matchRoutes(projectTabs, pathname);
  const path = isEmpty(matches) ? Path.AdminEntityManagement : pathname;

  return getTabValue(path);
};

export const renderOrganizations = (
  organizations: { id: string; name: string }[],
) => {
  const avatars = organizations.map((linkedOrg) => ({
    id: linkedOrg.id,
    tooltip: linkedOrg.name,
    value: linkedOrg.name,
  }));

  if (isEmpty(avatars)) {
    return <Typography variant="body2">{EMPTY_LABEL}</Typography>;
  }

  return avatars.length === 1 ? (
    <Typography variant="body2">{avatars[0].value}</Typography>
  ) : (
    <Stack direction="row">
      <Typography variant="body2">{avatars[0].value}</Typography>
      <Tooltip
        title={
          <>
            {avatars.slice(1).map((avatar, avatarIndex) => (
              <Typography key={avatarIndex} fontSize={11} mt={1}>
                {avatar.tooltip || avatar.value}
              </Typography>
            ))}
          </>
        }
      >
        <Avatar sx={styles.linkedOrgAvatar}>{`+${avatars.length - 1}`}</Avatar>
      </Tooltip>
    </Stack>
  );
};
