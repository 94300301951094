import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { gql } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { TCreateProductEntityMutationData } from "./useCreateProductEntity.models";

export const query = gql`
  mutation CreateProductWithVariants(
    $productWithVariants: ProductWithVariantsInput
  ) {
    createProductWithVariants(productWithVariants: $productWithVariants) {
      id
    }
  }
`;

const useCreateProductEntity = () => {
  const { t } = useTranslation("administration", {
    keyPrefix: "notifications",
  });

  const { showAlert } = useAlerts();
  const queryClient = useQueryClient();

  const { data, isError, isLoading, isSuccess, mutate } = useMutation(
    ({ productWithVariants }: TCreateProductEntityMutationData) =>
      services.graphQL
        .request(query, {
          productWithVariants,
        })
        .then(() => {
          showAlert({
            message: t("createProductSuccess"),
            severity: AlertSeverity.Success,
          });

          queryClient.invalidateQueries(QueryAPIKey.GetEntityProducts);
        })
        .catch((error) => {
          if (error.response?.errors[0]?.extensions?.response?.status === 409) {
            showAlert({
              message: t("createProductEntityFailureAlreadyExists"),
              severity: AlertSeverity.Error,
            });

            throw error;
          }

          showAlert({
            message: t("createProductFailure"),
            severity: AlertSeverity.Error,
          });

          throw error;
        }),
  );

  return { data, isLoading, isError, isSuccess, createProduct: mutate };
};

export default useCreateProductEntity;
