import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Table } from "components/shared";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { IDocument } from "models/documents.models";
import {
  getColumns,
  getDocumentSubmissionHistoryForRender,
} from "./SubmissionHistory.utils";
import styles from "./SubmissionHistory.styles";

type TSubmissionHistoryProps = {
  documents: IDocument[];
  onClose: () => void;
};

const SubmissionHistory = ({ documents, onClose }: TSubmissionHistoryProps) => {
  const { t } = useTranslation(["documents", "statuses", "common"]);

  const rows = useMemo(
    () => getDocumentSubmissionHistoryForRender(documents, t),
    [documents, t],
  );

  const buttons = [
    <Button color="primary" key="close" variant="contained" onClick={onClose}>
      {t("button.close", { ns: "common" })}
    </Button>,
  ];

  return (
    <Modal open title={t("documents.submissionHistory.modalTitle")}>
      <Table
        sx={styles.submissionHistory}
        columns={getColumns(t)}
        rows={rows}
      />
      <ModalActionButtons buttons={buttons} />
    </Modal>
  );
};

export default SubmissionHistory;
