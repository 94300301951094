import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  chip: {
    maxWidth: "130px",
    backgroundColor: getPaletteColor("grey.200"),
    borderRadius: 3,
    p: 0.5,
    m: 0.5,
    justifyContent: "space-between",
  },

  unremovableChip: {
    maxWidth: "65px",
    mx: 0.5,
    my: 0,
  },
};

export default styles;
