import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { AddHAQDrawer } from "components/common/HAQ";
import { useProject } from "context/ProjectContext";
import useAsset from "hooks/api/REST/assets/useAsset";
import useGetWorkflowExecution from "hooks/api/REST/workflow/useGetWorkflowExecution";
import usePermissions from "hooks/permissions";
import { IDocument } from "models/documents.models";
import { EventType } from "models/tracking.models";
import {
  IWorkflowExecution,
  WorkflowExecutionStatus,
} from "models/workflow.models";
import { useDocumentsContext } from "screens/Project/sections/Documents/DocumentsContext";
import { DocumentInformation } from "screens/Project/sections/Documents/drawers/DocumentInformation";
import { getCanDocumentBePreviewed } from "screens/Project/sections/Documents/utils/documentActions";
import { services } from "services";
import {
  DEFAULT_POLLING_INTERVAL,
  QueryAPIKey,
} from "utils/constants/api.constants";
import { AssetType } from "utils/constants/assets.constants";
import { DocumentAction, DocumentStatus } from "utils/constants/doc.constants";
import { useChangeDocumentStatus } from "../ChangeDocumentStatus/useChangeDocumentStatus";
import {
  ChangeDocumentName,
  ChangeDocumentStatus,
  DeleteDocument,
  DeleteDocuments,
  DocumentShareHistory,
  PreviewDocument,
  SendCopyDocuments,
  ShareDocument,
  SubmissionHistory,
  SubmitDocuments,
} from "../index";
import { useSubmitDocuments } from "../SubmitDocuments/useSubmitDocuments";

export const DocumentsActionModals = () => {
  // This preloads the translation files that the modals will use, since fetching translations
  // causes weird things to happen with the interaction between menus and drawers/modals.
  useTranslation(["HAQ", "common", "annotations"]);

  const { project } = useProject();

  const [searchParams, setSearchParams] = useSearchParams();

  const { permissions } = usePermissions(project?.id ?? "");

  const documentId = searchParams.get("documentId");

  const { asset: document } = useAsset(documentId ?? "");

  const queryClient = useQueryClient();

  const {
    currentActionOnDocument: { documents, documentAction, targetStatus },
    setCurrentActionOnDocument,
  } = useDocumentsContext();

  const onClose = () =>
    setCurrentActionOnDocument({ documents: [], documentAction: null });

  const {
    workflowInProgressId: changeStatusWorkflowInProgressId,
    setWorkflowInProgressId: setChangeStatusWorkflowInProgressId,
    initiateWorkflow: initiateChangeStatusWorkflow,
  } = useChangeDocumentStatus();

  const {
    workflowInProgressId: submitDocumentsWorkflowInProgressId,
    setWorkflowInProgressId: setSubmitDocumentsWorkflowInProgressId,
    initiateWorkflow: initiateSubmitDocumentsWorkflow,
  } = useSubmitDocuments(onClose);

  useEffect(() => {
    if (documentId && document) {
      if (
        getCanDocumentBePreviewed(document as unknown as IDocument, permissions)
      ) {
        isEmpty(documents) &&
          setCurrentActionOnDocument({
            documents: [document as unknown as IDocument],
            documentAction: DocumentAction.Preview,
          });
      } else {
        setSearchParams();
      }
    }
  }, [
    documents,
    documentId,
    document,
    setCurrentActionOnDocument,
    setSearchParams,
    permissions,
  ]);

  useGetWorkflowExecution(
    changeStatusWorkflowInProgressId ??
      submitDocumentsWorkflowInProgressId ??
      "",
    {
      enabled: Boolean(
        changeStatusWorkflowInProgressId || submitDocumentsWorkflowInProgressId,
      ),
      refetchInterval: (data) => {
        return data?.status === WorkflowExecutionStatus.Complete ||
          data?.status === WorkflowExecutionStatus.Failed
          ? false
          : DEFAULT_POLLING_INTERVAL;
      },
      onSuccess: (workflowInfoResponse: IWorkflowExecution) => {
        if (workflowInfoResponse?.status === WorkflowExecutionStatus.Complete) {
          queryClient.invalidateQueries(QueryAPIKey.GetDocuments);
        }

        if (
          workflowInfoResponse?.status === WorkflowExecutionStatus.Complete ||
          workflowInfoResponse?.status === WorkflowExecutionStatus.Failed
        ) {
          if (changeStatusWorkflowInProgressId) {
            setChangeStatusWorkflowInProgressId(null);
          }

          if (submitDocumentsWorkflowInProgressId) {
            setSubmitDocumentsWorkflowInProgressId(null);
          }
        }
      },
    },
  );

  return documentAction ? (
    <>
      {documentAction === DocumentAction.Submit && (
        <SubmitDocuments
          onSubmitUnstructuredDocs={initiateSubmitDocumentsWorkflow}
          projectId={project?.id}
          documents={documents}
          onClose={onClose}
        />
      )}
      {documentAction === DocumentAction.Delete && (
        <>
          {documents?.length > 1 ? (
            <DeleteDocuments documents={documents} onClose={onClose} />
          ) : (
            <DeleteDocument document={documents[0]} onClose={onClose} />
          )}
        </>
      )}

      {documentAction === DocumentAction.Preview && (
        <PreviewDocument
          document={documents[0]}
          onClose={() => {
            setSearchParams();
            onClose();
          }}
        />
      )}
      {documentAction === DocumentAction.Share && (
        <ShareDocument
          projectId={project?.id}
          documents={documents}
          onClose={onClose}
        />
      )}
      {documentAction === DocumentAction.ChangeStatus && (
        <ChangeDocumentStatus
          action={{
            documents: documents,
            targetStatus: targetStatus as DocumentStatus,
          }}
          onClose={onClose}
          onSubmitStatusChange={(documents, targetStatus, onClose) => {
            services.tracking.addBulkTrackingEvent(
              documents.map((document) => ({
                assetId: document.id,
                assetType: AssetType.Document,
                eventType: EventType.AppContentChangeStatus,
                projectId: project?.id || "",
              })),
            );

            initiateChangeStatusWorkflow(documents, targetStatus, onClose);
          }}
        />
      )}
      {documentAction === DocumentAction.ChangeName && (
        <ChangeDocumentName document={documents[0]} onClose={onClose} />
      )}
      {documentAction === DocumentAction.ViewShareHistory && (
        <DocumentShareHistory document={documents[0]} onClose={onClose} />
      )}
      {documentAction === DocumentAction.SendCopy && (
        <SendCopyDocuments
          projectId={project?.id}
          documents={documents}
          onClose={onClose}
        />
      )}
      {documentAction === DocumentAction.ViewContentInfo && (
        <DocumentInformation
          projectId={project?.id}
          document={documents[0]}
          handlePreviewDocument={() =>
            setCurrentActionOnDocument({
              documents,
              documentAction: DocumentAction.Preview,
            })
          }
          onClose={onClose}
        />
      )}
      {documentAction === DocumentAction.AddQuestion && (
        <AddHAQDrawer
          isOpen
          onClose={onClose}
          projectId={project?.id || ""}
          parent={documents[0]}
        />
      )}
      {documentAction === DocumentAction.ViewSubmitHistory && (
        <SubmissionHistory documents={documents} onClose={onClose} />
      )}
    </>
  ) : null;
};
