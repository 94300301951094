import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { ICreateSuggestionRequestData } from "models/annotations.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export type TSuggestionForCreate = {
  componentId: string;
  data: ICreateSuggestionRequestData;
};

const useAddSuggestion = () => {
  const queryClient = useQueryClient();

  const { t } = useTranslation("annotations", { keyPrefix: "notifications" });

  const addSuggestionMutation = useMutation(
    (mutationData: TSuggestionForCreate) => {
      const { componentId, data } = mutationData;

      return services.documents.suggestions.createSuggestion(componentId, data);
    },
    {
      onSuccess: (data, { componentId }) => {
        queryClient.invalidateQueries([QueryAPIKey.GetSuggestion, componentId]);
      },
    },
  );

  useShowAlert({
    status: addSuggestionMutation.status,
    failureMessage: t("createSuggestionFailure"),
  });

  return addSuggestionMutation;
};

export default useAddSuggestion;
