import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/material";
import { FormSwitch, FormTextInput } from "components/common/form-fields";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { ICreateFeatureFlagRequestParams } from "models/featureFlags.models";
import {
  FeatureFlagModalField,
  featureFlagModalValues,
  getFlagFromSchema,
  TFeatureFlagModalForm,
} from "../FeatureFlagModal.types";

type TAddFlagProps = {
  onClose: () => void;
  createFlag: (data: ICreateFeatureFlagRequestParams) => void;
};

export const AddFlag = (props: TAddFlagProps) => {
  const { t } = useTranslation(["featureFlags", "common"]);

  const methods = useForm<TFeatureFlagModalForm>({
    mode: "all",
    defaultValues: featureFlagModalValues,
    resolver: zodResolver(getFlagFromSchema(t)),
  });

  const {
    handleSubmit,
    watch,
    formState: { isValid },
  } = methods;

  const valueTypeStringField = watch(FeatureFlagModalField.IsValueString);

  const onSubmit = async (data: TFeatureFlagModalForm) => {
    const flagValue = data.isValueString ? data.value : data.isActive;
    props.createFlag({
      name: data.name,
      description: data.description,
      value: `${flagValue}`,
    });
    props.onClose();
  };

  const closeButton = (
    <Button variant="outlined" key="close" onClick={props.onClose}>
      {t("button.close", { ns: "common" })}
    </Button>
  );

  const createFlagButton = (
    <Button
      variant="contained"
      disabled={!isValid}
      key="create-flag"
      type="submit"
    >
      {t("addFlag.createFlagButton")}
    </Button>
  );

  return (
    <Modal open title={t("addFlag.modalTitle")}>
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <FormTextInput
            name={FeatureFlagModalField.Name}
            label={t("addFlag.controllerFlagNameLabel")}
          />
          <FormTextInput
            name={FeatureFlagModalField.Description}
            label={t("addFlag.controllerFlagDescriptionLabel")}
            multiline
            rows={3}
          />
          <FormSwitch
            label={t("addFlag.valueTypeLabel")}
            name={FeatureFlagModalField.IsValueString}
          />
          {valueTypeStringField ? (
            <FormTextInput
              name={FeatureFlagModalField.Value}
              label={t("addFlag.controllerFlagValueLabel")}
            />
          ) : (
            <FormSwitch
              name={FeatureFlagModalField.IsActive}
              label={t("addFlag.activeLabel")}
            />
          )}
        </Stack>
        <ModalActionButtons buttons={[closeButton, createFlagButton]} />
      </FormWrapper>
    </Modal>
  );
};

export default AddFlag;
