import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Divider, Grid } from "@mui/material";
import { FormSelect, FormTextInput } from "components/common/form-fields";
import { Button, FormWrapper, ModalActionButtons } from "components/shared";
import Modal from "components/shared/Modal";
import useUpdateProductFamily from "hooks/api/GQL/entityManagement/useUpdateProductFamily";
import { EntityStatus } from "models/entities.models";
import {
  ProductFamilyEntityFieldName,
  TProductFamilyEntityForm,
} from "../CreateProductFamilyModal/CreateProductFamilyModal.types";
import {
  getCreateProductFamilyModalSchema,
  getStatusFieldOptions,
} from "../CreateProductFamilyModal/CreateProductFamilyModal.utils";
import { TProductFamily } from "../ProductFamily.types";

type TEditProductFamilyModalProps = {
  onClose: () => void;
  selectedProductFamily: TProductFamily;
};

const getProductFamilyFormValuesForEdit = (
  selectedProductFamily: TProductFamily,
): TProductFamilyEntityForm => ({
  [ProductFamilyEntityFieldName.Name]: selectedProductFamily?.name ?? "",
  [ProductFamilyEntityFieldName.Description]:
    selectedProductFamily?.description ?? "",
  [ProductFamilyEntityFieldName.Status]: selectedProductFamily?.status ?? "",
});

const EditProductFamilyModal = ({
  onClose,
  selectedProductFamily,
}: TEditProductFamilyModalProps) => {
  const { t } = useTranslation(["administration", "common"]);

  const statusFieldOptions = useMemo(() => getStatusFieldOptions(t), [t]);

  const { updateProductFamily, isLoading } = useUpdateProductFamily();

  const values = getProductFamilyFormValuesForEdit(selectedProductFamily);

  const methods = useForm<TProductFamilyEntityForm>({
    mode: "all",
    values,
    resolver: zodResolver(getCreateProductFamilyModalSchema(t)),
  });

  const {
    formState: { isValid },
    handleSubmit,
  } = methods;

  const handleClose = () => {
    onClose();
  };

  const onSubmit = (formValues: TProductFamilyEntityForm) => {
    const familyId = selectedProductFamily.id;

    const productFamily = {
      id: selectedProductFamily.id,
      name: formValues[ProductFamilyEntityFieldName.Name].trim(),
      description: formValues[ProductFamilyEntityFieldName.Description],
      active:
        formValues[ProductFamilyEntityFieldName.Status] === EntityStatus.Active,
    };

    updateProductFamily({
      familyId,
      productFamily,
    }).then(handleClose);
  };

  const cancelButton = (
    <Button key="cancel" variant="text" onClick={handleClose}>
      {t("button.cancel", { ns: "common" })}
    </Button>
  );

  const saveButton = (
    <Button
      key="save-button"
      variant="contained"
      loading={isLoading}
      disabled={!isValid}
      type="save"
    >
      {t("button.save", { ns: "common" })}
    </Button>
  );

  if (!selectedProductFamily) {
    return <></>;
  }

  return (
    <Modal
      data-testid="edit-product-family-modal"
      data-qaid="edit-product-family-modal"
      title={t("editProductFamilyModalTitle")}
      open
    >
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} rowGap={1} mb={4}>
          <Grid item xs={6}>
            <FormTextInput
              fullWidth
              unregisterOnUnmount={false}
              name={ProductFamilyEntityFieldName.Name}
              label={t("editProductFamilyFormFields.name")}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              fullWidth
              unregisterOnUnmount={false}
              name={ProductFamilyEntityFieldName.Description}
              label={t("editProductFamilyFormFields.description")}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <FormSelect
              fullWidth
              unregisterOnUnmount={false}
              name={ProductFamilyEntityFieldName.Status}
              label={t("editProductFamilyFormFields.status")}
              options={statusFieldOptions}
              disabled={!selectedProductFamily.actions.canChangeStatus}
            />
          </Grid>
        </Grid>
        <ModalActionButtons children={[cancelButton, saveButton]} />
      </FormWrapper>
    </Modal>
  );
};

export default EditProductFamilyModal;
