import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { InfoOutlined } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import qs from "qs";
import DocumentHeader from "components/common/Document/DocumentHeader";
import StandaloneComments from "components/common/StandaloneComments";
import { InfoMenu } from "components/shared";
import ConfirmModal from "components/shared/ConfirmModal";
import GSPProvider from "context/GSPContext/GSPContext";
import useUpdateAssets from "hooks/api/REST/assets/useUpdateAssets";
import { TDocumentViewMode } from "models/documents.models";
import { IGSP } from "models/gsp.models";
import { SendCopyDocuments } from "screens/Project/sections/Documents/modals";
import {
  DocumentStatus,
  DocumentViewMode,
} from "utils/constants/doc.constants";
import GSPItems from "./GSPItems";
import {
  getGSPFormSchema,
  TGSPForm,
} from "./GSPItems/GSPFormItem/GSPForm.types";
import {
  getDefaultValues,
  getGSPUpdatePayload,
  TGSPComponentsMap,
} from "./GSPItems/GSPFormItem/GSPForm.utils";
import { hasSubmissionPlanHAsFilter } from "./GSPItems/GSPItems.utils";
import GSPOutline from "./GSPOutline/GSPOutline";
import GSPSubHeader from "./GSPSubHeader/GSPSubHeader";
import useGSPHeaderActions from "./useGSPHeaderActions";
import { AnnotationsIcon } from "assets/icons";
import styles from "./GSP.styles";

enum GSPInfoMenuType {
  Information = "Information",
  Comments = "Comments",
}

type TGSPProps = {
  GSPData: IGSP;
};

const GSPContent = ({ GSPData }: TGSPProps) => {
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { projectId } = useParams();
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const commentTargetRef = useRef<HTMLElement>(null);

  const { t } = useTranslation([
    "projects",
    "documents",
    "common",
    "annotations", // pre-loaded for comments
  ]);

  const [viewMode, setViewMode] = useState<TDocumentViewMode>(
    DocumentViewMode.Editing,
  );

  useEffect(() => {
    if (
      GSPData?.status === DocumentStatus.Final &&
      viewMode !== DocumentViewMode.Viewing
    ) {
      setViewMode(DocumentViewMode.Viewing);
    }
  }, [GSPData?.status, viewMode]);

  const isEachHAHasSubmissionPlan = GSPData.components.every(
    hasSubmissionPlanHAsFilter,
  );

  const { actionOptions, isOpenSendCopyModal, setIsOpenSendCopyModal } =
    useGSPHeaderActions({
      projectId,
      isEachHAHasSubmissionPlan,
      GSPStatus: GSPData.status,
    });

  const { mutateAsync: updateGSP, isLoading } = useUpdateAssets({
    failureMessage: t("notifications.getGspFailure"),
    successMessage: t("notifications.updateGspSuccess"),
  });

  const methods = useForm<TGSPForm>({
    mode: "all",
    defaultValues: getDefaultValues(GSPData.components),
    resolver: zodResolver(getGSPFormSchema(t)),
  });

  const { formState, handleSubmit, reset } = methods;

  const GSPComponentsMap: TGSPComponentsMap = useMemo(() => {
    return GSPData.components.reduce(
      (componentsMap: TGSPComponentsMap, curr) => {
        componentsMap[curr.id] = curr;

        return componentsMap;
      },
      {},
    );
  }, [GSPData]);

  const handleSave = useCallback(
    (data: TGSPForm) => {
      const payload = getGSPUpdatePayload(data, GSPComponentsMap);

      return updateGSP({ data: payload });
    },
    [GSPComponentsMap, updateGSP],
  );

  const handleCancelConfirm = useCallback((confirm: boolean) => {
    confirm && setViewMode(DocumentViewMode.Viewing);
    setShowCancelConfirm(false);
  }, []);

  const dirtyFieldsLength = Object.keys(
    methods?.formState?.dirtyFields || {},
  ).length;

  //formState.isValid is not giving actual state since phone error is set outside validation schema
  const isSaveDisabled = Object.keys(formState.errors).length;

  const shouldShowSubheader = useMemo(() => {
    return viewMode === DocumentViewMode.Editing && !!dirtyFieldsLength;
  }, [viewMode, dirtyFieldsLength]);

  useEffect(() => {
    reset(getDefaultValues(GSPData.components));
  }, [viewMode, GSPData.components, reset]);

  const toggleViewMode = (mode: TDocumentViewMode) => {
    if (shouldShowSubheader) {
      // If you are switching to view mode, and you've made changes, show confirm modal
      setShowCancelConfirm(true);
    } else {
      setViewMode(mode);
    }
  };

  return (
    <Box sx={styles.GSPContent} ref={commentTargetRef}>
      <Box>
        <DocumentHeader
          activeActionOptionId={GSPData.status}
          actionOptions={actionOptions}
          documentName={GSPData.name}
          viewMode={viewMode}
          isActionsDisabled={GSPData?.status === DocumentStatus.Final}
          onViewModeChange={toggleViewMode}
        />
        {shouldShowSubheader && (
          <GSPSubHeader
            onSave={handleSubmit(handleSave)}
            onCancel={() => setShowCancelConfirm(true)}
            isLoading={isLoading}
            saveDisabled={!!isSaveDisabled}
          />
        )}
      </Box>
      <Stack sx={styles.GSPForms} direction="row" spacing={0}>
        <FormProvider {...methods}>
          {!!GSPData?.components?.length && (
            <GSPProvider
              defaultActiveGSPItem={
                typeof queryParams?.ha === "string" ? queryParams?.ha : ""
              }
            >
              <GSPItems viewMode={viewMode} components={GSPData.components} />
              <InfoMenu
                textButtonHide={t("button.hide", { ns: "common" })}
                textButtonShow={t("button.show", { ns: "common" })}
                infoMenuItems={[
                  {
                    id: GSPInfoMenuType.Information,
                    content: <GSPOutline components={GSPData.components} />,
                    icon: <InfoOutlined fontSize="small" />,
                  },
                  {
                    id: GSPInfoMenuType.Comments,
                    content: (
                      <StandaloneComments
                        assetId={GSPData.id}
                        targetRef={commentTargetRef}
                        // TODO: (AR) When we work on the Orbis project we will need to add permissions
                        getAllowResolving={() => true}
                        allowCreateComments={true}
                        isReadOnly={GSPData?.status === DocumentStatus.Final}
                      />
                    ),
                    icon: <AnnotationsIcon fontSize="small" />,
                  },
                ]}
                openItemId={GSPInfoMenuType.Information}
              />
            </GSPProvider>
          )}
        </FormProvider>
      </Stack>
      <ConfirmModal
        open={showCancelConfirm}
        title={t("gsp.item.unsavedChangesModal.title", { ns: "documents" })}
        onConclude={handleCancelConfirm}
        confirmButtonText={t("button.confirm", { ns: "common" })}
        cancelButtonText={t("button.cancel", { ns: "common" })}
      >
        {t("gsp.item.unsavedChangesModal.body", { ns: "documents" })}
      </ConfirmModal>
      {isOpenSendCopyModal && (
        <SendCopyDocuments
          documents={[{ id: GSPData.id, name: GSPData.name }]}
          onClose={() => setIsOpenSendCopyModal(false)}
          projectId={projectId}
          GSPComponents={GSPData.components}
        />
      )}
    </Box>
  );
};

export default GSPContent;
