import { Navigate, useParams } from "react-router-dom";
import { Path } from "router/paths";

type TRedirectProps = {
  path: Path;
};

const Redirect = ({ path }: TRedirectProps) => {
  const params = useParams();

  const to = Object.keys(params).reduce((to, paramKey) => {
    return to.replace(`:${paramKey}`, params[paramKey] ?? "");
  }, path);

  return <Navigate to={to} replace />;
};

export default Redirect;
