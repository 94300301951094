import { TMultiSelectTypeaheadOption } from "components/shared/MultiSelectTypeahead";
import { IProjectMember } from "models/projectMembers.models";
import { IUserGroupData } from "models/userGroup.models";
import { getFullName } from "utils/helpers";

export enum DropdownGroup {
  Group = "Groups",
  Member = "Members",
}

export const getDisabledOptions = (options: TMultiSelectTypeaheadOption[]) =>
  options.filter((el) => el.disabled);

export const getEnabledSelectedOptions = (
  options: TMultiSelectTypeaheadOption[],
  disabledOptions: TMultiSelectTypeaheadOption[],
) => {
  return options.filter(
    (option) =>
      !option.disabled &&
      !disabledOptions.some(({ value }) => value === option.value),
  );
};

export const getSelectedGroup = (
  memberGroups: IUserGroupData[] | null,
  selectedOptions: TMultiSelectTypeaheadOption[],
) => {
  return (
    memberGroups?.filter((group) =>
      selectedOptions.some((option) => group.id === option.value),
    ) || []
  );
};

export const mapMembers = (
  members: IProjectMember[] | null,
  isDisabled?: boolean,
): TMultiSelectTypeaheadOption[] => {
  return members?.map((member) => mapMember(member, isDisabled)) || [];
};

const mapMember = (member: IProjectMember, isDisabled?: boolean) => ({
  label: getFullName(member),
  value: member.userId,
  type: DropdownGroup.Member,
  disabled: !!isDisabled,
});

export const mapGroups = (memberGroups: IUserGroupData[] | null) => {
  return (
    memberGroups?.map((item) => ({
      label: item.name,
      value: item.id,
      type: DropdownGroup.Group,
      disabled: false,
    })) || []
  );
};

export const mapMembersBySelectedGroup = (
  selectedGroups: IUserGroupData[],
  members: IProjectMember[],
): TMultiSelectTypeaheadOption[] => {
  const selectedGroupsMembers = selectedGroups?.flatMap(
    (group) => group.members,
  );

  return members.map((member) => {
    const isDisabled =
      selectedGroupsMembers?.some(
        (groupMember) => member.userId === groupMember.id,
      ) || false;

    return mapMember(member, isDisabled);
  });
};

export const getCheckedSections = (objects: TMultiSelectTypeaheadOption[]) => {
  return objects.reduce(
    (
      labels: (TMultiSelectTypeaheadOption & { haveChildren?: boolean })[],
      object,
    ) => {
      if (object.checked === "true") {
        labels.push({
          label: object.label,
          value: object.value,
          haveChildren: !!object.children,
        });
      }

      if (object.children) {
        labels.push(...getCheckedSections(object.children));
      }

      return labels;
    },
    [],
  );
};
