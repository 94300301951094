import {
  forwardRef,
  Ref,
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, Box } from "@mui/material";
import isEqual from "lodash/isEqual";
import isString from "lodash/isString";
import CheckboxTree from "components/shared/CheckboxTree";
import {
  flattenOptions,
  getNewSelections,
  ICheckboxOption,
  SELECT_ALL_CHECKBOX_OPTION,
} from "components/shared/CheckboxTree/CheckboxTree.utils";
import TextField from "components/shared/TextField";
import { getIsEmptyString } from "utils/helpers";
import { flatMap } from "utils/helpers/flatMap";
import SelectionChips from "../SelectionChips/SelectionChips";
import {
  TypeaheadChipDisplayMode,
  TypeaheadFilterVariant,
} from "./MultiSelectTypeahead.constants";
import { TMultiSelectTypeaheadProps } from "./MultiSelectTypeahead.types";
import {
  getFilteredOptions,
  getOptionLabel,
} from "./MultiSelectTypeahead.utils";
import styles from "./MultiSelectTypeahead.styles";

const MultiSelectTypeahead = forwardRef(
  <T extends unknown>(
    multiSelectTypeaheadProps: TMultiSelectTypeaheadProps<T>,
    _ref: Ref<any>,
  ) => {
    const rootRef = useRef(null);
    const [inputValue, setInputValue] = useState<string>("");
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const { t } = useTranslation("common");

    const [isRenderedTagsHidden, setIsRenderedTagsHidden] =
      useState<boolean>(false);

    // omitting custom `onChange` & `textFieldProps` props so that it doesn't conflict with the existing `onChange` prop
    const {
      error,
      helperText,
      label,
      onChange,
      options,
      required,
      textFieldProps,
      value = [] as T[],
      noOptionsText = "",
      placeholder,
      openText = "",
      closeText = "",
      selectAllLabel = "",
      expandButtonLabel = "",
      onBlur,
      isSelectAllEnabled = false,
      getValue = (option: ICheckboxOption | undefined) => option?.id as T,
      testId,
      onOpen,
      onClose,
      variant = TypeaheadFilterVariant.Chips,
      renderListHeader,
      componentsProps,
      disableClearable,
      chipDisplayMode = TypeaheadChipDisplayMode.All,
      hideTagsOnOpen = false,
    } = multiSelectTypeaheadProps;

    const parsedValues = useMemo(() => {
      return flattenOptions(options).filter((option) => {
        const optionValue = getValue(option);

        const optionIndex = value.findIndex((selectedOption) =>
          isEqual(optionValue, selectedOption),
        );

        return optionIndex > -1;
      });
    }, [value, getValue, options]);

    const handleSelectionChange = useCallback(
      (option: ICheckboxOption) => {
        if (onChange && value) {
          const newSelections = getNewSelections(parsedValues, option, options);

          onChange(newSelections.map((selection) => getValue(selection)));
        }
      },
      [onChange, value, parsedValues, options, getValue],
    );

    const handleAutocompleteOnChange = useCallback(
      (
        event: SyntheticEvent<Element, Event>,
        value: (string | ICheckboxOption)[],
      ) => {
        if (!isString(value)) {
          onChange?.(
            (value as ICheckboxOption[])?.map((selection) =>
              getValue(selection),
            ),
          );
        }
      },
      [onChange, getValue],
    );

    const selectionChips = useMemo(() => {
      return flatMap(options).filter(
        (option) => value.indexOf(getValue(option)) > -1,
      );
    }, [value, options, getValue]);

    const sxProp = {
      ...styles.autocomplete,
      ...(multiSelectTypeaheadProps.sx || {}),
    };

    const isChipsVariant = variant === TypeaheadFilterVariant.Chips;
    const isChipRemovable = chipDisplayMode === TypeaheadChipDisplayMode.All;

    return (
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        noOptionsText={noOptionsText}
        ListboxProps={{ style: { maxHeight: 250 } }}
        componentsProps={componentsProps}
        limitTags={20}
        openText={openText}
        closeText={closeText}
        sx={sxProp}
        options={
          isSelectAllEnabled
            ? [
                {
                  id: SELECT_ALL_CHECKBOX_OPTION,
                  label: "",
                  value: "",
                  children: options,
                },
              ]
            : options
        }
        ref={rootRef}
        disableCloseOnSelect
        disableClearable={disableClearable}
        value={value}
        isOptionEqualToValue={(option, value) =>
          isEqual(getValue(option), value) || !option.value
        }
        onChange={handleAutocompleteOnChange}
        onOpen={(event) => {
          if (hideTagsOnOpen) {
            setIsRenderedTagsHidden(true);
          }

          onOpen?.(event);
        }}
        onClose={() => {
          if (hideTagsOnOpen) {
            setIsRenderedTagsHidden(false);
          }

          onClose?.();
        }}
        inputValue={inputValue}
        onInputChange={(_, value) => setInputValue(value)}
        getOptionLabel={getOptionLabel}
        renderTags={() => {
          if (isRenderedTagsHidden) {
            return null;
          }

          return isChipsVariant ? (
            <SelectionChips
              chips={selectionChips}
              isRemovable={isChipRemovable}
              onChange={handleSelectionChange}
            />
          ) : (
            <Box
              sx={styles.selectedText}
              key={`${value.length} ${t("selected")}`}
            >
              {value.length} {t("selected")}
            </Box>
          );
        }}
        filterOptions={getFilteredOptions}
        renderOption={(props, option) => (
          <React.Fragment key={option.id}>
            {renderListHeader}
            <CheckboxTree<T>
              options={[option]}
              onChange={onChange}
              values={parsedValues}
              isSelectAllEnabled={isSelectAllEnabled}
              selectAllSectionsLabel={selectAllLabel}
              expandButtonLabel={expandButtonLabel}
              isAllOptionsExpanded={!getIsEmptyString(inputValue)}
              getValue={getValue}
            />
          </React.Fragment>
        )}
        renderInput={({
          InputProps: { startAdornment, ...restInputProps },
          ...restParams
        }) => {
          const inputProps = {
            ...restInputProps,
            "data-testid": testId,
            "data-qaid": testId,
          };

          return (
            <TextField
              {...restParams}
              error={error}
              required={required}
              size="small"
              helperText={helperText}
              onBlur={() => {
                onBlur?.();
                setIsFocused(false);
              }}
              onFocus={() => setIsFocused(true)}
              onKeyDown={(event) => {
                if (!isChipRemovable && event.key === "Backspace") {
                  event.stopPropagation();
                }
              }}
              label={label}
              placeholder={placeholder}
              {...textFieldProps}
              InputLabelProps={{
                shrink:
                  isFocused ||
                  !getIsEmptyString(inputValue) ||
                  value.length > 0,
              }}
              InputProps={{
                ...inputProps,
                startAdornment: (
                  <Box sx={styles.startAdornment}>{startAdornment}</Box>
                ),
              }}
              FormHelperTextProps={{
                variant: "standard",
                "aria-live": "polite",
              }}
            />
          );
        }}
      />
    );
  },
);

export default MultiSelectTypeahead as <T>(
  multiSelectTypeaheadProps: TMultiSelectTypeaheadProps<T>,
  ref: Ref<any>,
) => ReturnType<typeof MultiSelectTypeahead>;
