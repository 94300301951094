import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { IDomain } from "models/documents.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";

export default function useDomains() {
  const { t } = useTranslation("documents", {
    keyPrefix: "notifications",
  });

  const { data, status, isLoading, isError, isSuccess, error } = useQuery<
    IDomain[],
    Error
  >([QueryAPIKey.GetDomains], () =>
    services.documents.getDomains().then((res) => res.data),
  );

  useShowAlert({
    status,
    failureMessage: t("getDomainsFailure"),
  });

  return {
    domains: data,
    isLoading,
    isError,
    isSuccess,
    error,
  };
}
