import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ClientError, gql } from "graphql-request";
import { Variables } from "graphql-request/src/types";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { RoleScope } from "../admin/useUsers.models";
import { IUserGQLResponseData } from "./useUserData.models";

export const query = gql`
  query User($userId: String, $roleScope: String) {
    user(userId: $userId, roleScope: $roleScope) {
      email
      firstName
      id
      lastName
      organization {
        id
        tenantId
        parentId
        name
        shortName
        type
        dunsNumber
        ebsClientId
        evCode
        externalId
        globalId
        masterFileType
        country
        addresses {
          address1
          address2
          city
          state
          country
          postcode
          poBox
          addressComplement
          isPrimary
        }
        phones {
          phone
          phoneCountryPrefix
          phoneExtension
        }
        emails {
          emailAddress
        }
      }
      preferences {
        timezone
        emailNotificationsEnabled
      }
      roles {
        roleId
        projects
        id
        userGroupId
        userId
      }
      status
      title
    }
  }
`;

const useUserData = (userId: string | undefined, roleScope?: RoleScope) => {
  const { t } = useTranslation("user", {
    keyPrefix: "notifications",
  });

  const { showAlert } = useAlerts();

  const { data, isLoading, error } = useQuery<IUserGQLResponseData>(
    [QueryAPIKey.GetUserData, userId],
    () =>
      services.graphQL
        .request<IUserGQLResponseData, Variables>(query, {
          userId,
          roleScope,
        })
        .catch((error) => {
          showAlert({
            message: t("getUserDataFailure"),
            severity: AlertSeverity.Error,
          });

          throw error;
        }),
    {
      enabled: !!userId,
    },
  );

  return {
    data: data?.user,
    isLoading,
    error: error as unknown as ClientError,
  };
};

export default useUserData;
