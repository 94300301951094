import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  card: {
    ".MuiCardContent-root": {
      paddingTop: 0,
    },
  },

  editButton: {
    position: "absolute",
    right: 8,
  },

  label: {
    fontWeight: 500,
    color: getPaletteColor("custom.labelDefault"),
  },

  row: {
    display: "grid",
    gridTemplateColumns: "minmax(200px, 150px) 1fr",
    mt: 0,
    position: "relative",
    alignItems: "center",
    minHeight: 36,
    pt: 2.25,
    pb: 1.25,
    px: 1,
    borderTop: getBorderStyle("1px", "solid", "grey.200"),

    "&:first-of-type": {
      borderTop: "none",
    },
  },
};

export default styles;
