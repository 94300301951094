import { EntityStatus } from "models/entities.models";
import {
  ProductVariantFieldName,
  TProductVariantForm,
} from "../ProductVariantFormFields/ProductVariantFormFields.types";

export const mapFormValuesToVariantFields = (values: TProductVariantForm) => ({
  productTypeCode: values[ProductVariantFieldName.ProductType] ?? "",
  routeOfAdministrationTypeCode:
    values[ProductVariantFieldName.RouteOfAdministration] ?? "",
  manufacturedDoseFormTypeCode:
    values[ProductVariantFieldName.ManufacturedDoseForm] ?? "",
  numeratorUnitValue: values[ProductVariantFieldName.StrengthValue] ?? "",
  numeratorUnitTypeCode: values[ProductVariantFieldName.StrengthType] ?? "",
  denominatorUnitValue:
    values[ProductVariantFieldName.StrengthVolumeValue] ?? null,
  denominatorUnitTypeCode:
    values[ProductVariantFieldName.StrengthVolumeType] ?? "",
  active: values[ProductVariantFieldName.Status] === EntityStatus.Active,
});
