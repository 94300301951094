import { useMutation } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import {
  IAssetContent,
  ICreateAssetContentRequestData,
} from "models/assets.models";
import { TAssetId } from "models/documents.models";
import { services } from "services";

export type TCreateAssetContentMutation = {
  assetId: TAssetId;
  data: ICreateAssetContentRequestData;
};

type TUseCreateAssetContentProps = {
  failureMessage?: string;
  successMessage?: string;
};

export default function useCreateAssetContent({
  failureMessage,
  successMessage,
}: TUseCreateAssetContentProps) {
  const {
    status,
    error,
    isLoading,
    isError,
    isSuccess,
    data: assetContent,
    mutate,
  } = useMutation<IAssetContent, Error, TCreateAssetContentMutation>(
    ({ assetId, data }) =>
      services.assets.createAssetContent(assetId, data).then((res) => res.data),
  );

  useShowAlert({
    status,
    successMessage,
    failureMessage,
  });

  return {
    assetContent,
    error,
    isLoading,
    isError,
    isSuccess,
    createAssetContent: mutate,
  };
}
