import { TFunction } from "i18next";
import { TDictionary } from "screens/EntityManagement/components/Products/Products.types";
import { IOrganizationTypeMap } from "./CreateOrganizationModal.types";

export const getOrganizationTypeTitles = (
  types: TDictionary[],
  t: TFunction,
): IOrganizationTypeMap[] =>
  types.map((type) => ({
    title: t(`createOrganizationalModal.organizationTypes.${type.code}.title`),
    description: t(
      `createOrganizationalModal.organizationTypes.${type.code}.description`,
    ),
    value: type.code,
  }));
