import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { isAxiosError } from "api/types";
import useShowAlert from "hooks/useShowAlert";
import { IFilterParams } from "models/pagination.models";
import { services } from "services";
import {
  QueryAPIKey,
  QueryFetchingStatus,
} from "utils/constants/api.constants";

export type TUseGetUnreadNotificationCountReturn = {
  status: QueryFetchingStatus;
  error: unknown;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  unreadNotificationCount: number | undefined;
};

export default function useGetUnreadNotificationCount(
  userId: string | undefined,
  params: IFilterParams,
  pollingIntervalMs: number,
): TUseGetUnreadNotificationCountReturn {
  const { t } = useTranslation("user", {
    keyPrefix: "notifications",
  });

  const { status, error, isLoading, isError, isSuccess, data } = useQuery(
    [QueryAPIKey.GetUnreadNotificationCount, userId],
    async () => {
      const response = await services.notifications.getNotificationsForUser(
        userId || "",
        params,
      );

      // TODO: remove/update with global error handling work - FTE-4189
      return isAxiosError(response) ? Promise.reject(response) : response.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: pollingIntervalMs,
      enabled: !!userId,
    },
  );

  useShowAlert({
    status,
    failureMessage: t("getNotificationsForUserFailure"),
  });

  const unreadNotificationCount = data?.totalElements;

  return {
    status: status as QueryFetchingStatus,
    error,
    isLoading,
    isError,
    isSuccess,
    unreadNotificationCount,
  };
}
