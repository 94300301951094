import { Box } from "@mui/material";
import { IDocumentOutline } from "models/documents.models";
import OutlineSection from "./OutlineSection";
import styles from "./DocumentOutline.styles";

type TDocumentOutlineProps = {
  outline: IDocumentOutline[] | undefined;
  onItemClick: (outline: IDocumentOutline) => void;
};

const DocumentOutline = (props: TDocumentOutlineProps) => {
  return (
    <Box sx={styles.container}>
      {props.outline?.map((section, index) => (
        <OutlineSection
          key={section.id}
          section={section}
          depth={0}
          index={`${index + 1}`}
          onItemClick={props.onItemClick}
        />
      ))}
    </Box>
  );
};

export default DocumentOutline;
