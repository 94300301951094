import { FormControlLabel, Radio, useRadioGroup } from "@mui/material";
import RadioLabel, { TRadioLabelProps } from "./RadioLabel";
import styles from "./RadioTile.styles";

export type TRadioTileProps = TRadioLabelProps & {
  /**
   * Optional value for this radio button. If undefined,
   * then title is used instead.
   */
  value?: string;
};

const RadioTile = ({ value, ...props }: TRadioTileProps) => {
  const radioGroup = useRadioGroup();

  // value, or title as value
  const radioValue = value ?? props.title;

  const checked = radioGroup?.value === radioValue;

  return (
    <FormControlLabel
      sx={checked ? styles.checkedFormControlLabel : styles.formControlLabel}
      value={radioValue}
      control={
        <Radio
          inputProps={{
            "aria-label": props.title,
          }}
          sx={styles.radioButton}
        />
      }
      label={<RadioLabel {...props} />}
    />
  );
};

export default RadioTile;
