import { TStyles } from "utils/Styles";

const styles: TStyles = {
  fixedHeightContainer: {
    maxHeight: "470px",
    overflow: "auto",
    mt: 2.5,
  },
};

export default styles;
