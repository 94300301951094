import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  option: {
    display: "flex",
    alignItems: "center",
    gap: 0.5,
  },

  disabled: {
    color: getPaletteColor("grey.700"),
  },

  latest: {
    color: getPaletteColor("green.500"),
    fontWeight: 500,
  },

  createdDate: {
    fontSize: 13,
    color: getPaletteColor("grey.700"),
  },

  disabledInformation: {
    fontSize: 13,
    color: getPaletteColor("grey.700"),
  },
};

export default styles;
