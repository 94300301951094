import { AxiosResponse } from "axios";
import { del, get, post, routes } from "api";
import { IClientConfig } from "api/types";
import {
  IGetDocumentsRequestParams,
  IProcessDocumentRequestBody,
} from "models/documents.models";
import comments from "./comments";
import suggestions from "./suggestions";

const documents = {
  addDocument(data: FormData, isMockApiOn?: boolean): Promise<AxiosResponse> {
    return post(routes.asset.assets.all(), {
      data,
      isMockApiOn,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  processDocument(
    data?: IProcessDocumentRequestBody,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return post(routes.asset.assets.parseRequests(), {
      data,
      isMockApiOn,
    });
  },

  getDocuments(params?: IGetDocumentsRequestParams): Promise<AxiosResponse> {
    return get(routes.asset.assets.all(), {
      params,
    });
  },

  getDocument(id: string, config?: IClientConfig): Promise<AxiosResponse> {
    return get(routes.asset.assets.byId(id), config);
  },

  getLanguages: (isMockApiOn?: boolean): Promise<AxiosResponse> => {
    return get(routes.asset.documents.languages(), { isMockApiOn });
  },

  getDomains: (isMockApiOn?: boolean): Promise<AxiosResponse> => {
    return get(routes.asset.documents.domains(), { isMockApiOn });
  },

  getDocumentTypes: (
    domainId: string,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> => {
    return get(routes.asset.documents.types(domainId), { isMockApiOn });
  },

  lockAssetEditing: (assetId: string) => {
    return post(routes.asset.assets.lockAssetEditing(assetId));
  },

  unlockAssetEditing: (assetId: string) => {
    return del(routes.asset.assets.lockAssetEditing(assetId));
  },

  comments,
  suggestions,
};

export default documents;
