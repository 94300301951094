import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid } from "@mui/material";
import { FormTextInput } from "components/common/form-fields";
import ConfirmModal from "components/shared/ConfirmModal";
import FormWrapper from "components/shared/FormWrapper";
import Loading from "components/shared/Loading";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { getPartialCTOCDocument } from "utils/constants/CTOCDocument/CTOCDocument";
import ApplicableHASelect from "./components/ApplicableHASelect";
import CTOCDocumentSelect from "./components/CTOCDocumentSelect";
import { useDossierDifferenceModal } from "./context";
import {
  DossierDifferencesModalFields,
  getDossierDifferenceFormSchema,
  TDossierDifferencesModalForm,
  TDossierDifferencesModalFormEdit,
} from "./DossierDifferenceModal.types";
import useDeleteDossierDifferenceModal from "./hooks/useDeleteDossierDifferenceModal";
import useSubmitDossierDifferenceModal from "./hooks/useSubmitDossierDifferenceModal";
import { getDossierDifferenceId } from "./utils/getDossierDifferenceId";
import getDossierDifferenceModalButtons from "./utils/modalButtons";
import styles from "./DossierDifferenceModal.styles";
import commonStyles from "styles/common.styles";

const DossierDifferenceModal = () => {
  const { onClose, isOpen, listOfSubmittingHAs, initialFormData } =
    useDossierDifferenceModal();

  const { t } = useTranslation(["projects", "common"]);

  const methods = useForm<
    TDossierDifferencesModalForm | TDossierDifferencesModalFormEdit
  >({
    mode: "all",
    resolver: zodResolver(getDossierDifferenceFormSchema(t)),
  });

  const { reset, getValues, handleSubmit } = methods;

  const { isLoading, handleAdd } = useSubmitDossierDifferenceModal({
    onClose,
  });

  const {
    isOpenDeleteModal,
    openDeleteModal,
    onConcludeHandler,
    isLoadingDelete,
  } = useDeleteDossierDifferenceModal({
    onClose,
    dossierDifferenceId: getValues(
      DossierDifferencesModalFields.DossierDifferenceId,
    ),
  });

  const isEditMode = !!getDossierDifferenceId(initialFormData);

  useEffect(() => {
    reset(initialFormData);
  }, [initialFormData, reset]);

  const { addButton, cancelButton, deleteButton } =
    getDossierDifferenceModalButtons({
      isLoading,
      onClose,
      methods,
      isEditMode,
      openDeleteModal,
      t,
    });

  const isAnyLoading = isLoadingDelete || isLoading;

  return (
    <Modal
      data-qaid="dossier-difference-modal"
      open={isOpen}
      sx={styles.modal}
      title={
        isEditMode
          ? t("dossierDifferences.editDifferences")
          : t("dossierDifferences.addDifferences")
      }
    >
      <FormWrapper
        methods={methods}
        onSubmit={handleSubmit(handleAdd)}
        sx={styles.formContainer}
      >
        {isAnyLoading && (
          <Box sx={styles.loadingIndicatorContainer}>
            <Loading sx={commonStyles.loadingIndicator} />
          </Box>
        )}
        <Grid container spacing={2.3}>
          <Grid item xs={12}>
            <FormTextInput<TDossierDifferencesModalForm>
              data-qaid="dossier-differences-summary-text-field"
              name={DossierDifferencesModalFields.SummaryOfDifferences}
              label={t("dossierDifferences.summaryLabel")}
              multiline
              rows={3}
            />
          </Grid>

          <Grid item xs={12}>
            <Box>
              <CTOCDocumentSelect<TDossierDifferencesModalForm>
                data-qaid="dossier-differences-document-multi-select"
                dropdownOptions={getPartialCTOCDocument(["1"])}
                name={DossierDifferencesModalFields.Sections}
                label={t("dossierDifferences.chooseSection")}
                helperText={t("dossierDifferences.moreSections")}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <ApplicableHASelect
              options={listOfSubmittingHAs}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
        <ModalActionButtons
          buttons={[isEditMode && deleteButton, cancelButton, addButton]}
        />
      </FormWrapper>
      <ConfirmModal
        open={isOpenDeleteModal}
        title={t("dossierDifferences.confirmDossierDifferenceDelete")}
        onConclude={onConcludeHandler}
        confirmButtonText={t("button.confirm", { ns: "common" })}
        cancelButtonText={t("button.cancel", { ns: "common" })}
      />
    </Modal>
  );
};

export default DossierDifferenceModal;
