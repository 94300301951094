import { PropsWithChildren } from "react";
import { Security as OktaSecurity } from "@okta/okta-react";
import { oktaAuth } from "utils/auth";
import { useRestoreOriginalUri } from "./hooks/useRestoreOriginalUri";

export const Security = (props: PropsWithChildren) => {
  const restoreOriginalUri = useRestoreOriginalUri();

  return (
    <OktaSecurity oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {props.children}
    </OktaSecurity>
  );
};
