import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  separator: {
    borderTop: getBorderStyle("1px", "solid", "divider"),
    marginTop: 1,
    paddingTop: 1.25,
  },

  gridContainer: {
    display: "grid",
    gridTemplateColumns: "minmax(200px, 150px) 1fr",
    maxWidth: "600px",
    marginBottom: 1.25,
  },

  label: {
    color: getPaletteColor("text.secondary"),
    textTransform: "capitalize",
  },

  value: {
    maxWidth: "70%",
    overflowWrap: "break-word",
  },
};

export default styles;
