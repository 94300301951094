import { TStyles } from "utils/Styles";

const styles: TStyles = {
  autocomplete: {
    ".MuiIconButton-root": {
      marginRight: "-4px",

      ".MuiSvgIcon-root": {
        paddingTop: "2px",
      },
    },
  },
  option: {
    whiteSpace: "normal",
  },
  noWrapOption: {
    whiteSpace: "noWrap",
  },
};

export default styles;
