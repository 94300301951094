import {
  TUpdateDocumentMetadataForm,
  UpdateDocumentMetadataField,
} from "./UpdateDocumentMetadata.types";

export const getMetadataFormDefaultValues = (
  document: Partial<TUpdateDocumentMetadataForm> | undefined,
): Partial<TUpdateDocumentMetadataForm> => {
  return {
    [UpdateDocumentMetadataField.Language]: "",
    [UpdateDocumentMetadataField.LitigationHold]:
      document?.litigationHold || false,
    [UpdateDocumentMetadataField.Author]: document?.author || "",
    [UpdateDocumentMetadataField.Name]: document?.name || "",
  };
};
