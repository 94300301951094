import { get, post, put, routes } from "api";
import {
  ICreateSuggestionRequestData,
  IUpdateSuggestionRequestData,
} from "models/annotations.models";

const suggestions = {
  getSuggestion: (
    componentId: string,
    suggestionId: string,
    isMockApiOn?: boolean,
  ) => {
    return get(
      routes.asset.documents.suggestions.byId(componentId, suggestionId),
      { isMockApiOn },
    );
  },

  createSuggestion: (
    componentId: string,
    data: ICreateSuggestionRequestData,
    isMockApiOn?: boolean,
  ) => {
    return post(routes.asset.documents.suggestions.all(componentId), {
      data,
      isMockApiOn,
    });
  },

  updateSuggestion: (
    componentId: string,
    suggestionId: string,
    data: IUpdateSuggestionRequestData,
    isMockApiOn?: boolean,
  ) => {
    return put(
      routes.asset.documents.suggestions.byId(componentId, suggestionId),
      {
        data,
        isMockApiOn,
      },
    );
  },
};

export default suggestions;
