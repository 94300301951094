import { IDocument } from "models/documents.models";
import { TISODateFormat } from "utils/types";
import { TSendCopyGSP } from "./SendCopyDocuments";
import { SendCopyDocumentFieldName } from "./SendCopyDocuments.types";

export const getSendCopyDefaultValues = (
  documentsToSend: IDocument[] | TSendCopyGSP[],
) => ({
  [SendCopyDocumentFieldName.Documents]: documentsToSend,
  [SendCopyDocumentFieldName.Recipients]: [],
  [SendCopyDocumentFieldName.DueDate]: "" as TISODateFormat,
  [SendCopyDocumentFieldName.Instructions]: "",
});
