import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { IOrganizationResponse } from "models/organizations.models";
import { IGetOrganizationsRequestParams } from "models/organizations.models";
import { services } from "services";
import { MAX_SIZE, QueryAPIKey } from "utils/constants/api.constants";

export default function useOrganizations(
  params: IGetOrganizationsRequestParams = { size: MAX_SIZE },
  enabled?: boolean,
) {
  const safeParams = params || {};

  const { t } = useTranslation("organizations", {
    keyPrefix: "notifications",
  });

  const { data, status, isLoading, isError, isSuccess, error } = useQuery<
    IOrganizationResponse,
    Error
  >(
    [QueryAPIKey.GetOrganizations, params],
    () =>
      services.organizations
        .getOrganizations(safeParams)
        .then((res) => res.data),
    { enabled: enabled ?? true, staleTime: Infinity },
  );

  useShowAlert({
    status,
    failureMessage: t("getOrganizationsFailure"),
  });

  return {
    organizations: data,
    isLoading,
    isError,
    isSuccess,
    error,
    status,
  };
}
