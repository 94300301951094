import { forwardRef, Ref } from "react";
import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
} from "@mui/material";
import { TStyles } from "utils/Styles";
import styles from "./Avatar.styles";

export type TAvatarProps = MuiAvatarProps & {
  size?: number;
};

const Avatar = forwardRef(
  (
    { alt = "", sx, size = 26, ...muiAvatarProps }: TAvatarProps,
    ref: Ref<any>,
  ) => {
    const computedStyle = {
      ...styles.avatar,
      ...sx,
      width: size,
      height: size,
    } as TStyles;

    return (
      <MuiAvatar alt={alt} ref={ref} sx={computedStyle} {...muiAvatarProps} />
    );
  },
);

export default Avatar;
