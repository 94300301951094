import { TFunction } from "i18next";
import { TCountryCode } from "utils/constants/countries.constants";

const getCountryName = (countryCode: TCountryCode, t: TFunction): string => {
  return t(countryCode.toLowerCase(), {
    ns: "countries",
    defaultValue: countryCode,
  });
};

export default getCountryName;
