import { TFunction } from "i18next";
import { IOrganization } from "models/organizations.models";
import { getCountryName } from "utils/helpers/getCountryName";
import { getIsEmptyString } from "utils/helpers/getIsEmptyString";
import { EMPTY_LABEL } from "utils/helpers/getTextValue";

const getOrganizationLabel = (
  organization: IOrganization | undefined,
  t: TFunction,
) => {
  const organizationCountry = organization?.country
    ? getCountryName(organization?.country, t)
    : "";

  const organizationName = organization?.name;

  return organization
    ? [organizationCountry, organizationName]
        .filter((item) => !getIsEmptyString(item))
        .join(" - ")
    : EMPTY_LABEL;
};

export default getOrganizationLabel;
