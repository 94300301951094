import { ProjectType } from "utils/constants/project.constants";
import { isHAUser, isSponsorUser } from "utils/user/organization";
import { TPermissionFunction } from "../usePermissions.types";

//  TODO: Implementation of this functionality within the task FTE-11517

export const deriveChangeStateContentPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.Orbis:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveChangeStateContentInAssociatedProjectsPermission: TPermissionFunction =
  ({ project, organization, userRoles }) => {
    switch (project?.type) {
      case ProjectType.Reliance:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return false;
          }
        }

        return false;

      case ProjectType.RegulatoryReview:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return false;
          }
        }

        return false;

      case ProjectType.Orbis:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return false;
          }
        }

        return false;

      default:
        return false;
    }
  };

export const deriveStartStructuredAndUnstructuredContentWorkflowPermission: TPermissionFunction =
  ({ project, organization, userRoles }) => {
    switch (project?.type) {
      case ProjectType.Reliance:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return false;
          }
        }

        return false;

      case ProjectType.RegulatoryReview:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return false;
          }
        }

        return false;

      default:
        return false;
    }
  };

export const deriveReceiveAndCompleteAcknowledgeReceiptPermission: TPermissionFunction =
  ({ project, organization, userRoles }) => {
    switch (project?.type) {
      case ProjectType.Reliance:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return false;
          }
        }

        return false;

      case ProjectType.RegulatoryReview:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return false;
          }
        }

        return false;

      default:
        return false;
    }
  };

export const deriveUpdateDueDatesPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};
