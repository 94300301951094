import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Drawer } from "@mui/material";
import FormWrapper from "components/shared/FormWrapper";
import { queryClient } from "config/reactQueryClient";
import { useAlerts } from "context/AlertContext";
import useCreateAsset from "hooks/api/REST/assets/useCreateAsset";
import useOrganizations from "hooks/api/REST/organizations/useOrganizations";
import useHideJiraWidget from "hooks/useHideJiraWidget";
import { IHAQMetadata } from "models/HAQ.models";
import { EventType } from "models/tracking.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { AssetType } from "utils/constants/assets.constants";
import { HAQFieldName } from "utils/constants/HAQ.constants";
import { getHAQShortName } from "utils/helpers";
import { THAQForm, THAQParentData } from "./AddHAQDrawer.types";
import {
  getAddHAQSchema,
  getDefaultValues,
  getSubmitPayload,
} from "./AddHAQDrawer.utils";
import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";
import styles from "./AddHAQDrawer.styles";

export type TAddHAQDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  parent: THAQParentData;
};

const AddHAQDrawer = ({
  isOpen,
  onClose,
  projectId,
  parent,
}: TAddHAQDrawerProps) => {
  const { t } = useTranslation(["HAQ", "common"]);

  useHideJiraWidget(isOpen);

  const { showAlert } = useAlerts();

  const { mutateAsync: createHAQ, isLoading: isSubmitting } =
    useCreateAsset<IHAQMetadata>();

  const { organizations } = useOrganizations();

  const methods = useForm<THAQForm>({
    mode: "all",
    defaultValues: getDefaultValues(parent),
    resolver: zodResolver(getAddHAQSchema(t)),
  });

  const { handleSubmit, watch, resetField, reset } = methods;

  useEffect(() => {
    if (!isOpen) {
      reset(getDefaultValues(parent));
    }
  }, [reset, isOpen, parent]);

  useEffect(() => {
    const { unsubscribe } = watch((value, { name }) => {
      if (name === HAQFieldName.Domain && value[HAQFieldName.Topic]) {
        resetField(HAQFieldName.Topic);
      }
    });

    return () => unsubscribe();
  }, [resetField, watch]);

  const handleSubmitHAQ = async (data: THAQForm) => {
    const payload = getSubmitPayload(data, projectId || "");
    await createHAQ(
      { data: payload },
      {
        onSuccess: (response) => {
          showAlert({
            message: t("notifications.createHAQSuccess", {
              HAQName: getHAQShortName(
                response,
                t("HAQAcronym", { ns: "HAQ" }),
                organizations,
              ),
            }),
            severity: "success",
          });

          queryClient.invalidateQueries([QueryAPIKey.GetAssetsWithContent]);

          services.tracking.addTrackingEvent({
            assetId: response.id,
            assetType: AssetType.Question,
            eventType: EventType.AppHAQCreate,
            projectId: projectId || "",
          });
        },
        onError: async () => {
          showAlert({
            message: t("notifications.createHAQFailure"),
            severity: "error",
          });
        },
      },
    );

    onClose();
  };

  return (
    <Drawer anchor="right" open={isOpen} sx={styles.drawer} disableEnforceFocus>
      <FormWrapper methods={methods} onSubmit={handleSubmit(handleSubmitHAQ)}>
        <Box sx={styles.container} role="complementary">
          <Header onCloseDrawer={onClose} />
          <Content projectId={projectId} parent={parent} />
          <Footer
            isSubmitting={isSubmitting}
            onClose={onClose}
            handleSubmitHAQ={handleSubmitHAQ}
          />
        </Box>
      </FormWrapper>
    </Drawer>
  );
};

export default AddHAQDrawer;
