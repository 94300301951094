import { AxiosResponse } from "axios";
import { get, post, routes } from "api";
import { TFHIRRequestParams } from "models/fhir.models";
import { FHIRResourceType } from "utils/constants/fhir.constants";

const dataX = {
  getFHIRResource(
    resourceType: FHIRResourceType,
    id: string,
  ): Promise<AxiosResponse> {
    return get(routes.dataX.dataResources.byId(resourceType, id));
  },

  getFHIRResourcesByType(
    resourceType: FHIRResourceType,
    params?: TFHIRRequestParams,
  ): Promise<AxiosResponse> {
    return get(routes.dataX.dataResources.byType(resourceType), { params });
  },

  addResource(
    resourceType: FHIRResourceType,
    data: any,
  ): Promise<AxiosResponse> {
    return post(routes.dataX.dataResources.add(resourceType), {
      data,
      headers: { "Content-Type": "application/json" },
    });
  },
};

export default dataX;
