import { AxiosResponse } from "axios";
import { del, get, post, put, routes } from "api";
import {
  ICreateCommentRequestData,
  IUpdateCommentRequestData,
} from "models/annotations.models";

const comments = {
  getCommentThread: (
    componentId: string,
    threadId: string,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> => {
    return get(routes.asset.documents.comments.thread(componentId, threadId), {
      isMockApiOn,
    });
  },

  deleteCommentThread: (
    channelId: string,
    threadId: string,
  ): Promise<AxiosResponse> => {
    return del(routes.asset.documents.comments.thread(channelId, threadId));
  },

  updateCommentThread: (data: {
    assetId: string;
    threadId: string;
    isResolved: boolean;
  }): Promise<AxiosResponse> => {
    return put(
      routes.asset.documents.comments.thread(data.assetId, data.threadId),
      {
        data,
      },
    );
  },

  createComment: (
    componentId: string,
    threadId: string,
    data: ICreateCommentRequestData,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> => {
    return post(
      routes.asset.documents.comments.byThreadId(componentId, threadId),
      {
        data,
        isMockApiOn,
      },
    );
  },

  updateComment: (
    componentId: string,
    threadId: string,
    commentId: string,
    data: IUpdateCommentRequestData,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> => {
    return put(
      routes.asset.documents.comments.byId(componentId, threadId, commentId),
      { data, isMockApiOn },
    );
  },

  deleteComment: (
    componentId: string,
    threadId: string,
    commentId: string,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> => {
    return del(
      routes.asset.documents.comments.byId(componentId, threadId, commentId),
      { isMockApiOn },
    );
  },
};

export default comments;
