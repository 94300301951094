import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import isEmpty from "lodash/isEmpty";
import useShowAlert from "hooks/useShowAlert";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { IShareHistoryItem } from "utils/constants/shareHistory.constants";

const useBulkShareHistory = (ids: string[]) => {
  const { t } = useTranslation("user", {
    keyPrefix: "notifications",
  });

  const { data, isLoading, isError, status } = useQuery<
    Record<string, IShareHistoryItem[]> | {},
    Error
  >(
    [QueryAPIKey.GetAssetBulkShareHistory, ids],
    () => services.assets.getAssetBulkShareHistory(ids).then((res) => res.data),
    { enabled: !isEmpty(ids) },
  );

  useShowAlert({
    status,
    failureMessage: t("shareHistoryFailure"),
  });

  return {
    shareHistory: data,
    isLoading,
    isError,
  };
};

export default useBulkShareHistory;
