const parseDataUploadUrl = (dataUploadUrl: string | URL) => {
  const url = new URL(dataUploadUrl);

  const pathname = url.pathname.replace(/^\/|\/$/g, "").split(/\/|%2f/i);

  const blobServiceUrl = `${url.origin}${url.search}`;
  const containerName = pathname[0];
  const blobName = pathname.slice(1).join("/");

  if (blobServiceUrl && containerName && blobName) {
    return {
      blobServiceUrl,
      containerName,
      blobName,
    };
  } else {
    throw new Error("Failed to parse data upload url");
  }
};

export default parseDataUploadUrl;
