const formatStringToCamelCase = (string: string, variant: string = "lower") => {
  // Convert the input string to lowercase
  const lowerCamelCaseString = string
    .toLowerCase()
    // Replace underscores, dashes, and spaces with uppercase characters
    .replace(/[_-\s]+(.)/g, (_, c) => c.toUpperCase());

  if (variant === "upper") {
    // If variant is "upper" convert the first character to uppercase
    const upperCamelCaseString =
      lowerCamelCaseString.split("")[0].toUpperCase() +
      lowerCamelCaseString.slice(1);

    return upperCamelCaseString;
  }

  return lowerCamelCaseString;
};

export default formatStringToCamelCase;
