import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    backgroundColor: getPaletteColor("primary.50"),
    borderRadius: 1,
    minHeight: "50px",
  },

  documentIconWrapper: {
    p: 1,
    mr: 1,
    color: getPaletteColor("blueGrey.800"),
  },

  fileName: {
    maxWidth: "450px",
    overflowWrap: "break-word",
  },

  clearIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "none",
    border: "none",
    padding: 0,
    marginRight: "12px",

    "&:hover": {
      cursor: "pointer",
    },
  },
};

export default styles;
