import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  FormComboBox,
  FormDate,
  FormTextInput,
} from "components/common/form-fields";
import ButtonLink from "components/shared/ButtonLink";
import useUser from "hooks/useUser";
import { DocumentsField } from "screens/Project/sections/Documents/modals/fields";
import {
  ShareDocumentFieldName,
  TShareDocumentForm,
} from "screens/Project/sections/Documents/modals/ShareDocument/ShareDocument.types";
import { isSponsorUser } from "utils/user/organization";
import styles from "./FieldsLayout.styles";

type TFieldLayoutProps = {
  onAddClick: (event?: SyntheticEvent) => void;
  recipientsOptions: any;
};

const FieldsLayout = ({ onAddClick, recipientsOptions }: TFieldLayoutProps) => {
  const { organization } = useUser();

  const { t } = useTranslation(["documents", "common"]);

  return (
    <>
      <DocumentsField fieldName={ShareDocumentFieldName.Documents} />
      <ButtonLink
        onClick={(event?: SyntheticEvent) => onAddClick(event)}
        sx={styles.addDocumentsButton}
      >
        {t("documents.commonFields.documents.addDocument")}
      </ButtonLink>
      <FormComboBox
        data-qaid="share-document-recipients-combobox"
        helperText={t("documents.shareDocument.recipientHelperText")}
        name={ShareDocumentFieldName.Recipients}
        label={t("documents.shareDocument.fields.recipients.label")}
        options={recipientsOptions}
        deselectAllLabelText={t("multiSelect.deselectAll", { ns: "common" })}
        selectAllLabelText={t("multiSelect.selectAll", { ns: "common" })}
        errorAdornmentAriaLabel={t("ariaLabels.textFieldError", {
          ns: "common",
        })}
      />
      {isSponsorUser(organization) && (
        <FormDate
          data-qaid="share-documents-due-date-field"
          disablePast
          name={ShareDocumentFieldName.DueDate}
          label={t("documents.shareDocument.fields.dueDate.label")}
        />
      )}
      <FormTextInput<TShareDocumentForm>
        data-qaid="share-documents-message-text-field"
        name={ShareDocumentFieldName.Message}
        multiline
        rows={3}
        label={t("documents.shareDocument.fields.message.label")}
      />
    </>
  );
};

export default FieldsLayout;
