import { type CommentsAdapter } from "@ckeditor/ckeditor5-comments/src/comments/commentsrepository";
import { Context, Editor } from "ckeditor5";
import { IComment } from "models/annotations.models";
import { TCommentForCreate } from "../useAddComment";

const addComment =
  (
    _editorOrContext: Editor | Context, // We will need this later to implement handling of failed API requests
    mutationFn: (commentData: TCommentForCreate) => Promise<{ data: IComment }>,
  ): CommentsAdapter["addComment"] =>
  async (data) => {
    const { channelId, threadId, attributes, content, commentId } = data;

    const requestData = {
      id: commentId,
      // the values set in PrivateCommentView are not set on the data before this method is called,
      // so we have to set the same defaults here.
      attributes: {
        isPublic: attributes.isPublic ?? true,
        isVisibilityEditable: attributes.isVisibilityEditable ?? false,
      },
      content,
    };

    const { data: responseData }: { data: IComment } = await mutationFn({
      componentId: String(channelId),
      threadId,
      requestData,
    });

    return {
      commentId: responseData.id,
      createdAt: new Date(responseData.createdDate),
    };
  };

export default addComment;
