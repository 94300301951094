import { TSingleSelectTypeaheadOption } from "components/shared/SingleSelectTypeahead";

export enum SubstanceEntityFieldName {
  SubstanceName = "name",
  DrugClass = "class",
  INN = "INN",
  ATCCode = "ATC",
  LinkedOrganizations = "linkedOrganizations",
  Status = "status",
}

export type TSubstanceEntityForm = {
  [SubstanceEntityFieldName.SubstanceName]: string;
  [SubstanceEntityFieldName.DrugClass]: TSingleSelectTypeaheadOption;
  [SubstanceEntityFieldName.INN]: string;
  [SubstanceEntityFieldName.ATCCode]: string;
  [SubstanceEntityFieldName.LinkedOrganizations]: string[];
  [SubstanceEntityFieldName.Status]: string;
};
