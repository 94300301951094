import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { IProjectData } from "hooks/api/GQL/project/useProjectData.models";
import useUser from "hooks/useUser";
import { IRelianceProjectMetadata } from "models/projects.models";
import { ProjectType } from "utils/constants/project.constants";
import {
  getOrbisProjectStatuses,
  getRelianceProjectStatuses,
} from "./useProjectStatusOptions.utils";

export type TProjectStatusOption = {
  id: string;
  label: string;
  disabled?: boolean;
  adornment?: ReactNode | null;
};

const useProjectStatusOptions = (
  project: IProjectData | null | undefined,
): TProjectStatusOption[] => {
  const { t } = useTranslation(["statuses", "projects"]);
  const { organization } = useUser();

  switch (project?.type) {
    case ProjectType.Orbis:
      return getOrbisProjectStatuses(project.status, organization, t);

    case ProjectType.Reliance:
      return getRelianceProjectStatuses(
        project as IProjectData<IRelianceProjectMetadata>,
        t,
      );

    case ProjectType.RegulatoryReview:
      return getRelianceProjectStatuses(
        project as IProjectData<IRelianceProjectMetadata>,
        t,
      );

    default:
      return [];
  }
};

export default useProjectStatusOptions;
