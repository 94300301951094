import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { Typography } from "@mui/material";
import getEnv from "env";
import Button from "components/shared/Button";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { signOut } from "utils/auth";
import { DISMISS_IDLE_BROADCAST_CHANNEL_ID } from "utils/constants/broadcastChannel.constants";
import Countdown from "./Countdown";

type TUserSessionTimeoutProps = {
  crossTab?: boolean;
};

type THandleDismissParams = {
  shouldNotifyOthers: boolean;
};

const IDLE_TIMEOUT = Number(getEnv().idleTimeout);
const PROMPT_TIME_PERCENTAGE = 20;

export const UserSessionTimeout = ({
  crossTab = true,
}: TUserSessionTimeoutProps) => {
  const { t } = useTranslation("user");

  const [isPrompted, setIsPrompted] = useState(false);

  const { reset, message, getRemainingTime } = useIdleTimer({
    timeout: IDLE_TIMEOUT,
    promptBeforeIdle: (IDLE_TIMEOUT * PROMPT_TIME_PERCENTAGE) / 100,
    crossTab,
    onPrompt: handlePrompt,
    onIdle: handleIdle,
    name: DISMISS_IDLE_BROADCAST_CHANNEL_ID,
    onMessage: handleCrossTabCommunication,
  });

  function handleCrossTabCommunication(data: { isDismissed: boolean }) {
    if (data.isDismissed && isPrompted) {
      handleDismiss({ shouldNotifyOthers: false });
    }
  }

  function handleDismiss({ shouldNotifyOthers }: THandleDismissParams) {
    reset();
    setIsPrompted(false);

    if (shouldNotifyOthers) {
      message({ isDismissed: true });
    }
  }

  function handleIdle() {
    handleDismiss({ shouldNotifyOthers: true });
    signOut({
      shouldRestorePath: true,
      shouldLogoutEverywhere: true,
    }).catch(console.error);
  }

  function handlePrompt() {
    setIsPrompted(true);
  }

  return (
    <Modal open={isPrompted} title={t("logout.warningTitle")}>
      <Typography variant="body1" marginBottom={1.5}>
        <Trans t={t} i18nKey="logout.warningMessage">
          {isPrompted && <Countdown remainingTime={getRemainingTime()} />}
        </Trans>
      </Typography>
      <ModalActionButtons>
        <Button
          variant="contained"
          onClick={() => handleDismiss({ shouldNotifyOthers: true })}
        >
          {t("logout.dismiss")}
        </Button>
      </ModalActionButtons>
    </Modal>
  );
};
