import { FunctionComponent, PropsWithChildren, SVGProps } from "react";
import { Box, Theme, Typography } from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import { ReactComponent as NoDataIcon } from "assets/images/no-data.svg";
import styles from "./NoDataFallback.styles";

type TNoDataFallbackProps = PropsWithChildren<{
  message: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  noBorders?: boolean;
  textColor?: string;
  fontSize?: string | number;
  sx?: SystemStyleObject<Theme>;
}>;

const NoDataFallback = ({
  message,
  children,
  icon: Icon = NoDataIcon,
  noBorders,
  textColor,
  fontSize,
  sx,
}: TNoDataFallbackProps) => (
  <Box
    sx={{
      ...styles.noData,
      ...(noBorders && { border: 0 }),
      ...sx,
    }}
    data-testid="no-data-message"
    data-qaid="no-data-message"
  >
    <Icon />
    <Typography
      variant="body2"
      color={textColor}
      fontSize={fontSize}
      data-qaid="no-data-message-text"
    >
      {message}
    </Typography>
    {children}
  </Box>
);

export default NoDataFallback;
