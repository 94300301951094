import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { gql } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { TUseUpdateProductStatusMutationData } from "./useUpdateProductStatus.models";

export const query = gql`
  mutation UpdateProductStatus(
    $updateProductStatusBody: ProductUpdateStatusBody
  ) {
    updateProductStatus(updateProductStatusBody: $updateProductStatusBody) {
      id
    }
  }
`;

const useUpdateProductStatus = () => {
  const { t } = useTranslation("administration", {
    keyPrefix: "notifications",
  });

  const { showAlert } = useAlerts();
  const queryClient = useQueryClient();

  const { data, isError, isLoading, isSuccess, mutateAsync } = useMutation(
    ({ updateProductStatusBody }: TUseUpdateProductStatusMutationData) =>
      services.graphQL
        .request(query, {
          updateProductStatusBody,
        })
        .then(() => {
          showAlert({
            message: t("updateProductStatusSuccess"),
            severity: AlertSeverity.Success,
          });

          queryClient.invalidateQueries([QueryAPIKey.GetEntityProducts]);
          queryClient.invalidateQueries([
            QueryAPIKey.GetProductVariants,
            updateProductStatusBody.id,
          ]);
        })
        .catch((error) => {
          showAlert({
            message: t("updateProductStatusFailure"),
            severity: AlertSeverity.Error,
          });

          throw error;
        }),
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    updateStatus: mutateAsync,
  };
};

export default useUpdateProductStatus;
