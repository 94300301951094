import { TStyles } from "utils/Styles";

export const defaultModalMinWidth = 600;
export const minModalMinWidth = "444px";
export const actionButtonsHeight = "68px";

const styles: TStyles = {
  dialog: {
    "& .MuiDialog-paper": {
      minWidth: defaultModalMinWidth,
    },

    "& .MuiDialogContent-root": {
      pt: 1,
      pb: 2,
      mb: actionButtonsHeight,
    },
  },

  closeButton: {
    mr: "32px",
  },
};

export default styles;
