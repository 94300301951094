import { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import EditorSkeleton from "components/common/Editor/Skeleton/Skeleton";
import useAssetContent from "hooks/api/REST/assets/useAssetContent";
import useElementOnScreen from "hooks/observer";
import { TDocumentViewMode } from "models/documents.models";
import {
  TDocumentAction,
  TDocumentComponent,
  TSkeletonComponent,
} from "../Document.types";
import DocumentComponent from "../DocumentComponent";
import { useDocumentContext } from "../DocumentContext/DocumentContext";
import {
  CACHE_TIME,
  ELEMENTS_COUNT_TRESHOLD,
} from "./ObservedDocumentComponent.constants";
import { scrollIntoViewPromise } from "./scrollIntoViewPromise";
import styles from "../Document.styles";

export type TObservedDocumentComponentProps = {
  component: TSkeletonComponent;
  setActiveAction: (action: TDocumentAction) => void;
  viewMode: TDocumentViewMode;
  sx?: SxProps<Theme>;
  scrollTargetComponentId: string | null;
};

const ObservedDocumentComponent = ({
  component,
  setActiveAction,
  viewMode,
  sx,
  scrollTargetComponentId,
}: TObservedDocumentComponentProps) => {
  const { containerRef, isVisible } = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.7,
    delay: 1000,
  });

  const { documentId } = useParams();

  const isHeader = component?.parentId === documentId;

  const { setVisibleEditorOrder, visibleEditorOrder } = useDocumentContext();

  useEffect(() => {
    if (isVisible) {
      setVisibleEditorOrder(component?.order);
    }
  }, [isVisible, setVisibleEditorOrder, component]);

  const isEnabledElement = useMemo(
    () =>
      Math.abs(component?.order - visibleEditorOrder) <=
      ELEMENTS_COUNT_TRESHOLD,
    [component, visibleEditorOrder],
  );

  const { data } = useAssetContent(
    component?.assetId,
    {},
    {
      enabled: isHeader ? false : isVisible || isEnabledElement,
      staleTime: CACHE_TIME,
      cacheTime: CACHE_TIME,
    },
  );

  const newComponent = useMemo(
    () => ({
      ...component,
      content: data,
    }),
    [data, component],
  ) as TDocumentComponent;

  useEffect(() => {
    if (component.assetId === scrollTargetComponentId && containerRef.current) {
      scrollIntoViewPromise(containerRef.current, { behavior: "auto" });
    }
  }, [scrollTargetComponentId, component, containerRef]);

  return (
    <>
      {isHeader ? (
        <Typography
          variant="h6"
          sx={{ ...styles.selectedHeader }}
          id={component.assetId}
        >
          {component?.headerText}
        </Typography>
      ) : (
        <Box ref={containerRef} sx={sx} id={component.assetId}>
          {data && isEnabledElement && newComponent.content ? (
            <DocumentComponent
              key={`section-${newComponent.assetId}`}
              component={newComponent}
              onActionSelect={setActiveAction}
              viewMode={viewMode}
            />
          ) : (
            <EditorSkeleton
              order={component.order}
              key={component.assetId}
              height={400}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default ObservedDocumentComponent;
