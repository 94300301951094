import { TypographyVariantsOptions } from "@mui/material/styles";

const FONT_FAMILY_INTER = [
  "Inter",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join();

const FONT_FAMILY_READEX_PRO = "Readex Pro";

const typography: TypographyVariantsOptions = {
  fontFamily: FONT_FAMILY_INTER,
  fontFamilyReadexPro: FONT_FAMILY_READEX_PRO,
  htmlFontSize: 14,

  // Built-in MUI variants
  h1: {
    fontFamily: FONT_FAMILY_READEX_PRO,
    fontSize: 96,
    fontWeight: 300,
    letterSpacing: -1.5,
    lineHeight: 1.167,
  },

  h2: {
    fontFamily: FONT_FAMILY_READEX_PRO,
    fontSize: 60,
    fontWeight: 300,
    letterSpacing: -0.5,
    lineHeight: 1.2,
  },

  h3: {
    fontFamily: FONT_FAMILY_READEX_PRO,
    fontSize: 48,
    fontWeight: 400,
    lineHeight: 1.167,
    letterSpacing: 0,
  },

  h4: {
    fontFamily: FONT_FAMILY_READEX_PRO,
    fontSize: 34,
    fontWeight: 300,
    letterSpacing: 0.25,
    lineHeight: 1.235,
  },

  h5: {
    fontFamily: FONT_FAMILY_READEX_PRO,
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: 0,
  },

  h6: {
    fontFamily: FONT_FAMILY_READEX_PRO,
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: 1.6,
  },

  subtitle1: {
    fontFamily: FONT_FAMILY_READEX_PRO,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.15,
    lineHeight: 1.75,
  },

  subtitle2: {
    fontFamily: FONT_FAMILY_READEX_PRO,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
    lineHeight: 1.57,
  },

  body1: {
    fontFamily: FONT_FAMILY_INTER,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.15,
    lineHeight: 1.5,
  },

  body2: {
    fontFamily: FONT_FAMILY_INTER,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.17,
    lineHeight: 1.43,
  },

  // aka Button Medium
  button: {
    fontFamily: FONT_FAMILY_READEX_PRO,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.4,
    lineHeight: 1.71,
    textTransform: "none",
  },

  caption: {
    fontFamily: FONT_FAMILY_INTER,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.4,
    lineHeight: 1.66,
  },

  overline: {
    fontFamily: FONT_FAMILY_INTER,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 1,
    lineHeight: 2.66,
    textTransform: "uppercase",
  },

  // Custom variants
  buttonLarge: {
    fontFamily: FONT_FAMILY_READEX_PRO,
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: 0.46,
    lineHeight: 1.73,
  },

  buttonSmall: {
    fontFamily: FONT_FAMILY_READEX_PRO,
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.46,
    lineHeight: 1.69,
  },

  helperText: {
    fontFamily: FONT_FAMILY_INTER,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.4,
    lineHeight: 1.67,
  },

  inputLabel: {
    fontFamily: FONT_FAMILY_INTER,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.15,
    lineHeight: 1.5,
  },

  inputText: {
    fontFamily: FONT_FAMILY_INTER,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.15,
    lineHeight: 1.5,
  },

  tooltip: {
    fontFamily: FONT_FAMILY_INTER,
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: 0,
  },

  badgeLabel: {
    fontFamily: FONT_FAMILY_READEX_PRO,
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.14,
    lineHeight: 1,
  },

  textLabel: {
    fontFamily: FONT_FAMILY_INTER,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.5,
    lineHeight: 1,
    textTransform: "uppercase",
  },

  alertTitle: {
    fontFamily: FONT_FAMILY_READEX_PRO,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: 0.15,
  },
};

export default typography;
