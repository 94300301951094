import { ProjectType } from "utils/constants/project.constants";
import { isHAUser, isSponsorUser } from "utils/user/organization";
import { TPermissionFunction } from "../usePermissions.types";

export const deriveSendHAInvitationPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveManageProjectInvitationsPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
    case ProjectType.RegulatoryReview:
      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};
