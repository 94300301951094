import { ReactNode } from "react";

export enum OrganizationsTable {
  Id = "id",
  Name = "name",
  Alias = "alias",
  Type = "type",
  Address = "address",
  City = "city",
  ZipCode = "zipCode",
  Country = "country",
  Status = "status",
  Actions = "actions",
}

export type TOrganizationsRowData = {
  [OrganizationsTable.Id]: string;
  [OrganizationsTable.Name]: string;
  [OrganizationsTable.Alias]: string;
  [OrganizationsTable.Type]: string;
  [OrganizationsTable.Address]?: string;
  [OrganizationsTable.City]?: string;
  [OrganizationsTable.ZipCode]?: string;
  [OrganizationsTable.Country]: string;
  [OrganizationsTable.Status]: string;
  [OrganizationsTable.Actions]: ReactNode;
};
