import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import InfoTooltip from "components/shared/InfoTooltip";
import { IFeatureFlag } from "models/featureFlags.models";
import { DEFAULT_DATE_FORMAT_WITH_TIME } from "utils/constants/format.constants";
import { formatDate, includeInArray } from "utils/helpers";
import {
  FeatureFlagsTable,
  TFeatureFlagsTableData,
} from "./FeatureFlagsTable.types";

type TFeatureFlagsTableColumn = TFeatureFlagsTableData & {
  apiFilterKey?: string;
};

type TGetFeatureFlagTableColumns = {
  onEditFlag: (flag: IFeatureFlag) => void;
  onDeleteFlag: (flag: IFeatureFlag) => void;
  isReadOnly: boolean;
};

const columnHelper = createColumnHelper<TFeatureFlagsTableColumn>();

const getColumns = (props: TGetFeatureFlagTableColumns, t: TFunction) => [
  columnHelper.accessor(FeatureFlagsTable.Name, {
    header: t("utils.columns.name"),
    cell: ({ cell: { getValue }, row: { original: flagData } }) => {
      return (
        <Typography>
          {getValue()}
          {flagData.description && (
            <InfoTooltip
              title={
                <Stack spacing={1}>
                  <Typography variant="subtitle2">
                    {t("utils.descriptionTooltip")}
                  </Typography>
                  <Typography variant="caption">
                    {flagData.description}
                  </Typography>
                </Stack>
              }
              textAriaLabel={t("ariaLabels.information", { ns: "common" })}
            />
          )}
        </Typography>
      );
    },
    enableSorting: true,
    enableColumnFilter: false,
    enableGlobalFilter: true,
    meta: {
      width: "38%",
    },
  }),
  columnHelper.accessor(FeatureFlagsTable.Value, {
    header: t("utils.columns.value"),
    enableSorting: true,
    enableColumnFilter: true,
    enableGlobalFilter: true,
    filterFn: "arrIncludesSome",
    meta: {
      width: "12%",
    },
  }),
  columnHelper.accessor(FeatureFlagsTable.DateStamp, {
    header: t("utils.columns.dateStamp"),
    enableSorting: true,
    enableColumnFilter: false,
    enableGlobalFilter: true,
    cell: ({ cell: { getValue } }) =>
      formatDate(getValue(), DEFAULT_DATE_FORMAT_WITH_TIME),
    meta: {
      width: "18%",
    },
  }),
  columnHelper.accessor(FeatureFlagsTable.ModifiedBy, {
    header: t("utils.columns.modifiedBy"),
    enableSorting: true,
    enableColumnFilter: true,
    enableGlobalFilter: true,
    filterFn: "arrIncludesSome",
    meta: {
      width: "22%",
    },
  }),
  ...includeInArray(
    columnHelper.accessor(FeatureFlagsTable.EditAction, {
      header: "",
      enableSorting: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: ({ cell: { getValue } }) => {
        return (
          <IconButton
            onClick={() => props.onEditFlag(getValue())}
            data-testid="flag-edit-btn"
            data-qaid="flag-edit-btn"
          >
            <EditIcon color="primary" />
          </IconButton>
        );
      },
      meta: {
        width: "5%",
      },
    }),
    !props.isReadOnly,
  ),
  ...includeInArray(
    columnHelper.accessor(FeatureFlagsTable.DeleteAction, {
      header: "",
      enableSorting: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: ({ cell: { getValue } }) => {
        return (
          <IconButton
            onClick={() => props.onDeleteFlag(getValue())}
            data-testid="flag-delete-btn"
            data-qaid="flag-delete-btn"
          >
            <DeleteIcon color="primary" />
          </IconButton>
        );
      },
      meta: {
        width: "5%",
      },
    }),
    !props.isReadOnly,
  ),
];

export default getColumns;
