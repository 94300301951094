import { useEffect, useRef } from "react";

export type TUseIntervalProps = {
  callback: () => void;
  delay: number | null;
};

export default function ({ callback, delay }: TUseIntervalProps) {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function exec() {
      savedCallback.current?.();
    }

    if (delay !== null) {
      const id = setInterval(exec, delay);

      return () => {
        clearInterval(id);
      };
    }
  }, [delay]);
}
