import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },

  aaidNote: {
    mb: 1.5,
  },

  accessAlarmIconBox: {
    backgroundColor: getPaletteColor("custom.accessAlarmIconBackground"),

    ".MuiSvgIcon-root": {
      color: getPaletteColor("text.primary"),
    },
  },
};

export default styles;
