import { AxiosError } from "axios";
import { GraphQLErrorExtensions } from "graphql/index";
import { ClientError } from "graphql-request";
import isNil from "lodash/isNil";

export const getStatusCode = (
  error: AxiosError | Error | ClientError | null,
): number | undefined | null => {
  if (isNil(error) || !("response" in error)) {
    return null;
  }

  if (error instanceof ClientError) {
    return (
      error.response.errors?.[0].extensions as GraphQLErrorExtensions as {
        response: { status: number };
      }
    ).response.status;
  }

  if (error instanceof AxiosError) {
    return error.response?.status;
  }
};
