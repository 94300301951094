import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import useShowAlert from "hooks/useShowAlert";
import { services } from "services";
import {
  NOTIFICATIONS_CURRENT_USER,
  QueryAPIKey,
} from "utils/constants/api.constants";

export type TNotificationMetadataForCreate = {
  custom: string;
};

export type TNotificationForCreate = {
  createdBy: string;
  createdDate: string;
  templateType: string;
  metadata: TNotificationMetadataForCreate;
};

const useCreateNotification = () => {
  const queryClient = useQueryClient();

  const { t } = useTranslation("user");

  const {
    status,
    error,
    isLoading,
    isError,
    isSuccess,
    mutate: triggerCreateNotification,
  } = useMutation(
    ({ userId, notification }: { userId: string; notification: any }) =>
      services.notifications
        .createNotification(userId || NOTIFICATIONS_CURRENT_USER, notification)
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryAPIKey.GetNotificationsForUser);
      },
    },
  );

  useShowAlert({
    status,
    failureMessage: t("notifications.createNotificationFailure"),
  });

  return {
    status,
    error,
    isLoading,
    isError,
    isSuccess,
    triggerCreateNotification,
  };
};

export default useCreateNotification;
