import { isFirefox } from "utils/helpers";
import { TStyles } from "utils/Styles";

const focusHighlightColor = isFirefox()
  ? "highlight"
  : "-webkit-focus-ring-color";

export const focusOutlineStyle = `${focusHighlightColor} auto 1px`;

const styles: TStyles = {
  SROnly: {
    position: "absolute",
    left: "-10000px",
    top: "auto",
    width: "1px",
    height: "1px",
    overflow: "hidden",
  },
};

export default styles;
