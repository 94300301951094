import { TStyles } from "utils/Styles";

const getStyles = (size = 26): TStyles => ({
  avatarGroup: {
    display: "flex",
    justifyContent: "flex-end",

    "& .MuiAvatar-root": {
      fontSize: "10px",
      fontFamily: "Inter",
      fontWeight: 500,
      position: "relative",
      width: size,
      height: size,
    },
  },
});

export default getStyles;
