import { IAsset } from "models/assets.models";
import { Path } from "router/paths";
import { DocumentSubtype } from "utils/constants/doc.constants";

const getDocumentPath = (
  projectId: string | undefined,
  document: Pick<IAsset, "id" | "type"> | undefined,
) => {
  if (document?.type === DocumentSubtype.GSP) {
    return Path.ProjectGSP.replace(":projectId", projectId ?? "").replace(
      ":GSPId",
      document?.id ?? "",
    );
  }

  if (document?.type === DocumentSubtype.AssessmentAid) {
    return Path.ProjectDocument.replace(":projectId", projectId ?? "").replace(
      ":documentId",
      document?.id ?? "",
    );
  }

  if (document?.type === DocumentSubtype.ECTD) {
    return Path.ProjectECTD.replace(":projectId", projectId ?? "").replace(
      ":ECTDId",
      document?.id ?? "",
    );
  }

  return document
    ? `${Path.ProjectDocuments.replace(
        ":projectId",
        projectId ?? "",
      )}?documentId=${document?.id}`
    : Path.ProjectDocuments.replace(":projectId", projectId ?? "");
};

export default getDocumentPath;
