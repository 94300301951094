import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { gql } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { ICreateFeatureFlagRequestParams } from "models/featureFlags.models";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";

export const query = gql`
  mutation CreateFeatureFlag($featureFlag: FeatureFlagInput!) {
    createFeatureFlag(featureFlag: $featureFlag) {
      name
      description
      value
      dateStamp
      modifiedBy
    }
  }
`;

const useCreateFeatureFlag = () => {
  const { t } = useTranslation("featureFlags", {
    keyPrefix: "notifications",
  });

  const { showAlert } = useAlerts();
  const queryClient = useQueryClient();

  const { data, isError, isLoading, isSuccess, mutate } = useMutation(
    ({ name, description, value }: ICreateFeatureFlagRequestParams) =>
      services.graphQL
        .request(query, {
          featureFlag: {
            name,
            description,
            value,
          },
        })
        .then(() => {
          showAlert({
            message: t("createFlagSuccess"),
            severity: AlertSeverity.Success,
          });

          queryClient.invalidateQueries(QueryAPIKey.GetFeatureFlags);
        })
        .catch((error) => {
          showAlert({
            message: t("createFlagFailure"),
            severity: AlertSeverity.Error,
          });

          throw error;
        }),
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    mutate,
  };
};

export default useCreateFeatureFlag;
