import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  keywordFilterField: {
    width: 260,

    "& .MuiOutlinedInput-notchedOutline": {
      border: getBorderStyle("1px", "solid", "blueGrey.100"),
    },

    "& .MuiInputBase-input": {
      fontSize: 14,
    },

    "& input::placeholder": {
      color: getPaletteColor("blueGrey.700"),
      opacity: 1,
    },
  },
  startAdornment: {
    color: getPaletteColor("blueGrey.600"),
  },
};

export default styles;
