import { createSvgIcon } from "@mui/material";

export const ArrowDropDownOutlinedIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path d="M7 10L12 15L17 10H7Z" fill-opacity="0.54" />
  </svg>,
  "ArrowDropDownOutlined",
);
