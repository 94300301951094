import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { gql, Variables } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { IProductVariantsResponse } from "./useProductVariants.models";

export const query = gql`
  query ProductVariants($productId: String!) {
    productVariants(productId: $productId) {
      id
      ownerTenantId
      productType {
        id
        code
        name
      }
      strengthValue
      strengthType {
        id
        code
        name
      }
      strengthVolumeValue
      strengthVolumeType {
        id
        code
        name
      }
      routeOfAdministrationType {
        id
        code
        name
      }
      manufacturedDoseFormType {
        id
        code
        name
      }
      actions {
        canChangeStatus
        canEditEntity
      }
      status
    }
  }
`;

type TUseProductVariantsParams = {
  productId: string;
};

const useProductVariants = ({ productId }: TUseProductVariantsParams) => {
  const { t } = useTranslation("administration");
  const { showAlert } = useAlerts();

  const { data, error, isLoading, isError, isSuccess } =
    useQuery<IProductVariantsResponse>(
      [QueryAPIKey.GetProductVariants, productId],
      () =>
        services.graphQL.request<IProductVariantsResponse, Variables>(query, {
          productId,
        }),
      {
        enabled: !!productId,
        onError: () => {
          showAlert({
            severity: AlertSeverity.Error,
            message: t("notifications.getProductVariantsFailure"),
          });
        },
      },
    );

  return {
    productVariants: data?.productVariants ?? [],
    error,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useProductVariants;
