import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import { DEFAULT_RELIANCE_HA_COLLAB_TENANT_ID } from "utils/constants/organization.constants";
import styles from "./RelianceRecipients.styles";

type TRelianceRecipientsField = {
  fieldName: string;
};

const RelianceRecipientsField = ({ fieldName }: TRelianceRecipientsField) => {
  const { t } = useTranslation(["documents"]);

  const { setValue, trigger } = useFormContext();

  useEffect(() => {
    // ToDo (AM): Defaulting the recipients to all Reliance HAs ("ha-collab")
    // "ha-collab" as a separate organization was deprecated and no longer returned from BE
    // Workflows will keep working with this value for now as future implementation is still TBD
    const fieldValue = [
      { label: "", value: DEFAULT_RELIANCE_HA_COLLAB_TENANT_ID },
    ];

    setValue(fieldName, fieldValue);
    trigger(fieldName);
  }, [setValue, trigger, fieldName]);

  return (
    <Box paddingTop={2}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="body2" sx={styles.label}>
            {t("documents.submitDocuments.fields.recipients.label")}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">
            {t(
              "documents.submitDocuments.fields.recipients.relianceRecipients",
            )}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RelianceRecipientsField;
