import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { Row } from "@tanstack/react-table";
import { TFunction } from "i18next";
import ButtonMenu from "components/shared/ButtonMenu";
import { ICheckboxOption } from "components/shared/CheckboxTree/CheckboxTree.utils";
import { EntityStatus } from "models/entities.models";
import {
  TEntityOrganization,
  TLinkedOrganizationEntity,
} from "screens/EntityManagement/components/Organization/Organization.types";
import { TProductFamily } from "screens/EntityManagement/components/ProductFamily/ProductFamily.types";
import { TSubstance } from "screens/EntityManagement/components/Substances/Substances.types";
import { formatValuesToOptions } from "utils/helpers";
import { TEntityProduct } from "../Products.types";
import { ProductsTable, TProductsRowData } from "./ProductsTable.types";

export const getProductRowActions = (
  product: TEntityProduct,
  t: TFunction,
  onEditProduct: (product: TEntityProduct) => void,
  onCreateProductVariant: (product: TEntityProduct) => void,
  onChangeProductStatus: (product: TEntityProduct) => void,
  canManageEntities: boolean,
) =>
  canManageEntities
    ? [
        {
          id: "change-product-status",
          label: t("changeProductStatus", { ns: "administration" }),
          onClick: () => {
            onChangeProductStatus(product);
          },
          disabled: !product.actions.canChangeStatus,
        },
        {
          id: "create-product-variant",
          label: t("createProductVariant", { ns: "administration" }),
          onClick: () => {
            onCreateProductVariant(product);
          },
          disabled: !product.actions.canEditEntity,
        },
        {
          id: "edit-product",
          label: t("editProduct", { ns: "administration" }),
          onClick: () => {
            onEditProduct(product);
          },
          disabled: !product.actions.canEditEntity,
        },
      ]
    : [];

export const getProductsForRender = (
  products: TEntityProduct[],
  t: TFunction,
  onEditProduct: (product: TEntityProduct) => void,
  onCreateProductVariant: (product: TEntityProduct) => void,
  onChangeProductStatus: (product: TEntityProduct) => void,
  canManageEntities: boolean,
): TProductsRowData[] =>
  products.map((product) => ({
    [ProductsTable.Id]: product.id,
    [ProductsTable.Name]: product.tradeName,
    [ProductsTable.NonProprietaryName]: product.nonproprietaryName,
    [ProductsTable.ProductFamily]: product.productFamilies,
    [ProductsTable.Substance]: product.substances,
    [ProductsTable.LinkedOrganizations]: product.linkedOrganizations,
    [ProductsTable.Status]: product.status,
    [ProductsTable.Actions]: (
      <ButtonMenu
        options={getProductRowActions(
          product,
          t,
          onEditProduct,
          onCreateProductVariant,
          onChangeProductStatus,
          canManageEntities,
        )}
        onClick={(event) => {
          event.stopPropagation(); // Prevents row expand / collapse from firing when clicking a menu option
        }}
      >
        <IconButton aria-label="actionsButton" disabled={!canManageEntities}>
          <MoreVertIcon />
        </IconButton>
      </ButtonMenu>
    ),
  }));

export const getProductAndProductVariantStatusOptions = (t: TFunction) =>
  formatValuesToOptions(
    [EntityStatus.Active, EntityStatus.Inactive],
    (status) => t(`${status}`),
    (status) => status,
  );

export const getOrganizationsOptions = (
  organizations: TEntityOrganization[],
  t: TFunction,
): ICheckboxOption[] =>
  organizations.map((organization) => ({
    id: organization.id,
    label: organization.name,
    value: organization.id,
    children: organization.roleTypes.map((roleType) => ({
      id: `${organization.id}-${roleType}`,
      label: t(`createOrganizationalModal.organizationRoles.${roleType}`),
      value: `${organization.id}-${roleType}`,
    })),
  }));

export const filterByLinkedOrg = (
  row: Row<TProductsRowData>,
  columnId: string,
  filterValue: string[],
) => {
  const rowOrgIds = (row.getValue(columnId) as TLinkedOrganizationEntity[]).map(
    (org) => org.id,
  );

  return filterValue.some((selectedOrgId) => rowOrgIds.includes(selectedOrgId));
};

export const filterByLinkedProductFamily = (
  row: Row<TProductsRowData>,
  columnId: string,
  filterValue: string[],
) => {
  const rowProductFamilyIds = (row.getValue(columnId) as TProductFamily[]).map(
    (productFamily) => productFamily.id,
  );

  return filterValue.some((selectedProductFamilyId) =>
    rowProductFamilyIds.includes(selectedProductFamilyId),
  );
};

export const filterByLinkedSubstance = (
  row: Row<TProductsRowData>,
  columnId: string,
  filterValue: string[],
) => {
  const substanceIds = (row.getValue(columnId) as TSubstance[]).map(
    (substance) => substance.id,
  );

  return filterValue.some((selectedSubstanceId) =>
    substanceIds.includes(selectedSubstanceId),
  );
};
